import React, { useState, useEffect, useContext } from 'react';
import { Col, Row, Form, InputGroup, Badge } from 'react-bootstrap';
import { AxiosResponse } from 'axios';

import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import { months, years } from 'utils/constData';
import { ROLES as RolesMap } from 'constantes/permission-maps';
import { Capitalize1stLetter, getRandomlyFromArray, hasRole, sortByNumber } from 'utils/helpers';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import Charts, { PieData, DoughnutData, LineData, BarData } from 'components/shared/charts';
import CardDashboard, { colorsClasses } from 'components/UI/cardDashboard';
import { getDashboardStates, getDashboardCharts } from 'services/dashboard.service';
import { getLivreursByFournisseur } from 'services/user.service';
import { getClientsByIdFournisseur } from 'services/client.service';
import UserInterface from 'types/interfaces/user.interface';
import ClientInterface from 'types/interfaces/client.interface';

const Home: React.FC = () => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);
    const [statesData, setStatesData] = useState<any[]>([]);
    const [chartsData, setChartsData] = useState<any[]>([]);
    const [livreurs, setLivreurs] = useState<UserInterface[]>([]);
    const [clients, setClients] = useState<ClientInterface[]>([]);
    const [currentLivreur, setCurrentLivreur] = useState<number | null>(null);
    const [currentClient, setCurrentClient] = useState<number | null>(null);
    const [year, setYear] = useState<number | null>(null);

    useEffect(() => {
        document.title = 'Dashboard - DS';

        getDashboardStates()
            .then((response: AxiosResponse<any[] | any>) => {
                const { data } = response;
                setStatesData(Object.entries(data).map(([name, val]) => ({ [name]: val })));
            })
            .catch();
    }, []);
    useEffect(() => {
        if (contextUser) {
            if (hasRole(contextUser.ROLES, RolesMap.FOURNISSEUR_ADMIN)) {
                let idClient = -1;
                let idLivreur = -1;
                getLivreursByFournisseur(contextUser.userData?.idFournisseur!)
                    .then((response: AxiosResponse<UserInterface[] | any>) => {
                        const { data } = response;
                        if (data.length > 0) idLivreur = data[0].idUser;
                        setCurrentLivreur(idLivreur);
                        setLivreurs(data);
                        return getClientsByIdFournisseur(contextUser.userData?.idFournisseur!);
                    })
                    .then((response: AxiosResponse<ClientInterface[] | any>) => {
                        const { data } = response;
                        if (data.length > 0) idClient = data[0].idClient;
                        setCurrentClient(idClient);
                        setClients(data);
                    })
                    .catch();
            } else {
                setYear(new Date().getFullYear());
            }
        }
    }, [contextUser]);

    useEffect(() => {
        if (currentLivreur && currentClient && hasRole(contextUser?.ROLES, RolesMap.FOURNISSEUR_ADMIN)) {
            getDashboardCharts(year!, currentClient, currentLivreur)
                .then((response: AxiosResponse<any[] | any>) => {
                    const { data } = response;
                    setChartsData(Object.entries(data).map(([name, val]) => ({ [name]: val })));
                })
                .catch();
        } else if (!hasRole(contextUser?.ROLES, RolesMap.FOURNISSEUR_ADMIN)) {
            getDashboardCharts(year!)
                .then((response: AxiosResponse<any[] | any>) => {
                    const { data } = response;
                    setChartsData(Object.entries(data).map(([name, val]) => ({ [name]: val })));
                })
                .catch();
        }
    }, [year, currentLivreur, currentClient, contextUser]);

    // useEffect(() => {
    //     console.log('yy');
    //     getDashboardCharts(year)
    //         .then((response: AxiosResponse<any[] | any>) => {
    //             const { data } = response;
    //             setChartsData(Object.entries(data).map(([name, val]) => ({ [name]: val })));
    //         })
    //         .catch();
    // }, [year]);

    const breadcrumbData: breadcrumbDataType[] = [{ path: '/', text: 'Dashboard', active: true }];

    const renderChartData = (type: 'Bar' | 'Line' | 'Pie' | 'Doughnut', values: any) => {
        let chartData = null;
        if (type === 'Bar')
            chartData = BarData({
                labels: values.labels,
                title: values.title,
                data: values.data,
            });
        else if (type === 'Line')
            chartData = LineData({
                labels: values.labels,
                title: values.title,
                data: values.data,
            });
        else if (type === 'Pie')
            chartData = PieData({
                labels: values.labels,
                title: values.title,
                data: values.data,
            });
        else if (type === 'Doughnut')
            chartData = DoughnutData({
                labels: values.labels,
                title: values.title,
                data: values.data,
            });
        return chartData;
    };

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <Row>
                {statesData.map((d: any, i: number) => (
                    <Col className="pb-2" key={i} lg={12 / statesData.length} md={12 / statesData.length}>
                        <CardDashboard
                            borderColor={getRandomlyFromArray(
                                colorsClasses.filter((d: string) => !['light'].includes(d)),
                            )}
                            textColor="primary"
                            headerTitle={Capitalize1stLetter(Object.keys(d)[0])}
                            bodyTitle="Total"
                            bodyNumber={{ number: d[Object.keys(d)[0]] }}
                        />
                    </Col>
                ))}
            </Row>
            <div className="d-flex justify-content-between align-items-center py-2">
                <div></div>
                <div>
                    <Row>
                        <Col auto>
                            <InputGroup className="align-items-center" size="sm">
                                Année: &nbsp;
                                <Form.Select
                                    size="sm"
                                    className="text-end"
                                    name="year"
                                    value={year!}
                                    onChange={(e: any) => setYear(+e.target.value)}
                                    placeholder="Selectionner une année"
                                >
                                    {years().map((d: number) => (
                                        <option key={d} value={d}>
                                            {d}
                                        </option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Col>
                        {currentLivreur && currentClient && hasRole(contextUser?.ROLES, RolesMap.FOURNISSEUR_ADMIN) && (
                            <>
                                <Col auto>
                                    <InputGroup className="align-items-center" size="sm">
                                        Client: &nbsp;
                                        <Form.Select
                                            size="sm"
                                            className="text-end"
                                            name="client"
                                            value={currentClient}
                                            onChange={(e: any) => setCurrentClient(+e.target.value)}
                                            placeholder="Selectionner un client"
                                        >
                                            {clients.map((d: ClientInterface) => (
                                                <option key={d.idClient} value={d.idClient}>
                                                    {d.raisonSocial}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                                <Col auto>
                                    <InputGroup className="align-items-center" size="sm">
                                        Livreur: &nbsp;
                                        <Form.Select
                                            size="sm"
                                            className="text-end"
                                            name="livreur"
                                            value={currentLivreur}
                                            onChange={(e: any) => setCurrentLivreur(+e.target.value)}
                                            placeholder="Selectionner une livreur"
                                        >
                                            {livreurs.map((d: UserInterface) => (
                                                <option key={d.idUser} value={d.idUser}>
                                                    {d.nom.toUpperCase()} {Capitalize1stLetter(d.prenom!)}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                            </>
                        )}
                    </Row>
                </div>
            </div>
            <Row className="mt-3">
                {chartsData.map((d: any, i: number) => {
                    const usingMonths = !!d[Object.keys(d)[0]].useMonths;
                    const dataValues = sortByNumber(d[Object.keys(d)[0]].data, 'month');
                    const labelsList = usingMonths ? months : dataValues.map((dv: any) => dv.name);
                    const datasList = dataValues.map((dv: any) => dv.count);
                    const chart_values = {
                        title: Capitalize1stLetter(d[Object.keys(d)[0]].title),
                        labels: labelsList,
                        data: datasList,
                    };
                    const chart_data = renderChartData(d[Object.keys(d)[0]].type, chart_values);
                    return (
                        <Col
                            className="pb-2"
                            key={i}
                            lg={i === chartsData.length - 1 && (i + 1) % 2 !== 0 ? 12 : 6}
                            md={i === chartsData.length - 1 && (i + 1) % 2 !== 0 ? 12 : 6}
                        >
                            <h5 className="my-header-title">
                                {Capitalize1stLetter(d[Object.keys(d)[0]].title)}{' '}
                                <Badge bg="light" text="dark" style={{ fontSize: '0.7rem' }}>
                                    {year}
                                </Badge>
                            </h5>
                            <Charts
                                type={d[Object.keys(d)[0]].type}
                                data={chart_data?.data}
                                options={chart_data?.options}
                            />
                            <hr />
                        </Col>
                    );
                })}
            </Row>
        </>
    );
};

export default Home;
