import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { Col, Form, Row, Button, Spinner, InputGroup, Table } from 'react-bootstrap';
import Datetime from 'react-datetime';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import PermissionsGate from 'hoc/permissionsGate';
import { ROLES as RolesMap } from 'constantes/permission-maps';

import CommandeInterface from 'types/interfaces/commande.interface';
import FactureInterface from 'types/interfaces/facture.interface';
import { updateFacture, getDeepFactureById } from 'services/facture.service';

const schemaFacture = yup.object({
    idFacture: yup.number().required(),
    refFacture: yup.string().required(),
    dateFacture: yup.date().required(),
    montantFacture: yup.number().positive().required(),
    remiseAccorde: yup.number().moreThan(-1).required(),
});

type Props = {
    idFacture: number;
    factures: { items: FactureInterface[]; count: number };
    setFactures: (value: { items: FactureInterface[]; count: number }) => void;
};

const EditFormFacture: React.FC<Props> = ({ idFacture, factures, setFactures }: Props) => {
    const [spinIt, setSpinIt] = useState<boolean>(false);
    const [facture, setFacture] = useState<FactureInterface | null>(null);

    useEffect(() => {
        getDeepFactureById(idFacture)
            .then((response: AxiosResponse<FactureInterface | any>) => {
                const { data } = response;
                setFacture(data);
            })
            .catch();
    }, [idFacture]);

    const submitFactureForm = async (values: FactureInterface | any) => {
        if (values.idPaiement) return;
        try {
            setSpinIt(true);
            const response = await updateFacture(values);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Facture a été modifiée.');
                const facturesData = [...factures.items];
                const factureIndex = facturesData.findIndex(
                    (el: FactureInterface) => el.idFacture == resultData.idFacture,
                );
                facturesData[factureIndex] = resultData;
                setFacture(resultData);
                setFactures({ items: facturesData, count: facturesData.length });
                setSpinIt(false);
            }
        } catch (Exception) {
            setSpinIt(false);
        }

        setSpinIt(false);
    };
    return (
        <div>
            <Formik
                validationSchema={schemaFacture}
                onSubmit={(values) => submitFactureForm(values)}
                enableReinitialize={true}
                initialValues={{
                    idFacture: facture?.idFacture!,
                    refFacture: facture?.refFacture!,
                    dateFacture: facture?.dateFacture! ? moment() : moment(facture?.dateFacture),
                    montantFacture: facture?.montantFacture!,
                    remiseAccorde: facture?.remiseAccorde!,
                    idPaiement: facture?.idPaiement!,
                }}
            >
                {({ handleSubmit, handleChange, values, errors }) => (
                    <Form noValidate onSubmit={handleSubmit} className="d-grid gap-2">
                        <Row>
                            <Col md="6">
                                <Row className="align-items-center">
                                    <Col auto>
                                        <Form.Label>Réf:</Form.Label>
                                    </Col>
                                    <Col auto>
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            name="refFacture"
                                            value={values.refFacture}
                                            onChange={() => void 0}
                                            readOnly
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="6">
                                <Row className="align-items-center">
                                    <Col auto>
                                        <Form.Label>Date:</Form.Label>
                                    </Col>
                                    <Col auto>
                                        <Datetime
                                            className="form-control form-control-sm no_form-control_input_style"
                                            inputProps={{
                                                name: 'dateFacture',
                                                className: `text-end form-control form-control-sm ${
                                                    !!errors.dateFacture ? 'is-invalid' : ''
                                                }`,
                                                readOnly: true,
                                            }}
                                            timeFormat={false}
                                            dateFormat="DD/MM/YYYY"
                                            value={values.dateFacture}
                                            onChange={(dateValue: any) => {
                                                const e = {
                                                    target: {
                                                        value: dateValue,
                                                        name: 'dateFacture',
                                                    },
                                                };

                                                if (moment.isMoment(dateValue)) handleChange(e);
                                            }}
                                            closeOnSelect={true}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <Row className="align-items-center">
                                    <Col auto>
                                        <Form.Label>Montant:</Form.Label>
                                    </Col>
                                    <Col auto>
                                        <InputGroup>
                                            <Form.Control
                                                className="text-end"
                                                type="number"
                                                name="montantFacture"
                                                value={values.montantFacture}
                                                onChange={handleChange}
                                            />
                                            <InputGroup.Text>DH</InputGroup.Text>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="6">
                                <Row className="align-items-center">
                                    <Col auto>
                                        <Form.Label>Remise:</Form.Label>
                                    </Col>
                                    <Col auto>
                                        <InputGroup>
                                            <Form.Control
                                                className="text-end"
                                                type="number"
                                                name="remiseAccorde"
                                                min={0}
                                                value={values.remiseAccorde}
                                                max={values.montantFacture}
                                                onChange={handleChange}
                                                isInvalid={!!errors.remiseAccorde}
                                            />
                                            <InputGroup.Text>DH</InputGroup.Text>
                                        </InputGroup>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.remiseAccorde}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <h6>Détails:</h6>
                        <Table striped responsive size="sm">
                            <thead>
                                <tr>
                                    <th>Code</th>
                                    <th>Ville</th>
                                    <th>Acheteur</th>
                                    <th>Date de livraison</th>
                                    <th>Frais</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {facture?.commandes?.map((d: CommandeInterface) => (
                                    <tr key={d.idCommande}>
                                        <td>
                                            <b>{d.code}</b>
                                        </td>
                                        <td>{d.ville?.nomVille}</td>
                                        <td>
                                            <b>{d.acheteur?.fullName!}</b>
                                            <br />
                                            {d.acheteur?.gsm}
                                        </td>
                                        <td>{moment(d.dateOperation).format('DD/MM/YYYY HH:mm')}</td>
                                        <td>
                                            {(d.commandetarifs ?? []).reduce((accumulator: number, object: any) => {
                                                return accumulator + object.tarif?.prixTarif;
                                            }, 0)}
                                        </td>
                                        <td>{d.prixTotal!}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        {!values.idPaiement && (
                            <PermissionsGate scopes={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_FACTURE]} strict>
                                <div className="d-grid gap-2">
                                    <Button variant="primary" type="submit" disabled={!!spinIt}>
                                        Enregistrer
                                        {!!spinIt && (
                                            <>
                                                &nbsp; <Spinner animation="border" size="sm" />
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </PermissionsGate>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EditFormFacture;
