import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Button, Row, Form, Fade, Alert, Spinner } from 'react-bootstrap';
import { TableColumnType } from 'react-bs-datatable';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { AxiosResponse } from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck, faPlus, faEdit, faCheckDouble, faBan } from '@fortawesome/free-solid-svg-icons';

import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';

import { useQueryMeMo, buildUrlQueryParams, isEmptyObject, hasRole } from 'utils/helpers';
import { ROLES as RolesMap } from 'constantes/permission-maps';

import ClientInterface from 'types/interfaces/client.interface';
import { getRegions } from 'services/region.service';
import { getRoles } from 'services/role.service';
import { getBanques } from 'services/banque.service';
import { getFournisseurs } from 'services/fournisseur.service';
import RegionInterface from 'types/interfaces/region.interface';
import BanqueInterface from 'types/interfaces/banque.interface';
import RoleInterface from 'types/interfaces/role.interface';
import FournisseurInterface from 'types/interfaces/fournisseur.interface';
import { getClientsPaginated, saveFreshOrExistedClient, toggleDisableClient } from 'services/client.service';

import CustomModal from 'components/UI/customModal';
import { FormCheckField, FormField } from 'components/shared/formInputs';
import Stepper from 'components/UI/stepper';
import Datatable from 'components/shared/datatable';
import SearchInput from 'components/shared/searchInput';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import TitleElement from 'components/UI/titleElement';

const schema = [
    yup.object().shape({
        client: yup.object().shape({
            idClient: yup.number(),
            raisonSocial: yup.string().required(),
            addresse: yup.string().nullable(),
            telephoneBureau1: yup.string().required(),
            telephoneBureau2: yup.string(),
            siteWeb: yup.string(),
            pageFacebook: yup.string(),
            pageInstagrame: yup.string(),
            etatCompte: yup.boolean(),
            rIB: yup.string(),
            idRegion: yup.number().required(),
            idBanque: yup.number().required(),
            idFournisseur: yup.number().required(),
            echeancePaiement: yup.number().positive().required(),
            cin: yup
                .string()
                .nullable()
                .when('type', {
                    is: (type: string) => type === 'Particulier',
                    then: yup.string().required(),
                }),
            ice: yup
                .string()
                .nullable()
                .when('type', {
                    is: (type: string) => type === 'Société',
                    then: yup.string().required(),
                }),
            if: yup
                .string()
                .nullable()
                .when('type', {
                    is: (type: string) => type === 'Société',
                    then: yup.string().required(),
                }),
            type: yup.string().nullable(),
        }),
    }),
    yup.object({
        user: yup.object().shape({
            nom: yup.string().required(),
            prenom: yup.string().required(),
            gSM: yup.string(),
            cIN: yup.string(),
            email: yup.string().email().required(),
            pwd: yup
                .string()
                .min(3)
                .max(30)
                .when('idUser', {
                    is: (idUser: number) => !!isNaN(idUser),
                    then: yup.string().min(3).max(30).required(),
                }),
        }),
    }),
];

const TOTAL_DATA_PER_PAGE = 100;

const ClientIndexPage: React.FC = () => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    const [clients, setClients] = useState<{ items: ClientInterface[]; count: number }>({
        items: [],
        count: 0,
    });
    const [currentClient, setCurrentClient] = useState<ClientInterface | null>(null);
    const [regions, setRegions] = useState<RegionInterface[]>([]);
    const [banques, setBanques] = useState<BanqueInterface[]>([]);
    const [fournisseurs, setFournisseurs] = useState<FournisseurInterface[]>([]);

    const [showFormModal, setShowFormModal] = useState<boolean>(false);
    const [formModalEditMode, setFormModalEditMode] = useState<boolean>(false);
    const [spinIt, setSpinIt] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [rolesMap, setRolesMap] = useState<object>({});

    const [inputValue, setInputValue] = useState('');
    const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null);

    const [activePage, setActivePage] = useState<number>(1);

    const history = useNavigate();
    const query = useQueryMeMo();

    const [step, setStep] = useState(0);
    const [confirmed, setConfirmed] = useState(false);
    const [formValues, setFormValues] = useState({});

    const maxStep = !!formModalEditMode ? 0 : 1;

    const handleFormModalClose = () => {
        setCurrentClient(null);
        setShowFormModal(false);
        resetStepper();
    };

    const handleFormModalOpen = (editMode = false, clientData?: ClientInterface) => {
        setConfirmed(false);
        setErrorMessage(null);
        if (clientData) setCurrentClient(clientData);
        setFormModalEditMode(editMode);
        setShowFormModal(true);
    };

    const inputChanged = (e: any) => {
        setInputValue(e.target.value);

        clearTimeout(timer!);

        const newTimer = setTimeout(() => {
            handlePageNFilterChange(1, e.target.value);
        }, 500);

        setTimer(newTimer);
    };

    const handlePageNFilterChange = (activePage: number, search?: string) => {
        const urlObject: any = {};
        if (activePage > 1) urlObject['page'] = activePage;
        if (search && search.trim() != '') urlObject['search'] = search;
        history(isEmptyObject(urlObject) ? window.location.pathname : `?${buildUrlQueryParams(urlObject)}`);
    };

    const tableHeaders: TableColumnType<any>[] = [
        {
            prop: 'raisonSocial',
            title: 'Raison Social',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'echeancePaiement',
            title: 'Écheance de paiement',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'etatCompte',
            title: 'Etat de Compte',
            cellProps: {
                className: 'foceVerticalMiddle text-center',
            },
            thProps: {
                className: 'text-center',
            },
            cell: (row: ClientInterface) => (
                <>
                    {!!row.etatCompte ? (
                        <FontAwesomeIcon icon={faCheck} size="xs" style={{ color: '#337ac8' }} />
                    ) : (
                        <FontAwesomeIcon icon={faXmark} size="xs" style={{ color: '#ff832c' }} />
                    )}
                </>
            ),
        },
        {
            prop: 'null',
            title: 'Actions',
            alignment: { horizontal: 'right' },
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: ClientInterface) => (
                <>
                    <Button
                        variant="outline-secondary"
                        className="button-input-group-effect gap-5marginpx"
                        id="basic-addon1"
                        onClick={() => handleFormModalOpen(true, row)}
                    >
                        <FontAwesomeIcon icon={faEdit} size="xs" style={{ color: '#337ab7' }} />
                    </Button>
                    {!!row.etatCompte ? (
                        <Button
                            variant="outline-secondary"
                            className="button-input-group-effect gap-5marginpx rounded-circle"
                            id="basic-addon2"
                            onClick={() => toggleStatus(row)}
                        >
                            <FontAwesomeIcon icon={faBan} size="xs" style={{ color: '#ff833b' }} />
                        </Button>
                    ) : (
                        <Button
                            variant="outline-secondary"
                            className="button-input-group-effect gap-5marginpx rounded-circle"
                            id="basic-addon2"
                            onClick={() => toggleStatus(row)}
                        >
                            <FontAwesomeIcon icon={faCheckDouble} size="xs" style={{ color: '#33b76e' }} />
                        </Button>
                    )}
                </>
            ),
        },
    ];

    const formData = (step: number, props: any) =>
        [
            <div key={0}>
                {!formModalEditMode && <h4 className="text-center">Informations du client</h4>}
                <Row>
                    <FormField
                        rowGroup
                        label="Raison Social"
                        name="client.raisonSocial"
                        type="text"
                        size="sm"
                        upperCase={true}
                    />
                    <FormField
                        rowGroup
                        as="textarea"
                        rows={2}
                        label="Addresse"
                        name="client.addresse"
                        type="text"
                        size="sm"
                    />
                </Row>
                <Row>
                    <FormField
                        rowGroup
                        label="Telephone Bureau1"
                        name="client.telephoneBureau1"
                        type="text"
                        size="sm"
                    />
                    <FormField
                        rowGroup
                        label="Telephone Bureau2"
                        name="client.telephoneBureau2"
                        type="text"
                        size="sm"
                    />
                </Row>
                <Row>
                    <FormField rowGroup label="SiteWeb" name="client.siteWeb" type="text" size="sm" />
                    <FormField rowGroup label="Page Facebook" name="client.pageFacebook" type="text" size="sm" />
                </Row>
                <Row>
                    <FormField rowGroup label="Page Instagrame" name="client.pageInstagrame" type="text" size="sm" />
                    <FormField rowGroup label="RIB" name="client.rIB" type="text" size="sm" />
                </Row>
                <Row>
                    <FormField
                        rowGroup
                        label="Ville"
                        name="client.idRegion"
                        as="select"
                        size="sm"
                        className="form-select form-select-sm"
                    >
                        <>
                            <option>Selectionner une ville</option>
                            {regions.map((d: RegionInterface) => (
                                <option key={d.idRegion} value={d.idRegion}>
                                    {d.nomRegion}
                                </option>
                            ))}
                        </>
                    </FormField>
                    <FormField
                        rowGroup
                        label="Banque"
                        name="client.idBanque"
                        as="select"
                        size="sm"
                        className="form-select form-select-sm"
                    >
                        <>
                            <option>Selectionner une banque</option>
                            {banques.map((d: BanqueInterface) => (
                                <option key={d.idBanque} value={d.idBanque}>
                                    {d.libelleBanque}
                                </option>
                            ))}
                        </>
                    </FormField>
                </Row>
                <Row>
                    <FormField
                        rowGroup
                        label="Type"
                        name="client.type"
                        as="select"
                        size="sm"
                        className="form-select form-select-sm"
                    >
                        <>
                            <option value="Société">Société</option>
                            <option value="Particulier">Particulier</option>
                        </>
                    </FormField>
                    <FormField
                        rowGroup
                        label="CIN"
                        name="client.cin"
                        type="text"
                        size="sm"
                        hideOn={props.values.client.type !== 'Particulier'}
                    />
                </Row>
                <Row>
                    <FormField
                        rowGroup
                        label="ICE"
                        name="client.ice"
                        type="text"
                        size="sm"
                        hideOn={props.values.client.type !== 'Société'}
                    />
                    <FormField
                        rowGroup
                        label="IF"
                        name="client.if"
                        type="text"
                        size="sm"
                        hideOn={props.values.client.type !== 'Société'}
                    />
                </Row>
                <Row>
                    <FormField
                        rowGroup
                        label="Écheance de paiement"
                        name="client.echeancePaiement"
                        type="number"
                        size="sm"
                        min={1}
                        max={60}
                    />
                    <FormCheckField rowGroup label="ETAT de COMPTE" name="client.etatCompte" />
                </Row>

                {contextUser && hasRole(contextUser.ROLES, RolesMap.SUPER_ADMIN) && (
                    <FormField
                        label="Fournisseur"
                        name="client.idFournisseur"
                        as="select"
                        size="sm"
                        className="form-select form-select-sm"
                    >
                        <>
                            <option>Selectionner un fournisseur</option>
                            {fournisseurs.map((d: FournisseurInterface) => (
                                <option key={d.idFournisseur} value={d.idFournisseur}>
                                    {d.raisonSocial}
                                </option>
                            ))}
                        </>
                    </FormField>
                )}
            </div>,
            <div key={1}>
                {!formModalEditMode && <h4 className="text-center">Client utilisateur Admin</h4>}
                <Row>
                    <FormField rowGroup label="Nom" name="user.nom" type="text" size="sm" upperCase={true} />
                    <FormField rowGroup label="Prenom" name="user.prenom" type="text" size="sm" />
                </Row>
                <Row>
                    <FormField rowGroup label="GSM" name="user.gSM" type="text" size="sm" />
                    <FormField rowGroup label="CIN" name="user.cIN" type="text" size="sm" />
                </Row>
                <Row>
                    <FormField rowGroup label="Email" name="user.email" type="email" size="sm" />
                    <FormField rowGroup label="Password" name="user.pwd" type="password" size="sm" />
                </Row>
            </div>,
        ][step];

    const loadClients = async (totalPage: number, page: number, search?: string) => {
        try {
            const {
                status,
                data: { items: data },
                data: { count },
            } = await getClientsPaginated(TOTAL_DATA_PER_PAGE, page, search);
            if (status === 200) {
                setClients({ items: data, count });
            }
        } catch (Exception) {}
    };

    useEffect(() => {
        document.title = 'Clients - DS';

        getBanques()
            .then((response: AxiosResponse<BanqueInterface[] | any>) => {
                const { data } = response;
                setBanques(data);
            })
            .catch();

        getRegions()
            .then((response: AxiosResponse<RegionInterface[] | any>) => {
                const { data } = response;
                setRegions(data);
            })
            .catch();

        getRoles()
            .then((response: AxiosResponse<RoleInterface[] | any>) => {
                const { data } = response;
                const rolesObject = {};
                data.forEach((element: RoleInterface) => {
                    (rolesObject as any)[element.nomRole] = element.idRole;
                });
                setRolesMap(rolesObject);
            })
            .catch();
    }, []);

    useEffect(() => {
        if (contextUser && hasRole(contextUser.ROLES, RolesMap.SUPER_ADMIN)) {
            getFournisseurs()
                .then((response: AxiosResponse<FournisseurInterface[] | any>) => {
                    const { data } = response;
                    setFournisseurs(data);
                })
                .catch();
        }
    }, [contextUser]);

    useEffect(() => {
        const page = query.get('page');
        const search = query.get('search');
        let currentPage = 1;
        let instantSearch = '';
        if (page) currentPage = +page;
        if (search) instantSearch = decodeURIComponent(search);
        setActivePage(currentPage);
        setInputValue(instantSearch);
        const loadData = async () => {
            await loadClients(TOTAL_DATA_PER_PAGE, currentPage, instantSearch);
        };
        loadData();
    }, [query]);

    const toggleStatus = async (clientData: ClientInterface) => {
        try {
            const { data: clientInfo } = await toggleDisableClient(clientData.idClient);
            const clientsList = [...clients.items];
            const index = clientsList.findIndex((d) => d.idClient == clientInfo.idClient);
            clientsList[index] = clientInfo;
            setClients({ items: clientsList, count: clients.count });
        } catch (Exception) {}
    };

    const resetStepper = () => {
        setStep(0);
        setConfirmed(false);
    };

    const submitForm = async (clientValues: ClientInterface | any) => {
        let submitedData = {};
        if (!!formModalEditMode) {
            submitedData = clientValues.client;
        } else {
            submitedData = { ...formValues, ...clientValues };
        }
        setFormValues(submitedData);
        if (step < maxStep) {
            setStep(step + 1);
            return;
        }
        try {
            setErrorMessage(null);
            setSpinIt(true);
            const response = await saveFreshOrExistedClient(submitedData, formModalEditMode);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Client a été enregistré avec succès');
                const clientDATA = [...clients.items].filter((dd) => dd.idClient !== resultData.idClient);
                clientDATA.unshift(resultData);
                setClients({ items: clientDATA, count: clients.count });
                handleFormModalClose();
                setSpinIt(false);
                setConfirmed(true);
                // console.log('submit', submitedData);
            }
        } catch (Exception) {
            setSpinIt(false);
            const { message = undefined }: any = Exception;
            setErrorMessage(message || 'Error occured!');
        }
    };

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { active: true, text: 'Clients' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Clients
                </TitleElement>
                <Button
                    variant="outline-secondary"
                    className="button-input-group-effect"
                    id="basic-addon1"
                    onClick={() => handleFormModalOpen()}
                >
                    <FontAwesomeIcon icon={faPlus} size="xs" style={{ color: '#337ab7' }} />
                </Button>
            </div>
            <Datatable data={clients.items} tableColumns={tableHeaders}>
                <SearchInput
                    inputValue={inputValue}
                    inputChanged={inputChanged}
                    activePage={activePage}
                    totalPages={Math.ceil(clients.count / TOTAL_DATA_PER_PAGE)}
                    handlePageNFilterChange={handlePageNFilterChange}
                />
            </Datatable>
            <CustomModal
                title={`${!!formModalEditMode ? 'Edit' : 'Nouveau'} Client`}
                size="lg"
                show={showFormModal}
                handleClose={handleFormModalClose}
            >
                <Formik
                    validationSchema={schema[step]}
                    onSubmit={(values) => submitForm(values)}
                    enableReinitialize={true}
                    initialValues={{
                        client: {
                            idClient: currentClient && !!formModalEditMode ? currentClient.idClient : undefined,
                            raisonSocial: currentClient && !!formModalEditMode ? currentClient.raisonSocial : '',
                            addresse: currentClient && !!formModalEditMode ? currentClient.addresse : '',
                            telephoneBureau1:
                                currentClient && !!formModalEditMode ? currentClient.telephoneBureau1 : '',
                            telephoneBureau2:
                                currentClient && !!formModalEditMode ? currentClient.telephoneBureau2 : '',
                            rIB: currentClient && !!formModalEditMode ? currentClient.rIB : '',
                            siteWeb: currentClient && !!formModalEditMode ? currentClient.siteWeb : '',
                            pageFacebook: currentClient && !!formModalEditMode ? currentClient.pageFacebook : '',
                            pageInstagrame: currentClient && !!formModalEditMode ? currentClient.pageInstagrame : '',
                            etatCompte: currentClient && !!formModalEditMode ? currentClient.etatCompte : true,
                            idRegion: currentClient && !!formModalEditMode ? currentClient.idRegion : undefined,
                            idBanque: currentClient && !!formModalEditMode ? currentClient.idBanque : undefined,
                            echeancePaiement: currentClient && !!formModalEditMode ? currentClient.echeancePaiement : 3,
                            type: currentClient && !!formModalEditMode ? currentClient.type : 'Société',
                            cin: currentClient && !!formModalEditMode ? currentClient.cin : '',
                            ice: currentClient && !!formModalEditMode ? currentClient.ice : '',
                            if: currentClient && !!formModalEditMode ? currentClient.if : '',
                            idFournisseur:
                                currentClient && !!formModalEditMode
                                    ? currentClient.idFournisseur
                                    : contextUser &&
                                      (hasRole(contextUser.ROLES, RolesMap.FOURNISSEUR_ADMIN) ||
                                          hasRole(contextUser.ROLES, RolesMap.FOURNISSEUR_RECEPTIONISTE))
                                    ? contextUser.userData.idFournisseur!
                                    : undefined,
                        },
                        user: {
                            nom: '',
                            prenom: '',
                            gSM: '',
                            cIN: '',
                            email: '',
                            pwd: '',
                            type: 'Client',
                            etatCompte: true,
                            roles: [(rolesMap as any)[RolesMap.CLIENT_ADMIN]],
                        },
                    }}
                >
                    {(props: any) => (
                        <Form noValidate onSubmit={props.handleSubmit}>
                            <Stepper step={step} stepsCount={maxStep + 1} done={confirmed} />
                            <Fade timeout={500} in={!!errorMessage}>
                                {errorMessage ? <Alert variant="danger">{errorMessage}</Alert> : <></>}
                            </Fade>
                            {formData(step, props)}
                            <div className="d-flex justify-content-evenly">
                                {!confirmed && (
                                    <>
                                        {step > 0 && (
                                            <Button
                                                className="mt-3"
                                                variant="outline-dark"
                                                onClick={() => setStep(step - 1)}
                                                disabled={!!spinIt}
                                            >
                                                Prev
                                                {!!spinIt && (
                                                    <>
                                                        &nbsp; <Spinner animation="border" size="sm" />
                                                    </>
                                                )}
                                            </Button>
                                        )}
                                        <Button
                                            className="mt-3"
                                            variant={step < maxStep ? 'outline-primary' : 'outline-success'}
                                            type="submit"
                                            disabled={!!spinIt}
                                        >
                                            {step < maxStep ? 'Next' : 'Confirm'}
                                            {!!spinIt && (
                                                <>
                                                    &nbsp; <Spinner animation="border" size="sm" />
                                                </>
                                            )}
                                        </Button>
                                    </>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </CustomModal>
        </>
    );
};

export default ClientIndexPage;
