import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';

type Props = {
    borderColor?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light';
    textColor?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light';
    headerTitle: string;
    bodyTitle: string;
    bodyNumber: { number: number; options?: { style: string; currency: string } };
    bodyText?: string;
    bodyTextClassNames?:
        | 'text-primary'
        | 'text-secondary'
        | 'text-success'
        | 'text-danger'
        | 'text-warning'
        | 'text-info'
        | 'text-dark'
        | 'text-light';
};

const CardDashboard: React.FC<Props> = ({
    borderColor,
    textColor,
    headerTitle,
    bodyTitle,
    bodyNumber,
    bodyText,
    bodyTextClassNames,
}: Props) => {
    return (
        <Card border={borderColor} text={textColor}>
            <Card.Header>{headerTitle}</Card.Header>
            <Card.Body>
                <Card.Title>
                    <Row>
                        <Col>{bodyTitle}:</Col>
                        <Col auto className="text-end">
                            {bodyNumber.number.toLocaleString('fr-FR', bodyNumber.options)}
                        </Col>
                    </Row>
                </Card.Title>
                <Card.Text className={bodyTextClassNames}>{bodyText}</Card.Text>
            </Card.Body>
        </Card>
    );
};

export default CardDashboard;

export const colorsClasses = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light'];
export const colorsTextClasses = [
    'text-primary',
    'text-secondary',
    'text-success',
    'text-danger',
    'text-warning',
    'text-info',
    'text-dark',
    'text-light',
];

/*
    const lData = LineData({
        labels: months,
        title: 'Line Chart',
        data: [100, 200, 300, 500, 200, 300, 100, 400, 300, 800, 500, 100],
    });
    const bData = BarData({
        labels: months,
        title: 'Bar Chart',
        data: [200, 200, 100, 400, 300, 200, 200, 300, 400, 500, 600, 700],
    });
    const dData = DoughnutData({
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        title: 'Doughnut Chart',
        data: [12, 19, 3, 5, 2, 3],
    });
    const pData = PieData({
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        title: 'Pie Chart',
        data: [12, 19, 3, 5, 2, 3],
    });
*/
