import React, { useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import { ROLES as RolesMap } from 'constantes/permission-maps';

import { updateDemandeRetourLivreurRange } from 'services/demandeRetourLivreur.service';
import DemandeRetourLivreurInterface from 'types/interfaces/demandeRetourLivreur.interface';

type Props = {
    checkedDemandes: number[];
    close: () => void;
    status: string;
    setDemandes: (value: { items: DemandeRetourLivreurInterface[]; count: number }) => void;
};
const DemandeRetourLivreurSection: React.FC<Props> = ({ checkedDemandes, close, status, setDemandes }: Props) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);
    const [spinIt, setSpinIt] = useState<boolean>(false);
    const submitDemande = async (newStatus: string) => {
        try {
            setSpinIt(true);
            updateDemandeRetourLivreurRange({ ids: checkedDemandes, status: newStatus })
                .then(() => {
                    (
                        setDemandes as React.Dispatch<
                            React.SetStateAction<{ items: DemandeRetourLivreurInterface[]; count: number }>
                        >
                    )((prev: { items: DemandeRetourLivreurInterface[]; count: number }) => {
                        const updating = prev.items.filter(
                            (item: DemandeRetourLivreurInterface) =>
                                !checkedDemandes.includes(item.idDemandeRetourLivreur),
                        );
                        return {
                            items: updating,
                            count: prev.count,
                        };
                    });
                    toast.success('Demandes ont été modifiées avec succès.');
                    setSpinIt(false);
                    close();
                })
                .catch(() => {
                    setSpinIt(false);
                });
        } catch (Exception) {
            setSpinIt(false);
        }

        setSpinIt(false);
    };

    return (
        <div>
            <b>{checkedDemandes.length}</b> demendes(s) selectionnée(s)
            <p>
                Voulez-vous vraiment modifié l&apos;etat ces demandes de retour ?
                <div className="d-flex gap-3 justify-content-center mt-2">
                    {status === 'En attente' &&
                        contextUser?.userData?.userroles?.some(
                            (d) =>
                                d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                                d.role.nomRole === RolesMap.FOURNISSEUR_LIVREUR,
                        ) && (
                            <Button variant="outline-info" onClick={() => submitDemande('Traitée')} disabled={spinIt}>
                                Envoyer
                            </Button>
                        )}
                    {status === 'En attente' &&
                        contextUser?.userData?.userroles?.some(
                            (d) =>
                                d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                                d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE,
                        ) && (
                            <Button
                                variant="outline-warning"
                                onClick={() => submitDemande('Annulée')}
                                disabled={spinIt}
                            >
                                Annuler
                            </Button>
                        )}
                    {status === 'Traitée' &&
                        contextUser?.userData?.userroles?.some(
                            (d) =>
                                d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                                d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE,
                        ) && (
                            <Button
                                variant="outline-success"
                                onClick={() => submitDemande('Validée')}
                                disabled={spinIt}
                            >
                                Valider
                            </Button>
                        )}
                </div>
            </p>
        </div>
    );
};

export default DemandeRetourLivreurSection;
