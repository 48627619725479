import React, { Dispatch, SetStateAction } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, FormGroup, FormLabel, FormControl, Col, Form as FormRB, Row, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Datetime from 'react-datetime';
import moment from 'moment';

import { transferDraftCommandesRange } from 'services/draftCommande.service';
import DraftCommandeInterface from 'types/interfaces/draftCommande.interface';

// Yup Validation Schema
const validationSchema = Yup.object().shape({
    refColis: Yup.string().nullable(),
    commentaireEnvoi: Yup.string(),
    moyenTransport: Yup.string().required(),
    paye: Yup.boolean(),
    montantEnvoi: Yup.number().when('paye', {
        is: true,
        then: Yup.number().required(),
    }),
    dateRecuPrevu: Yup.date().required(),
    aOuvrir: Yup.boolean(),
    fragile: Yup.boolean(),
    aRecuperer: Yup.boolean(),
});
type Props = {
    ids: number[];
    close: () => void;
    clear: () => void;
    setList: Dispatch<SetStateAction<DraftCommandeInterface[]>>;
    idClient?: number;
};

const TransferDraftCommandesForm: React.FC<Props> = ({ ids, close, clear, setList, idClient }: Props) => {
    const submitForm = (values: any, { setSubmitting }: any) => {
        transferDraftCommandesRange(
            {
                idDraftCommandes: ids,
                transferWrapper: {
                    ...values,
                    dateRecuPrevu: values.dateRecuPrevu.toDate(),
                },
            },
            idClient,
        )
            .then(() => {
                toast.success('Les éléments sélectionnés ont été treansferés avec succès.');
                setList((prev: DraftCommandeInterface[]) =>
                    prev.filter((item: DraftCommandeInterface) => !ids.includes(item.idDraftCommande)),
                );
                clear();
                close();
            })
            .catch(() => {
                toast.error("Une erreur s'est produite.");
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <>
            <Alert variant="warning">
                <strong>Attention</strong> : ce transfert va créer un colis qui inclura ces commandes et les définira
                automatiquement comme envoyées.
                <br />
                Ces éléments disparaîtront de la liste actuelle et vous les trouverez dans les pages <b>
                    Commandes
                </b> et <b>Colis</b>. Veuillez vérifier avant de confirmer.
            </Alert>
            <Formik
                initialValues={{
                    refColis: '',
                    commentaireEnvoi: '',
                    moyenTransport: '',
                    montantEnvoi: 0,
                    paye: false,
                    dateRecuPrevu: moment(), // default to current date
                    aOuvrir: false,
                    fragile: false,
                    aRecuperer: false,
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => submitForm(values, { setSubmitting })}
            >
                {({ isSubmitting, values, errors, handleChange }) => (
                    <Form>
                        <FormGroup>
                            <FormLabel>Ref Colis</FormLabel>
                            <Field as={FormControl} name="refColis" />
                            <ErrorMessage name="refColis" component="div" />
                        </FormGroup>

                        <FormGroup>
                            <FormLabel>Commentaire Envoi</FormLabel>
                            <Field as={FormControl} name="commentaireEnvoi" />
                            <ErrorMessage name="commentaireEnvoi" component="div" />
                        </FormGroup>

                        <FormGroup>
                            <FormRB.Label>Moyen de Transport</FormRB.Label>
                            <FormRB.Control
                                size="sm"
                                type="text"
                                name="moyenTransport"
                                placeholder="Moyen de Transport"
                                value={values.moyenTransport}
                                onChange={handleChange}
                                isInvalid={!!errors.moyenTransport}
                            />
                        </FormGroup>
                        <Row>
                            <FormGroup as={Col} md="6" controlId="form_grp_paye">
                                <FormLabel>Payé</FormLabel>
                                <FormRB.Check
                                    type="checkbox"
                                    name="paye"
                                    checked={values.paye}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleChange({
                                            target: {
                                                value: 0,
                                                name: 'montantEnvoi',
                                            },
                                        });
                                    }}
                                    isInvalid={!!errors.paye}
                                />
                            </FormGroup>
                            <FormGroup as={Col} md="6" controlId="form_grp_montentEnvoi">
                                <FormLabel>Montant d&apos;envoi</FormLabel>
                                <FormControl
                                    size="sm"
                                    type="number"
                                    min={0}
                                    name="montantEnvoi"
                                    placeholder="Montant d'envoi"
                                    value={values.montantEnvoi}
                                    onChange={handleChange}
                                    isInvalid={!!errors.montantEnvoi}
                                    readOnly={!values.paye}
                                />
                            </FormGroup>
                        </Row>
                        <FormGroup className="mb-3">
                            <FormLabel>Date Reçu Prévu</FormLabel>

                            <Datetime
                                className="form-control form-control-sm no_form-control_input_style"
                                inputProps={{
                                    name: 'dateRecuPrevu',
                                    className: `form-control form-control-sm ${
                                        !!errors.dateRecuPrevu ? 'is-invalid' : ''
                                    }`,
                                    readOnly: true,
                                }}
                                timeFormat={false}
                                dateFormat="DD/MM/YYYY"
                                value={values.dateRecuPrevu}
                                onChange={(dateValue: any) => {
                                    const e = {
                                        target: {
                                            value: dateValue,
                                            name: 'dateRecuPrevu',
                                        },
                                    };
                                    if (moment.isMoment(dateValue)) handleChange(e);
                                }}
                                closeOnSelect={true}
                            />
                            <ErrorMessage name="dateRecuPrevu" component="div" />
                        </FormGroup>

                        <Row className="mb-3">
                            <FormGroup as={Col} md="4">
                                <Field type="checkbox" name="aOuvrir" />
                                <FormLabel className="ms-2">À Ouvrir</FormLabel>
                            </FormGroup>
                            <FormGroup as={Col} md="4">
                                <Field type="checkbox" name="fragile" />
                                <FormLabel className="ms-2">Fragile</FormLabel>
                            </FormGroup>
                            <FormGroup as={Col} md="4">
                                <Field type="checkbox" name="aRecuperer" />
                                <FormLabel className="ms-2">À Récupérer</FormLabel>
                            </FormGroup>
                        </Row>

                        <div className="d-flex justify-content-end gap-2">
                            <Button variant="secondary" onClick={close}>
                                Annuler
                            </Button>
                            <Button type="submit" variant="primary" disabled={isSubmitting}>
                                Confirmer
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default TransferDraftCommandesForm;
