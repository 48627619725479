import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import OptionServiceInterface from 'types/interfaces/optionService.interface';

const apiEndPoint = `${apiUrl}/optionServices`;

export const getOptionServices = (): Promise<OptionServiceInterface[] | any> => {
    return http.get(apiEndPoint);
};

export const saveOptionService = (optionService: OptionServiceInterface) => {
    const body = {
        ...optionService,
    };
    if (optionService.idOptionService) {
        return http.put(`${apiEndPoint}/${optionService.idOptionService}`, body);
    }
    return http.post(`${apiEndPoint}`, body);
};
