import React from 'react';
import QRCode from 'react-qr-code';
import { CSSColorTYPE } from 'types';

type Props = {
    value: string;
    options?: {
        title?: string;
        bgColor?: CSSColorTYPE;
        fgColor?: CSSColorTYPE;
        level?: 'L' | 'M' | 'Q' | 'H';
        size?: number;
    };
};

const QrCode: React.FC<Props> = ({ value, options }: Props) => <QRCode value={value} {...options} />;

export default QrCode;
