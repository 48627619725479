import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import CommissionsLivreurInterface, {
    CommissionsLivreurFormDataRequest,
} from 'types/interfaces/commissionsLivreur.interface';

const apiEndPoint = `${apiUrl}/commissionsLivreurs`;

export const getCommissionsLivreursFiltered = (
    total: number,
    page: number,
    idLivreur?: number | string,
    idVille?: number | string,
): Promise<{ count: number; items: CommissionsLivreurInterface[] } | any> => {
    const query = `?page=${page}&total=${total}${idLivreur ? '&idLivreur=' + idLivreur : ''}${
        idVille ? '&idVille=' + idVille : ''
    }`;
    return http.get(`${apiEndPoint}/filter${query}`);
};

export const GetCommissionsByLivreur = (
    idLivreur?: number | string,
): Promise<{ count: number; items: CommissionsLivreurInterface[] } | any> => {
    return http.get(`${apiEndPoint}/byLivreur/${idLivreur}`);
};

export const createMultipleCommissionsLivreur = (
    formData: CommissionsLivreurFormDataRequest,
    idLivreur?: number | string,
    idVille?: number | string,
) => {
    let queryString = '';
    if (idLivreur !== undefined) {
        queryString += `?idLivreur=${idLivreur}`;
    }
    if (idVille !== undefined) {
        queryString += `${queryString ? '&' : '?'}idVille=${idVille}`;
    }
    return http.post(`${apiEndPoint}${queryString}`, formData);
};
