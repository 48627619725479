import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { Table } from 'react-bootstrap';

import Printer from 'components/shared/printer';
import SiteLogo from 'components/UI/siteLogo';
import DraftCommandeInterface from 'types/interfaces/draftCommande.interface';

import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import { Deserialize } from 'pages/DraftCommande/utils';
import { AcheteurCommandeDraftResponse, ProduitCommandeDraftResponse } from 'types/interfaces/draftCommande.interface';

interface Props {
    drafts: DraftCommandeInterface[];
}

const PrintDrafts: React.FC<Props> = ({ drafts }: Props) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);
    return (
        <Printer documentTitle="Paquet Personnalisé">
            <>
                <div className="d-flex justify-content-between m-1">
                    <SiteLogo />
                    <div className="text-end">
                        <div> Le {dayjs().format('DD/MM/YYYY HH:mm')}</div>
                        <div>
                            <span className="my-xsm-font">
                                <b>{drafts.length}</b> au total
                            </span>
                        </div>
                    </div>
                </div>
                <div className="m-2 d-flex justify-content-between">
                    <h6>Paquet Personnalisé</h6>
                    <div>
                        <b>{contextUser?.userData.client?.raisonSocial!}</b>
                    </div>
                </div>
                <hr style={{ margin: 0 }} />

                <Table bordered hover striped>
                    <thead>
                        <tr>
                            <th>Référence</th>
                            <th colSpan={2}>Acheteur</th>
                            <th colSpan={3}>Produit</th>
                            <th>Prix Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {drafts.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <span className="my-xsm-font">{item.ref}</span>
                                </td>
                                <td>
                                    <span className="my-xsm-font">
                                        {Deserialize<AcheteurCommandeDraftResponse>(item.acheteurJson).fullName}
                                    </span>{' '}
                                </td>
                                <td>
                                    <span className="my-xsm-font">
                                        {Deserialize<AcheteurCommandeDraftResponse>(item.acheteurJson).gsm}
                                    </span>
                                </td>
                                <td>
                                    <span className="my-xsm-font">
                                        {Deserialize<ProduitCommandeDraftResponse>(item.produitJson).designation}
                                    </span>{' '}
                                </td>
                                <td>
                                    <span className="my-xsm-font">
                                        {Deserialize<ProduitCommandeDraftResponse>(item.produitJson).quantity ?? 1}
                                    </span>{' '}
                                </td>
                                <td>
                                    <span className="my-xsm-font">
                                        {Deserialize<ProduitCommandeDraftResponse>(item.produitJson).prixVente} DH
                                    </span>
                                </td>
                                <td>
                                    <span className="my-xsm-font fw-bolder">{item.prixTotal} DH</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </>
        </Printer>
    );
};

export default PrintDrafts;
