import React from 'react';
import moment from 'moment';
import { AxiosResponse } from 'axios';
import { Col, Form, InputGroup, Row, OverlayTrigger, Tooltip, Image } from 'react-bootstrap';

import { getColisRecuFileById } from 'services/colisRecuFile.service';
import CommandeInterface from 'types/interfaces/commande.interface';
import ColisRecuFileInterface from 'types/interfaces/colisRecuFile.interface';
import ImageElement from 'components/UI/imageElement';
import { DisplayName, readImageTobase64 } from 'utils/helpers';

type Props = {
    currentCommande: CommandeInterface;
    setCurrentCommande: (value: CommandeInterface | null) => void;
    setCommandes: (value: { items: CommandeInterface[]; count: number }) => void;
    commandes: { items: CommandeInterface[]; count: number };
};
const CommandeFactureInfo: React.FC<Props> = ({
    currentCommande,
    setCurrentCommande,
    setCommandes,
    commandes,
}: Props) => {
    const loadPaiementImageRecu = (id: number) => {
        getColisRecuFileById(id)
            .then((response: AxiosResponse<ColisRecuFileInterface | any>) => {
                const { data } = response;
                const cmdData = [...commandes.items];
                commandes.items
                    .filter((d: CommandeInterface) => currentCommande?.facture?.idFacture! === d.idFacture)
                    .forEach((element: CommandeInterface) => {
                        const cmdIndex = cmdData.findIndex(
                            (di: CommandeInterface) => di.idCommande === element.idCommande!,
                        );
                        if (cmdData[cmdIndex].idFacture && cmdData[cmdIndex].facture?.paiement)
                            cmdData[cmdIndex].facture!.paiement!.recuFile = data;
                    });
                const cmdIndex = cmdData.findIndex(
                    (d: CommandeInterface) => d.idCommande == currentCommande?.idCommande!,
                );
                if (cmdData[cmdIndex].idFacture && cmdData[cmdIndex].facture?.paiement)
                    cmdData[cmdIndex].facture!.paiement!.recuFile = data;
                setCurrentCommande(cmdData[cmdIndex]);
                setCommandes({ items: cmdData, count: commandes.count });
            })
            .catch();
    };

    return (
        <>
            <Row>
                <Col>
                    <h5>Facture:</h5>
                </Col>
            </Row>
            <hr />
            <div className="d-grid gap-2 mt-3">
                <Row>
                    <Col md="3" lg="3">
                        <InputGroup className="align-items-center" size="sm">
                            Réf: &nbsp;
                            <Form.Control
                                className="text-end"
                                size="sm"
                                type="text"
                                name="refFacture"
                                value={currentCommande.facture?.refFacture!}
                                onChange={() => void 0}
                                disabled
                            />
                        </InputGroup>
                    </Col>
                    <Col md="3" lg="3">
                        <InputGroup className="align-items-center" size="sm">
                            Date: &nbsp;
                            <Form.Control
                                className="text-end"
                                size="sm"
                                type="text"
                                name="refFacture"
                                value={moment(currentCommande.facture?.dateFacture!).format('DD/MM/YYYY')}
                                onChange={() => void 0}
                                disabled
                            />
                        </InputGroup>
                    </Col>
                    <Col md="3" lg="3">
                        <InputGroup className="align-items-center" size="sm">
                            Montant: &nbsp;
                            <Form.Control
                                className="text-end"
                                size="sm"
                                type="text"
                                name="montantFacture"
                                value={currentCommande.facture?.montantFacture!}
                                onChange={() => void 0}
                                disabled
                            />
                            <InputGroup.Text>DH</InputGroup.Text>
                        </InputGroup>
                    </Col>
                    <Col md="3" lg="3">
                        <InputGroup className="align-items-center" size="sm">
                            Remise Accorde: &nbsp;
                            <Form.Control
                                className="text-end"
                                size="sm"
                                type="text"
                                name="remiseAccorde"
                                value={currentCommande.facture?.remiseAccorde!}
                                onChange={() => void 0}
                                disabled
                            />
                            <InputGroup.Text>DH</InputGroup.Text>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="justify-content-end">
                    <Col md="3" lg="3">
                        <InputGroup className="align-items-center" size="sm">
                            Agent: &nbsp;
                            <Form.Control
                                size="sm"
                                type="text"
                                name="agent"
                                value={DisplayName(currentCommande.facture?.agent!)}
                                onChange={() => void 0}
                                disabled
                            />
                        </InputGroup>
                    </Col>
                </Row>
                {currentCommande.facture?.paiement && (
                    <>
                        <Row>
                            <Col>
                                <h5>Paiement:</h5>
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3" lg="3">
                                <InputGroup className="align-items-center" size="sm">
                                    Num Piece: &nbsp;
                                    <Form.Control
                                        className="text-end"
                                        size="sm"
                                        type="text"
                                        name="numPiece"
                                        value={currentCommande.facture?.paiement?.numPiece}
                                        onChange={() => void 0}
                                        disabled
                                    />
                                </InputGroup>
                            </Col>
                            <Col md="3" lg="3">
                                <InputGroup className="align-items-center" size="sm">
                                    Date: &nbsp;
                                    <Form.Control
                                        className="text-end"
                                        size="sm"
                                        type="text"
                                        name="datePaiement"
                                        value={moment(currentCommande.facture?.paiement?.datePaiement).format(
                                            'DD/MM/YYYY',
                                        )}
                                        onChange={() => void 0}
                                        disabled
                                    />
                                </InputGroup>
                            </Col>
                            <Col md="3" lg="3">
                                <InputGroup className="align-items-center" size="sm">
                                    Montant: &nbsp;
                                    <Form.Control
                                        className="text-end"
                                        size="sm"
                                        type="text"
                                        name="montantPaye"
                                        value={currentCommande.facture?.paiement?.montantPaye!}
                                        onChange={() => void 0}
                                        disabled
                                    />
                                    <InputGroup.Text>DH</InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col md="3" lg="3">
                                <InputGroup className="align-items-center" size="sm">
                                    Mode de paiement: &nbsp;
                                    <Form.Control
                                        className="text-end"
                                        size="sm"
                                        type="text"
                                        name="modepaiement"
                                        value={
                                            currentCommande.facture?.paiement?.modepaiement?.descriptionModePaiement!
                                        }
                                        onChange={() => void 0}
                                        disabled
                                    />
                                    <InputGroup.Text>DH</InputGroup.Text>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ span: 3, offset: 3 }} lg={{ span: 3, offset: 3 }}>
                                <InputGroup className="align-items-center" size="sm">
                                    Agent: &nbsp;
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        name="paiementagent"
                                        value={DisplayName(currentCommande.facture?.paiement?.agent!)}
                                        onChange={() => void 0}
                                        disabled
                                    />
                                </InputGroup>
                            </Col>
                            <Col md="6" lg="6">
                                {currentCommande.facture?.paiement?.idRecuFile ? (
                                    <>
                                        <h6>Paiement Récu :</h6>
                                        {currentCommande.facture?.paiement?.recuFile ? (
                                            <ImageElement
                                                file={readImageTobase64(
                                                    currentCommande.facture?.paiement?.recuFile.fileImage,
                                                )}
                                                alt="file recu"
                                                fluid
                                            />
                                        ) : (
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="tooltip-image-preview">Afficher le recu.</Tooltip>
                                                }
                                            >
                                                <Image
                                                    src="/images/before-preview.jpg"
                                                    thumbnail
                                                    onClick={() =>
                                                        loadPaiementImageRecu(
                                                            currentCommande.facture?.paiement?.idRecuFile!,
                                                        )
                                                    }
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </OverlayTrigger>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Col>
                        </Row>
                    </>
                )}
            </div>
        </>
    );
};

export default CommandeFactureInfo;
