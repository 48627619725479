// transfertService.ts
import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';

import TransfertCommandeResponse from 'types/interfaces/transfertCommande.interface';
import TransfertFactureResponse from 'types/interfaces/transfertFacture.interface';

const apiEndPoint = `${apiUrl}/transferts`;

export const getTransfertToReceive = (): Promise<TransfertCommandeResponse[] | any> => {
    return http.get(`${apiEndPoint}/receive`);
};

export const getTransfertToCancel = (): Promise<TransfertCommandeResponse[] | any> => {
    return http.get(`${apiEndPoint}/to-cancel`);
};

export const getTransfertToReturn = (): Promise<TransfertCommandeResponse[] | any> => {
    return http.get(`${apiEndPoint}/to-return`);
};

export const getReturnedTransfertToConfirmReturning = (): Promise<TransfertCommandeResponse[] | any> => {
    return http.get(`${apiEndPoint}/return-confirmation`);
};

export const postReturn = (idCommande: number): Promise<TransfertCommandeResponse[] | any> => {
    return http.post(`${apiEndPoint}/return/${idCommande}`);
};

export const putReturn = (id: number): Promise<TransfertCommandeResponse | any> => {
    return http.put(`${apiEndPoint}/return/${id}`);
};

export const putReceived = (id: number): Promise<TransfertCommandeResponse | any> => {
    return http.put(`${apiEndPoint}/received/${id}`);
};

export const putReturnConfirmed = (id: number): Promise<void> => {
    return http.put(`${apiEndPoint}/return-confirmed/${id}`);
};

export const putCancel = (id: number): Promise<void> => {
    return http.put(`${apiEndPoint}/cancel/${id}`);
};

export const getTransfertCommandeToFacture = (idf: number): Promise<TransfertCommandeResponse[] | any> => {
    return http.get(`${apiEndPoint}/to-facture/${idf}`);
};

export const createFacture = (transfertFactureCreation: { ids: number[] }): Promise<TransfertFactureResponse | any> => {
    return http.post(`${apiEndPoint}/facture`, transfertFactureCreation);
};

export const postFacturePaiement = (transfertFacturePaiement: {
    id: number;
    fileImage: string;
}): Promise<TransfertFactureResponse | any> => {
    return http.post(`${apiEndPoint}/facture/paiement`, transfertFacturePaiement);
};

export const puttConfirmFacturePaiement = (tfpcFormData: {
    idTransfertFacture: number;
}): Promise<TransfertFactureResponse | any> => {
    return http.put(`${apiEndPoint}/facture/paiement`, tfpcFormData);
};

export const getFactures = (
    dateDebut?: Date | string,
    dateFin?: Date | string,
    status?: string,
    page?: number,
    total?: number,
): Promise<TransfertFactureResponse[] | any> => {
    const query =
        `${apiEndPoint}/factures?` +
        new URLSearchParams({
            dateDebut: dateDebut ? dateDebut.toString() : '',
            dateFin: dateFin ? dateFin.toString() : '',
            status: status ?? '',
            page: page?.toString() ?? '',
            total: total?.toString() ?? '',
        });
    return http.get(query);
};

export const getTransfertCommandeByCodeOrRefForAction = (
    codeOrRef: string,
    action: string,
): Promise<TransfertCommandeResponse | any> => {
    return http.get(`${apiEndPoint}/scan/byCodeRef/${codeOrRef}/${action}`);
};

export const putMassAction = (data: { ids: number[]; action: string }): Promise<TransfertCommandeResponse | any> => {
    return http.put(`${apiEndPoint}/mass/action`, data);
};
