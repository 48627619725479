import React, { ReactNode } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

type RecaptchaComponentProps = {
    children: ReactNode;
};

const RecaptchaComponent: React.FC<RecaptchaComponentProps> = ({ children }: RecaptchaComponentProps) => {
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
            scriptProps={{
                async: false,
                defer: false,
                appendTo: 'head',
                nonce: undefined,
            }}
        >
            {children}
        </GoogleReCaptchaProvider>
    );
};

export default RecaptchaComponent;
