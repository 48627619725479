import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import EtatcommandeInterface from 'types/interfaces/etatCommande.interface';

const apiEndPoint = `${apiUrl}/etatcommandes`;

export const getEtatCommandes = (): Promise<EtatcommandeInterface[] | any> => {
    return http.get(apiEndPoint);
};

export const saveEtatCommande = (etatcommande: EtatcommandeInterface) => {
    const body = {
        ...etatcommande,
    };
    if (etatcommande.idEtatCommande) {
        return http.put(`${apiEndPoint}/${etatcommande.idEtatCommande}`, body);
    }
    return http.post(`${apiEndPoint}`, body);
};
