import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { Col, Form, Row, Button, OverlayTrigger, Tooltip, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckDouble, faEdit, faPrint } from '@fortawesome/free-solid-svg-icons';
import { ROLES as RolesMap } from 'constantes/permission-maps';

import { useQueryMeMo, isEmptyObject, buildUrlQueryParams, b64_to_utf8, utf8_to_b64, DisplayName } from 'utils/helpers';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import TableTemplate from 'components/shared/datatable/tableTemplate';
import Paginator from 'components/UI/paginator';

import ClientInterface from 'types/interfaces/client.interface';

import { getClientsByIdFournisseur } from 'services/client.service';

import { getDemandeRetourStocks } from 'services/demandeRetourStock.service';

import TitleElement from 'components/UI/titleElement';
import PermissionsGate from 'hoc/permissionsGate';
import CustomModal from 'components/UI/customModal';
import DemandeRetourStockInterface from 'types/interfaces/demandeRetourStock.interface';
import DemandeRetourStockSection from 'pages/Stock/components/DemandeRetourStockSection';
import PrintDemandeRetour from 'pages/Stock/components/PrintDemandeRetour';

const TOTAL_DATA_PER_PAGE = 100;

const DemandeRetourStocksPage: React.FC = () => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    const [demandes, setDemandes] = useState<{ items: DemandeRetourStockInterface[]; count: number }>({
        items: [],
        count: 0,
    });

    const [clients, setClients] = useState<ClientInterface[]>([]);

    const [selectedClient, setSelectedClient] = useState<string | null>(null);
    const [selectedStatus, setSelectedStatus] = useState<string>('');

    const [activePage, setActivePage] = useState<number>(1);

    const query = useQueryMeMo();
    const history = useNavigate();

    const [showMassModal, setShowMassModal] = useState<boolean>(false);
    const [checkedDemandes, setCheckedDemandes] = useState<number[]>([]);
    const [checkedPrintingDemandes, setCheckedPrintingDemandes] = useState<number[]>([]);
    const [showPrintModal, setShowPrintModal] = useState<boolean>(false);

    const handlePrintDemandesClose = () => {
        setShowPrintModal(false);
        setCheckedPrintingDemandes([]);
    };

    const handlePrintDemandesOpen = () => {
        setShowPrintModal(true);
    };

    const handleMassModalClose = () => {
        setShowMassModal(false);
        setCheckedDemandes([]);
        setCheckedPrintingDemandes([]);
    };

    const handleMassModalOpen = () => {
        setShowMassModal(true);
    };

    const onCheckPrintingAllDemandes = useCallback(() => {
        setCheckedPrintingDemandes((oldState) => {
            if (
                oldState.length === demandes.items.filter((item: DemandeRetourStockInterface) => !item.dateFin!).length
            ) {
                return [];
            }

            return demandes.items
                .filter((item: DemandeRetourStockInterface) => !item.dateFin!)
                .map((el: DemandeRetourStockInterface) => el.idDemandeRetourStock);
        });
    }, [demandes.items]);

    const onCheckPrintingDemandesrow = useCallback(
        (row: DemandeRetourStockInterface) => {
            setCheckedPrintingDemandes((oldState) => {
                const idx = oldState.findIndex((el: number) => el === row.idDemandeRetourStock);

                if (row.dateFin!) {
                    return oldState;
                }

                if (idx === -1) {
                    return oldState.concat(row.idDemandeRetourStock);
                }

                const newState = [...oldState];
                newState.splice(idx, 1);

                return newState;
            });
        },
        [demandes.items],
    );

    const onCheckAllDemandes = useCallback(() => {
        setCheckedDemandes((oldState) => {
            if (
                oldState.length ===
                demandes.items.filter(
                    (item: DemandeRetourStockInterface) =>
                        !item.dateFin! &&
                        (item.fromStock === 'YES' ||
                            (item.fromStock !== 'YES' && item.commande?.transfertCommandes?.length === 0)),
                ).length
            ) {
                return [];
            }

            return demandes.items
                .filter(
                    (item: DemandeRetourStockInterface) =>
                        !item.dateFin! &&
                        (item.fromStock === 'YES' ||
                            (item.fromStock !== 'YES' && item.commande?.transfertCommandes?.length === 0)),
                )
                .map((el: DemandeRetourStockInterface) => el.idDemandeRetourStock);
        });
    }, [demandes.items]);

    const onCheckDemandesrow = useCallback(
        (row: DemandeRetourStockInterface) => {
            setCheckedDemandes((oldState) => {
                const idx = oldState.findIndex((el: number) => el === row.idDemandeRetourStock);

                if (row.dateFin! || (row.fromStock !== 'YES' && row.commande?.transfertCommandes?.length !== 0)) {
                    return oldState;
                }

                if (idx === -1) {
                    return oldState.concat(row.idDemandeRetourStock);
                }

                const newState = [...oldState];
                newState.splice(idx, 1);

                return newState;
            });
        },
        [demandes.items],
    );

    const loadDemandes = (total: number, page: number, status: string, idClient?: string) => {
        getDemandeRetourStocks(total, page, status, +idClient!)
            .then((response: AxiosResponse<any[] | any>) => {
                const { data } = response;
                setDemandes({
                    items: data.items,
                    count: data.count,
                });
            })
            .catch();
    };

    const handlePageNFilterChange = (activePage: number, searchedData?: string) => {
        const urlObject: any = {};
        if (activePage > 1) urlObject['page'] = activePage;
        if (searchedData) {
            const parsedObject = JSON.parse(searchedData);
            Object.keys(parsedObject).forEach((key) => {
                if (parsedObject[key as any]) {
                    if (key === 'client' && parsedObject[key as any])
                        urlObject[key] = utf8_to_b64(parsedObject[key as any]);
                    else urlObject[key] = parsedObject[key as any];
                }
            });
        }
        history(isEmptyObject(urlObject) ? window.location.pathname : `?${buildUrlQueryParams(urlObject)}`);
    };

    useEffect(() => {
        document.title = `Demandes de retour de stock - DS`;
    }, []);

    useEffect(() => {
        if (contextUser && !contextUser?.userData?.userroles?.some((d) => d.role.nomRole === RolesMap.CLIENT_ADMIN))
            getClientsByIdFournisseur(contextUser.userData.idFournisseur!)
                .then((response: AxiosResponse<ClientInterface[] | any>) => {
                    const { data } = response;
                    setClients(data);
                })
                .catch();
    }, [contextUser]);

    useEffect(() => {
        setCheckedDemandes([]);
        setCheckedPrintingDemandes([]);
        const page = query.get('page');
        const client = query.get('client');
        const status = query.get('status') ?? '';
        const currentPage = page ? +page : 1;
        const currentClient = client ? b64_to_utf8(client) : '';
        setActivePage(currentPage);
        setSelectedStatus(status);
        setSelectedClient(currentClient!);
        const loadData = async () => {
            loadDemandes(TOTAL_DATA_PER_PAGE, currentPage, status, currentClient);
        };
        loadData();
    }, [query, selectedClient]);

    const tableHeaders = useMemo(() => {
        const columns = [
            ...(['En attente'].includes(selectedStatus) &&
            contextUser?.userData?.userroles?.some(
                (d) =>
                    d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                    d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE,
            )
                ? [
                      {
                          Header: () => (
                              <input
                                  type="checkbox"
                                  checked={
                                      checkedDemandes.length ===
                                          demandes.items.filter((item) => !item.dateFin).length &&
                                      checkedDemandes.length !== 0
                                  }
                                  onChange={onCheckAllDemandes}
                              />
                          ),
                          accessor: 'checkbox',
                          Cell: ({ row }: any) => (
                              <>
                                  {!row.original.dateFin &&
                                      (row.original.fromStock === 'YES' ||
                                          (row.original.fromStock !== 'YES' &&
                                              row.original.commande?.transfertCommandes?.length === 0)) && (
                                          <input
                                              type="checkbox"
                                              checked={checkedDemandes.includes(row.original.idDemandeRetourStock)}
                                              onChange={() => onCheckDemandesrow(row.original)}
                                          />
                                      )}
                              </>
                          ),
                          disableSortBy: true,
                          disableFilters: true,
                      },
                  ]
                : []),
            ...(contextUser?.userData?.userroles?.some((d) => d.role.nomRole === RolesMap.CLIENT_ADMIN)
                ? []
                : [
                      {
                          Header: 'Client',
                          accessor: 'client.raisonSocial',
                      },
                  ]),
            {
                Header: 'Demande',
                accessor: 'codeDemande',
                Cell: ({ value }: any) => <span className="my-xsm-font">{value}</span>,
            },
            {
                Header: 'Produit Ref',
                accessor: 'produit.ref',
            },
            {
                Header: 'Produit Designation',
                accessor: 'produit.designation',
            },
            {
                Header: 'Quantité',
                accessor: 'qte',
            },
            {
                Header: 'Info',
                accessor: 'produit-info',
                Cell: ({ row }: any) => (
                    <span className="my-xsm-font">
                        Colis: &nbsp;
                        <b>
                            {row.original.produit.colisproduitenvoyes &&
                            row.original.produit.colisproduitenvoyes.length > 0
                                ? row.original.produit.colisproduitenvoyes[0].colis.refColis
                                : '-'}
                        </b>
                        <br />
                        Commande: &nbsp;
                        <b>{row.original.commande ? row.original.commande.code : '-'}</b>
                        <br />
                        Acheteur: &nbsp;
                        <b>{row.original.commande ? row.original.commande.acheteur.fullName : '-'}</b>
                    </span>
                ),
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: 'Date demande',
                accessor: 'dateCreation',
                Cell: ({ row }: any) => (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="t2">Créée par {DisplayName(row.original.userClient)}</Tooltip>}
                    >
                        <div>{moment(row.original.dateCreation).format('DD/MM/YYYY HH:mm')}</div>
                    </OverlayTrigger>
                ),
                disableSortBy: true,
            },
            {
                Header: 'Etat',
                accessor: 'status',
            },
            {
                Header: 'Traité le',
                accessor: 'dateFin',
                Cell: ({ row }: any) => (
                    <>
                        {row.original.dateFin ? (
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="t1">Traitée par {DisplayName(row.original.userFournisseur)}</Tooltip>
                                }
                            >
                                <div>{moment(row.original.dateFin).format('DD/MM/YYYY HH:mm')}</div>
                            </OverlayTrigger>
                        ) : (
                            '-'
                        )}
                    </>
                ),
                disableSortBy: true,
            },
            ...(['En attente', ''].includes(selectedStatus)
                ? [
                      {
                          Header: () => (
                              <>
                                  <InputGroup size="sm" className="justify-content-center">
                                      <Button
                                          variant="outline-dark"
                                          size="sm"
                                          onClick={() => onCheckPrintingAllDemandes()}
                                      >
                                          <FontAwesomeIcon
                                              icon={
                                                  checkedPrintingDemandes.length ===
                                                      demandes.items.filter((item) => !item.dateFin).length &&
                                                  checkedPrintingDemandes.length !== 0
                                                      ? faCheck
                                                      : faCheckDouble
                                              }
                                              size="xs"
                                          />
                                      </Button>
                                      {checkedPrintingDemandes.length > 0 && (
                                          <>
                                              <Button
                                                  variant="outline-secondary"
                                                  size="sm"
                                                  onClick={() => handlePrintDemandesOpen()}
                                              >
                                                  <FontAwesomeIcon icon={faPrint} size="xs" />
                                              </Button>
                                          </>
                                      )}
                                  </InputGroup>
                              </>
                          ),
                          accessor: 'checkbox2',
                          Cell: ({ row }: any) => (
                              <>
                                  {!row.original.dateFin && (
                                      <input
                                          type="checkbox"
                                          checked={checkedPrintingDemandes.includes(row.original.idDemandeRetourStock)}
                                          onChange={() => onCheckPrintingDemandesrow(row.original)}
                                      />
                                  )}
                              </>
                          ),
                          disableSortBy: true,
                          disableFilters: true,
                      },
                  ]
                : []),
        ];

        return columns;
    }, [
        demandes,
        checkedDemandes,
        onCheckAllDemandes,
        onCheckDemandesrow,
        checkedPrintingDemandes,
        onCheckPrintingAllDemandes,
        onCheckPrintingDemandesrow,
    ]);

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        {
            active: true,
            text: 'Etat de stockage',
        },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Demande retour stock
                </TitleElement>
            </div>
            <Row className="mb-2 holdit_notEffect">
                {!contextUser?.userData?.userroles?.some((d) => d.role.nomRole === RolesMap.CLIENT_ADMIN) && (
                    <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_Client">
                        <Form.Label>Client:</Form.Label>
                        <Form.Select
                            size="sm"
                            name="selectedClient"
                            value={selectedClient!}
                            onChange={(e: any) => {
                                const {
                                    target: { value },
                                } = e;
                                setSelectedClient(value);
                                handlePageNFilterChange(
                                    1,
                                    JSON.stringify({
                                        client: value,
                                        status: selectedStatus,
                                    }),
                                );
                            }}
                            placeholder="Selectionner un client"
                        >
                            <option value="">Selectionner un client</option>
                            {clients.map((d: ClientInterface) => (
                                <option key={d.idClient} value={d.idClient}>
                                    {d.raisonSocial}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                )}
                <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_status">
                    <Form.Label>Etat:</Form.Label>
                    <Form.Select
                        size="sm"
                        name="selectedStatus"
                        value={selectedStatus!}
                        onChange={(e: any) => {
                            const {
                                target: { value },
                            } = e;
                            setSelectedStatus(value);
                            handlePageNFilterChange(
                                1,
                                JSON.stringify({
                                    client: selectedClient,
                                    status: value,
                                }),
                            );
                        }}
                        placeholder="Selectionner une etat"
                    >
                        <option value="">Tous</option>
                        <option value="En attente">En attente</option>
                        <option value="Traitée">Traitée</option>
                        <option value="Rejetée">Rejetée</option>
                    </Form.Select>
                </Form.Group>
                {checkedDemandes.length > 0 && (
                    <Col className="align-self-center d-flex justify-content-end">
                        <PermissionsGate
                            scopes={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_RECEPTIONISTE]}
                            strict
                        >
                            <Button
                                variant="outline-secondary"
                                className="button-input-group-effect gap-5marginpx"
                                id="basic-addon1"
                                onClick={() => handleMassModalOpen()}
                            >
                                <FontAwesomeIcon icon={faEdit} size="xs" style={{ color: '#337ab7' }} />
                            </Button>
                        </PermissionsGate>
                    </Col>
                )}
            </Row>
            <TableTemplate
                columns={tableHeaders}
                data={demandes.items}
                options={{
                    pagination: true,
                    showGlobalFilter: true,
                    debugData: false,
                    showFiltersRowByColumn: true,
                    rowSelecting: false,
                    hiddenColumns: ['userId'],
                    autoGrouping: { columnId: 'codeDemande' },
                }}
            >
                <Row>
                    <Col className="d-flex flex-col justify-content-end align-items-end">
                        <Paginator
                            defaultPage={activePage}
                            totalPages={Math.ceil(demandes.count / TOTAL_DATA_PER_PAGE)}
                            callback={(e_page) =>
                                handlePageNFilterChange(
                                    e_page,
                                    JSON.stringify({
                                        client: selectedClient,
                                        status: selectedStatus,
                                    }),
                                )
                            }
                        />
                    </Col>
                </Row>
            </TableTemplate>
            <CustomModal title="Demande retour stock" size="lg" show={showMassModal} handleClose={handleMassModalClose}>
                <DemandeRetourStockSection
                    checkedDemandes={checkedDemandes}
                    close={handleMassModalClose}
                    setDemandes={setDemandes}
                />
            </CustomModal>
            <CustomModal
                title="Imprimer les demandes"
                size="lg"
                show={showPrintModal}
                handleClose={handlePrintDemandesClose}
            >
                <PrintDemandeRetour
                    demandes={demandes.items.filter((item: DemandeRetourStockInterface) =>
                        checkedPrintingDemandes.includes(item.idDemandeRetourStock),
                    )}
                    colSpan={5}
                    componentHeader={() => (
                        <>
                            <th>Produit Ref</th>
                            <th>Produit Designation</th>
                            <th>Quantité</th>
                            <th>Info</th>
                            <th>Date demande</th>
                        </>
                    )}
                    componentBody={({ item }: { item: DemandeRetourStockInterface }) => (
                        <>
                            <td>{item.produit?.ref!}</td>
                            <td>{item.produit?.designation!}</td>
                            <td>{item.qte}</td>
                            <td>
                                <span className="my-xsm-font">
                                    Colis: &nbsp;
                                    <b>
                                        {item.produit?.colisproduitenvoyes &&
                                        item.produit?.colisproduitenvoyes.length > 0
                                            ? item.produit?.colisproduitenvoyes[0].colis?.refColis
                                            : '-'}
                                    </b>
                                    <br />
                                    Commande: &nbsp;
                                    <b>{item.commande ? item.commande?.code : '-'}</b>
                                    <br />
                                    Acheteur: &nbsp;
                                    <b>{item.commande ? item.commande?.acheteur.fullName : '-'}</b>
                                </span>
                            </td>
                            <td>{moment(item.dateCreation).format('DD/MM/YYYY HH:mm')}</td>
                        </>
                    )}
                    target="client"
                />
            </CustomModal>
        </>
    );
};

export default DemandeRetourStocksPage;
