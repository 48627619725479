import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import TarifTransfertInterface, {
    TarifTransfertsMultipleCreationInterface,
} from 'types/interfaces/tarifTransfert.interface';

const apiEndPoint = `${apiUrl}/tarifTransferts`;

export const getTarifsByIdFournisseur = (id: number): Promise<TarifTransfertInterface[] | any> => {
    return http.get(`${apiEndPoint}/fournisseur/${id}`);
};
export const getLastTarif = (
    id: number,
    villeId: number,
    destinataireId: number,
): Promise<TarifTransfertInterface | any> => {
    return http.get(`${apiEndPoint}/fournisseur/${id}/ville/${villeId}/destinataire/${destinataireId}`);
};

export const getTarifsFiltered = (
    total: number,
    page: number,
    idFournisseurTransfert?: number | string,
    idVille?: number | string,
): Promise<{ count: number; items: TarifTransfertInterface[] } | any> => {
    const query = `?page=${page}&total=${total}${
        idFournisseurTransfert ? '&idFournisseurTransfert=' + idFournisseurTransfert : ''
    }${idVille ? '&idVille=' + idVille : ''}`;
    return http.get(`${apiEndPoint}/filter${query}`);
};

export const createMultipleTarif = (
    tarif: TarifTransfertsMultipleCreationInterface,
    idFournisseurTransfert?: number | string,
) => {
    return http.post(
        `${apiEndPoint}/multiple${idFournisseurTransfert ? '?clientId=' + idFournisseurTransfert : ''}`,
        tarif,
    );
};

export const getLastTarifs = (
    id: number,
    villeIds: string,
    destinataireId: number,
): Promise<TarifTransfertInterface[] | any> => {
    return http.get(`${apiEndPoint}/multi/fournisseur/${id}/listVilles/${villeIds}/destinataire/${destinataireId}`);
};
