import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';

import { readImageTobase64 } from 'utils/helpers';
import ImageElement from 'components/UI/imageElement';
import FileInterface from 'types/interfaces/file.interface';
import { getFileById } from 'services/file.service';

type Props = {
    idFile: number;
};

const PaiementRecu: React.FC<Props> = ({ idFile }: Props) => {
    const [file, setFile] = useState<FileInterface | null>(null);

    useEffect(() => {
        getFileById(idFile).then((response: any) => {
            const { data } = response;
            setFile(data);
        });
    }, [idFile]);
    return (
        <Row>
            <Col md={{ span: 6, offset: 3 }}>
                {file ? (
                    <div className="text-center my-3">
                        <ImageElement file={readImageTobase64(file?.fileImage)} alt="file recu" fluid />
                    </div>
                ) : (
                    <div className="text-center">
                        <Spinner animation="border" size="sm" />
                    </div>
                )}
            </Col>
        </Row>
    );
};

export default PaiementRecu;
