import React, { useState, useEffect, useContext, useMemo } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { TableColumnType } from 'react-bs-datatable';
import { Col, Form, OverlayTrigger, Row, Tooltip, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ROLES as RolesMap } from 'constantes/permission-maps';

import { useQueryMeMo, isEmptyObject, buildUrlQueryParams, b64_to_utf8, utf8_to_b64 } from 'utils/helpers';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import Datatable from 'components/shared/datatable';
import Paginator from 'components/UI/paginator';

import ClientInterface from 'types/interfaces/client.interface';

import { getClientsByIdFournisseur } from 'services/client.service';

import { getProduitStock } from 'services/produit.service';

import TitleElement from 'components/UI/titleElement';
import PermissionsGate from 'hoc/permissionsGate';
import CustomModal from 'components/UI/customModal';
import DemandeRetourStockFormCreation from 'pages/Stock/components/DemandeRetourStockCreation';

const TOTAL_DATA_PER_PAGE = 100;

const ProduitStockPage: React.FC = () => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    const [produits, setProduits] = useState<{ items: any[]; count: number }>({
        items: [],
        count: 0,
    });
    const [focusedProduit, setFocusedProduit] = useState(null);

    const [clients, setClients] = useState<ClientInterface[]>([]);

    const [selectedClient, setSelectedClient] = useState<string | null>(null);

    const [activePage, setActivePage] = useState<number>(1);

    const query = useQueryMeMo();
    const history = useNavigate();

    const [showModal, setShowModal] = useState<boolean>(false);

    const handleModalClose = () => {
        setShowModal(false);
        setFocusedProduit(null);
    };

    const handleModalOpen = (data: any) => {
        setFocusedProduit(data);
        setShowModal(true);
    };

    const loadProduits = (total: number, page: number, idClient?: string) => {
        getProduitStock(total, page, idClient)
            .then((response: AxiosResponse<any[] | any>) => {
                const { data } = response;
                setProduits({
                    items: data.items,
                    count: data.count,
                });
            })
            .catch();
    };

    const handlePageNFilterChange = (activePage: number, searchedData?: string) => {
        const urlObject: any = {};
        if (activePage > 1) urlObject['page'] = activePage;
        if (searchedData) {
            const parsedObject = JSON.parse(searchedData);
            Object.keys(parsedObject).forEach((key) => {
                if (parsedObject[key as any]) {
                    if (key === 'client' && parsedObject[key as any])
                        urlObject[key] = utf8_to_b64(parsedObject[key as any]);
                    else urlObject[key] = parsedObject[key as any];
                }
            });
        }
        history(isEmptyObject(urlObject) ? window.location.pathname : `?${buildUrlQueryParams(urlObject)}`);
    };

    useEffect(() => {
        document.title = `Etat de stockage - DS`;
    }, []);

    useEffect(() => {
        if (contextUser && !contextUser?.userData?.userroles?.some((d) => d.role.nomRole === RolesMap.CLIENT_ADMIN))
            getClientsByIdFournisseur(contextUser.userData.idFournisseur!)
                .then((response: AxiosResponse<ClientInterface[] | any>) => {
                    const { data } = response;
                    setClients(data);
                })
                .catch();
    }, [contextUser]);

    useEffect(() => {
        const page = query.get('page');
        const client = query.get('client');
        const currentPage = page ? +page : 1;
        const currentClient = client ? b64_to_utf8(client) : '';
        setActivePage(currentPage);
        setSelectedClient(currentClient!);
        const loadData = async () => {
            loadProduits(TOTAL_DATA_PER_PAGE, currentPage, currentClient);
        };
        loadData();
    }, [query, selectedClient]);

    const tableHeaders: TableColumnType<any>[] = useMemo(
        () => [
            ...(contextUser?.userData?.userroles?.some((d) => d.role.nomRole === RolesMap.CLIENT_ADMIN)
                ? []
                : [
                      {
                          prop: 'client.raisonSocial',
                          title: 'Client',
                          cellProps: {
                              className: 'foceVerticalMiddle',
                          },
                          isFilterable: true,
                          isSortable: true,
                      },
                  ]),
            {
                prop: 'ref',
                title: 'Ref',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
            },
            {
                prop: 'designation',
                title: 'Designation',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
            },
            {
                prop: 'qte',
                title: 'Quantité non éxpidié',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                cell: (row: any) => (
                    <>{row.colisproduitenvoyes[0].qteNonExpide ? row.colisproduitenvoyes[0].qteNonExpide : '-'}</>
                ),
            },
            {
                prop: 'qte2',
                title: 'Quantité disponible à commander',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                cell: (row: any) => (
                    <>{row.colisproduitenvoyes[0].qteResteAcmd ? row.colisproduitenvoyes[0].qteResteAcmd : '-'}</>
                ),
            },
            {
                prop: 'ddc',
                title: 'Dernière commande',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                cell: (row: any) => (
                    <>
                        {row.colisproduitenvoyes[0].coliscommandes.length > 0 &&
                        row.colisproduitenvoyes[0].coliscommandes[0].commande.dateCreation
                            ? moment(row.colisproduitenvoyes[0].coliscommandes[0].commande.dateCreation).format(
                                  'DD/MM/YYYY HH:mm',
                              )
                            : '-'}
                    </>
                ),
            },
            {
                prop: 'ddr',
                title: 'Dernière réception',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                cell: (row: any) => (
                    <>
                        {row.colisproduitrecus[0].colis.dateRec
                            ? moment(row.colisproduitrecus[0].colis.dateRec).format('DD/MM/YYYY HH:mm')
                            : '-'}
                    </>
                ),
            },
            {
                prop: 'actions',
                title: 'Actions',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                cell: (row: any) => (
                    <PermissionsGate scopes={[RolesMap.CLIENT_ADMIN]} strict>
                        <Button
                            variant="outline-secondary"
                            className="button-input-group-effect gap-5marginpx"
                            id="basic-addon1"
                            onClick={() =>
                                handleModalOpen({
                                    idProduit: row.idProduit,
                                    colisproduitenvoyes: row.colisproduitenvoyes[0],
                                })
                            }
                        >
                            <FontAwesomeIcon icon={faAdd} size="xs" style={{ color: '#337ab7' }} />
                        </Button>
                    </PermissionsGate>
                ),
            },
        ],
        [produits],
    );

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        {
            active: true,
            text: 'Etat de stockage',
        },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Etat de stockage
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={(props: any) => (
                            <Tooltip id="info-stock-tooltip" {...props}>
                                les commandes non expédiées sont incluses
                            </Tooltip>
                        )}
                    >
                        <Button type="button" variant="secondary-outline">
                            <FontAwesomeIcon icon={faInfoCircle} size="lg" style={{ color: '#4b80ad' }} width="20" />
                        </Button>
                    </OverlayTrigger>
                </TitleElement>
            </div>
            {!contextUser?.userData?.userroles?.some((d) => d.role.nomRole === RolesMap.CLIENT_ADMIN) && (
                <Row className="mb-2 holdit_notEffect">
                    <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_Client">
                        <Form.Label>Client:</Form.Label>
                        <Form.Select
                            size="sm"
                            name="selectedClient"
                            value={selectedClient!}
                            onChange={(e) => {
                                const {
                                    target: { value },
                                } = e;
                                setSelectedClient(value);
                                handlePageNFilterChange(
                                    1,
                                    JSON.stringify({
                                        client: value,
                                    }),
                                );
                            }}
                            placeholder="Selectionner un client"
                        >
                            <option value="">Selectionner un client</option>
                            {clients.map((d: ClientInterface) => (
                                <option key={d.idClient} value={d.idClient}>
                                    {d.raisonSocial}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Row>
            )}
            <Datatable data={produits.items} tableColumns={tableHeaders}>
                <Row>
                    <Col className="d-flex flex-col justify-content-end align-items-end">
                        <Paginator
                            defaultPage={activePage}
                            totalPages={Math.ceil(produits.count / TOTAL_DATA_PER_PAGE)}
                            callback={(e_page) =>
                                handlePageNFilterChange(
                                    e_page,
                                    JSON.stringify({
                                        client: selectedClient,
                                    }),
                                )
                            }
                        />
                    </Col>
                </Row>
            </Datatable>
            <CustomModal title="Demande retour stock" size="lg" show={showModal} handleClose={handleModalClose}>
                {focusedProduit ? (
                    <DemandeRetourStockFormCreation
                        produit={focusedProduit}
                        setProduits={setProduits}
                        closeModal={handleModalClose}
                    />
                ) : (
                    <></>
                )}
            </CustomModal>
        </>
    );
};

export default ProduitStockPage;
