import React, { useContext, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import { DisplayName } from 'utils/helpers';
import { AppContextState } from 'types/index';
import AppContext from 'utils/appContext';
import CommandeInterface from 'types/interfaces/commande.interface';
import UserInterface from 'types/interfaces/user.interface';
import EtatTitreInterface from 'types/interfaces/etatTitre.interface';
import { affecterALivreurList } from 'services/commande.service';
import ClockInput from 'components/shared/clockInput';

export const schema = yup.object({
    ids: yup.array().of(yup.number().required()).min(1).required(),
    idLivreur: yup.number().required(),
    remarque: yup.string(),
    idEtatTitre: yup.number(),
});

type Props = {
    commandes: { items: CommandeInterface[]; count: number };
    setCommandes: (value: { items: CommandeInterface[]; count: number }) => void;
    setCommande?: (value: CommandeInterface | null) => void;
    setCheckedCommandes: (value: number[]) => void;
    setCheckedPrintingCMD?: (value: number[]) => void;
    livreurs: UserInterface[];
    etatTitres: EtatTitreInterface[];
    selectedCommandes: number[];
};
const AffectationLivreur: React.FC<Props> = ({
    commandes,
    setCommandes,
    setCommande,
    setCheckedCommandes,
    setCheckedPrintingCMD,
    livreurs,
    etatTitres,
    selectedCommandes,
}: Props) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);
    const [spinIt, setSpinIt] = useState<boolean>(false);

    const submitAffectCommande = async (livraisonValues: any) => {
        const livreurData = livreurs.find((d: UserInterface) => d.idUser == livraisonValues.idLivreur);
        livraisonValues.remarque = `${
            livraisonValues.remarque ? livraisonValues.remarque + '\nPS: ' : ''
        }Affectée à ${DisplayName(livreurData!)}`;
        try {
            setSpinIt(true);
            const response = await affecterALivreurList(livraisonValues);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Commande(s) a été affecté(s) à un livreur.');
                const cmdData = [...commandes.items].map((dd) =>
                    !resultData.includes(dd.idCommande)
                        ? dd
                        : {
                              ...dd,
                              dateOperation: new Date(),
                              idLivreur: livraisonValues.idLivreur,
                              livreur: livreurs.find((d) => +d.idUser === +livraisonValues.idLivreur)!,
                              idEtatCommande: 2,
                              etatCommande: { idEtatCommande: 2, etatCommande: "En cours d'expédition" },
                              commandeActions: [
                                  ...dd.commandeActions,
                                  {
                                      idCommandeAction: -1,
                                      idEtatTitre: livraisonValues.idEtatTitre,
                                      etatTitre: etatTitres.find(
                                          (det: EtatTitreInterface) =>
                                              det.idEtatTitre.toString() === livraisonValues.idEtatTitre.toString(),
                                      ),
                                      description: livraisonValues.remarque,
                                      idUser: contextUser?.IdUser,
                                      idCommande: dd.idCommande,
                                      dateAction: new Date(),
                                      user: contextUser?.userData,
                                  },
                              ],
                          },
                );
                setCommandes({ items: cmdData, count: commandes.count - resultData.length });
                if (typeof setCommande !== 'undefined') {
                    (setCommande as React.Dispatch<React.SetStateAction<CommandeInterface>>)(
                        (prev: CommandeInterface) => {
                            return {
                                ...prev,
                                dateOperation: new Date(),
                                idLivreur: livraisonValues.idLivreur,
                                livreur: livreurs.find((d) => +d.idUser === +livraisonValues.idLivreur)!,
                                idEtatCommande: 2,
                                etatCommande: { idEtatCommande: 2, etatCommande: "En cours d'expédition" },
                                commandeActions: [
                                    ...prev.commandeActions,
                                    {
                                        idCommandeAction: -1,
                                        idEtatTitre: livraisonValues.idEtatTitre,
                                        etatTitre: etatTitres.find(
                                            (det: EtatTitreInterface) =>
                                                det.idEtatTitre.toString() === livraisonValues.idEtatTitre.toString(),
                                        ),
                                        description: livraisonValues.remarque,
                                        idUser: contextUser?.IdUser,
                                        idCommande: prev.idCommande,
                                        dateAction: new Date(),
                                        user: contextUser?.userData,
                                    },
                                ],
                            };
                        },
                    );
                }
                setCheckedCommandes([]);
                if (typeof setCheckedPrintingCMD !== 'undefined') setCheckedPrintingCMD([]);
                setSpinIt(false);
            }
        } catch (Exception) {
            setSpinIt(false);
        }

        setSpinIt(false);
    };

    const mdSize = typeof setCheckedPrintingCMD !== 'undefined';

    return (
        <Formik
            validationSchema={schema}
            onSubmit={(values) => submitAffectCommande(values)}
            enableReinitialize={true}
            initialValues={{
                ids: selectedCommandes,
                idLivreur: '',
                remarque: '',
                idEtatTitre: 8,
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Row>
                        <Form.Group as={Col} md={mdSize ? 4 : 12} controlId="form_grp_date_aff">
                            <Form.Label>Date d&apos;affectation</Form.Label>
                            <ClockInput />
                        </Form.Group>
                        <Form.Group as={Col} md={mdSize ? 4 : 12} controlId="form_grp_livreur">
                            <Form.Label>Livreur</Form.Label>
                            <Form.Select
                                name="idLivreur"
                                value={values.idLivreur}
                                onChange={handleChange}
                                isInvalid={!!errors.idLivreur}
                                placeholder="Sélectionner un livreur"
                            >
                                <option>Sélectionner un livreur</option>
                                {livreurs?.map((d: UserInterface) => (
                                    <option key={d.idUser} value={d.idUser}>
                                        {DisplayName(d)}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md={mdSize ? 4 : 12} controlId="form_grp_remarque">
                            <Form.Label>Remarque</Form.Label>
                            <Form.Control
                                size="sm"
                                as="textarea"
                                rows={2}
                                name="remarque"
                                placeholder="Remarque"
                                value={values.remarque}
                                onChange={handleChange}
                                isInvalid={!!errors.remarque}
                            />
                        </Form.Group>
                    </Row>
                    <div className="gap-2 mt-3 d-flex justify-content-end">
                        <Button className="ms-2" variant="outline-primary" type="submit" disabled={!!spinIt}>
                            Enregistrer
                            {!!spinIt && (
                                <>
                                    &nbsp; <Spinner animation="border" size="sm" />
                                </>
                            )}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default AffectationLivreur;
