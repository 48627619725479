import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import EmplacementInterface from 'types/interfaces/emplacement.interface';

const apiEndPoint = `${apiUrl}/emplacements`;

export const getEmplacements = (): Promise<EmplacementInterface[] | any> => {
    return http.get(apiEndPoint);
};

export const saveEmplacement = (emplacement: EmplacementInterface) => {
    const body = {
        ...emplacement,
    };
    if (emplacement.idEmplacement) {
        return http.put(`${apiEndPoint}/${emplacement.idEmplacement}`, body);
    }
    return http.post(`${apiEndPoint}`, body);
};

export const getEmplacementsByIdFournisseur = (id: number): Promise<EmplacementInterface[] | any> => {
    return http.get(`${apiEndPoint}/fournisseur/${id}`);
};
export const getSpecEmplacements = (): Promise<EmplacementInterface[] | any> => {
    return http.get(`${apiEndPoint}/spec`);
};
