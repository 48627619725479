import React from 'react';
import { useField } from 'formik';
import { Col, Form } from 'react-bootstrap';

type Props = any;

export const FormField: React.FC<Props> = ({ label, children, rowGroup, upperCase, hideOn, ...props }: Props) => {
    const [field, meta, helpers] = useField(props);
    // console.log(helpers, meta, field, props);
    return (
        <>
            {!!rowGroup ? (
                <Form.Group as={Col} md="6" controlId={`form_grp_${label.trim()}`} className={hideOn ? 'd-none' : ''}>
                    <Form.Label>{label}</Form.Label>
                    {upperCase ? (
                        <Form.Control
                            {...field}
                            {...props}
                            value={meta.value || ''}
                            onChange={(e: any) => helpers.setValue(e.target.value.toUpperCase())}
                            isInvalid={!!meta?.error}
                        >
                            {children}
                        </Form.Control>
                    ) : (
                        <Form.Control {...field} {...props} value={meta.value || ''} isInvalid={!!meta?.error}>
                            {children}
                        </Form.Control>
                    )}
                </Form.Group>
            ) : (
                <Form.Group controlId={`form_grp_${label.trim()}`} className={hideOn ? 'd-none' : ''}>
                    <Form.Label>{label}</Form.Label>
                    {upperCase ? (
                        <Form.Control
                            {...field}
                            {...props}
                            value={meta.value || ''}
                            onChange={(e: any) => helpers.setValue(e.target.value.toUpperCase())}
                            isInvalid={!!meta?.error}
                        >
                            {children}
                        </Form.Control>
                    ) : (
                        <Form.Control {...field} {...props} value={meta.value || ''} isInvalid={!!meta?.error}>
                            {children}
                        </Form.Control>
                    )}
                </Form.Group>
            )}
        </>
    );
};

FormField.defaultProps = {
    rowGroup: false,
};

export const FormCheckField: React.FC<Props> = ({ label, rowGroup, ...props }: Props) => {
    const [field, meta /*, helpers*/] = useField(props);
    // console.log(field, meta);
    return (
        <>
            {!!rowGroup ? (
                <Form.Group as={Col} md="6" controlId={`form_grp_${label.trim()}`}>
                    <Form.Label>{label}</Form.Label>
                    <Form.Check type="checkbox" {...field} {...props} checked={meta.value} isInvalid={!!meta?.error} />
                </Form.Group>
            ) : (
                <Form.Group controlId={`form_grp_${label.trim()}`}>
                    <Form.Label>{label}</Form.Label>
                    <Form.Check type="checkbox" {...field} {...props} checked={meta.value} isInvalid={!!meta?.error} />
                </Form.Group>
            )}
        </>
    );
};

FormCheckField.defaultProps = {
    rowGroup: false,
};
