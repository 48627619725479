import React from 'react';
import { Badge } from 'react-bootstrap';

export const colorVariant = (value: string) => {
    let variant = 'light';
    switch (value) {
        case 'Créée':
            variant = 'info';
            break;
        case "En cours d'expédition":
            variant = 'secondary';
            break;
        case 'Expédiée':
            variant = 'primary';
            break;
        case 'Livrée':
            variant = 'success';
            break;
        case 'Annulée':
            variant = 'warning';
            break;
        case 'Rejetée':
            variant = 'danger';
            break;
        default:
            variant = 'light';
            break;
    }
    return variant;
};

type Props = {
    value: string;
};

const EtatCommandeBadge: React.FC<Props> = ({ value }: Props) => {
    const variant = colorVariant(value);
    return <Badge bg={variant}>{value}</Badge>;
};

export default EtatCommandeBadge;
