import React from 'react';
import styled from 'styled-components';

type ColorsTypes = { main: string; second: string };

type Props = {
    stepsCount: number;
    step: number;
    done: boolean;
    colors?: ColorsTypes;
};

const Stepper: React.FC<Props> = ({ stepsCount, step, done, colors }: Props) =>
    stepsCount > 1 ? (
        <StepsElement count={stepsCount} colors={colors!}>
            <div className="stepper-wrapper">
                <ul className="stepper-progressbar">
                    {[...Array(stepsCount)].map((x, i) => (
                        <li
                            key={i}
                            className={step === i && !done && i !== stepsCount ? 'active' : step >= i ? 'complete' : ''}
                        >
                            {x}
                        </li>
                    ))}
                </ul>
            </div>
        </StepsElement>
    ) : (
        <></>
    );

Stepper.defaultProps = {
    stepsCount: 0,
    step: 0,
    done: false,
    colors: { second: 'rgba(0, 0, 0, 0.54)', main: '#42B549' },
};

export default Stepper;

type StyledProps = {
    count: number;
    colors: ColorsTypes;
};

const StepsElement = styled.div<StyledProps>`
    ${({ count, colors }: StyledProps) => `
  padding: 20px;
  height: 95px;
  background-color: #f3fef335;

  .stepper-wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }

  & .stepper-progressbar {
    counter-reset: step;

    & li {
      list-style-type: none;
      width: calc(100% / ${count});
      float: left;
      font-size: 11px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      color: ${colors.second};
      z-index:1;

      &:before {
        width: 30px;
        height: 30px;
        content: counter(step);
        counter-increment: step;
        line-height: 27px;
        border: 1px solid ${colors.main};
        display: block;
        text-align: center;
        margin: 0 auto 10px auto;
        border-radius: 50%;
        background-color: white;
        font-size: 15px;
        color: ${colors.main};
      }

      &:after {
        width: 100%;
        height: 1px;
        content: "";
        position: absolute;
        background-color: rgba(66, 181, 73, 0.2);
        top: 15px;
        left: 50%;
        z-index: -1;
      }

      &:last-child:after {
        content: none;
      }

      &.active {
        color: ${colors.main};

        &:before {
          background-color: ${colors.main};
          color: #ffffff;
          box-shadow: 0 0 6px 0 rgba(66, 181, 73, 0.4);
        }
      }

      &.active:before {
        border-color: #55b776;
      }

      &.complete {
        &:after {
          background-color: rgba(66, 181, 73, 0.9);
        }

        &:before {
          color: #ffffff00;
          border: none;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%2342B549' transform='translate(1 1)'%3E%3Ccircle cx='14' cy='14' r='14' fill='%23FFF' fill-rule='nonzero'/%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M9 15.084l3.842 3.543L19.938 10'/%3E%3C/g%3E%3C/svg%3E%0A");
        }
      }
    }
  }
`}
`;
