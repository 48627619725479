// transfertService.ts
import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';

import FactureLivreurInterface from 'types/interfaces/factureLivreur.interface';

const apiEndPoint = `${apiUrl}/factureLivreurs`;

export const putValidatePaiement = (reqData: {
    idFactureLivreur: number;
    idFactureLivreurPaiement: number;
    status: string;
}): Promise<FactureLivreurInterface | any> => {
    return http.put(`${apiEndPoint}/facture/action`, reqData);
};

export const postAddPaiement = (
    factureLivreurPaiement: {
        montant: number;
        fileImage: string;
    },
    id: number,
): Promise<FactureLivreurInterface | any> => {
    return http.post(`${apiEndPoint}/facture/paiement/${id}`, factureLivreurPaiement);
};

export const getFactures = (
    dateDebut?: Date | string,
    dateFin?: Date | string,
    status?: string,
    idLivreur?: number | string,
    page?: number,
    total?: number,
): Promise<FactureLivreurInterface[] | any> => {
    const query =
        `${apiEndPoint}?` +
        new URLSearchParams({
            dateDebut: dateDebut ? dateDebut.toString() : '',
            dateFin: dateFin ? dateFin.toString() : '',
            status: status ?? '',
            idLivreur: idLivreur ? idLivreur?.toString() : '',
            page: page?.toString() ?? '',
            total: total?.toString() ?? '',
        });
    return http.get(query);
};

export const deleteFacture = (id: number) => {
    return http.delete(`${apiEndPoint}/facture/${id}`);
};
