import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import ColisInterface from 'types/interfaces/colis.interface';

const apiEndPoint = `${apiUrl}/colis`;

export const saveColis = (colis: ColisInterface) => {
    const body = {
        ...colis,
    };
    if (colis.idColis) {
        return http.put(`${apiEndPoint}/${colis.idColis}`, body);
    }
    return http.post(`${apiEndPoint}`, body);
};

export const envoiColis = (id: number) => http.post(`${apiEndPoint}/envoi/${id}`);

export const envoiColisList = (ids: number[]): Promise<number[] | any> =>
    http.post(`${apiEndPoint}/range/envoi`, { ids });

export const ramasseColisList = (data: any): Promise<number[] | any> =>
    http.post(`${apiEndPoint}/range/ramassage`, data);

export const getConsultationColis = (
    total: number,
    page: number,
    idClient?: number | string,
    dateDebut?: Date | string,
    dateFin?: Date | string,
    status?: string | null,
): Promise<{ count: number; items: ColisInterface[] } | any> => {
    const query = `?page=${page}&total=${total}${idClient ? '&idClient=' + idClient : ''}${
        dateDebut ? '&dateDebut=' + dateDebut : ''
    }${dateFin ? '&dateFin=' + dateFin : ''}${status ? '&status=' + status : ''}`;
    return http.get(`${apiEndPoint}/client${query}`);
};

export const getWProduitById = (id: number): Promise<ColisInterface | any> => {
    return http.get(`${apiEndPoint}/details/${id}`);
};

export const getRamassageColis = (): Promise<ColisInterface[] | any> => {
    return http.get(`${apiEndPoint}/ramassage`);
};

export const confirmRamasseColisList = (data: any): Promise<number[] | any> =>
    http.post(`${apiEndPoint}/range/ramassage/confirm`, data);

export const getReceptionColis = (): Promise<ColisInterface[] | any> => {
    return http.get(`${apiEndPoint}/reception`);
};

export const doReceptionnerColis = (data: any): Promise<ColisInterface | any> =>
    http.put(`${apiEndPoint}/reception/${data.idColis}`, data);

export const getPreparedColis = (idClient?: number): Promise<ColisInterface[] | any> => {
    return http.get(`${apiEndPoint}/ready-commande${idClient ? '?idClient=' + idClient : ''}`);
};

export const getPreparedColisById = (idColis: number): Promise<ColisInterface | any> => {
    return http.get(`${apiEndPoint}/ready-commande/${idColis}`);
};

export const deleteColis = (id: number) => http.delete(`${apiEndPoint}/${id}`);
