import React, { useState, useEffect, useContext } from 'react';
import * as yup from 'yup';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { Button, Form, Spinner } from 'react-bootstrap';
import { TableColumnType } from 'react-bs-datatable';

import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';

import EmplacementInterface from 'types/interfaces/emplacement.interface';
import FournisseurInterface from 'types/interfaces/fournisseur.interface';
import AgenceInterface from 'types/interfaces/agence.interface';
import { getAgencesByIdFournisseur } from 'services/agence.service';
import { getEmplacements, getEmplacementsByIdFournisseur, saveEmplacement } from 'services/emplacement.service';
import { getFournisseurs } from 'services/fournisseur.service';
import { hasRole } from 'utils/helpers';
import { ROLES as RolesMap } from 'constantes/permission-maps';

import CustomModal from 'components/UI/customModal';
import Datatable from 'components/shared/datatable';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import TitleElement from 'components/UI/titleElement';

const schema = (isAdmin: boolean) =>
    isAdmin
        ? yup.object({
              idEmplacement: yup.number(),
              libelle: yup.string().required(),
              idAgence: yup.number().required(),
              _idFournisseur: yup.number().required(),
          })
        : yup.object({
              idEmplacement: yup.number(),
              libelle: yup.string().required(),
              idAgence: yup.number().required(),
          });

const EmplacementPage: React.FC = () => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);
    const [emplacements, setEmplacements] = useState<EmplacementInterface[]>([]);
    const [currentEmplacement, setCurrentEmplacement] = useState<EmplacementInterface | null>(null);
    const [agences, setAgences] = useState<AgenceInterface[]>([]);
    const [fournisseurs, setFournisseurs] = useState<FournisseurInterface[]>([]);

    const [showFormModal, setShowFormModal] = useState<boolean>(false);
    const [formModalEditMode, setFormModalEditMode] = useState<boolean>(false);
    const [spinIt, setSpinIt] = useState<boolean>(false);

    const handleFormModalClose = () => {
        setCurrentEmplacement(null);
        setShowFormModal(false);
        if (hasRole(contextUser?.ROLES, RolesMap.SUPER_ADMIN)) setAgences([]);
    };

    const handleFormModalOpen = (editMode = false, emplacementsData?: EmplacementInterface) => {
        if (emplacementsData) setCurrentEmplacement(emplacementsData);
        // console.log(emplacementsData);
        if (editMode && hasRole(contextUser?.ROLES, RolesMap.SUPER_ADMIN))
            loadAgencesByFournisseur((emplacementsData as any)['agence.idFournisseur']);
        setFormModalEditMode(editMode);
        setShowFormModal(true);
    };

    const tableHeaders: TableColumnType<any>[] = [
        {
            prop: 'libelle',
            title: 'Libelle',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'agence.libelle',
            title: 'Agence',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        ...(hasRole(contextUser?.ROLES, RolesMap.SUPER_ADMIN)
            ? [
                  {
                      prop: 'agence.fournisseur.raisonSocial',
                      title: 'Fournisseur',
                      cellProps: {
                          className: 'foceVerticalMiddle',
                      },
                      isFilterable: true,
                      isSortable: true,
                  },
              ]
            : []),
        {
            prop: 'agence.ville.NomVille',
            title: 'Ville, Region',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: AgenceInterface) => (
                <>{`${(row as any)['agence.ville.nomVille']}, ${(row as any)['agence.ville.region.nomRegion']}`}</>
            ),
        },
        {
            prop: 'null',
            title: 'Action',
            alignment: { horizontal: 'right' },
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: EmplacementInterface) => (
                <Button
                    variant="outline-secondary"
                    className="button-input-group-effect"
                    id="basic-addon1"
                    onClick={() => handleFormModalOpen(true, row)}
                >
                    <FontAwesomeIcon icon={faEdit} size="xs" style={{ color: '#337ab7' }} />
                </Button>
            ),
            isFilterable: false,
            isSortable: false,
        },
    ];

    useEffect(() => {
        document.title = 'Paramétrage Agence - DS';
    }, []);

    useEffect(() => {
        if (contextUser) {
            if (hasRole(contextUser.ROLES, RolesMap.SUPER_ADMIN)) {
                getEmplacements()
                    .then((response: AxiosResponse<EmplacementInterface | any>) => {
                        const { data } = response;
                        setEmplacements(data);
                    })
                    .catch();
                getFournisseurs()
                    .then((response: AxiosResponse<FournisseurInterface | any>) => {
                        const { data } = response;
                        setFournisseurs(data);
                    })
                    .catch();
            } else {
                getEmplacementsByIdFournisseur(contextUser.userData.idFournisseur!)
                    .then((response: AxiosResponse<EmplacementInterface | any>) => {
                        const { data } = response;
                        setEmplacements(data);
                    })
                    .catch();
                loadAgencesByFournisseur(contextUser.userData.idFournisseur!);
            }
        }
    }, [contextUser]);

    const loadAgencesByFournisseur = (id: number) => {
        getAgencesByIdFournisseur(id)
            .then((response: AxiosResponse<AgenceInterface | any>) => {
                const { data } = response;
                setAgences(data);
            })
            .catch();
    };

    const submitForm = async (emplacementValues: EmplacementInterface | any) => {
        try {
            setSpinIt(true);
            const response = await saveEmplacement(emplacementValues);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Emplacement a été enregistré avec succès');
                const emplacementsData = [...emplacements].filter(
                    (dd) => dd.idEmplacement !== resultData.idEmplacement,
                );
                emplacementsData.unshift(resultData);
                setEmplacements(emplacementsData);
                handleFormModalClose();
                setSpinIt(false);
            }
        } catch (Exception) {
            setSpinIt(false);
        }
    };

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { text: 'Paramétrage' },
        { active: true, text: 'Emplacements' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Emplacements
                </TitleElement>
                <Button
                    variant="outline-secondary"
                    className="button-input-group-effect"
                    id="basic-addon1"
                    onClick={() => handleFormModalOpen()}
                >
                    <FontAwesomeIcon icon={faPlus} size="xs" style={{ color: '#337ab7' }} />
                </Button>
            </div>
            <Datatable data={emplacements} tableColumns={tableHeaders} />
            <CustomModal
                title={`${!!formModalEditMode ? 'Edit' : 'Nouveau'} Agence`}
                size="lg"
                show={showFormModal}
                handleClose={handleFormModalClose}
            >
                <div>
                    <Formik
                        validationSchema={schema(hasRole(contextUser?.ROLES, RolesMap.SUPER_ADMIN))}
                        onSubmit={(values) => submitForm(values)}
                        enableReinitialize={true}
                        initialValues={{
                            idEmplacement:
                                currentEmplacement && !!formModalEditMode
                                    ? currentEmplacement.idEmplacement
                                    : undefined,
                            libelle: currentEmplacement && !!formModalEditMode ? currentEmplacement.libelle : '',
                            idAgence:
                                currentEmplacement && !!formModalEditMode ? currentEmplacement.idAgence : undefined,
                            _idFournisseur:
                                currentEmplacement && !!formModalEditMode
                                    ? (currentEmplacement as any)['agence.idFournisseur']
                                    : undefined,
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                {values.idAgence && (
                                    <Form.Control
                                        type="hidden"
                                        name="idEmplacement"
                                        value={values.idEmplacement}
                                        readOnly={true}
                                    />
                                )}
                                <Form.Group controlId="form_grp_nom">
                                    <Form.Label>Libelle</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="libelle"
                                        placeholder="Libelle"
                                        value={values.libelle}
                                        onChange={handleChange}
                                        isInvalid={!!errors.libelle}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.libelle}</Form.Control.Feedback>
                                </Form.Group>
                                {contextUser && hasRole(contextUser.ROLES, RolesMap.SUPER_ADMIN) && (
                                    <Form.Group controlId="form_grp_frs">
                                        <Form.Label>Fournisseurs</Form.Label>
                                        <Form.Select
                                            name="_idFournisseur"
                                            value={values._idFournisseur}
                                            onChange={(e) => {
                                                const {
                                                    target: { value },
                                                } = e;
                                                loadAgencesByFournisseur(+value);
                                                handleChange({ target: { value, name: '_idFournisseur' } });
                                            }}
                                            isInvalid={!!errors._idFournisseur}
                                            placeholder="Selectionner un founisseur"
                                            disabled={true}
                                        >
                                            <option>Selectionner un fournisseur</option>
                                            {fournisseurs.map((d: FournisseurInterface) => (
                                                <option key={d.idFournisseur} value={d.idFournisseur}>
                                                    {d.raisonSocial}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                )}
                                <Form.Group controlId="form_grp_region">
                                    <Form.Label>Agences</Form.Label>
                                    <Form.Select
                                        name="idAgence"
                                        value={values.idAgence}
                                        onChange={handleChange}
                                        isInvalid={!!errors.idAgence}
                                        placeholder="Selectionner un agence"
                                    >
                                        <option>Selectionner une agence</option>
                                        {agences.map((d: AgenceInterface) => (
                                            <option key={d.idAgence} value={d.idAgence}>
                                                {d.libelle}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <div className="d-grid gap-2 mt-3">
                                    <Button variant="primary" type="submit" disabled={!!spinIt}>
                                        Enregistrer
                                        {!!spinIt && (
                                            <>
                                                &nbsp; <Spinner animation="border" size="sm" />
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </CustomModal>
        </>
    );
};

export default EmplacementPage;
