import React, { useState, useEffect, useCallback } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import memoize from 'fast-memoize';

import { ToastContainer } from 'react-toastify';

import { ROLES as RolesMap } from 'constantes/permission-maps';
import EventBus from 'utils/eventBus';
import appStore, { StoreStateInterface } from 'store/appStore';
import AppContext from 'utils/appContext';
import { localStorageToken } from 'constantes/envLoader';
import getUser from 'utils/getUser';
import { UserAuthInterface } from 'types/index';
import { logout as serviceLogOut } from 'services/authService';

import GuardedComponent from 'hoc/guardedComponent';
import DefaultLayout from 'components/core/default-layout';

import Home from 'pages/Home';
import NotFoundPage from 'pages/NotFound';
import NotAuthorizedPage from 'pages/NotAuthorized';

import EtatTitrePage from 'pages/Setting/EtatTitre';
import RegionPage from 'pages/Setting/Region';
import VillePage from 'pages/Setting/Ville';
import LoginPage from 'pages/Login';
import ProfilePasswordChangePage from 'pages/Profile/PasswordChange';
import ForgetPasswordPage from 'pages/ForgetPassword';
import ResetPasswordPage from 'pages/ResetPassword';
import BanquePage from 'pages/Setting/Banque';
import ServicePage from 'pages/Setting/Service';
// import RolePage from 'pages/Setting/Role';
import OptionServicePage from 'pages/Setting/OptionService';
import ModePaiementPage from 'pages/Setting/ModePaiement';
// import EtatCommandePage from 'pages/Setting/EtatCommande';
import UserIndexPage from 'pages/Users/Index';
import AgencePage from 'pages/Setting/Agence';
import EmplacementPage from 'pages/Setting/Emplacement';
import TarifPage from 'pages/Setting/Tarif';
import FournisseurIndexPage from 'pages/Fournisseur/Index';
import ClientIndexPage from 'pages/Client/Index';
import ColisIndexPage from 'pages/Colis/Index';
import FormColisPage from 'pages/Colis/Form';
import ColisRamassagePage from 'pages/Colis/Ramassage';
import ColisReceptionPage from 'pages/Colis/Reception';
import PreparedColisPage from 'pages/Commande/PreparedColis';
import CommandeIndexPage from 'pages/Commande/Index';
import CommandeLivreurPaiementPage from 'pages/Commande/LivreurPaiement';
import FactureIndexPage from 'pages/Facture/Index';
import PaiementPage from 'pages/Facture/Paiement';
import ActiveLivreursPage from 'pages/Users/ActiveLivreurs';
import ProduitStockPage from 'pages/Stock/Produits';
import LoadingBackDropSpin from 'components/shared/loadingBackDrop';
import LogoChangePage from 'pages/Profile/LogoChange';
import DemandeRetourStocksPage from 'pages/Stock/Demandes';
import DemandeRetourLivreurPage from 'pages/Stock/DemandeRetourLivreur';
import DraftCommandePage from 'pages/DraftCommande';
import CreateOrUpdateForm from 'pages/DraftCommande/CreateOrUpdateForm';
import ScanActions from 'pages/ScanActions';
import TarifTransfertPage from 'pages/Setting/TarifTransfert';
import PartnerShipPage from 'pages/Partnership';
import TransfertRequestsPage from 'pages/Transfert/Requests';
import TransferttoFacture from 'pages/Transfert/factures/toFacture';
import FacturesList from 'pages/Transfert/factures/index';
import FactureLivreurIndexPage from 'pages/FactureLivreur/index';
import GroupVilleClonePage from 'pages/Setting/GroupVilleClone';
import CommissionsLivreurSettingPage from 'pages/Setting/CommissionsLivreur';
import AutoFacturePage from 'pages/Facture/AutoFacture';

const App: React.FC = () => {
    const [user, setUser] = useState<UserAuthInterface | null>(getUser() || null);
    const setSiteLogo = appStore((state: StoreStateInterface) => state.setSiteLogo);
    const location = useLocation();
    const history = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem(localStorageToken);
        if (token) {
            const decodedJwt = getUser(token);
            if (decodedJwt?.exp! * 1000 < Date.now()) {
                logOut();
            }
        } else logOut();
    }, [location]);

    const logOut = useCallback(
        memoize((noRedirecting = false) => {
            serviceLogOut();
            setUser!(null);
            setSiteLogo(null);

            if (noRedirecting === true)
                history({
                    pathname: '/login',
                    search: '?noRedirecting=true',
                });
        }),
        [user],
    );

    useEffect(() => {
        EventBus.on('logout', () => {
            logOut();
        });

        return () => {
            EventBus.remove('logout');
        };
    }, [user, logOut]);

    EventBus.on('logout', () => {
        logOut();
    });

    return (
        <>
            <LoadingBackDropSpin />
            <AppContext.Provider value={{ user, setUser }}>
                <ToastContainer />
                <Routes>
                    <Route element={<DefaultLayout logout={logOut} />}>
                        <Route
                            path="/"
                            element={
                                <GuardedComponent guarded>
                                    <Home />
                                </GuardedComponent>
                            }
                        />
                        <Route
                            path="/partnerships"
                            element={
                                <GuardedComponent guarded rolesRequired={[RolesMap.FOURNISSEUR_ADMIN]}>
                                    <PartnerShipPage />
                                </GuardedComponent>
                            }
                        />
                        <Route path="profile">
                            <Route
                                path="password"
                                element={
                                    <GuardedComponent guarded>
                                        <ProfilePasswordChangePage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="logo"
                                element={
                                    <GuardedComponent guarded rolesRequired={[RolesMap.FOURNISSEUR_ADMIN]} strict>
                                        <LogoChangePage />
                                    </GuardedComponent>
                                }
                            />
                        </Route>
                        <Route path="stock">
                            <Route
                                index
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                            RolesMap.CLIENT_ADMIN,
                                        ]}
                                        strict
                                    >
                                        <ProduitStockPage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="demandes"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                            RolesMap.CLIENT_ADMIN,
                                        ]}
                                        strict
                                    >
                                        <DemandeRetourStocksPage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="demandes-livreur"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                            RolesMap.FOURNISSEUR_LIVREUR,
                                        ]}
                                        strict
                                    >
                                        <DemandeRetourLivreurPage />
                                    </GuardedComponent>
                                }
                            />
                        </Route>
                        <Route path="factures">
                            <Route
                                index
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.FOURNISSEUR_FACTURE,
                                            RolesMap.CLIENT_ADMIN,
                                        ]}
                                        strict
                                    >
                                        <FactureIndexPage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="auto"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_FACTURE]}
                                        strict
                                    >
                                        <AutoFacturePage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="aFacturer"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_FACTURE]}
                                        strict
                                    >
                                        <CommandeIndexPage pageType="AFacturer" />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="paiements"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.FOURNISSEUR_FACTURE,
                                            RolesMap.CLIENT_ADMIN,
                                        ]}
                                        strict
                                    >
                                        <PaiementPage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="livreurs"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.FOURNISSEUR_FACTURE,
                                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                            RolesMap.FOURNISSEUR_LIVREUR,
                                        ]}
                                        strict
                                    >
                                        <FactureLivreurIndexPage />
                                    </GuardedComponent>
                                }
                            />
                        </Route>
                        <Route path="commandes">
                            <Route
                                index
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.CLIENT_ADMIN,
                                            RolesMap.FOURNISSEUR_FACTURE,
                                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                            RolesMap.FOURNISSEUR_LIVREUR,
                                        ]}
                                        strict
                                    >
                                        <CommandeIndexPage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="need-attention"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.CLIENT_ADMIN,
                                            RolesMap.FOURNISSEUR_FACTURE,
                                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                            RolesMap.FOURNISSEUR_LIVREUR,
                                        ]}
                                        strict
                                    >
                                        <CommandeIndexPage pageType="NeedAttention" />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="transfert"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_RECEPTIONISTE]}
                                        strict
                                    >
                                        <CommandeIndexPage pageType="Transfert" />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="scan-action/transfert"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_RECEPTIONISTE]}
                                        strict
                                    >
                                        <ScanActions title="Transfert commandes" pageType="transfert_cmd" />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="transferts/actions"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_RECEPTIONISTE]}
                                        strict
                                    >
                                        <TransfertRequestsPage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="transferts/factures"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                            RolesMap.FOURNISSEUR_FACTURE,
                                        ]}
                                        strict
                                    >
                                        <FacturesList />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="transferts/toFacture"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                            RolesMap.FOURNISSEUR_FACTURE,
                                        ]}
                                        strict
                                    >
                                        <TransferttoFacture />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="scan-action/affectation-livreur"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_RECEPTIONISTE]}
                                        strict
                                    >
                                        <ScanActions title="Affectation" pageType="affectation_livreur" />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="scan-action/retour-agence"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_RECEPTIONISTE]}
                                        strict
                                    >
                                        <ScanActions title="Retour agence" pageType="return_cmd" />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="draft"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[
                                            RolesMap.CLIENT_ADMIN,
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                        ]}
                                        strict
                                    >
                                        <DraftCommandePage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="draft/new"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[
                                            RolesMap.CLIENT_ADMIN,
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                        ]}
                                        strict
                                    >
                                        <CreateOrUpdateForm />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="draft/edit/:id"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[
                                            RolesMap.CLIENT_ADMIN,
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                        ]}
                                        strict
                                    >
                                        <CreateOrUpdateForm />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="livreur-paiement"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                            RolesMap.FOURNISSEUR_FACTURE,
                                            // RolesMap.FOURNISSEUR_LIVREUR,
                                        ]}
                                        strict
                                    >
                                        <CommandeLivreurPaiementPage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="ready-colis"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.CLIENT_ADMIN]}
                                        strict
                                    >
                                        <PreparedColisPage />
                                    </GuardedComponent>
                                }
                            />
                        </Route>
                        <Route path="colis">
                            <Route
                                index
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                            RolesMap.CLIENT_ADMIN,
                                        ]}
                                        strict
                                    >
                                        <ColisIndexPage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="ramassage"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.FOURNISSEUR_LIVREUR,
                                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                        ]}
                                        strict
                                    >
                                        <ColisRamassagePage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="reception"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_RECEPTIONISTE]}
                                        strict
                                    >
                                        <ColisReceptionPage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="new"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.CLIENT_ADMIN]}
                                        strict
                                    >
                                        <FormColisPage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="edit/:id"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.CLIENT_ADMIN,
                                            RolesMap.FOURNISSEUR_LIVREUR,
                                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                        ]}
                                        strict
                                    >
                                        <FormColisPage />
                                    </GuardedComponent>
                                }
                            />
                        </Route>
                        <Route path="clients">
                            <Route
                                index
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[
                                            RolesMap.SUPER_ADMIN,
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                        ]}
                                    >
                                        <ClientIndexPage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="admin/users"
                                element={
                                    <GuardedComponent guarded rolesRequired={[RolesMap.SUPER_ADMIN]}>
                                        <UserIndexPage pageContent="clients_sa" title="Liste des agents" />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="fournisseur/users"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_RECEPTIONISTE]}
                                        strict
                                    >
                                        <UserIndexPage pageContent="clients_fa" title="Liste des agents" />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="client/users"
                                element={
                                    <GuardedComponent guarded rolesRequired={[RolesMap.CLIENT_ADMIN]} strict>
                                        <UserIndexPage pageContent="clients_ca" title="Liste des agents" />
                                    </GuardedComponent>
                                }
                            />
                        </Route>
                        <Route path="fournisseurs">
                            <Route
                                index
                                element={
                                    <GuardedComponent guarded rolesRequired={[RolesMap.SUPER_ADMIN]}>
                                        <FournisseurIndexPage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="admin/users"
                                element={
                                    <GuardedComponent guarded rolesRequired={[RolesMap.SUPER_ADMIN]}>
                                        <UserIndexPage pageContent="fournisseurs_sa" title="Liste des agents" />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="fournisseur/users"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_RECEPTIONISTE]}
                                        strict
                                    >
                                        <UserIndexPage pageContent="fournisseurs_fa" title="Liste des agents" />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="livreurs-actifs"
                                element={
                                    <GuardedComponent
                                        guarded
                                        rolesRequired={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_RECEPTIONISTE]}
                                        strict
                                    >
                                        <ActiveLivreursPage />
                                    </GuardedComponent>
                                }
                            />
                        </Route>
                        <Route path="settings">
                            <Route
                                path="regions"
                                element={
                                    <GuardedComponent guarded rolesRequired={[RolesMap.SUPER_ADMIN]}>
                                        <RegionPage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="villes"
                                element={
                                    <GuardedComponent guarded rolesRequired={[RolesMap.SUPER_ADMIN]}>
                                        <VillePage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="banques"
                                element={
                                    <GuardedComponent guarded rolesRequired={[RolesMap.SUPER_ADMIN]}>
                                        <BanquePage />
                                    </GuardedComponent>
                                }
                            />
                            {/* <Route
                            path="etatCommandes"
                            element={
                                <GuardedComponent guarded rolesRequired={[RolesMap.SUPER_ADMIN]}>
                                    <EtatCommandePage />
                                </GuardedComponent>
                            }
                        /> */}
                            <Route
                                path="modePaiements"
                                element={
                                    <GuardedComponent guarded rolesRequired={[RolesMap.SUPER_ADMIN]}>
                                        <ModePaiementPage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="optionServices"
                                element={
                                    <GuardedComponent guarded rolesRequired={[RolesMap.SUPER_ADMIN]}>
                                        <OptionServicePage />
                                    </GuardedComponent>
                                }
                            />
                            {/* <Route
                            path="roles"
                            element={
                                <GuardedComponent guarded rolesRequired={[RolesMap.SUPER_ADMIN]}>
                                    <RolePage />
                                </GuardedComponent>
                            }
                        /> */}
                            <Route
                                path="services"
                                element={
                                    <GuardedComponent guarded rolesRequired={[RolesMap.SUPER_ADMIN]}>
                                        <ServicePage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="agences"
                                element={
                                    <GuardedComponent guarded rolesRequired={[RolesMap.FOURNISSEUR_ADMIN]} /*strict*/>
                                        <AgencePage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="emplacements"
                                element={
                                    <GuardedComponent guarded rolesRequired={[RolesMap.FOURNISSEUR_ADMIN]} /*strict*/>
                                        <EmplacementPage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="tarifs"
                                element={
                                    <GuardedComponent guarded rolesRequired={[RolesMap.FOURNISSEUR_ADMIN]} /*strict*/>
                                        <TarifPage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="tariftransferts"
                                element={
                                    <GuardedComponent guarded rolesRequired={[RolesMap.FOURNISSEUR_ADMIN]} strict>
                                        <TarifTransfertPage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="group-villes-clone"
                                element={
                                    <GuardedComponent guarded rolesRequired={[RolesMap.FOURNISSEUR_ADMIN]} strict>
                                        <GroupVilleClonePage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="commissions-livreurs"
                                element={
                                    <GuardedComponent guarded rolesRequired={[RolesMap.FOURNISSEUR_ADMIN]} strict>
                                        <CommissionsLivreurSettingPage />
                                    </GuardedComponent>
                                }
                            />
                            <Route
                                path="etatTitres"
                                element={
                                    <GuardedComponent guarded rolesRequired={[RolesMap.SUPER_ADMIN]}>
                                        <EtatTitrePage />
                                    </GuardedComponent>
                                }
                            />
                        </Route>
                    </Route>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/password/forget" element={<ForgetPasswordPage />} />
                    <Route path="/password/reset/:token" element={<ResetPasswordPage />} />
                    <Route path="/not-found" element={<NotFoundPage />} />
                    <Route path="/not-authorized" element={<NotAuthorizedPage />} />
                    <Route path="*" element={<Navigate to="/not-found" replace />} />
                </Routes>
            </AppContext.Provider>
        </>
    );
};

export default App;
