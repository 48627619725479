import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { Col, Form, Row, Button, Spinner, InputGroup } from 'react-bootstrap';
import Datetime from 'react-datetime';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import Compressor from 'compressorjs';

import PermissionsGate from 'hoc/permissionsGate';
import { ROLES as RolesMap } from 'constantes/permission-maps';

import { MAX_SIZE_IMAGE_UPLOAD } from 'constantes/envLoader';
import { readFileAsync, readImageTobase64 } from 'utils/helpers';
import ImageElement from 'components/UI/imageElement';
import ModePaiementInterface from 'types/interfaces/modePaiement.interface';
import PaiementInterface from 'types/interfaces/paiement.interface';
import FactureInterface from 'types/interfaces/facture.interface';
import { getModePaiements } from 'services/modePaiement.service';
import { getDeepFactureById, savePaiement } from 'services/facture.service';

const schemaPaiement = yup.object({
    idModePaiement: yup.number().required(),
    datePaiement: yup.date().required(),
    montantPaye: yup.number().positive().required(),
    numPiece: yup.string(),
    fileImage: yup.mixed(),
});

type Props = {
    idFacture: number;
    factures: { items: FactureInterface[]; count: number };
    setFactures: (value: { items: FactureInterface[]; count: number }) => void;
};

const FormPaiement: React.FC<Props> = ({ idFacture, factures, setFactures }: Props) => {
    const [modePaiements, setModePaiements] = useState<ModePaiementInterface[]>([]);
    const [facture, setFacture] = useState<FactureInterface | null>(null);
    const [spinIt, setSpinIt] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);

    useEffect(() => {
        getModePaiements()
            .then((response: AxiosResponse<ModePaiementInterface[] | any>) => {
                const { data } = response;
                setModePaiements(data);
            })
            .catch();
    }, []);

    useEffect(() => {
        getDeepFactureById(idFacture)
            .then((response: AxiosResponse<FactureInterface | any>) => {
                const { data } = response;
                setEditMode(!!data.idPaiement);
                setFacture(data);
            })
            .catch();
    }, [idFacture]);

    const submitPaiementForm = async (values: PaiementInterface | any) => {
        try {
            setSpinIt(true);
            if (values.fileImage) {
                try {
                    if (values.fileImage) {
                        const fileResult = await readFileAsync(values.fileImage);
                        values.fileImage =
                            (fileResult as string)?.replace(/^data:image\/(png|jpg|jpeg);base64,/, '') || null;
                    }
                } catch (ex) {
                    values.fileImage = null;
                }
            }
            const response = await savePaiement(facture?.idFacture!, values);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Paiement est modifiée');
                const facturesData = [...factures.items];
                const factureIndex = facturesData.findIndex(
                    (el: FactureInterface) => el.idFacture == resultData.idFacture,
                );
                facturesData[factureIndex] = resultData;
                setFacture(resultData);
                setFactures({ items: facturesData, count: facturesData.length });
                setSpinIt(false);
                setSpinIt(false);
            }
        } catch (Exception) {
            setSpinIt(false);
        }
        setSpinIt(false);
    };
    return (
        <Formik
            validationSchema={schemaPaiement}
            onSubmit={(values: any) => submitPaiementForm(values)}
            enableReinitialize={true}
            initialValues={{
                idPaiement: editMode && facture?.paiement ? facture?.paiement?.idPaiement! : undefined,
                idModePaiement: editMode && facture?.paiement ? facture?.paiement?.idModePaiement! : '',
                datePaiement: editMode && facture?.paiement ? moment(facture?.paiement?.datePaiement) : moment(),
                montantPaye: editMode && facture?.paiement ? facture?.paiement?.montantPaye : facture?.montantFacture!,
                numPiece: editMode && facture?.paiement ? facture?.paiement?.numPiece : '',
                fileImage: null,
            }}
        >
            {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit} className="d-grid gap-2">
                    <Row>
                        <Col md="6">
                            <Row className="align-items-center">
                                <Col auto>
                                    <Form.Label>Num Piece:</Form.Label>
                                </Col>
                                <Col auto>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        name="numPiece"
                                        placeholder="Num Piece"
                                        value={values.numPiece}
                                        onChange={handleChange}
                                        isInvalid={!!errors.numPiece}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md="6">
                            <Row className="align-items-center">
                                <Col auto>
                                    <Form.Label>Date:</Form.Label>
                                </Col>
                                <Col auto>
                                    <Datetime
                                        className="form-control form-control-sm no_form-control_input_style"
                                        inputProps={{
                                            name: 'datePaiement',
                                            className: `text-end form-control form-control-sm ${
                                                !!errors.datePaiement ? 'is-invalid' : ''
                                            }`,
                                            readOnly: true,
                                        }}
                                        timeFormat={false}
                                        dateFormat="DD/MM/YYYY"
                                        value={values.datePaiement}
                                        onChange={(dateValue: any) => {
                                            const e = {
                                                target: {
                                                    value: dateValue,
                                                    name: 'datePaiement',
                                                },
                                            };

                                            if (moment.isMoment(dateValue)) handleChange(e);
                                        }}
                                        closeOnSelect={true}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Row className="align-items-center">
                                <Col auto>
                                    <Form.Label>Montant:</Form.Label>
                                </Col>
                                <Col auto>
                                    <InputGroup size="sm">
                                        <Form.Control
                                            size="sm"
                                            className="text-end"
                                            type="text"
                                            name="montantPaye"
                                            value={values.montantPaye}
                                            onChange={handleChange}
                                        />
                                        <InputGroup.Text>DH</InputGroup.Text>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="6">
                            <Row className="align-items-center">
                                <Col auto>
                                    <Form.Label>Mode de paiement:</Form.Label>
                                </Col>
                                <Col auto>
                                    <InputGroup>
                                        <Form.Select
                                            size="sm"
                                            name="idModePaiement"
                                            value={values.idModePaiement}
                                            onChange={handleChange}
                                            isInvalid={!!errors.idModePaiement}
                                            placeholder="Selectionner un mode de paiement"
                                        >
                                            <option>Selectionner un mode de paiement</option>
                                            {modePaiements.map((d: ModePaiementInterface) => (
                                                <option key={d.idModePaiement} value={d.idModePaiement}>
                                                    {d.descriptionModePaiement}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ span: 6, offset: 3 }}>
                            <PermissionsGate scopes={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_FACTURE]} strict>
                                <Form.Group controlId="form_grp_fileImage">
                                    <Form.Label>Recu</Form.Label>
                                    <InputGroup size="sm">
                                        <Form.Control
                                            size="sm"
                                            type="file"
                                            name="fileImage"
                                            placeholder="Recu"
                                            onChange={(event: any) => {
                                                const image = event.currentTarget.files[0];
                                                if (image.size > MAX_SIZE_IMAGE_UPLOAD) {
                                                    toast.warning(
                                                        'vous ne pouvez pas télécharger une image de plus de 1mo',
                                                    );
                                                    return;
                                                } else if (!image.name.match(/.(png|jpg|jpeg)$/i)) {
                                                    toast.warning("Image Format n'est pas valide");
                                                    return;
                                                }
                                                // Compress the image
                                                new Compressor(image, {
                                                    quality: 0.8, // Adjust compression quality as needed
                                                    success: (compressedImage) => {
                                                        console.log(image.size, compressedImage.size);
                                                        setFieldValue('fileImage', compressedImage);
                                                    },
                                                    error: () => {
                                                        toast.warning("Erreur lors de la compression de l'image");
                                                    },
                                                });
                                                // setFieldValue('fileImage', image);
                                            }}
                                            isInvalid={!!errors.fileImage}
                                            accept="image/png, image/jpg, image/jpeg"
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </PermissionsGate>
                            {values.fileImage ? (
                                <div className="text-center my-3">
                                    <ImageElement file={readImageTobase64(values.fileImage)} alt="file recu" fluid />
                                </div>
                            ) : facture?.paiement?.recuFile ? (
                                <div className="text-center my-3">
                                    <ImageElement
                                        file={readImageTobase64(facture?.paiement?.recuFile.fileImage)}
                                        alt="file recu"
                                        fluid
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </Col>
                    </Row>
                    <PermissionsGate scopes={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_FACTURE]} strict>
                        <div className="d-grid gap-2">
                            <Button variant="primary" type="submit" disabled={!!spinIt}>
                                Enregistrer
                                {!!spinIt && (
                                    <>
                                        &nbsp; <Spinner animation="border" size="sm" />
                                    </>
                                )}
                            </Button>
                        </div>
                    </PermissionsGate>
                </Form>
            )}
        </Formik>
    );
};

export default FormPaiement;
