import React, { useState, useContext } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Button, Form, Spinner, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faPlus } from '@fortawesome/free-solid-svg-icons';
import * as fortAwsimeIcons from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { Formik, FormikHelpers } from 'formik';
import Datetime from 'react-datetime';
import * as yup from 'yup';
import moment from 'moment';

import { canControlCommande, getDisplayNameForUserOrSecretName } from 'utils/helpers';
import { saveCommandeAction } from 'services/commandeAction.service';
import CommandeActionInterface from 'types/interfaces/commandeAction.interface';
import CommandeInterface from 'types/interfaces/commande.interface';
import EtatTitreInterface from 'types/interfaces/etatTitre.interface';

import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';

const schema = yup.object().shape({
    idEtatTitre: yup.number().required(),
    // description: yup.string().required(),
    description_date: yup
        .date()
        .nullable()
        .when('idEtatTitre', {
            is: (idEtatTitre: any) => ['2', '19'].includes(idEtatTitre?.toString()),
            then: yup.date().required(),
        }),
    description_text: yup
        .string()
        .nullable()
        .when('idEtatTitre', {
            is: (idEtatTitre: any) => !['2', '19'].includes(idEtatTitre?.toString()),
            then: yup.string().required(),
        }),
    idCommande: yup.number().required(),
});

const TimeLineWrapper = styled.div`
    margin-bottom: 1rem;
    [class*='tracking-status-'] p {
        margin: 0;
        font-size: 1.1rem;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
    }
    [class*='tracking-status-'] {
        padding: 5px 0;
    }
    .tracking-status-intransit {
        background-color: #65aee0;
    }
    .tracking-status-outfordelivery {
        background-color: #f5a551;
    }
    .tracking-status-deliveryoffice {
        background-color: #f7dc6f;
    }
    .tracking-status-delivered {
        background-color: #4cbb87;
    }
    .tracking-status-attemptfail {
        background-color: #b789c7;
    }
    .tracking-status-error,
    .tracking-status-exception {
        background-color: #d26759;
    }
    .tracking-status-expired {
        background-color: #616e7d;
    }
    .tracking-status-pending {
        background-color: #ccc;
    }
    .tracking-status-inforeceived {
        background-color: #214977;
    }
    .tracking-list {
        border: 1px solid #e5e5e5;
    }
    .tracking-item {
        border-left: 1px solid #e5e5e5;
        position: relative;
        padding: 1rem 1rem 0.5rem 2rem;
        font-size: 0.8rem;
        margin-left: 3rem;
        min-height: 5rem;
    }
    .tracking-item:last-child {
        padding-bottom: 4rem;
    }
    .tracking-item .tracking-content {
        padding: 0.5rem 0.8rem;
        background-color: #f4f4f4;
        border-radius: 0.5rem;
    }
    .tracking-item .tracking-content span.tracking_date {
        display: block;
        color: #888;
        font-size: 85%;
    }
    .tracking-item .tracking-content span:last-child {
        display: block;
    }
    .tracking-item .tracking-icon {
        line-height: 2.6rem;
        position: absolute;
        left: -1.3rem;
        width: 2.6rem;
        height: 2.6rem;
        text-align: center;
        border-radius: 50%;
        font-size: 1.1rem;
        background-color: #fff;
        color: #fff;
    }
    [class*='tracking-icon status-'] {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .tracking-item .tracking-icon.status-8 {
        background-color: #f68;
    }
    .tracking-item .tracking-icon.status-7 {
        background-color: #214977;
    }
    .tracking-item .tracking-icon.status-6 {
        background-color: #f7dc6f;
    }
    .tracking-item .tracking-icon.status-5 {
        background-color: #b789c7;
    }
    .tracking-item .tracking-icon.status-4 {
        background-color: #f5a551;
    }
    .tracking-item .tracking-icon.status-3 {
        background-color: #d26759;
    }
    .tracking-item .tracking-icon.status-2 {
        background-color: #4cbb87;
    }
    .tracking-item .tracking-icon.status-1 {
        color: #e5e5e5;
        border: 1px solid #e5e5e5;
        font-size: 0.6rem;
    }
    @media (min-width: 992px) {
        .tracking-item .tracking-content {
            padding: 0;
            background-color: transparent;
        }
    }
`;

const TimeLineSVG: React.FC = () => (
    <svg
        className="svg-inline--fa fa-circle fa-w-16"
        aria-hidden="true"
        data-prefix="fas"
        data-icon="circle"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        data-fa-i2svg=""
    >
        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
    </svg>
);

type Props = {
    data: CommandeActionInterface[];
    title: string;
    commande: CommandeInterface;
    commandes: { items: CommandeInterface[]; count: number };
    updateCommande: (data: CommandeInterface) => void;
    updateCommandesList: (data: { items: CommandeInterface[]; count: number }) => void;
    etatTitres: EtatTitreInterface[];
    useIconInsteadOfSVG?: boolean;
};

const TimeLine: React.FC<Props> = ({
    data,
    title,
    commande,
    commandes,
    updateCommande,
    updateCommandesList,
    etatTitres,
    useIconInsteadOfSVG = false,
}: Props) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    const [showForm, setShowForm] = useState<boolean>(false);

    const submitForm = async (values: any, { setSubmitting }: FormikHelpers<any>) => {
        try {
            setSubmitting(true);
            const realValues = {
                idEtatTitre: values.idEtatTitre,
                description: !['2', '19'].includes(values.idEtatTitre)
                    ? values.description_text
                    : `${values.description_date.format('DD/MM/YYYY')}${
                          values.description_text ? ' - ' + values.description_text : ''
                      }`,
                idCommande: values.idCommande,
            };
            const response = await saveCommandeAction(realValues as CommandeActionInterface);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Action est ajoutée');
                const updatedCmd = { ...commande, commandeActions: [...commande.commandeActions, resultData] };
                updateCommande(updatedCmd);
                setShowForm(false);
                const cmdData: CommandeInterface[] = [...commandes.items].map((dd) =>
                    dd.idCommande === commande.idCommande ? updatedCmd : dd,
                );
                updateCommandesList({ items: cmdData, count: commandes.count });
            }
        } catch (Exception) {
            setSubmitting(false);
            const { message = undefined }: any = Exception;
            toast.error(message || 'Error occured!');
        }
    };
    return (
        <>
            <Collapse timeout={1000} in={showForm}>
                <div>
                    {showForm && (
                        <Formik
                            validationSchema={schema}
                            onSubmit={submitForm}
                            enableReinitialize={true}
                            initialValues={{
                                idEtatTitre: '',
                                description_date: undefined,
                                description_text: '',
                                idCommande: commande?.idCommande!,
                            }}
                        >
                            {({ handleSubmit, handleChange, values, errors, isSubmitting }) => (
                                <Form noValidate onSubmit={handleSubmit} id="formTimeLine" className="clearfix mb-2">
                                    <h5>Ajouter une action</h5>
                                    <Form.Group controlId="form_grp_idEtatTitre">
                                        <Form.Label>Titre</Form.Label>
                                        <Form.Select
                                            size="sm"
                                            name="idEtatTitre"
                                            placeholder="Selectionner un titre"
                                            value={values.idEtatTitre}
                                            onChange={(e) => {
                                                if (['2', '19'].includes(e.target.value.toString())) {
                                                    handleChange({
                                                        target: {
                                                            value: moment(),
                                                            name: 'description_date',
                                                        },
                                                    });
                                                    handleChange({
                                                        target: {
                                                            value: '',
                                                            name: 'description_text',
                                                        },
                                                    });
                                                } else if (['2', '19'].includes(values.idEtatTitre.toString())) {
                                                    handleChange({
                                                        target: {
                                                            value: undefined,
                                                            name: 'description_date',
                                                        },
                                                    });
                                                    handleChange({
                                                        target: {
                                                            value: '',
                                                            name: 'description_text',
                                                        },
                                                    });
                                                }
                                                handleChange(e);
                                            }}
                                            isInvalid={!!errors.idEtatTitre}
                                        >
                                            <option value="">Selectionner un titre</option>
                                            {etatTitres
                                                .filter(
                                                    (d: EtatTitreInterface) =>
                                                        d.hideSelection === false &&
                                                        data.length > 0 &&
                                                        d.idEtatTitre !== (data as any)[0].idEtatTitre,
                                                )
                                                .map((d: EtatTitreInterface) => (
                                                    <option key={d.idEtatTitre} value={d.idEtatTitre}>
                                                        {d.titre}
                                                    </option>
                                                ))}
                                        </Form.Select>
                                    </Form.Group>
                                    {['2', '19'].includes(values.idEtatTitre.toString()) && (
                                        <Form.Group controlId="form_grp_date_reporte">
                                            <Form.Label>Reporter à:</Form.Label>
                                            <Datetime
                                                inputProps={{
                                                    name: 'description_date',
                                                    className: 'form-control form-control-sm',
                                                    readOnly: true,
                                                }}
                                                onChange={(e: any) =>
                                                    handleChange({
                                                        target: {
                                                            value: e,
                                                            name: 'description_date',
                                                        },
                                                    })
                                                }
                                                value={values.description_date}
                                                dateFormat="DD/MM/YYYY"
                                                timeFormat="HH:mm"
                                                closeOnSelect
                                            />
                                        </Form.Group>
                                    )}
                                    <Form.Group controlId="form_grp_description">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            as="textarea"
                                            rows={2}
                                            name="description_text"
                                            placeholder="Description_text"
                                            value={values.description_text}
                                            onChange={handleChange}
                                            isInvalid={!!errors.description_text}
                                        />
                                    </Form.Group>

                                    <div className="d-flex gap-2 mt-3 justify-content-end">
                                        <Button
                                            size="sm"
                                            variant="secondary"
                                            type="button"
                                            disabled={isSubmitting}
                                            onClick={() => setShowForm(false)}
                                        >
                                            Annuler
                                            {isSubmitting && (
                                                <>
                                                    &nbsp; <Spinner animation="border" size="sm" />
                                                </>
                                            )}
                                        </Button>
                                        <Button size="sm" variant="primary" type="submit" disabled={isSubmitting}>
                                            Enregistrer
                                            {isSubmitting && (
                                                <>
                                                    &nbsp; <Spinner animation="border" size="sm" />
                                                </>
                                            )}
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                </div>
            </Collapse>
            <TimeLineWrapper>
                <div className="text-center tracking-status-intransit my-title-block-header p-3">
                    <p className="tracking-status text-tight">{title}</p>
                    <div className="clearfix">
                        {commande?.idEtatCommande! < 4 &&
                            (contextUser?.userData.type === 'Client' ||
                                canControlCommande(contextUser?.userData?.idFournisseur!, commande!)) && (
                                <Button
                                    variant="outline-secondary"
                                    className="button-input-group-effect my-2 float-end"
                                    id="basic-addon1"
                                    onClick={() => setShowForm(true)}
                                >
                                    <FontAwesomeIcon icon={faPlus} size="xs" style={{ color: '#337ab7' }} />
                                </Button>
                            )}
                    </div>
                </div>
                <div className="tracking-list">
                    {data.map((obj: CommandeActionInterface) => (
                        <div className="tracking-item" key={obj.idCommandeAction}>
                            <div
                                className={`tracking-icon status-1`}
                                style={{
                                    color: obj.etatTitre?.textColor,
                                    backgroundColor: obj.etatTitre?.backgroundColor,
                                }}
                            >
                                {useIconInsteadOfSVG ? (
                                    <>
                                        <FontAwesomeIcon
                                            icon={(fortAwsimeIcons as any)[obj.etatTitre?.fortAwsomeIcon!]}
                                            size="lg"
                                        />
                                    </>
                                ) : (
                                    <TimeLineSVG />
                                )}
                            </div>
                            <div className="tracking-content">
                                <b>{obj.etatTitre?.titre}</b>
                                <span className="tracking_date">
                                    <FontAwesomeIcon icon={faClock} size="xs" /> &nbsp;
                                    {dayjs(obj.dateAction).format('DD/MM/YYYY HH:mm')}
                                </span>
                                <span>{obj.description}</span>
                                <span style={{ fontSize: '0.7rem' }}>
                                    par:{' '}
                                    <strong>
                                        {getDisplayNameForUserOrSecretName(
                                            contextUser?.userData!,
                                            obj.user!,
                                            commande!,
                                        )}
                                    </strong>
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </TimeLineWrapper>
        </>
    );
};

export default TimeLine;
