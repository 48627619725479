import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { Button, Form, Spinner, Row, Col } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

const schema = yup.object({
    idOptionService: yup.number().required(),
    idsVille: yup.array().of(yup.number().required()).min(1).required(),
    idsLivreur: yup.array().of(yup.number().required()).min(1).required(),
    prix: yup.number().required(),
});

import UserInterface from 'types/interfaces/user.interface';
import RegionInterface from 'types/interfaces/region.interface';
import CommissionsLivreurInterface, {
    CommissionsLivreurFormDataRequest,
} from 'types/interfaces/commissionsLivreur.interface';
import { getRegionsWVilles } from 'services/region.service';
import { getOptionServices } from 'services/optionService.service';
import { getCommissionEmployeTypeLivreurs } from 'services/user.service';
import { getCommissionsLivreursFiltered, createMultipleCommissionsLivreur } from 'services/commissionsLivreur.service';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import TitleElement from 'components/UI/titleElement';
import TableTemplate from 'components/shared/datatable/tableTemplate';
import CustomModal from 'components/UI/customModal';
import OptionServiceInterface from 'types/interfaces/optionService.interface';
import MultiSelectGroupedComponent from 'components/UI/multiSelectGroupedComponent';
import {
    groupingOptionsRegionVilles,
    getUsersLabelOptions,
    b64_to_utf8,
    utf8_to_b64,
    buildUrlQueryParams,
    useQueryMeMo,
    isEmptyObject,
    DisplayName,
} from 'utils/helpers';
import Paginator from 'components/UI/paginator';
import VilleInterface from 'types/interfaces/ville.interface';

const TOTAL_DATA_PER_PAGE = 100;

const CommissionsLivreurSettingPage: React.FC = () => {
    const [livreurs, setLivreurs] = useState<UserInterface[]>([]);
    const [regions, setRegions] = useState<RegionInterface[]>([]);
    const [optionServices, setOptionServices] = useState<OptionServiceInterface[]>([]);
    const [commissionsLivreurs, setCommissionsLivreurs] = useState<{
        items: CommissionsLivreurInterface[];
        count: number;
    }>({ items: [], count: 0 });

    const [selectedLivreur, setSelectedLivreur] = useState<string | null>(null);
    const [selectedVille, setSelectedVille] = useState<string | null>(null);
    const [activePage, setActivePage] = useState<number>(1);
    const [showCLModal, setShowCLModal] = useState<boolean>(false);

    const query = useQueryMeMo();
    const history = useNavigate();

    const openCLModal = () => {
        setShowCLModal(true);
    };

    const closeCLModal = () => {
        setShowCLModal(false);
    };

    useEffect(() => {
        document.title = `Commissions livreurs - DS`;

        getRegionsWVilles()
            .then((response: AxiosResponse<RegionInterface[] | any>) => {
                const { data } = response;
                setRegions(data);
            })
            .catch();

        getCommissionEmployeTypeLivreurs()
            .then((response: AxiosResponse<UserInterface[] | any>) => {
                const { data } = response;
                setLivreurs(data);
            })
            .catch();

        getOptionServices()
            .then((response: AxiosResponse<OptionServiceInterface | any>) => {
                const { data } = response;
                setOptionServices(data);
            })
            .catch();
    }, []);

    const handlePageNFilterChange = (activePage: number, searchedData?: string) => {
        const urlObject: any = {};
        if (activePage > 1) urlObject['page'] = activePage;
        if (searchedData) {
            const parsedObject = JSON.parse(searchedData);
            Object.keys(parsedObject).forEach((key) => {
                if (parsedObject[key as any]) {
                    if ((key === 'livreur' || key === 'ville') && parsedObject[key as any])
                        urlObject[key] = utf8_to_b64(parsedObject[key as any]);
                    else urlObject[key] = parsedObject[key as any];
                }
            });
        }
        history(isEmptyObject(urlObject) ? window.location.pathname : `?${buildUrlQueryParams(urlObject)}`);
    };
    const loadCommissionsLivreur = (idVille?: string, idLivreur?: string, page?: number, total?: number) => {
        getCommissionsLivreursFiltered(total!, page!, idLivreur, idVille)
            .then((response: AxiosResponse<CommissionsLivreurInterface[] | any>) => {
                const { data } = response;
                setCommissionsLivreurs({
                    items: data.items,
                    count: data.count,
                });
            })
            .catch();
    };

    useEffect(() => {
        const page = query.get('page');
        const _livreur = query.get('livreur');
        const _ville = query.get('ville');

        const _currentLivreur = _livreur ? b64_to_utf8(_livreur) : undefined;
        const _currentVille = _ville ? b64_to_utf8(_ville) : undefined;

        const currentPage = page ? +page : 1;

        setActivePage(currentPage);
        setSelectedVille(_currentVille!);
        setSelectedLivreur(_currentLivreur!);

        const loadData = async () => {
            loadCommissionsLivreur(_currentVille, _currentLivreur, currentPage, TOTAL_DATA_PER_PAGE);
        };
        loadData();
    }, [query, selectedLivreur, selectedVille]);

    const arrayColumns = useMemo(
        () => [
            {
                Header: 'Livreur',
                accessor: 'livreur.nom',
                Cell: ({ row: { original } }: { row: { original: CommissionsLivreurInterface } }) =>
                    DisplayName(original.livreur!),
            },
            {
                Header: 'Ville',
                accessor: 'ville.nomVille',
            },
            {
                Header: 'Service',
                accessor: 'optionService.nameOptionService',
            },
            {
                Header: 'Prix',
                accessor: 'prix',
            },
            {
                Header: 'Date',
                accessor: 'dateCreation',
                Cell: ({ row: { original } }: { row: { original: CommissionsLivreurInterface } }) =>
                    dayjs(original.dateCreation).format('DD/MM/YYYY HH:mm'),
            },
        ],
        [commissionsLivreurs.items],
    );

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { text: 'Paramétrage' },
        { active: true, text: 'Commissions livreurs' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Commissions livreurs
                </TitleElement>
                <div className="d-flex gap-1">
                    <Button
                        variant="outline-secondary"
                        className="button-input-group-effect"
                        id="basic-addon1"
                        onClick={() => openCLModal()}
                    >
                        <FontAwesomeIcon icon={faAdd} size="xs" style={{ color: '#337ab7' }} />
                    </Button>
                </div>
            </div>

            <Row className="mb-2 holdit_notEffect">
                <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_Livreur">
                    <Form.Label>Livreur:</Form.Label>
                    <Form.Select
                        size="sm"
                        name="selectedLivreur"
                        value={selectedLivreur!}
                        onChange={(e) => {
                            const {
                                target: { value },
                            } = e;
                            setSelectedLivreur(value);
                            handlePageNFilterChange(
                                1,
                                JSON.stringify({
                                    ville: selectedVille,
                                    livreur: value,
                                }),
                            );
                        }}
                        placeholder="Selectionner un livreur"
                    >
                        <option value="">Selectionner un livreur</option>
                        {livreurs.map((d: UserInterface) => (
                            <option key={d.idUser} value={d.idUser}>
                                {DisplayName(d)}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_Livreur">
                    <Form.Label>Ville:</Form.Label>
                    <Form.Select
                        size="sm"
                        name="selectedVille"
                        value={selectedVille!}
                        onChange={(e) => {
                            const {
                                target: { value },
                            } = e;
                            setSelectedVille(value);
                            handlePageNFilterChange(
                                1,
                                JSON.stringify({
                                    ville: value,
                                    livreur: selectedLivreur,
                                }),
                            );
                        }}
                        placeholder="Selectionner une ville"
                    >
                        <option value="">Selectionner un livreur</option>
                        {regions.map((dr: RegionInterface) => (
                            <optgroup key={dr.idRegion} label={dr.nomRegion}>
                                {dr.villes?.map((d: VilleInterface) => (
                                    <option key={d.idVille} value={d.idVille}>
                                        {d.nomVille}
                                    </option>
                                ))}
                            </optgroup>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Row>

            <TableTemplate
                columns={arrayColumns}
                data={commissionsLivreurs.items}
                options={{
                    pagination: true,
                    showGlobalFilter: false,
                    debugData: false,
                    showFiltersRowByColumn: true,
                    rowSelecting: false,
                    hiddenColumns: ['userId'],
                }}
            >
                <Row>
                    <Col className="d-flex flex-col justify-content-end align-items-end">
                        <Paginator
                            defaultPage={activePage}
                            totalPages={Math.ceil(commissionsLivreurs.count / TOTAL_DATA_PER_PAGE)}
                            callback={(e_page) =>
                                handlePageNFilterChange(
                                    e_page,
                                    JSON.stringify({
                                        livreur: selectedLivreur,
                                        ville: selectedVille,
                                    }),
                                )
                            }
                        />
                    </Col>
                </Row>
            </TableTemplate>

            <CustomModal title={`Nouvelle Commission`} size="lg" show={showCLModal} handleClose={closeCLModal}>
                <CommissionsLivreursForm
                    close={() => closeCLModal()}
                    regions={regions}
                    livreurs={livreurs}
                    optionServices={optionServices}
                    selectedLivreur={selectedLivreur!}
                    selectedVille={selectedVille!}
                    handlePageNFilterChange={handlePageNFilterChange}
                    setList={setCommissionsLivreurs}
                />
            </CustomModal>
        </>
    );
};

export default CommissionsLivreurSettingPage;

type VilleGroupementFormType = {
    close: () => void;
    regions: RegionInterface[];
    livreurs: UserInterface[];
    optionServices: OptionServiceInterface[];
    selectedVille?: number | string;
    selectedLivreur?: number | string;
    handlePageNFilterChange: (activePage: number, searchedData?: string) => void;
    setList: Dispatch<SetStateAction<{ items: CommissionsLivreurInterface[]; count: number }>>;
};

const CommissionsLivreursForm: React.FC<VilleGroupementFormType> = ({
    close,
    regions,
    livreurs,
    optionServices,
    selectedVille,
    selectedLivreur,
    handlePageNFilterChange,
    setList,
}: VilleGroupementFormType) => {
    const [spinIt, setSpinIt] = useState<boolean>();

    const submitForm = async (values: CommissionsLivreurFormDataRequest) => {
        try {
            setSpinIt(true);
            const response = await createMultipleCommissionsLivreur(values, selectedLivreur, selectedVille);
            if (response.status === 200) {
                toast.success('Commissions enregistrée(s) avec succès');
                setList(response.data);
                handlePageNFilterChange(
                    1,
                    JSON.stringify({
                        livreur: selectedLivreur,
                        ville: selectedVille,
                    }),
                );
                close();
                setSpinIt(false);
            }
        } catch (Exception) {
        } finally {
            setSpinIt(false);
        }
    };

    return (
        <Formik
            validationSchema={schema}
            onSubmit={(values) => submitForm(values as any)}
            enableReinitialize={true}
            initialValues={{
                idOptionService: undefined,
                idsLivreur: [],
                idsVille: [],
                prix: 0,
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Row>
                        <Form.Group as={Col} md="6" controlId="form_grp_ddd">
                            <Form.Label>OptionServices</Form.Label>
                            <Form.Select
                                name="idOptionService"
                                value={values.idOptionService}
                                onChange={handleChange}
                                isInvalid={!!errors.idOptionService}
                                placeholder="Selectionner un optionService"
                            >
                                <option>Selectionner une Option de Service</option>
                                {optionServices.map((d: OptionServiceInterface) => (
                                    <option key={d.idOptionService} value={d.idOptionService}>
                                        {d.nameOptionService}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md={6} controlId="form_grp_commisionLivreur">
                            <Form.Label>Commission</Form.Label>
                            <Form.Control
                                type="text"
                                name="prix"
                                placeholder="Commission"
                                value={values.prix}
                                onChange={handleChange}
                                isInvalid={!!errors.prix}
                            />
                            <Form.Control.Feedback type="invalid">{errors.prix}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Form.Group controlId="form_grp_livreurs">
                        <Form.Label>Livreurs</Form.Label>
                        <MultiSelectGroupedComponent
                            options={getUsersLabelOptions('Livreurs', livreurs)}
                            placeholder="Selectionner des livreurs"
                            onChange={(selectedItems: string[]) => {
                                handleChange({ target: { value: selectedItems, name: 'idsLivreur' } });
                            }}
                            classNames={!!errors.idsLivreur ? 'is-invalid' : ''}
                        />
                        <Form.Control.Feedback type="invalid">{errors.idsLivreur}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="form_grp_dd">
                        <Form.Label>Villes</Form.Label>
                        <MultiSelectGroupedComponent
                            options={groupingOptionsRegionVilles(regions)}
                            placeholder="Selectionner des villes"
                            onChange={(selectedItems: string[]) => {
                                handleChange({ target: { value: selectedItems, name: 'idsVille' } });
                            }}
                            classNames={!!errors.idsVille ? 'is-invalid' : ''}
                        />
                        <Form.Control.Feedback type="invalid">{errors.idsVille}</Form.Control.Feedback>
                    </Form.Group>
                    <div className="d-grid gap-2 mt-3">
                        <Button variant="primary" type="submit" disabled={!!spinIt}>
                            Enregistrer
                            {!!spinIt && (
                                <>
                                    &nbsp; <Spinner animation="border" size="sm" />
                                </>
                            )}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
