import { matchSorter } from 'match-sorter';

export const fuzzyTextFilterFn = (rows, id, filterValue) => {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
};

//  Let the table remove the filter if the string is empty
// fuzzyTextFilterFn.autoRemove = (val) => !val;

export const filterGreaterThan = (rows, id, filterValue) => {
    return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue >= filterValue;
    });
};
