import React from 'react';
import UserFormFournisseursSA from 'pages/Users/components/admin/fournisseurs';
import UserFormClientsSA from 'pages/Users/components/admin/clients';
import UserFormFournisseursFA from 'pages/Users/components/fournisseur/fournisseurs';
import UserFormClientsFA from 'pages/Users/components/fournisseur/clients';
import UserFormClientsCA from 'pages/Users/components/client/clients';

type Props = any;

const UserFormLoader: React.FC<Props> = ({ pageContent, ...props }: Props) => {
    return (
        <>
            {(() => {
                switch (pageContent) {
                    case 'fournisseurs_sa':
                        return <UserFormFournisseursSA {...props} />;
                    case 'clients_sa':
                        return <UserFormClientsSA {...props} />;
                    case 'fournisseurs_fa':
                        return <UserFormFournisseursFA {...props} />;
                    case 'clients_fa':
                        return <UserFormClientsFA {...props} />;
                    case 'clients_ca':
                        return <UserFormClientsCA {...props} />;
                    default:
                        return null;
                }
            })()}
        </>
    );
};

export default UserFormLoader;
