import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { Button, Form, Spinner } from 'react-bootstrap';
import { TableColumnType } from 'react-bs-datatable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';

import ModePaiementInterface from 'types/interfaces/modePaiement.interface';
import { getModePaiements, saveModePaiement } from 'services/modePaiement.service';

import CustomModal from 'components/UI/customModal';
import Datatable from 'components/shared/datatable';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import TitleElement from 'components/UI/titleElement';

const schema = yup.object({
    idModePaiement: yup.number(),
    descriptionModePaiement: yup.string().required(),
});

const ModePaiementPage: React.FC = () => {
    const [modePaiements, setModePaiements] = useState<ModePaiementInterface[]>([]);
    const [currentModePaiement, setCurrentModePaiement] = useState<ModePaiementInterface | null>(null);

    const [showFormModal, setShowFormModal] = useState<boolean>(false);
    const [formModalEditMode, setFormModalEditMode] = useState<boolean>(false);
    const [spinIt, setSpinIt] = useState<boolean>(false);

    const handleFormModalClose = () => {
        setCurrentModePaiement(null);
        setShowFormModal(false);
    };

    const handleFormModalOpen = (editMode = false, modePaiementsData?: ModePaiementInterface) => {
        if (modePaiementsData) setCurrentModePaiement(modePaiementsData);
        setFormModalEditMode(editMode);
        setShowFormModal(true);
    };

    const tableHeaders: TableColumnType<any>[] = [
        {
            prop: 'descriptionModePaiement',
            title: 'Nom',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'null',
            title: 'Action',
            alignment: { horizontal: 'right' },
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: ModePaiementInterface) => (
                <Button
                    variant="outline-secondary"
                    className="button-input-group-effect"
                    id="basic-addon1"
                    onClick={() => handleFormModalOpen(true, row)}
                >
                    <FontAwesomeIcon icon={faEdit} size="xs" style={{ color: '#337ab7' }} />
                </Button>
            ),
            isFilterable: false,
            isSortable: false,
        },
    ];

    useEffect(() => {
        document.title = 'Paramétrage ModePaiement - DS';

        getModePaiements()
            .then((response: AxiosResponse<ModePaiementInterface | any>) => {
                const { data } = response;
                setModePaiements(data);
            })
            .catch();
    }, []);

    const submitForm = async (modePaiementValues: ModePaiementInterface | any) => {
        try {
            setSpinIt(true);
            const response = await saveModePaiement(modePaiementValues);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('ModePaiement a été enregistré avec succès');
                const modePaiementsData = [...modePaiements].filter(
                    (dd) => dd.idModePaiement !== resultData.idModePaiement,
                );
                modePaiementsData.unshift(resultData);
                setModePaiements(modePaiementsData);
                handleFormModalClose();
                setSpinIt(false);
            }
        } catch (Exception) {
            setSpinIt(false);
        }
    };

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { text: 'Paramétrage' },
        { active: true, text: 'ModePaiement' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    ModePaiements
                </TitleElement>
                <Button
                    variant="outline-secondary"
                    className="button-input-group-effect"
                    id="basic-addon1"
                    onClick={() => handleFormModalOpen()}
                >
                    <FontAwesomeIcon icon={faPlus} size="xs" style={{ color: '#337ab7' }} />
                </Button>
            </div>
            <Datatable data={modePaiements} tableColumns={tableHeaders} />
            <CustomModal
                title={`${!!formModalEditMode ? 'Edit' : 'Nouveau'} ModePaiement`}
                size="lg"
                show={showFormModal}
                handleClose={handleFormModalClose}
            >
                <div>
                    <Formik
                        validationSchema={schema}
                        onSubmit={(values) => submitForm(values)}
                        enableReinitialize={true}
                        initialValues={{
                            idModePaiement:
                                currentModePaiement && !!formModalEditMode
                                    ? currentModePaiement.idModePaiement
                                    : undefined,
                            descriptionModePaiement:
                                currentModePaiement && !!formModalEditMode
                                    ? currentModePaiement.descriptionModePaiement
                                    : '',
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                {values.idModePaiement && (
                                    <Form.Control
                                        type="hidden"
                                        name="idModePaiement"
                                        value={values.idModePaiement}
                                        readOnly={true}
                                    />
                                )}
                                <Form.Group controlId="form_grp_nom">
                                    <Form.Label>Description ModePaiement</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="descriptionModePaiement"
                                        placeholder="ModePaiement"
                                        value={values.descriptionModePaiement}
                                        onChange={handleChange}
                                        isInvalid={!!errors.descriptionModePaiement}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.descriptionModePaiement}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className="d-grid gap-2 mt-3">
                                    <Button variant="primary" type="submit" disabled={!!spinIt}>
                                        Enregistrer
                                        {!!spinIt && (
                                            <>
                                                &nbsp; <Spinner animation="border" size="sm" />
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </CustomModal>
        </>
    );
};

export default ModePaiementPage;
