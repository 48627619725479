export const months = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
];

export const years = () => {
    const max = new Date().getFullYear();
    const min = 2022;
    const years = [];

    for (let i = max; i >= min; i--) {
        years.push(i);
    }
    return years;
};
