import React from 'react';
import {
    Chart as ChartJS,
    ArcElement,
    BarElement,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Legend,
    Title,
    Tooltip,
    Filler,
} from 'chart.js';
import { Line, Bar, Pie, Doughnut } from 'react-chartjs-2';
import colorsList from 'utils/colorsList';

ChartJS.register(
    ArcElement,
    BarElement,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Title,
    Tooltip,
    Filler,
    Legend,
);

type Props = {
    type: 'Bar' | 'Line' | 'Pie' | 'Doughnut';
    data: any;
    options?: any;
};

const components = {
    Bar,
    Line,
    Pie,
    Doughnut,
};

const Charts: React.FC<Props> = ({ type, ...props }: Props) => {
    const ChartComponenet = components[type];
    return <ChartComponenet {...props} />;
};

export default Charts;

type chartDataType = {
    title?: string;
    position?: 'bottom' | 'top';
    labels: string[];
    data: number[];
};

export const PieData = ({ title, position = 'bottom', labels, data }: chartDataType) => ({
    options: {
        // maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position,
            },
            title: {
                display: true,
                text: title,
            },
        },
    },
    data: {
        labels,
        datasets: [
            {
                data,
                backgroundColor: Array.from(Array(6)).map((x, i: number) => colorsList[i].transparent),
                borderColor: Array.from(Array(6)).map(() => '#bbb'),
                borderWidth: 1,
            },
        ],
    },
});

export const DoughnutData = ({ title, position = 'bottom', labels, data }: chartDataType) => ({
    options: {
        // maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position,
            },
            title: {
                display: true,
                text: title,
            },
        },
    },
    data: {
        labels,
        datasets: [
            {
                data,
                backgroundColor: Array.from(Array(6)).map((x, i: number) => colorsList[i].transparent),
                borderColor: Array.from(Array(6)).map(() => '#bbb'),
                borderWidth: 1,
            },
        ],
    },
});

export const LineData = ({ title, labels, data }: chartDataType) => ({
    options: {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: title,
            },
        },
    },
    data: {
        labels,
        datasets: [
            {
                fill: true,
                data,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    },
});

export const BarData = ({ title, labels, data }: chartDataType) => ({
    options: {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: title,
            },
        },
    },
    data: {
        labels,
        datasets: [
            {
                data,
                backgroundColor: Array.from(Array(7)).map((x, i: number) => colorsList[i].originalColor),
            },
        ],
    },
});
