import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import RoleInterface from 'types/interfaces/role.interface';

const apiEndPoint = `${apiUrl}/roles`;

export const getRoles = (): Promise<RoleInterface[] | any> => {
    return http.get(apiEndPoint);
};

export const saveRole = (role: RoleInterface) => {
    const body = {
        ...role,
    };
    if (role.idRole) {
        return http.put(`${apiEndPoint}/${role.idRole}`, body);
    }
    return http.post(`${apiEndPoint}`, body);
};
