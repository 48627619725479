import React, { useContext, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Col, Form, Row, Button, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import CommandeInterface from 'types/interfaces/commande.interface';
import UserInterface from 'types/interfaces/user.interface';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import { getLivreursByFournisseur } from 'services/user.service';
import { DisplayName } from 'utils/helpers';
import useUserStore, { StoreStateInterface } from 'store/appStore';
import { affecterALivreurList } from 'services/commande.service';
import ClockInput from 'components/shared/clockInput';

export const schema = yup.object({
    ids: yup.array().of(yup.number().required()).min(1).required(),
    idLivreur: yup.number().required(),
    remarque: yup.string(),
    idEtatTitre: yup.number(),
});

type Props = {
    commandes: CommandeInterface[];
    setCommandes: React.Dispatch<React.SetStateAction<CommandeInterface[]>>;
};

const AffectationLivreur: React.FC<Props> = ({ commandes, setCommandes }: Props) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    const setLoadingFalse = useUserStore((state: StoreStateInterface) => state.setLoadingFalse);
    const setLoadingTrue = useUserStore((state: StoreStateInterface) => state.setLoadingTrue);

    const [livreurs, setLivreurs] = useState<UserInterface[]>([]);
    useEffect(() => {
        if (contextUser)
            getLivreursByFournisseur(contextUser?.userData?.idFournisseur!)
                .then((response: AxiosResponse<UserInterface[] | any>) => {
                    const { data } = response;
                    setLivreurs(data);
                })
                .catch();
    }, [contextUser]);

    const submit = async (livraisonValues: any, { setSubmitting }: any) => {
        const livreurData = livreurs.find((d: UserInterface) => d.idUser == livraisonValues.idLivreur);
        livraisonValues.remarque = `${
            livraisonValues.remarque ? livraisonValues.remarque + '\nPS: ' : ''
        }Affectée à ${DisplayName(livreurData!)}`;
        try {
            setLoadingTrue();
            setSubmitting(true);
            const response = await affecterALivreurList(livraisonValues);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Commande(s) a été affecté(s) à un livreur.');
                setCommandes((prev: CommandeInterface[]) =>
                    prev.filter((commande: CommandeInterface) => !resultData.includes(commande.idCommande)),
                );
            }
        } catch (Exception) {
        } finally {
            setLoadingFalse();
            setSubmitting(false);
        }
    };

    return (
        <div className="d-flex justify-content-end">
            {commandes.length > 0 && (
                <Formik
                    validationSchema={schema}
                    onSubmit={(values, { setSubmitting }) => submit(values, { setSubmitting })}
                    enableReinitialize={true}
                    initialValues={{
                        ids: commandes.map((item: CommandeInterface) => item.idCommande),
                        idLivreur: '',
                        remarque: '',
                        idEtatTitre: 8,
                    }}
                >
                    {({ handleSubmit, handleChange, values, errors, isSubmitting }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row>
                                <Form.Group as={Col} md={4} controlId="form_grp_date_aff">
                                    <Form.Label>Date d&apos;affectation</Form.Label>
                                    <ClockInput />
                                </Form.Group>
                                <Form.Group as={Col} md={4} controlId="form_grp_livreur">
                                    <Form.Label>Livreur</Form.Label>
                                    <Form.Select
                                        name="idLivreur"
                                        value={values.idLivreur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.idLivreur}
                                        placeholder="Sélectionner un livreur"
                                    >
                                        <option>Sélectionner un livreur</option>
                                        {livreurs?.map((d: UserInterface) => (
                                            <option key={d.idUser} value={d.idUser}>
                                                {DisplayName(d)}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md={4} controlId="form_grp_remarque">
                                    <Form.Label>Remarque</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        as="textarea"
                                        rows={2}
                                        name="remarque"
                                        placeholder="Remarque"
                                        value={values.remarque}
                                        onChange={handleChange}
                                        isInvalid={!!errors.remarque}
                                    />
                                </Form.Group>
                            </Row>
                            <div className="gap-2 mt-3 d-flex justify-content-end">
                                <Button
                                    className="ms-2"
                                    variant="outline-primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Enregistrer
                                    {isSubmitting && <Spinner animation="border" size="sm" />}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default AffectationLivreur;
