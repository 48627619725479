import React from 'react';
import Barcode from 'react-barcode';
import { CSSColorTYPE } from 'types';

type Props = {
    value: string;
    options?: {
        width?: number;
        height?: number;
        format?: string;
        displayValue?: boolean;
        fontOptions?: string;
        font?: string;
        textAlign?: string;
        textPosition?: string;
        textMargin?: number;
        fontSize?: number;
        background?: CSSColorTYPE;
        lineColor?: CSSColorTYPE;
        margin?: number;
        marginTop?: number;
        marginBottom?: number;
        marginLeft?: number;
        marginRight?: number;
        // text?: string;
        // flat?: boolean;
        // valid?: (valid: any) => void;
    };
};

const BarCode: React.FC<Props> = ({ value, options }: Props) => <Barcode value={value} {...options} />;

export default BarCode;
