import React, { useState } from 'react';
import clsx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders } from '@fortawesome/free-solid-svg-icons';
import { Button, Collapse } from 'react-bootstrap';

declare type Dimension = 'height' | 'width';

type Props = {
    children: React.ReactNode | React.ReactElement | React.FC | any;
    moreClassNames?: string[];
    dimension?: Dimension | (() => Dimension);
};

const Collapser: React.FC<Props> = ({ children, moreClassNames }: Props) => {
    const [open, setOpen] = useState(false);
    return (
        <div className={clsx('d-flex', 'flex-row-reverse', 'justify-content-between', 'flex-wrap')}>
            <Button
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
                className="align-self-start my-3"
                variant="light"
            >
                <FontAwesomeIcon icon={faSliders} />
            </Button>
            <Collapse in={open} dimension="height" className={clsx(...moreClassNames!)}>
                {children}
            </Collapse>
        </div>
    );
};

Collapser.defaultProps = {
    dimension: 'height',
};

export default Collapser;
