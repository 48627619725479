import React, { useState, useContext } from 'react';
import { Alert, Button, Card, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { savingAuth } from 'utils/getUser';
import appStore, { StoreStateInterface } from 'store/appStore';
import AppContext from 'utils/appContext';
import { DisplayName } from 'utils/helpers';

type Props = {
    data: any;
    setData: (data: any) => void;
    closeModal: () => void;
};

const ForcingAuth: React.FC<Props> = ({ data, setData, closeModal }: Props) => {
    const [spinIt, setSpinIt] = useState<boolean>(false);
    const navigate = useNavigate();
    const setSiteLogo = appStore((state: StoreStateInterface) => state.setSiteLogo);
    const { setUser } = useContext(AppContext);

    const { data: authData, user } = data;

    const submitForm = async () => {
        try {
            setSpinIt(true);
            const { user, imageLogo } = savingAuth(authData.token, authData.logo);
            toast.success('Vous avez changé de compte avec succès');
            setUser!(user);
            setSiteLogo(imageLogo);
            navigate('/', { replace: true });
        } catch (err) {
            toast.error("Une erreur s'est produite !");
            setSpinIt(false);
        }
    };

    const handleClose = () => {
        closeModal();
        setData(null);
    };

    const isClient = user.type === 'Client';
    const isFournisseur = user.type === 'Fournisseur';

    return (
        <>
            <Alert variant="warning">
                <div className="mb-3">
                    <b>Attention:</b> Êtes-vous certain ? En procédant ainsi, vous passerez à un autre compte sans
                    option de retour. Pour revenir à l&apos;ancien compte, il faudra vous déconnecter puis vous
                    reconnecter.
                </div>
                <Card className="user-card">
                    <Card.Header as="h5">User Information</Card.Header>
                    <Card.Body>
                        <Card.Title>{DisplayName(user)}</Card.Title>
                        <Card.Text>
                            <strong>Email:</strong> {user.email}
                            {isFournisseur && (
                                <>
                                    <br />
                                    <strong>Fournisseur:</strong> {user['fournisseur.raisonSocial']}
                                </>
                            )}
                            {isClient && (
                                <>
                                    <br />
                                    <strong>Client:</strong> {user['client.raisonSocial']}
                                    <br />
                                    <strong>Fournisseur:</strong> {user['client.fournisseur.raisonSocial']}
                                </>
                            )}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Alert>
            <div className="d-flex justify-content-center gap-1">
                <Button variant="info" onClick={() => submitForm()} disabled={!!spinIt}>
                    Login
                    {!!spinIt && (
                        <>
                            &nbsp; <Spinner animation="border" size="sm" />
                        </>
                    )}
                </Button>
                <Button variant="secondary" onClick={() => handleClose()} disabled={!!spinIt}>
                    Retourner
                </Button>
            </div>
        </>
    );
};

export default ForcingAuth;
