import React, { useState, useContext } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBarcode,
    faBellConcierge,
    faBoxesPacking,
    faGears,
    faHandshake,
    faPeopleCarry,
    faTruckFast,
    faTruckRampBox,
} from '@fortawesome/free-solid-svg-icons';
import SiteLogo from 'components/UI/siteLogo';

import PermissionsGate from 'hoc/permissionsGate';
import SearchByCodeFormModal from 'components/shared/searchByCodeFormModal';

import { ROLES as RolesMap } from 'constantes/permission-maps';

import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';

type Props = {
    logout: (noRedirecting: boolean) => void;
};

const Header: React.FC<Props> = ({ logout }: Props) => {
    const { user } = useContext<AppContextState>(AppContext);
    const [showSearchModal, setShowSearchModal] = useState<boolean>(false);
    const location = useLocation();

    return (
        <Navbar bg="light" expand="lg" className="custom-navbar">
            <Navbar.Brand as={NavLink} to="/">
                <SiteLogo />
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <PermissionsGate
                        scopes={[
                            RolesMap.FOURNISSEUR_ADMIN,
                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                            RolesMap.FOURNISSEUR_FACTURE,
                            RolesMap.FOURNISSEUR_LIVREUR,
                            RolesMap.CLIENT_ADMIN,
                        ]}
                        strict
                    >
                        <NavDropdown
                            renderMenuOnMount={true}
                            active
                            title={
                                <span>
                                    <FontAwesomeIcon icon={faBellConcierge} size="xs" style={{ color: '#337ab7' }} />{' '}
                                    Commande
                                </span>
                            }
                            id="basic-nav-dropdown-Commandes"
                        >
                            <PermissionsGate
                                scopes={[
                                    RolesMap.FOURNISSEUR_ADMIN,
                                    RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                    RolesMap.FOURNISSEUR_FACTURE,
                                    RolesMap.FOURNISSEUR_LIVREUR,
                                    RolesMap.CLIENT_ADMIN,
                                ]}
                                strict
                            >
                                <NavDropdown.Item as={NavLink} to="/commandes" end>
                                    Liste des commandes
                                </NavDropdown.Item>
                            </PermissionsGate>
                            <PermissionsGate
                                scopes={[
                                    RolesMap.FOURNISSEUR_ADMIN,
                                    RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                    RolesMap.FOURNISSEUR_FACTURE,
                                    RolesMap.FOURNISSEUR_LIVREUR,
                                    RolesMap.CLIENT_ADMIN,
                                ]}
                                strict
                            >
                                <NavDropdown.Item as={NavLink} to="/commandes/need-attention">
                                    Besoin d&apos;attention
                                </NavDropdown.Item>
                            </PermissionsGate>
                            <PermissionsGate
                                scopes={[
                                    RolesMap.FOURNISSEUR_ADMIN,
                                    RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                    RolesMap.CLIENT_ADMIN,
                                ]}
                                strict
                            >
                                <NavDropdown
                                    renderMenuOnMount={true}
                                    active
                                    title="Nouvelle commande"
                                    id="basic-nav-dropdown-NewCmdsubs"
                                    className="navDropDownAsItem"
                                >
                                    <PermissionsGate
                                        scopes={[
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                            RolesMap.CLIENT_ADMIN,
                                        ]}
                                        strict
                                    >
                                        <NavDropdown.Item as={NavLink} to="/commandes/draft" end>
                                            <span className="ms-3">Paquet rapide</span>
                                        </NavDropdown.Item>
                                    </PermissionsGate>
                                    <PermissionsGate
                                        scopes={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.CLIENT_ADMIN]}
                                        strict
                                    >
                                        <NavDropdown.Item as={NavLink} to="/commandes/ready-colis" end>
                                            <span className="ms-3">A partir du stock</span>
                                        </NavDropdown.Item>
                                    </PermissionsGate>
                                </NavDropdown>
                            </PermissionsGate>

                            <PermissionsGate
                                scopes={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_RECEPTIONISTE]}
                                strict
                            >
                                <NavDropdown
                                    renderMenuOnMount={true}
                                    active
                                    title="Actions rapides"
                                    id="basic-nav-dropdown-FastActionsubs"
                                    className="navDropDownAsItem"
                                >
                                    <NavDropdown.Item as={NavLink} to="/commandes/scan-action/affectation-livreur" end>
                                        <span className="ms-3">Affectation</span>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={NavLink} to="/commandes/scan-action/retour-agence" end>
                                        <span className="ms-3">Retour agence</span>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </PermissionsGate>
                        </NavDropdown>
                    </PermissionsGate>
                    <PermissionsGate scopes={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_RECEPTIONISTE]} strict>
                        <NavDropdown
                            renderMenuOnMount={true}
                            active
                            title={
                                <span>
                                    <FontAwesomeIcon icon={faHandshake} size="xs" style={{ color: '#337ab7' }} />{' '}
                                    Partenariat
                                </span>
                            }
                            id="basic-nav-dropdown-Colis"
                        >
                            <NavDropdown.Item as={NavLink} to="/commandes/scan-action/transfert">
                                Transfert commandes
                            </NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to="/commandes/transfert">
                                Liste des commandes
                            </NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to="/commandes/transferts/actions">
                                Besoin d&apos;attention
                            </NavDropdown.Item>
                        </NavDropdown>
                    </PermissionsGate>
                    <PermissionsGate
                        scopes={[
                            RolesMap.FOURNISSEUR_ADMIN,
                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                            RolesMap.FOURNISSEUR_LIVREUR,
                            RolesMap.CLIENT_ADMIN,
                        ]}
                        strict
                    >
                        <NavDropdown
                            renderMenuOnMount={true}
                            active
                            title={
                                <span>
                                    <FontAwesomeIcon icon={faBoxesPacking} size="xs" style={{ color: '#337ab7' }} />{' '}
                                    Colis
                                </span>
                            }
                            id="basic-nav-dropdown-Colis"
                        >
                            <PermissionsGate
                                scopes={[
                                    RolesMap.FOURNISSEUR_ADMIN,
                                    RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                    RolesMap.CLIENT_ADMIN,
                                ]}
                                strict
                            >
                                <NavDropdown.Item as={NavLink} to="/colis" end>
                                    Liste des colis
                                </NavDropdown.Item>
                            </PermissionsGate>
                            <PermissionsGate
                                scopes={[
                                    RolesMap.FOURNISSEUR_ADMIN,
                                    RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                    RolesMap.FOURNISSEUR_LIVREUR,
                                ]}
                                strict
                            >
                                <NavDropdown.Item as={NavLink} to="/colis/ramassage">
                                    Ramassage
                                </NavDropdown.Item>
                            </PermissionsGate>
                            <PermissionsGate
                                scopes={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_RECEPTIONISTE]}
                                strict
                            >
                                <NavDropdown.Item as={NavLink} to="/colis/reception">
                                    Réception
                                </NavDropdown.Item>
                            </PermissionsGate>
                        </NavDropdown>
                    </PermissionsGate>
                    <PermissionsGate
                        scopes={[
                            RolesMap.FOURNISSEUR_ADMIN,
                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                            RolesMap.FOURNISSEUR_LIVREUR,
                            RolesMap.FOURNISSEUR_FACTURE,
                            RolesMap.CLIENT_ADMIN,
                        ]}
                        strict
                    >
                        <NavDropdown
                            renderMenuOnMount={true}
                            active
                            title={
                                <span>
                                    <FontAwesomeIcon icon={faTruckFast} size="xs" style={{ color: '#337ab7' }} />{' '}
                                    Facturation
                                </span>
                            }
                            id="basic-nav-dropdown-Facturation"
                        >
                            <PermissionsGate
                                scopes={[
                                    RolesMap.FOURNISSEUR_ADMIN,
                                    RolesMap.FOURNISSEUR_FACTURE,
                                    RolesMap.CLIENT_ADMIN,
                                ]}
                                strict
                            >
                                <NavDropdown
                                    renderMenuOnMount={true}
                                    active
                                    title="Client"
                                    id="basic-nav-dropdown-FacturationClientsubs"
                                    className="navDropDownAsItem"
                                >
                                    <PermissionsGate
                                        scopes={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_FACTURE]}
                                        strict
                                    >
                                        <NavDropdown.Item as={NavLink} to="/factures/auto">
                                            <span className="ms-3">Créer facture client (auto)</span>
                                        </NavDropdown.Item>
                                    </PermissionsGate>
                                    <PermissionsGate
                                        scopes={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_FACTURE]}
                                        strict
                                    >
                                        <NavDropdown.Item as={NavLink} to="/factures/aFacturer">
                                            <span className="ms-3">Créer facture client</span>
                                        </NavDropdown.Item>
                                    </PermissionsGate>
                                    <PermissionsGate
                                        scopes={[
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.FOURNISSEUR_FACTURE,
                                            RolesMap.CLIENT_ADMIN,
                                        ]}
                                        strict
                                    >
                                        <NavDropdown.Item as={NavLink} to="/factures" end>
                                            <span className="ms-3">Liste factures client</span>
                                        </NavDropdown.Item>
                                    </PermissionsGate>

                                    <PermissionsGate
                                        scopes={[
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.FOURNISSEUR_FACTURE,
                                            RolesMap.CLIENT_ADMIN,
                                        ]}
                                        strict
                                    >
                                        <NavDropdown.Item as={NavLink} to="/factures/paiements">
                                            <span className="ms-3">Liste paiements client</span>
                                        </NavDropdown.Item>
                                    </PermissionsGate>
                                </NavDropdown>
                            </PermissionsGate>

                            <PermissionsGate
                                scopes={[
                                    RolesMap.FOURNISSEUR_ADMIN,
                                    RolesMap.FOURNISSEUR_FACTURE,
                                    RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                    RolesMap.FOURNISSEUR_LIVREUR,
                                ]}
                                strict
                            >
                                <NavDropdown
                                    renderMenuOnMount={true}
                                    active
                                    title="Livreur"
                                    id="basic-nav-dropdown-Facturationlivreurssubs"
                                    className="navDropDownAsItem"
                                >
                                    <PermissionsGate
                                        scopes={[
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.FOURNISSEUR_FACTURE,
                                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                            // RolesMap.FOURNISSEUR_LIVREUR,
                                        ]}
                                        strict
                                    >
                                        <NavDropdown.Item as={NavLink} to="/commandes/livreur-paiement">
                                            <span className="ms-3">Créer facture livreur</span>
                                        </NavDropdown.Item>
                                    </PermissionsGate>
                                    <NavDropdown.Item as={NavLink} to="/factures/livreurs">
                                        <span className="ms-3"> Liste factures livreurs</span>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </PermissionsGate>
                            <PermissionsGate
                                scopes={[
                                    RolesMap.FOURNISSEUR_ADMIN,
                                    RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                    RolesMap.FOURNISSEUR_FACTURE,
                                ]}
                                strict
                            >
                                <NavDropdown
                                    renderMenuOnMount={true}
                                    active
                                    title="Parternaire"
                                    id="basic-nav-dropdown-FacturationParternairesubs"
                                    className="navDropDownAsItem"
                                >
                                    <NavDropdown.Item as={NavLink} to="/commandes/transferts/toFacture">
                                        <span className="ms-3">Créer facture partenaire</span>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={NavLink} to="/commandes/transferts/factures">
                                        <span className="ms-3">Liste facture partenaire</span>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </PermissionsGate>
                        </NavDropdown>
                    </PermissionsGate>
                    <PermissionsGate
                        scopes={[
                            RolesMap.FOURNISSEUR_ADMIN,
                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                            RolesMap.CLIENT_ADMIN,
                            RolesMap.FOURNISSEUR_LIVREUR,
                        ]}
                        strict
                    >
                        <NavDropdown
                            renderMenuOnMount={true}
                            active
                            title={
                                <span>
                                    <FontAwesomeIcon icon={faTruckRampBox} size="xs" style={{ color: '#337ab7' }} />{' '}
                                    Stock
                                </span>
                            }
                            id="basic-nav-dropdown-Stock"
                        >
                            <PermissionsGate
                                scopes={[
                                    RolesMap.FOURNISSEUR_ADMIN,
                                    RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                    RolesMap.CLIENT_ADMIN,
                                ]}
                                strict
                            >
                                <NavDropdown.Item as={NavLink} to="/stock" end>
                                    Etat de stockage
                                </NavDropdown.Item>
                            </PermissionsGate>
                            <PermissionsGate
                                scopes={[
                                    RolesMap.FOURNISSEUR_ADMIN,
                                    RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                    RolesMap.CLIENT_ADMIN,
                                ]}
                                strict
                            >
                                <NavDropdown.Item as={NavLink} to="/stock/demandes">
                                    Demande de retour
                                </NavDropdown.Item>
                            </PermissionsGate>
                            <PermissionsGate
                                scopes={[
                                    RolesMap.FOURNISSEUR_ADMIN,
                                    RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                    RolesMap.FOURNISSEUR_LIVREUR,
                                ]}
                                strict
                            >
                                <NavDropdown.Item as={NavLink} to="/stock/demandes-livreur">
                                    Demande de retour au livreur
                                </NavDropdown.Item>
                            </PermissionsGate>
                        </NavDropdown>
                    </PermissionsGate>
                    <PermissionsGate
                        scopes={[
                            RolesMap.SUPER_ADMIN,
                            RolesMap.FOURNISSEUR_ADMIN,
                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                            RolesMap.CLIENT_ADMIN,
                        ]}
                        strict
                    >
                        <NavDropdown
                            renderMenuOnMount={true}
                            active
                            title={
                                <span>
                                    <FontAwesomeIcon icon={faPeopleCarry} size="xs" style={{ color: '#337ab7' }} />{' '}
                                    Acteurs
                                </span>
                            }
                            id="basic-nav-dropdown-Acteurs"
                        >
                            <PermissionsGate
                                scopes={[
                                    RolesMap.SUPER_ADMIN,
                                    RolesMap.FOURNISSEUR_ADMIN,
                                    RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                    RolesMap.CLIENT_ADMIN,
                                ]}
                                strict
                            >
                                <PermissionsGate
                                    scopes={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_RECEPTIONISTE]}
                                    strict
                                >
                                    <NavDropdown.Item as={NavLink} to="/fournisseurs/livreurs-actifs">
                                        Livreurs actifs
                                    </NavDropdown.Item>
                                </PermissionsGate>
                                <NavDropdown
                                    renderMenuOnMount={true}
                                    active
                                    title="Clients"
                                    id="basic-nav-dropdown-Clients"
                                    className="navDropDownAsItem"
                                >
                                    <PermissionsGate
                                        scopes={[
                                            RolesMap.SUPER_ADMIN,
                                            RolesMap.FOURNISSEUR_ADMIN,
                                            RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                        ]}
                                        strict
                                    >
                                        <NavDropdown.Item as={NavLink} to="/clients" end>
                                            <span className="ms-3">Liste des clients</span>
                                        </NavDropdown.Item>
                                    </PermissionsGate>
                                    <PermissionsGate scopes={[RolesMap.CLIENT_ADMIN]} strict>
                                        <NavDropdown.Item as={NavLink} to="/clients/client/users">
                                            <span className="ms-3">Liste des agents</span>
                                        </NavDropdown.Item>
                                    </PermissionsGate>
                                    <PermissionsGate
                                        scopes={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_RECEPTIONISTE]}
                                        strict
                                    >
                                        <NavDropdown.Item as={NavLink} to="/clients/fournisseur/users">
                                            <span className="ms-3">Liste des agents</span>
                                        </NavDropdown.Item>
                                    </PermissionsGate>
                                    <PermissionsGate scopes={[RolesMap.SUPER_ADMIN]} strict>
                                        <NavDropdown.Item as={NavLink} to="/clients/admin/users">
                                            <span className="ms-3">Liste des agents</span>
                                        </NavDropdown.Item>
                                    </PermissionsGate>
                                </NavDropdown>
                            </PermissionsGate>
                            <PermissionsGate
                                scopes={[
                                    RolesMap.SUPER_ADMIN,
                                    RolesMap.FOURNISSEUR_ADMIN,
                                    RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                ]}
                                strict
                            >
                                <NavDropdown
                                    renderMenuOnMount={true}
                                    active
                                    title="Fournisseurs"
                                    id="basic-nav-dropdown-Fournisseurs"
                                    className="navDropDownAsItem"
                                >
                                    <PermissionsGate scopes={[RolesMap.SUPER_ADMIN]} strict>
                                        <NavDropdown.Item as={NavLink} to="/fournisseurs" end>
                                            <span className="ms-3">Liste des fournisseurs</span>
                                        </NavDropdown.Item>
                                    </PermissionsGate>
                                    <PermissionsGate scopes={[RolesMap.SUPER_ADMIN]} strict>
                                        <NavDropdown.Item as={NavLink} to="/fournisseurs/admin/users">
                                            <span className="ms-3">Liste des agents</span>
                                        </NavDropdown.Item>
                                    </PermissionsGate>
                                    <PermissionsGate
                                        scopes={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_RECEPTIONISTE]}
                                        strict
                                    >
                                        <NavDropdown.Item as={NavLink} to="/fournisseurs/fournisseur/users">
                                            <span className="ms-3">Liste des agents</span>
                                        </NavDropdown.Item>
                                    </PermissionsGate>
                                </NavDropdown>
                            </PermissionsGate>
                        </NavDropdown>
                    </PermissionsGate>
                    <PermissionsGate scopes={[RolesMap.SUPER_ADMIN, RolesMap.FOURNISSEUR_ADMIN]} strict>
                        <NavDropdown
                            renderMenuOnMount={true}
                            active
                            title={
                                <span>
                                    <FontAwesomeIcon icon={faGears} size="xs" style={{ color: '#337ab7' }} /> Paramètres
                                </span>
                            }
                            id="basic-nav-dropdown-Paramétrage"
                        >
                            <PermissionsGate scopes={[RolesMap.SUPER_ADMIN]} strict>
                                <NavDropdown.Item as={NavLink} to="/settings/villes">
                                    Regions
                                </NavDropdown.Item>
                            </PermissionsGate>
                            <PermissionsGate scopes={[RolesMap.SUPER_ADMIN]} strict>
                                <NavDropdown.Item as={NavLink} to="/settings/regions">
                                    Villes
                                </NavDropdown.Item>
                            </PermissionsGate>
                            <PermissionsGate scopes={[RolesMap.SUPER_ADMIN]} strict>
                                <NavDropdown.Item as={NavLink} to="/settings/banques">
                                    Banques
                                </NavDropdown.Item>
                            </PermissionsGate>
                            <PermissionsGate scopes={[RolesMap.SUPER_ADMIN]} strict>
                                <NavDropdown.Item as={NavLink} to="/settings/modePaiements">
                                    Mode Paiements
                                </NavDropdown.Item>
                            </PermissionsGate>
                            <PermissionsGate scopes={[RolesMap.SUPER_ADMIN]} strict>
                                <NavDropdown.Item as={NavLink} to="/settings/optionServices">
                                    Option Services
                                </NavDropdown.Item>
                            </PermissionsGate>
                            <PermissionsGate scopes={[RolesMap.SUPER_ADMIN]} strict>
                                <NavDropdown.Item as={NavLink} to="/settings/services">
                                    Services
                                </NavDropdown.Item>
                            </PermissionsGate>
                            <PermissionsGate scopes={[RolesMap.FOURNISSEUR_ADMIN]} strict>
                                <NavDropdown.Item as={NavLink} to="/settings/agences">
                                    Agences
                                </NavDropdown.Item>
                            </PermissionsGate>
                            <PermissionsGate scopes={[RolesMap.FOURNISSEUR_ADMIN]} strict>
                                <NavDropdown.Item as={NavLink} to="/settings/emplacements">
                                    Emplacements
                                </NavDropdown.Item>
                            </PermissionsGate>
                            <PermissionsGate scopes={[RolesMap.FOURNISSEUR_ADMIN]} strict>
                                <NavDropdown.Item as={NavLink} to="/settings/tarifs">
                                    Tarifs
                                </NavDropdown.Item>
                            </PermissionsGate>
                            <PermissionsGate scopes={[RolesMap.FOURNISSEUR_ADMIN]} strict>
                                <NavDropdown.Item as={NavLink} to="/settings/tariftransferts">
                                    Tarifs transfert
                                </NavDropdown.Item>
                            </PermissionsGate>
                            <PermissionsGate scopes={[RolesMap.FOURNISSEUR_ADMIN]} strict>
                                <NavDropdown.Item as={NavLink} to="/settings/group-villes-clone">
                                    Villes groupement
                                </NavDropdown.Item>
                            </PermissionsGate>
                            <PermissionsGate scopes={[RolesMap.FOURNISSEUR_ADMIN]} strict>
                                <NavDropdown.Item as={NavLink} to="/settings/commissions-livreurs">
                                    Commissions livreurs
                                </NavDropdown.Item>
                            </PermissionsGate>
                            <PermissionsGate scopes={[RolesMap.SUPER_ADMIN]} strict>
                                <NavDropdown.Item as={NavLink} to="/settings/etatTitres">
                                    Etat Titres
                                </NavDropdown.Item>
                            </PermissionsGate>
                        </NavDropdown>
                    </PermissionsGate>
                </Nav>
                <Nav>
                    {user ? (
                        <>
                            <NavDropdown
                                className="custom_nav_link headerfix-responsive-profiledp"
                                title={
                                    <React.Fragment>
                                        {user.userData.nom} {user.userData.prenom} &nbsp;
                                        <Image src="/user_thumbnail.png" roundedCircle width="25" height="25" />
                                    </React.Fragment>
                                }
                                id="basic-nav-dropdown"
                            >
                                <PermissionsGate
                                    scopes={[
                                        RolesMap.FOURNISSEUR_ADMIN,
                                        RolesMap.FOURNISSEUR_LIVREUR,
                                        RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                        RolesMap.FOURNISSEUR_FACTURE,
                                    ]}
                                    strict
                                >
                                    <>
                                        <NavDropdown.Item
                                            as={Button}
                                            variant="outline-light"
                                            onClick={() => setShowSearchModal(true)}
                                        >
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span>Scanner</span> <FontAwesomeIcon icon={faBarcode} size="lg" />
                                            </div>
                                        </NavDropdown.Item>

                                        <SearchByCodeFormModal show={showSearchModal} setShow={setShowSearchModal} />
                                        <NavDropdown.Divider />
                                    </>
                                </PermissionsGate>
                                <PermissionsGate scopes={[RolesMap.FOURNISSEUR_ADMIN]} strict>
                                    <>
                                        <NavDropdown.Item as={Link} to="/partnerships">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span>Partenaires</span>
                                            </div>
                                        </NavDropdown.Item>

                                        <NavDropdown.Divider />
                                    </>
                                </PermissionsGate>
                                <PermissionsGate scopes={[RolesMap.FOURNISSEUR_ADMIN]} strict>
                                    <NavDropdown.Item as={Link} to="/profile/logo">
                                        Changengement de logo
                                    </NavDropdown.Item>
                                </PermissionsGate>
                                <NavDropdown.Item as={Link} to="/profile/password">
                                    Changer votre mot de passe
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item as={Button} onClick={() => logout(true)}>
                                    Logout
                                </NavDropdown.Item>
                            </NavDropdown>
                            <PermissionsGate
                                scopes={[
                                    RolesMap.FOURNISSEUR_ADMIN,
                                    RolesMap.FOURNISSEUR_LIVREUR,
                                    RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                    RolesMap.FOURNISSEUR_FACTURE,
                                    RolesMap.CLIENT_ADMIN,
                                ]}
                                strict
                            >
                                <>
                                    <Nav.Link
                                        as={Button}
                                        variant="outline-light"
                                        onClick={() => setShowSearchModal(true)}
                                        className="align-self-end hiderootscan"
                                    >
                                        <FontAwesomeIcon icon={faBarcode} size="lg" />
                                    </Nav.Link>
                                    <SearchByCodeFormModal show={showSearchModal} setShow={setShowSearchModal} />
                                </>
                            </PermissionsGate>
                        </>
                    ) : (
                        <Nav.Link
                            as={NavLink}
                            to="/login"
                            state={{ referrer: `${location.pathname}${location.search}` }}
                        >
                            Login
                        </Nav.Link>
                    )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Header;
