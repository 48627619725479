import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import { allowAccess } from 'utils/helpers';

type Props = {
    rolesRequired?: string[];
    strict?: boolean;
    guarded?: boolean;
    children: React.FC | any;
};

const GuardedComponent: React.FC<Props> = ({ children, guarded = false, rolesRequired, strict }: Props) => {
    const { user } = useContext<AppContextState>(AppContext);
    const location = useLocation();

    return !guarded || (guarded && user) ? (
        allowAccess(user?.ROLES, rolesRequired!, strict) ? (
            children
        ) : (
            <Navigate
                to={{
                    pathname: '/not-authorized',
                }}
            />
        )
    ) : (
        <Navigate to="/login" state={{ referrer: `${location.pathname}${location.search}` }} />
    );
};

export default GuardedComponent;
