import React, { useEffect, useState } from 'react';
import { Accordion, Form } from 'react-bootstrap';

import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import TitleElement from 'components/UI/titleElement';

import RequestsToCancel from 'pages/Transfert/components/ToCancel';
import RequestsToReceive from 'pages/Transfert/components/ToReceive';
import RequestsToReturn from 'pages/Transfert/components/ToReturn';
import RequestsToConfirmReturning from 'pages/Transfert/components/ToConfirmReturning';

const TransfertRequests: React.FC = () => {
    const [scanMode, setScanMode] = useState<boolean>(true);

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        {
            active: true,
            text: 'Partenariat',
        },
        {
            active: true,
            text: "Besoin d'attention",
        },
    ];

    useEffect(() => {
        document.title = "Besoin d'attention - DS";
    }, []);

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Besoin d&apos;attention
                </TitleElement>

                <Form.Check
                    type="switch"
                    id="modecheck"
                    label="Scan mode"
                    checked={scanMode}
                    onChange={() => setScanMode(!scanMode)}
                />
            </div>
            <Accordion defaultActiveKey="0">
                <RequestsToReceive eventKey="0" scanMode={scanMode} />
                <div className="border-bottom-dashed"></div>
                <RequestsToCancel eventKey="1" scanMode={scanMode} />
                <div className="border-bottom-dashed"></div>
                <RequestsToReturn eventKey="2" scanMode={scanMode} />
                <div className="border-bottom-dashed"></div>
                <RequestsToConfirmReturning eventKey="3" scanMode={scanMode} />
            </Accordion>
        </>
    );
};

export default TransfertRequests;
