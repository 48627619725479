import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import EtatTitreInterface from 'types/interfaces/etatTitre.interface';

const apiEndPoint = `${apiUrl}/etatTitres`;

export const getEtatTitres = (): Promise<EtatTitreInterface[] | any> => {
    return http.get(apiEndPoint);
};

export const saveEtatTitre = (etatTitre: EtatTitreInterface) => {
    const body = {
        ...etatTitre,
    };
    if (etatTitre.idEtatTitre) {
        return http.put(`${apiEndPoint}/${etatTitre.idEtatTitre}`, body);
    }
    return http.post(`${apiEndPoint}`, body);
};
