import React, { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { Button, Col, Row, Form, Spinner } from 'react-bootstrap';
import { TableColumnType } from 'react-bs-datatable';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import OptionServiceInterface from 'types/interfaces/optionService.interface';
import ClientInterface from 'types/interfaces/client.interface';
import RegionInterface from 'types/interfaces/region.interface';
import VilleInterface from 'types/interfaces/ville.interface';
import TarifInterface, { TarifsMultipleCreationInterface } from 'types/interfaces/tarif.interface';
import FournisseurInterface from 'types/interfaces/fournisseur.interface';
import { getRegionsWVilles } from 'services/region.service';
import { getOptionServices } from 'services/optionService.service';
import { getClientsByIdFournisseur } from 'services/client.service';
import { getTarifsFiltered, createMultipleTarif } from 'services/tarif.service';
import { getFournisseurs, getFournisseursClients } from 'services/fournisseur.service';

import CustomModal from 'components/UI/customModal';
import Datatable from 'components/shared/datatable';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import { hasRole, groupingOptionsRegionVilles } from 'utils/helpers';
import { ROLES as RolesMap } from 'constantes/permission-maps';
import Paginator from 'components/UI/paginator';
import TitleElement from 'components/UI/titleElement';
import MultiSelectGroupedComponent from 'components/UI/multiSelectGroupedComponent';

const schema = yup.object({
    idOptionService: yup.number().required(),
    idFournisseur: yup.number().required(),
    idVilles: yup.array().of(yup.number().required()).min(1).required(),
    idClient: yup.number(),
    prixTarif: yup.number().positive().required(),
    commissionLivreur: yup.number().required(),
});

const TOTAL_DATA_PER_PAGE = 100;

const TarifPage: React.FC = () => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);
    const [tarifs, setTarifs] = useState<{ items: TarifInterface[]; count: number }>({ items: [], count: 0 });
    const [clients, setClients] = useState<ClientInterface[]>([]);
    const [regions, setRegions] = useState<RegionInterface[]>([]);
    const [optionServices, setOptionServices] = useState<OptionServiceInterface[]>([]);
    const [fournisseurs, setFournisseurs] = useState<FournisseurInterface[]>([]);
    const [fournisseursClients, setFournisseursClients] = useState<FournisseurInterface[]>([]);

    const [activePage, setActivePage] = useState<number>(1);
    const [selectedClient, setSelectedClient] = useState<string>('');
    const [selectedVille, setSelectedVille] = useState<string>('');

    const [showFormModal, setShowFormModal] = useState<boolean>(false);
    const [spinIt, setSpinIt] = useState<boolean>(false);

    const handleFormModalClose = () => {
        if (hasRole(contextUser?.ROLES, RolesMap.SUPER_ADMIN)) setClients([]);
        setShowFormModal(false);
    };

    const handleFormModalOpen = () => {
        setShowFormModal(true);
    };

    const tableHeaders: TableColumnType<any>[] = [
        {
            prop: 'client.raisonSocial',
            title: 'Client',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'optionService.nameOptionService',
            title: 'Service',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: TarifInterface) => (
                <>{`${(row as any)['optionService.nameOptionService']}, ${
                    (row as any)['optionService.service.nomService']
                }`}</>
            ),
        },
        {
            prop: 'ville.nomVille',
            title: 'Ville',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'prixTarif',
            title: 'Prix Tarif',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'commissionLivreur',
            title: 'Commission Livreur',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'dateCreation',
            title: 'Date',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: TarifInterface) => (
                <>{row.dateCreation ? dayjs(row.dateCreation).format('DD/MM/YYYY HH:mm') : '-'}</>
            ),
        },
    ];

    useEffect(() => {
        document.title = 'Paramétrage Tarif - DS';

        getRegionsWVilles()
            .then((response: AxiosResponse<RegionInterface[] | any>) => {
                const { data } = response;
                setRegions(data);
            })
            .catch();

        getOptionServices()
            .then((response: AxiosResponse<OptionServiceInterface | any>) => {
                const { data } = response;
                setOptionServices(data);
            })
            .catch();
    }, []);

    useEffect(() => {
        if (contextUser) {
            getFournisseursClients()
                .then((response: AxiosResponse<TarifInterface | any>) => {
                    const { data } = response;
                    setFournisseursClients(data);
                })
                .catch();
            if (hasRole(contextUser.ROLES, RolesMap.SUPER_ADMIN)) {
                getFournisseurs()
                    .then((response: AxiosResponse<FournisseurInterface[] | any>) => {
                        const { data } = response;
                        setFournisseurs(data);
                    })
                    .catch();
            } else {
                getClientsByIdFournisseur(contextUser.userData.idFournisseur!)
                    .then((response: AxiosResponse<ClientInterface | any>) => {
                        const { data } = response;
                        setClients(data);
                    })
                    .catch();
                loadClientsByFournisseur(contextUser.userData.idFournisseur!);
            }
        }
    }, [contextUser]);

    useEffect(() => {
        if (contextUser && (selectedClient || selectedVille || activePage)) {
            getTarifsFiltered(TOTAL_DATA_PER_PAGE, activePage, selectedClient, selectedVille)
                .then((response: AxiosResponse<TarifInterface | any>) => {
                    const { data } = response;
                    setTarifs(data);
                })
                .catch();
        }
    }, [contextUser, selectedClient, selectedVille, activePage]);

    const loadClientsByFournisseur = (id: number) => {
        getClientsByIdFournisseur(id)
            .then((response: AxiosResponse<ClientInterface | any>) => {
                const { data } = response;
                setClients(data);
            })
            .catch();
    };

    const submitForm = async (tarifValues: TarifsMultipleCreationInterface | any) => {
        try {
            setSpinIt(true);
            const response = await createMultipleTarif(tarifValues, selectedClient);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Tarif a été enregistré avec succès');
                const tarifsData = [...tarifs.items, ...resultData];
                setTarifs({ items: tarifsData, count: tarifsData.length });
                handleFormModalClose();
                setSpinIt(false);
            }
        } catch (Exception) {
            setSpinIt(false);
        }
    };

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { text: 'Paramétrage' },
        { active: true, text: 'Tarif' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Tarifs
                </TitleElement>
                <Button
                    variant="outline-secondary"
                    className="button-input-group-effect"
                    id="basic-addon1"
                    onClick={() => handleFormModalOpen()}
                >
                    <FontAwesomeIcon icon={faPlus} size="xs" style={{ color: '#337ab7' }} />
                </Button>
            </div>
            <Datatable
                data={tarifs.items}
                tableColumns={tableHeaders}
                initialSort={{ initialState: { prop: 'idTarif', order: 'desc' } }}
            >
                <Row>
                    <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_sClient">
                        <Form.Label>Client:</Form.Label>
                        <Form.Select
                            size="sm"
                            name="selectedClient"
                            value={selectedClient}
                            onChange={(e) => {
                                const {
                                    target: { value },
                                } = e;
                                setSelectedClient(value);
                                setActivePage(1);
                            }}
                            placeholder="Selectionner un client"
                        >
                            <option value="">Tous</option>
                            {fournisseursClients.map((dr: FournisseurInterface) => (
                                <optgroup key={dr.idFournisseur} label={dr.raisonSocial}>
                                    {dr.clients?.map((d: ClientInterface) => (
                                        <option key={d.idClient} value={d.idClient}>
                                            {d.raisonSocial}
                                        </option>
                                    ))}
                                </optgroup>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_sVille">
                        <Form.Label>Villes</Form.Label>
                        <Form.Select
                            name="selectedVille"
                            value={selectedVille}
                            onChange={(e) => {
                                const {
                                    target: { value },
                                } = e;
                                setSelectedVille(value);
                                setActivePage(1);
                            }}
                            placeholder="Selectionner une ville"
                        >
                            <option value="">Tous</option>
                            {regions.map((dr: RegionInterface) => (
                                <optgroup key={dr.idRegion} label={dr.nomRegion}>
                                    {dr.villes?.map((d: VilleInterface) => (
                                        <option key={d.idVille} value={d.idVille}>
                                            {d.nomVille}
                                        </option>
                                    ))}
                                </optgroup>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Col className="d-flex flex-col justify-content-end align-items-end mt-1">
                        <Paginator
                            defaultPage={activePage}
                            totalPages={Math.ceil(tarifs.count / TOTAL_DATA_PER_PAGE)}
                            callback={(e_page) => setActivePage(e_page)}
                        />
                    </Col>
                </Row>
            </Datatable>
            <CustomModal title="New Tarif" size="lg" show={showFormModal} handleClose={handleFormModalClose}>
                <div>
                    <Formik
                        validationSchema={schema}
                        onSubmit={(values) => submitForm(values)}
                        enableReinitialize={true}
                        initialValues={{
                            idTarif: undefined,
                            prixTarif: undefined,
                            commissionLivreur: undefined,
                            idClient: undefined,
                            idOptionService: undefined,
                            idVilles: undefined,
                            idFournisseur:
                                contextUser && hasRole(contextUser.ROLES, RolesMap.FOURNISSEUR_ADMIN)
                                    ? contextUser?.userData.idFournisseur!
                                    : undefined,
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                {values.idTarif && (
                                    <Form.Control type="hidden" name="idTarif" value={values.idTarif} readOnly={true} />
                                )}
                                <Row>
                                    <Form.Group as={Col} md={6} controlId="form_grp_ptarif">
                                        <Form.Label>Prix Tarif</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="prixTarif"
                                            placeholder="Prix Tarif"
                                            value={values.prixTarif}
                                            onChange={handleChange}
                                            isInvalid={!!errors.prixTarif}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.prixTarif}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} controlId="form_grp_commisionLivreur">
                                        <Form.Label>Commission Livreur</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="commissionLivreur"
                                            placeholder="Commission Livreur"
                                            value={values.commissionLivreur}
                                            onChange={handleChange}
                                            isInvalid={!!errors.commissionLivreur}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.commissionLivreur}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                {contextUser && hasRole(contextUser.ROLES, RolesMap.SUPER_ADMIN) && (
                                    <Form.Group controlId="form_grp_frs">
                                        <Form.Label>Fournisseurs</Form.Label>
                                        <Form.Select
                                            name="idFournisseur"
                                            value={values.idFournisseur}
                                            onChange={(e) => {
                                                const {
                                                    target: { value },
                                                } = e;
                                                loadClientsByFournisseur(+value);
                                                handleChange({ target: { value, name: 'idFournisseur' } });
                                            }}
                                            isInvalid={!!errors.idFournisseur}
                                            placeholder="Selectionner un founisseur"
                                        >
                                            <option>Selectionner un fournisseur</option>
                                            {fournisseurs.map((d: FournisseurInterface) => (
                                                <option key={d.idFournisseur} value={d.idFournisseur}>
                                                    {d.raisonSocial}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                )}
                                <Form.Group controlId="form_grp_ville">
                                    <Form.Label>Clients</Form.Label>
                                    <Form.Select
                                        name="idClient"
                                        value={values.idClient}
                                        onChange={handleChange}
                                        isInvalid={!!errors.idClient}
                                        placeholder="Selectionner un client"
                                    >
                                        <option>Selectionner un client</option>
                                        {clients.map((d: ClientInterface) => (
                                            <option key={d.idClient} value={d.idClient}>
                                                {d.raisonSocial}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <Row>
                                    <Form.Group as={Col} md="6" controlId="form_grp_dd">
                                        <Form.Label>Villes</Form.Label>
                                        {/* <Form.Select
                                            name="idVilles"
                                            value={values.idVilles}
                                            onChange={handleChange}
                                            isInvalid={!!errors.idVilles}
                                            placeholder="Selectionner des villes"
                                            multiple
                                        >
                                            <option>Selectionner des villes</option>
                                            {regions.map((dr: RegionInterface) => (
                                                <optgroup key={dr.idRegion} label={dr.nomRegion}>
                                                    {dr.villes?.map((d: VilleInterface) => (
                                                        <option key={d.idVille} value={d.idVille}>
                                                            {d.nomVille}
                                                        </option>
                                                    ))}
                                                </optgroup>
                                            ))}
                                        </Form.Select> */}
                                        <MultiSelectGroupedComponent
                                            options={groupingOptionsRegionVilles(regions)}
                                            placeholder="Selectionner des villes"
                                            onChange={(selectedItems: string[]) => {
                                                handleChange({ target: { value: selectedItems, name: 'idVilles' } });
                                            }}
                                            classNames={!!errors.idVilles ? 'is-invalid' : ''}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.idVilles}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="form_grp_ddd">
                                        <Form.Label>OptionServices</Form.Label>
                                        <Form.Select
                                            name="idOptionService"
                                            value={values.idOptionService}
                                            onChange={handleChange}
                                            isInvalid={!!errors.idOptionService}
                                            placeholder="Selectionner un optionService"
                                        >
                                            <option>Selectionner une Option de Service</option>
                                            {optionServices.map((d: OptionServiceInterface) => (
                                                <option key={d.idOptionService} value={d.idOptionService}>
                                                    {d.nameOptionService}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                <div className="d-grid gap-2 mt-3">
                                    <Button variant="primary" type="submit" disabled={!!spinIt}>
                                        Enregistrer
                                        {!!spinIt && (
                                            <>
                                                &nbsp; <Spinner animation="border" size="sm" />
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </CustomModal>
        </>
    );
};

export default TarifPage;
