import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Button, InputGroup, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Formik, FieldArray } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosResponse } from 'axios';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { removeDuplicatedArrObj, sortArrByTwoCols, sortByNumber } from 'utils/helpers';
import CommandeInterface, { CommandeEditInterface } from 'types/interfaces/commande.interface';
import TarifInterface from 'types/interfaces/tarif.interface';
import RegionInterface from 'types/interfaces/region.interface';
import VilleInterface from 'types/interfaces/ville.interface';
import EtatCommandeInterface from 'types/interfaces/etatCommande.interface';
import { getCommandeById, editCommande } from 'services/commande.service';
import { getRegionsWVilles } from 'services/region.service';
import { getTarifsForClient } from 'services/tarif.service';
import { getEtatCommandes } from 'services/etatCommande.service';

const schema = yup.object().shape({
    idVille: yup.number().required(),
    adresse: yup.string().required(),
    prixTotal: yup.number().moreThan(-1).required(),
    commandeTarifs: yup
        .array()
        .of(
            yup.object({
                idTarif: yup.number().required(),
            }),
        )
        .min(1)
        .required(),
});

type Props = {
    commandes: { items: CommandeInterface[]; count: number };
    setCommandes: (value: { items: CommandeInterface[]; count: number }) => void;
    currentCommande: CommandeInterface;
    setCurrentCommande: (value: CommandeInterface | null) => void;
    setCheckedCommandes: (value: number[]) => void;
    closeModal: () => void;
};

const CommandeEditForm: React.FC<Props> = ({
    commandes,
    setCommandes,
    currentCommande,
    setCurrentCommande,
    setCheckedCommandes,
    closeModal,
}: Props) => {
    const [commande, setCommande] = useState<CommandeInterface | null>(null);
    const [regions, setRegions] = useState<RegionInterface[]>([]);
    const [tarifs, setTarifs] = useState<TarifInterface[]>([]);
    const [etatCommandes, setEtatCommandes] = useState<EtatCommandeInterface[]>([]);
    const [spinIt, setSpinIt] = useState<boolean>(false);

    useEffect(() => {
        getRegionsWVilles()
            .then((response: AxiosResponse<RegionInterface[] | any>) => {
                const { data } = response;
                setRegions(data);
            })
            .catch();

        getEtatCommandes()
            .then((response: AxiosResponse<EtatCommandeInterface[] | any>) => {
                const { data } = response;
                setEtatCommandes(data);
            })
            .catch();
    }, []);

    useEffect(() => {
        if (currentCommande) {
            getCommandeById(currentCommande?.idCommande!)
                .then((response: AxiosResponse<CommandeInterface | any>) => {
                    setCommande(response.data);
                })
                .catch();

            getTarifsForClient(currentCommande.idClient)
                .then((response: AxiosResponse<TarifInterface[] | any>) => {
                    const { data: responseData } = response;
                    const sortedArr = sortArrByTwoCols(
                        sortByNumber(responseData, 'idTarif', true),
                        'idOptionService',
                        'idClient',
                        true,
                    );
                    setTarifs(sortedArr);
                })
                .catch();
        }
    }, [currentCommande]);

    const submitForm = async (editedData: CommandeEditInterface | any) => {
        try {
            setSpinIt(true);
            const response = await editCommande(commande?.idCommande!, editedData);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Commande a été modifiée');
                setCurrentCommande(resultData);
                setSpinIt(false);
                setCheckedCommandes([]);
                const cmdData: CommandeInterface[] = [...commandes.items].map((dd) =>
                    dd.idCommande === resultData.idCommande ? resultData : dd,
                );
                setCommandes({ items: cmdData, count: commandes.count });
                closeModal();
            }
        } catch (Exception) {
            setSpinIt(false);
            const { message = undefined }: any = Exception;
            toast.error(message || 'Error occured!');
        }
    };

    return (
        <Formik
            validationSchema={schema}
            onSubmit={(values) => submitForm(values)}
            enableReinitialize={true}
            initialValues={{
                idVille: commande?.idVille ?? '',
                adresse: commande?.adresse! ?? '',
                prixTotal: commande?.prixTotal! ?? 0,
                commandeTarifs: commande?.commandetarifs! ?? [],
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group controlId="form_grp_Client">
                        <Form.Label>Etat:</Form.Label>
                        <Form.Select
                            size="sm"
                            name="selectedEtat"
                            value={commande?.idEtatCommande!}
                            onChange={() => void 0}
                            placeholder="Selectionner une etat"
                            disabled
                        >
                            <option value="">Tous</option>
                            {etatCommandes.map((d: EtatCommandeInterface) => (
                                <option key={d.idEtatCommande} value={d.idEtatCommande}>
                                    {d.etatCommande}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group controlId="form_grp_ville">
                        <Form.Label>Villes</Form.Label>
                        <Form.Select
                            size="sm"
                            name="idVille"
                            value={values.idVille}
                            onChange={(e) => {
                                handleChange({ target: { value: [], name: 'commandeTarifs' } });
                                handleChange(e);
                            }}
                            isInvalid={!!errors.idVille}
                            placeholder="Selectionner une ville"
                        >
                            <option>Selectionner une ville</option>
                            {regions.map((dr: RegionInterface) => (
                                <optgroup key={dr.idRegion} label={dr.nomRegion}>
                                    {dr.villes?.map((d: VilleInterface) => (
                                        <option key={d.idVille} value={d.idVille}>
                                            {d.nomVille}
                                        </option>
                                    ))}
                                </optgroup>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Row>
                        <Col>
                            <h5 className="text-center">Services</h5>
                            <FieldArray
                                name="commandeTarifs"
                                render={(arrayHelpers) => {
                                    return (
                                        removeDuplicatedArrObj(
                                            tarifs.filter(
                                                (d: TarifInterface) =>
                                                    values.idVille.toString() === d.idVille.toString(),
                                            ),
                                            'idOptionService',
                                        ) as TarifInterface[]
                                    ).map((d: TarifInterface, index) => (
                                        <Row className=" g-3 align-items-center" key={d.idTarif}>
                                            <Col auto>
                                                <Form.Check
                                                    type="checkbox"
                                                    label={`${d.optionService.nameOptionService} :`}
                                                    name={`commandeTarifs[${index}].idTarif`}
                                                    id={`commandeTarifs[${index}].idTarif`}
                                                    checked={values.commandeTarifs
                                                        .map((dt: any) => dt.idTarif.toString())
                                                        .includes(d.idTarif.toString())}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            arrayHelpers.push({
                                                                idTarif: d.idTarif,
                                                            });
                                                        } else {
                                                            const index = values.commandeTarifs.findIndex(
                                                                (obj: any) => obj.idTarif == d.idTarif,
                                                            );
                                                            arrayHelpers.remove(index);
                                                        }
                                                    }}
                                                />
                                            </Col>
                                            <Col auto className="text-end">
                                                {d.prixTarif} DH
                                            </Col>
                                        </Row>
                                    ));
                                }}
                            />
                            <Row className="g-3 align-items-center">
                                <Col auto>
                                    <strong>Prix des services:</strong>{' '}
                                    {!!errors.commandeTarifs && (
                                        <FontAwesomeIcon
                                            icon={faExclamationTriangle}
                                            size="sm"
                                            style={{ color: '#dc3545', fontSize: '1rem' }}
                                        />
                                    )}
                                </Col>
                                <Col auto className="text-end">
                                    <strong>
                                        {tarifs
                                            .filter((d: TarifInterface) =>
                                                values.commandeTarifs.find((dd: any) => dd.idTarif === d.idTarif),
                                            )
                                            .reduce((accumulator: number, object: any) => {
                                                return accumulator + object.prixTarif;
                                            }, 0)}{' '}
                                        DH
                                    </strong>
                                </Col>
                            </Row>
                            <Form.Group controlId="form_grp_prixTotal">
                                <Row className=" g-3 align-items-center">
                                    <Col auto>
                                        <Form.Label style={{ fontWeight: 700, fontSize: '1rem' }}>Total</Form.Label>
                                    </Col>
                                    <Col auto>
                                        <InputGroup>
                                            <Form.Control
                                                className="text-end"
                                                type="text"
                                                name="prixTotal"
                                                placeholder="Prix Tarif"
                                                value={values.prixTotal}
                                                onChange={handleChange}
                                                isInvalid={!!errors.prixTotal}
                                            />
                                            <InputGroup.Text>DH</InputGroup.Text>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group controlId="form_grp_adresse">
                        <Form.Label>Adresse de livraison</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            name="adresse"
                            placeholder="Adresse de livraison"
                            value={values.adresse}
                            onChange={handleChange}
                            isInvalid={!!errors.adresse}
                        />
                    </Form.Group>
                    <div className="d-grid gap-2 mt-3">
                        <Button variant="primary" type="submit" disabled={!!spinIt}>
                            Enregistrer
                            {!!spinIt && (
                                <>
                                    &nbsp; <Spinner animation="border" size="sm" />
                                </>
                            )}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default CommandeEditForm;
