import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { AxiosResponse } from 'axios';

import DraftCommandeInterface, {
    AcheteurCommandeDraftResponse,
    ProduitCommandeDraftResponse,
} from 'types/interfaces/draftCommande.interface';
import ClientInterface from 'types/interfaces/client.interface';
import { getAllDraftCommandesByClient } from 'services/draftCommande.service';
import { getClientsByIdFournisseur } from 'services/client.service';

import { Deserialize } from 'pages/DraftCommande/utils';
import { utf8_to_b64, b64_to_utf8, useQueryMeMo, buildUrlQueryParams, isEmptyObject } from 'utils/helpers';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';

import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import TitleElement from 'components/UI/titleElement';
import TableTemplate from 'components/shared/datatable/tableTemplate';
import CustomModal from 'components/UI/customModal';
import ActionDraft from 'pages/DraftCommande/components/ActionDraft';
import DeleteDraftCommandesForm from 'pages/DraftCommande/components/DeleteDraftsForm';
import ExcelUploadComponent from 'components/shared/draftExcelFileUploader';

const DraftCommandePage: React.FC = () => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    const [drafts, setDrafts] = useState<DraftCommandeInterface[]>([]);

    const [checkDrafts, setCheckDrafts] = useState<number[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showSingleDeleteModal, setShowSingleDeleteModal] = useState<boolean>(false);
    const [selectedRowId, setSelectedRowId] = useState<number | null>(null);

    const [clients, setClients] = useState<ClientInterface[]>([]);
    const [selectedClient, setSelectedClient] = useState<string | null>(null);

    const history = useNavigate();
    const query = useQueryMeMo();

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleModalOpen = () => {
        setShowModal(true);
    };
    const handleSingleDeleteModalClose = () => {
        setShowSingleDeleteModal(false);
        setSelectedRowId(null);
    };

    const handleSingleDeleteModalOpen = (id: number) => {
        setSelectedRowId(id);
        setShowSingleDeleteModal(true);
    };

    const onCheckAllDrafts = useCallback(() => {
        setCheckDrafts((oldState) => {
            if (oldState.length === drafts.length) {
                return [];
            }

            return drafts.map((el: DraftCommandeInterface) => el.idDraftCommande);
        });
    }, [drafts]);

    const onCheckDraftsrow = useCallback(
        (row: DraftCommandeInterface) => {
            setCheckDrafts((oldState) => {
                const idx = oldState.findIndex((el: number) => el === row.idDraftCommande);

                if (idx === -1) {
                    return oldState.concat(row.idDraftCommande);
                }

                const newState = [...oldState];
                newState.splice(idx, 1);

                return newState;
            });
        },
        [drafts],
    );

    useEffect(() => {
        document.title = `Paquet rapide - DS`;
    }, []);

    useEffect(() => {
        if (contextUser) {
            const client = query.get('client');
            const currentClient =
                contextUser && contextUser.userData.type === 'Client' ? '' : client ? b64_to_utf8(client) : '';

            if (contextUser.userData.type !== 'Client' && !currentClient) {
                return;
            }
            setSelectedClient(currentClient!);

            getAllDraftCommandesByClient(currentClient!)
                .then(({ data }) => {
                    setDrafts(data);
                })
                .catch(() => {
                    toast.error("Une erreur s'est produite");
                });
        }
    }, [contextUser, query]);

    useEffect(() => {
        if (contextUser && contextUser.userData.type === 'Fournisseur') {
            getClientsByIdFournisseur(contextUser.userData.idFournisseur!)
                .then((response: AxiosResponse<ClientInterface[] | any>) => {
                    const { data } = response;
                    setClients(data);
                })
                .catch();
        }
    }, [contextUser]);

    const handlePageNFilterChange = (searchedData?: string) => {
        setCheckDrafts([]);
        const urlObject: any = {};
        if (searchedData) {
            const parsedObject = JSON.parse(searchedData);
            Object.keys(parsedObject).forEach((key) => {
                if (parsedObject[key as any]) {
                    if (key === 'client' && parsedObject[key as any])
                        urlObject[key] = utf8_to_b64(parsedObject[key as any]);
                    else urlObject[key] = parsedObject[key as any];
                }
            });
        }
        history(isEmptyObject(urlObject) ? window.location.pathname : `?${buildUrlQueryParams(urlObject)}`);
    };
    const arrayColumns = useMemo(
        () => [
            {
                Header: 'Référence',
                accessor: 'ref',
                Cell: ({ value }: any) => <span className="my-xsm-font">{value}</span>,
            },
            {
                Header: 'Acheteur',
                accessor: 'acheteurJson',
                Cell: ({ value }: any) => (
                    <span className="my-xsm-font fw-bolder">
                        {Deserialize<AcheteurCommandeDraftResponse>(value).fullName} <br />{' '}
                        {Deserialize<AcheteurCommandeDraftResponse>(value).gsm}
                    </span>
                ),
            },
            {
                Header: 'Adresse',
                accessor: 'adresse',
                Cell: ({ value }: any) => <span className="my-xsm-font">{value}</span>,
            },
            {
                Header: 'Commentaire',
                accessor: 'commentaire',
                Cell: ({ value }: any) => <span className="my-xsm-font">{value}</span>,
            },
            {
                Header: 'Produit',
                accessor: 'produitJson',
                Cell: ({ value }: any) => (
                    <span className="my-xsm-font fw-bold">
                        {Deserialize<ProduitCommandeDraftResponse>(value).designation ?? '-'} <br />
                        Qte: {Deserialize<ProduitCommandeDraftResponse>(value).quantity ?? 1} <br />
                        Prix: {Deserialize<ProduitCommandeDraftResponse>(value).prixVente ?? 0} DH
                    </span>
                ),
            },
            {
                Header: 'Prix Total',
                accessor: 'prixTotal',
                Cell: ({ value }: any) => <span className="my-xsm-font fw-bolder">{value} DH</span>,
            },
            {
                Header: 'Date',
                accessor: 'dateCreation',
                Cell: ({ value }: any) => (
                    <span className="my-xsm-font">{dayjs(value).format('DD/MM/YYYY HH:mm')}</span>
                ),
            },
            {
                Header: 'Editer',
                accessor: 'edit',
                Cell: ({ row: { original } }: any) => (
                    <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle id="dropdown-autoclose-true"></Dropdown.Toggle>

                        <Dropdown.Menu className="btnDropDownInList" style={{ width: 'auto', minWidth: 'auto' }}>
                            <Dropdown.Item
                                className="text-left"
                                as={Link}
                                to={`/commandes/draft/edit/${original.idDraftCommande}${
                                    selectedClient ? `?client=${utf8_to_b64(selectedClient!)}` : ''
                                }`}
                            >
                                <FontAwesomeIcon icon={faEdit} size="xs" style={{ color: '#337ab7' }} />
                            </Dropdown.Item>
                            <Dropdown.Item
                                className="text-left"
                                onClick={() => handleSingleDeleteModalOpen(original.idDraftCommande)}
                            >
                                <FontAwesomeIcon icon={faTrash} size="xs" style={{ color: '#f35a69' }} />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                ),
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: () => (
                    <input
                        type="checkbox"
                        checked={checkDrafts.length === drafts.length && checkDrafts.length !== 0}
                        onChange={onCheckAllDrafts}
                    />
                ),
                accessor: 'checkbox',
                Cell: ({ row }: any) => (
                    <>
                        <input
                            type="checkbox"
                            checked={checkDrafts.includes(row.original.idDraftCommande)}
                            onChange={() => onCheckDraftsrow(row.original)}
                        />
                    </>
                ),
                disableSortBy: true,
                disableFilters: true,
            },
        ],
        [drafts, checkDrafts, onCheckAllDrafts, onCheckDraftsrow],
    );

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { active: true, text: 'Paquet rapide' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Paquet rapide
                </TitleElement>
                {contextUser &&
                    (contextUser.userData.type === 'Client' ||
                        (contextUser.userData.type === 'Fournisseur' && selectedClient)) && (
                        <div className="d-flex gap-1">
                            <Link
                                className="btn btn-outline-secondary"
                                id="basic-addon1"
                                to={`/commandes/draft/new${
                                    selectedClient ? `?client=${utf8_to_b64(selectedClient!)}` : ''
                                }`}
                            >
                                Ajouter <FontAwesomeIcon icon={faAdd} size="xs" style={{ color: '#337ab7' }} />
                            </Link>
                            <ExcelUploadComponent idClient={+selectedClient!} />
                            {checkDrafts.length > 0 && (
                                <Button
                                    variant="outline-primary"
                                    className=""
                                    onClick={() => {
                                        handleModalOpen();
                                    }}
                                >
                                    Gérer la Sélection
                                </Button>
                            )}
                        </div>
                    )}
            </div>
            <Row className="mb-2 holdit_notEffect">
                {contextUser && contextUser.userData.type === 'Fournisseur' && (
                    <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_Client">
                        <Form.Label>Client:</Form.Label>
                        <Form.Select
                            size="sm"
                            name="selectedClient"
                            value={selectedClient!}
                            onChange={(e) => {
                                const {
                                    target: { value },
                                } = e;
                                setSelectedClient(value);
                                handlePageNFilterChange(
                                    JSON.stringify({
                                        client: value,
                                    }),
                                );
                            }}
                            placeholder="Selectionner un client"
                        >
                            <option value="">Selectionner un client</option>
                            {clients.map((d: ClientInterface) => (
                                <option key={d.idClient} value={d.idClient}>
                                    {d.raisonSocial}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                )}
            </Row>
            <TableTemplate
                columns={arrayColumns}
                data={drafts}
                options={{
                    pagination: true,
                    showGlobalFilter: true,
                    debugData: false,
                    showFiltersRowByColumn: true,
                    rowSelecting: false,
                    hiddenColumns: ['userId'],
                }}
            />
            <CustomModal title="Confirmez Votre Choix" size="lg" show={showModal} handleClose={handleModalClose}>
                <ActionDraft
                    close={handleModalClose}
                    list={drafts.filter((item: DraftCommandeInterface) => checkDrafts.includes(item.idDraftCommande))}
                    setList={setDrafts}
                    setChecks={setCheckDrafts}
                    checks={checkDrafts}
                    idClient={+selectedClient!}
                />
            </CustomModal>
            <CustomModal
                title="Suppression"
                size="lg"
                show={showSingleDeleteModal}
                handleClose={handleSingleDeleteModalClose}
            >
                <DeleteDraftCommandesForm
                    setList={setDrafts}
                    ids={[selectedRowId!]}
                    clear={() => setSelectedRowId(null)}
                    close={handleSingleDeleteModalClose}
                />
            </CustomModal>
        </>
    );
};

export default DraftCommandePage;
