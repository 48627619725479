import React, { useState, useEffect, useContext } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Container, Form, Button, Row, Col, InputGroup, Spinner } from 'react-bootstrap';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import Compressor from 'compressorjs';

import appStore, { StoreStateInterface } from 'store/appStore';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';

import SiteLogoFournisseurInterface from 'types/interfaces/siteLogoFournisseur.interface';
import { getSiteLogoFournisseurById, saveImageLogo } from 'services/siteLogoFournisseur.service';
import ImageModal from 'components/UI/imageModal';

import { readFileAsync, readImageTobase64, getImageDimension, scaleWidthToHeight } from 'utils/helpers';
import { MAX_SIZE_IMAGE_UPLOAD, LOGO_WIDTH, LOGO_MAX_HEIGHT } from 'constantes/envLoader';

const schema = yup.object({
    imageLogo: yup.mixed(),
});

const LogoChangePage: React.FC = () => {
    const setSiteLogo = appStore((state: StoreStateInterface) => state.setSiteLogo);
    const { user: contextUser } = useContext<AppContextState>(AppContext);
    const [siteLogoFournisseur, setSiteLogoFournisseur] = useState<SiteLogoFournisseurInterface | null>(null);
    const [spinIt, setSpinIt] = useState<boolean>(false);
    const [showImageModal, setShowImageModal] = useState<boolean>(false);
    const [imageFile, setImageFile] = useState<Blob | null>(null);

    const handleImageModalClose = () => {
        setShowImageModal(false);
    };

    const handleImageModalOpen = (file: Blob) => {
        const rFile = readImageTobase64(file);
        setImageFile(rFile as Blob);
        setShowImageModal(true);
    };

    const submitForm = async (imgFormValue: any) => {
        setSpinIt(true);
        if (imgFormValue.imageLogo) {
            try {
                if (imgFormValue.imageLogo) {
                    const fileResult = await readFileAsync(imgFormValue.imageLogo);
                    imgFormValue.imageLogo = (fileResult as string)?.replace(/^data:image\/(png);base64,/, '') || null;
                }
            } catch (ex) {
                imgFormValue.imageLogo = null;
            }
        }
        try {
            const response = await saveImageLogo(imgFormValue);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Logo a été enregistré avec succès');
                setSiteLogoFournisseur(resultData);
                setSiteLogo(resultData.imageLogo);
                setSpinIt(false);
            }
        } catch (Exception) {
            setSpinIt(false);
            const { message = undefined }: any = Exception;
            toast.error(message || 'Error occured!');
        }
    };

    useEffect(() => {
        getSiteLogoFournisseurById(contextUser?.userData.idFournisseur!)
            .then((response: AxiosResponse<SiteLogoFournisseurInterface | any>) => {
                const { data } = response;
                setSiteLogoFournisseur(data);
            })
            .catch();
    }, [contextUser]);

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        {
            active: true,
            text: 'Changengement de logo',
        },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <Container>
                <Formik
                    validationSchema={schema}
                    onSubmit={(values) => submitForm(values)}
                    enableReinitialize={true}
                    initialValues={{
                        imageLogo: null,
                    }}
                >
                    {({ setFieldValue, handleSubmit, values, errors }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row>
                                <Form.Group as={Col} md={{ span: 4, offset: 4 }} controlId="form_grp_imageLogo">
                                    <Form.Label>Logo</Form.Label>
                                    <InputGroup size="sm">
                                        <Form.Control
                                            size="sm"
                                            type="file"
                                            name="imageLogo"
                                            placeholder="Recu"
                                            onChange={async (event: any) => {
                                                const image = event.currentTarget.files[0];
                                                const imageDimension = await getImageDimension(image);
                                                if (imageDimension) {
                                                    const { width: orgWidth, height: orgHeight } = imageDimension;
                                                    const newWidth = scaleWidthToHeight(
                                                        LOGO_MAX_HEIGHT,
                                                        orgHeight,
                                                        orgWidth,
                                                    );
                                                    if (newWidth > LOGO_WIDTH) {
                                                        toast.warning("Image Dimension n'est pas valide");
                                                        return;
                                                    }
                                                }
                                                if (image.size > MAX_SIZE_IMAGE_UPLOAD) {
                                                    toast.warning(
                                                        'vous ne pouvez pas télécharger une image de plus de 1mo',
                                                    );
                                                    return;
                                                } else if (!image.name.match(/.(png)$/i)) {
                                                    toast.warning("Image Format n'est pas valide");
                                                    return;
                                                }
                                                // Compress the image
                                                new Compressor(image, {
                                                    quality: 0.8, // Adjust compression quality as needed
                                                    success: (compressedImage) => {
                                                        console.log(image.size, compressedImage.size);
                                                        setFieldValue('imageLogo', compressedImage);
                                                    },
                                                    error: () => {
                                                        toast.warning("Erreur lors de la compression de l'image");
                                                    },
                                                });
                                                // setFieldValue('imageLogo', image);
                                            }}
                                            isInvalid={!!errors.imageLogo}
                                            accept="image/png"
                                        />
                                        {(values.imageLogo || siteLogoFournisseur?.imageLogo!) && (
                                            <Button
                                                variant="outline-secondary"
                                                size="sm"
                                                onClick={() =>
                                                    handleImageModalOpen(
                                                        values.imageLogo! ?? siteLogoFournisseur?.imageLogo!,
                                                    )
                                                }
                                            >
                                                Afficher
                                            </Button>
                                        )}
                                    </InputGroup>
                                </Form.Group>
                            </Row>
                            <Row className="text-left mt-1">
                                <Col md={{ span: 4, offset: 4 }}>
                                    <p>
                                        <small>
                                            Veuillez noter que avant de télécharger un logo, il doit être redimensionné
                                            avec une hauteur de {LOGO_MAX_HEIGHT} pixels et une largeur de maximum{' '}
                                            {LOGO_WIDTH} pixels, sans dépasser une taille de {MAX_SIZE_IMAGE_UPLOAD}{' '}
                                            Bytes.
                                        </small>
                                    </p>
                                </Col>
                            </Row>
                            <div className="text-center">
                                <Button variant="primary" type="submit" disabled={!!spinIt}>
                                    Enregistrer
                                    {!!spinIt && (
                                        <>
                                            &nbsp; <Spinner animation="border" size="sm" />
                                        </>
                                    )}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
                <ImageModal
                    title="Logo"
                    file={imageFile}
                    alt="Logo"
                    show={showImageModal}
                    handleClose={handleImageModalClose}
                    fluid
                />
            </Container>
        </>
    );
};

export default LogoChangePage;
