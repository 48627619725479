import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import SiteLogoFournisseurInterface from 'types/interfaces/siteLogoFournisseur.interface';

const apiEndPoint = `${apiUrl}/sitelogofournisseur`;

export const getSiteLogoFournisseurById = (id: number): Promise<SiteLogoFournisseurInterface | any> => {
    return http.get(`${apiEndPoint}/${id}`);
};

export const saveImageLogo = (data: any): Promise<SiteLogoFournisseurInterface | any> => {
    return http.post(`${apiEndPoint}`, data);
};
