import React from 'react';
import { Table } from 'react-bootstrap';
import dayjs from 'dayjs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import TransfertFactureInterface from 'types/interfaces/transfertFacture.interface';
import Printer from 'components/shared/printer';
import SiteLogo from 'components/UI/siteLogo';
import TransfertFactureCommandeInterface from 'types/interfaces/transfertFactureCommande.interface';

type Props = {
    facturation: TransfertFactureInterface;
};

const FacturePrint: React.FC<Props> = ({ facturation }: Props) => {
    const { transfertFactureCommandes: factureDetails } = facturation;

    return (
        <Printer documentTitle="Facture">
            <>
                <div className="d-flex justify-content-between m-1">
                    <SiteLogo />
                    <div className="text-end">
                        <div> Le {dayjs().format('DD/MM/YYYY HH:mm')}</div>
                        <div>
                            <span className="my-xsm-font">
                                Créée le <b>{dayjs(facturation.dateCreation).format('DD/MM/YYYY HH:mm')}</b>
                                {facturation.paid === true && (
                                    <>
                                        <br />
                                        {facturation.confirmed! && (
                                            <>
                                                <FontAwesomeIcon
                                                    icon={faCheckCircle}
                                                    size="lg"
                                                    style={{ color: '#1d9c0c' }}
                                                    width="20"
                                                />{' '}
                                            </>
                                        )}
                                        Payée le <b>{dayjs(facturation.dateFin).format('DD/MM/YYYY HH:mm')}</b>
                                    </>
                                )}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="m-2">
                    <h6> Facture détails</h6>
                </div>
                <hr style={{ margin: 0 }} />
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>Commande</th>
                            <th>Prix de commande</th>
                            <th>Date</th>
                            <th>Ville</th>
                            <th>Prix</th>
                        </tr>
                    </thead>
                    <tbody>
                        {factureDetails?.map((facture: TransfertFactureCommandeInterface, index: number) => (
                            <tr key={index}>
                                <td>
                                    <b style={{ fontSize: '0.7rem' }}>
                                        {facture.transfertCommande?.commande?.code.replace('CMD', '')}
                                    </b>
                                    {facture.transfertCommande?.commande?.ref && (
                                        <>
                                            <br />
                                            <b style={{ fontSize: '0.6rem' }}>
                                                {facture.transfertCommande?.commande?.ref}
                                            </b>
                                        </>
                                    )}
                                </td>
                                <td>{facture.transfertCommande?.commande?.prixTotal!} DH</td>
                                <td>{dayjs(facture.transfertCommande?.dateCreation).format('DD/MM/YYYY')}</td>
                                <td>{facture.transfertCommande?.commande?.ville?.nomVille}</td>
                                <td>{facture.transfertCommande?.prixTarif} DH</td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan={2} style={{ textAlign: 'right' }}>
                                Total Commande :
                            </td>
                            <td>
                                <b>
                                    {factureDetails?.reduce(
                                        (total, facture) => total + facture.transfertCommande?.commande?.prixTotal!,
                                        0,
                                    )}{' '}
                                    DH
                                </b>
                            </td>
                            <td style={{ textAlign: 'right' }}>Total Facturation :</td>
                            <td>
                                <b>
                                    {factureDetails?.reduce(
                                        (total, facture) => total + facture.transfertCommande?.prixTarif!,
                                        0,
                                    )}{' '}
                                    DH
                                </b>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={4} style={{ textAlign: 'right' }}>
                                <b>Total :</b>
                            </td>
                            <td>
                                <b>
                                    {(factureDetails?.reduce(
                                        (total, facture) => total + facture.transfertCommande?.commande?.prixTotal!,
                                        0,
                                    ) ?? 0) -
                                        (factureDetails?.reduce(
                                            (total, facture) => total + facture.transfertCommande?.prixTarif!,
                                            0,
                                        ) ?? 0)}{' '}
                                    DH
                                </b>
                            </td>
                            <td style={{ textAlign: 'right' }}></td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
            </>
        </Printer>
    );
};

export default FacturePrint;
