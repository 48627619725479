import React from 'react';
import { InputGroup } from 'react-bootstrap';
import { useAsyncDebounce } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <div className="row flex-row-reverse mb-1">
            {/* <label
        for="gisearch"
        className="col-sm-2 col-form-label col-form-label-sm"
      >
        Search
      </label> */}
            <div className="col-sm-4">
                <InputGroup>
                    <InputGroup.Text className="bg-light inputgrp-border-right-none">
                        <FontAwesomeIcon icon={faSearch} size="2xs" />
                    </InputGroup.Text>
                    <input
                        id="gisearch"
                        value={value || ''}
                        onChange={(e) => {
                            setValue(e.target.value);
                            onChange(e.target.value);
                        }}
                        // placeholder={`${count} enregistrements...`}
                        placeholder=""
                        className="form-control form-control-sm colder-placeholder"
                    />
                </InputGroup>
            </div>
        </div>
    );
};

export default GlobalFilter;
