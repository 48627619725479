import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { Link } from 'react-router-dom';
import { TableColumnType } from 'react-bs-datatable';
import { Col, Form, Row, Button, Spinner, Badge } from 'react-bootstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Formik } from 'formik';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { utf8_to_b64 } from 'utils/helpers';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import Datatable from 'components/shared/datatable';
import ClockInput from 'components/shared/clockInput';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import ColisInterface from 'types/interfaces/colis.interface';
import AgenceInterface from 'types/interfaces/agence.interface';
import { getRamassageColis, confirmRamasseColisList } from 'services/colis.service';
import { getAgencesByIdFournisseur } from 'services/agence.service';
import TitleElement from 'components/UI/titleElement';

export const schema = yup.object({
    ids: yup.array().of(yup.number().required()).min(1).required(),
    idAgence: yup.number().required(),
    remarque: yup.string(),
});

const ColisRamassagePage: React.FC = () => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    const [colis, setColis] = useState<ColisInterface[]>([]);
    const [agences, setAgences] = useState<AgenceInterface[]>([]);
    const [checkedColis, setCheckedColis] = useState<number[]>([]);

    const [spinIt, setSpinIt] = useState<boolean>(false);

    const onCheckAllColis = useCallback(() => {
        setCheckedColis((oldState) => {
            if (oldState.length === colis.length) {
                return [];
            }

            return colis.map((el: ColisInterface) => el.idColis);
        });
    }, [colis]);

    const onCheckColis = useCallback(
        (row: ColisInterface) => {
            setCheckedColis((oldState) => {
                const idx = oldState.findIndex((el: number) => el === row.idColis);

                if (idx === -1) {
                    return oldState.concat(row.idColis);
                }

                const newState = [...oldState];
                newState.splice(idx, 1);

                return newState;
            });
        },
        [colis],
    );

    useEffect(() => {
        if (contextUser) {
            getRamassageColis()
                .then((response: AxiosResponse<ColisInterface[] | any>) => {
                    const { data } = response;
                    setColis(data);
                })
                .catch();
            getAgencesByIdFournisseur(contextUser.userData.idFournisseur!)
                .then((response: AxiosResponse<AgenceInterface[] | any>) => {
                    const { data } = response;
                    setAgences(data);
                })
                .catch();
        }
    }, [contextUser]);

    const submitRamassageColis = async (ramassageValues: any) => {
        setSpinIt(true);
        try {
            setSpinIt(true);
            const response = await confirmRamasseColisList(ramassageValues);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Colis Ramassé(s) avec succès');
                const colisData = [...colis].filter((dd) => !resultData.includes(dd.idColis));
                setColis(colisData);
                setCheckedColis([]);
                setSpinIt(false);
            }
        } catch (Exception) {
            setSpinIt(false);
        }

        setSpinIt(false);
    };

    const tableHeaders: TableColumnType<any>[] = useMemo(
        () => [
            {
                prop: 'checkbox',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                headerCell: () => (
                    <input type="checkbox" checked={checkedColis.length === colis.length} onChange={onCheckAllColis} />
                ),
                cell: (row: ColisInterface) => (
                    <input
                        type="checkbox"
                        checked={checkedColis.includes(row.idColis)}
                        // readOnly
                        onChange={() => onCheckColis(row)}
                    />
                ),
            },
            {
                prop: 'refColis',
                title: 'Ref',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
            },
            {
                prop: 'client.raisonSocial',
                title: 'Client',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
            },
            {
                prop: 'null2',
                title: '-',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                cell: (row: ColisInterface) => (
                    <>
                        {row.fragile && !!row.fragile && (
                            <>
                                <Badge bg="info">Fragile</Badge>&nbsp;
                            </>
                        )}
                        {row.aRecuperer && !!row.aRecuperer && (
                            <>
                                <Badge bg="info">À récuperer</Badge>&nbsp;
                            </>
                        )}
                        {row.aOuvrir && !!row.aOuvrir && (
                            <>
                                <Badge bg="info">À ouvrir</Badge>&nbsp;
                            </>
                        )}
                    </>
                ),
            },
            {
                prop: 'moyenTransport',
                title: 'Moyen de Transport',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
            },
            {
                prop: 'ramassage.dateAffectation',
                title: "Date d'affectation",
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: ColisInterface) => (
                    <>
                        {(row as any)['ramassage.dateAffectation']
                            ? moment((row as any)['ramassage.dateAffectation']).format('DD/MM/YYYY HH:mm')
                            : '-'}
                    </>
                ),
            },
            {
                prop: 'ramassage.remarque',
                title: 'Remarque',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
            },
            {
                prop: 'null',
                title: '#',
                alignment: { horizontal: 'right' },
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                cell: (row: ColisInterface) => (
                    <Link to={`/colis/edit/${utf8_to_b64(row.idColis.toString())}`}>
                        <Button
                            variant="outline-secondary"
                            className="button-input-group-effect gap-5marginpx"
                            id="basic-addon1"
                        >
                            <FontAwesomeIcon icon={faInfo} size="xs" style={{ color: '#376ca9' }} />
                        </Button>
                    </Link>
                ),
            },
        ],
        [checkedColis, onCheckAllColis, onCheckColis, colis, contextUser],
    );

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { text: 'Colis' },
        { active: true, text: 'Ramassage' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Colis à ramasser
                </TitleElement>
            </div>
            <Datatable
                data={colis}
                tableColumns={tableHeaders}
                // rowClickCB={onCheckColis}
            />
            {checkedColis.length > 0 && (
                <Formik
                    validationSchema={schema}
                    onSubmit={(values) => submitRamassageColis(values)}
                    enableReinitialize={true}
                    initialValues={{ ids: checkedColis, idAgence: contextUser?.userData?.idAgence!, remarque: '' }}
                >
                    {({ handleSubmit, handleChange, values, errors }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row>
                                <Form.Group as={Col} md="4" controlId="form_grp_date_r">
                                    <Form.Label>Date Ramassage</Form.Label>
                                    <ClockInput />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="form_grp_livreur">
                                    <Form.Label>Agences</Form.Label>
                                    <Form.Select
                                        name="idAgence"
                                        value={values.idAgence}
                                        onChange={handleChange}
                                        isInvalid={!!errors.idAgence}
                                        placeholder="Selectionner une agence"
                                    >
                                        <option>Selectionner une livreur</option>
                                        {agences.map((d: AgenceInterface) => (
                                            <option key={d.idAgence} value={d.idAgence}>
                                                {d.libelle}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="form_grp_remarque">
                                    <Form.Label>Remarque</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        as="textarea"
                                        rows={2}
                                        name="remarque"
                                        placeholder="Remarque"
                                        value={values.remarque}
                                        onChange={handleChange}
                                        isInvalid={!!errors.remarque}
                                    />
                                </Form.Group>
                            </Row>
                            <div className="gap-2 mt-3 float-end">
                                <Button className="ms-2" variant="outline-primary" type="submit" disabled={!!spinIt}>
                                    Enregistrer
                                    {!!spinIt && (
                                        <>
                                            &nbsp; <Spinner animation="border" size="sm" />
                                        </>
                                    )}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default ColisRamassagePage;
