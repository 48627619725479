import React, { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { Button, Col, Row, Form, Spinner } from 'react-bootstrap';
import { TableColumnType } from 'react-bs-datatable';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import RegionInterface from 'types/interfaces/region.interface';
import VilleInterface from 'types/interfaces/ville.interface';
import TarifTransfertInterface, {
    TarifTransfertsMultipleCreationInterface,
} from 'types/interfaces/tarifTransfert.interface';
import FournisseurInterface from 'types/interfaces/fournisseur.interface';
import { getRegionsWVilles } from 'services/region.service';
import { getTarifsFiltered, createMultipleTarif } from 'services/tarifTransfert.service';
import { getPartnersFournisseurs } from 'services/fournisseur.service';
import { groupingOptionsRegionVilles } from 'utils/helpers';

import CustomModal from 'components/UI/customModal';
import Datatable from 'components/shared/datatable';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import Paginator from 'components/UI/paginator';
import TitleElement from 'components/UI/titleElement';
import MultiSelectGroupedComponent from 'components/UI/multiSelectGroupedComponent';

const schema = yup.object({
    idFournisseur: yup.number().required(),
    idFournisseurTransfert: yup.number(),
    idVilles: yup.array().of(yup.number().required()).min(1).required(),
    prixTarif: yup.number().positive().required(),
});

const TOTAL_DATA_PER_PAGE = 100;

const TarifTransfertPage: React.FC = () => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);
    const [tarifs, setTarifs] = useState<{ items: TarifTransfertInterface[]; count: number }>({ items: [], count: 0 });
    const [regions, setRegions] = useState<RegionInterface[]>([]);
    const [fournisseurs, setFournisseurs] = useState<FournisseurInterface[]>([]);

    const [activePage, setActivePage] = useState<number>(1);
    const [selectedFournisseurTransfert, setSelectedFournisseurTransfert] = useState<string>('');
    const [selectedVille, setSelectedVille] = useState<string>('');

    const [showFormModal, setShowFormModal] = useState<boolean>(false);
    const [spinIt, setSpinIt] = useState<boolean>(false);

    const handleFormModalClose = () => {
        setShowFormModal(false);
    };

    const handleFormModalOpen = () => {
        setShowFormModal(true);
    };

    const tableHeaders: TableColumnType<any>[] = [
        {
            prop: 'fournisseurTransfert.raisonSocial',
            title: 'Fournisseur',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'ville.nomVille',
            title: 'Ville',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'prixTarif',
            title: 'Prix Tarif',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'dateCreation',
            title: 'Date',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: TarifTransfertInterface) => (
                <>{row.dateCreation ? dayjs(row.dateCreation).format('DD/MM/YYYY HH:mm') : '-'}</>
            ),
        },
    ];

    useEffect(() => {
        document.title = 'Paramétrage Tarif Transfert - DS';

        getRegionsWVilles()
            .then((response: AxiosResponse<RegionInterface[] | any>) => {
                const { data } = response;
                setRegions(data);
            })
            .catch();
    }, []);

    useEffect(() => {
        if (contextUser) {
            getPartnersFournisseurs()
                .then((response: AxiosResponse<FournisseurInterface[] | any>) => {
                    const { data } = response;
                    setFournisseurs(
                        data.filter(
                            (item: FournisseurInterface) => item.idFournisseur !== contextUser.userData?.idFournisseur!,
                        ),
                    );
                })
                .catch();
        }
    }, [contextUser]);

    useEffect(() => {
        if (contextUser && (selectedFournisseurTransfert || selectedVille || activePage)) {
            getTarifsFiltered(TOTAL_DATA_PER_PAGE, activePage, selectedFournisseurTransfert, selectedVille)
                .then((response: AxiosResponse<TarifTransfertInterface[] | any>) => {
                    const { data } = response;
                    setTarifs(data);
                })
                .catch();
        }
    }, [contextUser, selectedFournisseurTransfert, selectedVille, activePage]);

    const submitForm = async (tarifValues: TarifTransfertsMultipleCreationInterface | any) => {
        try {
            setSpinIt(true);
            const response = await createMultipleTarif(tarifValues, selectedFournisseurTransfert);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Tarif a été enregistré avec succès');
                // const tarifsData = [...tarifs.items, ...resultData];
                setTarifs({ items: resultData, count: resultData.length });
                handleFormModalClose();
                setSpinIt(false);
            }
        } catch (Exception) {
            setSpinIt(false);
        }
    };

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { text: 'Paramétrage' },
        { active: true, text: 'Tarif transfert' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Tarifs transfert
                </TitleElement>
                <Button
                    variant="outline-secondary"
                    className="button-input-group-effect"
                    id="basic-addon1"
                    onClick={() => handleFormModalOpen()}
                >
                    <FontAwesomeIcon icon={faPlus} size="xs" style={{ color: '#337ab7' }} />
                </Button>
            </div>
            <Datatable
                data={tarifs.items}
                tableColumns={tableHeaders}
                initialSort={{ initialState: { prop: 'idTarif', order: 'desc' } }}
            >
                <Row>
                    <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_sClient">
                        <Form.Label>Fournisseur:</Form.Label>
                        <Form.Select
                            size="sm"
                            name="selectedFournisseurTransfert"
                            value={selectedFournisseurTransfert}
                            onChange={(e) => {
                                const {
                                    target: { value },
                                } = e;
                                setSelectedFournisseurTransfert(value);
                                setActivePage(1);
                            }}
                            placeholder="Selectionner un fournisseur"
                        >
                            <option value="">Tous</option>
                            {fournisseurs.map((dr: FournisseurInterface) => (
                                <option key={dr.idFournisseur} value={dr.idFournisseur}>
                                    {dr.raisonSocial}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_sVille">
                        <Form.Label>Villes</Form.Label>
                        <Form.Select
                            name="selectedVille"
                            value={selectedVille}
                            onChange={(e) => {
                                const {
                                    target: { value },
                                } = e;
                                setSelectedVille(value);
                                setActivePage(1);
                            }}
                            placeholder="Selectionner une ville"
                        >
                            <option value="">Tous</option>
                            {regions.map((dr: RegionInterface) => (
                                <optgroup key={dr.idRegion} label={dr.nomRegion}>
                                    {dr.villes?.map((d: VilleInterface) => (
                                        <option key={d.idVille} value={d.idVille}>
                                            {d.nomVille}
                                        </option>
                                    ))}
                                </optgroup>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Col className="d-flex flex-col justify-content-end align-items-end mt-1">
                        <Paginator
                            defaultPage={activePage}
                            totalPages={Math.ceil(tarifs.count / TOTAL_DATA_PER_PAGE)}
                            callback={(e_page) => setActivePage(e_page)}
                        />
                    </Col>
                </Row>
            </Datatable>
            <CustomModal title="New Tarif" size="lg" show={showFormModal} handleClose={handleFormModalClose}>
                <div>
                    <Formik
                        validationSchema={schema}
                        onSubmit={(values) => submitForm(values)}
                        enableReinitialize={true}
                        initialValues={{
                            idTarifTransfert: undefined,
                            prixTarif: undefined,
                            idFournisseurTransfert: undefined,
                            idVilles: undefined,
                            idFournisseur: contextUser?.userData.idFournisseur!,
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group controlId="form_grp_ptarif">
                                    <Form.Label>Prix Tarif</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="prixTarif"
                                        placeholder="Prix Tarif"
                                        value={values.prixTarif}
                                        onChange={handleChange}
                                        isInvalid={!!errors.prixTarif}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.prixTarif}</Form.Control.Feedback>
                                </Form.Group>

                                <Row>
                                    <Form.Group as={Col} md="6" controlId="form_grp_frse">
                                        <Form.Label>Fournisseur</Form.Label>
                                        <Form.Select
                                            name="idFournisseurTransfert"
                                            value={values.idFournisseurTransfert}
                                            onChange={handleChange}
                                            isInvalid={!!errors.idFournisseurTransfert}
                                            placeholder="Selectionner un fournisseur"
                                        >
                                            <option>Selectionner un fournisseur</option>
                                            {fournisseurs.map((d: FournisseurInterface) => (
                                                <option key={d.idFournisseur} value={d.idFournisseur}>
                                                    {d.raisonSocial}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId="form_grp_dd">
                                        <Form.Label>Villes</Form.Label>
                                        {/* <Form.Select
                                            name="idVilles"
                                            value={values.idVilles}
                                            onChange={handleChange}
                                            isInvalid={!!errors.idVilles}
                                            placeholder="Selectionner des villes"
                                            multiple
                                        >
                                            <option>Selectionner des villes</option>
                                            {regions.map((dr: RegionInterface) => (
                                                <optgroup key={dr.idRegion} label={dr.nomRegion}>
                                                    {dr.villes?.map((d: VilleInterface) => (
                                                        <option key={d.idVille} value={d.idVille}>
                                                            {d.nomVille}
                                                        </option>
                                                    ))}
                                                </optgroup>
                                            ))}
                                        </Form.Select> */}
                                        <MultiSelectGroupedComponent
                                            options={groupingOptionsRegionVilles(regions)}
                                            placeholder="Selectionner des villes"
                                            onChange={(selectedItems: string[]) => {
                                                handleChange({ target: { value: selectedItems, name: 'idVilles' } });
                                            }}
                                            classNames={!!errors.idVilles ? 'is-invalid' : ''}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.idVilles}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <div className="d-grid gap-2 mt-3">
                                    <Button variant="primary" type="submit" disabled={!!spinIt}>
                                        Enregistrer
                                        {!!spinIt && (
                                            <>
                                                &nbsp; <Spinner animation="border" size="sm" />
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </CustomModal>
        </>
    );
};

export default TarifTransfertPage;
