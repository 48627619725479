import http from 'services/httpService';
import { apiUrl, localStorageToken } from 'constantes/envLoader';
import { authCredentials, userActivePassword, userReqResetPassword } from 'types/index';
import { savingAuth } from 'utils/getUser';
import { UserAuthInterface } from 'types/index';

const apiEndPoint = `${apiUrl}/auth`;

export const postLogin = async (credentials: authCredentials): Promise<UserAuthInterface | any> => {
    try {
        const {
            data: { token, imageLogo },
        } = await http.post(`${apiEndPoint}/Login`, credentials);
        return savingAuth(token, imageLogo);
    } catch (ex) {
        throw ex;
    }
};
export const getDirectAuth = async (id: number): Promise<any> => http.get(`${apiEndPoint}/${id}/direct`);

export const logout = (): void => {
    localStorage.removeItem(localStorageToken);
};

export const resetPasswordRequest = (data: userReqResetPassword): Promise<any> => {
    return http.post(`${apiEndPoint}/reset/password`, data);
};

export const vrifyResetToken = (token: string): Promise<any> => {
    return http.get(`${apiEndPoint}/verify/reset/${token}`);
};

export const changeResetPassword = (token: string, data: userActivePassword): Promise<any> => {
    return http.post(`${apiEndPoint}/verify/reset/${token}`, data);
};
