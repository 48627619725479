import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { Formik } from 'formik';
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import CustomModal from 'components/UI/customModal';
import { getCommandeByCodeOrRefForAction } from 'services/commande.service';
import CommandeInterface from 'types/interfaces/commande.interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode, faX } from '@fortawesome/free-solid-svg-icons';
import TransfertCommandeInterface from 'types/interfaces/transfertCommande.interface';
import { isNumber, utf8_to_b64 } from 'utils/helpers';

const schema = yup.object({
    code: yup.string().required(),
});

type Props = {
    pageType: string;
    setCommandes: React.Dispatch<React.SetStateAction<CommandeInterface[]>>;
    query?: URLSearchParams;
};
const ScanByCodeOrRefComoponent: React.FC<Props> = ({ setCommandes, pageType, query }: Props) => {
    const [show, setShow] = useState<boolean>(false);
    const [spinIt, setSpinIt] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (pageType === 'transfert_cmd') return;
        setShow(true);
    }, []);

    const submitForm = async (values: any, { resetForm }: any) => {
        try {
            setSpinIt(true);
            const response: AxiosResponse<CommandeInterface> = await getCommandeByCodeOrRefForAction(
                values.code,
                pageType,
            );
            if (response.status === 200) {
                const { data: commande } = response;
                const destinataire = Number(query?.get('destinataire'));
                if (
                    isNumber(destinataire) &&
                    (destinataire === commande.idFournisseur ||
                        commande.transfertCommandes?.some(
                            (item: TransfertCommandeInterface) => item.idFournisseurDestinataire === destinataire,
                        )!)
                ) {
                    toast.warning('Action est refusée');
                    return;
                }
                setCommandes((prev: CommandeInterface[]) => {
                    const updatedData = prev.filter(
                        (item: CommandeInterface) => item.idCommande.toString() !== commande.idCommande.toString(),
                    );
                    return [commande, ...updatedData];
                });

                // Reset the form
                resetForm();
            }
        } catch (error: any) {
            if (error && error.status === 404) toast.warning("Commande n'existe pas");
            else if (error) {
                if (error.message && error.commande)
                    toast.warning(() => (
                        <div className="d-flex flex-column justify-content-center align-items-start">
                            <div>{error.message}</div>
                            <Button
                                className="align-self-center"
                                variant="outline-secondary"
                                onClick={() =>
                                    navigate(`/commandes?open=${utf8_to_b64(error.commande.idCommande?.toString()!)}`)
                                }
                            >
                                Voir détails commande
                            </Button>
                        </div>
                    ));
                else toast.warning(error);
            } else toast.error('Erreur est survenue');
        } finally {
            setSpinIt(false);
        }
    };

    return (
        <>
            {(pageType !== 'transfert_cmd' || (pageType === 'transfert_cmd' && query?.get('destinataire'))) && (
                <Button variant="outline-info" onClick={() => setShow(true)}>
                    <FontAwesomeIcon icon={faBarcode} size="lg" />
                </Button>
            )}

            <CustomModal title="Scanner le code barre" show={show} handleClose={() => setShow(false)}>
                <div>
                    <Formik
                        validationSchema={schema}
                        onSubmit={(values, { resetForm }) => submitForm(values, { resetForm })}
                        enableReinitialize={true}
                        initialValues={{
                            code: '',
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group controlId="form_grp_nom">
                                    <Form.Label>Code barre / Ref</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type="text"
                                            name="code"
                                            placeholder="Code barre"
                                            value={values.code}
                                            onChange={handleChange}
                                            isInvalid={!!errors.code}
                                            autoFocus={true}
                                        />
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => handleChange({ target: { name: 'code', value: '' } })}
                                        >
                                            <FontAwesomeIcon icon={faX} size="lg" color="#3b3535" />
                                        </Button>
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">{errors.code}</Form.Control.Feedback>
                                </Form.Group>
                                <div className="d-grid gap-2 mt-3">
                                    <Button variant="primary" type="submit" disabled={!!spinIt}>
                                        Trouver
                                        {!!spinIt && (
                                            <>
                                                &nbsp; <Spinner animation="border" size="sm" />
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </CustomModal>
        </>
    );
};

export default ScanByCodeOrRefComoponent;
