import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { TableColumnType } from 'react-bs-datatable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck, faInfo } from '@fortawesome/free-solid-svg-icons';

import UserInterface from 'types/interfaces/user.interface';
import { getActiveLivreurs } from 'services/user.service';

import Datatable from 'components/shared/datatable';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import TitleElement from 'components/UI/titleElement';
import { AxiosResponse } from 'axios';
import CustomModal from 'components/UI/customModal';
import CommandesList from 'pages/Users/components/CommandesList';
import ExportExcel from 'components/shared/excelExport/index';
import CommandeInterface from 'types/interfaces/commande.interface';
import { getLivreursActiveCommandes } from 'services/commande.service';

const ActiveLivreursPage: React.FC = () => {
    const [users, setUsers] = useState<UserInterface[]>([]);
    const [targetedUser, setTargetedUser] = useState<UserInterface | null>(null);
    const [commandes, setCommandes] = useState<{ items: CommandeInterface[]; count: number }>({
        items: [],
        count: 0,
    });

    const [showModal, setShowModal] = useState<boolean>(false);

    const handleModalClose = () => {
        setCommandes({
            items: [],
            count: 0,
        });
        setTargetedUser(null);
        setShowModal(false);
    };

    const handleModalOpen = (userData: UserInterface) => {
        setTargetedUser(userData);
        getLivreursActiveCommandes(userData.idUser).then(
            (response: AxiosResponse<{ items: CommandeInterface[]; count: number }>) => {
                setCommandes(response.data);
            },
        );
        setShowModal(true);
    };

    const tableHeaders: TableColumnType<any>[] = [
        {
            prop: 'nom',
            title: 'Nom complet',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: UserInterface) => <>{`${row.nom.toUpperCase()} ${row.prenom.toLowerCase()}`}</>,
        },
        {
            prop: '--commandes',
            title: 'Commandes actifs',
            alignment: { horizontal: 'center' },
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: UserInterface) => <>{row.livreurCommandes?.length ?? 0}</>,
        },
        {
            prop: 'employe',
            title: 'Type',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: UserInterface) => <>{row.employe ?? 'Non défini'}</>,
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'requireDr',
            title: 'Demande retour',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: UserInterface) => <>{row.requireDr ? 'Oui' : 'Non'}</>,
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'etatCompte',
            title: 'Etat de Compte',
            cellProps: {
                className: 'foceVerticalMiddle text-center',
            },
            thProps: {
                className: 'text-center',
            },
            cell: (row: UserInterface) => (
                <>
                    {!!row.etatCompte ? (
                        <FontAwesomeIcon icon={faCheck} size="xs" style={{ color: '#337ac8' }} />
                    ) : (
                        <FontAwesomeIcon icon={faXmark} size="xs" style={{ color: '#ff832c' }} />
                    )}
                </>
            ),
        },
        {
            prop: 'null',
            title: 'Actions',
            alignment: { horizontal: 'right' },
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: UserInterface) => (
                <>
                    <Button
                        variant="outline-secondary"
                        className="button-input-group-effect gap-5marginpx"
                        id="basic-addon1"
                        onClick={() => handleModalOpen(row)}
                    >
                        <FontAwesomeIcon icon={faInfo} size="xs" style={{ color: '#337ab7' }} />
                    </Button>
                </>
            ),
        },
    ];

    useEffect(() => {
        document.title = 'Livreurs actifs - DS';
        getActiveLivreurs()
            .then((response: AxiosResponse<UserInterface[] | any>) => {
                const { data } = response;
                setUsers(data);
            })
            .catch();
    }, []);

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { text: 'Agents' },
        { active: true, text: 'Livreurs actifs' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Livreurs actifs
                </TitleElement>
            </div>
            <Datatable data={users} tableColumns={tableHeaders} />
            <CustomModal title="Commandes" size="lg" show={showModal} handleClose={handleModalClose} fullscreen>
                <ExportExcel
                    excelData={commandes.items.map((clt: CommandeInterface) => {
                        return {
                            code: clt.code,
                            ref: clt.ref,
                            adresse: clt.adresse,
                            dateCreation: clt.dateCreation,
                            dateOperation: clt.dateOperation,
                            prixTotal: clt.prixTotal,
                            acheteur: clt.acheteur.fullName,
                            ville: clt.ville.nomVille,
                            client: clt.client.raisonSocial,
                            etatCommande: clt.etatCommande.etatCommande,
                            colis: clt.colis.refColis,
                        };
                    })}
                    fileName="Commandes - LA"
                    variant="outline-primary"
                />
                <CommandesList commandes={commandes} setCommandes={setCommandes} livreur={targetedUser!} />
            </CustomModal>
        </>
    );
};

export default ActiveLivreursPage;
