import React, { useState } from 'react';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import { Formik } from 'formik';
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';

import CustomModal from 'components/UI/customModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode, faX } from '@fortawesome/free-solid-svg-icons';
import DemandeRetourLivreurInterface from 'types/interfaces/demandeRetourLivreur.interface';

const schema = yup.object({
    code: yup.string().required(),
});

type Props = {
    showScanButton: boolean;
    checkedRows: number[];
    checkRow: (row: DemandeRetourLivreurInterface) => void;
    list: DemandeRetourLivreurInterface[];
};
const ScanByCodeOrRefDRLComoponent: React.FC<Props> = ({ showScanButton, checkedRows, checkRow, list }: Props) => {
    const [show, setShow] = useState<boolean>(false);
    const [spinIt, setSpinIt] = useState<boolean>(false);

    const submitForm = async (values: any, { resetForm }: any) => {
        try {
            setSpinIt(true);
            const row = list.find((item) => item.commande?.code === values.code || item.commande?.ref === values.code);

            if (!row) {
                toast.error("La commande n'existe pas.");
                return;
            }

            if (checkedRows.includes(row.idDemandeRetourLivreur)) {
                toast.warning('La commande est déjà selectionnée.');
            } else {
                checkRow(row);
                toast.success('Commande sélectionnée avec succès!');
            }

            // Reset the form
            resetForm();
        } finally {
            setSpinIt(false);
        }
    };

    return (
        <>
            {showScanButton && (
                <Button variant="outline-info" onClick={() => setShow(true)}>
                    <FontAwesomeIcon icon={faBarcode} size="lg" />
                </Button>
            )}

            <CustomModal title="Scanner le code barre" show={show} handleClose={() => setShow(false)}>
                <div>
                    <Formik
                        validationSchema={schema}
                        onSubmit={(values, { resetForm }) => submitForm(values, { resetForm })}
                        enableReinitialize={true}
                        initialValues={{
                            code: '',
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group controlId="form_grp_nom">
                                    <Form.Label>Code barre / Ref</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type="text"
                                            name="code"
                                            placeholder="Code barre"
                                            value={values.code}
                                            onChange={handleChange}
                                            isInvalid={!!errors.code}
                                            autoFocus={true}
                                        />
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => handleChange({ target: { name: 'code', value: '' } })}
                                        >
                                            <FontAwesomeIcon icon={faX} size="lg" color="#3b3535" />
                                        </Button>
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">{errors.code}</Form.Control.Feedback>
                                </Form.Group>
                                <div className="d-grid gap-2 mt-3">
                                    <Button variant="primary" type="submit" disabled={!!spinIt}>
                                        Trouver
                                        {!!spinIt && (
                                            <>
                                                &nbsp; <Spinner animation="border" size="sm" />
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </CustomModal>
        </>
    );
};

export default ScanByCodeOrRefDRLComoponent;
