import React from 'react';
import { Badge } from 'react-bootstrap';

type Props = {
    value: string;
    textColor?: string;
    backgroundColor?: string;
    className?: string;
};

const CustomColorsBadge: React.FC<Props> = ({ value, backgroundColor, textColor, className }: Props) => (
    <Badge
        ref={(node: any) => {
            if (node) {
                node.style.setProperty('color', textColor, 'important');
                node.style.setProperty('background-color', backgroundColor, 'important');
            }
        }}
        className={className}
    >
        {value}
    </Badge>
);

CustomColorsBadge.defaultProps = {
    textColor: '#fff',
    backgroundColor: '#6c757d',
};

export default CustomColorsBadge;
