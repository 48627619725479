import React, { useState, useEffect, useContext } from 'react';
import { AxiosResponse } from 'axios';
import { TableColumnType } from 'react-bs-datatable';
import moment from 'moment';
import * as yup from 'yup';
import { Field, FieldArray, Formik } from 'formik';
import { toast } from 'react-toastify';
import { Button, Badge, Form, Spinner, InputGroup, Row, Col } from 'react-bootstrap';

import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import CustomModal from 'components/UI/customModal';
import Datatable from 'components/shared/datatable';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import ColisInterface from 'types/interfaces/colis.interface';
import EmplacementInterface from 'types/interfaces/emplacement.interface';
import ColisProduitEnvoyeInterface from 'types/interfaces/colisProduitEnvoye.interface';
import { getSpecEmplacements } from 'services/emplacement.service';
import { getReceptionColis, doReceptionnerColis } from 'services/colis.service';
import TitleElement from 'components/UI/titleElement';

export const schema = yup.object({
    idColis: yup.number(),
    idEmp: yup.number().required(),
    colisproduitenvoyes: yup.array().required(),
    colisproduitrecus: yup
        .array()
        .of(
            yup.object({
                quantiteReceptione: yup.number().min(0),
            }),
        )
        .min(1)
        .required(),
    commentaire: yup.string(),
});

const ColisReceptionPage: React.FC = () => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    const [colis, setColis] = useState<ColisInterface[]>([]);
    const [currentColis, setCurrentColis] = useState<ColisInterface | null>(null);
    const [emplacements, setEmplacements] = useState<EmplacementInterface[]>([]);

    const [showFormModal, setShowFormModal] = useState<boolean>(false);
    const [spinIt, setSpinIt] = useState<boolean>(false);

    const handleFormModalClose = () => {
        setCurrentColis(null);
        setShowFormModal(false);
    };

    const handleFormModalOpen = (colisData: ColisInterface) => {
        setCurrentColis(colisData);
        setShowFormModal(true);
    };

    useEffect(() => {
        if (contextUser) {
            getReceptionColis()
                .then((response: AxiosResponse<ColisInterface[] | any>) => {
                    const { data } = response;
                    setColis(data);
                })
                .catch();
            getSpecEmplacements()
                .then((response: AxiosResponse<EmplacementInterface[] | any>) => {
                    const { data } = response;
                    setEmplacements(data);
                })
                .catch();
        }
    }, [contextUser]);

    const submitForm = async (colisValues: ColisInterface | any) => {
        const data = { ...colisValues };
        data.colisproduitenvoyes.forEach((d: ColisProduitEnvoyeInterface, i: number) => {
            const qteRecu = data.colisproduitrecus[i].quantiteReceptione;
            d.quantiteRecu = qteRecu;
            d.qteNonExpide = qteRecu;
            data.colisproduitrecus[i].commentaire = data.commentaire;
            data.colisproduitrecus[i].quantiteReste = qteRecu;
            data.colisproduitrecus[i].idEmp = data.idEmp;
        });
        try {
            setSpinIt(true);
            const response = await doReceptionnerColis(data);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Colis Réceptionné avec succès');
                const colisData = [...colis].filter((dd) => resultData.idColis !== dd.idColis);
                setColis(colisData);
                handleFormModalClose();
                setSpinIt(false);
            }
        } catch (Exception) {
            setSpinIt(false);
        }
    };

    const tableHeaders: TableColumnType<any>[] = [
        {
            prop: 'refColis',
            title: 'Ref',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'ramassage.dateRamassage',
            title: 'Date de ramassage',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: ColisInterface) => (
                <>{moment((row as any)['ramassage.dateRamassage']).format('DD/MM/YYYY HH:mm')}</>
            ),
        },
        {
            prop: 'client.raisonSocial',
            title: 'Client',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'agence.libelle',
            title: 'Agence',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'dateEnvoi',
            title: "Date d'envoi",
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: ColisInterface) => <>{moment((row as any)['dateEnvoi']).format('DD/MM/YYYY HH:mm')}</>,
        },
        {
            prop: 'ramassage.remarque',
            title: 'Remarque',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'null2',
            title: '-',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: ColisInterface) => (
                <>
                    {row.fragile && !!row.fragile && (
                        <>
                            <Badge bg="info">Fragile</Badge>&nbsp;
                        </>
                    )}
                    {row.aRecuperer && !!row.aRecuperer && (
                        <>
                            <Badge bg="info">À récuperer</Badge>&nbsp;
                        </>
                    )}
                    {row.aOuvrir && !!row.aOuvrir && (
                        <>
                            <Badge bg="info">À ouvrir</Badge>&nbsp;
                        </>
                    )}
                </>
            ),
        },
        {
            prop: 'null',
            title: '#',
            alignment: { horizontal: 'right' },
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: ColisInterface) => (
                <Button size="sm" variant="outline-success" onClick={() => handleFormModalOpen(row)}>
                    Réceptioner
                </Button>
            ),
        },
    ];

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { text: 'Colis' },
        { active: true, text: 'Réception' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Colis à récuperer
                </TitleElement>
            </div>
            <Datatable data={colis} tableColumns={tableHeaders} />
            <CustomModal title="Réceptionner colis" size="lg" show={showFormModal} handleClose={handleFormModalClose}>
                <div>
                    <Formik
                        validationSchema={schema}
                        onSubmit={(values) => submitForm(values)}
                        enableReinitialize={false}
                        initialValues={{
                            idColis: currentColis?.idColis,
                            idEmp: undefined,
                            colisproduitenvoyes: currentColis ? currentColis.colisproduitenvoyes : [],
                            colisproduitrecus: currentColis
                                ? currentColis.colisproduitenvoyes.map((d: ColisProduitEnvoyeInterface) => {
                                      return {
                                          idColis: d.idColis,
                                          idProduit: d.idProduit,
                                          quantiteReceptione: d.quantiteEnvoi,
                                          produit: d.produit,
                                      };
                                  })
                                : [],
                            commentaire: '',
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <div className="d-grid gap-2 mt-3">
                                    <Row>
                                        <Form.Group as={Col} md="6" lg="6" controlId="form_grp_emp">
                                            <Form.Label>Réf</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                name="refColis"
                                                placeholder="RefColis"
                                                value={currentColis?.refColis}
                                                onChange={() => void 0}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" lg="6" controlId="form_grp_emp">
                                            <Form.Label>Emplacements</Form.Label>
                                            <Form.Select
                                                name="idEmp"
                                                value={values.idEmp}
                                                onChange={handleChange}
                                                isInvalid={!!errors.idEmp}
                                                placeholder="Selectionner un emplacement"
                                            >
                                                <option>Selectionner une emplacement</option>
                                                {emplacements.map((d: EmplacementInterface) => (
                                                    <option key={d.idEmplacement} value={d.idEmplacement}>
                                                        {d.libelle}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>
                                </div>
                                <InputGroup className="mt-1 header-multi-produit">
                                    <Field
                                        name={`header.produit.ref`}
                                        type="text"
                                        value="Ref"
                                        className="form-control"
                                        readOnly
                                    />
                                    <Field
                                        name={`header.produit.designation`}
                                        type="text"
                                        value="Designation"
                                        className="form-control"
                                        readOnly
                                    />
                                    <Field
                                        name={`header.produit.prixVente`}
                                        type="text"
                                        value="PrixVente"
                                        className="form-control"
                                        readOnly
                                    />
                                    <Field
                                        name={`header.quantity`}
                                        type="text"
                                        value="Quantity Récu"
                                        className="form-control"
                                        readOnly
                                    />
                                </InputGroup>
                                <FieldArray
                                    name="colisproduitrecus"
                                    render={() =>
                                        values.colisproduitrecus &&
                                        values.colisproduitrecus.map((rnData, index) => {
                                            const rnErrors =
                                                errors.colisproduitrecus?.length && errors.colisproduitrecus[index];
                                            return (
                                                <InputGroup
                                                    key={index}
                                                    className={`${index === 0 ? 'body-multi-produit-first' : ''}`}
                                                >
                                                    <Field
                                                        name={`colisproduitrecus[${index}].produit.ref`}
                                                        type="text"
                                                        placeholder="Ref"
                                                        className={`form-control ${!!rnErrors ? ' is-invalid' : ''}`}
                                                        readOnly
                                                        value={
                                                            index === 0 &&
                                                            values.colisproduitrecus.length === 1 &&
                                                            rnData.produit?.ref === 'DP-X0000X'
                                                                ? '-'
                                                                : rnData.produit?.ref
                                                        }
                                                    />
                                                    <Field
                                                        name={`colisproduitrecus[${index}].produit.designation`}
                                                        type="text"
                                                        placeholder="Designation"
                                                        className={`form-control ${!!rnErrors ? ' is-invalid' : ''}`}
                                                        readOnly
                                                    />
                                                    <Field
                                                        name={`colisproduitrecus[${index}].produit.prixVente`}
                                                        type="text"
                                                        placeholder="Prix"
                                                        className={`form-control ${!!rnErrors ? ' is-invalid' : ''}`}
                                                        readOnly
                                                    />
                                                    <Field
                                                        name={`colisproduitrecus[${index}].quantiteReceptione`}
                                                        type="number"
                                                        min={1}
                                                        placeholder="Quantity"
                                                        className={`form-control ${!!rnErrors ? ' is-invalid' : ''}`}
                                                    />
                                                </InputGroup>
                                            );
                                        })
                                    }
                                />
                                <div className="d-grid gap-2 mt-3">
                                    <Form.Group controlId="form_grp_commentaire">
                                        <Form.Label>Commentaire</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            as="textarea"
                                            rows={2}
                                            name="commentaire"
                                            placeholder="CommentaIre"
                                            value={values.commentaire}
                                            onChange={handleChange}
                                            isInvalid={!!errors.commentaire}
                                        />
                                    </Form.Group>
                                    <Button variant="primary" type="submit" disabled={!!spinIt}>
                                        Enregistrer
                                        {!!spinIt && (
                                            <>
                                                &nbsp; <Spinner animation="border" size="sm" />
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </CustomModal>
        </>
    );
};

export default ColisReceptionPage;
