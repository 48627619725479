import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { createDemandeRetourStockRange } from 'services/demandeRetourStock.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

type Props = {
    cmdIds: number[];
    resetCheckedInputs: () => void;
    reloadData: () => void;
    closeModal: () => void;
};

const DemandeRetourStockFormRangeAction: React.FC<Props> = ({
    cmdIds,
    reloadData,
    resetCheckedInputs,
    closeModal,
}: Props) => {
    const [spinIt, setSpinIt] = useState<boolean>(false);

    const submitForm = async () => {
        try {
            setSpinIt(true);
            createDemandeRetourStockRange(cmdIds)
                .then(() => {
                    reloadData();
                    toast.success('Demandes ont été créee avec succès');
                    setSpinIt(false);
                    resetCheckedInputs();
                    closeModal();
                })
                .catch(() => {
                    setSpinIt(false);
                });
        } catch (err) {
            console.log(err);
            setSpinIt(false);
        }
    };

    return (
        <>
            <div className="d-flex justify-content-center gap-1">
                <Button variant="success" onClick={() => submitForm()} disabled={!!spinIt}>
                    Confirmer
                    {!!spinIt && (
                        <>
                            &nbsp; <Spinner animation="border" size="sm" />
                        </>
                    )}
                </Button>
                <Button variant="warning" onClick={() => closeModal()} disabled={!!spinIt}>
                    <FontAwesomeIcon icon={faX} size="xs" />
                </Button>
            </div>
        </>
    );
};

export default DemandeRetourStockFormRangeAction;
