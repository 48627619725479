export type colorsPaletteType = {
    transparent: string;
    originalColor: string;
};

const colorsPalette: colorsPaletteType[] = [
    {
        transparent: '#63b59870',
        originalColor: '#63b598',
    },
    {
        transparent: '#ce7d7870',
        originalColor: '#ce7d78',
    },
    {
        transparent: '#ea9e7070',
        originalColor: '#ea9e70',
    },
    {
        transparent: '#a48a9e70',
        originalColor: '#a48a9e',
    },
    {
        transparent: '#c6e1e870',
        originalColor: '#c6e1e8',
    },
    {
        transparent: '#64817770',
        originalColor: '#648177',
    },
    {
        transparent: '#0d5ac170',
        originalColor: '#0d5ac1',
    },
    {
        transparent: '#f205e670',
        originalColor: '#f205e6',
    },
    {
        transparent: '#1c036570',
        originalColor: '#1c0365',
    },
    {
        transparent: '#14a9ad70',
        originalColor: '#14a9ad',
    },
    {
        transparent: '#4ca2f970',
        originalColor: '#4ca2f9',
    },
    {
        transparent: '#a4e43f70',
        originalColor: '#a4e43f',
    },
    {
        transparent: '#d298e270',
        originalColor: '#d298e2',
    },
    {
        transparent: '#6119d070',
        originalColor: '#6119d0',
    },
    {
        transparent: '#d2737d70',
        originalColor: '#d2737d',
    },
    {
        transparent: '#c0a43c70',
        originalColor: '#c0a43c',
    },
    {
        transparent: '#f2510e70',
        originalColor: '#f2510e',
    },
    {
        transparent: '#651be670',
        originalColor: '#651be6',
    },
    {
        transparent: '#79806e70',
        originalColor: '#79806e',
    },
    {
        transparent: '#61da5e70',
        originalColor: '#61da5e',
    },
    {
        transparent: '#cd2f0070',
        originalColor: '#cd2f00',
    },
    {
        transparent: '#9348af70',
        originalColor: '#9348af',
    },
    {
        transparent: '#01ac5370',
        originalColor: '#01ac53',
    },
    {
        transparent: '#c5a4fb70',
        originalColor: '#c5a4fb',
    },
    {
        transparent: '#99663570',
        originalColor: '#996635',
    },
    {
        transparent: '#b1157370',
        originalColor: '#b11573',
    },
    {
        transparent: '#4bb47370',
        originalColor: '#4bb473',
    },
    {
        transparent: '#75d89e70',
        originalColor: '#75d89e',
    },
    {
        transparent: '#2f3f9470',
        originalColor: '#2f3f94',
    },
    {
        transparent: '#2f7b9970',
        originalColor: '#2f7b99',
    },
    {
        transparent: '#da967d70',
        originalColor: '#da967d',
    },
    {
        transparent: '#34891f70',
        originalColor: '#34891f',
    },
    {
        transparent: '#b0d87b70',
        originalColor: '#b0d87b',
    },
    {
        transparent: '#ca475170',
        originalColor: '#ca4751',
    },
    {
        transparent: '#7e50a870',
        originalColor: '#7e50a8',
    },
    {
        transparent: '#c4d64770',
        originalColor: '#c4d647',
    },
    {
        transparent: '#e0eeb870',
        originalColor: '#e0eeb8',
    },
    {
        transparent: '#11dec170',
        originalColor: '#11dec1',
    },
    {
        transparent: '#28981270',
        originalColor: '#289812',
    },
    {
        transparent: '#566ca070',
        originalColor: '#566ca0',
    },
    {
        transparent: '#ffdbe170',
        originalColor: '#ffdbe1',
    },
    {
        transparent: '#2f117970',
        originalColor: '#2f1179',
    },
    {
        transparent: '#935b6d70',
        originalColor: '#935b6d',
    },
    {
        transparent: '#91698870',
        originalColor: '#916988',
    },
    {
        transparent: '#513d9870',
        originalColor: '#513d98',
    },
    {
        transparent: '#aead3a70',
        originalColor: '#aead3a',
    },
    {
        transparent: '#9e6d7170',
        originalColor: '#9e6d71',
    },
    {
        transparent: '#4b5bdc70',
        originalColor: '#4b5bdc',
    },
    {
        transparent: '#0cd36d70',
        originalColor: '#0cd36d',
    },
    {
        transparent: '#25066270',
        originalColor: '#250662',
    },
    {
        transparent: '#cb5bea70',
        originalColor: '#cb5bea',
    },
    {
        transparent: '#22891670',
        originalColor: '#228916',
    },
    {
        transparent: '#ac3e1b70',
        originalColor: '#ac3e1b',
    },
    {
        transparent: '#df514a70',
        originalColor: '#df514a',
    },
    {
        transparent: '#53939770',
        originalColor: '#539397',
    },
    {
        transparent: '#88097770',
        originalColor: '#880977',
    },
    {
        transparent: '#f697c170',
        originalColor: '#f697c1',
    },
    {
        transparent: '#ba96ce70',
        originalColor: '#ba96ce',
    },
    {
        transparent: '#679c9d70',
        originalColor: '#679c9d',
    },
    {
        transparent: '#c6c42c70',
        originalColor: '#c6c42c',
    },
    {
        transparent: '#5d2c5270',
        originalColor: '#5d2c52',
    },
    {
        transparent: '#48b41b70',
        originalColor: '#48b41b',
    },
    {
        transparent: '#e1cf3b70',
        originalColor: '#e1cf3b',
    },
    {
        transparent: '#5be4f070',
        originalColor: '#5be4f0',
    },
    {
        transparent: '#57c4d870',
        originalColor: '#57c4d8',
    },
    {
        transparent: '#a4d17a70',
        originalColor: '#a4d17a',
    },
    {
        transparent: '#225b870',
        originalColor: '#225b8',
    },
    {
        transparent: '#be608b70',
        originalColor: '#be608b',
    },
    {
        transparent: '#96b00c70',
        originalColor: '#96b00c',
    },
    {
        transparent: '#088baf70',
        originalColor: '#088baf',
    },
    {
        transparent: '#f158bf70',
        originalColor: '#f158bf',
    },
    {
        transparent: '#e145ba70',
        originalColor: '#e145ba',
    },
    {
        transparent: '#ee91e370',
        originalColor: '#ee91e3',
    },
    {
        transparent: '#05d37170',
        originalColor: '#05d371',
    },
    {
        transparent: '#5426e070',
        originalColor: '#5426e0',
    },
    {
        transparent: '#4834d070',
        originalColor: '#4834d0',
    },
    {
        transparent: '#80223470',
        originalColor: '#802234',
    },
    {
        transparent: '#6749e870',
        originalColor: '#6749e8',
    },
    {
        transparent: '#0971f070',
        originalColor: '#0971f0',
    },
    {
        transparent: '#8fb41370',
        originalColor: '#8fb413',
    },
    {
        transparent: '#b2b4f070',
        originalColor: '#b2b4f0',
    },
    {
        transparent: '#c3c89d70',
        originalColor: '#c3c89d',
    },
    {
        transparent: '#c9a94170',
        originalColor: '#c9a941',
    },
    {
        transparent: '#41d15870',
        originalColor: '#41d158',
    },
    {
        transparent: '#fb21a370',
        originalColor: '#fb21a3',
    },
    {
        transparent: '#51aed970',
        originalColor: '#51aed9',
    },
    {
        transparent: '#5bb32d70',
        originalColor: '#5bb32d',
    },
    {
        transparent: '#807fb70',
        originalColor: '#807fb',
    },
    {
        transparent: '#89d53470',
        originalColor: '#89d534',
    },
    {
        transparent: '#d3664770',
        originalColor: '#d36647',
    },
    {
        transparent: '#7fb41170',
        originalColor: '#7fb411',
    },
    {
        transparent: '#0023b870',
        originalColor: '#0023b8',
    },
    {
        transparent: '#3b8c2a70',
        originalColor: '#3b8c2a',
    },
    {
        transparent: '#986b5370',
        originalColor: '#986b53',
    },
    {
        transparent: '#f5042270',
        originalColor: '#f50422',
    },
    {
        transparent: '#983f7a70',
        originalColor: '#983f7a',
    },
    {
        transparent: '#ea24a370',
        originalColor: '#ea24a3',
    },
    {
        transparent: '#79352c70',
        originalColor: '#79352c',
    },
    {
        transparent: '#52125070',
        originalColor: '#521250',
    },
    {
        transparent: '#c79ed270',
        originalColor: '#c79ed2',
    },
    {
        transparent: '#d6dd9270',
        originalColor: '#d6dd92',
    },
    {
        transparent: '#e33e5270',
        originalColor: '#e33e52',
    },
    {
        transparent: '#b2be5770',
        originalColor: '#b2be57',
    },
    {
        transparent: '#fa06ec70',
        originalColor: '#fa06ec',
    },
    {
        transparent: '#1bb69970',
        originalColor: '#1bb699',
    },
    {
        transparent: '#6b2e5f70',
        originalColor: '#6b2e5f',
    },
    {
        transparent: '#64820f70',
        originalColor: '#64820f',
    },
    {
        transparent: '#21538e70',
        originalColor: '#21538e',
    },
    {
        transparent: '#1c27170',
        originalColor: '#1c271',
    },
    {
        transparent: '#9cb64a70',
        originalColor: '#9cb64a',
    },
    {
        transparent: '#996c4870',
        originalColor: '#996c48',
    },
    {
        transparent: '#9ab9b770',
        originalColor: '#9ab9b7',
    },
    {
        transparent: '#06e05270',
        originalColor: '#06e052',
    },
    {
        transparent: '#e3a48170',
        originalColor: '#e3a481',
    },
    {
        transparent: '#0eb62170',
        originalColor: '#0eb621',
    },
    {
        transparent: '#fc458e70',
        originalColor: '#fc458e',
    },
    {
        transparent: '#b2db1570',
        originalColor: '#b2db15',
    },
    {
        transparent: '#aa226d70',
        originalColor: '#aa226d',
    },
    {
        transparent: '#792ed870',
        originalColor: '#792ed8',
    },
    {
        transparent: '#73872a70',
        originalColor: '#73872a',
    },
    {
        transparent: '#520d3a70',
        originalColor: '#520d3a',
    },
    {
        transparent: '#cefcb870',
        originalColor: '#cefcb8',
    },
    {
        transparent: '#a5b3d970',
        originalColor: '#a5b3d9',
    },
    {
        transparent: '#7d1d8570',
        originalColor: '#7d1d85',
    },
    {
        transparent: '#c4fd5770',
        originalColor: '#c4fd57',
    },
    {
        transparent: '#f1ae1670',
        originalColor: '#f1ae16',
    },
    {
        transparent: '#8fe22a70',
        originalColor: '#8fe22a',
    },
    {
        transparent: '#ef6e3c70',
        originalColor: '#ef6e3c',
    },
    {
        transparent: '#243eeb70',
        originalColor: '#243eeb',
    },
    {
        transparent: '#1dc1870',
        originalColor: '#1dc18',
    },
    {
        transparent: '#dd93fd70',
        originalColor: '#dd93fd',
    },
    {
        transparent: '#3f847370',
        originalColor: '#3f8473',
    },
    {
        transparent: '#e7dbce70',
        originalColor: '#e7dbce',
    },
    {
        transparent: '#421f7970',
        originalColor: '#421f79',
    },
    {
        transparent: '#7a3d9370',
        originalColor: '#7a3d93',
    },
    {
        transparent: '#635f6d70',
        originalColor: '#635f6d',
    },
    {
        transparent: '#93f2d770',
        originalColor: '#93f2d7',
    },
    {
        transparent: '#9b5c2a70',
        originalColor: '#9b5c2a',
    },
    {
        transparent: '#15b9ee70',
        originalColor: '#15b9ee',
    },
    {
        transparent: '#0f599770',
        originalColor: '#0f5997',
    },
    {
        transparent: '#40918870',
        originalColor: '#409188',
    },
    {
        transparent: '#911e2070',
        originalColor: '#911e20',
    },
    {
        transparent: '#1350ce70',
        originalColor: '#1350ce',
    },
    {
        transparent: '#10e5b170',
        originalColor: '#10e5b1',
    },
    {
        transparent: '#fff4d770',
        originalColor: '#fff4d7',
    },
    {
        transparent: '#cb258270',
        originalColor: '#cb2582',
    },
    {
        transparent: '#ce00be70',
        originalColor: '#ce00be',
    },
    {
        transparent: '#32d5d670',
        originalColor: '#32d5d6',
    },
    {
        transparent: '#1723270',
        originalColor: '#17232',
    },
    {
        transparent: '#60857270',
        originalColor: '#608572',
    },
    {
        transparent: '#c79bc270',
        originalColor: '#c79bc2',
    },
    {
        transparent: '#00f87c70',
        originalColor: '#00f87c',
    },
    {
        transparent: '#77772a70',
        originalColor: '#77772a',
    },
    {
        transparent: '#6995ba70',
        originalColor: '#6995ba',
    },
    {
        transparent: '#fc6b5770',
        originalColor: '#fc6b57',
    },
    {
        transparent: '#f0781570',
        originalColor: '#f07815',
    },
    {
        transparent: '#8fd88370',
        originalColor: '#8fd883',
    },
    {
        transparent: '#060e2770',
        originalColor: '#060e27',
    },
    {
        transparent: '#96e59170',
        originalColor: '#96e591',
    },
    {
        transparent: '#21d52e70',
        originalColor: '#21d52e',
    },
    {
        transparent: '#d0004370',
        originalColor: '#d00043',
    },
    {
        transparent: '#b4716270',
        originalColor: '#b47162',
    },
    {
        transparent: '#1ec22770',
        originalColor: '#1ec227',
    },
    {
        transparent: '#4f0f6f70',
        originalColor: '#4f0f6f',
    },
    {
        transparent: '#1d1d5870',
        originalColor: '#1d1d58',
    },
    {
        transparent: '#94700270',
        originalColor: '#947002',
    },
    {
        transparent: '#bde05270',
        originalColor: '#bde052',
    },
    {
        transparent: '#e08c5670',
        originalColor: '#e08c56',
    },
    {
        transparent: '#28fcfd70',
        originalColor: '#28fcfd',
    },
    {
        transparent: '#bb09b70',
        originalColor: '#bb09b',
    },
    {
        transparent: '#36486a70',
        originalColor: '#36486a',
    },
    {
        transparent: '#d02e2970',
        originalColor: '#d02e29',
    },
    {
        transparent: '#1ae6db70',
        originalColor: '#1ae6db',
    },
    {
        transparent: '#3e464c70',
        originalColor: '#3e464c',
    },
    {
        transparent: '#a84a8f70',
        originalColor: '#a84a8f',
    },
    {
        transparent: '#911e7e70',
        originalColor: '#911e7e',
    },
    {
        transparent: '#3f16d970',
        originalColor: '#3f16d9',
    },
    {
        transparent: '#0f525f70',
        originalColor: '#0f525f',
    },
    {
        transparent: '#ac7c0a70',
        originalColor: '#ac7c0a',
    },
    {
        transparent: '#b4c08670',
        originalColor: '#b4c086',
    },
    {
        transparent: '#c9d73070',
        originalColor: '#c9d730',
    },
    {
        transparent: '#30cc4970',
        originalColor: '#30cc49',
    },
    {
        transparent: '#3d675170',
        originalColor: '#3d6751',
    },
    {
        transparent: '#fb4c0370',
        originalColor: '#fb4c03',
    },
    {
        transparent: '#640fc170',
        originalColor: '#640fc1',
    },
    {
        transparent: '#62c03e70',
        originalColor: '#62c03e',
    },
    {
        transparent: '#d3493a70',
        originalColor: '#d3493a',
    },
    {
        transparent: '#88aa0b70',
        originalColor: '#88aa0b',
    },
    {
        transparent: '#406df970',
        originalColor: '#406df9',
    },
    {
        transparent: '#615af070',
        originalColor: '#615af0',
    },
    {
        transparent: '#4be4770',
        originalColor: '#4be47',
    },
    {
        transparent: '#2a343470',
        originalColor: '#2a3434',
    },
    {
        transparent: '#4a543f70',
        originalColor: '#4a543f',
    },
    {
        transparent: '#79bca070',
        originalColor: '#79bca0',
    },
    {
        transparent: '#a8b8d470',
        originalColor: '#a8b8d4',
    },
    {
        transparent: '#00efd470',
        originalColor: '#00efd4',
    },
    {
        transparent: '#7ad23670',
        originalColor: '#7ad236',
    },
    {
        transparent: '#7260d870',
        originalColor: '#7260d8',
    },
    {
        transparent: '#1deaa770',
        originalColor: '#1deaa7',
    },
    {
        transparent: '#06f43a70',
        originalColor: '#06f43a',
    },
    {
        transparent: '#823c5970',
        originalColor: '#823c59',
    },
    {
        transparent: '#e3d94c70',
        originalColor: '#e3d94c',
    },
    {
        transparent: '#dc1c0670',
        originalColor: '#dc1c06',
    },
    {
        transparent: '#f53b2a70',
        originalColor: '#f53b2a',
    },
    {
        transparent: '#b4623870',
        originalColor: '#b46238',
    },
    {
        transparent: '#2dfff670',
        originalColor: '#2dfff6',
    },
    {
        transparent: '#a82b8970',
        originalColor: '#a82b89',
    },
    {
        transparent: '#1a801170',
        originalColor: '#1a8011',
    },
    {
        transparent: '#436a9f70',
        originalColor: '#436a9f',
    },
    {
        transparent: '#1a806a70',
        originalColor: '#1a806a',
    },
    {
        transparent: '#4cf09d70',
        originalColor: '#4cf09d',
    },
    {
        transparent: '#c188a270',
        originalColor: '#c188a2',
    },
    {
        transparent: '#67eb4b70',
        originalColor: '#67eb4b',
    },
    {
        transparent: '#b308d370',
        originalColor: '#b308d3',
    },
    {
        transparent: '#fc7e4170',
        originalColor: '#fc7e41',
    },
    {
        transparent: '#af310170',
        originalColor: '#af3101',
    },
    {
        transparent: '#ff06570',
        originalColor: '#ff065',
    },
    {
        transparent: '#71b1f470',
        originalColor: '#71b1f4',
    },
    {
        transparent: '#a2f8a570',
        originalColor: '#a2f8a5',
    },
    {
        transparent: '#e23dd070',
        originalColor: '#e23dd0',
    },
    {
        transparent: '#d3486d70',
        originalColor: '#d3486d',
    },
    {
        transparent: '#00f7f970',
        originalColor: '#00f7f9',
    },
    {
        transparent: '#47489370',
        originalColor: '#474893',
    },
    {
        transparent: '#3cec3570',
        originalColor: '#3cec35',
    },
    {
        transparent: '#1c65cb70',
        originalColor: '#1c65cb',
    },
    {
        transparent: '#5d1d0c70',
        originalColor: '#5d1d0c',
    },
    {
        transparent: '#2d7d2a70',
        originalColor: '#2d7d2a',
    },
    {
        transparent: '#ff342070',
        originalColor: '#ff3420',
    },
    {
        transparent: '#5cdd8770',
        originalColor: '#5cdd87',
    },
    {
        transparent: '#a259a470',
        originalColor: '#a259a4',
    },
    {
        transparent: '#e4ac4470',
        originalColor: '#e4ac44',
    },
    {
        transparent: '#1bede670',
        originalColor: '#1bede6',
    },
    {
        transparent: '#8798a470',
        originalColor: '#8798a4',
    },
    {
        transparent: '#d7790f70',
        originalColor: '#d7790f',
    },
    {
        transparent: '#b2c24f70',
        originalColor: '#b2c24f',
    },
    {
        transparent: '#de73c270',
        originalColor: '#de73c2',
    },
    {
        transparent: '#d70a9c70',
        originalColor: '#d70a9c',
    },
    {
        transparent: '#25b6770',
        originalColor: '#25b67',
    },
    {
        transparent: '#88e9b870',
        originalColor: '#88e9b8',
    },
    {
        transparent: '#c2b0e270',
        originalColor: '#c2b0e2',
    },
    {
        transparent: '#86e98f70',
        originalColor: '#86e98f',
    },
    {
        transparent: '#ae90e270',
        originalColor: '#ae90e2',
    },
    {
        transparent: '#1a806b70',
        originalColor: '#1a806b',
    },
    {
        transparent: '#436a9e70',
        originalColor: '#436a9e',
    },
    {
        transparent: '#0ec0ff70',
        originalColor: '#0ec0ff',
    },
    {
        transparent: '#f812b370',
        originalColor: '#f812b3',
    },
    {
        transparent: '#b17fc970',
        originalColor: '#b17fc9',
    },
    {
        transparent: '#8d6c2f70',
        originalColor: '#8d6c2f',
    },
    {
        transparent: '#d3277a70',
        originalColor: '#d3277a',
    },
    {
        transparent: '#2ca1ae70',
        originalColor: '#2ca1ae',
    },
    {
        transparent: '#9685eb70',
        originalColor: '#9685eb',
    },
    {
        transparent: '#8a96c670',
        originalColor: '#8a96c6',
    },
    {
        transparent: '#dba2e670',
        originalColor: '#dba2e6',
    },
    {
        transparent: '#76fc1b70',
        originalColor: '#76fc1b',
    },
    {
        transparent: '#608fa470',
        originalColor: '#608fa4',
    },
    {
        transparent: '#20f6ba70',
        originalColor: '#20f6ba',
    },
    {
        transparent: '#07d7f670',
        originalColor: '#07d7f6',
    },
    {
        transparent: '#dce77a70',
        originalColor: '#dce77a',
    },
    {
        transparent: '#77ecca70',
        originalColor: '#77ecca',
    },
];

export default colorsPalette;
