import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import moment from 'moment';
import clsx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { TableColumnType } from 'react-bs-datatable';
import {
    Col,
    Form,
    Row,
    Tab,
    Tabs,
    Button,
    InputGroup,
    Badge,
    Dropdown,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';
import Datetime from 'react-datetime';
import * as yup from 'yup';
import {
    faInfo,
    faPrint,
    faSearch,
    faEdit,
    faCheckDouble,
    faPaperclip,
    faPersonCircleQuestion,
    faTruckArrowRight,
    faTruckRampBox,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ROLES as RolesMap } from 'constantes/permission-maps';
import {
    isEmptyObject,
    buildUrlQueryParams,
    useQueryMeMo,
    Capitalize1stLetter,
    utf8_to_b64,
    b64_to_utf8,
    hideFullCell,
    isTheOwnerOfCommande,
    isSameFournisseurEmployee,
    canControlCommande,
} from 'utils/helpers';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import PermissionsGate from 'hoc/permissionsGate';
import CommandeFormComponent from 'pages/Commande/components/CommandeForm';
import CommandeAcheteurEditForm from 'pages/Commande//components/CommandeAcheteurEditForm';
import CommandeEditFormComponent from 'pages/Commande/components/CommandeEditForm';
import FactureCommandesFormComponent from 'pages/Commande/components/FactureForm';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import EtatCommandesDashboard from 'components/shared/etatCommandesDashboard';
import CopyTextToClipboardComponent from 'components/UI/copyTextToClipboardComponent';
import CustomColorsBadge from 'components/UI/customColorsBadge';
import EtatCommandeBadge from 'components/UI/etatCommandeBadge';
import CustomModal from 'components/UI/customModal';
import Datatable from 'components/shared/datatable';
import Paginator from 'components/UI/paginator';
import PrintCommande from 'pages/Commande/components/PrintCommande';
import SmallCommande from 'pages/Commande/components/SmallCommande';
import MultiSmallCommandes from 'pages/Commande/components/MultiSmallCommandes';
import MultiCommandesBE from 'pages/Commande/components/MultiCommandesBE';
import FactureComponent from 'pages/Commande/components/Facture';
import CommandeInterface from 'types/interfaces/commande.interface';
import ClientInterface from 'types/interfaces/client.interface';
import UserInterface from 'types/interfaces/user.interface';
import EtatCommandeInterface from 'types/interfaces/etatCommande.interface';
import EtatTitreInterface from 'types/interfaces/etatTitre.interface';
import VilleInterface from 'types/interfaces/ville.interface';
import GroupVilleInterface from 'types/interfaces/groupVilleClone.interface';
import { getPartnersFournisseurs } from 'services/fournisseur.service';
import FournisseurInterface from 'types/interfaces/fournisseur.interface';
import { getAllGroupVilleClones } from 'services/groupVilleClone.service';
import { getClientsByIdFournisseur } from 'services/client.service';
import { getEtatTitres } from 'services/etatTitre.service';
import { getVilles } from 'services/ville.service';
import {
    getCommandesFiltered,
    getCommandesneedAttentionFiltered,
    getCommandesStatesFiltered,
    getCommandeById,
} from 'services/commande.service';
import { getLivreursByAgence, getLivreursByFournisseur } from 'services/user.service';
import { getEtatCommandes } from 'services/etatCommande.service';
import TitleElement from 'components/UI/titleElement';
import Collapser from 'components/UI/collapser';
import AffectationLivreur from 'pages/Commande/components/AffectationLivreur';
import DemandeRetourStockFormRangeAction from 'pages/Commande/components/DemandeRetourStockRangeAction';
import TransfertCommandeInterface from 'types/interfaces/transfertCommande.interface';

export const schema = yup.object({
    ids: yup.array().of(yup.number().required()).min(1).required(),
    idLivreur: yup.number().required(),
    remarque: yup.string(),
    idEtatTitre: yup.number(),
});

type Props = {
    pageType?: string;
};

const TOTAL_DATA_PER_PAGE = 100;

const CommandeIndexPage: React.FC<Props> = ({ pageType }: Props) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    const [groupVilles, setGroupVilles] = useState<GroupVilleInterface[]>([]);
    const [commandes, setCommandes] = useState<{ items: CommandeInterface[]; count: number }>({
        items: [],
        count: 0,
    });
    const [etatCommandesStates, setEtatCommandesStates] = useState<any[]>([]);
    const [currentCommande, setCurrentCommande] = useState<CommandeInterface | null>(null);
    const [etatCommandes, setEtatCommandes] = useState<EtatCommandeInterface[]>([]);
    const [villes, setVilles] = useState<VilleInterface[]>([]);
    const [livreurs, setLivreurs] = useState<UserInterface[]>([]);
    const [clients, setClients] = useState<ClientInterface[]>([]);
    const [etatTitres, setEtatTitres] = useState<EtatTitreInterface[]>([]);
    const [partners, setPartners] = useState<FournisseurInterface[]>([]);
    const [checkedCommandes, setCheckedCommandes] = useState<number[]>([]);
    const [checkedPrintingCMD, setCheckedPrintingCMD] = useState<number[]>([]);
    const [checkDemandeRetourStock, setCheckDemandeRetourStock] = useState<number[]>([]);

    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [selectedVille, setSelectedVille] = useState<string>('');
    const [selectedEtatTitre, setSelectedEtatTitre] = useState<string>('');
    const [selectedEtat, setSelectedEtat] = useState<string>('');
    const [selectedFactureEtat, setSelectedFactureEtat] = useState<string>('');
    const [selectedPaiementEtat, setSelectedPaiementEtat] = useState<string>('');
    const [selectedClient, setSelectedClient] = useState<string | null>(null);
    const [selectedPartner, setSelectedPartner] = useState<string | null>(null);
    const [selectedLivreur, setSelectedLivreur] = useState<string | null>(null);
    const [date_debut, setDate_debut] = useState<any>(null);
    const [date_fin, setDate_fin] = useState<any>(null);
    const [activePage, setActivePage] = useState<number>(1);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalDemandeRetourStock, setShowModalDemandeRetourStock] = useState<boolean>(false);
    const [showFactureFormModal, setShowFactureFormModal] = useState<boolean>(false);
    const [showPrintableOneCommandeFacture, setShowPrintableOneCommandeFacture] = useState<boolean>(false);
    const [showSmallCommande, setShowSmallCommande] = useState<boolean>(false);
    const [showMultipleCommande, setShowMultipleCommande] = useState<boolean>(false);
    const [showMultipleCommandeBE, setShowMultipleCommandeBE] = useState<boolean>(false);
    const [showFullFacture, setShowFullFacture] = useState<boolean>(false);
    const [showEditCommandeForm, setShowEditCommandeForm] = useState<boolean>(false);
    const [showEditCommandeAcheteurForm, setShowEditCommandeAcheteurForm] = useState<boolean>(false);
    const [idFacture, setIdFacture] = useState<null | number>(null);

    const query = useQueryMeMo();
    const history = useNavigate();

    const handleShowFullFactureOpen = (idFacture: number) => {
        setIdFacture(idFacture);
        setShowFullFacture(true);
    };

    const handleShowFullFactureClose = () => {
        setShowFullFacture(false);
    };

    const handleModalDemandeRetourStockClose = () => {
        setCheckDemandeRetourStock([]);
        setShowModalDemandeRetourStock(false);
    };

    const handleModalDemandeRetourStockOpen = () => {
        setShowModalDemandeRetourStock(true);
    };
    const handleModalClose = () => {
        setCurrentCommande(null);
        setShowModal(false);
    };

    const handleModalOpen = (data: CommandeInterface, trueData = false) => {
        if (trueData) {
            setCurrentCommande(data);
            setShowModal(true);
        } else {
            const cmdData = commandes.items.find((d: CommandeInterface) => d.idCommande === data.idCommande);
            if (cmdData) {
                setCurrentCommande(cmdData);
                setShowModal(true);
            }
        }
    };

    const handleEditCommandeFormClose = () => {
        setCurrentCommande(null);
        setShowEditCommandeForm(false);
    };

    const handleEditCommandeFormOpen = (data: CommandeInterface) => {
        const cmdData = commandes.items.find((d: CommandeInterface) => d.idCommande === data.idCommande);
        if (cmdData) {
            setCurrentCommande(cmdData);
            setShowEditCommandeForm(true);
        }
    };

    const handleEditCommandeAcheteurFormClose = () => {
        setCurrentCommande(null);
        setShowEditCommandeAcheteurForm(false);
    };

    const handleEditCommandeAcheteurFormOpen = (data: CommandeInterface) => {
        const cmdData = commandes.items.find((d: CommandeInterface) => d.idCommande === data.idCommande);
        if (cmdData) {
            setCurrentCommande(cmdData);
            setShowEditCommandeAcheteurForm(true);
        }
    };

    const handlePrintableOneCommandeFactureModalClose = () => {
        setCurrentCommande(null);
        setShowPrintableOneCommandeFacture(false);
    };

    const handlePrintableOneCommandeFactureModalOpen = (data: CommandeInterface) => {
        const cmdData = commandes.items.find((d: CommandeInterface) => d.idCommande === data.idCommande);
        if (cmdData) {
            setCurrentCommande(cmdData);
            setShowPrintableOneCommandeFacture(true);
        }
    };
    const handleMultipleCommandeModalClose = () => {
        setShowMultipleCommande(false);
    };

    const handleMultipleCommandeModalOpen = () => {
        setShowMultipleCommande(true);
    };
    const handleMultipleCommandeBEModalClose = () => {
        setShowMultipleCommandeBE(false);
    };
    const handleMultipleCommandeBEModalOpen = () => {
        setShowMultipleCommandeBE(true);
    };

    const handleSmallCommandeModalClose = () => {
        setCurrentCommande(null);
        setShowSmallCommande(false);
    };

    const handleSmallCommandeModalOpen = (data: CommandeInterface) => {
        const cmdData = commandes.items.find((d: CommandeInterface) => d.idCommande === data.idCommande);
        if (cmdData) {
            setCurrentCommande(cmdData);
            setShowSmallCommande(true);
        }
    };

    const handleFactureFormModalClose = () => {
        setShowFactureFormModal(false);
    };

    const handleFactureFormModalOpen = () => {
        if (checkedCommandes.length > 0) {
            setShowFactureFormModal(true);
        }
    };

    const onChangeDateDebut = (e: any) => {
        setDate_debut(e);
        handlePageNFilterChange(
            1,
            JSON.stringify({
                client: selectedClient,
                livreur: selectedLivreur,
                status: selectedEtat,
                facture: selectedFactureEtat,
                paiement: selectedPaiementEtat,
                dDebut: e.format('YYYY-MM-DD HH:mm:ss'),
                dFin: date_fin,
                phoneNumber,
                idVille: selectedVille,
                idEtatTitre: selectedEtatTitre,
                partner: selectedPartner,
            }),
        );
    };

    const onChangeDateFin = (e: any) => {
        setDate_fin(e);
        handlePageNFilterChange(
            1,
            JSON.stringify({
                client: selectedClient,
                livreur: selectedLivreur,
                status: selectedEtat,
                facture: selectedFactureEtat,
                paiement: selectedPaiementEtat,
                dDebut: date_debut,
                dFin: e.format('YYYY-MM-DD HH:mm:ss'),
                phoneNumber,
                idVille: selectedVille,
                idEtatTitre: selectedEtatTitre,
                partner: selectedPartner,
            }),
        );
    };

    const resetCheckedInputs = () => {
        setCheckedCommandes([]);
        setCheckedPrintingCMD([]);
        setCheckDemandeRetourStock([]);
    };

    const loadCommandes = (
        total: number,
        page: number,
        status: string,
        factureStatus: string,
        paiementStatus: string,
        phoneNumber: string,
        idVille: string,
        idEtatTitre?: string,
        idClient?: string,
        idLivreur?: string,
        dateDebut?: Date | string,
        dateFin?: Date | string,
        idPartner?: string,
    ) => {
        if (pageType !== 'AFacturer') {
            getCommandesStatesFiltered(
                pageType,
                total,
                page,
                status,
                factureStatus,
                paiementStatus,
                phoneNumber,
                idVille,
                idEtatTitre,
                idClient,
                idLivreur,
                dateDebut,
                dateFin,
                pageType === 'Transfert',
                idPartner,
            )
                .then((response: AxiosResponse<any>) => {
                    const { data } = response;
                    const index = data.states.findIndex((d: any) => d.obj.idEtatCommande.toString() === '1');
                    if (index > -1) data.states[index].count = data.count;
                    setEtatCommandesStates(data.states);
                })
                .catch();
        }

        if (pageType === 'Original' || pageType === 'AFacturer' || pageType === 'Transfert') {
            getCommandesFiltered(
                total,
                page,
                status,
                factureStatus,
                paiementStatus,
                phoneNumber,
                idVille,
                idEtatTitre,
                idClient,
                idLivreur,
                dateDebut,
                dateFin,
                pageType === 'AFacturer',
                pageType === 'Transfert',
                idPartner,
            )
                .then((response: AxiosResponse<CommandeInterface[] | any>) => {
                    const { data } = response;
                    setCommandes({
                        items: data.items,
                        count: data.count,
                    });
                })
                .catch();
        } else if (pageType == 'NeedAttention') {
            getCommandesneedAttentionFiltered(
                total,
                page,
                status,
                factureStatus,
                paiementStatus,
                phoneNumber,
                idVille,
                idEtatTitre,
                idClient,
                idLivreur,
                dateDebut,
                dateFin,
            )
                .then((response: AxiosResponse<CommandeInterface[] | any>) => {
                    const { data } = response;
                    setCommandes({
                        items: data.items,
                        count: data.count,
                    });
                })
                .catch();
        }
    };

    const handlePageNFilterChange = (activePage: number, searchedData?: string) => {
        setCheckedCommandes([]);
        setCheckedPrintingCMD([]);
        setCheckDemandeRetourStock([]);
        const urlObject: any = {};
        if (activePage > 1) urlObject['page'] = activePage;
        if (searchedData) {
            const parsedObject = JSON.parse(searchedData);
            Object.keys(parsedObject).forEach((key) => {
                if (parsedObject[key as any]) {
                    if ((key === 'client' || key === 'partner') && parsedObject[key as any])
                        urlObject[key] = utf8_to_b64(parsedObject[key as any]);
                    else urlObject[key] = parsedObject[key as any];
                }
            });
        }
        history(isEmptyObject(urlObject) ? window.location.pathname : `?${buildUrlQueryParams(urlObject)}`);
    };

    const onCheckAllCommandes = useCallback(() => {
        setCheckedCommandes((oldState) => {
            if (oldState.length === commandes.items.length) {
                return [];
            }

            if (selectedEtat === '4' && selectedClient) {
                return commandes.items
                    .filter(
                        (el: CommandeInterface) =>
                            el.idEtatCommande === 4 &&
                            !el.idFacture &&
                            isTheOwnerOfCommande(contextUser?.userData?.idFournisseur!, el),
                    )
                    .map((el: CommandeInterface) => el.idCommande);
            }

            return commandes.items
                .filter(
                    (el: CommandeInterface) =>
                        el.idEtatCommande === 1 &&
                        !el.idLivreur &&
                        el.colis.dateRec &&
                        canControlCommande(contextUser?.userData?.idFournisseur!, el),
                )
                .map((el: CommandeInterface) => el.idCommande);
        });
    }, [commandes.items, selectedEtat, selectedClient, contextUser]);

    const onCheckCommandesrow = useCallback(
        (row: CommandeInterface) => {
            setCheckedCommandes((oldState) => {
                const idx = oldState.findIndex((el: number) => el === row.idCommande);

                if (idx === -1) {
                    if (
                        (selectedEtat === '4' &&
                            (row.idFacture || !isTheOwnerOfCommande(contextUser?.userData?.idFournisseur!, row))) ||
                        (selectedEtat !== '4' &&
                            (row.idEtatCommande !== 1 ||
                                !canControlCommande(contextUser?.userData?.idFournisseur!, row) ||
                                row.idLivreur ||
                                (!row.idLivreur && !(row as any)['colis.dateRec'])))
                    )
                        return oldState;
                    return oldState.concat(row.idCommande);
                }

                const newState = [...oldState];
                newState.splice(idx, 1);

                return newState;
            });
        },
        [commandes.items, selectedEtat, contextUser],
    );

    const onCheckPrintCommandesRow = useCallback(
        (row: CommandeInterface) => {
            setCheckedPrintingCMD((oldState) => {
                const idx = oldState.findIndex((el: number) => el === row.idCommande);

                if (idx === -1) {
                    if ([4, 5].includes(row.idEtatCommande!)) return oldState;
                    return oldState.concat(row.idCommande);
                }

                const newState = [...oldState];
                newState.splice(idx, 1);

                return newState;
            });
        },
        [commandes.items],
    );

    const onCheckPrintAllCommandesRow = useCallback(() => {
        setCheckedPrintingCMD((oldState: number[]) => {
            if (
                oldState.length ===
                commandes.items.filter((el: CommandeInterface) => ![4, 5].includes(el.idEtatCommande!)).length
            ) {
                return [];
            }

            return commandes.items
                .filter((el: CommandeInterface) => ![4, 5].includes(el.idEtatCommande!))
                .map((el: CommandeInterface) => el.idCommande);
        });
    }, [commandes.items]);

    const onCheckDRSCommandesRow = useCallback(
        (row: CommandeInterface) => {
            setCheckDemandeRetourStock((oldState) => {
                const idx = oldState.findIndex((el: number) => el === row.idCommande);

                if (idx === -1) {
                    if (row.idEtatCommande! >= 4 || row.demandeRetourStocks?.length! > 0) return oldState;
                    return oldState.concat(row.idCommande);
                }

                const newState = [...oldState];
                newState.splice(idx, 1);

                return newState;
            });
        },
        [commandes.items],
    );

    const onCheckDRSAllCommandesRow = useCallback(() => {
        setCheckDemandeRetourStock((oldState: number[]) => {
            if (
                oldState.length ===
                commandes.items.filter(
                    (el: CommandeInterface) => el.idEtatCommande! < 4 && el.demandeRetourStocks?.length! === 0,
                ).length
            ) {
                return [];
            }

            return commandes.items
                .filter((el: CommandeInterface) => el.idEtatCommande! < 4 && el.demandeRetourStocks?.length! === 0)
                .map((el: CommandeInterface) => el.idCommande);
        });
    }, [commandes.items]);

    useEffect(() => {
        let docTitle = ' | Liste des commandes';
        if (pageType === 'NeedAttention') docTitle = " Besoin d'attention";
        else if (pageType === 'AFacturer') docTitle = ' | Créer facture client';
        else if (pageType === 'Transfert') docTitle = ' | Commandes (Partenariat)';
        document.title = `Commandes${docTitle} - DS`;
        setCheckedCommandes([]);
        setCheckedPrintingCMD([]);
        setCheckDemandeRetourStock([]);
    }, [pageType]);

    useEffect(() => {
        getVilles()
            .then((response: AxiosResponse<VilleInterface[] | any>) => {
                const { data } = response;
                setVilles(data);
            })
            .catch();

        getEtatTitres()
            .then((response: AxiosResponse<EtatTitreInterface[] | any>) => {
                const { data } = response;
                setEtatTitres(data);
            })
            .catch();

        getEtatCommandes()
            .then((response: AxiosResponse<EtatCommandeInterface[] | any>) => {
                const { data } = response;
                setEtatCommandes(data);
            })
            .catch();
    }, []);

    useEffect(() => {
        if (contextUser && contextUser.userData.type === 'Fournisseur') {
            getClientsByIdFournisseur(contextUser.userData.idFournisseur!)
                .then((response: AxiosResponse<ClientInterface[] | any>) => {
                    const { data } = response;
                    setClients(data);
                })
                .catch();
            if (
                contextUser?.userData?.userroles?.some(
                    (d) =>
                        d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                        d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE,
                )
            ) {
                getLivreursByFournisseur(contextUser?.userData?.idFournisseur!)
                    .then((response: AxiosResponse<UserInterface[] | any>) => {
                        const { data } = response;
                        setLivreurs(data);
                    })
                    .catch();
            } else {
                getLivreursByAgence(contextUser?.userData?.idAgence!)
                    .then((response: AxiosResponse<UserInterface[] | any>) => {
                        const { data } = response;
                        setLivreurs(data);
                    })
                    .catch();
            }

            getAllGroupVilleClones()
                .then((response: AxiosResponse<GroupVilleInterface[]>) => {
                    const { data } = response;
                    setGroupVilles(data);
                })
                .catch();

            if (pageType === 'Transfert') {
                getPartnersFournisseurs()
                    .then((response: AxiosResponse<FournisseurInterface[] | any>) => {
                        const { data } = response;
                        setPartners(
                            data.filter(
                                (item: FournisseurInterface) =>
                                    item.idFournisseur !== contextUser.userData?.idFournisseur!,
                            ),
                        );
                    })
                    .catch()
                    .finally();
            }
        }
    }, [contextUser, pageType]);

    useEffect(() => {
        const isStrictLivreur =
            contextUser?.userData?.userroles?.some((d) => d.role.nomRole === RolesMap.FOURNISSEUR_LIVREUR) &&
            !contextUser?.userData?.userroles?.some((d) => d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN);
        const yesterdayStart = moment().subtract(1, 'days').startOf('day');

        const page = query.get('page');
        const client = query.get('client');
        const partner = query.get('partner');
        const status = query.get('status');
        const factureStatus = query.get('facture');
        const paiementStatus = query.get('paiement');
        const dDebut = query.get('dDebut');
        const dFin = query.get('dFin');
        const currentPage = page ? +page : 1;
        const currentClient =
            contextUser && contextUser.userData.type === 'Client' ? '' : client ? b64_to_utf8(client) : '';
        const currentPartner =
            contextUser && contextUser.userData.type === 'Client' ? '' : partner ? b64_to_utf8(partner) : '';
        const _phoneNumber = query.get('phoneNumber') ?? '';
        const _idVille = query.get('idVille') ?? '';
        const _livreur = query.get('livreur') ?? '';
        const _idEtatTitre = query.get('idEtatTitre') ?? '';
        const currentStatus = pageType === 'AFacturer' ? '4' : status ?? '';
        const currentStatusFacture = pageType === 'AFacturer' ? '0' : factureStatus ?? '';
        const currentStatusPaiement = pageType === 'AFacturer' ? '' : paiementStatus ?? '';
        const _dateDebut = (
            dDebut ? moment(dDebut) : isStrictLivreur ? yesterdayStart : moment().subtract(3, 'days')
        ).format('YYYY-MM-DD HH:mm:ss');
        const _dateFin = (dFin ? moment(dFin) : moment().endOf('day')).format('YYYY-MM-DD HH:mm:ss');
        setActivePage(currentPage);
        setSelectedClient(currentClient!);
        setSelectedPartner(currentPartner!);
        setSelectedEtat(currentStatus);
        setSelectedFactureEtat(currentStatusFacture);
        setSelectedPaiementEtat(currentStatusPaiement);
        setDate_debut(_dateDebut!);
        setDate_fin(_dateFin!);
        setPhoneNumber(_phoneNumber);
        setSelectedVille(_idVille);
        setSelectedLivreur(_livreur);
        const loadData = async () => {
            loadCommandes(
                TOTAL_DATA_PER_PAGE,
                currentPage,
                currentStatus,
                currentStatusFacture,
                currentStatusPaiement,
                _phoneNumber,
                _idVille,
                _idEtatTitre,
                currentClient,
                _livreur,
                _dateDebut,
                _dateFin,
                currentPartner,
            );
        };
        loadData();
    }, [
        contextUser,
        query,
        date_debut,
        date_fin,
        selectedClient,
        selectedLivreur,
        selectedEtat,
        selectedFactureEtat,
        selectedPaiementEtat,
        selectedVille,
        selectedPartner,
        pageType,
    ]);

    useEffect(() => {
        const open = query.get('open');
        if (open) {
            const cmdId = +b64_to_utf8(open);
            eagerLoadingCommande(cmdId, true);
        }
    }, [query]);

    const eagerLoadingCommande = (cmdId: number, openModal = false, updateInList = false) => {
        getCommandeById(cmdId)
            .then((response: AxiosResponse<CommandeInterface | any>) => {
                const { data } = response;
                if (updateInList && commandes.items.length > 0) {
                    const isStrictLivreur =
                        contextUser?.userData?.userroles?.some(
                            (d) => d.role.nomRole === RolesMap.FOURNISSEUR_LIVREUR,
                        ) &&
                        !contextUser?.userData?.userroles?.some((d) => d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN);
                    if (isStrictLivreur && data.idLivreur !== contextUser?.userData?.idUser!) {
                        const cmdData = commandes.items.filter(
                            (d: CommandeInterface) => d.idCommande != data.idCommande!,
                        );
                        setCommandes({ items: cmdData, count: commandes.count - 1 });
                    } else {
                        const cmdData = [...commandes.items];
                        const cmdIndex = cmdData.findIndex((d: CommandeInterface) => d.idCommande == data.idCommande!);
                        cmdData[cmdIndex] = data;
                        setCommandes({ items: cmdData, count: commandes.count });
                        if (openModal) handleModalOpen(data, true);
                    }
                } else {
                    if (openModal) handleModalOpen(data, true);
                }
            })
            .catch();
    };

    const tableHeaders: TableColumnType<any>[] = useMemo(
        () => [
            ...(contextUser?.userData.type === 'Fournisseur' &&
            contextUser?.userData?.userroles?.some(
                (d) =>
                    d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                    (selectedEtat !== '4' && d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE) ||
                    (selectedEtat === '4' && d.role.nomRole === RolesMap.FOURNISSEUR_FACTURE),
            )
                ? [
                      {
                          prop: 'checkbox',
                          cellProps: {
                              className: 'foceVerticalMiddle',
                          },
                          headerCell: () => (
                              <input
                                  type="checkbox"
                                  checked={checkedCommandes.length === commandes.items.length}
                                  onChange={onCheckAllCommandes}
                              />
                          ),
                          cell: (row: CommandeInterface) => (
                              <>
                                  {((selectedClient &&
                                      selectedEtat === '4' &&
                                      !row.idFacture &&
                                      isTheOwnerOfCommande(contextUser?.userData?.idFournisseur!, row)) ||
                                      (row.idEtatCommande === 1 &&
                                          !row.idLivreur &&
                                          (row as any)['colis.dateRec'] &&
                                          canControlCommande(contextUser?.userData?.idFournisseur!, row))) && (
                                      <input
                                          type="checkbox"
                                          checked={checkedCommandes.includes(row.idCommande)}
                                          //   readOnly
                                          onChange={() => onCheckCommandesrow(row)}
                                      />
                                  )}
                              </>
                          ),
                      },
                  ]
                : []),
            ...(pageType === 'Transfert'
                ? [
                      {
                          prop: '--transfert-info',
                          title: 'Activité',
                          cellProps: {
                              className: 'foceVerticalMiddle',
                          },
                          thProps: {
                              style: { minWidth: '150px' },
                          },
                          cell: (row: CommandeInterface) => {
                              const source = row.transfertCommandes?.find(
                                  (item: TransfertCommandeInterface) =>
                                      item.idFournisseurSource === contextUser?.userData?.idFournisseur,
                              );
                              const destinataire = row.transfertCommandes?.find(
                                  (item: TransfertCommandeInterface) =>
                                      item.idFournisseurDestinataire === contextUser?.userData?.idFournisseur,
                              );
                              return (
                                  <div className="d-flex justify-content-around align-items-center gap-1">
                                      {source && (
                                          <FontAwesomeIcon
                                              icon={faTruckArrowRight}
                                              color="#127093"
                                              title={`Commande transférée à ${source.fournisseurSource
                                                  ?.raisonSocial!} (${moment(source?.dateCreation).format(
                                                  'DD/MM/YYYY HH:mm',
                                              )})`}
                                          />
                                      )}
                                      {destinataire && (
                                          <FontAwesomeIcon
                                              icon={faTruckRampBox}
                                              color="#bf8450"
                                              title={`Commande provient de ${destinataire.fournisseurSource
                                                  ?.raisonSocial!} (${moment(destinataire?.dateCreation).format(
                                                  'DD/MM/YYYY HH:mm',
                                              )})`}
                                          />
                                      )}
                                  </div>
                              );
                          },
                      },
                  ]
                : []),
            {
                prop: 'Ref/Code',
                title: 'Ref/Code',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                thProps: {
                    style: { minWidth: '150px' },
                },
                cell: (row: CommandeInterface) => (
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={(props: any) => (
                            <Tooltip className="refNcode_tooltip" {...props}>
                                Code: {row.code}
                                <br />
                                Ref: {row.ref ? row.ref : '-'}
                            </Tooltip>
                        )}
                    >
                        <Button type="button" variant="secondary-outline">
                            <>
                                <CopyTextToClipboardComponent text={row.code}>
                                    <b style={{ fontSize: '0.7rem' }}>{row.code.replace('CMD', '')}</b>
                                </CopyTextToClipboardComponent>
                                {row.ref && (
                                    <>
                                        <br />
                                        <CopyTextToClipboardComponent text={row.ref}>
                                            <b style={{ fontSize: '0.6rem' }}>{row.ref}</b>
                                        </CopyTextToClipboardComponent>
                                    </>
                                )}
                            </>
                        </Button>
                    </OverlayTrigger>
                ),
            },
            ...(contextUser?.userData.type === 'Fournisseur'
                ? [
                      {
                          prop: 'client.raisonSocial',
                          title: 'Client',
                          cellProps: {
                              className: 'foceVerticalMiddle',
                          },
                          isFilterable: true,
                          isSortable: true,
                          cell: (row: CommandeInterface) => (
                              <span className="my-xsm-font">
                                  {isTheOwnerOfCommande(contextUser?.userData?.idFournisseur!, row)
                                      ? (row as any)['client.raisonSocial']
                                      : '****'}
                              </span>
                          ),
                      },
                  ]
                : []),
            {
                prop: 'acheteur.fullName',
                title: 'Acheteur',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: CommandeInterface) => (
                    <span className="my-xsm-font">{(row as any)['acheteur.fullName']}</span>
                ),
            },
            {
                prop: 'acheteur.gsm',
                title: 'GSM',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: CommandeInterface) => <span className="my-xsm-font">{(row as any)['acheteur.gsm']}</span>,
            },
            {
                prop: 'prixTotal',
                title: 'Prix',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: CommandeInterface) => <span className="my-xsm-font">{row.prixTotal}</span>,
            },
            {
                prop: 'ville.nomVille',
                title: 'Ville',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                cell: (row: CommandeInterface) => <span className="my-xsm-font">{(row as any)['ville.nomVille']}</span>,
                isFilterable: true,
                isSortable: true,
            },
            {
                prop: 'adresse',
                title: 'Adresse',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                cell: (row: CommandeInterface) => <span className="my-xsm-font">{row.adresse}</span>,
                isFilterable: true,
                isSortable: true,
            },
            {
                prop: 'dateCreation',
                title: 'Créé le',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: CommandeInterface) => (
                    <span className="my-xsm-font">{moment(row.dateCreation).format('DD/MM/YY HH:mm')}</span>
                ),
            },
            {
                prop: 'dateLivraison',
                title: 'Livrée le',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: CommandeInterface) => (
                    <span className="my-xsm-font">
                        {row.idEtatCommande === 4 ? moment(row.dateOperation).format('DD/MM/YY HH:mm') : '-'}
                    </span>
                ),
            },
            {
                prop: 'livreur.nom',
                title: 'Livreur',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: CommandeInterface) => (
                    <span className="my-xsm-font">
                        {(row as any)['livreur.nom'] ? (
                            <>
                                {contextUser?.userData.type === 'Client' ||
                                isSameFournisseurEmployee(
                                    contextUser?.userData.idFournisseur!,
                                    (row as any)['livreur.idFournisseur'],
                                )
                                    ? `${(row as any)['livreur.nom'].toUpperCase()} ${Capitalize1stLetter(
                                          (row as any)['livreur.prenom'],
                                      )}`
                                    : '****'}
                            </>
                        ) : (
                            '-'
                        )}
                    </span>
                ),
                ...hideFullCell(
                    contextUser?.userData?.userroles?.some((d) => d.role.nomRole === RolesMap.FOURNISSEUR_LIVREUR) &&
                        !contextUser?.userData?.userroles?.some((d) => d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN),
                ),
            },
            {
                prop: 'etatCommande.etatCommande',
                title: 'Etat',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: CommandeInterface) => (
                    <>
                        <EtatCommandeBadge value={(row as any)['etatCommande.etatCommande']} />{' '}
                        {!!row.livreurPaiement && <Badge bg="info">P</Badge>}
                    </>
                ),
            },
            {
                prop: 'historique',
                title: 'Historique',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                cell: (row: CommandeInterface) => (
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={(props: any) =>
                            row.commandeActions.length > 1 &&
                            row.commandeActions[row.commandeActions.length - 1].idEtatTitre == 10 ? (
                                <Tooltip className="etattitre_tooltip" {...props}>
                                    {row.commandeActions[row.commandeActions.length - 2].etatTitre?.titre!}
                                </Tooltip>
                            ) : (
                                <></>
                            )
                        }
                    >
                        <div>
                            <CustomColorsBadge
                                value={row.commandeActions[row.commandeActions.length - 1].etatTitre?.titre!}
                                textColor={row.commandeActions[row.commandeActions.length - 1].etatTitre?.textColor!}
                                backgroundColor={
                                    row.commandeActions[row.commandeActions.length - 1].etatTitre?.backgroundColor!
                                }
                                className="me-1"
                            />
                            {(row.commandeActions[row.commandeActions.length - 1].idEtatTitre === 7 ||
                                row.commandeActions[row.commandeActions.length - 1].idEtatTitre === 10) && (
                                <CustomColorsBadge
                                    value={moment(row.dateCreation).format('DD/MM/YY HH:mm')}
                                    textColor="#fff"
                                    backgroundColor="#5e837cbd"
                                />
                            )}
                            {(row.commandeActions[row.commandeActions.length - 1].idEtatTitre === 2 ||
                                row.commandeActions[row.commandeActions.length - 1].idEtatTitre === 19) && (
                                <>
                                    <CustomColorsBadge
                                        value={
                                            row.commandeActions[row.commandeActions.length - 1].description?.split(
                                                ' - ',
                                            )[0]!
                                        }
                                        textColor="#fff"
                                        backgroundColor="#937388"
                                    />
                                </>
                            )}
                            {row.commandeActions[row.commandeActions.length - 1].idEtatTitre === 10 && (
                                <>
                                    <CustomColorsBadge
                                        value={row.commandeActions[row.commandeActions.length - 2].etatTitre?.titre!}
                                        textColor={
                                            row.commandeActions[row.commandeActions.length - 2].etatTitre?.textColor!
                                        }
                                        backgroundColor={
                                            row.commandeActions[row.commandeActions.length - 2].etatTitre
                                                ?.backgroundColor!
                                        }
                                        className="me-1"
                                    />
                                </>
                            )}
                        </div>
                    </OverlayTrigger>
                ),
            },
            {
                prop: '--',
                title: 'Facture',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: CommandeInterface) => {
                    if (
                        contextUser?.userData.type !== 'Client' &&
                        !isTheOwnerOfCommande(contextUser?.userData?.idFournisseur!, row)
                    )
                        return <>Indisponible</>;
                    const fPaiement = row.idFacture && (row as any)['facture.idPaiement'];
                    return (
                        <span className="my-xsm-font">
                            {row.idFacture ? (
                                <>
                                    <strong>{(row as any)['facture.refFacture']}</strong>
                                    {fPaiement ? ' Payée' : ''}
                                </>
                            ) : (
                                '-'
                            )}
                        </span>
                    );
                },
                ...hideFullCell(pageType === 'AFacturer'),
            },
            {
                prop: 'null',
                title: '#',
                alignment: { horizontal: 'right' },
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                cell: (row: CommandeInterface) => (
                    <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle id="dropdown-autoclose-true"></Dropdown.Toggle>

                        <Dropdown.Menu className="btnDropDownInList" style={{ width: 'auto', minWidth: 'auto' }}>
                            <Dropdown.Item
                                className="text-left"
                                onClick={() => eagerLoadingCommande(row.idCommande, true, true)}
                            >
                                <FontAwesomeIcon icon={faInfo} size="xs" style={{ color: '#376ca9' }} width="20" />
                                Détails commande
                            </Dropdown.Item>

                            <Dropdown.Item className="text-left" onClick={() => handleSmallCommandeModalOpen(row)}>
                                <FontAwesomeIcon icon={faPrint} size="xs" style={{ color: '#776ca9' }} width="20" />
                                Imprimer commande
                            </Dropdown.Item>

                            {contextUser &&
                                contextUser?.userData?.userroles?.some(
                                    (d) =>
                                        d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                                        d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                ) &&
                                row.idEtatCommande! < 4 &&
                                isTheOwnerOfCommande(contextUser?.userData?.idFournisseur!, row) && (
                                    <Dropdown.Item
                                        className="text-left"
                                        onClick={() => handleEditCommandeFormOpen(row)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            size="xs"
                                            style={{ color: '#376ca9' }}
                                            width="20"
                                        />
                                        Editer commande
                                    </Dropdown.Item>
                                )}

                            {contextUser &&
                                (contextUser?.userData.type === 'Client' ||
                                    isTheOwnerOfCommande(contextUser?.userData?.idFournisseur!, row)) &&
                                contextUser?.userData?.userroles?.some(
                                    (d) =>
                                        d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                                        d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE ||
                                        d.role.nomRole === RolesMap.CLIENT_ADMIN,
                                ) &&
                                row.idEtatCommande! < 4 && (
                                    <Dropdown.Item
                                        className="text-left"
                                        onClick={() => handleEditCommandeAcheteurFormOpen(row)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            size="xs"
                                            style={{ color: '#376ca9' }}
                                            width="20"
                                        />
                                        Editer Acheteur
                                    </Dropdown.Item>
                                )}
                            {row.idFacture &&
                                (contextUser?.userData.type === 'Client' ||
                                    isTheOwnerOfCommande(contextUser?.userData?.idFournisseur!, row)) && (
                                    <>
                                        <PermissionsGate
                                            scopes={[
                                                RolesMap.FOURNISSEUR_ADMIN,
                                                RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                                RolesMap.FOURNISSEUR_FACTURE,
                                                RolesMap.CLIENT_ADMIN,
                                            ]}
                                            strict
                                        >
                                            <Dropdown.Item
                                                className="text-left"
                                                onClick={() => handleShowFullFactureOpen(row.idFacture!)}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPrint}
                                                    size="xs"
                                                    style={{ color: '#776ca9' }}
                                                    width="20"
                                                />
                                                Imprimer facture
                                            </Dropdown.Item>
                                        </PermissionsGate>
                                        <PermissionsGate
                                            scopes={[
                                                RolesMap.FOURNISSEUR_ADMIN,
                                                RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                                RolesMap.FOURNISSEUR_FACTURE,
                                                RolesMap.CLIENT_ADMIN,
                                            ]}
                                            strict
                                        >
                                            <Dropdown.Item
                                                className="text-left"
                                                onClick={() => handlePrintableOneCommandeFactureModalOpen(row)}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPrint}
                                                    size="xs"
                                                    style={{ color: '#776ca9' }}
                                                    width="20"
                                                />
                                                Imprimer commande facture
                                            </Dropdown.Item>
                                        </PermissionsGate>
                                    </>
                                )}
                        </Dropdown.Menu>
                    </Dropdown>
                ),
            },
            ...(pageType === 'Original' || pageType === 'Transfert'
                ? [
                      {
                          prop: 'printchecked',
                          cellProps: {
                              className: 'foceVerticalMiddle text-center',
                              //   style: { width: '40px' },
                          },
                          thProps: {
                              className: 'text-center',
                              style: { width: '100px' },
                          },
                          headerCell: () => (
                              <InputGroup size="sm" className="justify-content-center">
                                  {checkedPrintingCMD.length > 0 && (
                                      <>
                                          <Button
                                              variant="outline-secondary"
                                              size="sm"
                                              onClick={() => handleMultipleCommandeBEModalOpen()}
                                          >
                                              <FontAwesomeIcon icon={faPaperclip} size="xs" />
                                          </Button>
                                      </>
                                  )}
                                  <Button
                                      variant="outline-dark"
                                      size="sm"
                                      onClick={() => onCheckPrintAllCommandesRow()}
                                  >
                                      <FontAwesomeIcon icon={faCheckDouble} size="xs" />
                                  </Button>
                                  {checkedPrintingCMD.length > 0 && (
                                      <>
                                          <Button
                                              variant="outline-info"
                                              size="sm"
                                              onClick={() => handleMultipleCommandeModalOpen()}
                                          >
                                              <FontAwesomeIcon icon={faPrint} size="xs" />
                                          </Button>
                                      </>
                                  )}
                              </InputGroup>
                          ),
                          cell: (row: CommandeInterface) => (
                              <>
                                  {![4, 5].includes(row.idEtatCommande!) && (
                                      <input
                                          type="checkbox"
                                          checked={checkedPrintingCMD.includes(row.idCommande)}
                                          //   readOnly
                                          onChange={() => onCheckPrintCommandesRow(row)}
                                      />
                                  )}
                              </>
                          ),
                      },
                  ]
                : []),
            ...(pageType === 'Original' &&
            contextUser?.userData?.userroles?.some((d) => d.role.nomRole === RolesMap.CLIENT_ADMIN)
                ? [
                      {
                          prop: 'drschecked',
                          cellProps: {
                              className: 'foceVerticalMiddle text-center',
                          },
                          thProps: {
                              className: 'text-center',
                              style: { width: '100px' },
                          },
                          headerCell: () => (
                              <InputGroup size="sm" className="justify-content-center">
                                  <Button variant="outline-dark" size="sm" onClick={() => onCheckDRSAllCommandesRow()}>
                                      <FontAwesomeIcon icon={faCheckDouble} size="xs" />
                                  </Button>
                                  {checkDemandeRetourStock.length > 0 && (
                                      <>
                                          <Button
                                              variant="outline-secondary"
                                              size="sm"
                                              onClick={() => handleModalDemandeRetourStockOpen()}
                                          >
                                              <FontAwesomeIcon icon={faPersonCircleQuestion} size="xs" />
                                          </Button>
                                      </>
                                  )}
                              </InputGroup>
                          ),
                          cell: (row: CommandeInterface) => (
                              <>
                                  {+row.idEtatCommande! < 4 && row.demandeRetourStocks?.length! === 0 && (
                                      <input
                                          type="checkbox"
                                          checked={checkDemandeRetourStock.includes(row.idCommande)}
                                          //   readOnly
                                          onChange={() => onCheckDRSCommandesRow(row)}
                                      />
                                  )}
                              </>
                          ),
                      },
                  ]
                : []),
        ],
        [
            checkedCommandes,
            onCheckAllCommandes,
            onCheckCommandesrow,
            checkedPrintingCMD,
            checkDemandeRetourStock,
            onCheckPrintCommandesRow,
            onCheckPrintAllCommandesRow,
            onCheckDRSCommandesRow,
            onCheckDRSAllCommandesRow,
            commandes.items,
            contextUser,
            selectedEtat,
            selectedClient,
            selectedLivreur,
            pageType,
        ],
    );

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        {
            active: pageType === 'Original' ? true : false,
            text: 'Liste des commandes',
            path: pageType === 'Original' ? undefined : '/commandes',
        },
    ];

    if (pageType === 'NeedAttention') {
        breadcrumbData.push({ active: true, text: "Besoin d'attention" });
    } else if (pageType === 'AFacturer') {
        breadcrumbData.push({ active: true, text: 'Créer facture client' });
    } else if (pageType === 'Transfert') {
        breadcrumbData.push({ active: true, text: 'Commandes (Partenariat)' });
    }

    const isStrictLivreur =
        contextUser?.userData?.userroles?.some((d) => d.role.nomRole === RolesMap.FOURNISSEUR_LIVREUR) &&
        !contextUser?.userData?.userroles?.some((d) => d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN);

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <Row>
                <Col md={pageType !== 'AFacturer' ? '4' : '6'} lg={pageType !== 'AFacturer' ? '3' : '6'}>
                    <TitleElement level={1} lineWidth="50%">
                        {pageType === 'AFacturer'
                            ? 'Créer facture client'
                            : pageType === 'NeedAttention'
                            ? "besoin d'attention"
                            : pageType === 'Transfert'
                            ? 'Commandes (Partenariat)'
                            : 'Liste des commandes'}
                    </TitleElement>
                </Col>
                {pageType !== 'AFacturer' && (
                    <Col auto>
                        <EtatCommandesDashboard data={etatCommandesStates} />
                    </Col>
                )}
            </Row>

            <Collapser moreClassNames={['flex-grow-07']}>
                <div>
                    <Row className="mb-2 holdit_notEffect">
                        {contextUser && contextUser.userData.type === 'Fournisseur' && (
                            <Form.Group
                                as={Col}
                                xs={6}
                                lg={3}
                                md={3}
                                controlId="form_grp_Client"
                                className={`${!!isStrictLivreur ? ' d-none' : ''}`}
                            >
                                <Form.Label>Client:</Form.Label>
                                <Form.Select
                                    size="sm"
                                    name="selectedClient"
                                    value={selectedClient!}
                                    onChange={(e) => {
                                        const {
                                            target: { value },
                                        } = e;
                                        setSelectedClient(value);
                                        handlePageNFilterChange(
                                            1,
                                            JSON.stringify({
                                                client: value,
                                                status: selectedEtat,
                                                facture: selectedFactureEtat,
                                                paiement: selectedPaiementEtat,
                                                dDebut: date_debut,
                                                dFin: date_fin,
                                                phoneNumber,
                                                idVille: selectedVille,
                                                idEtatTitre: selectedEtatTitre,
                                                partner: selectedPartner,
                                            }),
                                        );
                                    }}
                                    placeholder="Selectionner un client"
                                    disabled={!!isStrictLivreur}
                                >
                                    <option value="">Selectionner un client</option>
                                    {clients.map((d: ClientInterface) => (
                                        <option key={d.idClient} value={d.idClient}>
                                            {d.raisonSocial}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        )}

                        <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_VilleFilter">
                            <Form.Label>Ville:</Form.Label>
                            <Form.Select
                                size="sm"
                                name="selectedVille"
                                value={selectedVille!}
                                onChange={(e) => {
                                    const {
                                        target: { value },
                                    } = e;
                                    setSelectedVille(value);
                                    handlePageNFilterChange(
                                        1,
                                        JSON.stringify({
                                            client: selectedClient,
                                            status: selectedEtat,
                                            facture: selectedFactureEtat,
                                            paiement: selectedPaiementEtat,
                                            dDebut: date_debut,
                                            dFin: date_fin,
                                            phoneNumber,
                                            idVille: value,
                                            idEtatTitre: selectedEtatTitre,
                                            partner: selectedPartner,
                                        }),
                                    );
                                }}
                                placeholder="Selectionner une Ville"
                            >
                                <option value="">Selectionner une Ville</option>
                                {villes.map((d: VilleInterface) => (
                                    <option key={d.idVille} value={d.idVille}>
                                        {d.nomVille}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Col xs={6} lg={3} md={3}>
                            <Row>
                                <Form.Group as={Col} xs={6} lg={6} md={6} controlId="form_grp_date_debut">
                                    <Form.Label>Date début:</Form.Label>
                                    <Datetime
                                        inputProps={{
                                            name: 'date_debut',
                                            className: 'form-control form-control-sm',
                                            readOnly: true,
                                            // disabled: !!isStrictLivreur,
                                        }}
                                        onChange={(e: any) => onChangeDateDebut(e)}
                                        value={moment(date_debut, 'YYYY-MM-DD  HH:mm:ss').format('DD/MM/YYYY')}
                                        dateFormat="DD/MM/YYYY"
                                        timeFormat={false}
                                        closeOnSelect
                                    />
                                </Form.Group>
                                <Form.Group as={Col} xs={6} lg={6} md={6} controlId="form_grp_date_fin">
                                    <Form.Label>Date fin:</Form.Label>
                                    <Datetime
                                        inputProps={{
                                            name: 'date_fin',
                                            className: 'form-control form-control-sm',
                                            readOnly: true,
                                            // disabled: !!isStrictLivreur,
                                        }}
                                        onChange={(e: any) => onChangeDateFin(e)}
                                        value={moment(date_fin, 'YYYY-MM-DD  HH:mm:ss').format('DD/MM/YYYY')}
                                        dateFormat="DD/MM/YYYY"
                                        timeFormat={false}
                                        closeOnSelect
                                    />
                                </Form.Group>
                            </Row>
                        </Col>

                        <PermissionsGate
                            scopes={[
                                RolesMap.FOURNISSEUR_ADMIN,
                                RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                RolesMap.FOURNISSEUR_FACTURE,
                            ]}
                            strict
                        >
                            <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_livreur">
                                <Form.Label>Livreur</Form.Label>
                                <Form.Select
                                    size="sm"
                                    name="idLivreur"
                                    value={selectedLivreur!}
                                    onChange={(e) => {
                                        const {
                                            target: { value },
                                        } = e;
                                        setSelectedLivreur(value);
                                        handlePageNFilterChange(
                                            1,
                                            JSON.stringify({
                                                client: selectedClient,
                                                livreur: value,
                                                status: selectedEtat,
                                                facture: selectedFactureEtat,
                                                paiement: selectedPaiementEtat,
                                                dDebut: date_debut,
                                                dFin: date_fin,
                                                phoneNumber,
                                                idVille: selectedVille,
                                                idEtatTitre: selectedEtatTitre,
                                                partner: selectedPartner,
                                            }),
                                        );
                                    }}
                                    placeholder="Sélectionner un livreur"
                                >
                                    <option>Sélectionner un livreur</option>
                                    {livreurs.map((d: UserInterface) => (
                                        <option key={d.idUser} value={d.idUser}>
                                            {d.nom.toUpperCase()} {Capitalize1stLetter(d.prenom)}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </PermissionsGate>
                    </Row>
                    <Row className="mb-2 holdit_notEffect">
                        <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_phone">
                            <Form.Label>Téléphone:</Form.Label>
                            <InputGroup className="align-items-center" size="sm">
                                <Form.Control
                                    type="text"
                                    placeholder="phoneNumber"
                                    aria-label="phoneNumber"
                                    aria-describedby="basic-phoneNumber"
                                    onChange={(e: any) => setPhoneNumber(e.target.value)}
                                    value={phoneNumber}
                                    size="sm"
                                />
                                <Button
                                    variant="outline-info"
                                    className="button-input-group-effect gap-5marginpx"
                                    id="basic-addon1"
                                    onClick={() =>
                                        handlePageNFilterChange(
                                            1,
                                            JSON.stringify({
                                                client: selectedClient!,
                                                status: selectedEtat,
                                                facture: selectedFactureEtat,
                                                paiement: selectedPaiementEtat,
                                                dDebut: date_debut,
                                                dFin: date_fin,
                                                phoneNumber,
                                                idVille: selectedVille,
                                                idEtatTitre: selectedEtatTitre,
                                                partner: selectedPartner,
                                            }),
                                        )
                                    }
                                >
                                    <FontAwesomeIcon icon={faSearch} size="xs" style={{ color: '#000' }} />
                                </Button>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_Client">
                            <Form.Label>Etat:</Form.Label>
                            <Form.Select
                                size="sm"
                                name="selectedEtat"
                                value={selectedEtat}
                                onChange={(e) => {
                                    const {
                                        target: { value },
                                    } = e;
                                    setSelectedEtat(value);
                                    setCheckedCommandes([]);
                                    setCheckedPrintingCMD([]);
                                    setCheckDemandeRetourStock([]);
                                    const updatedFactureEtat = value !== '4' ? '' : selectedFactureEtat;
                                    const updatedPaiementEtat = value !== '4' ? '' : selectedPaiementEtat;
                                    handlePageNFilterChange(
                                        1,
                                        JSON.stringify({
                                            client: selectedClient!,
                                            status: value,
                                            facture: updatedFactureEtat,
                                            paiement: updatedPaiementEtat,
                                            dDebut: date_debut,
                                            dFin: date_fin,
                                            phoneNumber,
                                            idVille: selectedVille,
                                            idEtatTitre: selectedEtatTitre,
                                            partner: selectedPartner,
                                        }),
                                    );
                                }}
                                placeholder="Selectionner une etat"
                                disabled={pageType === 'AFacturer'}
                            >
                                <option value="">Tous</option>
                                {etatCommandes.map((d: EtatCommandeInterface) => (
                                    <option key={d.idEtatCommande} value={d.idEtatCommande}>
                                        {d.etatCommande}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_histo">
                            <Form.Label>Historique:</Form.Label>
                            <Form.Select
                                size="sm"
                                name="selectedEtatTitre"
                                value={selectedEtatTitre}
                                onChange={(e) => {
                                    const {
                                        target: { value },
                                    } = e;
                                    setSelectedEtatTitre(value);
                                    handlePageNFilterChange(
                                        1,
                                        JSON.stringify({
                                            client: selectedClient,
                                            status: selectedEtat,
                                            facture: selectedFactureEtat,
                                            paiement: selectedPaiementEtat,
                                            dDebut: date_debut,
                                            dFin: date_fin,
                                            phoneNumber,
                                            idVille: selectedVille,
                                            idEtatTitre: value,
                                            partner: selectedPartner,
                                        }),
                                    );
                                }}
                                placeholder="Historique"
                            >
                                <option value="">Tous</option>
                                {etatTitres.map((d: EtatTitreInterface) => (
                                    <option key={d.idEtatTitre} value={d.idEtatTitre}>
                                        {d.titre}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Col xs={6} lg={3} md={3}>
                            <Row
                                className={clsx({
                                    'd-none': pageType === 'Transfert',
                                })}
                            >
                                <Form.Group as={Col} xs={6} lg={6} md={6} controlId="form_grp_Client">
                                    <Form.Label>Facturation:</Form.Label>
                                    <Form.Select
                                        size="sm"
                                        name="selectedFactureEtat"
                                        value={selectedFactureEtat}
                                        onChange={(e) => {
                                            const {
                                                target: { value },
                                            } = e;
                                            setSelectedFactureEtat(value);
                                            const updatedPaiementEtat = value !== '1' ? '' : selectedPaiementEtat;
                                            handlePageNFilterChange(
                                                1,
                                                JSON.stringify({
                                                    client: selectedClient!,
                                                    status: selectedEtat,
                                                    facture: value,
                                                    paiement: updatedPaiementEtat,
                                                    dDebut: date_debut,
                                                    dFin: date_fin,
                                                    phoneNumber,
                                                    idVille: selectedVille,
                                                    idEtatTitre: selectedEtatTitre,
                                                    partner: selectedPartner,
                                                }),
                                            );
                                        }}
                                        disabled={selectedEtat !== '4' || pageType === 'AFacturer'}
                                        placeholder="Selectionner une etat de facture"
                                    >
                                        <option value="">Tous</option>
                                        <option value="1">Facturée</option>
                                        <option value="0">Non Facturée</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} xs={6} lg={6} md={6} controlId="form_grp_Client">
                                    <Form.Label>Paiement:</Form.Label>
                                    <Form.Select
                                        size="sm"
                                        name="selectedPaiementEtat"
                                        value={selectedPaiementEtat}
                                        onChange={(e) => {
                                            const {
                                                target: { value },
                                            } = e;
                                            setSelectedPaiementEtat(value);
                                            handlePageNFilterChange(
                                                1,
                                                JSON.stringify({
                                                    client: selectedClient!,
                                                    status: selectedEtat,
                                                    facture: selectedFactureEtat,
                                                    paiement: value,
                                                    dDebut: date_debut,
                                                    dFin: date_fin,
                                                    phoneNumber,
                                                    idVille: selectedVille,
                                                    idEtatTitre: selectedEtatTitre,
                                                    partner: selectedPartner,
                                                }),
                                            );
                                        }}
                                        disabled={selectedFactureEtat !== '1' || pageType === 'AFacturer'}
                                        placeholder="Selectionner une etat de paiement"
                                    >
                                        <option value="">Tous</option>
                                        <option value="1">Payée</option>
                                        <option value="0">Non Payée</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <Row
                                className={clsx({
                                    'd-none': pageType !== 'Transfert',
                                })}
                            >
                                <Form.Group as={Col} md={12} controlId="form_grp_Client">
                                    <Form.Label>Partenaire:</Form.Label>
                                    <Form.Select
                                        size="sm"
                                        name="selectedPartner"
                                        value={selectedPartner!}
                                        onChange={(e) => {
                                            const {
                                                target: { value },
                                            } = e;
                                            setSelectedPartner(value);
                                            handlePageNFilterChange(
                                                1,
                                                JSON.stringify({
                                                    client: selectedClient,
                                                    status: selectedEtat,
                                                    facture: selectedFactureEtat,
                                                    paiement: selectedPaiementEtat,
                                                    dDebut: date_debut,
                                                    dFin: date_fin,
                                                    phoneNumber,
                                                    idVille: selectedVille,
                                                    idEtatTitre: selectedEtatTitre,
                                                    partner: value,
                                                }),
                                            );
                                        }}
                                        placeholder="Selectionner un partenaire"
                                        disabled={!!isStrictLivreur}
                                    >
                                        <option value="">Tous</option>
                                        {partners.map((dr: FournisseurInterface) => (
                                            <option key={dr.idFournisseur} value={dr.idFournisseur}>
                                                {dr.raisonSocial}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Collapser>
            <Datatable
                data={commandes.items}
                tableColumns={tableHeaders}
                initialSort={{ initialState: { prop: 'dateOperation', order: 'desc' } }}
                // rowClickCB={onCheckCommandesrow}
            >
                <Row>
                    <Col className="d-flex flex-col justify-content-end align-items-end">
                        <Paginator
                            defaultPage={activePage}
                            totalPages={Math.ceil(commandes.count / TOTAL_DATA_PER_PAGE)}
                            callback={(e_page) =>
                                handlePageNFilterChange(
                                    e_page,
                                    JSON.stringify({
                                        client: selectedClient,
                                        status: selectedEtat,
                                        facture: selectedFactureEtat,
                                        paiement: selectedPaiementEtat,
                                        dDebut: date_debut,
                                        dFin: date_fin,
                                        phoneNumber,
                                        idVille: selectedVille,
                                        idEtatTitre: selectedEtatTitre,
                                        partner: selectedPartner,
                                    }),
                                )
                            }
                        />
                    </Col>
                </Row>
            </Datatable>
            {selectedClient &&
                checkedCommandes.length > 0 &&
                selectedEtat === '4' &&
                contextUser?.userData.type === 'Fournisseur' && (
                    <Tabs
                        defaultActiveKey="factureCreation"
                        transition={false}
                        id="factureCreation_tabs"
                        className="mt-1"
                    >
                        <Tab eventKey="factureCreation" title="Creation de facture" className="tab-content-border">
                            <div className="d-flex justify-content-between gap-2 p-1">
                                <div>
                                    <b>{checkedCommandes.length}</b> commande(s) selectionnée(s) (Prix Total:{' '}
                                    {commandes.items
                                        .filter((d: CommandeInterface) => checkedCommandes.includes(d.idCommande))
                                        .reduce((accumulator: number, object: any) => {
                                            return accumulator + object.prixTotal;
                                        }, 0)}{' '}
                                    DH )
                                </div>
                                <Button
                                    variant="outline-info"
                                    className="gap-5marginpx"
                                    onClick={() => handleFactureFormModalOpen()}
                                >
                                    Créer facture
                                </Button>
                            </div>
                        </Tab>
                    </Tabs>
                )}
            {checkedCommandes.length > 0 && selectedEtat !== '4' && contextUser?.userData.type === 'Fournisseur' && (
                <Tabs defaultActiveKey="livraison" transition={false} id="livraison_tabs" className="mt-1">
                    <Tab
                        eventKey="livraison"
                        title={<strong>Affectation livreur</strong>}
                        className="tab-content-border"
                    >
                        <div className="d-flex justify-content-between gap-2 p-1">
                            <div>
                                <b>{checkedCommandes.length}</b> commande(s) selectionnée(s) (Prix Total:{' '}
                                {commandes.items
                                    .filter((d: CommandeInterface) => checkedCommandes.includes(d.idCommande))
                                    .reduce((accumulator: number, object: any) => {
                                        return accumulator + object.prixTotal;
                                    }, 0)}{' '}
                                DH )
                            </div>
                        </div>
                        <AffectationLivreur
                            commandes={commandes}
                            setCommandes={setCommandes}
                            setCheckedCommandes={setCheckedCommandes}
                            setCheckedPrintingCMD={setCheckedPrintingCMD}
                            livreurs={livreurs}
                            etatTitres={etatTitres}
                            selectedCommandes={checkedCommandes}
                        />
                    </Tab>
                </Tabs>
            )}

            <CustomModal
                title="Commande Détails"
                size="lg"
                show={showModal}
                handleClose={handleModalClose}
                fullscreen
                id="cmd-details-info"
            >
                <CommandeFormComponent
                    commandes={commandes}
                    setCommandes={(value: { items: CommandeInterface[]; count: number }) => setCommandes(value)}
                    currentCommande={currentCommande!}
                    setCurrentCommande={(value: CommandeInterface | null) => setCurrentCommande(value)}
                    setCheckedCommandes={(value: number[]) => setCheckedCommandes(value)}
                />
            </CustomModal>
            <CustomModal
                title="Editer la commande"
                size="lg"
                show={showEditCommandeForm}
                handleClose={handleEditCommandeFormClose}
            >
                <CommandeEditFormComponent
                    commandes={commandes}
                    setCommandes={(value: { items: CommandeInterface[]; count: number }) => setCommandes(value)}
                    currentCommande={currentCommande!}
                    setCurrentCommande={(value: CommandeInterface | null) => setCurrentCommande(value)}
                    setCheckedCommandes={(value: number[]) => setCheckedCommandes(value)}
                    closeModal={handleEditCommandeFormClose}
                />
            </CustomModal>
            <CustomModal
                title="Editer l'acheteur"
                size="lg"
                show={showEditCommandeAcheteurForm}
                handleClose={handleEditCommandeAcheteurFormClose}
            >
                <CommandeAcheteurEditForm
                    commandes={commandes}
                    setCommandes={(value: { items: CommandeInterface[]; count: number }) => setCommandes(value)}
                    currentCommande={currentCommande!}
                    setCurrentCommande={(value: CommandeInterface | null) => setCurrentCommande(value)}
                    setCheckedCommandes={(value: number[]) => setCheckedCommandes(value)}
                    closeModal={handleEditCommandeAcheteurFormClose}
                />
            </CustomModal>
            <CustomModal
                title="Création de facture"
                size="lg"
                show={showFactureFormModal}
                handleClose={handleFactureFormModalClose}
            >
                <FactureCommandesFormComponent
                    commandes={commandes}
                    setCommandes={(value: { items: CommandeInterface[]; count: number }) => setCommandes(value)}
                    checkedCommandes={checkedCommandes}
                    setCheckedCommandes={(value: number[]) => setCheckedCommandes(value)}
                    selectedClient={selectedClient}
                    handleFactureFormModalClose={() => handleFactureFormModalClose()}
                />
            </CustomModal>
            <CustomModal
                title="Imprimer facture d'une seule commande"
                size="lg"
                show={showPrintableOneCommandeFacture}
                handleClose={handlePrintableOneCommandeFactureModalClose}
            >
                {currentCommande ? <PrintCommande commande={currentCommande!} /> : <></>}
            </CustomModal>
            <CustomModal
                title="Imprimer la commande"
                size="lg"
                show={showSmallCommande}
                handleClose={handleSmallCommandeModalClose}
            >
                {currentCommande ? <SmallCommande commande={currentCommande!} /> : <></>}
            </CustomModal>
            <CustomModal
                title="Imprimer les commandes"
                size="lg"
                show={showMultipleCommande}
                handleClose={handleMultipleCommandeModalClose}
            >
                {checkedPrintingCMD.length > 0 && !!showMultipleCommande ? (
                    <MultiSmallCommandes
                        groupVilles={groupVilles}
                        commandes={commandes.items.filter((d: CommandeInterface) =>
                            checkedPrintingCMD.includes(d.idCommande),
                        )}
                    />
                ) : (
                    <></>
                )}
            </CustomModal>
            <CustomModal
                title="Imprimer le bordereau d'envoi"
                size="lg"
                show={showMultipleCommandeBE}
                handleClose={handleMultipleCommandeBEModalClose}
            >
                {checkedPrintingCMD.length > 0 && !!showMultipleCommandeBE ? (
                    <MultiCommandesBE
                        commandes={commandes.items.filter((d: CommandeInterface) =>
                            checkedPrintingCMD.includes(d.idCommande),
                        )}
                    />
                ) : (
                    <></>
                )}
            </CustomModal>
            <CustomModal
                title="Imprimer facture"
                size="lg"
                show={showFullFacture}
                handleClose={handleShowFullFactureClose}
            >
                <FactureComponent loadData={showFullFacture} idFacture={idFacture!} />
            </CustomModal>
            <CustomModal
                title="Demande retour stock"
                size="lg"
                show={showModalDemandeRetourStock}
                handleClose={handleModalDemandeRetourStockClose}
            >
                <DemandeRetourStockFormRangeAction
                    cmdIds={checkDemandeRetourStock}
                    resetCheckedInputs={() => resetCheckedInputs()}
                    reloadData={() =>
                        loadCommandes(
                            TOTAL_DATA_PER_PAGE,
                            activePage,
                            selectedEtat,
                            selectedFactureEtat,
                            selectedPaiementEtat,
                            phoneNumber,
                            selectedVille,
                            selectedEtatTitre,
                            selectedClient!,
                            selectedLivreur!,
                            date_debut,
                            date_fin,
                            selectedPartner!,
                        )
                    }
                    closeModal={handleModalDemandeRetourStockClose}
                />
            </CustomModal>
        </>
    );
};

CommandeIndexPage.defaultProps = {
    pageType: 'Original',
};

export default CommandeIndexPage;
