import React, { useEffect, useState, useMemo, useContext } from 'react';
import moment from 'moment';
import { Col, Dropdown, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faHandHoldingDollar, faInfo, faPrint, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import TitleElement from 'components/UI/titleElement';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import TableTemplate from 'components/shared/datatable/tableTemplate';
import TransfertFactureInterface from 'types/interfaces/transfertFacture.interface';
import CustomModal from 'components/UI/customModal';
import Paginator from 'components/UI/paginator';
import { buildUrlQueryParams, isEmptyObject, useQueryMeMo } from 'utils/helpers';
import Datetime from 'react-datetime';
import Collapser from 'components/UI/collapser';
import { getFactures } from 'services/transfert.service';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import TransfertFactureDetails from 'pages/Transfert/factures/components/TransfertFactureDetails';
import TransfertFacturePaiementForm from 'pages/Transfert/factures/components/TransfertFacturePaiementForm';
import FacturePrint from 'pages/Transfert/factures/components/FacturePrint';

const TOTAL_DATA_PER_PAGE = 100;

const FacturesList: React.FC = () => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);
    const [currentTransfertFacture, setCurrentTransfertFacture] = useState<TransfertFactureInterface | null>(null);
    const [factures, setFactures] = useState<{ items: TransfertFactureInterface[]; count: number }>({
        items: [],
        count: 0,
    });
    const [enablePaymentForm, setEnablePaymentForm] = useState<boolean>(false);
    const [showPaiementDetails, setShowPaiementDetails] = useState<boolean>(false);
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [date_debut, setDate_debut] = useState<any>(null);
    const [date_fin, setDate_fin] = useState<any>(null);
    const [showFacturationModal, setShowFacturationModal] = useState<boolean>(false);
    const query = useQueryMeMo();
    const history = useNavigate();

    const [selectedStatus, setSelectedStatus] = useState<string>('');
    const [activePage, setActivePage] = useState<number>(1);

    const handleShowFacture = (data: TransfertFactureInterface) => {
        setCurrentTransfertFacture(data);
        setShowFacturationModal(true);
    };
    const handleCloseFacture = () => {
        setCurrentTransfertFacture(null);
        setShowFacturationModal(false);
    };

    const openDetailsModals = (data: TransfertFactureInterface) => {
        setCurrentTransfertFacture(data);
        setShowDetails(true);
    };

    const closeDetailsModals = () => {
        setCurrentTransfertFacture(null);
        setShowDetails(false);
    };
    const openPaiementDetailsModals = (data: TransfertFactureInterface, permission: boolean) => {
        setEnablePaymentForm(permission);
        setCurrentTransfertFacture(data);
        setShowPaiementDetails(true);
    };

    const closePaiementDetailsModals = () => {
        setEnablePaymentForm(false);
        setCurrentTransfertFacture(null);
        setShowPaiementDetails(false);
    };

    const onChangeDateDebut = (e: any) => {
        setDate_debut(e);
        handlePageNFilterChange(
            1,
            JSON.stringify({
                dDebut: e.format('YYYY-MM-DD HH:mm:ss'),
                dFin: date_fin,
                status: selectedStatus,
            }),
        );
    };

    const onChangeDateFin = (e: any) => {
        setDate_fin(e);
        handlePageNFilterChange(
            1,
            JSON.stringify({
                dDebut: date_debut,
                dFin: e.format('YYYY-MM-DD HH:mm:ss'),
                status: selectedStatus,
            }),
        );
    };

    const handlePageNFilterChange = (activePage: number, searchedData?: string) => {
        const urlObject: any = {};
        if (activePage > 1) urlObject['page'] = activePage;
        if (searchedData) {
            const parsedObject = JSON.parse(searchedData);
            Object.keys(parsedObject).forEach((key) => {
                if (parsedObject[key as any]) {
                    urlObject[key] = parsedObject[key as any];
                }
            });
        }
        history(isEmptyObject(urlObject) ? window.location.pathname : `?${buildUrlQueryParams(urlObject)}`);
    };

    const loadFactures = (
        dateDebut?: Date | string,
        dateFin?: Date | string,
        status?: string,
        page?: number,
        total?: number,
    ) => {
        getFactures(dateDebut, dateFin, status, page, total)
            .then((response: AxiosResponse<TransfertFactureInterface[] | any>) => {
                const { data } = response;
                setFactures({
                    items: data.items,
                    count: data.count,
                });
            })
            .catch();
    };

    useEffect(() => {
        const page = query.get('page');
        const status = query.get('status') ?? '';
        const selectedPage = page ? +page : 1;
        const dDebut = query.get('dDebut');
        const dFin = query.get('dFin');

        const _dateDebut = (dDebut ? moment(dDebut) : moment().subtract(3, 'days')).format('YYYY-MM-DD HH:mm:ss');
        const _dateFin = (dFin ? moment(dFin) : moment().endOf('day')).format('YYYY-MM-DD HH:mm:ss');

        setDate_debut(_dateDebut!);
        setDate_fin(_dateFin!);
        setActivePage(selectedPage);
        setSelectedStatus(status);

        const loadData = async () => {
            loadFactures(_dateDebut, _dateFin, status, selectedPage, TOTAL_DATA_PER_PAGE);
        };
        loadData();
    }, [query]);

    const tableHeaders = useMemo(
        () => [
            {
                Header: 'Ref',
                accessor: 'ref',
            },
            {
                Header: 'Source',
                accessor: 'fournisseurSource',
                Cell: ({ row: { original } }: { row: { original: TransfertFactureInterface } }) => (
                    <>
                        {
                            (original.transfertFactureCommandes as any)[0].transfertCommande?.fournisseurSource
                                ?.raisonSocial
                        }
                    </>
                ),
            },
            {
                Header: 'Destinataire',
                accessor: 'fournisseurDestinataire',
                Cell: ({ row: { original } }: { row: { original: TransfertFactureInterface } }) => (
                    <>
                        {
                            (original.transfertFactureCommandes as any)[0].transfertCommande?.fournisseurDestinataire
                                ?.raisonSocial
                        }
                    </>
                ),
            },
            {
                Header: 'Date',
                accessor: 'dateCreation',
                Cell: ({ row: { original } }: { row: { original: TransfertFactureInterface } }) => (
                    <>{moment(original.dateCreation).format('DD/MM/YYYY HH:mm:ss')}</>
                ),
            },
            {
                Header: 'Commandes',
                accessor: '--commandes',
                Cell: ({ row: { original } }: { row: { original: TransfertFactureInterface } }) => (
                    <>
                        {original.transfertFactureCommandes?.reduce(
                            (total, facture) => total + facture.transfertCommande?.commande?.prixTotal!,
                            0,
                        )}{' '}
                        DH
                    </>
                ),
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: 'Service',
                accessor: 'total',
                Cell: ({ row: { original } }: { row: { original: TransfertFactureInterface } }) => (
                    <>{original.total} DH</>
                ),
            },
            {
                Header: 'Payé',
                accessor: 'paid',
                Cell: ({ row: { original } }: { row: { original: TransfertFactureInterface } }) => (
                    <>
                        {original.paid ? 'Oui' : 'Non'}{' '}
                        {original.confirmed! && (
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{ color: '#1d9c0c' }} width="20" />
                        )}
                    </>
                ),
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: 'Montant Final',
                accessor: '--mfinal',
                Cell: ({ row: { original } }: { row: { original: TransfertFactureInterface } }) => (
                    <b>
                        {(original.transfertFactureCommandes?.reduce(
                            (total, facture) => total + facture.transfertCommande?.commande?.prixTotal!,
                            0,
                        ) ?? 0) - original.total}{' '}
                        DH
                    </b>
                ),
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: 'Détails',
                accessor: '--d',
                Cell: ({ row: { original } }: { row: { original: TransfertFactureInterface } }) => (
                    <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle id="dropdown-autoclose-true"></Dropdown.Toggle>
                        <Dropdown.Menu className="btnDropDownInList" style={{ width: 'auto', minWidth: 'auto' }}>
                            <Dropdown.Item className="text-left" onClick={() => openDetailsModals(original)}>
                                <FontAwesomeIcon icon={faInfo} size="xs" style={{ color: '#376ca9' }} width="20" />
                                Détails commande
                            </Dropdown.Item>
                            <Dropdown.Item className="text-left" onClick={() => handleShowFacture(original)}>
                                <FontAwesomeIcon icon={faPrint} size="xs" style={{ color: '#776ca9' }} width="20" />
                                Imprimer facture
                            </Dropdown.Item>
                            {(original.paid === true ||
                                (original.transfertFactureCommandes as any)[0].transfertCommande
                                    ?.idFournisseurDestinataire === contextUser?.userData?.idFournisseur!) && (
                                <Dropdown.Item
                                    className="text-left"
                                    onClick={() =>
                                        openPaiementDetailsModals(
                                            original!,
                                            (original.transfertFactureCommandes as any)[0].transfertCommande
                                                ?.idFournisseurDestinataire === contextUser?.userData?.idFournisseur! &&
                                                !original.paid,
                                        )
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={original.paid === true ? faFileInvoice : faHandHoldingDollar}
                                        size="xs"
                                        style={{ color: '#376ca9' }}
                                        width="20"
                                    />
                                    {original.paid === true ? 'Paiement détails' : 'Payer'}
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                ),
                disableSortBy: true,
                disableFilters: true,
            },
        ],
        [factures.items, contextUser],
    );

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        {
            active: true,
            text: 'Liste facture partenaire',
        },
    ];

    useEffect(() => {
        document.title = 'Liste facture partenaire - DS';
    }, []);

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Liste facture partenaire
                </TitleElement>
            </div>
            <Collapser moreClassNames={['flex-grow-07']}>
                <div>
                    <Row className="mb-2 holdit_notEffect">
                        <Form.Group as={Col} xs={6} lg={4} md={4} controlId="form_grp_date_debut">
                            <Form.Label>Date début:</Form.Label>
                            <Datetime
                                inputProps={{
                                    name: 'date_debut',
                                    className: 'form-control form-control-sm',
                                    readOnly: true,
                                }}
                                onChange={(e: any) => onChangeDateDebut(e)}
                                value={moment(date_debut, 'YYYY-MM-DD  HH:mm:ss').format('DD/MM/YYYY')}
                                dateFormat="DD/MM/YYYY"
                                timeFormat={false}
                                closeOnSelect
                            />
                        </Form.Group>
                        <Form.Group as={Col} xs={6} lg={4} md={4} controlId="form_grp_date_fin">
                            <Form.Label>Date fin:</Form.Label>
                            <Datetime
                                inputProps={{
                                    name: 'date_fin',
                                    className: 'form-control form-control-sm',
                                    readOnly: true,
                                }}
                                onChange={(e: any) => onChangeDateFin(e)}
                                value={moment(date_fin, 'YYYY-MM-DD  HH:mm:ss').format('DD/MM/YYYY')}
                                dateFormat="DD/MM/YYYY"
                                timeFormat={false}
                                closeOnSelect
                            />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} lg={4} md={4} controlId="form_grp_Client">
                            <Form.Label>Paiement:</Form.Label>
                            <Form.Select
                                size="sm"
                                name="selectedPaiementEtat"
                                value={selectedStatus}
                                onChange={(e) => {
                                    const {
                                        target: { value },
                                    } = e;
                                    setSelectedStatus(value);
                                    handlePageNFilterChange(
                                        1,
                                        JSON.stringify({
                                            dDebut: date_debut,
                                            dFin: date_fin,
                                            status: value,
                                        }),
                                    );
                                }}
                                placeholder="Selectionner une etat de paiement"
                            >
                                <option value="">Tous</option>
                                <option value="paid">Payée</option>
                                <option value="not-paid">Non Payée</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                </div>
            </Collapser>
            <TableTemplate
                columns={tableHeaders}
                data={factures.items}
                options={{
                    pagination: true,
                    showGlobalFilter: true,
                    debugData: false,
                    showFiltersRowByColumn: true,
                    rowSelecting: false,
                    hiddenColumns: ['userId'],
                }}
            >
                <Row>
                    <Col className="d-flex flex-col justify-content-end align-items-end">
                        <Paginator
                            defaultPage={activePage}
                            totalPages={Math.ceil(factures.count / TOTAL_DATA_PER_PAGE)}
                            callback={(e_page) =>
                                handlePageNFilterChange(
                                    e_page,
                                    JSON.stringify({
                                        dDebut: date_debut,
                                        dFin: date_fin,
                                        status: selectedStatus,
                                    }),
                                )
                            }
                        />
                    </Col>
                </Row>
            </TableTemplate>
            <CustomModal
                title="Imprimer facture"
                size="lg"
                show={showFacturationModal}
                handleClose={handleCloseFacture}
            >
                {currentTransfertFacture ? <FacturePrint facturation={currentTransfertFacture!} /> : <></>}
            </CustomModal>
            <CustomModal title="Transferts details" size="lg" show={showDetails} handleClose={closeDetailsModals}>
                {currentTransfertFacture ? <TransfertFactureDetails facture={currentTransfertFacture!} /> : <></>}
            </CustomModal>
            <CustomModal
                title="facture paiement"
                size="lg"
                show={showPaiementDetails}
                handleClose={closePaiementDetailsModals}
            >
                {currentTransfertFacture ? (
                    <TransfertFacturePaiementForm
                        transfertFacture={currentTransfertFacture!}
                        setTransfertFacture={setCurrentTransfertFacture}
                        enablePaymentForm={enablePaymentForm}
                        setList={setFactures}
                        close={() => closePaiementDetailsModals()}
                    />
                ) : (
                    <></>
                )}
            </CustomModal>
        </>
    );
};

export default FacturesList;
