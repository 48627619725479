import React from 'react';
import { faDolly, faShippingFast, faTruck, faTruckLoading, faBan, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-bootstrap';

import { colorVariant } from 'components/UI/etatCommandeBadge';

type IconsProps = { id: number; classNames?: string };

const Icons: React.FC<IconsProps> = ({ id, classNames }: IconsProps) => {
    let icon = faDolly;
    switch (id) {
        case 1:
            icon = faDolly;
            break;
        case 2:
            icon = faTruckLoading;
            break;
        case 3:
            icon = faTruck;
            break;
        case 4:
            icon = faShippingFast;
            break;
        case 5:
            icon = faTimes;
            break;
        case 6:
            icon = faBan;
            break;
    }
    return <FontAwesomeIcon className={classNames} icon={icon} />;
};

type Props = { data: any[]; showOnly?: number[] };
const EtatCommandesDashboard: React.FC<Props> = ({ data, showOnly }: Props) => {
    return (
        <Row>
            {data
                .filter((d: any) => (showOnly?.length! > 0 ? showOnly?.includes(d.obj.idEtatCommande) : true))
                .map((d: any) => (
                    <Col key={d.obj.idEtatCommande} className="mt-1">
                        <div className="d-flex align-items-center text-primary p-2 border border-primary h-100">
                            <Icons id={d.obj.idEtatCommande} classNames="me-3 w-30px" />
                            <div className="flex-grow-1">
                                <a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bolder lh-0">
                                    {d.count}
                                </a>
                                <span className="text-gray-400 fw-bold d-block fs-6">
                                    <span className={`text-${colorVariant(d.obj.etatCommande)}`}>
                                        {d.obj.etatCommande}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </Col>
                ))}
        </Row>
    );
};
EtatCommandesDashboard.defaultProps = { data: [], showOnly: [] };

export default EtatCommandesDashboard;
