import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import dayjs from 'dayjs';

import { readImageTobase64 } from 'utils/helpers';
import ImageElement from 'components/UI/imageElement';
import FactureLivreurInterface, { FactureLivreurPaiementInterface } from 'types/interfaces/factureLivreur.interface';
import FileInterface from 'types/interfaces/file.interface';
import { putValidatePaiement } from 'services/factureLivreur.service';
import { getFileById } from 'services/file.service';
import { toast } from 'react-toastify';
import { getStatusTranslation } from 'pages/FactureLivreur/components/CommandeTarifs';

type Props = {
    current: FactureLivreurInterface;
    flp: FactureLivreurPaiementInterface;
    setCurrent: Dispatch<SetStateAction<FactureLivreurInterface | null>>;
    setList: Dispatch<SetStateAction<{ items: FactureLivreurInterface[]; count: number }>>;
    close: () => void;
};

const PaiementAction: React.FC<Props> = ({ setCurrent, setList, current, flp, close }: Props) => {
    const [spinIt, setSpinIt] = useState<boolean>(false);
    const [file, setFile] = useState<FileInterface | null>(null);

    const submit = async (status: string) => {
        const values = {
            idFactureLivreur: flp.idFactureLivreur,
            idFactureLivreurPaiement: flp.idFactureLivreurPaiement,
            status,
        };
        try {
            setSpinIt(true);
            const response = await putValidatePaiement(values);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Action est effectuée avec succées');
                setCurrent(resultData);
                setList((factures: { items: FactureLivreurInterface[]; count: number }) => {
                    const facturesData = [...factures.items];
                    const factureIndex = facturesData.findIndex(
                        (el: FactureLivreurInterface) => el.idFactureLivreur == resultData.idFactureLivreur,
                    );
                    facturesData[factureIndex] = resultData;
                    return { items: facturesData, count: factures.count };
                });
                setSpinIt(false);
                close();
            }
        } catch (Exception) {
        } finally {
            setSpinIt(false);
        }
    };

    useEffect(() => {
        if (flp.idFile) {
            getFileById(flp.idFile!).then((response: any) => {
                const { data } = response;
                setFile(data);
            });
        }
    }, [flp.idFile]);
    return (
        <>
            <h5>Paiement</h5>
            <Row>
                <Col md={{ span: 6, offset: 3 }}>
                    Date : <b>{dayjs(flp.paimentDate).format('DD/MM/YYYY HH:mm:ss')}</b>
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 6, offset: 3 }}>
                    Total/ Payé/ Reste : <b>{current.montantTotal} DH</b>/ <b>{current.montantPaid} DH</b>/{' '}
                    <b>{current.montantRest} DH</b>
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 6, offset: 3 }}>
                    Montant payé : <b>{flp.montant} DH</b>
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 6, offset: 3 }}>
                    Etat : <b>{getStatusTranslation(flp.status)}</b>
                </Col>
            </Row>
            {flp.idFile! && (
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                        {file ? (
                            <div className="text-center my-3">
                                <ImageElement file={readImageTobase64(file?.fileImage)} alt="file recu" fluid />
                            </div>
                        ) : (
                            <div className="text-center">
                                <Spinner animation="border" size="sm" />
                            </div>
                        )}
                    </Col>
                </Row>
            )}
            {flp.status === 'Encours' && (
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                        <div className="d-flex justify-content-between mt-2">
                            <Button variant="success" onClick={() => submit('yes')} disabled={spinIt}>
                                Confirmer
                            </Button>
                            <Button variant="warning" onClick={() => submit('no')} disabled={spinIt}>
                                Rejeter
                            </Button>
                        </div>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default PaiementAction;
