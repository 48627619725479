import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import { buildQueryFromParamsObj } from 'utils/helpers';
const apiEndPoint = `${apiUrl}/dashboard`;

export const getDashboardStates = (): Promise<any[] | any> => {
    return http.get(`${apiEndPoint}/states`);
};

export const getDashboardCharts = (
    year?: number,
    idClient?: number | string,
    idLivreur?: number | string,
): Promise<any[] | any> => {
    const paramObject = { year, idClient, idLivreur };
    const query = buildQueryFromParamsObj(paramObject);
    return http.get(`${apiEndPoint}/charts${query}`);
};
