import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { Col, Form, Row, Button, OverlayTrigger, Tooltip, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckDouble, faEdit, faPrint } from '@fortawesome/free-solid-svg-icons';
import { ROLES as RolesMap } from 'constantes/permission-maps';

import { useQueryMeMo, isEmptyObject, buildUrlQueryParams, b64_to_utf8, utf8_to_b64, DisplayName } from 'utils/helpers';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';

import TableTemplate from 'components/shared/datatable/tableTemplate';

import Paginator from 'components/UI/paginator';

import { getLivreursByFournisseur } from 'services/user.service';

import { getDemandeRetourLivreurs } from 'services/demandeRetourLivreur.service';

import TitleElement from 'components/UI/titleElement';
import PermissionsGate from 'hoc/permissionsGate';
import CustomModal from 'components/UI/customModal';
import DemandeRetourLivreurInterface from 'types/interfaces/demandeRetourLivreur.interface';
import UserInterface from 'types/interfaces/user.interface';
import DemandeRetourLivreurSection from 'pages/Stock/components/DemandeRetourLiveurSection';
import PrintDemandeRetour from 'pages/Stock/components/PrintDemandeRetour';
import Scan from 'pages/Stock/components/Scan';

const TOTAL_DATA_PER_PAGE = 100;

const DemandeRetourLivreurPage: React.FC = () => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    const [demandes, setDemandes] = useState<{ items: DemandeRetourLivreurInterface[]; count: number }>({
        items: [],
        count: 0,
    });

    const [livreurs, setLivreurs] = useState<UserInterface[]>([]);
    const [selectedLivreur, setSelectedLivreur] = useState<number | null>(null);
    const [selectedStatus, setSelectedStatus] = useState<string>('');

    const [activePage, setActivePage] = useState<number>(1);

    const query = useQueryMeMo();
    const history = useNavigate();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [checkedDemandes, setCheckedDemandes] = useState<number[]>([]);
    const [checkedPrintingDemandes, setCheckedPrintingDemandes] = useState<number[]>([]);
    const [showPrintModal, setShowPrintModal] = useState<boolean>(false);

    const handlePrintDemandesClose = () => {
        setShowPrintModal(false);
        setCheckedPrintingDemandes([]);
    };

    const handlePrintDemandesOpen = () => {
        setShowPrintModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
        setCheckedDemandes([]);
        setCheckedPrintingDemandes([]);
    };

    const handleModalOpen = () => {
        setShowModal(true);
    };

    const onCheckPrintingAllDemandes = useCallback(() => {
        setCheckedPrintingDemandes((oldState) => {
            if (oldState.length === demandes.items.length) {
                return [];
            }

            return demandes.items.map((el: DemandeRetourLivreurInterface) => el.idDemandeRetourLivreur);
        });
    }, [demandes.items]);

    const onCheckPrintingDemandesrow = useCallback(
        (row: DemandeRetourLivreurInterface) => {
            setCheckedPrintingDemandes((oldState) => {
                const idx = oldState.findIndex((el: number) => el === row.idDemandeRetourLivreur);

                if (idx === -1) {
                    return oldState.concat(row.idDemandeRetourLivreur);
                }

                const newState = [...oldState];
                newState.splice(idx, 1);

                return newState;
            });
        },
        [demandes.items],
    );

    const onCheckAllDemandes = useCallback(() => {
        setCheckedDemandes((oldState) => {
            if (oldState.length === demandes.items.length) {
                return [];
            }

            return demandes.items.map((el: DemandeRetourLivreurInterface) => el.idDemandeRetourLivreur);
        });
    }, [demandes.items]);

    const onCheckDemandesrow = useCallback(
        (row: DemandeRetourLivreurInterface) => {
            setCheckedDemandes((oldState) => {
                const idx = oldState.findIndex((el: number) => el === row.idDemandeRetourLivreur);

                if (idx === -1) {
                    return oldState.concat(row.idDemandeRetourLivreur);
                }

                const newState = [...oldState];
                newState.splice(idx, 1);

                return newState;
            });
        },
        [demandes.items],
    );

    const loadDemandes = (total: number, page: number, status: string, idLivreur?: string) => {
        getDemandeRetourLivreurs(total, page, status, +idLivreur!)
            .then((response: any) => {
                const { data } = response;
                setDemandes({
                    items: data.items,
                    count: data.count,
                });
            })
            .catch();
    };

    const handlePageNFilterChange = (activePage: number, searchedData?: string) => {
        const urlObject: any = {};
        if (activePage > 1) urlObject['page'] = activePage;
        if (searchedData) {
            const parsedObject = JSON.parse(searchedData);
            Object.keys(parsedObject).forEach((key) => {
                if (parsedObject[key as any]) {
                    if (key === 'livreur' && parsedObject[key as any])
                        urlObject[key] = utf8_to_b64(parsedObject[key as any]);
                    else urlObject[key] = parsedObject[key as any];
                }
            });
        }
        history(isEmptyObject(urlObject) ? window.location.pathname : `?${buildUrlQueryParams(urlObject)}`);
    };

    useEffect(() => {
        document.title = `Demandes de retour (livreurs) - DS`;
    }, []);

    useEffect(() => {
        if (
            contextUser &&
            contextUser?.userData?.userroles?.some(
                (d) =>
                    d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                    d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE,
            )
        ) {
            let idLivreur = -1;
            getLivreursByFournisseur(contextUser.userData?.idFournisseur!).then(
                (response: AxiosResponse<UserInterface[] | any>) => {
                    const { data } = response;
                    if (data.length > 0) idLivreur = data[0].idUser;
                    setSelectedLivreur(idLivreur);
                    setLivreurs(data);
                },
            );
        }
    }, [contextUser]);

    useEffect(() => {
        setCheckedDemandes([]);
        setCheckedPrintingDemandes([]);
        const page = query.get('page');
        const livreur = query.get('livreur');
        const status = query.get('status') ?? '';
        const selectedPage = page ? +page : 1;
        const selectedLivreur = livreur ? b64_to_utf8(livreur) : '';
        setActivePage(selectedPage);
        setSelectedStatus(status);
        setSelectedLivreur(+selectedLivreur!);
        const loadData = async () => {
            loadDemandes(TOTAL_DATA_PER_PAGE, selectedPage, status, selectedLivreur);
        };
        loadData();
    }, [query, selectedLivreur]);

    const columns = useMemo(() => {
        const baseColumns = [
            {
                Header: 'Demande',
                accessor: 'codeDemande',
                Cell: ({ row }: any) => <span className="my-xsm-font">{row.original.codeDemande}</span>,
            },
            {
                Header: 'CMD-Ref',
                accessor: 'commande.ref',
                Cell: ({ row }: any) => <>{row.original.commande ? row.original.commande.ref : '-'}</>,
            },
            {
                Header: 'Client',
                accessor: 'commande.client.raisonSocial',
            },
            {
                Header: 'Code',
                accessor: 'commande.code',
            },
            {
                Header: 'Date demande',
                accessor: 'dateCreation',
                Cell: ({ row }: any) => (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="t2">Créée par {DisplayName(row.original.userFournisseur)}</Tooltip>}
                    >
                        <div>{moment(row.original.dateCreation).format('DD/MM/YYYY HH:mm')}</div>
                    </OverlayTrigger>
                ),
                disableFilters: true,
            },
            {
                Header: 'Etat',
                accessor: 'status',
            },
            {
                Header: 'Traité par livreur',
                accessor: 'dateActionLivreur',
                Cell: ({ row }: any) =>
                    row.original.dateActionLivreur ? (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="t1">Traitée par {DisplayName(row.original.livreur)}</Tooltip>}
                        >
                            <div>{moment(row.original.dateActionLivreur).format('DD/MM/YYYY HH:mm')}</div>
                        </OverlayTrigger>
                    ) : (
                        '-'
                    ),
                disableFilters: true,
            },
            {
                Header: 'Date fin',
                accessor: 'dateFin',
                Cell: ({ row }: any) =>
                    row.original.dateFin ? (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="t1">Traitée par {DisplayName(row.original.confirmer)}</Tooltip>}
                        >
                            <div>{moment(row.original.dateFin).format('DD/MM/YYYY HH:mm')}</div>
                        </OverlayTrigger>
                    ) : (
                        '-'
                    ),
                disableFilters: true,
            },
            ...(['En attente'].includes(selectedStatus)
                ? [
                      {
                          Header: () => (
                              <>
                                  <InputGroup size="sm" className="justify-content-center">
                                      <Button
                                          variant="outline-dark"
                                          size="sm"
                                          onClick={() => onCheckPrintingAllDemandes()}
                                      >
                                          <FontAwesomeIcon
                                              icon={
                                                  checkedPrintingDemandes.length === demandes.items.length &&
                                                  checkedPrintingDemandes.length !== 0
                                                      ? faCheck
                                                      : faCheckDouble
                                              }
                                              size="xs"
                                          />
                                      </Button>
                                      {checkedPrintingDemandes.length > 0 && (
                                          <>
                                              <Button
                                                  variant="outline-secondary"
                                                  size="sm"
                                                  onClick={() => handlePrintDemandesOpen()}
                                              >
                                                  <FontAwesomeIcon icon={faPrint} size="xs" />
                                              </Button>
                                          </>
                                      )}
                                  </InputGroup>
                              </>
                          ),
                          accessor: 'checkbox2',
                          Cell: ({ row }: any) => (
                              <>
                                  <input
                                      type="checkbox"
                                      checked={checkedPrintingDemandes.includes(row.original.idDemandeRetourLivreur)}
                                      onChange={() => onCheckPrintingDemandesrow(row.original)}
                                  />
                              </>
                          ),
                          disableSortBy: true,
                          disableFilters: true,
                      },
                  ]
                : []),
        ];

        // Conditional column for checkbox
        if (
            (selectedStatus === 'En attente' &&
                contextUser?.userData?.userroles?.some(
                    (d) =>
                        d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                        d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE ||
                        d.role.nomRole === RolesMap.FOURNISSEUR_LIVREUR,
                )) ||
            (selectedStatus === 'Traitée' &&
                contextUser?.userData?.userroles?.some(
                    (d) =>
                        d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                        d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE,
                ))
        ) {
            return [
                {
                    Header: () => (
                        <input
                            type="checkbox"
                            checked={checkedDemandes.length === demandes.items.length && checkedDemandes.length !== 0}
                            onChange={onCheckAllDemandes}
                        />
                    ),
                    accessor: 'checkbox',
                    Cell: ({ row }: any) => (
                        <input
                            type="checkbox"
                            checked={checkedDemandes.includes(row.original.idDemandeRetourLivreur)}
                            onChange={() => onCheckDemandesrow(row.original)}
                        />
                    ),

                    disableSortBy: true,
                    disableFilters: true,
                },
                ...baseColumns,
            ];
        }

        return baseColumns;
    }, [
        demandes,
        checkedDemandes,
        onCheckAllDemandes,
        onCheckDemandesrow,
        selectedStatus,
        contextUser,
        checkedPrintingDemandes,
        onCheckPrintingAllDemandes,
        onCheckPrintingDemandesrow,
    ]);

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        {
            active: true,
            text: 'Demandes de retour',
        },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Demande de retour au livreurs
                </TitleElement>
                <div>
                    <Scan
                        checkRow={onCheckDemandesrow}
                        list={demandes.items}
                        checkedRows={checkedDemandes}
                        showScanButton={
                            !!(
                                (selectedStatus === 'En attente' &&
                                    contextUser?.userData?.userroles?.some(
                                        (d) =>
                                            d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                                            d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE ||
                                            d.role.nomRole === RolesMap.FOURNISSEUR_LIVREUR,
                                    )) ||
                                (selectedStatus === 'Traitée' &&
                                    contextUser?.userData?.userroles?.some(
                                        (d) =>
                                            d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                                            d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                    ))
                            )
                        }
                    />
                </div>
            </div>
            <Row className="mb-2 holdit_notEffect">
                {contextUser?.userData?.userroles?.some(
                    (d) =>
                        d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                        d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE,
                ) && (
                    <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_Livreur">
                        <Form.Label>Livreur:</Form.Label>
                        <Form.Select
                            size="sm"
                            name="selectedLivreur"
                            value={selectedLivreur!}
                            onChange={(e: any) => {
                                const {
                                    target: { value },
                                } = e;
                                setSelectedLivreur(+value);
                                handlePageNFilterChange(
                                    1,
                                    JSON.stringify({
                                        livreur: value,
                                        status: selectedStatus,
                                    }),
                                );
                            }}
                            placeholder="Selectionner un livreur"
                        >
                            {livreurs.map((d: UserInterface) => (
                                <option key={d.idUser} value={d.idUser}>
                                    {DisplayName(d)}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                )}
                <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_status">
                    <Form.Label>Etat:</Form.Label>
                    <Form.Select
                        size="sm"
                        name="selectedStatus"
                        value={selectedStatus!}
                        onChange={(e: any) => {
                            const {
                                target: { value },
                            } = e;
                            setSelectedStatus(value);
                            handlePageNFilterChange(
                                1,
                                JSON.stringify({
                                    livreur: selectedLivreur,
                                    status: value,
                                }),
                            );
                        }}
                        placeholder="Selectionner une etat"
                    >
                        <option value="">Tous</option>
                        <option value="En attente">En attente</option>
                        <option value="Traitée">Traitée</option>
                        <option value="Validée">Validée</option>
                        <option value="Annulée">Annulée</option>
                    </Form.Select>
                </Form.Group>

                {checkedDemandes.length > 0 && (
                    <Col className="align-self-center d-flex justify-content-end">
                        <PermissionsGate
                            scopes={
                                selectedStatus === 'En attente'
                                    ? [
                                          RolesMap.FOURNISSEUR_ADMIN,
                                          RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                          RolesMap.FOURNISSEUR_LIVREUR,
                                      ]
                                    : [RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_RECEPTIONISTE]
                            }
                            strict
                        >
                            <Button
                                variant="outline-secondary"
                                className="button-input-group-effect gap-5marginpx"
                                id="basic-addon1"
                                onClick={() => handleModalOpen()}
                            >
                                <FontAwesomeIcon icon={faEdit} size="xs" style={{ color: '#337ab7' }} />
                            </Button>
                        </PermissionsGate>
                    </Col>
                )}
            </Row>
            <TableTemplate
                columns={columns}
                data={demandes.items}
                options={{
                    pagination: true,
                    showGlobalFilter: true,
                    debugData: false,
                    showFiltersRowByColumn: true,
                    rowSelecting: false,
                    hiddenColumns: ['userId'],
                    autoGrouping: { columnId: 'codeDemande' },
                }}
            >
                <Row>
                    <Col className="d-flex flex-col justify-content-end align-items-end">
                        <Paginator
                            defaultPage={activePage}
                            totalPages={Math.ceil(demandes.count / TOTAL_DATA_PER_PAGE)}
                            callback={(e_page) =>
                                handlePageNFilterChange(
                                    e_page,
                                    JSON.stringify({
                                        livreur: selectedLivreur,
                                        status: selectedStatus,
                                    }),
                                )
                            }
                        />
                    </Col>
                </Row>
            </TableTemplate>
            <CustomModal title="Demande retour livreur" size="lg" show={showModal} handleClose={handleModalClose}>
                <DemandeRetourLivreurSection
                    checkedDemandes={checkedDemandes}
                    close={handleModalClose}
                    status={selectedStatus}
                    setDemandes={setDemandes}
                />
            </CustomModal>
            <CustomModal
                title="Imprimer les demandes"
                size="lg"
                show={showPrintModal}
                handleClose={handlePrintDemandesClose}
            >
                <PrintDemandeRetour
                    demandes={demandes.items.filter((item: DemandeRetourLivreurInterface) =>
                        checkedPrintingDemandes.includes(item.idDemandeRetourLivreur),
                    )}
                    colSpan={5}
                    componentHeader={() => (
                        <>
                            <th>CMD-Ref</th>
                            <th>Code</th>
                            <th>Client</th>
                            <th>Date demande</th>
                        </>
                    )}
                    componentBody={({ item }: { item: DemandeRetourLivreurInterface }) => (
                        <>
                            <td>
                                <span className="my-xsm-font">{item.commande?.ref!}</span>
                            </td>
                            <td>
                                <span className="my-xsm-font">{item.commande?.code!}</span>
                            </td>
                            <td>
                                <span className="my-xsm-font">{item.commande?.client?.raisonSocial!}</span>
                            </td>
                            <td>{moment(item.dateCreation).format('DD/MM/YYYY HH:mm')}</td>
                        </>
                    )}
                    target="livreur"
                />
            </CustomModal>
        </>
    );
};

export default DemandeRetourLivreurPage;
