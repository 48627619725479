import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { Field, FieldArray, Formik } from 'formik';
import { Button, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { TableColumnType } from 'react-bs-datatable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

import VilleInterface from 'types/interfaces/ville.interface';
import { getVilles, saveVille } from 'services/ville.service';
import RegionInterface from 'types/interfaces/region.interface';
import { getRegions } from 'services/region.service';

import CustomModal from 'components/UI/customModal';
import Datatable from 'components/shared/datatable';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import TitleElement from 'components/UI/titleElement';

const schema = yup.object({
    idVille: yup.number(),
    nomVille: yup.string().required(),
    idRegion: yup.number().required(),
    similarNames: yup
        .array()
        .of(yup.string().required())
        .test('no-commas', 'Similar names must not contain commas', (value) => {
            if (Array.isArray(value)) {
                return !value.some((name) => name?.includes(','));
            }
            return true; // if not an array, no need to check
        }),
});

const VillePage: React.FC = () => {
    const [villes, setVilles] = useState<VilleInterface[]>([]);
    const [currentVille, setCurrentVille] = useState<VilleInterface | null>(null);
    const [regions, setRegions] = useState<RegionInterface[]>([]);

    const [showFormModal, setShowFormModal] = useState<boolean>(false);
    const [formModalEditMode, setFormModalEditMode] = useState<boolean>(false);
    const [spinIt, setSpinIt] = useState<boolean>(false);

    const handleFormModalClose = () => {
        setCurrentVille(null);
        setShowFormModal(false);
    };

    const handleFormModalOpen = (editMode = false, villeData?: VilleInterface) => {
        if (villeData) setCurrentVille(villeData);
        setFormModalEditMode(editMode);
        setShowFormModal(true);
    };

    const tableHeaders: TableColumnType<any>[] = [
        {
            prop: 'nomVille',
            title: 'Nom',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'similarNames',
            title: 'Similaire',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'region.nomRegion',
            title: 'Region',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'null',
            title: 'Action',
            alignment: { horizontal: 'right' },
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: VilleInterface) => (
                <Button
                    variant="outline-secondary"
                    className="button-input-group-effect"
                    id="basic-addon1"
                    onClick={() => handleFormModalOpen(true, row)}
                >
                    <FontAwesomeIcon icon={faEdit} size="xs" style={{ color: '#337ab7' }} />
                </Button>
            ),
            isFilterable: false,
            isSortable: false,
        },
    ];

    useEffect(() => {
        document.title = 'Paramétrage Regions - DS';

        getVilles()
            .then((response: AxiosResponse<VilleInterface | any>) => {
                const { data } = response;
                setVilles(data);
            })
            .catch();

        getRegions()
            .then((response: AxiosResponse<RegionInterface | any>) => {
                const { data } = response;
                setRegions(data);
            })
            .catch();
    }, []);

    const submitForm = async (villeValues: VilleInterface | any) => {
        try {
            setSpinIt(true);
            const formatedVillesValue = { ...villeValues, similarNames: villeValues.similarNames.join(',') };
            const response = await saveVille(formatedVillesValue);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Region a été enregistré avec succès');
                const villeDATA = [...villes].filter((dd) => dd.idVille !== resultData.idVille);
                villeDATA.unshift(resultData);
                setVilles(villeDATA);
                handleFormModalClose();
                setSpinIt(false);
            } else {
                toast.error("Une erreur s'est produite, veuillez verifier les champs et reessayer !");
            }
        } catch (Exception) {
            toast.error("Une erreur s'est produite, veuillez verifier les champs et reessayer !");
            setSpinIt(false);
        }
    };

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { text: 'Paramétrage' },
        { active: true, text: 'Region' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Regions
                </TitleElement>
                <Button
                    variant="outline-secondary"
                    className="button-input-group-effect"
                    id="basic-addon1"
                    onClick={() => handleFormModalOpen()}
                >
                    <FontAwesomeIcon icon={faPlus} size="xs" style={{ color: '#337ab7' }} />
                </Button>
            </div>
            <Datatable data={villes} tableColumns={tableHeaders} />
            <CustomModal
                title={`${!!formModalEditMode ? 'Edit' : 'Nouveau'} Region`}
                size="lg"
                show={showFormModal}
                handleClose={handleFormModalClose}
            >
                <div>
                    <Formik
                        validationSchema={schema}
                        onSubmit={(values) => submitForm(values)}
                        enableReinitialize={true}
                        initialValues={{
                            idVille: currentVille && !!formModalEditMode ? currentVille.idVille : undefined,
                            nomVille: currentVille && !!formModalEditMode ? currentVille.nomVille : '',
                            idRegion: currentVille && !!formModalEditMode ? currentVille.idRegion : undefined,
                            similarNames:
                                currentVille && !!formModalEditMode
                                    ? currentVille.similarNames
                                        ? currentVille.similarNames.split(',')
                                        : []
                                    : [],
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                {values.idVille && (
                                    <Form.Control type="hidden" name="idVille" value={values.idVille} readOnly={true} />
                                )}
                                <Form.Group controlId="form_grp_nom">
                                    <Form.Label>Nom</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="nomVille"
                                        placeholder="Nom"
                                        value={values.nomVille}
                                        onChange={handleChange}
                                        isInvalid={!!errors.nomVille}
                                    />
                                </Form.Group>
                                <Form.Group controlId="form_grp_region">
                                    <Form.Label>Villes</Form.Label>
                                    <Form.Select
                                        name="idRegion"
                                        value={values.idRegion}
                                        onChange={handleChange}
                                        isInvalid={!!errors.idRegion}
                                        placeholder="Selectionner une ville"
                                    >
                                        <option>Selectionner une region</option>
                                        {regions.map((d: RegionInterface) => (
                                            <option key={d.idRegion} value={d.idRegion}>
                                                {d.nomRegion}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <div className="my-2">
                                    <Row className="align-items-center mb-2">
                                        <Col md="8" lg="8" className="align-self-center">
                                            <h5>Similaires</h5>
                                        </Col>

                                        <Col md="4" lg="4" className="float-end mt-2 clearfix">
                                            <Button
                                                className="float-end"
                                                variant="outline-secondary"
                                                onClick={() => {
                                                    const ifOneEmpty = values.similarNames.filter(
                                                        (d: string) => !d || d.trim() === '',
                                                    );
                                                    if (ifOneEmpty.length > 0) return;
                                                    const updatedArr = [...values.similarNames, ''];
                                                    handleChange({
                                                        target: {
                                                            value: updatedArr,
                                                            name: 'similarNames',
                                                        },
                                                    });
                                                }}
                                            >
                                                Ajouter un neaveau similaire
                                            </Button>
                                        </Col>
                                    </Row>
                                    <FieldArray
                                        name="similarNames"
                                        render={() =>
                                            values.similarNames.map((rnData, index) => {
                                                const rnErrors =
                                                    errors.similarNames?.length && errors.similarNames[index];
                                                return (
                                                    <InputGroup key={index} className="my-1">
                                                        <Field
                                                            name={`similarNames[${index}]`}
                                                            type="string"
                                                            placeholder="Nom"
                                                            className={`form-control ${
                                                                !!rnErrors ? ' is-invalid' : ''
                                                            }`}
                                                        />
                                                        <Button
                                                            variant="outline-warning"
                                                            className="inputGrpButton dynamicJsHoverColorIcon"
                                                            onClick={() => {
                                                                const updatedArr = [...values.similarNames].filter(
                                                                    (dvr, i) => i !== index,
                                                                );

                                                                handleChange({
                                                                    target: {
                                                                        value: updatedArr,
                                                                        name: 'similarNames',
                                                                    },
                                                                });
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faMinus}
                                                                size="xs"
                                                                style={{ color: '#ff0000' }}
                                                            />
                                                        </Button>
                                                    </InputGroup>
                                                );
                                            })
                                        }
                                    />
                                </div>
                                <div className="d-grid gap-2 mt-3">
                                    <Button variant="primary" type="submit" disabled={!!spinIt}>
                                        Enregistrer
                                        {!!spinIt && (
                                            <>
                                                &nbsp; <Spinner animation="border" size="sm" />
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </CustomModal>
        </>
    );
};

export default VillePage;
