import React, { useState } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { StatusType } from 'types';
import { sendEtatCommande } from 'services/commande.service';
import CommandeInterface from 'types/interfaces/commande.interface';

type Props = {
    commandes: { items: CommandeInterface[]; count: number };
    setCommandes: (value: { items: CommandeInterface[]; count: number }) => void;
    currentCommande: CommandeInterface;
    setCurrentCommande: (value: CommandeInterface | null) => void;
    setCheckedCommandes: (value: number[]) => void;
    status: StatusType;
    buttonText: string;
    buttonVariant: string;
};

const StatusButton: React.FC<Props> = ({
    commandes,
    setCommandes,
    currentCommande,
    setCurrentCommande,
    setCheckedCommandes,
    status,
    buttonText,
    buttonVariant,
}: Props) => {
    const [spinIt, setSpinIt] = useState<boolean>(false);
    const submitStatus = async (id: number, status: StatusType) => {
        try {
            setSpinIt(true);
            const response = await sendEtatCommande(id, status);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Action est ajoutée');
                setCurrentCommande(resultData);
                setCheckedCommandes([]);
                const cmdData: CommandeInterface[] = [...commandes.items].map((dd) =>
                    dd.idCommande === resultData.idCommande ? resultData : dd,
                );
                setCommandes({ items: cmdData, count: commandes.count });
            }
        } catch (Exception) {
            const { message = undefined }: any = Exception;
            toast.error(message || 'Error occured!');
        } finally {
            setSpinIt(false);
        }
    };

    return (
        <OverlayTrigger
            trigger="focus"
            placement="top"
            overlay={
                <Popover id="popover-basic">
                    <Popover.Header>Êtes-vous sûr??</Popover.Header>
                    <Popover.Body className="text-center">
                        <Button
                            size="sm"
                            type="button"
                            variant="success"
                            onClick={() => submitStatus(currentCommande?.idCommande!, status)}
                            disabled={!!spinIt}
                        >
                            Confirmer
                        </Button>
                    </Popover.Body>
                </Popover>
            }
        >
            <Button size="sm" variant={buttonVariant} disabled={!!spinIt}>
                {buttonText}
            </Button>
        </OverlayTrigger>
    );
};

export default StatusButton;
