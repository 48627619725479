import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { Button, Col, Form, OverlayTrigger, Popover, Row, Spinner } from 'react-bootstrap';
import Datetime from 'react-datetime';
import { buildUrlQueryParams, isEmptyObject, useQueryMeMo, utf8_to_b64 } from 'utils/helpers';
import { OrderSummaryResponse } from 'types/interfaces/facture.interface';
import { createAutoCommandesFacture, getAutoCommandesFacture } from 'services/facture.service';
import TitleElement from 'components/UI/titleElement';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import TableTemplate from 'components/shared/datatable/tableTemplate';

const AutoFacture: React.FC = () => {
    const history = useNavigate();
    const query = useQueryMeMo();

    const [list, setList] = useState<OrderSummaryResponse[]>([]);
    const [date_fin, setDate_fin] = useState<any>(null);
    const [checkClients, setCheckClients] = useState<number[]>([]);

    const onCheckAllClients = useCallback(() => {
        setCheckClients((oldState) => {
            if (oldState.length === list.length) {
                return [];
            }

            return list.map((el: OrderSummaryResponse) => el.idClient);
        });
    }, [list]);

    const onCheckClientsrow = useCallback(
        (row: OrderSummaryResponse) => {
            setCheckClients((oldState) => {
                const idx = oldState.findIndex((el: number) => el === row.idClient);

                if (idx === -1) {
                    return oldState.concat(row.idClient);
                }

                const newState = [...oldState];
                newState.splice(idx, 1);

                return newState;
            });
        },
        [list],
    );

    const onChangeDateFin = (e: any) => {
        const dateValue = e.endOf('day');
        setDate_fin({
            target: {
                value: dateValue,
                name: 'date_fin',
            },
        });
        handlePageNFilterChange(
            1,
            JSON.stringify({
                dFin: dateValue.format('YYYY-MM-DD HH:mm:ss'),
            }),
        );
    };
    const handlePageNFilterChange = (activePage: number, searchedData?: string) => {
        const urlObject: any = {};
        if (activePage > 1) urlObject['page'] = activePage;
        if (searchedData) {
            const parsedObject = JSON.parse(searchedData);
            Object.keys(parsedObject).forEach((key) => {
                if (parsedObject[key as any]) {
                    if (key === 'livreur' && parsedObject[key as any])
                        urlObject[key] = utf8_to_b64(parsedObject[key as any]);
                    else urlObject[key] = parsedObject[key as any];
                }
            });
        }
        history(isEmptyObject(urlObject) ? window.location.pathname : `?${buildUrlQueryParams(urlObject)}`);
    };

    const loadOrderSummaryData = (dateFin: Date | string) => {
        getAutoCommandesFacture(dateFin)
            .then(({ data }: AxiosResponse<OrderSummaryResponse[]>) => {
                setList(data);
            })
            .catch();
    };

    useEffect(() => {
        const dFin = query.get('dFin');
        const _dateFin = (dFin ? moment(dFin) : moment().subtract(1, 'days').endOf('day')).format(
            'YYYY-MM-DD HH:mm:ss',
        );
        setDate_fin(_dateFin!);

        const loadData = async () => {
            loadOrderSummaryData(_dateFin);
        };
        loadData();
    }, [query]);
    const submit = (_: any, { setSubmitting }: FormikHelpers<any>) => {
        setSubmitting(true);
        createAutoCommandesFacture({
            beforeDate: moment(date_fin).format('YYYY-MM-DDTHH:mm:ss'),
            clientsIds: checkClients,
        })
            .then(() => {
                toast.success('Facture(s) créees avec succès.');
                setCheckClients([]);
                loadOrderSummaryData(date_fin);
            })
            .catch(() => {
                toast.error("Une erreur s'est produite");
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const tableHeaders = useMemo(
        () => [
            {
                Header: () => (
                    <input
                        type="checkbox"
                        checked={checkClients.length === list.length && checkClients.length !== 0}
                        onChange={onCheckAllClients}
                    />
                ),
                accessor: 'checkbox',
                Cell: ({ row }: any) => (
                    <>
                        <input
                            type="checkbox"
                            checked={checkClients.includes(row.original.idClient)}
                            onChange={() => onCheckClientsrow(row.original)}
                        />
                    </>
                ),
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: 'Client',
                accessor: 'clientName',
            },
            {
                Header: 'Nb commandes',
                accessor: 'numberOfOrders',
            },
            {
                Header: 'Prix totaal',
                accessor: 'totalPrice',
                Cell: ({ row: { original } }: { row: { original: OrderSummaryResponse } }) => (
                    <>{original.totalPrice} DH</>
                ),
            },
        ],
        [list, checkClients, onCheckAllClients, onCheckClientsrow],
    );

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { path: '/commandes', text: 'Commandes' },
        { active: true, text: 'Créer facture client' },
        { active: true, text: 'Auto' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Créer facture client (Auto)
                </TitleElement>

                <div className="d-flex gap-1">
                    {checkClients.length > 0 && (
                        <Formik initialValues={{}} onSubmit={submit}>
                            {({ handleSubmit, isSubmitting }) => (
                                <Form onSubmit={handleSubmit}>
                                    <OverlayTrigger
                                        trigger="focus"
                                        placement="left"
                                        overlay={
                                            <Popover id="popover-basic">
                                                <Popover.Header>Êtes-vous sûr??</Popover.Header>
                                                <Popover.Body className="text-center">
                                                    <Button
                                                        className="ms-2"
                                                        variant="outline-warning"
                                                        type="submit"
                                                        onClick={() => handleSubmit()}
                                                        disabled={isSubmitting}
                                                    >
                                                        Confirmer
                                                        {isSubmitting && (
                                                            <>
                                                                &nbsp; <Spinner animation="border" size="sm" />
                                                            </>
                                                        )}
                                                    </Button>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <Button
                                            size="sm"
                                            type="button"
                                            variant="outline-primary"
                                            disabled={isSubmitting}
                                        >
                                            Factures création
                                            {isSubmitting && (
                                                <>
                                                    &nbsp; <Spinner animation="border" size="sm" />
                                                </>
                                            )}
                                        </Button>
                                    </OverlayTrigger>
                                </Form>
                            )}
                        </Formik>
                    )}
                </div>
            </div>
            <Row className="mb-2 holdit_notEffect">
                <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_date_fin">
                    <Form.Label>Date fin:</Form.Label>
                    <Datetime
                        inputProps={{
                            name: 'date_fin',
                            className: 'form-control form-control-sm',
                            readOnly: true,
                        }}
                        onChange={(e: any) => onChangeDateFin(e)}
                        value={moment(date_fin, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')}
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                        closeOnSelect
                    />
                </Form.Group>
            </Row>
            <TableTemplate
                columns={tableHeaders}
                data={list}
                options={{
                    pagination: true,
                    showGlobalFilter: true,
                    debugData: false,
                    showFiltersRowByColumn: true,
                    rowSelecting: false,
                    hiddenColumns: ['userId'],
                }}
            />
        </>
    );
};

export default AutoFacture;
