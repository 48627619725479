import React, { useEffect, useRef, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Button, ProgressBar, OverlayTrigger, Popover, Table, Row, Col, Badge } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';

import CustomModal from 'components/UI/customModal';
import { readUploadedExcelFile } from 'services/draftCommande.service';
import { toast } from 'react-toastify';

type Props = {
    idClient?: number;
};

const ExcelUploadComponent: React.FC<Props> = ({ idClient }: Props) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [showProgressModal, setShowProgressModal] = useState<boolean>(false);
    const [showErrorDetailsModal, setShowErrorDetailsModal] = useState<boolean>(false);
    const [errorDetails, setErrorDetails] = useState<FileErrorTableProps | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    // const navigate = useNavigate();

    const openErrorModal = (errorDetails: FileErrorTableProps) => {
        setErrorDetails(errorDetails);
        setShowErrorDetailsModal(true);
    };
    const closeErrorModal = () => {
        setErrorDetails(null);
        setShowErrorDetailsModal(false);
    };

    const triggerInputFileClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];
            setSelectedFile(file);
            e.target.value = '';
        }
    };

    const handleUpload = () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('excelFile', selectedFile);

            setShowProgressModal(true);
            readUploadedExcelFile(
                formData,
                {
                    onUploadProgress: (progressEvent: any) => {
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        setUploadProgress(progress);
                    },
                },
                idClient,
            )
                .then((response: AxiosResponse<any>) => {
                    // Handle success, if needed
                    console.log('Upload successful', response);
                    toast.success('Importation des données reussie');

                    setTimeout(() => {
                        // navigate('/commandes/draft');
                        window.location.reload();
                    }, 1000);
                })
                .catch((error) => {
                    // Handle error, if needed
                    console.error('Upload failed', error);
                    if (error) {
                        if (error.data && error.details) {
                            // ...
                            toast.warning(() => (
                                <div className="d-flex flex-column justify-content-center align-items-start">
                                    <div>Il y a des erreurs dans le fichier que vous avez téléchargé.</div>

                                    <Button
                                        className="align-self-center"
                                        variant="outline-secondary"
                                        onClick={() =>
                                            openErrorModal({ excelData: error.data, errorRows: error.details })
                                        }
                                    >
                                        Consulter les détails
                                    </Button>
                                </div>
                            ));
                        } else {
                            toast.error(error);
                        }
                    } else toast.error("Une erreur s'est produite lors du traitement de votre demande.");
                })
                .finally(() => {
                    setShowProgressModal(false);
                    setSelectedFile(null);
                    // toast.info(
                    //     "Cette fonctionnalité n'est pas disponible pour le moment. Elle sera disponible prochainement.",
                    // );
                });
        }
    };

    useEffect(() => {
        if (selectedFile) {
            handleUpload();
        }
    }, [selectedFile]);

    return (
        <div>
            <input
                type="file"
                ref={fileInputRef}
                accept=".xlsx,.xlsm,.xlsb,.xltx"
                onChange={handleFileChange}
                hidden={true}
            />
            <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={UploadInfo}>
                <Button variant="outline-primary" onClick={triggerInputFileClick}>
                    <FontAwesomeIcon icon={faFileUpload} color="#2e95d3" size="lg" />
                </Button>
            </OverlayTrigger>
            <CustomModal title="Uplading Progress" show={showProgressModal} handleClose={() => void 0}>
                <>{uploadProgress > 0 && <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />}</>
            </CustomModal>
            <CustomModal
                title="Téléchargement erreurs détails!"
                show={showErrorDetailsModal}
                handleClose={closeErrorModal}
                size="lg"
                fullscreen
            >
                <>
                    {errorDetails && (
                        <FileErrorTable errorRows={errorDetails?.errorRows} excelData={errorDetails?.excelData} />
                    )}
                </>
            </CustomModal>
        </div>
    );
};

export default ExcelUploadComponent;
const UploadInfo = (
    <Popover id="popover-basic" style={{ position: 'fixed', maxWidth: '90%', maxHeight: '80%', overflowY: 'auto' }}>
        <Popover.Header>Informations</Popover.Header>
        <Popover.Body>
            <div>
                <p>Exemple de données Excel :</p>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>CodeSuivi</th>
                            <th>Destinataire</th>
                            <th>Téléphone</th>
                            <th>Ville</th>
                            <th>Adresse</th>
                            <th>Nom de produit</th>
                            <th>Quantité</th>
                            <th>Prix</th>
                            <th>Commentaire</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>BGR03238AA4S8AP</td>
                            <td>Adam</td>
                            <td>0612345678</td>
                            <td>Ben Guerir</td>
                            <td>Av M6, rue 666 n°15</td>
                            <td>PC</td>
                            <td>1</td>
                            <td>250.50</td>
                            <td>Remarque...</td>
                        </tr>
                    </tbody>
                </table>
                <p className="m-0 text-danger">Tous les champs sont obligatoires.</p>
                <p className="m-0 text-danger">CodeSuivi doit être une chaîne unique.</p>
                <p className="m-0 text-danger">
                    Le numéro de téléphone doit commencer par le code pays, soit avec le symbole `+` ou sans, ou
                    s&apos;il s&apos;agit d&apos;un numéro marocain, il peut commencer par 0.
                </p>
                <p className="m-0 text-danger">La ville doit être un nom de région ou de ville valide.</p>
                <p className="m-0 text-danger">La quantité doit être supérieur ou égal à 0</p>
                <p className="m-0 text-danger">
                    Le prix doit être supérieur ou égal à 0, s&apos;il est décimal, il doit utiliser un point.
                </p>
                <p className="m-0 text-danger">Le commentaire est obligatoire.</p>
            </div>
        </Popover.Body>
    </Popover>
);

interface ExcelItem {
    codeSuivi: string;
    destinataire: string;
    telephone: string;
    ville: string | null;
    adresse: string;
    nomProduit: string;
    quantite: number;
    prix: number;
    commentaire: string;
}

interface ErrorRow {
    [key: number]: { item1: number; item2: number }[];
}

interface FileErrorTableProps {
    excelData: { item1: number; item2: ExcelItem }[];
    errorRows: ErrorRow;
}

const FileErrorTable: React.FC<FileErrorTableProps> = ({ excelData, errorRows }) => {
    const getErrorClass = (rowIndex: number, columnIndex: number): string => {
        if (errorRows && errorRows[rowIndex]) {
            const columnErrors = errorRows[rowIndex].filter((error) => error.item1 === columnIndex);
            if (columnErrors.length > 0) {
                const errorStatuses = columnErrors.map((error) => error.item2);
                if (errorStatuses.includes(400)) {
                    return 'bg-warning';
                } else if (errorStatuses.includes(406)) {
                    return 'bg-warning fw-bolder text-dark';
                } else if (errorStatuses.includes(404)) {
                    return 'bg-warning fw-bolder text-danger';
                } else if (errorStatuses.includes(500)) {
                    return 'bg-danger fw-bolder text-warning';
                }
            }
        }
        return '';
    };

    return (
        <>
            <Legend />
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Line</th>
                        <th>CodeSuivi</th>
                        <th>Destinataire</th>
                        <th>Téléphone</th>
                        <th>Ville</th>
                        <th>Adresse</th>
                        <th>Nom de produit</th>
                        <th>Quantité</th>
                        <th>Prix</th>
                        <th>Commentaire</th>
                    </tr>
                </thead>
                <tbody>
                    {excelData.map(({ item1: index, item2: item }) => (
                        <tr key={index}>
                            <td className="fw-bolder">{index}</td>
                            <td className={`${getErrorClass(index, 1)}`}>{item.codeSuivi}</td>
                            <td className={`${getErrorClass(index, 2)}`}>{item.destinataire}</td>
                            <td className={`${getErrorClass(index, 3)}`}>{item.telephone}</td>
                            <td className={`${getErrorClass(index, 4)}`}>{item.ville}</td>
                            <td className={`${getErrorClass(index, 5)}`}>{item.adresse}</td>
                            <td className={`${getErrorClass(index, 6)}`}>{item.nomProduit}</td>
                            <td className={`${getErrorClass(index, 7)}`}>{item.quantite}</td>
                            <td className={`${getErrorClass(index, 8)}`}>{item.prix}</td>
                            <td className={`${getErrorClass(index, 9)}`}>{item.commentaire}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
};

const Legend: React.FC = () => {
    return (
        <div>
            <Row className="justify-content-center gap-2">
                <Col xs="auto">
                    <div className="d-flex align-items-center">
                        <Badge bg="warning" className="me-2">
                            Requis
                        </Badge>
                    </div>
                </Col>
                <Col xs="auto">
                    <div className="d-flex align-items-center">
                        <Badge bg="warning" text="dark" className="me-2">
                            Données invalides ou format incorrect
                        </Badge>
                    </div>
                </Col>
                <Col xs="auto">
                    <div className="d-flex align-items-center">
                        <Badge bg="warning" text="danger" className="me-2">
                            Données invalides ou introuvables
                        </Badge>
                    </div>
                </Col>
                <Col xs="auto">
                    <div className="d-flex align-items-center">
                        <Badge bg="danger" text="warning" className="me-2">
                            Non paramétré
                        </Badge>
                    </div>
                </Col>
            </Row>
            <hr />
        </div>
    );
};
