import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import ProduitInterface from 'types/interfaces/produit.interface';

const apiEndPoint = `${apiUrl}/produits`;

export const getProduitByClientId = (id: number): Promise<ProduitInterface[] | any> => {
    return http.get(`${apiEndPoint}/client/${id}`);
};

export const saveProduit = (produit: ProduitInterface) => {
    const body = {
        ...produit,
    };
    if (produit.idProduit) {
        return http.put(`${apiEndPoint}/${produit.idProduit}`, body);
    }
    return http.post(`${apiEndPoint}`, body);
};

export const getProduitStock = (total: number, page: number, idClient?: number | string): Promise<any> => {
    const query = `?page=${page}&total=${total}${idClient ? '&idClient=' + idClient : ''}`;
    return http.get(`${apiEndPoint}/stock${query}`);
};
