import React from 'react';

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
        <>
            <input className="form-check form-check-sm" type="checkbox" ref={resolvedRef} {...rest} />
        </>
    );
});

export default IndeterminateCheckbox;
