import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { TableColumnType } from 'react-bs-datatable';
import { Col, Form, Row, Button, Spinner, Badge, Tabs, Tab, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import Datetime from 'react-datetime';
import { faEdit, faPlus, faCheck, faX, faClock, faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Formik } from 'formik';

import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import Datatable from 'components/shared/datatable';
import Paginator from 'components/UI/paginator';
import { buildUrlQueryParams, isEmptyObject, useQueryMeMo, DisplayName, utf8_to_b64, b64_to_utf8 } from 'utils/helpers';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import ColisInterface from 'types/interfaces/colis.interface';
import ClientInterface from 'types/interfaces/client.interface';
import UserInterface from 'types/interfaces/user.interface';
import { getClientsByIdFournisseur } from 'services/client.service';
import { getLivreursByFournisseur } from 'services/user.service';
import { getConsultationColis, envoiColisList, ramasseColisList } from 'services/colis.service';
import ClockInput from 'components/shared/clockInput';
import TitleElement from 'components/UI/titleElement';
import Collapser from 'components/UI/collapser';
import ColisProduitEnvoyeInterface from 'types/interfaces/colisProduitEnvoye.interface';

export const schema = yup.object({
    ids: yup.array().of(yup.number().required()).min(1).required(),
    idLivreur: yup.number().required(),
    remarque: yup.string(),
});

const TOTAL_DATA_PER_PAGE = 100;

const ColisIndexPage: React.FC = () => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    const [colis, setColis] = useState<{ items: ColisInterface[]; count: number }>({
        items: [],
        count: 0,
    });
    const [livreurs, setLivreurs] = useState<UserInterface[]>([]);
    const [clients, setClients] = useState<ClientInterface[]>([]);
    const [selectedClient, setSelectedClient] = useState<string | null>(null);
    const [selectedEtat, setSelectedEtat] = useState<string>('');
    const [checkedColis, setCheckedColis] = useState<number[]>([]);

    const [date_debut, setDate_debut] = useState<any>(null);
    const [date_fin, setDate_fin] = useState<any>(null);
    const [activePage, setActivePage] = useState<number>(1);
    const [spinIt, setSpinIt] = useState<boolean>(false);
    const query = useQueryMeMo();
    const history = useNavigate();

    const onChangeDateDebut = (e: any) => {
        setDate_debut(e);
        handlePageNFilterChange(
            1,
            JSON.stringify({
                status: selectedEtat,
                client: selectedClient,
                dDebut: e.format('YYYY-MM-DD HH:mm:ss'),
                dFin: date_fin,
            }),
        );
    };

    const onChangeDateFin = (e: any) => {
        setDate_fin(e);
        handlePageNFilterChange(
            1,
            JSON.stringify({
                status: selectedEtat,
                client: selectedClient,
                dDebut: date_debut,
                dFin: e.format('YYYY-MM-DD HH:mm:ss'),
            }),
        );
    };

    const loadColis = (
        total: number,
        page: number,
        idClient?: string,
        dateDebut?: Date | string,
        dateFin?: Date | string,
        status?: string | null,
    ) => {
        getConsultationColis(total, page, idClient, dateDebut, dateFin, status)
            .then((response: AxiosResponse<ColisInterface[] | any>) => {
                const { data } = response;
                setColis({
                    items: data.items,
                    count: data.count,
                });
            })
            .catch();
    };

    const handlePageNFilterChange = (activePage: number, searchedData?: string) => {
        setCheckedColis([]);
        const urlObject: any = {};
        if (activePage > 1) urlObject['page'] = activePage;
        if (searchedData) {
            const parsedObject = JSON.parse(searchedData);
            Object.keys(parsedObject).forEach((key) => {
                if (parsedObject[key as any]) {
                    if (key === 'client' && parsedObject[key as any])
                        urlObject[key] = utf8_to_b64(parsedObject[key as any]);
                    else urlObject[key] = parsedObject[key as any];
                }
            });
        }
        history(isEmptyObject(urlObject) ? window.location.pathname : `?${buildUrlQueryParams(urlObject)}`);
    };

    const onCheckAllColis = useCallback(() => {
        setCheckedColis((oldState) => {
            if (oldState.length === colis.items.length) {
                return [];
            }

            return colis.items
                .filter(
                    (el: ColisInterface) =>
                        (!el.idRamassage && el.aRecuperer == true && selectedEtat === 'envoye') ||
                        selectedEtat === 'nonenvoye',
                )
                .map((el: ColisInterface) => el.idColis);
        });
    }, [colis.items]);

    const onCheckColis = useCallback(
        (row: ColisInterface) => {
            setCheckedColis((oldState) => {
                const idx = oldState.findIndex((el: number) => el === row.idColis);
                if (idx === -1) {
                    if (
                        (!!(row as any)['ramassage.idRamassage'] || row.aRecuperer === false) &&
                        selectedEtat === 'envoye'
                    )
                        return oldState;
                    return oldState.concat(row.idColis);
                }

                const newState = [...oldState];
                newState.splice(idx, 1);

                return newState;
            });
        },
        [colis.items],
    );

    useEffect(() => {
        const page = query.get('page');
        const status = query.get('status');
        const client = query.get('client');
        const dDebut = query.get('dDebut');
        const dFin = query.get('dFin');
        const currentPage = page ? +page : 1;
        const currentEtat = status ? status : '';
        const _dateDebut = (dDebut ? moment(dDebut) : moment().subtract(3, 'days')).format('YYYY-MM-DD HH:mm:ss');
        const _dateFin = (dFin ? moment(dFin) : moment().endOf('day')).format('YYYY-MM-DD HH:mm:ss');
        const currentClient =
            contextUser && contextUser.userData.type === 'Client' ? '' : client ? b64_to_utf8(client) : '';
        setActivePage(currentPage);
        setSelectedEtat(currentEtat);
        setSelectedClient(currentClient!);
        setDate_debut(_dateDebut!);
        setDate_fin(_dateFin!);
        const loadData = async () => {
            loadColis(TOTAL_DATA_PER_PAGE, currentPage, currentClient, _dateDebut, _dateFin, currentEtat);
        };
        loadData();
    }, [contextUser, query, date_debut, date_fin, selectedClient, selectedEtat]);

    useEffect(() => {
        if (contextUser && contextUser.userData.type === 'Fournisseur') {
            getClientsByIdFournisseur(contextUser.userData.idFournisseur!)
                .then((response: AxiosResponse<ClientInterface[] | any>) => {
                    const { data } = response;
                    setClients(data);
                })
                .catch();
            getLivreursByFournisseur(contextUser.userData.idFournisseur!)
                .then((response: AxiosResponse<ClientInterface[] | any>) => {
                    const { data } = response;
                    setLivreurs(data);
                })
                .catch();
        }
    }, [contextUser]);

    const submitEnvoiColis = async () => {
        setSpinIt(true);
        try {
            setSpinIt(true);
            const response = await envoiColisList(checkedColis);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Colis envoyés avec succès');
                const colisData = [...colis.items].filter((dd) => !resultData.includes(dd.idColis));
                setColis({ items: colisData, count: colis.count - resultData.length });
                setSpinIt(false);
            }
        } catch (Exception) {
            setSpinIt(false);
        }

        setSpinIt(false);
    };
    const submitRamassageColis = async (ramassageValues: any) => {
        try {
            setSpinIt(true);
            const response = await ramasseColisList(ramassageValues);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Colis Ramassé(s) avec succès');
                // console.log(resultData)
                const colisData = [...colis.items].map((dd) => {
                    const colisObject = resultData.find((r: any) => r.idColis.toString() === dd.idColis.toString());
                    if (!colisObject) return dd;
                    return {
                        ...dd,
                        idRamassage: colisObject?.idRamassage!,
                        ramassage: {
                            idRamassage: colisObject?.idRamassage!,
                            idLivreur: ramassageValues.idLivreur,
                            dateAffectation: new Date(),
                            etatRamassage: false,
                            remarque: '',
                        },
                    };
                });
                setColis({ items: colisData, count: colis.count - resultData.length }); // fix data sync in view
                setCheckedColis([]);
                setSpinIt(false);
            }
        } catch (Exception) {
            setSpinIt(false);
        }

        setSpinIt(false);
    };

    const tableHeaders: TableColumnType<any>[] = useMemo(
        () => [
            ...((contextUser?.userData.type === 'Fournisseur' && selectedEtat === 'envoye') ||
            selectedEtat === 'nonenvoye'
                ? [
                      {
                          prop: 'checkbox',
                          cellProps: {
                              className: 'foceVerticalMiddle',
                          },
                          headerCell: () => (
                              <input
                                  type="checkbox"
                                  checked={checkedColis.length === colis.items.length}
                                  onChange={onCheckAllColis}
                              />
                          ),
                          cell: (row: ColisInterface) => (
                              <>
                                  {((!row.idRamassage && row.aRecuperer == true && selectedEtat === 'envoye') ||
                                      selectedEtat === 'nonenvoye') && (
                                      <>
                                          {/* <span>{(!row.idRamassage).toString()}</span>
                                          <span>{row.aRecuperer?.toString()}</span> */}
                                          <input
                                              type="checkbox"
                                              checked={checkedColis.includes(row.idColis)}
                                              //   readOnly
                                              onChange={() => onCheckColis(row)}
                                          />
                                      </>
                                  )}
                              </>
                          ),
                      },
                  ]
                : []),
            {
                prop: 'refColis',
                title: 'Ref',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
            },
            ...(contextUser?.userData.type === 'Fournisseur'
                ? [
                      {
                          prop: 'client.raisonSocial',
                          title: 'Client',
                          cellProps: {
                              className: 'foceVerticalMiddle',
                          },
                          isFilterable: true,
                          isSortable: true,
                      },
                  ]
                : []),
            {
                prop: 'dateEnregistrement',
                title: 'Enregistré',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: ColisInterface) => (
                    <span className="my-xsm-font">
                        {row.dateEnregistrement ? moment(row.dateEnregistrement).format('DD/MM/YYYY HH:mm') : '-'}
                    </span>
                ),
            },
            {
                prop: '--produits',
                title: 'Produits',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: ColisInterface) => <ProduitsDataColumn colisproduitenvoyes={row.colisproduitenvoyes} />,
            },
            {
                prop: 'dateEnvoi',
                title: 'Envoyé',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: ColisInterface) => (
                    <span className="my-xsm-font">
                        {row.dateEnvoi ? moment(row.dateEnvoi).format('DD/MM/YYYY HH:mm') : '-'}
                    </span>
                ),
            },
            {
                prop: 'null2',
                title: 'Options',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                cell: (row: ColisInterface) => (
                    <>
                        {row.fragile && !!row.fragile && (
                            <>
                                <Badge bg="info">Fragile</Badge>&nbsp;
                            </>
                        )}
                        {row.aRecuperer && !!row.aRecuperer && (
                            <>
                                <Badge bg="info">À récuperer</Badge>&nbsp;
                            </>
                        )}
                        {row.aOuvrir && !!row.aOuvrir && (
                            <>
                                <Badge bg="info">À ouvrir</Badge>&nbsp;
                            </>
                        )}
                    </>
                ),
            },
            {
                prop: 'moyenTransport',
                title: 'Transport',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
            },
            {
                prop: 'ramassage.dateRamassage',
                title: 'Ramassage',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: ColisInterface) => (
                    <span className="my-xsm-font">
                        {(row as any)['ramassage.dateRamassage']
                            ? moment((row as any)['ramassage.dateRamassage']).format('DD/MM/YYYY HH:mm')
                            : '-'}
                    </span>
                ),
            },
            {
                prop: 'dateRec',
                title: 'Réçu le',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: ColisInterface) => (
                    <span className="my-xsm-font">
                        {row.dateRec ? moment(row.dateRec).format('DD/MM/YYYY HH:mm') : '-'}
                    </span>
                ),
            },
            {
                prop: 'dateRecPrevu',
                title: 'Récep.prévu',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: ColisInterface) => (
                    <span className="my-xsm-font">{moment(row.dateRecuPrevu).format('DD/MM/YYYY')}</span>
                ),
            },
            {
                prop: 'null',
                title: 'Ramassage',
                alignment: { horizontal: 'center' },
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                cell: (row: ColisInterface) => (
                    <>
                        {(row as any)['ramassage.idRamassage'] ? (
                            !!(row as any)['ramassage.etatRamassage'] ? (
                                <FontAwesomeIcon icon={faCheck} size="xs" style={{ color: '#33b76e' }} />
                            ) : (
                                <FontAwesomeIcon icon={faClock} size="xs" style={{ color: '#71a2ad' }} />
                            )
                        ) : (
                            <FontAwesomeIcon icon={faX} size="xs" style={{ color: '#ff833b' }} />
                        )}
                    </>
                ),
            },
            {
                prop: 'null',
                title: '#',
                alignment: { horizontal: 'right' },
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                cell: (row: ColisInterface) => (
                    <Link to={`/colis/edit/${utf8_to_b64(row.idColis.toString())}`}>
                        <Button
                            variant="outline-secondary"
                            className="button-input-group-effect gap-5marginpx"
                            id="basic-addon1"
                        >
                            {!(row as any)['ramassage.idRamassage'] ? (
                                <FontAwesomeIcon icon={faEdit} size="xs" style={{ color: '#337ab7' }} />
                            ) : (
                                <FontAwesomeIcon icon={faInfo} size="xs" style={{ color: '#376ca9' }} />
                            )}
                        </Button>
                    </Link>
                ),
            },
        ],
        [checkedColis, onCheckAllColis, onCheckColis, colis.items, contextUser],
    );

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { active: true, text: 'Colis' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    List des colis
                </TitleElement>

                {contextUser && contextUser.userData.type === 'Fournisseur' && selectedClient && (
                    <Link to={`/colis/new?client=${utf8_to_b64(selectedClient?.toString()!)}`}>
                        <Button variant="outline-secondary" className="button-input-group-effect" id="basic-addon1">
                            <FontAwesomeIcon icon={faPlus} size="xs" style={{ color: '#337ab7' }} />
                        </Button>
                    </Link>
                )}
                {contextUser && contextUser.userData.type === 'Client' && (
                    <Link to="/colis/new">
                        <Button variant="outline-secondary" className="button-input-group-effect" id="basic-addon1">
                            <FontAwesomeIcon icon={faPlus} size="xs" style={{ color: '#337ab7' }} />
                        </Button>
                    </Link>
                )}
            </div>
            <Collapser moreClassNames={['flex-grow-07']}>
                <Row className="mb-2 holdit_notEffect">
                    {contextUser && contextUser.userData.type === 'Fournisseur' && (
                        <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_Client">
                            <Form.Label>Client:</Form.Label>
                            <Form.Select
                                size="sm"
                                name="selectedClient"
                                value={selectedClient!}
                                onChange={(e) => {
                                    const {
                                        target: { value },
                                    } = e;
                                    setSelectedClient(value);
                                    handlePageNFilterChange(
                                        1,
                                        JSON.stringify({
                                            status: selectedEtat,
                                            client: value,
                                            dDebut: date_debut,
                                            dFin: date_fin,
                                        }),
                                    );
                                }}
                                placeholder="Selectionner un client"
                            >
                                <option value="">Selectionner un client</option>
                                {clients.map((d: ClientInterface) => (
                                    <option key={d.idClient} value={d.idClient}>
                                        {d.raisonSocial}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    )}
                    <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_Etat">
                        <Form.Label>Etat:</Form.Label>
                        <Form.Select
                            size="sm"
                            name="selectedEtat"
                            value={selectedEtat!}
                            onChange={(e) => {
                                const {
                                    target: { value },
                                } = e;
                                setSelectedEtat(value);
                                handlePageNFilterChange(
                                    1,
                                    JSON.stringify({
                                        status: value,
                                        client: selectedClient,
                                        dDebut: date_debut,
                                        dFin: date_fin,
                                    }),
                                );
                            }}
                            placeholder="Selectionner une Etat"
                        >
                            <option value="">Tous</option>
                            <option value="nonenvoye">N&apos;as pas envoyé</option>
                            <option value="envoye">Envoyé</option>
                            <option value="recu">Recu</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_date_debut">
                        <Form.Label>Date début:</Form.Label>
                        <Datetime
                            inputProps={{
                                name: 'date_debut',
                                className: 'form-control form-control-sm',
                                readOnly: true,
                            }}
                            onChange={(e: any) => onChangeDateDebut(e)}
                            value={moment(date_debut, 'YYYY-MM-DD  HH:mm:ss').format('DD/MM/YYYY')}
                            dateFormat="DD/MM/YYYY"
                            timeFormat={false}
                            closeOnSelect
                        />
                    </Form.Group>
                    <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_date_fin">
                        <Form.Label>Date fin:</Form.Label>
                        <Datetime
                            inputProps={{
                                name: 'date_fin',
                                className: 'form-control form-control-sm',
                                readOnly: true,
                            }}
                            onChange={(e: any) => onChangeDateFin(e)}
                            value={moment(date_fin, 'YYYY-MM-DD  HH:mm:ss').format('DD/MM/YYYY')}
                            dateFormat="DD/MM/YYYY"
                            timeFormat={false}
                            closeOnSelect
                        />
                    </Form.Group>
                </Row>
            </Collapser>
            <Datatable
                data={colis.items}
                tableColumns={tableHeaders}
                //rowClickCB={onCheckColis}
            >
                <Row>
                    <Col className="d-flex flex-col justify-content-end align-items-end">
                        <Paginator
                            defaultPage={activePage}
                            totalPages={Math.ceil(colis.count / TOTAL_DATA_PER_PAGE)}
                            callback={(e_page) =>
                                handlePageNFilterChange(
                                    e_page,
                                    JSON.stringify({
                                        status: selectedEtat,
                                        client: selectedClient,
                                        dDebut: date_debut,
                                        dFin: date_fin,
                                    }),
                                )
                            }
                        />
                    </Col>
                </Row>
            </Datatable>

            {checkedColis.length > 0 && selectedEtat === 'nonenvoye' && (
                <div className="gap-2 mt-3 float-end">
                    <Button
                        className="ms-2"
                        variant="outline-primary"
                        type="button"
                        disabled={!!spinIt}
                        onClick={() => submitEnvoiColis()}
                    >
                        Envoyer
                        {!!spinIt && (
                            <>
                                &nbsp; <Spinner animation="border" size="sm" />
                            </>
                        )}
                    </Button>
                </div>
            )}
            {checkedColis.length > 0 && selectedEtat === 'envoye' && contextUser?.userData.type === 'Fournisseur' && (
                <Tabs defaultActiveKey="ramassage" transition={false} id="ramassage_tabs" className="mt-1">
                    <Tab eventKey="ramassage" title="Ramassage" className="tab-content-border">
                        <Formik
                            validationSchema={schema}
                            onSubmit={(values) => submitRamassageColis(values)}
                            enableReinitialize={true}
                            initialValues={{ ids: checkedColis, idLivreur: contextUser.IdUser, remarque: '' }}
                        >
                            {({ handleSubmit, handleChange, values, errors }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Row>
                                        <Form.Group as={Col} md="4" controlId="form_grp_date_aff">
                                            <Form.Label>Date d&apos;affectation</Form.Label>
                                            <ClockInput />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="form_grp_livreur">
                                            <Form.Label>Livreur</Form.Label>
                                            <Form.Select
                                                name="idLivreur"
                                                value={values.idLivreur}
                                                onChange={handleChange}
                                                isInvalid={!!errors.idLivreur}
                                                placeholder="Selectionner un livreur"
                                            >
                                                <option>Selectionner une livreur</option>
                                                {livreurs.map((d: UserInterface) => (
                                                    <option key={d.idUser} value={d.idUser}>
                                                        {DisplayName(d)}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="form_grp_remarque">
                                            <Form.Label>Remarque</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                as="textarea"
                                                rows={2}
                                                name="remarque"
                                                placeholder="Remarque"
                                                value={values.remarque}
                                                onChange={handleChange}
                                                isInvalid={!!errors.remarque}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <div className="gap-2 mt-3 float-end">
                                        <Button
                                            className="ms-2"
                                            variant="outline-primary"
                                            type="submit"
                                            disabled={!!spinIt}
                                        >
                                            Enregistrer
                                            {!!spinIt && (
                                                <>
                                                    &nbsp; <Spinner animation="border" size="sm" />
                                                </>
                                            )}
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Tab>
                </Tabs>
            )}
        </>
    );
};

export default ColisIndexPage;

type ProduitsDataColumnProps = {
    colisproduitenvoyes: ColisProduitEnvoyeInterface[];
};
const ProduitsDataColumn: React.FC<ProduitsDataColumnProps> = ({ colisproduitenvoyes }: ProduitsDataColumnProps) => {
    const products = (variant = 'success') =>
        colisproduitenvoyes.map((cpe) => (
            <Badge
                key={cpe.idEnvoi!}
                className="user-select-none d-flex justify-content-center align-content-center align-self-center"
                bg={variant}
            >
                {!cpe.produit?.designation || cpe.produit?.designation.trim() === ''
                    ? cpe.produit?.ref
                    : cpe.produit?.designation}
            </Badge>
        ));

    const defaultList = products();
    const DetailsList = products('info');
    const firstTwoProducts = defaultList.slice(0, 2);

    return (
        <div className="d-flex gap-1 my-xsm-font align-content-center ">
            {firstTwoProducts}{' '}
            {defaultList.length > 2 && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-show-products">{DetailsList}</Tooltip>}>
                    <div className="cursor-pointer">...</div>
                </OverlayTrigger>
            )}
        </div>
    );
};
