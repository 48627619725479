import React, { useState } from 'react';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { Formik } from 'formik';
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';

import CustomModal from 'components/UI/customModal';
import { getTransfertCommandeByCodeOrRefForAction } from 'services/transfert.service';
import TransfertCommandeInterface from 'types/interfaces/transfertCommande.interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode, faX } from '@fortawesome/free-solid-svg-icons';

const schema = yup.object({
    code: yup.string().required(),
});

type Props = {
    pageType: string;
    setTransferts: React.Dispatch<React.SetStateAction<TransfertCommandeInterface[]>>;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};
const SearchLocalByCodeRefFormModal: React.FC<Props> = ({ setTransferts, pageType, show, setShow }: Props) => {
    const [spinIt, setSpinIt] = useState<boolean>(false);

    const submitForm = async (values: any, { resetForm }: any) => {
        try {
            setSpinIt(true);
            const response: AxiosResponse<TransfertCommandeInterface> = await getTransfertCommandeByCodeOrRefForAction(
                values.code,
                pageType,
            );
            if (response.status === 200) {
                const { data } = response;
                setTransferts((prev: TransfertCommandeInterface[]) => {
                    const updatedData = prev.filter(
                        (item: TransfertCommandeInterface) => item.idTransfertCommande !== data.idTransfertCommande,
                    );
                    return [...updatedData, data];
                });

                // Reset the form
                resetForm();
            }
        } catch (error: any) {
            if (error && error.status === 404) toast.warning("Transfert n'existe pas");
            else if (error) toast.warning(error);
            else toast.error('Erreur est survenue');

            setSpinIt(false);
        } finally {
            setSpinIt(false);
        }
    };

    return (
        <>
            <CustomModal title="Scanner le code barre" show={show} handleClose={() => setShow(false)}>
                <div>
                    <Formik
                        validationSchema={schema}
                        onSubmit={(values, { resetForm }) => submitForm(values, { resetForm })}
                        enableReinitialize={true}
                        initialValues={{
                            code: '',
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group controlId="form_grp_nom">
                                    <Form.Label>Code barre / Ref</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type="text"
                                            name="code"
                                            placeholder="Code barre"
                                            value={values.code}
                                            onChange={handleChange}
                                            isInvalid={!!errors.code}
                                            autoFocus={true}
                                        />
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => handleChange({ target: { name: 'code', value: '' } })}
                                        >
                                            <FontAwesomeIcon icon={faX} size="lg" color="#3b3535" />
                                        </Button>
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">{errors.code}</Form.Control.Feedback>
                                </Form.Group>
                                <div className="d-grid gap-2 mt-3">
                                    <Button variant="primary" type="submit" disabled={!!spinIt}>
                                        Trouver
                                        {!!spinIt && (
                                            <>
                                                &nbsp; <Spinner animation="border" size="sm" />
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </CustomModal>
        </>
    );
};

export default SearchLocalByCodeRefFormModal;

type ScanButtonTriggerType = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ScanButtonTrigger: React.FC<ScanButtonTriggerType> = ({ setShow }: ScanButtonTriggerType) => {
    return (
        <Button variant="outline-info" onClick={() => setShow(true)}>
            <FontAwesomeIcon icon={faBarcode} size="lg" />
        </Button>
    );
};
