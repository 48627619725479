import React from 'react';
import { Form } from 'react-bootstrap';
import useRealTimeClock from 'hooks/useRealTimeClock';

const ClockInput: React.FC = () => {
    const clock = useRealTimeClock();

    return <Form.Control size="sm" type="text" name="clock" onChange={() => void 0} value={clock} disabled />;
};

export default ClockInput;
