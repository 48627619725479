import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { TableColumnType } from 'react-bs-datatable';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faXmark,
    faCheck,
    faPlus,
    faEdit,
    faCheckDouble,
    faBan,
    faRightFromBracket,
} from '@fortawesome/free-solid-svg-icons';

import { ROLES as RolesMap } from 'constantes/permission-maps';

import { useQueryMeMo, buildUrlQueryParams, isEmptyObject } from 'utils/helpers';
import UserInterface from 'types/interfaces/user.interface';
import {
    saveUser,
    toggleDisableUser,
    getUsersFournisseurs_SA,
    getUsersClients_SA,
    getUsersFournisseurs_FA,
    getUsersClients_FA,
    getUsersClients_CA,
} from 'services/user.service';

import CustomModal from 'components/UI/customModal';
import { AdminFournisseursTableData } from 'pages/Users/components/admin/fournisseurs';
import { AdminClientsTableData } from 'pages/Users/components/admin/clients';
import { FournisseursTableData } from 'pages/Users/components/fournisseur/fournisseurs';
import { FournisseursClientsTableData } from 'pages/Users/components/fournisseur/clients';
import { ClientsTableData } from 'pages/Users/components/client/clients';

import UserFormLoader from 'pages/Users/components/formLoader';
import Datatable from 'components/shared/datatable';
import SearchInput from 'components/shared/searchInput';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import TitleElement from 'components/UI/titleElement';
import PermissionsGate from 'hoc/permissionsGate';

import useUserStore, { StoreStateInterface } from 'store/appStore';
import { getDirectAuth } from 'services/authService';
import ForcingAuth from 'pages/Users/components/ForcingAuth';

const TOTAL_DATA_PER_PAGE = 100;

type Props = {
    title: string;
    pageContent: string;
};

const UserIndexPage: React.FC<Props> = ({ pageContent, title }: Props) => {
    const setLoadingFalse = useUserStore((state: StoreStateInterface) => state.setLoadingFalse);
    const setLoadingTrue = useUserStore((state: StoreStateInterface) => state.setLoadingTrue);
    const [users, setUsers] = useState<{ items: UserInterface[]; count: number }>({ items: [], count: 0 });
    const [currentUser, setCurrentUser] = useState<UserInterface | null>(null);
    const [forcedAuth, setForcedAuth] = useState<any>(null);

    const [showForcingAuth, setShowForcingAuth] = useState<boolean>(false);
    const [showFormModal, setShowFormModal] = useState<boolean>(false);
    const [formModalEditMode, setFormModalEditMode] = useState<boolean>(false);
    const [spinIt, setSpinIt] = useState<boolean>(false);
    const [Error, setError] = useState(null);

    const [inputValue, setInputValue] = useState('');
    const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null);

    const [activePage, setActivePage] = useState<number>(1);

    const history = useNavigate();
    const query = useQueryMeMo();

    const handleFormModalClose = () => {
        setCurrentUser(null);
        setShowFormModal(false);
    };

    const handleFormModalOpen = (editMode = false, userData?: UserInterface) => {
        setError(null);
        if (userData) setCurrentUser(userData);
        setFormModalEditMode(editMode);
        setShowFormModal(true);
    };

    const inputChanged = (e: any) => {
        setInputValue(e.target.value);

        clearTimeout(timer!);

        const newTimer = setTimeout(() => {
            handlePageNFilterChange(1, e.target.value);
        }, 500);

        setTimer(newTimer);
    };

    const handlePageNFilterChange = (activePage: number, search?: string) => {
        const urlObject: any = {};
        if (activePage > 1) urlObject['page'] = activePage;
        if (search && search.trim() != '') urlObject['search'] = search;
        history(isEmptyObject(urlObject) ? window.location.pathname : `?${buildUrlQueryParams(urlObject)}`);
    };

    const DirectAuth = async (userRow: UserInterface) => {
        try {
            setLoadingTrue();
            const { data } = await getDirectAuth(userRow.idUser);
            setForcedAuth({ user: userRow, data });
            setLoadingFalse();
            setShowForcingAuth(true);
        } catch (err) {
            toast.error("Une erreur s'est produite !");
            setLoadingFalse();
        }
    };

    let tableheaderData: TableColumnType<any>[] = [];
    switch (pageContent) {
        case 'fournisseurs_sa':
            tableheaderData = AdminFournisseursTableData;
            break;
        case 'clients_sa':
            tableheaderData = AdminClientsTableData;
            break;
        case 'fournisseurs_fa':
            tableheaderData = FournisseursTableData;
            break;
        case 'clients_fa':
            tableheaderData = FournisseursClientsTableData;
            break;
        case 'clients_ca':
            tableheaderData = ClientsTableData;
            break;
    }

    const tableHeaders: TableColumnType<any>[] = [
        {
            prop: 'nom',
            title: 'Nom complet',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: UserInterface) => <>{`${row.nom.toUpperCase()} ${row.prenom.toLowerCase()}`}</>,
        },
        {
            prop: 'email',
            title: 'Email',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        ...tableheaderData,
        {
            prop: 'etatCompte',
            title: 'Etat de Compte',
            cellProps: {
                className: 'foceVerticalMiddle text-center',
            },
            thProps: {
                className: 'text-center',
            },
            cell: (row: UserInterface) => (
                <>
                    {!!row.etatCompte ? (
                        <FontAwesomeIcon icon={faCheck} size="xs" style={{ color: '#337ac8' }} />
                    ) : (
                        <FontAwesomeIcon icon={faXmark} size="xs" style={{ color: '#ff832c' }} />
                    )}
                </>
            ),
        },
        {
            prop: 'null',
            title: 'Actions',
            alignment: { horizontal: 'right' },
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: UserInterface) => (
                <>
                    <Button
                        variant="outline-secondary"
                        className="button-input-group-effect gap-5marginpx"
                        id="basic-addon1"
                        onClick={() => handleFormModalOpen(true, row)}
                    >
                        <FontAwesomeIcon icon={faEdit} size="xs" style={{ color: '#337ab7' }} />
                    </Button>
                    {!!row.etatCompte ? (
                        <Button
                            variant="outline-secondary"
                            className="button-input-group-effect gap-5marginpx rounded-circle"
                            id="basic-addon2"
                            onClick={() => toggleStatus(row)}
                        >
                            <FontAwesomeIcon icon={faBan} size="xs" style={{ color: '#ff833b' }} />
                        </Button>
                    ) : (
                        <Button
                            variant="outline-secondary"
                            className="button-input-group-effect gap-5marginpx rounded-circle"
                            id="basic-addon2"
                            onClick={() => toggleStatus(row)}
                        >
                            <FontAwesomeIcon icon={faCheckDouble} size="xs" style={{ color: '#33b76e' }} />
                        </Button>
                    )}
                    {['Fournisseur', 'Client'].includes(row.type) && (
                        <PermissionsGate scopes={[RolesMap.SUPER_ADMIN]} strict>
                            <Button
                                variant="outline-secondary"
                                className="button-input-group-effect gap-5marginpx rounded-circle"
                                id="basic-addon33"
                                onClick={() => DirectAuth(row)}
                            >
                                <FontAwesomeIcon icon={faRightFromBracket} size="xs" style={{ color: '#376ca9' }} />
                            </Button>
                        </PermissionsGate>
                    )}
                </>
            ),
        },
    ];

    const loadgetUsersFournisseurs_SA = async (totalPage: number, page: number, search?: string) => {
        try {
            const {
                status,
                data: { items: data },
                data: { count },
            } = await getUsersFournisseurs_SA(TOTAL_DATA_PER_PAGE, page, search);
            if (status === 200) {
                setUsers({ items: data, count });
            }
        } catch (Exception) {}
    };
    const loadgetUsersClients_SA = async (totalPage: number, page: number, search?: string) => {
        try {
            const {
                status,
                data: { items: data },
                data: { count },
            } = await getUsersClients_SA(TOTAL_DATA_PER_PAGE, page, search);
            if (status === 200) {
                setUsers({ items: data, count });
            }
        } catch (Exception) {}
    };
    const loadgetUsersFournisseurs_FA = async (totalPage: number, page: number, search?: string) => {
        try {
            const {
                status,
                data: { items: data },
                data: { count },
            } = await getUsersFournisseurs_FA(TOTAL_DATA_PER_PAGE, page, search);
            if (status === 200) {
                setUsers({ items: data, count });
            }
        } catch (Exception) {}
    };
    const loadgetUsersClients_FA = async (totalPage: number, page: number, search?: string) => {
        try {
            const {
                status,
                data: { items: data },
                data: { count },
            } = await getUsersClients_FA(TOTAL_DATA_PER_PAGE, page, search);
            if (status === 200) {
                setUsers({ items: data, count });
            }
        } catch (Exception) {}
    };
    const loadgetUsersClients_CA = async (totalPage: number, page: number, search?: string) => {
        try {
            const {
                status,
                data: { items: data },
                data: { count },
            } = await getUsersClients_CA(TOTAL_DATA_PER_PAGE, page, search);
            if (status === 200) {
                setUsers({ items: data, count });
            }
        } catch (Exception) {}
    };

    useEffect(() => {
        document.title = 'Users - DS';
    }, []);

    useEffect(() => {
        const page = query.get('page');
        const search = query.get('search');
        let currentPage = 1;
        let instantSearch = '';
        if (page) currentPage = +page;
        if (search) instantSearch = decodeURIComponent(search);
        setActivePage(currentPage);
        setInputValue(instantSearch);
        const loadData = async () => {
            switch (pageContent) {
                case 'fournisseurs_sa':
                    await loadgetUsersFournisseurs_SA(TOTAL_DATA_PER_PAGE, currentPage, instantSearch);
                    break;
                case 'clients_sa':
                    await loadgetUsersClients_SA(TOTAL_DATA_PER_PAGE, currentPage, instantSearch);
                    break;
                case 'fournisseurs_fa':
                    await loadgetUsersFournisseurs_FA(TOTAL_DATA_PER_PAGE, currentPage, instantSearch);
                    break;
                case 'clients_fa':
                    await loadgetUsersClients_FA(TOTAL_DATA_PER_PAGE, currentPage, instantSearch);
                    break;
                case 'clients_ca':
                    await loadgetUsersClients_CA(TOTAL_DATA_PER_PAGE, currentPage, instantSearch);
                    break;
            }
        };
        loadData();
    }, [query, pageContent]);

    const toggleStatus = async (userData: UserInterface) => {
        try {
            const { data: userInfo } = await toggleDisableUser(userData.idUser);
            const usersList = [...users.items];
            const index = usersList.findIndex((d) => d.idUser == userInfo.idUser);
            usersList[index] = userInfo;
            setUsers({ items: usersList, count: users.count });
        } catch (Exception) {}
    };

    const submitForm = async (userValues: UserInterface | any) => {
        try {
            setError(null);
            setSpinIt(true);
            const response = await saveUser(userValues);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('User a été enregistré avec succès');
                const userDATA = [...users.items].filter((dd) => dd.idUser !== resultData.idUser);
                userDATA.unshift(resultData);
                setUsers({ items: userDATA, count: users.count });
                handleFormModalClose();
                setSpinIt(false);
            }
        } catch (Exception) {
            setSpinIt(false);
            const { message = undefined }: any = Exception;
            setError(message || 'Error occured!');
        }
    };

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { text: 'Agents' },
        { active: true, text: title },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    {title}
                </TitleElement>
                <Button
                    variant="outline-secondary"
                    className="button-input-group-effect"
                    id="basic-addon1"
                    onClick={() => handleFormModalOpen()}
                >
                    <FontAwesomeIcon icon={faPlus} size="xs" style={{ color: '#337ab7' }} />
                </Button>
            </div>
            <Datatable data={users.items} tableColumns={tableHeaders}>
                <SearchInput
                    inputValue={inputValue}
                    inputChanged={inputChanged}
                    activePage={activePage}
                    totalPages={Math.ceil(users.count / TOTAL_DATA_PER_PAGE)}
                    handlePageNFilterChange={handlePageNFilterChange}
                />
            </Datatable>
            <CustomModal
                title={`${!!formModalEditMode ? 'Edit' : 'Nouveau'} Agent`}
                size="lg"
                show={showFormModal}
                handleClose={handleFormModalClose}
            >
                <div>
                    <UserFormLoader
                        pageContent={pageContent}
                        user={currentUser}
                        spinIt={spinIt}
                        errorMessage={Error}
                        formEditMode={formModalEditMode}
                        submitForm={submitForm}
                    />
                </div>
            </CustomModal>
            <CustomModal
                title="Changer de compte"
                size="lg"
                show={showForcingAuth}
                handleClose={() => setShowForcingAuth(false)}
            >
                {forcedAuth ? (
                    <ForcingAuth
                        data={forcedAuth}
                        setData={setForcedAuth}
                        closeModal={() => setShowForcingAuth(false)}
                    />
                ) : (
                    <></>
                )}
            </CustomModal>
        </>
    );
};

export default UserIndexPage;
