import React, { useState } from 'react';
import { Container, Row, Col, Alert, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { resetPasswordRequest } from 'services/authService';
import { userReqResetPassword } from 'types/index';

import * as yup from 'yup';
import { Formik } from 'formik';

import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import RecaptchaComponent from 'components/shared/recaptchaComponent';

const schema = yup.object({
    Email: yup.string().required(),
});

type Props = any;

const ForgetPasswordComponent: React.FC<Props> = ({ googleReCaptchaProps }: Props) => {
    const [error, setError] = useState<any>(null);
    const [messageSent, setMessageSent] = useState(null);

    const handleSumitForm = async (formValues: any) => {
        const { executeRecaptcha } = googleReCaptchaProps;
        if (!executeRecaptcha) {
            console.log('Recaptcha has not been loaded');
            return;
        }
        try {
            const gReCaptchaToken = await executeRecaptcha('DS_RESET_PWD_REQ');
            if (!gReCaptchaToken) {
                setError('ReCaptcha Problem!');
                return;
            }
            submitForm({ ...formValues, reCaptchaToken: gReCaptchaToken });
        } catch (Exception) {
            setError('ReCaptcha Problem!');
        } finally {
        }
    };
    const submitForm = async (formValues: userReqResetPassword | any) => {
        try {
            setError(null);
            const {
                status,
                data: { message },
            } = await resetPasswordRequest(formValues);
            if (status === 200) {
                setMessageSent(message);
            } else throw new Error();
        } catch (err) {
            const { message = undefined }: any = err;
            setError(message || 'Error occured!');
        }
    };

    return (
        <Container>
            <Row>
                <Col xs={12} md={{ span: 4, offset: 4 }}>
                    <div className="text-center py-3 mt-3">
                        <h2 className="mb-3">Changement de mot de passe</h2>
                        {error && <Alert variant="danger">{error}</Alert>}
                        {messageSent && <Alert variant="info">{messageSent}</Alert>}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={{ span: 4, offset: 4 }}>
                    {!error && !messageSent && (
                        <Formik
                            validationSchema={schema}
                            onSubmit={(values) => handleSumitForm(values)}
                            enableReinitialize={true}
                            initialValues={{
                                Email: '',
                            }}
                        >
                            {({ handleSubmit, handleChange, values, errors }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Group controlId="exampleForm.ControlUserName">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Email"
                                            name="Email"
                                            value={values.Email}
                                            onChange={handleChange}
                                            isInvalid={!!errors.Email}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.Email}</Form.Control.Feedback>
                                    </Form.Group>
                                    <div className="d-grid gap-2 mt-3">
                                        <Button variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                    <Link to="/login">Back to Login?</Link>
                </Col>
            </Row>
        </Container>
    );
};

const ForgetPasswordPage = withGoogleReCaptcha(ForgetPasswordComponent);

export default () => (
    <RecaptchaComponent>
        <ForgetPasswordPage />
    </RecaptchaComponent>
);
