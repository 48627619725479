import React, { useState } from 'react';

import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';

import FactureInterface from 'types/interfaces/facture.interface';
import { deleteFacture } from 'services/facture.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

type Props = {
    idFacture: number;
    factures: { items: FactureInterface[]; count: number };
    setFactures: (value: { items: FactureInterface[]; count: number }) => void;
    close: () => void;
};

const EditFormFacture: React.FC<Props> = ({ idFacture, factures, setFactures, close }: Props) => {
    const [spinIt, setSpinIt] = useState<boolean>(false);
    const submitDelete = async () => {
        try {
            setSpinIt(true);
            const response = await deleteFacture(idFacture);
            if (response.status === 200) {
                const refreshedFactures = factures.items.filter(
                    (item: FactureInterface) => item.idFacture !== idFacture,
                );
                setFactures({ items: refreshedFactures, count: factures.items.length - 1 });
                toast.success('Facture a été supprimée.');
                setSpinIt(false);
                close();
            }
        } catch (Exception) {
            setSpinIt(false);
        }

        setSpinIt(false);
    };
    return (
        <div>
            <h5>Voulez vous vraiement supprimer cette commande ?</h5>
            <div className="d-flex gap-3 justify-content-end">
                <Button variant="outline-danger" onClick={submitDelete} disabled={spinIt}>
                    Confirmer
                </Button>
                <Button variant="outline-info" onClick={close} disabled={spinIt}>
                    <FontAwesomeIcon icon={faX} size="xs" />
                </Button>
            </div>
        </div>
    );
};

export default EditFormFacture;
