import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import ClientInterface from 'types/interfaces/client.interface';

const apiEndPoint = `${apiUrl}/clients`;

export const getClientById = (id: number): Promise<ClientInterface | any> => {
    return http.get(`${apiEndPoint}/${id}`);
};

export const getClientsByIdFournisseur = (id: number): Promise<ClientInterface[] | any> => {
    return http.get(`${apiEndPoint}/fournisseur/${id}`);
};

export const getClientsPaginated = (
    total: number,
    page: number,
    search?: string,
): Promise<{ count: number; items: ClientInterface[] } | any> => {
    const query = `?page=${page}&total=${total}${search ? '&search=' + search : ''}`;
    return http.get(`${apiEndPoint}/paginated${query}`);
};

export const toggleDisableClient = (idClient: number): Promise<ClientInterface | any> => {
    return http.put(`${apiEndPoint}/toggle-disable/${idClient}`);
};

export const saveFreshOrExistedClient = (data: ClientInterface | any, editMode = false) => {
    if (!!editMode) {
        return http.put(`${apiEndPoint}/${data.idClient}`, data);
    }
    return http.post(`${apiEndPoint}/fresh`, data);
};
