import React from 'react';
import { Image } from 'react-bootstrap';

import { readImageTobase64 } from 'utils/helpers';
import appStore, { StoreStateInterface } from 'store/appStore';

import ImageElement from 'components/UI/imageElement';
import { LOGO_MAX_HEIGHT } from 'constantes/envLoader';

const SiteLogo: React.FC = () => {
    const siteLogo = appStore((state: StoreStateInterface) => state.siteLogo);

    return (
        <div className="d-flex align-items-center gap-1 logoMaxHeight logoMaxWidth">
            {siteLogo ? (
                <>
                    <ImageElement file={readImageTobase64(siteLogo)} alt="logo" height={LOGO_MAX_HEIGHT.toString()} />
                </>
            ) : (
                <>
                    <Image src="/images/ds_logo.png" alt="logo" height={LOGO_MAX_HEIGHT.toString()} />
                    <span>DS</span>
                </>
            )}
        </div>
    );
};

export default SiteLogo;
