import React, { useState, useContext } from 'react';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import { Button, Col, Form, InputGroup, Row, Spinner, Tabs, Tab } from 'react-bootstrap';
import { Formik, Field, FieldArray, FormikHelpers, FormikErrors } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import VilleInterface from 'types/interfaces/ville.interface';
import SearchAcheteurByGSM from 'components/shared/searchAcheteurByGsm';
import RegionInterface from 'types/interfaces/region.interface';
import ColisInterface from 'types/interfaces/colis.interface';
import TarifInterface from 'types/interfaces/tarif.interface';
import { getPreparedColisById } from 'services/colis.service';
import { saveCommande } from 'services/commande.service';

import { removeDuplicatedArrObj } from 'utils/helpers';

import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';

const schema = yup.object().shape({
    idColis: yup.number().required(),
    idVille: yup.number().required(),
    adresse: yup.string().required(),
    code: yup.string().required(),
    ref: yup.string().nullable(),
    commentaire: yup.string(),
    prixTotal: yup.number().moreThan(-1).required(),
    acheteur: yup.object().shape({
        idAcheteur: yup.number(),
        addresse: yup.string().nullable(),
        fullName: yup.string().required(),
        gsm: yup
            .string()
            .matches(
                /^(?:(?:(?:\+|00)212[\s]?(?:[\s]?\(0\)[\s]?)?)|0){1}(?:5[\s.-]?[2-3]|[5-8][\s.-]?[0-9]){1}[0-9]{1}(?:[\s.-]?\d{2}){3}$/,
                "Merci d'entrer un N°GSM valide",
            )
            .required(),
        email: yup.string().email().nullable(),
    }),
    commandeTarifs: yup
        .array()
        .of(
            yup.object({
                idTarif: yup.number().required(),
            }),
        )
        .min(1)
        .required(),
    colisCommandes: yup
        .array()
        .of(
            yup
                .object({
                    idEnvoi: yup.number().required(),
                    qteCommande: yup.number().min(0).required(),
                    max: yup.number().required(),
                })
                .test('is-some-test', '${path} is over max', validateMaxWIndex),
        )
        .test(
            'at-least-one-qteCommande-greater-than-0',
            'At least one qteCommande must be greater than 0',
            (colisCommandes: any) => colisCommandes && colisCommandes?.some((item: any) => item.qteCommande > 0),
        ),
});

function validateMaxWIndex(this: any, currentVal: any) {
    const { options, parent } = this;
    return !(parent[options.index].max < currentVal.qteCommande);
}

type Props = {
    currentColis: ColisInterface | null;
    regions: RegionInterface[];
    tarifs: TarifInterface[];
    selectedClientAdmin: string | null;
    setColis: (data: ColisInterface[]) => void;
    setNextActionModal: (data: { idColis?: number; idCommande: number } | null) => void;
    colis: ColisInterface[];
    handleFormModalClose: () => void;
};
const FormNewCommande: React.FC<Props> = ({
    currentColis,
    regions,
    tarifs,
    selectedClientAdmin,
    setColis,
    setNextActionModal,
    colis,
    handleFormModalClose,
}: Props) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    const handleNextActionModalOpen = (idColis: number, idCommande: number) => {
        getPreparedColisById(idColis)
            .then((response: AxiosResponse<ColisInterface | any>) => {
                const { data: responseData } = response;
                const colisData = [...colis];
                const index = colisData.findIndex((p: ColisInterface) => p.idColis === idColis);
                colisData[index] = responseData;
                setColis(colisData);
                setNextActionModal({ idColis, idCommande });
            })
            .catch(() => {
                const colisData = [...colis].filter((p: ColisInterface) => p.idColis !== idColis);
                setColis(colisData);
                setNextActionModal({ idCommande });
            });
    };
    const submitForm = async (values: any, { setSubmitting }: FormikHelpers<any>) => {
        if (contextUser?.userData.type === 'Fournisseur') {
            if (currentColis?.idClient && selectedClientAdmin) {
                values.idClientAgent = selectedClientAdmin;
                values.IdClient = currentColis?.idClient;
            } else {
                toast.warning('Erreur est survenue, Client ou Agent client ne sont pas définis.');
                return;
            }
        }
        try {
            setSubmitting(true);
            const cleanValues = {
                ...values,
                colisCommandes: values.colisCommandes.filter((item: any) => item.qteCommande > 0),
            };
            const response = await saveCommande(cleanValues);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Commande a été créée avec succès');
                handleFormModalClose();
                handleNextActionModalOpen(resultData.idColis, resultData.idCommande);
            }
        } catch (Exception) {
            setSubmitting(false);
        }
    };

    const changeQuantity = (e: any, handleChange: any, values: any, rnData: any, index: number) => {
        const updatedArr = [...values.colisCommandes!];
        (updatedArr as any)[index].qteCommande = +e.target.value;
        handleChange({
            target: {
                value: updatedArr,
                name: 'colisCommandes',
            },
        });
        let prixInit =
            values.colisCommandes
                ?.filter((df: any, i: number) => i !== index)
                .reduce((accumulator: number, object: any) => {
                    return accumulator + object.productDetails.produit.prixVente * object.qteCommande;
                }, 0) ?? 0;
        prixInit += +rnData.qteCommande * rnData.productDetails.produit.prixVente;

        handleChange({
            target: {
                value: prixInit,
                name: 'prixTotal',
            },
        });
    };

    const acheteurInitValue = {
        idAcheteur: undefined,
        addresse: '',
        fullName: '',
        gsm: '',
        email: '',
        listeNoire: false,
    };

    return (
        <Formik
            validationSchema={schema}
            onSubmit={submitForm}
            enableReinitialize={true}
            initialValues={{
                idColis: currentColis?.idColis,
                code: `CMD${
                    contextUser?.userData?.type === 'Fournisseur'
                        ? currentColis?.idClient!
                        : contextUser?.userData?.idClient!
                }-${moment().valueOf()}`,
                ref: '',
                idVille: '',
                adresse: '',
                commentaire: '',
                prixTotal: currentColis?.colisproduitenvoyes.reduce((accumulator: number, object: any) => {
                    return accumulator + object.produit.prixVente * object.qteResteAcmd;
                }, 0),
                commandeTarifs: [],
                colisCommandes: currentColis?.colisproduitenvoyes.map((d: any) => {
                    return {
                        qteCommande: 0,
                        max: d.qteResteAcmd,
                        idEnvoi: d.idEnvoi,
                        productDetails: d,
                        checked: false,
                    };
                }),
                acheteur: acheteurInitValue,
            }}
        >
            {({ setFieldValue, handleSubmit, handleChange, values, errors, isSubmitting }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <div className="d-grid gap-2 mt-3">
                        <Row>
                            <Form.Group as={Col} md="4" controlId="form_grp_ref">
                                <Form.Label>Ref</Form.Label>
                                <Form.Control
                                    type="ref"
                                    name="ref"
                                    placeholder="Ref"
                                    value={values.ref}
                                    onChange={handleChange}
                                    isInvalid={!!errors.ref}
                                    size="sm"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="form_grp_ville">
                                <Form.Label>Villes</Form.Label>
                                <Form.Select
                                    size="sm"
                                    name="idVille"
                                    value={values.idVille}
                                    onChange={(e) => {
                                        const defaultLivraisonTarif = (
                                            removeDuplicatedArrObj(
                                                tarifs.filter(
                                                    (d: TarifInterface) =>
                                                        e.target.value.toString() === d.idVille.toString(),
                                                ),
                                                'idOptionService',
                                            ) as TarifInterface[]
                                        ).find((d: TarifInterface) => d.optionService.nameOptionService === 'Livraison')
                                            ?.idTarif!;
                                        handleChange({
                                            target: {
                                                value: defaultLivraisonTarif
                                                    ? [{ idTarif: defaultLivraisonTarif }]
                                                    : [],
                                                name: 'commandeTarifs',
                                            },
                                        });
                                        handleChange({
                                            target: {
                                                value: values.colisCommandes?.reduce(
                                                    (accumulator: number, object: any) => {
                                                        return (
                                                            accumulator +
                                                            object.productDetails.produit.prixVente * object.qteCommande
                                                        );
                                                    },
                                                    0,
                                                ),
                                                name: 'prixTotal',
                                            },
                                        });
                                        handleChange(e);
                                    }}
                                    isInvalid={!!errors.idVille}
                                    placeholder="Selectionner une ville"
                                >
                                    <option>Selectionner une ville</option>
                                    {regions.map((dr: RegionInterface) => (
                                        <optgroup key={dr.idRegion} label={dr.nomRegion}>
                                            {dr.villes?.map((d: VilleInterface) => (
                                                <option key={d.idVille} value={d.idVille}>
                                                    {d.nomVille}
                                                </option>
                                            ))}
                                        </optgroup>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="form_grp_commentaire">
                                <Form.Label>Commentaire</Form.Label>
                                <Form.Control
                                    size="sm"
                                    as="textarea"
                                    rows={2}
                                    name="commentaire"
                                    placeholder="Commentaire"
                                    value={values.commentaire}
                                    onChange={handleChange}
                                    isInvalid={!!errors.commentaire}
                                />
                            </Form.Group>
                        </Row>
                    </div>
                    <Row>
                        <Col md="8">
                            <FilteredColisCommandes
                                values={values}
                                errors={errors}
                                handleChange={handleChange}
                                changeQuantity={changeQuantity}
                                currentColis={currentColis}
                            />
                        </Col>
                        <Col md="4">
                            <h5 className="text-center">Services</h5>
                            <FieldArray
                                name="commandeTarifs"
                                render={(arrayHelpers) => {
                                    return (
                                        removeDuplicatedArrObj(
                                            tarifs.filter(
                                                (d: TarifInterface) =>
                                                    values.idVille.toString() === d.idVille.toString(),
                                            ),
                                            'idOptionService',
                                        ) as TarifInterface[]
                                    ).map((d: TarifInterface, index) => (
                                        <Row className=" g-3 align-items-center" key={d.idTarif}>
                                            <Col auto>
                                                <Form.Check
                                                    type="checkbox"
                                                    label={`${d.optionService.nameOptionService} :`}
                                                    name={`commandeTarifs[${index}].idTarif`}
                                                    id={`commandeTarifs[${index}].idTarif`}
                                                    checked={values.commandeTarifs
                                                        .map((dt: any) => dt.idTarif.toString())
                                                        .includes(d.idTarif.toString())}
                                                    disabled={d.optionService.nameOptionService === 'Livraison'}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            arrayHelpers.push({
                                                                idTarif: d.idTarif,
                                                            });
                                                        } else {
                                                            const index = values.commandeTarifs.findIndex(
                                                                (obj: any) => obj.idTarif == d.idTarif,
                                                            );
                                                            arrayHelpers.remove(index);
                                                        }
                                                    }}
                                                />
                                            </Col>
                                            <Col auto className="text-end">
                                                {d.prixTarif} DH
                                            </Col>
                                        </Row>
                                    ));
                                }}
                            />
                            <Row className="g-3 align-items-center">
                                <Col auto>
                                    <strong>Prix des services:</strong>{' '}
                                    {!!errors.commandeTarifs && (
                                        <FontAwesomeIcon
                                            icon={faExclamationTriangle}
                                            size="sm"
                                            style={{ color: '#dc3545', fontSize: '1rem' }}
                                        />
                                    )}
                                </Col>
                                <Col auto className="text-end">
                                    <strong>
                                        {tarifs
                                            .filter((d: TarifInterface) =>
                                                values.commandeTarifs.find((dd: any) => dd.idTarif === d.idTarif),
                                            )
                                            .reduce((accumulator: number, object: any) => {
                                                return accumulator + object.prixTarif;
                                            }, 0)}{' '}
                                        DH
                                    </strong>
                                </Col>
                            </Row>
                            <Form.Group controlId="form_grp_prixTotal">
                                <Row className=" g-3 align-items-center">
                                    <Col auto>
                                        <Form.Label style={{ fontWeight: 700, fontSize: '1rem' }}>Total</Form.Label>
                                    </Col>
                                    <Col auto>
                                        <InputGroup>
                                            <Form.Control
                                                className="text-end"
                                                type="text"
                                                name="prixTotal"
                                                placeholder="Prix Tarif"
                                                value={values.prixTotal}
                                                onChange={handleChange}
                                                isInvalid={!!errors.prixTotal}
                                            />
                                            <InputGroup.Text>DH</InputGroup.Text>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="6" controlId="form_grp_none">
                            <Tabs defaultActiveKey="acheteur" transition={false} id="acheteur_tabs" className="mt-1">
                                <Tab eventKey="acheteur" title="Acheteur" className="tab-content-border">
                                    <Form.Group controlId="form_grp_gsmSearch">
                                        <Form.Label>Chercher par GSM</Form.Label>
                                        <SearchAcheteurByGSM
                                            InitValue={acheteurInitValue}
                                            setField={(f: string, v: any) => setFieldValue(f, v)}
                                            fieldValuesData={values.acheteur}
                                        />
                                    </Form.Group>
                                    <Row>
                                        <Form.Group as={Col} md="6" controlId="form_grp_gsm">
                                            <Form.Label>GSM</Form.Label>
                                            <Form.Control
                                                type="gsm"
                                                name="acheteur.gsm"
                                                placeholder="Gsm"
                                                value={values.acheteur.gsm}
                                                onChange={handleChange}
                                                isInvalid={!!errors.acheteur?.gsm}
                                                size="sm"
                                                readOnly={!!values.acheteur.idAcheteur}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="form_grp_email">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="acheteur.email"
                                                placeholder="Email"
                                                value={values.acheteur.email}
                                                onChange={handleChange}
                                                isInvalid={!!errors.acheteur?.email}
                                                size="sm"
                                                readOnly={!!values.acheteur.idAcheteur}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} md="12" controlId="form_grp_fullName">
                                            <Form.Label>Nom</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="acheteur.fullName"
                                                placeholder="Nom"
                                                value={values.acheteur.fullName}
                                                onChange={handleChange}
                                                isInvalid={!!errors.acheteur?.fullName}
                                                size="sm"
                                                readOnly={!!values.acheteur.idAcheteur}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Form.Group controlId="form_grp_acheteur_adresse">
                                        <Form.Label>Adresse</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={2}
                                            name="acheteur.addresse"
                                            placeholder="Adresse"
                                            value={values.acheteur.addresse}
                                            onChange={(e: any) => {
                                                handleChange(e);
                                                handleChange({
                                                    target: {
                                                        value: e.target.value,
                                                        name: 'adresse',
                                                    },
                                                });
                                            }}
                                            isInvalid={!!errors.acheteur?.addresse}
                                            readOnly={!!values.acheteur.idAcheteur}
                                        />
                                    </Form.Group>
                                </Tab>
                            </Tabs>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="form_grp_adresse">
                            <Form.Label>Adresse de livraison</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={2}
                                name="adresse"
                                placeholder="Adresse de livraison"
                                value={values.adresse}
                                onChange={handleChange}
                                isInvalid={!!errors.adresse}
                            />
                        </Form.Group>
                    </Row>
                    <div className="d-grid gap-2 mt-3">
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                            Enregistrer
                            {isSubmitting && (
                                <>
                                    &nbsp; <Spinner animation="border" size="sm" />
                                </>
                            )}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default FormNewCommande;

const InputGrpButton: React.FC<any> = ({ cb, className, disabled, text }: any) => {
    const clickEvent = () => {
        if (disabled) return;
        cb();
    };
    return (
        <InputGroup.Text
            className={disabled ? 'bg-secondary cursor-no-drop text-light text-bold' : className}
            onClick={() => clickEvent()}
        >
            {text}
        </InputGroup.Text>
    );
};

interface FilteredColisCommandesProps {
    values: any;
    errors: FormikErrors<any>;
    handleChange: (e: React.ChangeEvent<any>) => void;
    changeQuantity: (e: any, handleChange: any, values: any, rnData: any, index: number) => void;
    currentColis: ColisInterface | null;
}

const FilteredColisCommandes: React.FC<FilteredColisCommandesProps> = ({
    values,
    errors,
    handleChange,
    changeQuantity,
    currentColis,
}) => {
    const [designationFilter, setDesignationFilter] = useState('');

    // Filter the `colisCommandes` while keeping a reference to the original index
    const filteredColisCommandes =
        values?.colisCommandes
            ?.map((rnData: any, originalIndex: number) => ({
                ...rnData,
                originalIndex,
            }))
            ?.filter((rnData: any) =>
                rnData.productDetails.produit.designation.toLowerCase().includes(designationFilter),
            ) ?? [];

    return (
        <>
            <InputGroup className="mb-3">
                <Form.Control
                    type="text"
                    placeholder="Filter by Designation"
                    value={designationFilter}
                    onChange={(e) => setDesignationFilter(e.target.value.toLowerCase())}
                />
            </InputGroup>

            <InputGroup className={`mt-1 header-multi-produit`}>
                <Field
                    name={`header.produit.designation`}
                    type="text"
                    value="Designation"
                    className="form-control"
                    readOnly
                />
                <Field
                    name={`header.produit.prixVente`}
                    type="text"
                    value="Prix"
                    className="form-control max-newcmd-width-field"
                    readOnly
                />
                <Field
                    name={`header.quantity`}
                    type="text"
                    value="Quantity"
                    className={`form-control ${
                        +errors.colisCommandes?.length! > 0 && 'border-danger border-start-simple'
                    }`}
                    readOnly
                />
                {currentColis?.dateRec && (
                    <Field
                        name={`header.qteResteAcmd`}
                        type="text"
                        value="Reste"
                        className="form-control max-newcmd-width-field"
                        readOnly
                    />
                )}
            </InputGroup>

            <FieldArray
                name="colisproduitenvoyes"
                render={() =>
                    filteredColisCommandes.map((rnData: any, index: number) => {
                        const rnErrors =
                            errors.colisCommandes?.length && (errors.colisCommandes! as any)[rnData.originalIndex];
                        return (
                            <InputGroup key={index} className={`${index === 0 ? 'body-multi-produit-first' : ''}`}>
                                <Field
                                    name={`colisCommandes[${rnData.originalIndex}].productDetails.produit.designation`}
                                    type="text"
                                    placeholder="Designation"
                                    className={`form-control`}
                                    readOnly
                                />

                                <Field
                                    name={`colisCommandes[${rnData.originalIndex}].productDetails.produit.prixVente`}
                                    type="text"
                                    placeholder="Prix"
                                    className={`form-control max-newcmd-width-field`}
                                    readOnly
                                />

                                <div className="custom_newcmd_grp">
                                    <InputGroup>
                                        <InputGrpButton
                                            className="bg-warning cursor-pointer text-bold"
                                            cb={() =>
                                                changeQuantity(
                                                    { target: { value: +rnData.qteCommande - 1 } },
                                                    handleChange,
                                                    values,
                                                    rnData,
                                                    rnData.originalIndex,
                                                )
                                            }
                                            disabled={rnData.qteCommande === 0}
                                            text="-"
                                        />
                                        <Field
                                            name={`colisCommandes[${rnData.originalIndex}].qteCommande`}
                                            type="number"
                                            min={1}
                                            max={rnData.productDetails?.qteResteAcmd}
                                            placeholder="Quantity"
                                            className={`form-control ${!!rnErrors ? ' is-invalid' : ''}`}
                                            value={+rnData.qteCommande}
                                            onChange={(e: any) =>
                                                changeQuantity(e, handleChange, values, rnData, rnData.originalIndex)
                                            }
                                        />
                                        <InputGrpButton
                                            className="bg-success cursor-pointer text-bold"
                                            cb={() =>
                                                changeQuantity(
                                                    { target: { value: +rnData.qteCommande + 1 } },
                                                    handleChange,
                                                    values,
                                                    rnData,
                                                    rnData.originalIndex,
                                                )
                                            }
                                            disabled={+rnData.productDetails?.qteResteAcmd === +rnData.qteCommande}
                                            text="+"
                                        />
                                    </InputGroup>
                                </div>
                                {currentColis?.dateRec && (
                                    <Field
                                        name={`colisCommandes[${rnData.originalIndex}].productDetails.qteResteAcmd`}
                                        type="number"
                                        min={1}
                                        placeholder="Quantity"
                                        className={`form-control max-newcmd-width-field`}
                                        readOnly
                                    />
                                )}
                            </InputGroup>
                        );
                    })
                }
            />
        </>
    );
};
