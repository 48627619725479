import React, { useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import * as yup from 'yup';
import { Formik } from 'formik';
import { createDemandeRetourStock } from 'services/demandeRetourStock.service';

const schema = (qteMax: number) =>
    yup.object({
        qte: yup.number().min(1).max(qteMax).required(),
    });

type Props = {
    produit: any;
    setProduits: React.Dispatch<React.SetStateAction<any>>;
    closeModal: () => void;
};

const DemandeRetourStockFormCreation: React.FC<Props> = ({ produit, setProduits, closeModal }: Props) => {
    const [spinIt, setSpinIt] = useState<boolean>(false);
    const submitForm = async (formValues: { qte: number }) => {
        try {
            setSpinIt(true);
            createDemandeRetourStock({ qte: formValues.qte, idProduit: produit.idProduit })
                .then(() => {
                    if (+produit.colisproduitenvoyes.qteResteAcmd === +formValues.qte) {
                        setProduits((prev: any) => {
                            const updating = prev.items.filter((item: any) => +item.idProduit !== +produit.idProduit);
                            return { items: updating, count: updating.length };
                        });
                    } else {
                        setProduits((prev: any) => {
                            const index = prev.items.findIndex((item: any) => +item.idProduit === +produit.idProduit);
                            if (index >= 0) {
                                const updating = [...prev.items];
                                updating[index].colisproduitenvoyes[0].qteResteAcmd =
                                    updating[index].colisproduitenvoyes[0].qteResteAcmd - +formValues.qte;
                                updating[index].colisproduitenvoyes[0].qteNonExpide =
                                    updating[index].colisproduitenvoyes[0].qteNonExpide - +formValues.qte;
                                return { items: updating, count: updating.length };
                            }
                            return prev;
                        });
                    }
                    toast.success('Demande a été  créée avec succès');
                    setSpinIt(false);
                    closeModal();
                })
                .catch(() => {
                    setSpinIt(false);
                });
        } catch (err) {
            console.log(err);
            setSpinIt(false);
        }
    };

    return (
        <Formik
            validationSchema={schema(produit.colisproduitenvoyes.qteResteAcmd)}
            onSubmit={(values) => submitForm(values)}
            enableReinitialize={true}
            initialValues={{
                qte: produit.colisproduitenvoyes.qteResteAcmd, // You can set an initial quantity value here
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group controlId="exampleForm.ControlQuantity">
                        <Form.Label>Quantité</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Quantité"
                            name="qte"
                            value={values.qte}
                            onChange={handleChange}
                            min={1}
                            max={produit.colisproduitenvoyes.qteResteAcmd}
                            isInvalid={!!errors.qte}
                        />
                    </Form.Group>
                    <div className="d-grid gap-2 mt-3">
                        <Button variant="primary" type="submit" disabled={!!spinIt}>
                            Enregistrer
                            {!!spinIt && (
                                <>
                                    &nbsp; <Spinner animation="border" size="sm" />
                                </>
                            )}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default DemandeRetourStockFormCreation;
