import ImageElement from 'components/UI/imageElement';
import moment from 'moment';
import React from 'react';
import { Col, Form, InputGroup, OverlayTrigger, Row, Table, Tooltip, Image } from 'react-bootstrap';
import { AxiosResponse } from 'axios';

import ColisProduitEnvoyeInterface from 'types/interfaces/colisProduitEnvoye.interface';
import ColisProduitRecuInterface from 'types/interfaces/colisProduitRecu.interface';
import CommandeInterface from 'types/interfaces/commande.interface';
import ColisRecuFileInterface from 'types/interfaces/colisRecuFile.interface';
import { getColisRecuFileById } from 'services/colisRecuFile.service';

import { DisplayName } from 'utils/helpers';

type Props = {
    currentCommande: CommandeInterface;
    setCurrentCommande: (value: CommandeInterface | null) => void;
    setCommandes: (value: { items: CommandeInterface[]; count: number }) => void;
    commandes: { items: CommandeInterface[]; count: number };
};
const CommandeColisInfo: React.FC<Props> = ({
    currentCommande,
    setCurrentCommande,
    setCommandes,
    commandes,
}: Props) => {
    const loadImageRecu = (id: number) => {
        getColisRecuFileById(id)
            .then((response: AxiosResponse<ColisRecuFileInterface | any>) => {
                const { data } = response;
                const cmdData = [...commandes.items];
                const cmdIndex = cmdData.findIndex(
                    (d: CommandeInterface) => d.idCommande == currentCommande?.idCommande!,
                );
                cmdData[cmdIndex].colis.colisRecuFile = data;
                setCurrentCommande(cmdData[cmdIndex]);
                setCommandes({ items: cmdData, count: commandes.count });
            })
            .catch();
    };

    return (
        <div className="d-grid gap-2 mt-3">
            <Row>
                <Col md="2" lg="2">
                    <InputGroup className="align-items-center">
                        Réf: &nbsp;
                        <Form.Control
                            size="sm"
                            type="text"
                            name="refColis"
                            placeholder="RefColis"
                            value={currentCommande.colis.refColis}
                            onChange={() => void 0}
                            disabled
                        />
                    </InputGroup>
                </Col>
                <Form.Group as={Col} md="2" lg="2" sm="4" controlId="form_grp_fragile">
                    <Form.Check
                        type="checkbox"
                        label="Fragile"
                        name="fragile"
                        checked={currentCommande.colis.fragile}
                        readOnly
                    />
                </Form.Group>
                <Form.Group as={Col} md="2" lg="2" sm="4" controlId="form_grp_aOuvrir">
                    <Form.Check
                        type="checkbox"
                        label="À Ouvrir"
                        name="aOuvrir"
                        checked={currentCommande.colis.aOuvrir}
                        readOnly
                    />
                </Form.Group>
                <Form.Group as={Col} md="2" lg="2" sm="4" controlId="form_grp_aRecuperer">
                    <Form.Check
                        type="checkbox"
                        label="À Récupérer"
                        name="aRecuperer"
                        checked={currentCommande.colis.aRecuperer}
                        readOnly
                    />
                </Form.Group>
                <Form.Group as={Col} md="4" lg="4" controlId="form_grp_commentaire">
                    <Form.Control
                        size="sm"
                        as="textarea"
                        rows={2}
                        name="commentaire"
                        placeholder="Commentaire"
                        value={currentCommande.colis.commentaire}
                        disabled
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="3" lg="3" controlId="form_grp_dateEnregistrement">
                    <Form.Label>Date d&apos;Enregistrement</Form.Label>
                    <Form.Control
                        size="sm"
                        type="text"
                        name="dateEnregistrement"
                        value={
                            currentCommande.colis.dateEnregistrement
                                ? moment(currentCommande.colis.dateEnregistrement).format('DD/MM/YYYY HH:mm')
                                : '-'
                        }
                        onChange={() => void 0}
                        disabled
                    />
                </Form.Group>
                <Form.Group as={Col} md="3" lg="3" controlId="form_grp_dateEnvoi">
                    <Form.Label>Date d&apos;Envoi</Form.Label>
                    <Form.Control
                        size="sm"
                        type="text"
                        name="dateEnvoi"
                        value={
                            currentCommande.colis.dateEnvoi
                                ? moment(currentCommande.colis.dateEnvoi).format('DD/MM/YYYY HH:mm')
                                : '-'
                        }
                        onChange={() => void 0}
                        disabled
                    />
                </Form.Group>
                <Form.Group as={Col} md="3" lg="3" controlId="form_grp_dateRamassage">
                    <Form.Label>Date de Ramassage</Form.Label>
                    <Form.Control
                        size="sm"
                        type="text"
                        name="dateRamassage"
                        value={
                            currentCommande.colis?.ramassage?.dateRamassage
                                ? moment(currentCommande.colis?.ramassage.dateRamassage!).format('DD/MM/YYYY HH:mm')
                                : '-'
                        }
                        onChange={() => void 0}
                        disabled
                    />
                </Form.Group>
                <Form.Group as={Col} md="3" lg="3" controlId="form_grp_dateRec">
                    <Form.Label>Date de réception</Form.Label>
                    <Form.Control
                        size="sm"
                        type="text"
                        name="dateRec"
                        value={
                            currentCommande.colis.dateRec
                                ? moment(currentCommande.colis.dateRec).format('DD/MM/YYYY HH:mm')
                                : '-'
                        }
                        onChange={() => void 0}
                        disabled
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="2" controlId="form_grp_dateRecuPrevu">
                    <Form.Label>Date de réception prévu</Form.Label>
                    <Form.Control
                        size="sm"
                        type="text"
                        name="dateRecuPrevu"
                        value={
                            currentCommande.colis.dateRecuPrevu
                                ? moment(currentCommande.colis.dateRecuPrevu).format('DD/MM/YYYY HH:mm')
                                : '-'
                        }
                        onChange={() => void 0}
                        disabled
                    />
                </Form.Group>
                <Form.Group as={Col} md="2" controlId="form_grp_moyenTransport">
                    <Form.Label>Moyen de Transport</Form.Label>
                    <Form.Control
                        size="sm"
                        type="text"
                        name="moyenTransport"
                        placeholder="Moyen de Transport"
                        value={currentCommande.colis.moyenTransport}
                        disabled
                    />
                </Form.Group>
                <Form.Group as={Col} md="2" controlId="form_grp_paye">
                    <Form.Label>Payé</Form.Label>
                    <Form.Check type="checkbox" name="paye" checked={currentCommande.colis.paye} disabled />
                </Form.Group>
                <Form.Group as={Col} md="2" controlId="form_grp_montentEnvoi">
                    <Form.Label>Montant d&apos;envoi</Form.Label>
                    <Form.Control
                        size="sm"
                        type="number"
                        min={0}
                        name="montantEnvoi"
                        placeholder="Montant d'envoi"
                        value={currentCommande.colis.montantEnvoi}
                        disabled
                    />
                </Form.Group>
                <Form.Group as={Col} md="2" controlId="form_grp_montentEnvoi">
                    <Form.Label>Ramasseur</Form.Label>
                    <Form.Control
                        size="sm"
                        type="text"
                        min={0}
                        name="ramasseur"
                        value={
                            currentCommande.colis.ramassage
                                ? DisplayName(currentCommande.colis.ramassage?.livreur!)
                                : '-'
                        }
                        disabled
                    />
                </Form.Group>
                <Form.Group as={Col} md="2" controlId="form_grp_montentEnvoi">
                    <Form.Label>Représentant</Form.Label>
                    <Form.Control
                        size="sm"
                        type="text"
                        min={0}
                        name="ramasseur"
                        value={
                            currentCommande.colis.represenatant
                                ? DisplayName(currentCommande.colis.represenatant!)
                                : '-'
                        }
                        disabled
                    />
                </Form.Group>
            </Row>
            <Table striped responsive>
                <thead>
                    <tr>
                        <th>Ref</th>
                        <th>Designation</th>
                        <th>PrixVente</th>
                        <th>Quantité</th>
                        <th>Quantité Recu</th>
                        <th>Quantité resté à commander</th>
                        <th>Emplacement</th>
                    </tr>
                </thead>
                <tbody>
                    {currentCommande.colis.colisproduitenvoyes.map((d: ColisProduitEnvoyeInterface, index: number) => (
                        <tr key={index}>
                            <td>
                                {index === 0 &&
                                currentCommande.colis.colisproduitenvoyes.length === 1 &&
                                d.produit?.ref === 'DP-X0000X'
                                    ? '-'
                                    : d.produit?.ref}
                            </td>
                            <td>{d.produit?.designation}</td>
                            <td>{d.produit?.prixVente} DH</td>
                            <td>{d.quantiteEnvoi}</td>
                            <td>{d.quantiteRecu}</td>
                            <td>{d.qteResteAcmd}</td>
                            <td>
                                {currentCommande.colis?.colisproduitrecus?.length! > 0
                                    ? currentCommande.colis?.colisproduitrecus?.find(
                                          (dd: ColisProduitRecuInterface) => dd.idProduit === d.idProduit,
                                      )?.emplacement?.libelle ?? '-'
                                    : '-'}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Row>
                <Col md="4" lg="4">
                    <InputGroup className="align-items-center">
                        Agence: &nbsp;
                        <Form.Control
                            size="sm"
                            type="text"
                            name="refColis"
                            value={currentCommande.colis.agence?.libelle ?? '-'}
                            onChange={() => void 0}
                            disabled
                        />
                    </InputGroup>
                </Col>
                <Col md="4" lg="4">
                    <InputGroup className="align-items-center">
                        Ville, Region: &nbsp;
                        <Form.Control
                            size="sm"
                            type="text"
                            name="refColis"
                            placeholder="RefColis"
                            value={
                                currentCommande.colis.agence
                                    ? `${currentCommande.colis.agence?.ville?.nomVille}, ${currentCommande.colis.agence?.ville?.region?.nomRegion}`
                                    : '-'
                            }
                            onChange={() => void 0}
                            disabled
                        />
                    </InputGroup>
                </Col>
                <Col md="4" lg="4">
                    <InputGroup className="align-items-center">
                        Client: &nbsp;
                        <Form.Control
                            size="sm"
                            type="text"
                            name="refColis"
                            placeholder="RefColis"
                            value={currentCommande.client?.raisonSocial}
                            onChange={() => void 0}
                            disabled
                        />
                    </InputGroup>
                </Col>
            </Row>
            {currentCommande.colis?.idColisRecuFile && (
                <>
                    <hr />
                    <h6>Fichier Récu :</h6>
                    <Row>
                        <Col className="text-center">
                            {currentCommande.colis?.colisRecuFile ? (
                                <ImageElement
                                    file={
                                        typeof currentCommande.colis?.colisRecuFile.fileImage === 'string' &&
                                        !(currentCommande.colis?.colisRecuFile.fileImage as string).startsWith(
                                            'data:image',
                                        )
                                            ? `data:image/jpeg;base64,${currentCommande.colis?.colisRecuFile.fileImage}`
                                            : currentCommande.colis?.colisRecuFile.fileImage
                                    }
                                    alt="file recu"
                                    fluid
                                />
                            ) : (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="tooltip-image-preview">Afficher le recu.</Tooltip>}
                                >
                                    <Image
                                        src="/images/before-preview.jpg"
                                        thumbnail
                                        onClick={() => loadImageRecu(currentCommande.colis?.idColisRecuFile!)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </OverlayTrigger>
                            )}
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
};

export default CommandeColisInfo;
