import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { AxiosResponse } from 'axios';
import { Col, Form, Row, Tab, Tabs, Button, InputGroup, Table, Alert, Accordion, Badge } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { ROLES as RolesMap } from 'constantes/permission-maps';
import {
    sortByDueDate,
    utf8_to_b64,
    siEncoursLivraison,
    getFournisseurAliasUrl,
    isTheOwnerOfCommande,
    isSameFournisseurEmployee,
    canControlCommande,
    isTransfertFound,
    DisplayName,
    isCommandeActionsBlocked,
} from 'utils/helpers';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import PermissionsGate from 'hoc/permissionsGate';
import BarCode from 'components/UI/barCode';
import QrCode from 'components/UI/qrCode';
import TimeLine from 'components/UI/timeLine';
import StatusButton from 'pages/Commande/components/StatusButton';
import DeleteButton from 'pages/Commande/components/DeleteButton';
import TransfertInfo from 'pages/Commande/components/TransfertInfo';
import AffectationLivreur from 'pages/Commande/components/AffectationLivreur';
import CommandeColisInfo from 'pages/Commande/components/CommandeColisInfo';
import CommandeFactureInfo from 'pages/Commande/components/CommandeFactureInfo';
import ContacterAcheteur from 'pages/Commande/components/ContacterAcheteur';
import ColisCommandeInterface from 'types/interfaces/colisCommande.interface';
import CommandeInterface, { CommandePatchInterface } from 'types/interfaces/commande.interface';
import CommandeTarifInterface from 'types/interfaces/commandeTarif.interface';
import EtatTitreInterface from 'types/interfaces/etatTitre.interface';
import { patchCommande } from 'services/commande.service';
import { getEtatTitres } from 'services/etatTitre.service';
import UserInterface from 'types/interfaces/user.interface';
import { getLivreursByAgence, getLivreursByFournisseur } from 'services/user.service';
import { getTransfertTarifFromCommande } from 'pages/FactureLivreur/components/CommandeTarifs';
type Props = {
    commandes: { items: CommandeInterface[]; count: number };
    setCommandes: (value: { items: CommandeInterface[]; count: number }) => void;
    currentCommande: CommandeInterface;
    setCurrentCommande: (value: CommandeInterface | null) => void;
    setCheckedCommandes: (value: number[]) => void;
    directLivreurs?: UserInterface[];
};

const CommandeFormComponent: React.FC<Props> = ({
    commandes,
    setCommandes,
    currentCommande,
    setCurrentCommande,
    setCheckedCommandes,
    directLivreurs,
}: Props) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    const [etatTitres, setEtatTitres] = useState<EtatTitreInterface[]>([]);
    const [livreurs, setLivreurs] = useState<UserInterface[]>(directLivreurs!);

    const [spinIt, setSpinIt] = useState<boolean>(false);

    useEffect(() => {
        getEtatTitres()
            .then((response: AxiosResponse<EtatTitreInterface[] | any>) => {
                const { data } = response;
                setEtatTitres(data);
            })
            .catch();
    }, []);

    useEffect(() => {
        if (typeof directLivreurs === 'undefined') {
            if (contextUser && contextUser.userData.type === 'Fournisseur') {
                if (
                    contextUser?.userData?.userroles?.some(
                        (d) =>
                            d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                            d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE,
                    )
                ) {
                    getLivreursByFournisseur(contextUser?.userData?.idFournisseur!)
                        .then((response: AxiosResponse<UserInterface[] | any>) => {
                            const { data } = response;
                            setLivreurs(data);
                        })
                        .catch();
                } else {
                    getLivreursByAgence(contextUser?.userData?.idAgence!)
                        .then((response: AxiosResponse<UserInterface[] | any>) => {
                            const { data } = response;
                            setLivreurs(data);
                        })
                        .catch();
                }
            }
        }
    }, [contextUser, directLivreurs]);
    const doCommandePatch = async (id: number, patchData: CommandePatchInterface) => {
        try {
            setSpinIt(true);
            const response = await patchCommande(id, patchData);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Commande a été modifiée');
                setCurrentCommande(resultData);
                setSpinIt(false);
                setCheckedCommandes([]);
                const cmdData: CommandeInterface[] = [...commandes.items].map((dd) =>
                    dd.idCommande === resultData.idCommande ? resultData : dd,
                );
                setCommandes({ items: cmdData, count: commandes.count });
            }
        } catch (Exception) {
            setSpinIt(false);
            const { message = undefined }: any = Exception;
            toast.error(message || 'Error occured!');
        }
    };

    return (
        <Tabs defaultActiveKey="cmd" transition={false} id="livraison_tabs" className="mt-1">
            <Tab eventKey="cmd" title="Commande Info" className="tab-content-border">
                <Row>
                    <Col sm="12" md="9" lg="9">
                        <Row>
                            <Col className="align-self-center">
                                CMD-Ref: <b>{currentCommande?.ref ?? '-'}</b>
                            </Col>
                            <Col className="align-self-center">
                                <BarCode value={currentCommande?.code!} options={{ height: 25, fontSize: 15 }} />
                            </Col>
                            <Col className="text-end align-self-center">
                                <QrCode
                                    value={`${getFournisseurAliasUrl(
                                        contextUser?.userData!,
                                    )}/commandes?open=${utf8_to_b64(currentCommande?.idCommande?.toString()!)}`}
                                    options={{ size: 50 }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="8">
                                {currentCommande?.colis?.dateRec &&
                                    currentCommande?.idEtatCommande === 2 &&
                                    currentCommande?.coliscommandes.some(
                                        (d: ColisCommandeInterface) =>
                                            d.colisproduitenvoye?.quantiteEnvoi! >
                                                d.colisproduitenvoye?.quantiteRecu! &&
                                            d.qteCommande > d.colisproduitenvoye?.qteNonExpide!,
                                        /*
                                            &&
                                            d.colisproduitenvoye?.quantiteEnvoi! - d.colisproduitenvoye?.quantiteRecu! >
                                                d.qteCommande - d.colisproduitenvoye?.qteNonExpide!,
                                            */
                                    ) && (
                                        <Alert variant="warning ">La quantité reçue n&apos;est pas suffisante..!</Alert>
                                    )}
                            </Col>
                            <Col md="4" className="d-flex align-items-start gap-2 justify-content-end">
                                <DeleteButton
                                    commandes={commandes}
                                    setCommandes={(value: { items: CommandeInterface[]; count: number }) =>
                                        setCommandes(value)
                                    }
                                    currentCommande={currentCommande!}
                                    setCheckedCommandes={(value: number[]) => setCheckedCommandes(value)}
                                />
                                {currentCommande?.idEtatCommande && currentCommande?.idEtatCommande < 4 && (
                                    <>
                                        {contextUser?.userData.type === 'Fournisseur' &&
                                            contextUser?.userData?.userroles?.some(
                                                (d) =>
                                                    d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                                                    d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE ||
                                                    d.role.nomRole === RolesMap.CLIENT_ADMIN,
                                            ) &&
                                            currentCommande?.idEtatCommande === 1 &&
                                            currentCommande?.transfertCommandes?.length === 0 && (
                                                <StatusButton
                                                    commandes={commandes}
                                                    setCommandes={(value: {
                                                        items: CommandeInterface[];
                                                        count: number;
                                                    }) => setCommandes(value)}
                                                    currentCommande={currentCommande!}
                                                    setCurrentCommande={(value: CommandeInterface | null) =>
                                                        setCurrentCommande(value)
                                                    }
                                                    setCheckedCommandes={(value: number[]) =>
                                                        setCheckedCommandes(value)
                                                    }
                                                    status="annuler"
                                                    buttonText="Annuler"
                                                    buttonVariant="danger"
                                                />
                                            )}
                                        {contextUser?.userData?.userroles?.some(
                                            (d) =>
                                                d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                                                d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE ||
                                                d.role.nomRole === RolesMap.FOURNISSEUR_LIVREUR,
                                        ) &&
                                            currentCommande?.colis?.dateRec &&
                                            currentCommande?.idEtatCommande === 2 &&
                                            canControlCommande(
                                                contextUser?.userData?.idFournisseur!,
                                                currentCommande!,
                                            ) && (
                                                <>
                                                    <StatusButton
                                                        commandes={commandes}
                                                        setCommandes={(value: {
                                                            items: CommandeInterface[];
                                                            count: number;
                                                        }) => setCommandes(value)}
                                                        currentCommande={currentCommande!}
                                                        setCurrentCommande={(value: CommandeInterface | null) =>
                                                            setCurrentCommande(value)
                                                        }
                                                        setCheckedCommandes={(value: number[]) =>
                                                            setCheckedCommandes(value)
                                                        }
                                                        status="retour-init"
                                                        buttonText="Retour agence!"
                                                        buttonVariant="outline-warning"
                                                    />
                                                    <StatusButton
                                                        commandes={commandes}
                                                        setCommandes={(value: {
                                                            items: CommandeInterface[];
                                                            count: number;
                                                        }) => setCommandes(value)}
                                                        currentCommande={currentCommande!}
                                                        setCurrentCommande={(value: CommandeInterface | null) =>
                                                            setCurrentCommande(value)
                                                        }
                                                        setCheckedCommandes={(value: number[]) =>
                                                            setCheckedCommandes(value)
                                                        }
                                                        status="expider"
                                                        buttonText={
                                                            currentCommande?.coliscommandes.some(
                                                                (d: ColisCommandeInterface) =>
                                                                    d.colisproduitenvoye?.quantiteEnvoi! -
                                                                        d.colisproduitenvoye?.quantiteRecu! >
                                                                    d.qteCommande - d.colisproduitenvoye?.qteNonExpide!,
                                                            )
                                                                ? 'Expédier !!'
                                                                : 'Expédier'
                                                        }
                                                        buttonVariant={
                                                            currentCommande?.coliscommandes.some(
                                                                (d: ColisCommandeInterface) =>
                                                                    d.colisproduitenvoye?.quantiteEnvoi! -
                                                                        d.colisproduitenvoye?.quantiteRecu! >
                                                                    d.qteCommande - d.colisproduitenvoye?.qteNonExpide!,
                                                            )
                                                                ? 'warning'
                                                                : 'info'
                                                        }
                                                    />
                                                </>
                                            )}
                                        {contextUser?.userData.type === 'Fournisseur' &&
                                            contextUser?.userData?.userroles?.some(
                                                (d) =>
                                                    d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                                                    d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                            ) &&
                                            currentCommande?.idEtatCommande === 1 &&
                                            currentCommande?.transfertCommandes?.length === 0 && (
                                                <StatusButton
                                                    commandes={commandes}
                                                    setCommandes={(value: {
                                                        items: CommandeInterface[];
                                                        count: number;
                                                    }) => setCommandes(value)}
                                                    currentCommande={currentCommande!}
                                                    setCurrentCommande={(value: CommandeInterface | null) =>
                                                        setCurrentCommande(value)
                                                    }
                                                    setCheckedCommandes={(value: number[]) =>
                                                        setCheckedCommandes(value)
                                                    }
                                                    status="refuser"
                                                    buttonText="Refuser"
                                                    buttonVariant="secondary"
                                                />
                                            )}
                                        {currentCommande?.idEtatCommande === 3 &&
                                            contextUser?.userData?.userroles?.some(
                                                (d) =>
                                                    d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                                                    d.role.nomRole === RolesMap.FOURNISSEUR_LIVREUR,
                                            ) &&
                                            canControlCommande(
                                                contextUser?.userData?.idFournisseur!,
                                                currentCommande!,
                                            ) && (
                                                <>
                                                    {siEncoursLivraison(currentCommande?.commandeActions!) ? (
                                                        <StatusButton
                                                            commandes={commandes}
                                                            setCommandes={(value: {
                                                                items: CommandeInterface[];
                                                                count: number;
                                                            }) => setCommandes(value)}
                                                            currentCommande={currentCommande!}
                                                            setCurrentCommande={(value: CommandeInterface | null) =>
                                                                setCurrentCommande(value)
                                                            }
                                                            setCheckedCommandes={(value: number[]) =>
                                                                setCheckedCommandes(value)
                                                            }
                                                            status="livrer"
                                                            buttonText="Livrer"
                                                            buttonVariant="success"
                                                        />
                                                    ) : (
                                                        <StatusButton
                                                            commandes={commandes}
                                                            setCommandes={(value: {
                                                                items: CommandeInterface[];
                                                                count: number;
                                                            }) => setCommandes(value)}
                                                            currentCommande={currentCommande!}
                                                            setCurrentCommande={(value: CommandeInterface | null) =>
                                                                setCurrentCommande(value)
                                                            }
                                                            setCheckedCommandes={(value: number[]) =>
                                                                setCheckedCommandes(value)
                                                            }
                                                            status="before-livrer"
                                                            buttonText="En cours de livraison"
                                                            buttonVariant="success"
                                                        />
                                                    )}
                                                </>
                                            )}
                                        {currentCommande?.idEtatCommande === 3 &&
                                            contextUser?.userData?.userroles?.some(
                                                (d) =>
                                                    d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                                                    d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                            ) &&
                                            canControlCommande(
                                                contextUser?.userData?.idFournisseur!,
                                                currentCommande!,
                                            ) && (
                                                <>
                                                    {!currentCommande?.livreur?.requireDr! && (
                                                        <StatusButton
                                                            commandes={commandes}
                                                            setCommandes={(value: {
                                                                items: CommandeInterface[];
                                                                count: number;
                                                            }) => setCommandes(value)}
                                                            currentCommande={currentCommande!}
                                                            setCurrentCommande={(value: CommandeInterface | null) =>
                                                                setCurrentCommande(value)
                                                            }
                                                            setCheckedCommandes={(value: number[]) =>
                                                                setCheckedCommandes(value)
                                                            }
                                                            status="retour-stock"
                                                            buttonText="Retour agence"
                                                            buttonVariant="outline-warning"
                                                        />
                                                    )}
                                                </>
                                            )}
                                    </>
                                )}
                                {currentCommande &&
                                currentCommande?.idEtatCommande === 4 &&
                                !isCommandeActionsBlocked(currentCommande!) &&
                                canControlCommande(contextUser?.userData?.idFournisseur!, currentCommande!) &&
                                contextUser?.userData?.userroles?.some(
                                    (d) =>
                                        d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                                        d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                ) ? (
                                    <StatusButton
                                        commandes={commandes}
                                        setCommandes={(value: { items: CommandeInterface[]; count: number }) =>
                                            setCommandes(value)
                                        }
                                        currentCommande={currentCommande!}
                                        setCurrentCommande={(value: CommandeInterface | null) =>
                                            setCurrentCommande(value)
                                        }
                                        setCheckedCommandes={(value: number[]) => setCheckedCommandes(value)}
                                        status="error-livraison"
                                        buttonText="Erreur"
                                        buttonVariant="danger"
                                    />
                                ) : (
                                    <></>
                                )}
                            </Col>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="2" controlId="form_grp_villle">
                                <Form.Label>Région</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    name="ville"
                                    value={currentCommande?.ville?.region?.nomRegion}
                                    onChange={() => void 0}
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="2" controlId="form_grp_villle">
                                <Form.Label>Ville</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    name="ville"
                                    value={currentCommande?.ville?.nomVille}
                                    onChange={() => void 0}
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="form_grp_commentaire">
                                <Form.Label>Commentaire</Form.Label>
                                <Form.Control
                                    size="sm"
                                    as="textarea"
                                    rows={2}
                                    name="commentaire"
                                    placeholder="Commentaire"
                                    value={currentCommande?.commentaire}
                                    disabled
                                />
                            </Form.Group>
                            <Col md={4}>
                                <Row className="my-1">
                                    <Col className="d-flex justify-content-between align-items-center flex-wrap">
                                        <span>Date de création:</span>
                                        <div className="d-inline-block commande-detail-form-control commande-detail-form-control-sm  ms-auto">
                                            {moment(currentCommande?.dateCreation!).format('DD/MM/YYYY HH:mm')}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-between align-items-center flex-wrap">
                                        <span>Etat:</span>
                                        <div className="d-inline-block commande-detail-form-control commande-detail-form-control-sm ms-auto">
                                            {`${currentCommande?.etatCommande?.etatCommande} (${moment(
                                                currentCommande?.dateOperation!,
                                            ).format('DD/MM/YYYY HH:mm')})`}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="8">
                                <Table striped responsive>
                                    <thead>
                                        <tr>
                                            <th>Ref</th>
                                            <th>Désignation</th>
                                            <th>PrixVente</th>
                                            <th>Quantité</th>
                                            <th>Qte Reçu (Colis)</th>
                                            <th>A commander (Colis)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentCommande?.coliscommandes?.map(
                                            (d: ColisCommandeInterface, index: number) => (
                                                <tr key={index}>
                                                    <td>
                                                        <span className="my-xsm-font">
                                                            {index === 0 &&
                                                            currentCommande?.coliscommandes?.length === 1 &&
                                                            d.colisproduitenvoye.produit?.ref === 'DP-X0000X'
                                                                ? '-'
                                                                : d.colisproduitenvoye.produit?.ref}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="my-xsm-font">
                                                            {d.colisproduitenvoye.produit?.designation}{' '}
                                                        </span>
                                                    </td>
                                                    <td>{d.colisproduitenvoye.produit?.prixVente} DH</td>
                                                    <td>{d.qteCommande}</td>
                                                    <td>{d.colisproduitenvoye.quantiteRecu}</td>
                                                    <td>{d.colisproduitenvoye.qteResteAcmd}</td>
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col md="4" className="send2end">
                                <PermissionsGate
                                    scopes={[
                                        RolesMap.FOURNISSEUR_ADMIN,
                                        RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                        RolesMap.FOURNISSEUR_FACTURE,
                                        RolesMap.CLIENT_ADMIN,
                                    ]}
                                    strict
                                >
                                    <h5 className="text-center border-bottom">Services</h5>
                                    {contextUser &&
                                        currentCommande &&
                                        (contextUser?.userData.type === 'Client' ||
                                            isTheOwnerOfCommande(
                                                contextUser?.userData?.idFournisseur!,
                                                currentCommande!,
                                            )) &&
                                        currentCommande?.commandetarifs?.map((d: CommandeTarifInterface) => (
                                            <Row className=" g-3 align-items-center" key={d.tarif.idTarif}>
                                                <Col auto>{d.tarif?.optionService?.nameOptionService} :</Col>
                                                <Col auto className="text-end">
                                                    {d.tarif?.prixTarif} DH
                                                </Col>
                                            </Row>
                                        ))}
                                    <Row className="g-3 align-items-center">
                                        <Col auto>
                                            <strong>Prix des services:</strong>
                                        </Col>
                                        <Col auto className="text-end">
                                            <strong>
                                                {currentCommande && contextUser && (
                                                    <>
                                                        {contextUser?.userData.type === 'Client' ||
                                                        isTheOwnerOfCommande(
                                                            contextUser?.userData?.idFournisseur!,
                                                            currentCommande!,
                                                        )
                                                            ? (currentCommande?.commandetarifs ?? []).reduce(
                                                                  (accumulator: number, object: any) => {
                                                                      return accumulator + object.tarif?.prixTarif;
                                                                  },
                                                                  0,
                                                              )
                                                            : getTransfertTarifFromCommande(
                                                                  currentCommande!,
                                                                  contextUser?.userData!,
                                                              )}{' '}
                                                        DH
                                                    </>
                                                )}
                                            </strong>
                                        </Col>
                                    </Row>
                                </PermissionsGate>
                                <Form.Group controlId="form_grp_prixTotal">
                                    <Row className=" g-3 align-items-center">
                                        <Col auto>
                                            <Form.Label style={{ fontWeight: 700, fontSize: '1rem' }}>Total</Form.Label>
                                        </Col>
                                        <Col auto>
                                            {currentCommande && contextUser && (
                                                <InputGroup>
                                                    <Form.Control
                                                        size="sm"
                                                        className="text-end"
                                                        type="number"
                                                        min={0}
                                                        name="prixTotal"
                                                        placeholder="Prix Tarif"
                                                        value={currentCommande?.prixTotal!}
                                                        disabled={
                                                            !isTheOwnerOfCommande(
                                                                contextUser?.userData?.idFournisseur!,
                                                                currentCommande!,
                                                            ) ||
                                                            !contextUser?.userData?.userroles?.some(
                                                                (d) => d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN,
                                                            ) ||
                                                            currentCommande?.idEtatCommande! >= 4
                                                        }
                                                        onChange={(e: any) => {
                                                            setCurrentCommande({
                                                                ...currentCommande!,
                                                                prixTotal: e.target.value,
                                                            });
                                                        }}
                                                    />
                                                    <InputGroup.Text>DH</InputGroup.Text>
                                                </InputGroup>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="6" controlId="form_grp_none">
                                <Tabs
                                    defaultActiveKey="acheteur"
                                    transition={false}
                                    id="acheteur_tabs"
                                    className="mt-1"
                                >
                                    <Tab eventKey="acheteur" title="Acheteur" className="tab-content-border">
                                        <Row>
                                            <Form.Group as={Col} md="6" controlId="form_grp_gsm">
                                                <Form.Label>GSM</Form.Label>
                                                <Form.Control
                                                    type="gsm"
                                                    name="acheteur.gsm"
                                                    placeholder="Gsm"
                                                    value={currentCommande?.acheteur?.gsm}
                                                    size="sm"
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="form_grp_email">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="acheteur.email"
                                                    placeholder="Email"
                                                    value={currentCommande?.acheteur?.email}
                                                    size="sm"
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} md="12" controlId="form_grp_fullName">
                                                <Form.Label>Nom</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="acheteur.fullName"
                                                    placeholder="Nom"
                                                    value={currentCommande?.acheteur?.fullName!}
                                                    size="sm"
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Form.Group controlId="form_grp_acheteur_adresse">
                                            <Form.Label>Adresse</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                as="textarea"
                                                rows={2}
                                                name="acheteur.addresse"
                                                placeholder="Adresse"
                                                value={currentCommande?.acheteur?.addresse}
                                            />
                                        </Form.Group>
                                    </Tab>
                                </Tabs>
                            </Form.Group>
                            <Col md="6" className="mt-1">
                                <ContacterAcheteur
                                    currentCommande={currentCommande!}
                                    phone={currentCommande?.acheteur?.gsm!}
                                />
                                <Form.Group controlId="form_grp_adresse">
                                    <Form.Label>Adresse de livraison</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        as="textarea"
                                        rows={2}
                                        name="adresse"
                                        placeholder="Adresse de livraison"
                                        value={currentCommande?.adresse}
                                        disabled={
                                            !contextUser?.userData?.userroles?.some(
                                                (d) => d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN,
                                            ) || currentCommande?.idEtatCommande! >= 4
                                        }
                                        onChange={(e: any) => {
                                            setCurrentCommande({
                                                ...currentCommande!,
                                                adresse: e.target.value,
                                            });
                                        }}
                                    />
                                </Form.Group>
                                <InputGroup className="align-items-center mt-2">
                                    <Col auto>Agent client</Col>
                                    <Col auto>
                                        <Form.Control
                                            size="sm"
                                            className="text-end"
                                            type="text"
                                            name="AgentClient"
                                            value={
                                                currentCommande?.clientAgent
                                                    ? contextUser?.userData.type === 'Client' ||
                                                      isTheOwnerOfCommande(
                                                          contextUser?.userData?.idFournisseur!,
                                                          currentCommande,
                                                      )
                                                        ? DisplayName(currentCommande?.clientAgent!)
                                                        : '****'
                                                    : '-'
                                            }
                                            disabled
                                        />
                                    </Col>
                                </InputGroup>
                                <InputGroup className="align-items-center mt-2">
                                    <Col auto>Livreur</Col>
                                    <Col auto>
                                        <div className="d-flex gap-1">
                                            <Form.Control
                                                size="sm"
                                                className="text-end"
                                                type="text"
                                                name="userLivreur"
                                                value={
                                                    currentCommande?.livreur
                                                        ? contextUser?.userData.type === 'Client' ||
                                                          isSameFournisseurEmployee(
                                                              contextUser?.userData.idFournisseur!,
                                                              currentCommande.livreur?.idFournisseur!,
                                                          )
                                                            ? DisplayName(currentCommande?.livreur!)
                                                            : '****'
                                                        : '-'
                                                }
                                                disabled
                                            />
                                            {currentCommande &&
                                                contextUser &&
                                                canControlCommande(
                                                    contextUser?.userData?.idFournisseur!,
                                                    currentCommande!,
                                                ) &&
                                                !!currentCommande.livreurPaiement && (
                                                    <Badge
                                                        className="d-flex justify-content-center align-items-center"
                                                        bg="info"
                                                    >
                                                        P
                                                    </Badge>
                                                )}
                                        </div>
                                    </Col>
                                </InputGroup>
                            </Col>
                        </Row>
                        {contextUser?.userData?.userroles?.some((d) => d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN) &&
                            currentCommande?.idEtatCommande! < 4 &&
                            isTheOwnerOfCommande(contextUser?.userData?.idFournisseur!, currentCommande) && (
                                <Row>
                                    <Col className="text-end">
                                        <Button
                                            size="sm"
                                            variant="outline-success"
                                            onClick={() =>
                                                doCommandePatch(currentCommande?.idCommande!, {
                                                    adresse: currentCommande?.adresse!,
                                                    prixTotal: currentCommande?.prixTotal!,
                                                })
                                            }
                                            disabled={!!spinIt}
                                        >
                                            Enregistrer
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                    </Col>
                    <Col sm="12" md="3" lg="3">
                        {+currentCommande?.idEtatCommande! === 1 &&
                            currentCommande?.colis?.dateRec! &&
                            contextUser?.userData?.userroles?.some(
                                (d) =>
                                    d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                                    d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE,
                            ) &&
                            canControlCommande(contextUser?.userData?.idFournisseur!, currentCommande!) && (
                                <Accordion className="mb-1" defaultActiveKey="-1">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Affecter à un livreur</Accordion.Header>
                                        <Accordion.Body>
                                            <AffectationLivreur
                                                commandes={commandes}
                                                setCommandes={setCommandes}
                                                setCommande={setCurrentCommande}
                                                setCheckedCommandes={setCheckedCommandes}
                                                livreurs={livreurs}
                                                etatTitres={etatTitres}
                                                selectedCommandes={[currentCommande?.idCommande!]}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            )}
                        {currentCommande &&
                            contextUser?.userData?.type === 'Fournisseur' &&
                            isTransfertFound(
                                currentCommande?.transfertCommandes!,
                                contextUser?.userData?.idFournisseur!,
                            ) && (
                                <Accordion className="mb-1" defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Centre de transfert</Accordion.Header>
                                        <Accordion.Body>
                                            <TransfertInfo
                                                commande={currentCommande!}
                                                updateCommande={setCurrentCommande}
                                                commandes={commandes}
                                                updateCommandesList={setCommandes}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            )}
                        <TimeLine
                            title="Historique des actions"
                            data={sortByDueDate(currentCommande?.commandeActions ?? [], 'dateAction', true)}
                            commande={currentCommande!}
                            updateCommande={setCurrentCommande}
                            commandes={commandes}
                            updateCommandesList={setCommandes}
                            etatTitres={etatTitres}
                            useIconInsteadOfSVG={true}
                        />
                    </Col>
                </Row>
            </Tab>
            {currentCommande &&
                currentCommande?.idFacture &&
                (contextUser?.userData.type === 'Client' ||
                    isTheOwnerOfCommande(contextUser?.userData?.idFournisseur!, currentCommande)) && (
                    <Tab eventKey="facture" title="Facture/Paiement Info" className="tab-content-border">
                        <CommandeFactureInfo
                            commandes={commandes}
                            currentCommande={currentCommande}
                            setCommandes={setCommandes}
                            setCurrentCommande={setCurrentCommande}
                        />
                    </Tab>
                )}
            {currentCommande &&
                (contextUser?.userData.type === 'Client' ||
                    isTheOwnerOfCommande(contextUser?.userData?.idFournisseur!, currentCommande)) && (
                    <Tab eventKey="colis" title="Colis Info" className="tab-content-border">
                        <CommandeColisInfo
                            commandes={commandes}
                            currentCommande={currentCommande}
                            setCommandes={setCommandes}
                            setCurrentCommande={setCurrentCommande}
                        />
                    </Tab>
                )}
        </Tabs>
    );
};

export default CommandeFormComponent;
