import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import dayjs from 'dayjs';
import { AxiosResponse } from 'axios';
import { Badge, Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFileLines, faHandHoldingDollar, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import { DisplayName, isOnlyLivreur } from 'utils/helpers';

import FactureLivreurInterface, {
    FactureLivreurCommandeInterface,
    FactureLivreurPaiementInterface,
} from 'types/interfaces/factureLivreur.interface';
import { getCommandeById } from 'services/commande.service';

import CommandeTarifsCommponent, {
    calculatePrix,
    getStatusTranslation,
} from 'pages/FactureLivreur/components/CommandeTarifs';
import CustomModal from 'components/UI/customModal';
import FacturePaiementForm from 'pages/FactureLivreur/components/PaiementForm';
import PaiementRecu from 'pages/FactureLivreur/components/PaiementRecu';
import PaiementAction from 'pages/FactureLivreur/components/PaiementAction';
import CommandeFormComponent from 'pages/Commande/components/CommandeForm';
import CommandeInterface from 'types/interfaces/commande.interface';

type Props = {
    current: FactureLivreurInterface;
    setCurrent: Dispatch<SetStateAction<FactureLivreurInterface | null>>;
    setList: Dispatch<SetStateAction<{ items: FactureLivreurInterface[]; count: number }>>;
};

const FactureDetails: React.FC<Props> = ({ current, setCurrent, setList }: Props) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);
    const [currentFlp, setCurrentFlp] = useState<FactureLivreurPaiementInterface | null>(null);
    const [currentIdFile, setCurrentIdFile] = useState<number | null>(null);
    const [currentLocalCommande, setCurrentLocalCommande] = useState<CommandeInterface | null>(null);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showValidationFlp, setShowValidationFlp] = useState<boolean>(false);
    const [showPaiementRecu, setShowPaiementRecu] = useState<boolean>(false);
    const [showFacturePaiementForm, setShowFacturePaiementForm] = useState<boolean>(false);

    const openPaiementRecuModal = (data: number) => {
        setCurrentIdFile(data);
        setShowPaiementRecu(true);
    };
    const closePaiementRecuModal = () => {
        setCurrentIdFile(null);
        setShowPaiementRecu(false);
    };
    const openValidationFlpModal = (flp: FactureLivreurPaiementInterface) => {
        setCurrentFlp(flp);
        setShowValidationFlp(true);
    };
    const closeValidationFlpModal = () => {
        setCurrentFlp(null);
        setShowValidationFlp(false);
    };
    const openFacturePaiementFormModal = () => {
        setShowFacturePaiementForm(true);
    };
    const closeFacturePaiementFormModal = () => {
        setShowFacturePaiementForm(false);
    };

    const openModal = (id: number) => {
        getCommandeById(id)
            .then((response: AxiosResponse<CommandeInterface | any>) => {
                const { data } = response;
                setCurrentLocalCommande(data);
                setShowModal(true);
            })
            .catch();
    };

    const closeModal = () => {
        setCurrentLocalCommande(null);
        setShowModal(false);
    };

    return (
        <>
            <div className="d-flex justify-content-between m-1">
                <div>
                    <strong>{current.ref}</strong>
                    {current.factureLivreurCommandes?.length! > 0 && (
                        <>
                            <br />
                            Livreur:{' '}
                            <strong>
                                {DisplayName((current.factureLivreurCommandes as any)[0].commande?.livreur!)}
                            </strong>
                        </>
                    )}
                    <br />
                    Créée le <b>{dayjs(current.factureDate).format('DD/MM/YYYY HH:mm')}</b>
                </div>
                <div className="text-end">
                    <strong>Total {current.status === 'Done' ? 'Payé' : 'Non payé'}</strong>
                    <br />
                    <span className="my-xsm-font">
                        updated le <b>{dayjs(current.lastActionDate).format('DD/MM/YYYY HH:mm')}</b>
                        <br />
                        Nb commandes: {current.factureLivreurCommandes?.length!}
                    </span>
                </div>
            </div>
            <div className="m-2">
                <h6> Facture détails</h6>
            </div>
            <Table responsive striped>
                <thead>
                    <tr>
                        <th>Commande</th>
                        <th>Prix de commande</th>
                        <th>Ville</th>
                        {contextUser && !isOnlyLivreur(contextUser?.userData) && <th>Services</th>}
                        <th>Commission</th>
                        <th className="text-end">#</th>
                    </tr>
                </thead>
                <tbody>
                    {current.factureLivreurCommandes?.map((flc: FactureLivreurCommandeInterface, index: number) => (
                        <tr key={index}>
                            <td>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip className="refNcode_tooltip">
                                            Code: {flc.commande?.code}
                                            <br />
                                            Ref: {flc.commande?.ref ? flc.commande?.ref : '-'}
                                        </Tooltip>
                                    }
                                >
                                    <Button type="button" variant="secondary-outline">
                                        <b style={{ fontSize: '0.7rem' }}>{flc.commande?.code.replace('CMD', '')}</b>
                                        {flc.commande?.ref && (
                                            <>
                                                <br />
                                                <b style={{ fontSize: '0.6rem' }}>{flc.commande?.ref}</b>
                                            </>
                                        )}
                                    </Button>
                                </OverlayTrigger>
                            </td>
                            <td>{flc.commande?.prixTotal!} DH</td>
                            <td>{flc.commande?.ville?.nomVille}</td>
                            {contextUser && !isOnlyLivreur(contextUser?.userData) && (
                                <td>
                                    <CommandeTarifsCommponent commande={flc.commande!} /> DH
                                </td>
                            )}
                            <td>{flc.commisionLivreur} DH</td>
                            <td className="text-end">
                                <div className="d-flex gap-1 justify-content-end align-items-center">
                                    <Button variant="outline-info" onClick={() => openModal(flc.commande?.idCommande!)}>
                                        <FontAwesomeIcon icon={faInfoCircle} size="xs" style={{ color: '#376ca9' }} />
                                        <span className="mx-1">Commande détails</span>
                                    </Button>
                                    {!!flc.commande?.livreurPaiement! && (
                                        <Badge className="d-flex justify-content-center align-items-center" bg="info">
                                            P
                                        </Badge>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={contextUser && !isOnlyLivreur(contextUser?.userData) ? 6 : 5} className="text-end">
                            <strong>
                                <div>Total Commande: {current.montantTotal} DH</div>
                                <div>Total payé: {current.montantPaid} DH</div>
                                <div>Total rest: {current.montantRest} DH</div>
                                {contextUser && !isOnlyLivreur(contextUser?.userData) && (
                                    <div>
                                        Total Services:{' '}
                                        {current.factureLivreurCommandes?.reduce((accumulator: number, item: any) => {
                                            return accumulator + calculatePrix(item.commande, contextUser?.userData);
                                        }, 0)}{' '}
                                        DH
                                    </div>
                                )}
                                <div>
                                    {(current.factureLivreurCommandes as any)[0].commande?.livreur?.employe! ===
                                        'Commission' && (
                                        <i>
                                            Commission:{' '}
                                            {current.factureLivreurCommandes?.reduce(
                                                (accumulator: number, item: any) => {
                                                    return accumulator + item.commisionLivreur;
                                                },
                                                0,
                                            )}{' '}
                                            DH
                                        </i>
                                    )}
                                </div>
                            </strong>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <div className="d-flex justify-content-between align-items-center m-2">
                <h6> Paiement historique</h6>
                {
                    // contextUser && isOnlyLivreur(contextUser?.userData) && // i made this to let everone add payment
                    current.status === 'Encours' && (
                        <Button
                            variant="outline-primary"
                            className="button-input-group-effect"
                            id="basic-addon1"
                            onClick={() => openFacturePaiementFormModal()}
                        >
                            <FontAwesomeIcon
                                icon={faHandHoldingDollar}
                                size="xs"
                                style={{ color: '#376ca9' }}
                                width="20"
                            />
                            Payer
                        </Button>
                    )
                }
            </div>
            <Table responsive striped>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Montant</th>
                        <th>Etat</th>
                        <th className="text-end">#</th>
                    </tr>
                </thead>
                <tbody>
                    {current.factureLivreurPaiements?.length === 0 && (
                        <tr>
                            <td colSpan={4} className="text-center">
                                Aucun paiement trouvé
                            </td>
                        </tr>
                    )}
                    {current.factureLivreurPaiements?.map((flp: FactureLivreurPaiementInterface, index: number) => (
                        <tr key={index}>
                            <td>{dayjs(flp.paimentDate).format('DD/MM/YYYY HH:mm:ss')}</td>
                            <td>{flp.montant} DH</td>
                            <td>{getStatusTranslation(flp.status)}</td>
                            <td>
                                <div className="d-flex justify-content-end align-items-center gap-1">
                                    {flp.idFile && (
                                        <Button
                                            size="sm"
                                            variant="outline-info"
                                            id="basic-addon1"
                                            onClick={() => openPaiementRecuModal(flp.idFile!)}
                                        >
                                            <FontAwesomeIcon
                                                icon={faEye}
                                                size="xs"
                                                style={{ color: '#376ca9' }}
                                                width="20"
                                            />
                                            Paiement reçu
                                        </Button>
                                    )}
                                    {contextUser && !isOnlyLivreur(contextUser?.userData) && (
                                        <Button
                                            size="sm"
                                            variant="outline-success"
                                            id="basic-addon1"
                                            onClick={() => openValidationFlpModal(flp)}
                                        >
                                            <FontAwesomeIcon
                                                icon={faFileLines}
                                                size="xs"
                                                style={{ color: '#376ca9' }}
                                                width="20"
                                            />
                                            {flp.status === 'Encours' ? 'Validation de paiement' : 'Détails'}
                                        </Button>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <CustomModal
                title="Payer facture"
                size="lg"
                show={showFacturePaiementForm}
                handleClose={closeFacturePaiementFormModal}
            >
                <FacturePaiementForm
                    current={current}
                    setCurrent={setCurrent}
                    setList={setList}
                    close={() => closeFacturePaiementFormModal()}
                />
            </CustomModal>
            <CustomModal
                title="Validation de paiement"
                size="lg"
                show={showValidationFlp}
                handleClose={closeValidationFlpModal}
            >
                {currentFlp ? (
                    <PaiementAction
                        flp={currentFlp}
                        current={current}
                        setCurrent={setCurrent}
                        setList={setList}
                        close={() => closeValidationFlpModal()}
                    />
                ) : (
                    <></>
                )}
            </CustomModal>
            <CustomModal title="Paiement reçu" size="lg" show={showPaiementRecu} handleClose={closePaiementRecuModal}>
                {currentIdFile ? <PaiementRecu idFile={currentIdFile} /> : <></>}
            </CustomModal>
            <CustomModal
                title="Commande Détails"
                size="lg"
                show={showModal}
                handleClose={closeModal}
                fullscreen
                id="cmd-details-info"
            >
                <CommandeFormComponent
                    currentCommande={currentLocalCommande!}
                    setCurrentCommande={(value: CommandeInterface | null) => setCurrentLocalCommande(value)}
                    // ...
                    commandes={{ items: [], count: 0 }}
                    setCommandes={() => void 0}
                    setCheckedCommandes={() => void 0}
                />
            </CustomModal>
        </>
    );
};

export default FactureDetails;
