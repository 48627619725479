import React, { useState, useContext, useEffect } from 'react';
import * as yup from 'yup';
import { AxiosResponse } from 'axios';
import { Button, Col, Form, Row, Table, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

import CommandeInterface from 'types/interfaces/commande.interface';
import FournisseurInterface from 'types/interfaces/fournisseur.interface';
import TarifTransfertInterface from 'types/interfaces/tarifTransfert.interface';
import { getPartnersFournisseurs } from 'services/fournisseur.service';
import { getLastTarifs } from 'services/tarifTransfert.service';
import { postTransfert } from 'services/commande.service';

import useUserStore, { StoreStateInterface } from 'store/appStore';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';

import CustomModal from 'components/UI/customModal';

export const schema = yup.object({
    ids: yup.array().of(yup.number().required()).min(1).required(),
    idLivreur: yup.number().required(),
    remarque: yup.string(),
    idEtatTitre: yup.number(),
});

type Props = {
    commandes: CommandeInterface[];
    setCommandes: React.Dispatch<React.SetStateAction<CommandeInterface[]>>;
};

const Transfert: React.FC<Props> = ({ commandes, setCommandes }: Props) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    const [selectedDestinataire, setSelectedDestinataire] = useState<string>('');
    const [fournisseurs, setFournisseurs] = useState<FournisseurInterface[]>([]);

    const [show, setShow] = useState<boolean>(false);

    const loading = useUserStore((state: StoreStateInterface) => state.loading);
    const setLoadingFalse = useUserStore((state: StoreStateInterface) => state.setLoadingFalse);
    const setLoadingTrue = useUserStore((state: StoreStateInterface) => state.setLoadingTrue);

    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        if (selectedDestinataire) {
            searchParams.set('destinataire', selectedDestinataire);
        } else {
            searchParams.delete('destinataire');
        }
        navigate({ search: searchParams.toString() }, { replace: true });
    }, [selectedDestinataire, navigate]);

    useEffect(() => {
        if (contextUser) {
            setLoadingTrue();
            getPartnersFournisseurs()
                .then((response: AxiosResponse<FournisseurInterface[] | any>) => {
                    const { data } = response;
                    setFournisseurs(
                        data.filter(
                            (item: FournisseurInterface) => item.idFournisseur !== contextUser.userData?.idFournisseur!,
                        ),
                    );
                })
                .catch()
                .finally(() => setLoadingFalse());
        }
    }, [contextUser]);

    const LoadTarifs = () => {
        setShow(true);
    };

    return (
        <>
            <Row>
                <Form.Group as={Col} xs={6} lg={6} md={6} controlId="form_grp_sClient">
                    <Form.Label>Fournisseur:</Form.Label>
                    <Form.Select
                        size="sm"
                        name="selectedDestinataire"
                        value={selectedDestinataire}
                        onChange={(e) => {
                            const {
                                target: { value },
                            } = e;
                            setCommandes([]);
                            setSelectedDestinataire(value);
                        }}
                        placeholder="Selectionner destinataire"
                    >
                        <option value="">Tous</option>
                        {fournisseurs.map((dr: FournisseurInterface) => (
                            <option key={dr.idFournisseur} value={dr.idFournisseur}>
                                {dr.raisonSocial}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
                {commandes.length > 0 && selectedDestinataire && (
                    <Col xs={6} lg={6} md={6} className="d-flex justify-content-end">
                        <Button size="sm" variant="outline-success" disabled={!!loading} onClick={() => LoadTarifs()}>
                            Passer au étape suivante
                        </Button>
                    </Col>
                )}
            </Row>
            {commandes.length > 0 && selectedDestinataire && (
                <CustomModal title="Confirmation de transfert" show={show} handleClose={() => setShow(false)}>
                    <ConfirmationTransfert
                        commandes={commandes}
                        setCommandes={setCommandes}
                        selectedDestinataire={+selectedDestinataire!}
                        close={() => setShow(false)}
                    />
                </CustomModal>
            )}
        </>
    );
};

export default Transfert;

type ConfirmationTransfertProps = {
    commandes: CommandeInterface[];
    setCommandes: React.Dispatch<React.SetStateAction<CommandeInterface[]>>;
    selectedDestinataire: number;
    close: () => void;
};

const ConfirmationTransfert: React.FC<ConfirmationTransfertProps> = ({
    commandes,
    setCommandes,
    selectedDestinataire,
    close,
}: ConfirmationTransfertProps) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);
    const [tarifs, setTarifs] = useState<TarifTransfertInterface[]>([]);

    const setLoadingFalse = useUserStore((state: StoreStateInterface) => state.setLoadingFalse);
    const setLoadingTrue = useUserStore((state: StoreStateInterface) => state.setLoadingTrue);

    useEffect(() => {
        if (contextUser) {
            loadTarifs();
        }
    }, [contextUser]);

    const loadTarifs = () => {
        setLoadingTrue();

        const villesIds = commandes.map((item: CommandeInterface) => item.idVille!).join(',');

        getLastTarifs(contextUser?.userData?.idFournisseur!, villesIds, selectedDestinataire)
            .then((response: AxiosResponse<FournisseurInterface[] | any>) => {
                const { data } = response;
                setTarifs(data);
            })
            .catch()
            .finally(() => setLoadingFalse());
    };
    false && console.log(commandes, setCommandes, selectedDestinataire, close);

    const noTarif = commandes.some(
        (item: CommandeInterface) => !tarifs.find((t: TarifTransfertInterface) => t.idVille === item.idVille),
    );

    const submit = async () => {
        try {
            setLoadingTrue();
            const bodyData = {
                idFournisseur: selectedDestinataire,
                transfertDetails: commandes.map((item: CommandeInterface) => ({
                    idCommande: item.idCommande,
                    idTarifTransfert: tarifs.find((t: TarifTransfertInterface) => t.idVille === item.idVille)
                        ?.idTarifTransfert!,
                    prix: tarifs.find((t: TarifTransfertInterface) => t.idVille === item.idVille)?.prixTarif!,
                })),
            };
            const response = await postTransfert(bodyData);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Commande(s) a été transferée(s) !');
                setCommandes((prev: CommandeInterface[]) =>
                    prev.filter((commande: CommandeInterface) => !resultData.includes(commande.idCommande)),
                );
            }
        } catch (Exception) {
        } finally {
            setLoadingFalse();
        }
    };

    return (
        <>
            <Table responsive striped>
                <thead>
                    <th>Commande</th>
                    <th>Ville</th>
                    <th>Tarif</th>
                </thead>
                <tbody>
                    {commandes.map((item: CommandeInterface) => (
                        <tr key={item.idCommande}>
                            <td>
                                <b style={{ fontSize: '0.7rem' }}>{item.code.replace('CMD', '')}</b>
                            </td>
                            <td>{item.ville?.nomVille}</td>
                            <td>
                                {tarifs.find((t: TarifTransfertInterface) => t.idVille === item.idVille)?.prixTarif ?? (
                                    <b style={{ fontSize: '0.7rem', color: 'red' }}>??</b>
                                )}{' '}
                                DH
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Alert variant="warning" show={noTarif}>
                <div className="d-flex justify-content-between align-items-start">
                    <p> S&apos;il vous plaît verifier vos tarifs</p>
                    <Button onClick={() => loadTarifs()}>
                        <FontAwesomeIcon icon={faSync} />
                    </Button>
                </div>
            </Alert>
            {!noTarif && (
                <div className="d-flex justify-content-end">
                    <Button variant="primary" onClick={submit}>
                        Confirmer
                    </Button>
                </div>
            )}
        </>
    );
};
