import React from 'react';

import { Table } from 'react-bootstrap';

import { groupBy } from 'utils/helpers';

interface GroupedTableProps<T> {
    data: T[];
    groupByKey: keyof T;
    colSpan: number;
    componentBody: any;
    componentHeader: any;
}
const GroupedTable = <T extends Record<string, any>>({
    data,
    groupByKey,
    colSpan,
    componentBody: ComponentBody,
    componentHeader: ComponentHeader,
}: GroupedTableProps<T>) => {
    const groupedData = groupBy(data, groupByKey);

    return (
        <Table bordered hover>
            <thead>
                <tr>
                    <ComponentHeader />
                </tr>
            </thead>
            <tbody>
                {Object.entries(groupedData).map(([group, items]) => (
                    <React.Fragment key={group}>
                        <tr style={{ backgroundColor: '#ebebeb' }}>
                            <td colSpan={colSpan}>{group}</td>
                        </tr>
                        {items.map((item, index) => (
                            <tr key={index}>
                                <ComponentBody item={item} />
                            </tr>
                        ))}
                    </React.Fragment>
                ))}
            </tbody>
        </Table>
    );
};

export default GroupedTable;
