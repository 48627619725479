import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { TableColumnType } from 'react-bs-datatable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import * as fortAwsimeIcons from '@fortawesome/free-solid-svg-icons';

import EtatTitreInterface from 'types/interfaces/etatTitre.interface';
import { getEtatTitres, saveEtatTitre } from 'services/etatTitre.service';

import CustomModal from 'components/UI/customModal';
import Datatable from 'components/shared/datatable';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import TitleElement from 'components/UI/titleElement';

const schema = yup.object({
    idEtatTitre: yup.number(),
    titre: yup.string().required(),
    textColor: yup.string().required(),
    backgroundColor: yup.string().required(),
    hideSelection: yup.boolean().nullable(),
    fortAwsomeIcon: yup.string().required(),
});

const EtatTitrePage: React.FC = () => {
    const [etatTitres, setEtatTitres] = useState<EtatTitreInterface[]>([]);
    const [currentEtatTitre, setCurrentEtatTitre] = useState<EtatTitreInterface | null>(null);

    const [showFormModal, setShowFormModal] = useState<boolean>(false);
    const [formModalEditMode, setFormModalEditMode] = useState<boolean>(false);
    const [spinIt, setSpinIt] = useState<boolean>(false);

    const handleFormModalClose = () => {
        setCurrentEtatTitre(null);
        setShowFormModal(false);
    };

    const handleFormModalOpen = (editMode = false, etatTitreData?: EtatTitreInterface) => {
        if (etatTitreData) setCurrentEtatTitre(etatTitreData);
        setFormModalEditMode(editMode);
        setShowFormModal(true);
    };

    const tableHeaders: TableColumnType<any>[] = [
        {
            prop: 'titre',
            title: 'Titre',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'textColor',
            title: 'TextColor',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'backgroundColor',
            title: 'BackgroundColor',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'fortAwsomeIcon',
            title: 'Icon',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: EtatTitreInterface) => (
                <div
                    style={{
                        textAlign: 'center',
                        borderRadius: '50%',
                        lineHeight: '2rem',
                        width: '2rem',
                        height: '2rem',
                        color: row.textColor,
                        backgroundColor: row.backgroundColor,
                    }}
                >
                    <FontAwesomeIcon icon={(fortAwsimeIcons as any)[row.fortAwsomeIcon]} size="lg" />
                </div>
            ),
        },
        {
            prop: 'hideSelection',
            title: 'Cacher',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: EtatTitreInterface) => <>{!!row.hideSelection ? 'Oui' : 'Non'}</>,
        },
        {
            prop: 'null',
            title: 'Action',
            alignment: { horizontal: 'right' },
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: EtatTitreInterface) => (
                <Button
                    variant="outline-secondary"
                    className="button-input-group-effect"
                    id="basic-addon1"
                    onClick={() => handleFormModalOpen(true, row)}
                >
                    <FontAwesomeIcon icon={faEdit} size="xs" style={{ color: '#337ab7' }} />
                </Button>
            ),
            isFilterable: false,
            isSortable: false,
        },
    ];

    useEffect(() => {
        document.title = 'Paramétrage Etat Titres - DS';

        getEtatTitres()
            .then((response: AxiosResponse<EtatTitreInterface | any>) => {
                const { data } = response;
                setEtatTitres(data);
            })
            .catch();
    }, []);

    const submitForm = async (etatTitreValues: EtatTitreInterface | any) => {
        try {
            setSpinIt(true);
            const response = await saveEtatTitre(etatTitreValues);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Etat titre a été enregistré avec succès');
                const etatTitreDATA = [...etatTitres].filter((dd) => dd.idEtatTitre !== resultData.idEtatTitre);
                etatTitreDATA.unshift(resultData);
                setEtatTitres(etatTitreDATA);
                handleFormModalClose();
                setSpinIt(false);
            }
        } catch (Exception) {
            setSpinIt(false);
        }
    };

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { text: 'Paramétrage' },
        { active: true, text: 'Etat Titres' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Titres
                </TitleElement>
                <Button
                    variant="outline-secondary"
                    className="button-input-group-effect"
                    id="basic-addon1"
                    onClick={() => handleFormModalOpen()}
                >
                    <FontAwesomeIcon icon={faPlus} size="xs" style={{ color: '#337ab7' }} />
                </Button>
            </div>
            <Datatable data={etatTitres} tableColumns={tableHeaders} />
            <CustomModal
                title={`${!!formModalEditMode ? 'Edit' : 'Nouveau'} Etat titre`}
                size="lg"
                show={showFormModal}
                handleClose={handleFormModalClose}
            >
                <div>
                    <Formik
                        validationSchema={schema}
                        onSubmit={(values) => submitForm(values)}
                        enableReinitialize={true}
                        initialValues={{
                            idEtatTitre:
                                currentEtatTitre && !!formModalEditMode ? currentEtatTitre.idEtatTitre : undefined,
                            titre: currentEtatTitre && !!formModalEditMode ? currentEtatTitre.titre : '',
                            textColor: currentEtatTitre && !!formModalEditMode ? currentEtatTitre.textColor : '#fff',
                            backgroundColor:
                                currentEtatTitre && !!formModalEditMode ? currentEtatTitre.backgroundColor : '#6c757d',
                            hideSelection:
                                currentEtatTitre && !!formModalEditMode ? currentEtatTitre.hideSelection : false,
                            fortAwsomeIcon:
                                currentEtatTitre && !!formModalEditMode ? currentEtatTitre.fortAwsomeIcon : 'faClock',
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                {values.idEtatTitre && (
                                    <Form.Control
                                        type="hidden"
                                        name="idEtatTitre"
                                        value={values.idEtatTitre}
                                        readOnly={true}
                                    />
                                )}
                                <Form.Group controlId="form_grp_nom">
                                    <Form.Label>Titre</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="titre"
                                        placeholder="Titre"
                                        value={values.titre}
                                        onChange={handleChange}
                                        isInvalid={!!errors.titre}
                                    />
                                </Form.Group>
                                <Form.Group controlId="form_grp_textColor">
                                    <Form.Label>TextColor</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="textColor"
                                        placeholder="TextColor"
                                        value={values.textColor}
                                        onChange={handleChange}
                                        isInvalid={!!errors.textColor}
                                    />
                                </Form.Group>
                                <Form.Group controlId="form_grp_backgroundColor">
                                    <Form.Label>BackgroundColor</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="backgroundColor"
                                        placeholder="BackgroundColor"
                                        value={values.backgroundColor}
                                        onChange={handleChange}
                                        isInvalid={!!errors.backgroundColor}
                                    />
                                </Form.Group>
                                <Row>
                                    <Form.Group as={Col} md="8" controlId="form_grp_icon">
                                        <Form.Label>Villes</Form.Label>
                                        <Form.Select
                                            name="fortAwsomeIcon"
                                            value={values.fortAwsomeIcon}
                                            onChange={handleChange}
                                            isInvalid={!!errors.fortAwsomeIcon}
                                            placeholder="Selectionner une Icon"
                                        >
                                            <option>Selectionner une Icon</option>
                                            {Object.keys(fortAwsimeIcons).map((item: string) => (
                                                <option key={item} value={item}>
                                                    {item}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Col className="align-self-end mb-1">
                                        <div
                                            style={{
                                                textAlign: 'center',
                                                borderRadius: '50%',
                                                lineHeight: '2rem',
                                                width: '2rem',
                                                height: '2rem',
                                                color: values.textColor,
                                                backgroundColor: values.backgroundColor,
                                                float: 'right',
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={(fortAwsimeIcons as any)[values.fortAwsomeIcon]}
                                                size="lg"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Form.Group controlId="form_grp_hideSelection">
                                    <Form.Check
                                        type="checkbox"
                                        label="Cacher"
                                        name="hideSelection"
                                        checked={values.hideSelection}
                                        onChange={handleChange}
                                        isInvalid={!!errors.hideSelection}
                                    />
                                </Form.Group>
                                <div className="d-grid gap-2 mt-3">
                                    <Button variant="primary" type="submit" disabled={!!spinIt}>
                                        Enregistrer
                                        {!!spinIt && (
                                            <>
                                                &nbsp; <Spinner animation="border" size="sm" />
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </CustomModal>
        </>
    );
};

export default EtatTitrePage;
