import { useState, useEffect } from 'react';
import moment from 'moment';

const useRealTimeClock = () => {
    const [clock, setClock] = useState<string>('');

    useEffect(() => {
        const interval = setInterval(() => {
            setClock(moment().format('DD/MM/YYYY HH:mm:ss'));
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return clock;
};

export default useRealTimeClock;
