import React from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, InputGroup, Button, FormControl } from 'react-bootstrap';
import Paginator from 'components/UI/paginator';

type Props = {
    inputValue: string;
    inputChanged: (e: any) => void;
    activePage: number;
    totalPages: number;
    handlePageNFilterChange: (e_page: number, e_inputValue?: string) => void;
};

const SearchInput: React.FC<Props> = ({
    inputValue,
    inputChanged,
    activePage,
    totalPages,
    handlePageNFilterChange,
}: Props) => {
    return (
        <Row className="mb-4 p-2 holdit_notEffect">
            <Col xs={12} lg={6} className="d-flex flex-col justify-content-end align-items-end">
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Filter"
                        aria-label="Filter"
                        aria-describedby="basic-addon2"
                        value={inputValue}
                        type="text"
                        onChange={inputChanged}
                    />
                    <Button
                        variant="outline-info"
                        id="button-addon2"
                        onClick={() => inputChanged({ target: { value: '' } })}
                    >
                        <FontAwesomeIcon icon={faXmark} size="sm" />
                    </Button>
                </InputGroup>
            </Col>
            <Col xs={12} lg={6} className="d-flex flex-col justify-content-end align-items-end">
                <Paginator
                    defaultPage={activePage}
                    totalPages={totalPages}
                    searchedData={inputValue}
                    callback={(e_page, e_inputValue) => handlePageNFilterChange(e_page, e_inputValue)}
                />
            </Col>
        </Row>
    );
};

SearchInput.defaultProps = {
    inputValue: '',
};

export default SearchInput;
