import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import DraftCommandeInterface, {
    DraftCommandeCreateRequest,
    DraftCommandeUpdateRequest,
    RangeTransferCreation,
} from 'types/interfaces/draftCommande.interface';

const apiEndPoint = `${apiUrl}/draftcommandes`;

export const getDraftCommandeById = (id: number): Promise<DraftCommandeInterface | any> => {
    return http.get(`${apiEndPoint}/${id}`);
};

export const readUploadedExcelFile = (formData: FormData, options: any, idClient?: number | string) => {
    return http.post(
        `${apiEndPoint}/import-data/excel/file/read${idClient ? '?idClient=' + idClient : ''}`,
        formData,
        options,
    );
};

export const getAllDraftCommandesByClient = (idClient?: number | string): Promise<DraftCommandeInterface[] | any> => {
    return http.get(`${apiEndPoint}${idClient ? '?idClient=' + idClient : ''}`);
};

export const createDraftCommande = (draftCommande: DraftCommandeCreateRequest, idClient?: number | string) => {
    const body = { ...draftCommande };
    return http.post(`${apiEndPoint}${idClient ? '?idClient=' + idClient : ''}`, body);
};

export const updateDraftCommande = (
    id: number,
    draftCommande: DraftCommandeUpdateRequest,
    idClient?: number | string,
) => {
    const body = { ...draftCommande };
    return http.put(`${apiEndPoint}/${id}${idClient ? '?idClient=' + idClient : ''}`, body);
};

export const transferDraftCommandesRange = (
    transferRangeDetails: RangeTransferCreation,
    idClient?: number | string,
) => {
    return http.put(`${apiEndPoint}/transfer/range${idClient ? '?idClient=' + idClient : ''}`, transferRangeDetails);
};

export const deleteDraftCommandesRange = (idDraftCommandes: number[]) => {
    const body = { idDraftCommandes };
    return http.delete(`${apiEndPoint}/range`, { data: body });
};
