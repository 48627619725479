import React, { useContext } from 'react';

import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import { isTheOwnerOfCommande } from 'utils/helpers';
import TransfertCommandeInterface from 'types/interfaces/transfertCommande.interface';
import CommandeInterface from 'types/interfaces/commande.interface';
import UserInterface from 'types/interfaces/user.interface';

interface CommandeTarifsCommponentProps {
    commande: CommandeInterface;
}

const CommandeTarifsCommponent: React.FC<CommandeTarifsCommponentProps> = ({ commande }) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    const isTheOwner = isTheOwnerOfCommande(contextUser?.userData?.idFournisseur!, commande);

    const sum = calculatePrix(commande, contextUser?.userData!);

    let color = '#000';
    if (sum === 0) {
        color = '#FF8080';
    } else if (!isTheOwner) {
        color = '#6196A6';
    }

    return <span style={{ color }}>{sum}</span>;
};

export default CommandeTarifsCommponent;

export const calculatePrix = (commande: CommandeInterface, userData: UserInterface) => {
    const isTheOwner = isTheOwnerOfCommande(userData.idFournisseur!, commande);
    let sum = 0;
    if (isTheOwner) {
        sum = (commande.commandetarifs ?? []).reduce((innerAccumulator: number, commandetarif: any) => {
            return innerAccumulator + (commandetarif.tarif?.prixTarif ?? 0);
        }, 0);
    } else {
        sum = getTransfertTarifFromCommande(commande, userData);
    }
    return sum;
};

export const calculateTotalPrix = (commandes: CommandeInterface[], userData: UserInterface) => {
    return commandes.reduce((accumulator, commande) => {
        return accumulator + calculatePrix(commande, userData);
    }, 0);
};

export const getTransfertTarifFromCommande = (commande: CommandeInterface, userData: UserInterface) => {
    const transfertCommande = commande.transfertCommandes?.find(
        (d: TransfertCommandeInterface) => d.idFournisseurDestinataire === userData.idFournisseur!,
    );
    return transfertCommande?.prixTarif ?? 0;
};

export const getStatusTranslation = (status: string) => {
    switch (status) {
        case 'Rejected':
            return 'Refusé';
        case 'Approved':
            return 'Approuvé';
        default:
            return status;
    }
};
