import React from 'react';
import styled from 'styled-components';

const Styles = styled.div`
    background-color: #f8f9fa;
    color: #000;
`;

const Footer: React.FC = () => {
    return (
        <Styles className="clearfix mt-1 p-3">
            <hr />
            &copy; SERVY 2022
        </Styles>
    );
};

export default Footer;
