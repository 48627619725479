import React, { useState } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { updateDemandeRetourStockRange } from 'services/demandeRetourStock.service';
import DemandeRetourStockInterface from 'types/interfaces/demandeRetourStock.interface';

type Props = {
    checkedDemandes: number[];
    close: () => void;
    setDemandes: (value: { items: DemandeRetourStockInterface[]; count: number }) => void;
};
const DemandeRetourStockSection: React.FC<Props> = ({ checkedDemandes, close, setDemandes }: Props) => {
    const [spinIt, setSpinIt] = useState<boolean>(false);
    const submitDemande = async (newStatus: string) => {
        try {
            setSpinIt(true);
            updateDemandeRetourStockRange({ ids: checkedDemandes, status: newStatus })
                .then(() => {
                    (
                        setDemandes as React.Dispatch<
                            React.SetStateAction<{ items: DemandeRetourStockInterface[]; count: number }>
                        >
                    )((prev: { items: DemandeRetourStockInterface[]; count: number }) => {
                        const updating = prev.items.filter(
                            (item: DemandeRetourStockInterface) => !checkedDemandes.includes(item.idDemandeRetourStock),
                        );
                        return {
                            items: updating,
                            count: prev.count,
                        };
                    });
                    toast.success('Demandes ont été modifiées avec succès.');
                    setSpinIt(false);
                    close();
                })
                .catch(() => {
                    setSpinIt(false);
                });
        } catch (Exception) {
            setSpinIt(false);
        }

        setSpinIt(false);
    };

    return (
        <div>
            <b>{checkedDemandes.length}</b> demendes(s) selectionnée(s)
            <Alert variant="warning">
                Accepter une demande liée à une commande en cours résultera en l&apos;annulation immédiate de cette
                commande. Veuillez vérifier attentivement avant de confirmer!
            </Alert>
            <p>
                Voulez-vous vraiment modifié l&apos;etat ces demandes de retour ?
                <div className="d-flex gap-3 justify-content-center mt-2">
                    <Button variant="success" onClick={() => submitDemande('Traitée')} disabled={!!spinIt}>
                        Accepter
                        {!!spinIt && (
                            <>
                                &nbsp; <Spinner animation="border" size="sm" />
                            </>
                        )}
                    </Button>
                    <Button variant="warning" onClick={() => submitDemande('Rejetée')} disabled={!!spinIt}>
                        Refuser
                        {!!spinIt && (
                            <>
                                &nbsp; <Spinner animation="border" size="sm" />
                            </>
                        )}
                    </Button>
                </div>
            </p>
        </div>
    );
};

export default DemandeRetourStockSection;
