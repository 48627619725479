import React from 'react';
import { Pagination, Form, InputGroup } from 'react-bootstrap';

const Paginator = ({
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageIndex,
    pageSize,
    pageOptions,
    setPageSize,
}) => {
    return (
        <div className="d-flex justify-content-between align-items-center mt-1 mb-2">
            <Pagination className="mb-0 pb-0" size="sm">
                <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                <Pagination.Ellipsis disabled />
                <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
            </Pagination>
            <div className="d-flex gap-3">
                <div className="d-flex justify-content-center align-items-center">
                    <div>
                        <span>Page </span>
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>
                    </div>
                </div>
                <div>
                    <InputGroup size="sm">
                        <div className="input-group-prepend">
                            <InputGroup.Text size="sm">Go to page</InputGroup.Text>
                        </div>
                        <Form.Control
                            size="sm"
                            className="form-control form-control-sm"
                            type="number"
                            min={1}
                            max={pageOptions.length}
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(page);
                            }}
                            style={{ width: '100px' }}
                        />
                    </InputGroup>
                </div>
                <div>
                    <Form.Control
                        as="select"
                        size="sm"
                        className="form-select form-select-sm"
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 25, 50, 100].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </Form.Control>
                </div>
            </div>
        </div>
    );
};

export default Paginator;
