import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import TableTemplate from 'components/shared/datatable/tableTemplate';
import { Badge, Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import moment from 'moment';
import Datetime from 'react-datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingDollar, faInfo, faPrint, faRefresh, faTrash } from '@fortawesome/free-solid-svg-icons';

import { ROLES as RolesMap } from 'constantes/permission-maps';
import {
    isEmptyObject,
    buildUrlQueryParams,
    useQueryMeMo,
    DisplayName,
    utf8_to_b64,
    b64_to_utf8,
    isOnlyLivreur,
} from 'utils/helpers';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import Paginator from 'components/UI/paginator';
import TitleElement from 'components/UI/titleElement';
import CustomModal from 'components/UI/customModal';
import FactureDetails from 'pages/FactureLivreur/components/Details';
import FacturePaiementForm from 'pages/FactureLivreur/components/PaiementForm';
import FacturePrint from 'pages/FactureLivreur/components/FacturePrint';

import FactureLivreurInterface from 'types/interfaces/factureLivreur.interface';
import UserInterface from 'types/interfaces/user.interface';
import { getFactures } from 'services/factureLivreur.service';
import { getLivreursByFournisseur } from 'services/user.service';
import { calculatePrix } from 'pages/FactureLivreur/components/CommandeTarifs';
import DeleteFactureAction from './components/DeleteFactureAction';

const TOTAL_DATA_PER_PAGE = 100;

const FactureLivreurIndexPage: React.FC = () => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    const [currentFacture, setCurrentFacture] = useState<FactureLivreurInterface | null>(null);
    const [factures, setFactures] = useState<{ items: FactureLivreurInterface[]; count: number }>({
        items: [],
        count: 0,
    });
    const [livreurs, setLivreurs] = useState<UserInterface[]>([]);

    const [date_debut, setDate_debut] = useState<any>(null);
    const [date_fin, setDate_fin] = useState<any>(null);
    const [selectedStatus, setSelectedStatus] = useState<string>('');

    const [livreurVersion, setLivreurVersion] = useState<boolean>(false);
    const [selectedLivreur, setSelectedLivreur] = useState<string | null>(null);
    const [activePage, setActivePage] = useState<number>(1);

    const [showFactureToPrint, setShowFactureToPrint] = useState<boolean>(false);
    const [showFactureDetails, setShowFactureDetails] = useState<boolean>(false);
    const [showFacturePaiementForm, setShowFacturePaiementForm] = useState<boolean>(false);
    const [showFactureDeletingForm, setShowFactureDeletingForm] = useState<boolean>(false);

    const openFactureDetailsModal = (data: FactureLivreurInterface) => {
        setCurrentFacture(data);
        setShowFactureDetails(true);
    };
    const closeFactureDetailsModal = () => {
        setCurrentFacture(null);
        setShowFactureDetails(false);
    };
    const openFacturePaiementFormModal = (data: FactureLivreurInterface) => {
        setCurrentFacture(data);
        setShowFacturePaiementForm(true);
    };
    const closeFacturePaiementFormModal = () => {
        setCurrentFacture(null);
        setShowFacturePaiementForm(false);
    };
    const openFactureDeletingFormModal = (data: FactureLivreurInterface) => {
        setCurrentFacture(data);
        setShowFactureDeletingForm(true);
    };
    const closeFactureDeletingFormModal = () => {
        setCurrentFacture(null);
        setShowFactureDeletingForm(false);
    };
    const openFactureToPrintModal = (data: FactureLivreurInterface, version: boolean) => {
        setLivreurVersion(version);
        setCurrentFacture(data);
        setShowFactureToPrint(true);
    };
    const closeFactureToPrintModal = () => {
        setCurrentFacture(null);
        setLivreurVersion(false);
        setShowFactureToPrint(false);
    };

    const query = useQueryMeMo();
    const history = useNavigate();

    const onChangeDateDebut = (e: any) => {
        setDate_debut(e);
        handlePageNFilterChange(
            1,
            JSON.stringify({
                dDebut: e.format('YYYY-MM-DD HH:mm:ss'),
                dFin: date_fin,
                status: selectedStatus,
                livreur: selectedLivreur,
            }),
        );
    };

    const onChangeDateFin = (e: any) => {
        setDate_fin(e);
        handlePageNFilterChange(
            1,
            JSON.stringify({
                dDebut: date_debut,
                dFin: e.format('YYYY-MM-DD HH:mm:ss'),
                status: selectedStatus,
                livreur: selectedLivreur,
            }),
        );
    };

    const refreshList = () => {
        getFactures(date_debut, date_fin, selectedStatus, selectedLivreur!, 1, TOTAL_DATA_PER_PAGE)
            .then((response: AxiosResponse<FactureLivreurInterface[] | any>) => {
                const { data } = response;
                setFactures({
                    items: data.items,
                    count: data.count,
                });
            })
            .catch();
    };

    const handlePageNFilterChange = (activePage: number, searchedData?: string) => {
        const urlObject: any = {};
        if (activePage > 1) urlObject['page'] = activePage;
        if (searchedData) {
            const parsedObject = JSON.parse(searchedData);
            Object.keys(parsedObject).forEach((key) => {
                if (parsedObject[key as any]) {
                    if (key === 'livreur' && parsedObject[key as any])
                        urlObject[key] = utf8_to_b64(parsedObject[key as any]);
                    else urlObject[key] = parsedObject[key as any];
                }
            });
        }
        history(isEmptyObject(urlObject) ? window.location.pathname : `?${buildUrlQueryParams(urlObject)}`);
    };

    const loadFactures = (
        dateDebut?: Date | string,
        dateFin?: Date | string,
        status?: string,
        idLivreur?: string,
        page?: number,
        total?: number,
    ) => {
        getFactures(dateDebut, dateFin, status, idLivreur, page, total)
            .then((response: AxiosResponse<FactureLivreurInterface[] | any>) => {
                const { data } = response;
                setFactures({
                    items: data.items,
                    count: data.count,
                });
            })
            .catch();
    };

    useEffect(() => {
        document.title = 'Liste factures livreur - DS';
    }, []);

    useEffect(() => {
        if (
            contextUser?.userData?.userroles?.some(
                (d) =>
                    d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                    d.role.nomRole === RolesMap.FOURNISSEUR_FACTURE ||
                    d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE,
            )
        ) {
            getLivreursByFournisseur(contextUser?.userData?.idFournisseur!)
                .then((response: AxiosResponse<UserInterface[] | any>) => {
                    const { data } = response;
                    setLivreurs(data);
                })
                .catch();
        }
    }, [contextUser]);

    useEffect(() => {
        const page = query.get('page');
        const status = query.get('status') ?? '';
        const dDebut = query.get('dDebut');
        const dFin = query.get('dFin');
        const _livreur = query.get('livreur');

        const _currentLivreur = _livreur ? b64_to_utf8(_livreur) : undefined;
        const currentPage = page ? +page : 1;
        const _dateDebut = (dDebut ? moment(dDebut) : moment().subtract(3, 'days')).format('YYYY-MM-DD HH:mm:ss');
        const _dateFin = (dFin ? moment(dFin) : moment().endOf('day')).format('YYYY-MM-DD HH:mm:ss');

        setDate_debut(_dateDebut!);
        setDate_fin(_dateFin!);
        setActivePage(currentPage);
        setSelectedStatus(status);
        setSelectedLivreur(_currentLivreur!);

        const loadData = async () => {
            loadFactures(_dateDebut, _dateFin, status, _currentLivreur, currentPage, TOTAL_DATA_PER_PAGE);
        };
        loadData();
    }, [query, selectedLivreur]);

    const tableHeaders = useMemo(
        () => [
            {
                Header: 'Ref',
                accessor: 'ref',
            },
            {
                Header: 'Livreur',
                accessor: '--livreur',
                Cell: ({ row: { original } }: { row: { original: FactureLivreurInterface } }) => (
                    <span className="my-xsm-font">
                        {DisplayName(original.factureLivreurCommandes![0]?.commande?.livreur!)}
                    </span>
                ),
            },
            {
                Header: 'Date',
                accessor: 'factureDate',
                Cell: ({ row: { original } }: { row: { original: FactureLivreurInterface } }) => (
                    <>{moment(original.factureDate).format('DD/MM/YYYY HH:mm:ss')}</>
                ),
            },
            {
                Header: 'Dernier changement',
                accessor: 'lastActionDate',
                Cell: ({ row: { original } }: { row: { original: FactureLivreurInterface } }) => (
                    <>{moment(original.lastActionDate).format('DD/MM/YYYY HH:mm:ss')}</>
                ),
            },
            {
                Header: 'Total',
                accessor: 'montantTotal',
                Cell: ({ row: { original } }: { row: { original: FactureLivreurInterface } }) => (
                    <>{original.montantTotal} DH</>
                ),
            },
            {
                Header: 'Payé',
                accessor: 'montantPaid',
                Cell: ({ row: { original } }: { row: { original: FactureLivreurInterface } }) => (
                    <>{original.montantPaid} DH</>
                ),
            },
            {
                Header: 'Reste',
                accessor: 'montantRest',
                Cell: ({ row: { original } }: { row: { original: FactureLivreurInterface } }) => (
                    <>{original.montantRest} DH</>
                ),
            },
            ...(contextUser && !isOnlyLivreur(contextUser?.userData)
                ? [
                      {
                          Header: 'Service',
                          accessor: 'factureLivreurPaiements',
                          Cell: ({ row: { original } }: { row: { original: FactureLivreurInterface } }) => (
                              <>
                                  {original.factureLivreurCommandes?.reduce((accumulator: number, item: any) => {
                                      return accumulator + calculatePrix(item.commande, contextUser?.userData!);
                                  }, 0)}{' '}
                                  DH
                              </>
                          ),
                      },
                  ]
                : []),
            {
                Header: 'Comission',
                accessor: 'totalCommisionLivreur',
                Cell: ({ row: { original } }: { row: { original: FactureLivreurInterface } }) => (
                    <>{original.totalCommisionLivreur} DH</>
                ),
            },
            ...(contextUser && !isOnlyLivreur(contextUser?.userData)
                ? [
                      {
                          Header: 'Marge',
                          accessor: '--benifice',
                          Cell: ({ row: { original } }: { row: { original: FactureLivreurInterface } }) => (
                              <>
                                  {(original.factureLivreurCommandes?.reduce((accumulator: number, item: any) => {
                                      return accumulator + calculatePrix(item.commande, contextUser?.userData!);
                                  }, 0) ?? 0) - original.totalCommisionLivreur}{' '}
                                  DH
                              </>
                          ),
                          disableSortBy: true,
                          disableFilters: true,
                      },
                  ]
                : []),
            {
                Header: 'status',
                accessor: 'Etat',
                Cell: ({ row: { original } }: { row: { original: FactureLivreurInterface } }) => (
                    <Badge bg={original.status === 'Done' ? 'success' : 'warning'}>
                        {original.status === 'Done' ? 'Payé' : original.status}
                    </Badge>
                ),
            },
            {
                Header: 'Info',
                accessor: '--info',
                Cell: ({ row: { original } }: { row: { original: FactureLivreurInterface } }) => {
                    const newSign = original.factureLivreurPaiements?.some((d) => d.status === 'Encours');
                    return newSign ? <Badge bg="info">Nouveau paiement</Badge> : <>-</>;
                },
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: 'Actions',
                accessor: '--actions',
                Cell: ({ row: { original } }: { row: { original: FactureLivreurInterface } }) => (
                    <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle id="dropdown-autoclose-true"></Dropdown.Toggle>
                        <Dropdown.Menu className="btnDropDownInList" style={{ width: 'auto', minWidth: 'auto' }}>
                            <Dropdown.Item className="text-left" onClick={() => openFactureDetailsModal(original)}>
                                <FontAwesomeIcon icon={faInfo} size="xs" style={{ color: '#376ca9' }} width="20" />
                                Détails
                            </Dropdown.Item>
                            <Dropdown.Item
                                className="text-left"
                                onClick={() => openFactureToPrintModal(original, false)}
                            >
                                <FontAwesomeIcon icon={faPrint} size="xs" style={{ color: '#776ca9' }} width="20" />
                                Imprimer facture
                            </Dropdown.Item>
                            {contextUser && !isOnlyLivreur(contextUser?.userData) && (
                                <Dropdown.Item
                                    className="text-left"
                                    onClick={() => openFactureToPrintModal(original, true)}
                                >
                                    <FontAwesomeIcon icon={faPrint} size="xs" style={{ color: '#776ca9' }} width="20" />
                                    Imprimer facture (Version livreur)
                                </Dropdown.Item>
                            )}
                            {
                                // contextUser && isOnlyLivreur(contextUser?.userData) && // i made this to let everone add payment
                                original.status === 'Encours' && (
                                    <Dropdown.Item
                                        className="text-left"
                                        onClick={() => openFacturePaiementFormModal(original)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faHandHoldingDollar}
                                            size="xs"
                                            style={{ color: '#376ca9' }}
                                            width="20"
                                        />
                                        Payer
                                    </Dropdown.Item>
                                )
                            }
                            {contextUser &&
                                contextUser?.userData?.userroles?.some(
                                    (d) =>
                                        d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                                        d.role.nomRole === RolesMap.FOURNISSEUR_FACTURE ||
                                        d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                ) &&
                                original.status === 'Encours' &&
                                (original.factureLivreurPaiements?.length === 0 ||
                                    !original.factureLivreurPaiements?.some(
                                        (p) => p.status === 'Encours' || p.status === 'Approved',
                                    )) && (
                                    <Dropdown.Item
                                        className="text-left"
                                        onClick={() => openFactureDeletingFormModal(original)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faTrash}
                                            size="xs"
                                            style={{ color: '#bd0c0c' }}
                                            width="20"
                                        />
                                        <span className="text-danger">Supprimer</span>
                                    </Dropdown.Item>
                                )}
                        </Dropdown.Menu>
                    </Dropdown>
                ),
                disableSortBy: true,
                disableFilters: true,
            },
        ],
        [factures.items, contextUser, selectedLivreur],
    );
    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { path: '/commandes', text: 'Commandes' },
        { active: true, text: 'Liste factures livreur' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Liste factures livreur
                </TitleElement>
                <Button variant="primary" onClick={() => refreshList()}>
                    <FontAwesomeIcon icon={faRefresh} size="xs" style={{ color: '#fff' }} width="20" />
                </Button>
            </div>
            {contextUser && !isOnlyLivreur(contextUser?.userData) && contextUser.userData.type === 'Fournisseur' && (
                <Row className="mb-2 holdit_notEffect">
                    <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_date_debut">
                        <Form.Label>Date début:</Form.Label>
                        <Datetime
                            inputProps={{
                                name: 'date_debut',
                                className: 'form-control form-control-sm',
                                readOnly: true,
                            }}
                            onChange={(e: any) => onChangeDateDebut(e)}
                            value={moment(date_debut, 'YYYY-MM-DD  HH:mm:ss').format('DD/MM/YYYY')}
                            dateFormat="DD/MM/YYYY"
                            timeFormat={false}
                            closeOnSelect
                        />
                    </Form.Group>
                    <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_date_fin">
                        <Form.Label>Date fin:</Form.Label>
                        <Datetime
                            inputProps={{
                                name: 'date_fin',
                                className: 'form-control form-control-sm',
                                readOnly: true,
                            }}
                            onChange={(e: any) => onChangeDateFin(e)}
                            value={moment(date_fin, 'YYYY-MM-DD  HH:mm:ss').format('DD/MM/YYYY')}
                            dateFormat="DD/MM/YYYY"
                            timeFormat={false}
                            closeOnSelect
                        />
                    </Form.Group>

                    <Form.Group as={Col} xs={12} lg={3} md={3} controlId="form_grp_Client">
                        <Form.Label>Paiement:</Form.Label>
                        <Form.Select
                            size="sm"
                            name="selectedPaiementEtat"
                            value={selectedStatus}
                            onChange={(e) => {
                                const {
                                    target: { value },
                                } = e;
                                setSelectedStatus(value);
                                handlePageNFilterChange(
                                    1,
                                    JSON.stringify({
                                        dDebut: date_debut,
                                        dFin: date_fin,
                                        status: value,
                                        livreur: selectedLivreur,
                                    }),
                                );
                            }}
                            placeholder="Selectionner une etat de paiement"
                        >
                            <option value="">Tous</option>
                            <option value="paid">Payée</option>
                            <option value="not-paid">Non Payée</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_Livreur">
                        <Form.Label>Livreur:</Form.Label>
                        <Form.Select
                            size="sm"
                            name="selectedLivreur"
                            value={selectedLivreur!}
                            onChange={(e) => {
                                const {
                                    target: { value },
                                } = e;
                                setSelectedLivreur(value);
                                handlePageNFilterChange(
                                    1,
                                    JSON.stringify({
                                        dDebut: date_debut,
                                        dFin: date_fin,
                                        status: selectedStatus,
                                        livreur: value,
                                    }),
                                );
                            }}
                            placeholder="Selectionner un livreur"
                        >
                            <option value="">Selectionner un livreur</option>
                            {livreurs.map((d: UserInterface) => (
                                <option key={d.idUser} value={d.idUser}>
                                    {DisplayName(d)}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Row>
            )}
            <TableTemplate
                columns={tableHeaders}
                data={factures.items}
                options={{
                    pagination: true,
                    showGlobalFilter: true,
                    debugData: false,
                    showFiltersRowByColumn: true,
                    rowSelecting: false,
                    hiddenColumns: ['userId'],
                }}
            >
                <Row>
                    <Col className="d-flex flex-col justify-content-end align-items-end">
                        <Paginator
                            defaultPage={activePage}
                            totalPages={Math.ceil(factures.count / TOTAL_DATA_PER_PAGE)}
                            callback={(e_page) =>
                                handlePageNFilterChange(
                                    e_page,
                                    JSON.stringify({
                                        dDebut: date_debut,
                                        dFin: date_fin,
                                        status: selectedStatus,
                                        livreur: selectedLivreur,
                                    }),
                                )
                            }
                        />
                    </Col>
                </Row>
            </TableTemplate>
            <CustomModal
                title="facture details"
                size="lg"
                show={showFactureDetails}
                handleClose={closeFactureDetailsModal}
            >
                {currentFacture ? (
                    <FactureDetails current={currentFacture!} setCurrent={setCurrentFacture} setList={setFactures} />
                ) : (
                    <></>
                )}
            </CustomModal>
            <CustomModal
                title="Payer facture"
                size="lg"
                show={showFacturePaiementForm}
                handleClose={closeFacturePaiementFormModal}
            >
                {currentFacture ? (
                    <FacturePaiementForm
                        current={currentFacture!}
                        setCurrent={setCurrentFacture}
                        setList={setFactures}
                        close={() => closeFacturePaiementFormModal()}
                    />
                ) : (
                    <></>
                )}
            </CustomModal>
            <CustomModal
                title="Imprimer facture"
                size="lg"
                show={showFactureToPrint}
                handleClose={closeFactureToPrintModal}
            >
                {currentFacture ? <FacturePrint current={currentFacture!} livreurVersion={livreurVersion} /> : <></>}
            </CustomModal>
            <CustomModal
                title="Supprimer facture"
                size="lg"
                show={showFactureDeletingForm}
                handleClose={closeFactureDeletingFormModal}
            >
                {currentFacture ? (
                    <DeleteFactureAction
                        current={currentFacture!}
                        setCurrent={setCurrentFacture}
                        setList={setFactures}
                        close={() => closeFactureDeletingFormModal()}
                    />
                ) : (
                    <></>
                )}
            </CustomModal>
        </>
    );
};

export default FactureLivreurIndexPage;
