import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { Button, Form, Spinner } from 'react-bootstrap';
import { TableColumnType } from 'react-bs-datatable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';

import RegionInterface from 'types/interfaces/region.interface';
import { getRegions, saveRegion } from 'services/region.service';

import CustomModal from 'components/UI/customModal';
import Datatable from 'components/shared/datatable';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import TitleElement from 'components/UI/titleElement';

const schema = yup.object({
    idRegion: yup.number(),
    nomRegion: yup.string().required(),
});

const RegionPage: React.FC = () => {
    const [regions, setRegions] = useState<RegionInterface[]>([]);
    const [currentRegion, setCurrentRegion] = useState<RegionInterface | null>(null);

    const [showFormModal, setShowFormModal] = useState<boolean>(false);
    const [formModalEditMode, setFormModalEditMode] = useState<boolean>(false);
    const [spinIt, setSpinIt] = useState<boolean>(false);

    const handleFormModalClose = () => {
        setCurrentRegion(null);
        setShowFormModal(false);
    };

    const handleFormModalOpen = (editMode = false, regionsData?: RegionInterface) => {
        if (regionsData) setCurrentRegion(regionsData);
        setFormModalEditMode(editMode);
        setShowFormModal(true);
    };

    const tableHeaders: TableColumnType<any>[] = [
        {
            prop: 'nomRegion',
            title: 'Nom',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'null',
            title: 'Action',
            alignment: { horizontal: 'right' },
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: RegionInterface) => (
                <Button
                    variant="outline-secondary"
                    className="button-input-group-effect"
                    id="basic-addon1"
                    onClick={() => handleFormModalOpen(true, row)}
                >
                    <FontAwesomeIcon icon={faEdit} size="xs" style={{ color: '#337ab7' }} />
                </Button>
            ),
            isFilterable: false,
            isSortable: false,
        },
    ];

    useEffect(() => {
        document.title = 'Paramétrage Ville - DS';

        getRegions()
            .then((response: AxiosResponse<RegionInterface | any>) => {
                const { data } = response;
                setRegions(data);
            })
            .catch();
    }, []);

    const submitForm = async (regionValues: RegionInterface | any) => {
        try {
            setSpinIt(true);
            const response = await saveRegion(regionValues);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Ville a été enregistré avec succès');
                const regionsData = [...regions].filter((dd) => dd.idRegion !== resultData.idRegion);
                regionsData.unshift(resultData);
                setRegions(regionsData);
                handleFormModalClose();
                setSpinIt(false);
            }
        } catch (Exception) {
            setSpinIt(false);
        }
    };

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { text: 'Paramétrage' },
        { active: true, text: 'Ville' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Villes
                </TitleElement>
                <Button
                    variant="outline-secondary"
                    className="button-input-group-effect"
                    id="basic-addon1"
                    onClick={() => handleFormModalOpen()}
                >
                    <FontAwesomeIcon icon={faPlus} size="xs" style={{ color: '#337ab7' }} />
                </Button>
            </div>
            <Datatable data={regions} tableColumns={tableHeaders} />
            <CustomModal
                title={`${!!formModalEditMode ? 'Edit' : 'Nouveau'} Ville`}
                size="lg"
                show={showFormModal}
                handleClose={handleFormModalClose}
            >
                <div>
                    <Formik
                        validationSchema={schema}
                        onSubmit={(values) => submitForm(values)}
                        enableReinitialize={true}
                        initialValues={{
                            idRegion: currentRegion && !!formModalEditMode ? currentRegion.idRegion : undefined,
                            nomRegion: currentRegion && !!formModalEditMode ? currentRegion.nomRegion : '',
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                {values.idRegion && (
                                    <Form.Control
                                        type="hidden"
                                        name="idRegion"
                                        value={values.idRegion}
                                        readOnly={true}
                                    />
                                )}
                                <Form.Group controlId="form_grp_nom">
                                    <Form.Label>Nom</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="nomRegion"
                                        placeholder="Nom"
                                        value={values.nomRegion}
                                        onChange={handleChange}
                                        isInvalid={!!errors.nomRegion}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.nomRegion}</Form.Control.Feedback>
                                </Form.Group>
                                <div className="d-grid gap-2 mt-3">
                                    <Button variant="primary" type="submit" disabled={!!spinIt}>
                                        Enregistrer
                                        {!!spinIt && (
                                            <>
                                                &nbsp; <Spinner animation="border" size="sm" />
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </CustomModal>
        </>
    );
};

export default RegionPage;
