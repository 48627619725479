import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import PartnershipInterface from 'types/interfaces/partnership.interface';

const apiEndPoint = `${apiUrl}/partnerships`;

export const getPartnerships = (): Promise<PartnershipInterface[] | any> => {
    return http.get(apiEndPoint);
};

export const getKey = (): Promise<string | any> => {
    return http.get(`${apiEndPoint}/keygen`);
};

export const createPartnership = (partnership: { key: string }) => {
    const body = {
        ...partnership,
    };
    return http.post(`${apiEndPoint}`, body);
};

export const acceptInvitation = (key: string) => {
    return http.post(`${apiEndPoint}/accept/${key}`);
};

export const deletePartnership = (id: number): Promise<any> => {
    return http.delete(`${apiEndPoint}/${id}`);
};
