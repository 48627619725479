import React from 'react';
import { Modal, Button } from 'react-bootstrap';

type Props = {
    id?: string;
    show: boolean;
    handleClose: () => void;
    title: string;
    useCbFunc?: boolean;
    variant?: string;
    buttonVText?: string;
    cbFunc?: () => void;
    body?: string;
    children?: JSX.Element | JSX.Element[];
    size?: 'sm' | 'lg' | 'xl';
    fullscreen?: true | string | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down';
    footerCenter?: boolean;
    bodyClassNames?: string;
    showFooter?: boolean;
};

const CustomModal: React.FC<Props> = ({
    id,
    show,
    handleClose,
    title,
    useCbFunc = false,
    variant = 'info',
    buttonVText = 'Validate',
    cbFunc,
    body,
    children,
    size,
    fullscreen,
    footerCenter,
    showFooter,
    bodyClassNames,
}: Props) => {
    return (
        <>
            <Modal size={size} show={show} onHide={handleClose} fullscreen={fullscreen} id={id}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={bodyClassNames}>{body ? body : children}</Modal.Body>
                {showFooter && (
                    <Modal.Footer className={!!footerCenter ? 'justify-content-center' : ''}>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        {useCbFunc === true && (
                            <Button variant={variant} onClick={cbFunc}>
                                {buttonVText}
                            </Button>
                        )}
                    </Modal.Footer>
                )}
            </Modal>
        </>
    );
};
CustomModal.defaultProps = {
    showFooter: true,
};

export default CustomModal;
