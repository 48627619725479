import React, { useContext } from 'react';
import { faWhatsapp, faWhatsappSquare } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect';

import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';

import { isTheOwnerOfCommande, canControlCommande, formatPhoneNumber } from 'utils/helpers';
import CommandeInterface from 'types/interfaces/commande.interface';

type Props = {
    phone: string;
    currentCommande: CommandeInterface;
};

const WHATSAPP_APP = 'whatsapp://send?phone=';
const WHATSAPP_WEB = 'https://api.whatsapp.com/send?phone=';

const ContacterAcheteur: React.FC<Props> = ({ phone, currentCommande }: Props) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);
    return (
        <>
            {currentCommande &&
                contextUser &&
                (contextUser.userData.type === 'Client' ||
                    isTheOwnerOfCommande(contextUser?.userData?.idFournisseur!, currentCommande!) ||
                    canControlCommande(contextUser?.userData?.idFournisseur!, currentCommande!)) && (
                    <>
                        <div className="d-flex gap-2 justify-content-between align-items-end mt-2">
                            <h6>Contacter l&apos;acheteur:</h6>
                            <div className="d-flex gap-2 justify-content-end">
                                {isMobile ? (
                                    <a
                                        title="Envoyer sur whatsapp"
                                        className="btn btn-outline-success"
                                        href={`${WHATSAPP_APP}${formatPhoneNumber(phone)}`}
                                        target="blank"
                                    >
                                        <FontAwesomeIcon
                                            icon={faWhatsappSquare}
                                            size="lg"
                                            style={{ color: '#25d266' }}
                                        />
                                    </a>
                                ) : (
                                    <a
                                        title="Envoyer sur whatsapp web"
                                        className="btn btn-outline-success"
                                        href={`${WHATSAPP_WEB}${formatPhoneNumber(phone)}`}
                                        target="blank"
                                    >
                                        <FontAwesomeIcon icon={faWhatsapp} size="lg" style={{ color: '#25d266' }} />
                                    </a>
                                )}
                                <a
                                    title="Appeler maintenant"
                                    className="btn btn-outline-info"
                                    href={`tel:${phone}`}
                                    target="blank"
                                >
                                    <FontAwesomeIcon icon={faPhone} size="lg" style={{ color: '#337ab7' }} />
                                </a>
                            </div>
                        </div>
                        <hr />
                    </>
                )}
        </>
    );
};

export default ContacterAcheteur;
