import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Navigate, Link, useLocation } from 'react-router-dom';
import { Form, Button, Row, Col, Alert, Fade } from 'react-bootstrap';
import styled from 'styled-components';
import { isTablet, useMobileOrientation } from 'react-device-detect';
import * as yup from 'yup';
import { Formik } from 'formik';
import { authCredentials } from 'types/index';

import appStore, { StoreStateInterface } from 'store/appStore';
import AppContext from 'utils/appContext';
import { postLogin as httpLogin } from 'services/authService';

import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import RecaptchaComponent from 'components/shared/recaptchaComponent';

const FormWrapper = styled.div`
    color: #000;
    border: 1px solid #fff;
    // border: 1px solid #d0e6ff;
    background: #fff;
    border-radius: 15px;
    .cli_icon-login {
        color: #205fa6;
        font-size: 10rem;
    }
    .cli_img-login {
        width: 15rem;
    }
    .cli_login-header {
        h2,
        p {
            color: #205fa6;
        }
    }
    hr {
        background-color: rgb(150 206 0);
        height: 3px;
        margin-left: -15px;
        margin-right: -15px;
    }
    form {
        .text-label-login {
            color: #205fa6;
        }
    }
    a {
        color: #146ac6;
        text-decoration: none;
        background-color: transparent;
        text-decoreation: none;
    }
    button {
        background-color: #92bf19;
        border-color: #92bf19;
        :hover {
            background-color: rgb(150 206 0);
            border-color: rgb(150 206 0);
        }
    }
    .cli-copyrights {
        color: #205fa6;
        font-weight: 400;
        font-size: 14px;
        font-family: 'Helvetica Neue', 'Segoe UI', helvetica, verdana, sans-serif;
    }
    .cli-design-cr {
        color: #ffffff;
        background: #205fa6;
        border: 1px solid #205fa6;
        border-radius: 10px 10px 50px 50px;
    }
`;

const schema = yup.object({
    email: yup.string().email().required(),
    pwd: yup.string().required(),
});

interface stateType {
    referrer: string;
}

type Props = any;

const LoginComponent: React.FC<Props> = ({ googleReCaptchaProps }: Props) => {
    const history = useNavigate();
    const location = useLocation();
    const state = location.state as stateType;

    const setSiteLogo = appStore((state: StoreStateInterface) => state.setSiteLogo);
    const { user, setUser } = useContext(AppContext);

    const [redirectTo, setRedirectTo] = useState('/');
    const [error, setError] = useState<any>(null);

    const { isLandscape } = useMobileOrientation();

    useEffect(() => {
        const REDIRECT_TO = state && state.referrer !== '/' ? `${encodeURIComponent(state.referrer)}` : '';
        setRedirectTo(`${decodeURIComponent(REDIRECT_TO)}`);
    }, []);

    const handleSumitForm = async (credentials: any) => {
        const { executeRecaptcha } = googleReCaptchaProps;
        if (!executeRecaptcha) {
            console.log('Recaptcha has not been loaded');
            return;
        }
        try {
            const gReCaptchaToken = await executeRecaptcha('DS_LOGIN');
            if (!gReCaptchaToken) {
                setError('ReCaptcha Problem!');
                return;
            }
            submitForm({ ...credentials, reCaptchaToken: gReCaptchaToken });
        } catch (Exception) {
            setError('ReCaptcha Problem!');
        } finally {
        }
    };

    const submitForm = async (credentials: authCredentials | any) => {
        setError(null);
        try {
            const { user, imageLogo } = await httpLogin(credentials);
            setUser!(user);
            setSiteLogo(imageLogo);
            history(redirectTo, { replace: true });
        } catch (ex) {
            const { message = undefined }: any = ex;
            setError(message || 'Error occured!');
        }
    };

    return user ? (
        <Navigate
            to={{
                pathname: '/',
            }}
        />
    ) : (
        <div className="container-94vh">
            <Row className="bg-login-content">
                {isTablet && isLandscape ? (
                    <Col lg={{ span: 8, offset: 2 }}>
                        <FormWrapper className="px-3 py-3 mx-3 my-3">
                            <Row>
                                <Col>
                                    <div className="text-center cli_login-header">
                                        {/* <BsPersonSquare className="cli_icon-login mb-3" /> */}
                                        <img src="/images/ds_logo.png" alt="DS-logo" className="cli_img-login mb-3" />
                                        <h2>Se connecter à DS</h2>
                                        <p>Delivery System - DS</p>
                                        <Fade timeout={500} in={!!error}>
                                            {error ? <Alert variant="danger">{error}</Alert> : <></>}
                                        </Fade>
                                    </div>
                                </Col>
                                <Col className="align-self-center">
                                    <Formik
                                        validationSchema={schema}
                                        onSubmit={(values) => handleSumitForm(values)}
                                        enableReinitialize={true}
                                        initialValues={{
                                            email: '',
                                            pwd: '',
                                        }}
                                    >
                                        {({ handleSubmit, handleChange, values, errors }) => (
                                            <Form noValidate onSubmit={handleSubmit}>
                                                <Form.Group
                                                    controlId="formBasicEmail"
                                                    className="text-left text-label-login"
                                                >
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        name="email"
                                                        placeholder="Enter Email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.email}
                                                        autoComplete="email"
                                                    />
                                                </Form.Group>

                                                <Form.Group
                                                    controlId="formBasicPwd"
                                                    className="text-left text-label-login mb-3"
                                                >
                                                    <Form.Label>Password</Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        placeholder="Password"
                                                        name="pwd"
                                                        value={values.pwd}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.pwd}
                                                        autoComplete="current-password"
                                                    />
                                                </Form.Group>
                                                <Button className="w-100" variant="primary" type="submit">
                                                    Sign In
                                                </Button>
                                            </Form>
                                        )}
                                    </Formik>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <hr />
                                    <div className="mt-3 text-center">
                                        <Link to="/password/forget">Forget Password?</Link>
                                        <div className="mt-3 cli-copyrights cli-design-cr">
                                            &#169; {new Date().getFullYear()}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </FormWrapper>
                    </Col>
                ) : (
                    <Col md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
                        <FormWrapper className="px-3 py-3 mx-3 my-3">
                            <div className="text-center cli_login-header">
                                {/* <BsPersonSquare className="cli_icon-login mb-3" /> */}
                                <img src="/images/ds_logo.png" alt="DS-logo" className="cli_img-login mb-3" />
                                <h2>Se connecter à DS</h2>
                                <p>Delivery System - DS</p>
                                <Fade timeout={500} in={!!error}>
                                    {error ? <Alert variant="danger">{error}</Alert> : <></>}
                                </Fade>
                            </div>
                            <hr />
                            <Formik
                                validationSchema={schema}
                                onSubmit={(values) => handleSumitForm(values)}
                                enableReinitialize={true}
                                initialValues={{
                                    email: '',
                                    pwd: '',
                                }}
                            >
                                {({ handleSubmit, handleChange, values, errors }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Form.Group controlId="formBasicEmail" className="text-left text-label-login">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                placeholder="Enter Email"
                                                value={values.email}
                                                onChange={handleChange}
                                                isInvalid={!!errors.email}
                                                autoComplete="email"
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="formBasicPwd" className="text-left text-label-login">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder="Password"
                                                name="pwd"
                                                value={values.pwd}
                                                onChange={handleChange}
                                                isInvalid={!!errors.pwd}
                                                autoComplete="current-password"
                                            />
                                        </Form.Group>
                                        <div className="d-grid gap-2 mt-3">
                                            <Button className="w-100" variant="primary" type="submit">
                                                Sign In
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                            <div className="mt-3 text-center">
                                <Link to="/password/forget">Forget Password?</Link>
                                <div className="mt-3 cli-copyrights cli-design-cr">
                                    &#169; {new Date().getFullYear()}
                                </div>
                            </div>
                        </FormWrapper>
                    </Col>
                )}
            </Row>
        </div>
    );
};

const LoginPage = withGoogleReCaptcha(LoginComponent);

export default () => (
    <RecaptchaComponent>
        <LoginPage />
    </RecaptchaComponent>
);
