import axios from 'axios';
import { toast } from 'react-toastify';
import { localStorageToken } from 'constantes/envLoader';
import EventBus from 'utils/eventBus';

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const ResponseStatus = error.response ? error.response.status : -1;
        switch (true) {
            case ResponseStatus === 401:
                // toast.error('Unauthorized!');
                setTimeout(() => EventBus.dispatch('logout'), 1000);
                return Promise.reject();
                break;
            case ResponseStatus === 403:
                toast.error("You don't have permission to do this.. (Forbidden)!");
                break;
            case ResponseStatus === -1 || (ResponseStatus > 401 && ResponseStatus < 403) || ResponseStatus > 404:
                toast.error('Unexpected error occurred!');
                break;
        }
        return Promise.reject(error.response.data);
    },
);

axios.interceptors.request.use(
    (config) => {
        // Do something before request is sent
        const token = localStorage.getItem(localStorageToken);
        config.headers = {
            Authorization: `Bearer ${token}`,
        };
        return config;
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error);
    },
);

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    patch: axios.patch,
};
