import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface PropsType {
    level: 1 | 2 | 3 | 5 | 6;
    children?: ReactNode;
}

type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export const HeadingComponent: React.FC<PropsType> = ({ level, children = null }: PropsType) => {
    const Tag = `h${level}` as HeadingTag;
    return <Tag>{children}</Tag>;
};

const CustomDiv = styled.div<any>`
    position: relative;
    > * {
        padding-top: 15px;
        font-family: 'Open Sans', Arial, sans-serif;
    }
    > *::after {
        position: absolute;
        content: '';
        height: 7px;
        margin: 0 auto;
        left: 0;
        top: 10%;
        width: ${(props: any) => props.lineWidth};
        background: #4b80ad;
    }
`;

interface Props {
    level?: 1 | 2 | 3 | 5 | 6;
    children?: ReactNode;
    classNames?: string;
    lineWidth?: string;
}
const TitleElement: React.FC<Props> = ({ children, level, classNames, lineWidth }: Props) => {
    return (
        <CustomDiv lineWidth={lineWidth!} className={classNames}>
            <HeadingComponent level={level!}>{children}</HeadingComponent>
        </CustomDiv>
    );
};

TitleElement.defaultProps = {
    level: 1,
    lineWidth: '25%',
};

export default TitleElement;
