import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import ServiceInterface from 'types/interfaces/service.interface';

const apiEndPoint = `${apiUrl}/services`;

export const getServices = (): Promise<ServiceInterface[] | any> => {
    return http.get(apiEndPoint);
};

export const saveService = (service: ServiceInterface) => {
    const body = {
        ...service,
    };
    if (service.idService) {
        return http.put(`${apiEndPoint}/${service.idService}`, body);
    }
    return http.post(`${apiEndPoint}`, body);
};
