import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import AcheteurInterface from 'types/interfaces/acheteur.interface';

const apiEndPoint = `${apiUrl}/acheteurs`;

export const saveAcheteur = (acheteur: AcheteurInterface) => {
    const body = {
        ...acheteur,
    };
    if (acheteur.idAcheteur) {
        return http.put(`${apiEndPoint}/${acheteur.idAcheteur}`, body);
    }
    return http.post(`${apiEndPoint}`, body);
};

export const getFilterAcheteursByGSM = (GSM: string): Promise<AcheteurInterface[] | any> => {
    return http.get(`${apiEndPoint}/gsm-filter/${GSM}`);
};
