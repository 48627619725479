import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Spinner, Alert } from 'react-bootstrap';

import { toast } from 'react-toastify';

import TransfertCommandeInterface from 'types/interfaces/transfertCommande.interface';
import { createFacture } from 'services/transfert.service';

type Props = {
    checkedTransferts: number[];
    close: () => void;
    transferts: TransfertCommandeInterface[];
    setTransferts: Dispatch<SetStateAction<TransfertCommandeInterface[]>>;
};

const FactureCreationForm: React.FC<Props> = ({ checkedTransferts, close, transferts, setTransferts }: Props) => {
    const [spinIt, setSpinIt] = useState<boolean>(false);

    const submit = async () => {
        try {
            setSpinIt(true);
            createFacture({ ids: checkedTransferts })
                .then(() => {
                    setTransferts((pre: TransfertCommandeInterface[]) =>
                        pre.filter(
                            (item: TransfertCommandeInterface) => !checkedTransferts.includes(item.idTransfertCommande),
                        ),
                    );
                    toast.success('Facture a été  créée avec succès');
                    setSpinIt(false);
                    close();
                })
                .catch(() => {
                    toast.error('Erreur est survenue!');
                })
                .finally(() => {
                    setSpinIt(false);
                });
        } catch (Exception) {
            // console.log(err);
            setSpinIt(false);
        }
    };

    return (
        <>
            <Alert variant="info">
                <div className="d-flex justify-content-between">
                    <div>
                        Voulez vraiement creer une facture pour ces <b>{checkedTransferts.length}</b> transferts ?
                    </div>
                    <b>{transferts.reduce((a: number, b: TransfertCommandeInterface) => a + b.prixTarif, 0)} DH</b>
                </div>
            </Alert>
            <div className="d-flex justify-content-end">
                <Button variant="primary" type="button" onClick={() => submit()} disabled={!!spinIt}>
                    Confirmer
                    {!!spinIt && (
                        <>
                            &nbsp; <Spinner animation="border" size="sm" />
                        </>
                    )}
                </Button>
            </div>
        </>
    );
};

export default FactureCreationForm;
