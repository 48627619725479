import React, { useState } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import CommandeInterface from 'types/interfaces/commande.interface';

import useUserStore, { StoreStateInterface } from 'store/appStore';
import { sendRetourCommandeRange } from 'services/commande.service';

export const schema = yup.object({
    ids: yup.array().of(yup.number().required()).min(1).required(),
    idLivreur: yup.number().required(),
    remarque: yup.string(),
    idEtatTitre: yup.number(),
});

type Props = {
    commandes: CommandeInterface[];
    setCommandes: React.Dispatch<React.SetStateAction<CommandeInterface[]>>;
};

const RetourAgence: React.FC<Props> = ({ commandes, setCommandes }: Props) => {
    const [spinIt, setSpintIt] = useState<boolean>(false);

    const setLoadingFalse = useUserStore((state: StoreStateInterface) => state.setLoadingFalse);
    const setLoadingTrue = useUserStore((state: StoreStateInterface) => state.setLoadingTrue);

    const submit = async () => {
        try {
            setLoadingTrue();
            setSpintIt(true);
            const response = await sendRetourCommandeRange(commandes.map((item: CommandeInterface) => item.idCommande));
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Commande(s) a été retournée(s)..');
                setCommandes((prev: CommandeInterface[]) =>
                    prev.filter((commande: CommandeInterface) => !resultData.includes(commande.idCommande)),
                );
            }
        } catch (Exception) {
        } finally {
            setLoadingFalse();
            setSpintIt(false);
        }
    };

    return (
        <div className="d-flex justify-content-end">
            {commandes.length > 0 && (
                <OverlayTrigger
                    trigger="focus"
                    placement="left"
                    overlay={
                        <Popover id="popover-basic">
                            <Popover.Header>Êtes-vous sûr??</Popover.Header>
                            <Popover.Body className="text-center">
                                <Button
                                    size="sm"
                                    type="button"
                                    variant="danger"
                                    onClick={() => submit()}
                                    disabled={!!spinIt}
                                >
                                    Confirmer
                                </Button>
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <Button size="sm" variant="outline-warning" disabled={!!spinIt}>
                        Retour agence
                    </Button>
                </OverlayTrigger>
            )}
        </div>
    );
};

export default RetourAgence;
