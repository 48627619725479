import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import FactureInterface from 'types/interfaces/facture.interface';
import PaiementInterface from 'types/interfaces/paiement.interface';
import CommandeInterface from 'types/interfaces/commande.interface';

const apiEndPoint = `${apiUrl}/factures`;

export const addFactureCommandes = (data: { ids: number[]; facture: FactureInterface }) => {
    return http.post(`${apiEndPoint}/commandes`, data);
};

export const loadFullFacture = (
    idFacture: number,
): Promise<{ facture: FactureInterface; commandes: CommandeInterface[] } | any> => {
    return http.get(`${apiEndPoint}/full/${idFacture}`);
};

export const getFacturesList = (
    total: number,
    page: number,
    idClient?: number | string,
    paiement?: number | string,
    dateDebut?: Date | string,
    dateFin?: Date | string,
): Promise<any> => {
    const query = `?page=${page}&total=${total}${idClient ? '&idClient=' + idClient : ''}${
        dateDebut ? '&dateDebut=' + dateDebut : ''
    }${dateFin ? '&dateFin=' + dateFin : ''}${paiement ? '&paiement=' + paiement : ''}`;
    return http.get(`${apiEndPoint}/list${query}`);
};

export const getFacturesPaiements = (
    total: number,
    page: number,
    idClient?: number | string,
    dateDebut?: Date | string,
    dateFin?: Date | string,
): Promise<any> => {
    const query = `?page=${page}&total=${total}${idClient ? '&idClient=' + idClient : ''}${
        dateDebut ? '&dateDebut=' + dateDebut : ''
    }${dateFin ? '&dateFin=' + dateFin : ''}`;
    return http.get(`${apiEndPoint}/paiements${query}`);
};

export const getDeepFactureById = (id: number): Promise<FactureInterface | any> => {
    return http.get(`${apiEndPoint}/deep/${id}`);
};

export const updateFacture = (facture: FactureInterface) => {
    return http.put(`${apiEndPoint}/edit/${facture.idFacture}`, facture);
};

export const savePaiement = (idFacture: number, paiement: PaiementInterface) => {
    if (paiement.idPaiement) {
        return http.put(`${apiEndPoint}/${idFacture}/paiement/${paiement.idPaiement}`, paiement);
    }
    return http.post(`${apiEndPoint}/${idFacture}/paiement`, paiement);
};

export const deleteFacture = (id: number) => {
    return http.delete(`${apiEndPoint}/${id}`);
};

export const getAutoCommandesFacture = (beforeDate: Date | string) => {
    return http.get(`${apiEndPoint}/commandes/auto?beforeDate=${beforeDate.toString()}`);
};

export const createAutoCommandesFacture = (body: { beforeDate: Date | string; clientsIds: number[] }) => {
    return http.post(`${apiEndPoint}/commandes/auto`, body);
};
