import React, { useContext } from 'react';
import { Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import moment from 'moment';

import { addLeadingZeros, getFournisseurAliasUrl, utf8_to_b64 } from 'utils/helpers';
import Printer, { SignatureSection } from 'components/shared/printer';
import BarCode from 'components/UI/barCode';
import QrCode from 'components/UI/qrCode';
import CommandeInterface from 'types/interfaces/commande.interface';
import ColisCommandeInterface from 'types/interfaces/colisCommande.interface';
import CommandeTarifInterface from 'types/interfaces/commandeTarif.interface';
import SiteLogo from 'components/UI/siteLogo';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';

type Props = {
    commande: CommandeInterface;
};

const PrintCommande: React.FC<Props> = ({ commande }: Props) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);
    return (
        <Printer documentTitle="commande_facture" whilePrintingContent={() => <SignatureSection />}>
            <div className="d-flex justify-content-between align-items-center p-2">
                <div className="d-flex flex-row align-items-center gap-2">
                    <SiteLogo />
                    <div className="d-flex flex-column border-start ps-1">
                        <span className="font-weight-bold">Facture Ref</span> <b>{commande.facture?.refFacture}</b>{' '}
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-between text-end">
                    <span>Le {moment(commande.facture?.dateFacture).format('DD/MM/YYYY')}</span>
                    {commande.facture?.paiement && (
                        <>
                            <div className="flexbasis100-break"></div>
                            <span>
                                Facture payée le {moment(commande.facture?.paiement.datePaiement).format('DD/MM/YYYY')}
                            </span>
                        </>
                    )}
                </div>
            </div>
            <hr style={{ margin: 0 }} />
            <Row className="p-2">
                <Col md="6" lg="6" sm="6" xs="6">
                    <Row>
                        <Col auto>Client:</Col>
                        <Col auto className="text-end">
                            {commande.client.raisonSocial}
                        </Col>
                    </Row>
                    <Row>
                        <Col auto>Téléphone:</Col>
                        <Col auto className="text-end">
                            {commande.client?.telephoneBureau1}
                        </Col>
                    </Row>
                    <Row>
                        <Col auto>Banque:</Col>
                        <Col auto className="text-end">
                            {commande.client?.banque.libelleBanque}
                        </Col>
                    </Row>
                    <Row>
                        <Col auto>RIB</Col>
                        <Col auto className="text-end">
                            {commande.client?.rIB ?? '-'}
                        </Col>
                    </Row>
                </Col>
                <Col md="6" lg="6" sm="6" xs="6" className="border-start">
                    <Row>
                        <Col auto>Acheteur:</Col>
                        <Col auto className="text-end">
                            {commande.acheteur?.fullName!}
                        </Col>
                    </Row>
                    <Row>
                        <Col auto>Téléphone:</Col>
                        <Col auto className="text-end">
                            {commande.acheteur?.gsm}
                        </Col>
                    </Row>
                    <Row>
                        <Col auto>Email:</Col>
                        <Col auto className="text-end">
                            {commande.acheteur?.email ? commande.acheteur?.email : '-'}
                        </Col>
                    </Row>
                    <Row>
                        <Col auto>Ville/Région:</Col>
                        <Col auto className="text-end">
                            {commande.ville.nomVille!} / {commande.ville?.region?.nomRegion!}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr style={{ margin: 0 }} />
            <div className="p-2">
                <h6 className="text-dark">
                    Commande <b className="float-end">{commande.ref ?? '-'}</b>
                </h6>
                <Row>
                    <Col>
                        CMD-Ref: <b>{commande.ref ?? '-'}</b>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BarCode value={commande.code} options={{ height: 25, fontSize: 15 }} />
                    </Col>
                    <Col className="text-end">
                        <QrCode
                            value={`${getFournisseurAliasUrl(contextUser?.userData!)}/commandes?open=${utf8_to_b64(
                                commande?.idCommande?.toString()!,
                            )}`}
                            options={{ size: 50 }}
                        />
                    </Col>
                </Row>

                <h6 className="text-start">Articles:</h6>
                <Table striped responsive>
                    <thead>
                        <tr>
                            <th>Ref</th>
                            <th>Designation</th>
                            <th>Quantité</th>
                            <th>Prix de vente</th>
                        </tr>
                    </thead>
                    <tbody>
                        {commande?.coliscommandes?.map((d: ColisCommandeInterface, index: number) => (
                            <tr key={index}>
                                <td>
                                    {index === 0 &&
                                    commande?.coliscommandes?.length === 1 &&
                                    d.colisproduitenvoye.produit?.ref === 'DP-X0000X'
                                        ? '-'
                                        : d.colisproduitenvoye.produit?.ref}
                                </td>
                                <td>{d.colisproduitenvoye.produit?.designation}</td>
                                <td>{d.qteCommande}</td>
                                <td>{d.colisproduitenvoye.produit?.prixVente} DH</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <h6 className="text-start">Services:</h6>
                {commande?.commandetarifs?.map((d: CommandeTarifInterface) => (
                    <Row className=" g-3 align-items-center" key={d.tarif.idTarif}>
                        <Col auto>{d.tarif?.optionService?.nameOptionService} :</Col>
                        <Col auto className="text-end">
                            {d.tarif?.prixTarif} DH
                        </Col>
                    </Row>
                ))}
                <Row className="g-3 align-items-center">
                    <Col auto>Prix total des services:</Col>
                    <Col auto className="text-end">
                        {(commande?.commandetarifs ?? []).reduce((accumulator: number, object: any) => {
                            return accumulator + object.tarif?.prixTarif;
                        }, 0)}{' '}
                        DH
                    </Col>
                </Row>
                <Row className="g-3 align-items-center">
                    <Col auto>Prix total de commande</Col>
                    <Col auto className="text-end">
                        {commande?.prixTotal!} DH
                    </Col>
                </Row>
                {commande.facture?.remiseAccorde && commande.facture?.remiseAccorde > 0 ? (
                    <Row className="g-3 align-items-center">
                        <Col auto>
                            <strong>Remise:</strong>
                        </Col>
                        <Col auto className="text-end">
                            {commande.facture?.remiseAccorde} DH
                        </Col>
                    </Row>
                ) : (
                    <></>
                )}
                <Form.Group controlId="form_grp_prixTotal">
                    <Row className=" g-3 align-items-center">
                        <Col auto>
                            <Form.Label style={{ fontWeight: 700, fontSize: '1rem' }}>Montant facturé</Form.Label>
                        </Col>
                        <Col auto>
                            <InputGroup>
                                <Form.Control
                                    size="sm"
                                    className="text-end"
                                    type="text"
                                    name="prixTotal"
                                    value={commande.facture?.montantFacture}
                                    disabled
                                />
                                <InputGroup.Text>DH</InputGroup.Text>
                            </InputGroup>
                        </Col>
                    </Row>
                </Form.Group>
            </div>
            <hr style={{ margin: 0 }} />
            <Row className="p-2">
                <Col md="6" lg="6" sm="6" xs="6">
                    <Row>
                        <Col auto>
                            <b>
                                <h6 className="text-dark">{commande.client.fournisseur.raisonSocial}</h6>
                            </b>
                        </Col>
                    </Row>
                    <Row>
                        <Col auto>Téléphone:</Col>
                        <Col auto>{commande.client?.fournisseur.telephoneBureau1}</Col>
                    </Row>
                    <Row>
                        <Col auto>Banque:</Col>
                        <Col auto>{commande.client?.fournisseur.banque.libelleBanque}</Col>
                    </Row>
                    <Row>
                        <Col auto>RIB</Col>
                        <Col auto>{commande.client?.fournisseur.rIB ?? '-'}</Col>
                    </Row>
                </Col>
                <Col md="6" lg="6" sm="6" xs="6" className="border-start">
                    <Row>
                        <Col md="6" lg="6" sm="6" xs="6">
                            <h6 className="text-dark">Colis</h6>
                            <div>
                                Ref:
                                <div>
                                    <b>{commande.colis.refColis}</b>
                                </div>
                                Date de réception:
                                <div>{moment(commande.colis.dateRec).format('DD/MM/YYYY')}</div>
                            </div>
                        </Col>
                        <Col md="6" lg="6" sm="6" xs="6">
                            <BarCode value={addLeadingZeros(commande.colis.idColis, 8)} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Printer>
    );
};

export default PrintCommande;
