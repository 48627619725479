import React from 'react';
import dayjs from 'dayjs';

import Printer from 'components/shared/printer';
import SiteLogo from 'components/UI/siteLogo';
import DemandeRetourLivreurInterface from 'types/interfaces/demandeRetourLivreur.interface';
import DemandeRetourStockInterface from 'types/interfaces/demandeRetourStock.interface';
import GroupedTable from 'components/shared/groupedTable';
import { DisplayName } from 'utils/helpers';

interface Props<T> {
    demandes: T[];
    colSpan: number;
    componentBody: any;
    componentHeader: any;
    target?: string;
}

const PrintDemandeRetour: React.FC<Props<DemandeRetourLivreurInterface | DemandeRetourStockInterface>> = ({
    demandes,
    colSpan,
    componentBody: ComponentBody,
    componentHeader: ComponentHeader,
    target,
}: Props<DemandeRetourLivreurInterface | DemandeRetourStockInterface>) => {
    let targetUser = '';
    if (target !== '' && demandes.length > 0) {
        if (target == 'livreur') {
            const firstDemande = (demandes as DemandeRetourLivreurInterface[])[0];
            targetUser = `${DisplayName(firstDemande.livreur!)}`;
        } else if ((target = 'client')) {
            const firstDemande = (demandes as DemandeRetourStockInterface[])[0];
            targetUser = firstDemande.client?.raisonSocial!;
        }
    }
    return (
        <Printer documentTitle="Bordereau d'envoi">
            <>
                <div className="d-flex justify-content-between m-1">
                    <SiteLogo />
                    <div className="text-end">
                        <div> Le {dayjs().format('DD/MM/YYYY HH:mm')}</div>
                        <div>
                            <span className="my-xsm-font">
                                <b>{demandes.length}</b> demandes au total
                            </span>
                        </div>
                    </div>
                </div>
                <div className="m-2 d-flex justify-content-between">
                    <h6> Bordereau d&apos;envoi</h6>
                    <div>
                        <b>{targetUser}</b>
                    </div>
                </div>
                <hr style={{ margin: 0 }} />
                <GroupedTable
                    colSpan={colSpan}
                    data={demandes}
                    groupByKey="codeDemande"
                    componentBody={ComponentBody}
                    componentHeader={ComponentHeader}
                />
            </>
        </Printer>
    );
};

export default PrintDemandeRetour;
