import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import AgenceInterface from 'types/interfaces/agence.interface';

const apiEndPoint = `${apiUrl}/agences`;

export const getAgences = (): Promise<AgenceInterface[] | any> => {
    return http.get(apiEndPoint);
};

export const saveAgence = (agence: AgenceInterface) => {
    const body = {
        ...agence,
    };
    if (agence.idAgence) {
        return http.put(`${apiEndPoint}/${agence.idAgence}`, body);
    }
    return http.post(`${apiEndPoint}`, body);
};

export const getAgencesByIdFournisseur = (id: number): Promise<AgenceInterface[] | any> => {
    return http.get(`${apiEndPoint}/fournisseur/${id}`);
};
