import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import {
    DatatableWrapper,
    TableBody,
    TableColumnType,
    TableHeader,
    Filter,
    Pagination,
    PaginationOptions,
} from 'react-bs-datatable';
import { flattenObjInArray } from 'utils/helpers';

type PaginationOtionpType = {
    rowsPerPage: number;
    options: number[];
};
type Props = {
    data: any[];
    tableColumns: TableColumnType<any>[];
    paginationOtion?: PaginationOtionpType;
    children?: React.ReactNode | React.ReactElement | React.FC | any;
    rowClickCB?: (row: any) => void;
    initialSort?: any;
};

const Datatable: React.FC<Props> = ({
    data,
    tableColumns,
    paginationOtion,
    children,
    rowClickCB,
    initialSort,
}: Props) => {
    return (
        <DatatableWrapper
            body={flattenObjInArray(data)}
            headers={tableColumns}
            sortProps={initialSort}
            paginationOptionsProps={
                children
                    ? undefined
                    : {
                          initialState: paginationOtion,
                      }
            }
        >
            {children ? (
                children
            ) : (
                <Row className="mb-4 p-2 holdit_notEffect">
                    <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                        <Filter />
                    </Col>
                    <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                    >
                        <PaginationOptions labels={{ beforeSelect: '\u00A0' }} />
                    </Col>
                    <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                        <Pagination />
                    </Col>
                </Row>
            )}
            <Table responsive /*style={{ minHeight: '400px' }}*/>
                <TableHeader />
                <TableBody classes={{ tbody: 'topBorderColor-F_bluewhite' }} onRowClick={rowClickCB!} />
            </Table>
        </DatatableWrapper>
    );
};

Datatable.defaultProps = {
    data: [],
    tableColumns: [],
    paginationOtion: {
        rowsPerPage: 10,
        options: [5, 10, 15, 20],
    },
};

export default Datatable;
