import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { Button, Form, Spinner } from 'react-bootstrap';
import { TableColumnType } from 'react-bs-datatable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';

import OptionServiceInterface from 'types/interfaces/optionService.interface';
import ServiceInterface from 'types/interfaces/service.interface';
import { getOptionServices, saveOptionService } from 'services/optionService.service';
import { getServices } from 'services/service.service';

import CustomModal from 'components/UI/customModal';
import Datatable from 'components/shared/datatable';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import TitleElement from 'components/UI/titleElement';

const schema = yup.object({
    idOptionService: yup.number(),
    nameOptionService: yup.string().required(),
    description: yup.string().required(),
    idService: yup.number().required(),
});

const OptionServicePage: React.FC = () => {
    const [optionServices, setOptionServices] = useState<OptionServiceInterface[]>([]);
    const [services, setServices] = useState<ServiceInterface[]>([]);
    const [currentOptionService, setCurrentOptionService] = useState<OptionServiceInterface | null>(null);

    const [showFormModal, setShowFormModal] = useState<boolean>(false);
    const [formModalEditMode, setFormModalEditMode] = useState<boolean>(false);
    const [spinIt, setSpinIt] = useState<boolean>(false);

    const handleFormModalClose = () => {
        setCurrentOptionService(null);
        setShowFormModal(false);
    };

    const handleFormModalOpen = (editMode = false, optionServicesData?: OptionServiceInterface) => {
        if (optionServicesData) setCurrentOptionService(optionServicesData);
        setFormModalEditMode(editMode);
        setShowFormModal(true);
    };

    const tableHeaders: TableColumnType<any>[] = [
        {
            prop: 'nameOptionService',
            title: 'Nom',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'description',
            title: 'Description',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'service.nomService',
            title: 'Nom mService',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'null',
            title: 'Action',
            alignment: { horizontal: 'right' },
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: OptionServiceInterface) => (
                <Button
                    variant="outline-secondary"
                    className="button-input-group-effect"
                    id="basic-addon1"
                    onClick={() => handleFormModalOpen(true, row)}
                >
                    <FontAwesomeIcon icon={faEdit} size="xs" style={{ color: '#337ab7' }} />
                </Button>
            ),
            isFilterable: false,
            isSortable: false,
        },
    ];

    useEffect(() => {
        document.title = 'Paramétrage OptionService - DS';

        getOptionServices()
            .then((response: AxiosResponse<OptionServiceInterface | any>) => {
                const { data } = response;
                setOptionServices(data);
            })
            .catch();

        getServices()
            .then((response: AxiosResponse<ServiceInterface | any>) => {
                const { data } = response;
                setServices(data);
            })
            .catch();
    }, []);

    const submitForm = async (optionServiceValues: OptionServiceInterface | any) => {
        try {
            setSpinIt(true);
            const response = await saveOptionService(optionServiceValues);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('OptionService a été enregistré avec succès');
                const optionServicesData = [...optionServices].filter(
                    (dd) => dd.idOptionService !== resultData.idOptionService,
                );
                optionServicesData.unshift(resultData);
                setOptionServices(optionServicesData);
                handleFormModalClose();
                setSpinIt(false);
            }
        } catch (Exception) {
            setSpinIt(false);
        }
    };

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { text: 'Paramétrage' },
        { active: true, text: 'OptionService' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    OptionServices
                </TitleElement>
                <Button
                    variant="outline-secondary"
                    className="button-input-group-effect"
                    id="basic-addon1"
                    onClick={() => handleFormModalOpen()}
                >
                    <FontAwesomeIcon icon={faPlus} size="xs" style={{ color: '#337ab7' }} />
                </Button>
            </div>
            <Datatable data={optionServices} tableColumns={tableHeaders} />
            <CustomModal
                title={`${!!formModalEditMode ? 'Edit' : 'Nouveau'} OptionService`}
                size="lg"
                show={showFormModal}
                handleClose={handleFormModalClose}
            >
                <div>
                    <Formik
                        validationSchema={schema}
                        onSubmit={(values) => submitForm(values)}
                        enableReinitialize={true}
                        initialValues={{
                            idOptionService:
                                currentOptionService && !!formModalEditMode
                                    ? currentOptionService.idOptionService
                                    : undefined,
                            idService:
                                currentOptionService && !!formModalEditMode
                                    ? currentOptionService.idService
                                    : undefined,
                            nameOptionService:
                                currentOptionService && !!formModalEditMode
                                    ? currentOptionService.nameOptionService
                                    : '',
                            description:
                                currentOptionService && !!formModalEditMode ? currentOptionService.description : '',
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                {values.idOptionService && (
                                    <Form.Control
                                        type="hidden"
                                        name="idOptionService"
                                        value={values.idOptionService}
                                        readOnly={true}
                                    />
                                )}
                                <Form.Group controlId="form_grp_nom">
                                    <Form.Label>Nom OptionService</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="nameOptionService"
                                        placeholder="Nom OptionService"
                                        value={values.nameOptionService}
                                        onChange={handleChange}
                                        isInvalid={!!errors.nameOptionService}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nameOptionService}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="form_grp_nom">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="description"
                                        placeholder="Description"
                                        value={values.description}
                                        onChange={handleChange}
                                        isInvalid={!!errors.description}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="form_grp_service">
                                    <Form.Label>Services</Form.Label>
                                    <Form.Select
                                        name="idService"
                                        value={values.idService}
                                        onChange={handleChange}
                                        isInvalid={!!errors.idService}
                                        placeholder="Selectionner un service"
                                    >
                                        <option>Selectionner une service</option>
                                        {services.map((d: ServiceInterface) => (
                                            <option key={d.idService} value={d.idService}>
                                                {d.nomService}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <div className="d-grid gap-2 mt-3">
                                    <Button variant="primary" type="submit" disabled={!!spinIt}>
                                        Enregistrer
                                        {!!spinIt && (
                                            <>
                                                &nbsp; <Spinner animation="border" size="sm" />
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </CustomModal>
        </>
    );
};

export default OptionServicePage;
