import React, { useState, useEffect, useContext } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { TableColumnType } from 'react-bs-datatable';
import UserInterface from 'types/interfaces/user.interface';
import { Button, Spinner, Form, Alert, Fade, Row, Col } from 'react-bootstrap';

import { getRoles } from 'services/role.service';
import RoleInterface from 'types/interfaces/role.interface';
import { AxiosResponse } from 'axios';

import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';

export const ClientsTableData: TableColumnType<any>[] = [
    {
        prop: 'client.raisonSocial',
        title: 'Raison Social',
        cellProps: {
            className: 'foceVerticalMiddle',
        },
        isFilterable: true,
        isSortable: true,
    },
    {
        prop: 'region.nomRegion',
        title: 'Region',
        cellProps: {
            className: 'foceVerticalMiddle',
        },
        isFilterable: true,
        isSortable: true,
    },
];

const schema = yup.object({
    idUser: yup.number(),
    idClient: yup.number().required(),
    type: yup.string().required(),
    nom: yup.string().required(),
    prenom: yup.string().required(),
    gSM: yup.string(),
    cIN: yup.string(),
    email: yup.string().email().required(),
    pwd: yup
        .string()
        .min(3)
        .max(30)
        .when('idUser', {
            is: (idUser: number) => !!isNaN(idUser),
            then: yup.string().min(3).max(30).required(),
        }),
    etatCompte: yup.boolean(),
    roles: yup.array().of(yup.number().required()).min(1).required(),
});

type Props = {
    user: UserInterface;
    formEditMode: boolean;
    submitForm: (values: UserInterface | any) => void;
    spinIt: boolean;
    errorMessage: null | string;
};

const UserFormClientsCA: React.FC<Props> = ({ user, formEditMode, submitForm, spinIt, errorMessage }: Props) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);
    const [roles, setRoles] = useState<RoleInterface[]>([]);

    useEffect(() => {
        getRoles()
            .then((response: AxiosResponse<RoleInterface[] | any>) => {
                const { data } = response;
                const dataFiltred = data.filter((d: RoleInterface) => d.nomRole.startsWith('CLIENT', 0));
                setRoles(dataFiltred);
            })
            .catch();
    }, []);

    return (
        <>
            <Formik
                validationSchema={schema}
                onSubmit={(values) => submitForm(values)}
                enableReinitialize={true}
                initialValues={{
                    idUser: user && !!formEditMode ? user.idUser : undefined,
                    type: 'Client',
                    nom: user && !!formEditMode ? user.nom : '',
                    prenom: user && !!formEditMode ? user.prenom : '',
                    gSM: user && !!formEditMode ? user.gSM : '',
                    cIN: user && !!formEditMode ? user.cIN : '',
                    email: user && !!formEditMode ? user.email : '',
                    pwd: undefined,
                    etatCompte: user && !!formEditMode ? user.etatCompte : true,
                    idClient: user && !!formEditMode ? user.idClient : contextUser?.userData.idClient!,
                    roles: user && !!formEditMode ? user.userroles?.map((d: any) => d.idRole) : undefined,
                }}
            >
                {({ handleSubmit, handleChange, values, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        {values.idUser && (
                            <Form.Control type="hidden" name="idUser" value={values.idUser} readOnly={true} />
                        )}
                        <Fade timeout={500} in={!!errorMessage}>
                            {errorMessage ? <Alert variant="danger">{errorMessage}</Alert> : <></>}
                        </Fade>
                        <Row>
                            <Form.Group as={Col} md="6" controlId="form_grp_nom">
                                <Form.Label>Nom</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="nom"
                                    placeholder="Nom"
                                    value={values.nom}
                                    onChange={(e: any) =>
                                        handleChange({
                                            target: { value: e.target.value.toUpperCase(), name: 'nom' },
                                        })
                                    }
                                    isInvalid={!!errors.nom}
                                    size="sm"
                                />
                                <Form.Control.Feedback type="invalid">{errors.nom}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="form_grp_prenom">
                                <Form.Label>Prenom</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="prenom"
                                    placeholder="Prenom"
                                    value={values.prenom}
                                    onChange={handleChange}
                                    isInvalid={!!errors.prenom}
                                    size="sm"
                                />
                                <Form.Control.Feedback type="invalid">{errors.nom}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md="6" controlId="form_grp_email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={!!errors.email}
                                    size="sm"
                                />
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="form_grp_10">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="pwd"
                                    placeholder="Password"
                                    value={values.pwd}
                                    onChange={handleChange}
                                    isInvalid={!!errors.pwd}
                                />
                                <Form.Control.Feedback type="invalid">{errors.pwd}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md="6" controlId="form_grp_cin">
                                <Form.Label>CIN</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="cIN"
                                    placeholder="CIN"
                                    value={values.cIN}
                                    onChange={handleChange}
                                    isInvalid={!!errors.cIN}
                                    size="sm"
                                />
                                <Form.Control.Feedback type="invalid">{errors.cIN}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="form_grp_gsm">
                                <Form.Label>GSM</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="gSM"
                                    placeholder="GSM"
                                    value={values.gSM}
                                    onChange={handleChange}
                                    isInvalid={!!errors.gSM}
                                    size="sm"
                                />
                                <Form.Control.Feedback type="invalid">{errors.gSM}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md="6" controlId="form_grp_16">
                                <Form.Label>Roles</Form.Label>
                                <Form.Select
                                    name="roles"
                                    value={values.roles}
                                    onChange={handleChange}
                                    isInvalid={!!errors.roles}
                                    placeholder="Selectionner un role"
                                    multiple
                                >
                                    <option>Selectionner un role</option>
                                    {roles.map((d: RoleInterface) => (
                                        <option key={d.idRole} value={d.idRole}>
                                            {d.nomRole}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="form_grp_6">
                                <Form.Label>Etat de Compte</Form.Label>
                                <Form.Check
                                    type="checkbox"
                                    name="etatCompte"
                                    checked={values.etatCompte}
                                    onChange={handleChange}
                                    isInvalid={!!errors.etatCompte}
                                />
                            </Form.Group>
                        </Row>
                        <div className="d-grid gap-2 mt-3">
                            <Button variant="primary" type="submit" disabled={!!spinIt}>
                                Enregistrer
                                {!!spinIt && (
                                    <>
                                        &nbsp; <Spinner animation="border" size="sm" />
                                    </>
                                )}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};
export default UserFormClientsCA;
