import React, { useEffect, useState } from 'react';

import { AxiosResponse } from 'axios';
import { Alert, Button, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { TableColumnType } from 'react-bs-datatable';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { Formik, FormikHelpers } from 'formik';

import CustomModal from 'components/UI/customModal';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import TitleElement from 'components/UI/titleElement';
import Datatable from 'components/shared/datatable';
import PartnershipInterface from 'types/interfaces/partnership.interface';
import {
    getPartnerships,
    getKey,
    createPartnership,
    acceptInvitation,
    deletePartnership,
} from 'services/partnership.service';
import { toast } from 'react-toastify';

const PartnerShipPage: React.FC = () => {
    const [partnerships, setPartnerships] = useState<PartnershipInterface[]>([]);
    const [currentPartnerships, setCurrentPartnerships] = useState<PartnershipInterface | null>(null);
    const [showInvite, setShowInvite] = useState<boolean>(false);
    const [showCreate, setShowCreate] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState<boolean>(false);

    const openModal = (type: string, data?: PartnershipInterface) => {
        switch (type) {
            case 'invite':
                setShowInvite(true);
                break;
            case 'create':
                setShowCreate(true);
                break;
            case 'delete':
                setCurrentPartnerships(data!);
                setShowDelete(true);
                break;
        }
    };

    const closeModal = (type: string): any => {
        switch (type) {
            case 'invite':
                setShowInvite(false);
                break;
            case 'create':
                setShowCreate(false);
                break;
            case 'delete':
                setCurrentPartnerships(null);
                setShowDelete(false);
                break;
        }
    };

    useEffect(() => {
        getPartnerships()
            .then(({ data }: AxiosResponse<PartnershipInterface[]>) => setPartnerships(data))
            .catch();
    }, []);

    const tableHeaders: TableColumnType<any>[] = [
        {
            prop: 'key',
            title: 'Key',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: PartnershipInterface) => (
                <>
                    <i
                        className="my_spoiler-text"
                        style={{
                            fontSize: '9pt',
                            fontStyle: 'normal',
                        }}
                    >
                        {row.key}
                    </i>
                </>
            ),
        },
        {
            prop: 'fournisseurOwner.raisonSocial',
            title: 'Owner',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: PartnershipInterface) => <>{(row as any)['fournisseurOwner.raisonSocial']}</>,
        },
        {
            prop: 'dateCreation',
            title: 'Créee',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: PartnershipInterface) => <>{dayjs(row.dateCreation).format('DD/MM/YYYY HH:mm')}</>,
        },
        {
            prop: 'fournisseurLinkedTo.raisonSocial',
            title: '&',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: PartnershipInterface) => (
                <>
                    {(row as any)['fournisseurLinkedTo.raisonSocial']
                        ? (row as any)['fournisseurLinkedTo.raisonSocial']
                        : '-'}
                </>
            ),
        },
        {
            prop: 'dateAcceptation',
            title: 'Acceptée',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: PartnershipInterface) => (
                <>{row.dateAcceptation ? dayjs(row.dateAcceptation).format('DD/MM/YYYY HH:mm') : '-'}</>
            ),
        },
        {
            prop: 'null',
            title: 'Action',
            alignment: { horizontal: 'right' },
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: PartnershipInterface) =>
                !row.linkedTo && !row.dateAcceptation ? (
                    <Button
                        variant="outline-secondary"
                        className="button-input-group-effect"
                        id="basic-addon1"
                        onClick={() => openModal('delete', row)}
                    >
                        <FontAwesomeIcon icon={faTrash} size="xs" style={{ color: '#bb2d3b' }} />
                    </Button>
                ) : (
                    <></>
                ),
            isFilterable: false,
            isSortable: false,
        },
    ];

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { active: true, text: 'Partenaires' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Partenaires
                </TitleElement>
                <div className="d-flex gap-1">
                    <Button
                        variant="outline-secondary"
                        className="button-input-group-effect"
                        id="basic-addon1"
                        onClick={() => openModal('invite')}
                    >
                        <FontAwesomeIcon icon={faSearch} size="xs" style={{ color: '#337ab7' }} />
                    </Button>{' '}
                    <Button
                        variant="outline-secondary"
                        className="button-input-group-effect"
                        id="basic-addon1"
                        onClick={() => openModal('create')}
                    >
                        <FontAwesomeIcon icon={faAdd} size="xs" style={{ color: '#337ab7' }} />
                    </Button>
                </div>
            </div>
            <Datatable data={partnerships} tableColumns={tableHeaders} />
            <CustomModal title={`Création`} size="lg" show={showCreate} handleClose={() => closeModal('create')}>
                <CreationForm setPartnerShips={setPartnerships} close={() => closeModal('create')} />
            </CustomModal>
            <CustomModal title={`Invitation`} size="lg" show={showInvite} handleClose={() => closeModal('invite')}>
                <InvitationForm setPartnerShips={setPartnerships} close={() => closeModal('invite')} />
            </CustomModal>
            <CustomModal title={`Suppression`} size="lg" show={showDelete} handleClose={() => closeModal('delete')}>
                <DeletionForm
                    partnerShip={currentPartnerships!}
                    setPartnerShips={setPartnerships}
                    close={() => closeModal('delete')}
                />
            </CustomModal>
        </>
    );
};

export default PartnerShipPage;

export const schema = yup.object({
    key: yup.string().required(),
});

export const schemaDeleting = yup.object({
    id: yup.number().required(),
});
interface FormProps {
    setPartnerShips: React.Dispatch<React.SetStateAction<PartnershipInterface[]>>;
    close: () => void;
}

interface DeletionFormProps extends FormProps {
    partnerShip: PartnershipInterface;
}

const CreationForm: React.FC<FormProps> = ({ close, setPartnerShips }: FormProps) => {
    const generateKey = async () => {
        try {
            const result = await getKey();
            if (result.status === 200) {
                return result.data;
            }
            return null;
        } catch (Exception) {
            return null;
        }
    };
    const submitForm = (values: { key: string }, { setSubmitting }: FormikHelpers<any>) => {
        setSubmitting(true);
        createPartnership(values)
            .then((result) => {
                if (result.status === 200) {
                    const resultData = result.data;
                    setPartnerShips((partnerships) => [...partnerships, resultData]);
                    toast.success('Invitation a été créee avec succès');
                    close();
                }
            })
            .catch(() => {
                toast.error("Une erreur s'est produite");
            })
            .finally(() => {
                setSubmitting(false);
            });
    };
    return (
        <Formik
            validationSchema={schema}
            onSubmit={submitForm}
            enableReinitialize={true}
            initialValues={{
                key: '',
            }}
        >
            {({ handleSubmit, handleChange, values, errors, isSubmitting }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group controlId="keyCTRLID">
                        <Form.Label>Key</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                placeholder="Shared Key"
                                name="key"
                                value={values.key}
                                onChange={handleChange}
                                isInvalid={!!errors.key}
                                readOnly
                                style={{
                                    textAlign: 'center',
                                    color: 'rgb(18 169 179)',
                                    backgroundColor: 'rgb(241 253 227)',
                                    fontWeight: 'bold',
                                }}
                            />
                            <Button
                                variant="outline-info"
                                className="inputGrpButton"
                                onClick={async () => {
                                    const genKey = await generateKey();
                                    handleChange({
                                        target: {
                                            value: genKey,
                                            name: 'key',
                                        },
                                    });
                                }}
                            >
                                Generate
                            </Button>
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">{errors.key}</Form.Control.Feedback>
                    </Form.Group>
                    <div className="d-grid gap-2 mt-2">
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                            Submit
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
const InvitationForm: React.FC<FormProps> = ({ close, setPartnerShips }: FormProps) => {
    const submitForm = ({ key }: { key: string }, { setSubmitting }: FormikHelpers<any>) => {
        setSubmitting(true);
        acceptInvitation(key)
            .then((result) => {
                if (result.status === 200) {
                    const resultData = result.data;
                    setPartnerShips((partnerships) => [...partnerships, resultData]);
                    toast.success('Invitation a été acceptée avec succès');
                    close();
                }
            })
            .catch(() => {
                toast.error('Key not valid');
            })
            .finally(() => {
                setSubmitting(false);
            });
    };
    return (
        <Formik
            validationSchema={schema}
            onSubmit={submitForm}
            enableReinitialize={true}
            initialValues={{
                key: '',
            }}
        >
            {({ handleSubmit, handleChange, values, errors, isSubmitting }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group controlId="keyCTRLID">
                        <Form.Label>Key</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Shared Key"
                            name="key"
                            value={values.key}
                            onChange={handleChange}
                            isInvalid={!!errors.key}
                        />
                        <Form.Control.Feedback type="invalid">{errors.key}</Form.Control.Feedback>
                    </Form.Group>
                    <div className="d-grid gap-2 mt-2">
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                            Submit
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
const DeletionForm: React.FC<DeletionFormProps> = ({ close, setPartnerShips, partnerShip }: DeletionFormProps) => {
    const submitForm = ({ id }: { id: string | number }, { setSubmitting }: FormikHelpers<any>) => {
        setSubmitting(true);
        deletePartnership(+id)
            .then(() => {
                setPartnerShips((partnerships) =>
                    partnerships.filter((p) => id.toString() !== p.idPartnership.toString()),
                );
                toast.success('Invitation a été supprimée avec succès');
                close();
            })
            .catch(() => {
                toast.error('Key not valid');
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    if (!partnerShip) return <></>;

    return (
        <div>
            <Alert variant="warning">
                Voulez-vous vraiment supprimer cette invitation ? <b>{partnerShip.key}</b>
            </Alert>
            <Formik
                validationSchema={schemaDeleting}
                onSubmit={submitForm}
                enableReinitialize={true}
                initialValues={{
                    id: partnerShip.idPartnership,
                }}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <div className="d-grid gap-2 mt-2">
                            <Button variant="danger" type="submit" disabled={isSubmitting}>
                                Submit
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
