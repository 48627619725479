import React, { useContext, cloneElement } from 'react';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import { allowAccess } from 'utils/helpers';

type Props = {
    scopes?: string[];
    children: React.FC | any;
    RenderError?: React.FC | React.ReactNode | React.ReactElement | any;
    errorProps?: object | null;
    creatorId?: number;
    strict?: boolean;
};

const PermissionsGate: React.FC<Props> = ({
    children,
    RenderError = () => <></>,
    errorProps = null,
    scopes = [],
    creatorId,
    strict = false,
}: Props) => {
    const { user } = useContext<AppContextState>(AppContext);

    const permissionGranted = user && allowAccess(user?.ROLES, scopes!, strict);

    if ((!permissionGranted || (creatorId && creatorId != user.IdUser)) && !errorProps) return <RenderError />;

    if (!permissionGranted && errorProps) return cloneElement(children, { ...errorProps });

    return <>{children}</>;
};

export default PermissionsGate;
