import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import { userChangePassword } from 'types/index';
import UserInterface from 'types/interfaces/user.interface';

const apiEndPoint = `${apiUrl}/users`;

export const passwordUpdate = (data: userChangePassword): Promise<UserInterface | any> => {
    return http.put(`${apiEndPoint}/password/update`, data);
};

export const toggleDisableUser = (userId: number): Promise<UserInterface | any> => {
    return http.put(`${apiEndPoint}/toggle-disable/${userId}`);
};

export const saveUser = (user: UserInterface) => {
    const body = {
        ...user,
    };
    if (user.idUser) {
        return http.put(`${apiEndPoint}/${user.idUser}`, body);
    }
    return http.post(`${apiEndPoint}`, body);
};

export const getUsersFournisseurs_SA = (
    total: number,
    page: number,
    search?: string,
): Promise<{ count: number; items: UserInterface[] } | any> => {
    const query = `?page=${page}&total=${total}${search ? '&search=' + search : ''}`;
    return http.get(`${apiEndPoint}/fournisseurs_sa${query}`);
};

export const getUsersClients_SA = (
    total: number,
    page: number,
    search?: string,
): Promise<{ count: number; items: UserInterface[] } | any> => {
    const query = `?page=${page}&total=${total}${search ? '&search=' + search : ''}`;
    return http.get(`${apiEndPoint}/clients_sa${query}`);
};

export const getUsersFournisseurs_FA = (
    total: number,
    page: number,
    search?: string,
): Promise<{ count: number; items: UserInterface[] } | any> => {
    const query = `?page=${page}&total=${total}${search ? '&search=' + search : ''}`;
    return http.get(`${apiEndPoint}/fournisseurs_fa${query}`);
};

export const getUsersClients_FA = (
    total: number,
    page: number,
    search?: string,
): Promise<{ count: number; items: UserInterface[] } | any> => {
    const query = `?page=${page}&total=${total}${search ? '&search=' + search : ''}`;
    return http.get(`${apiEndPoint}/clients_fa${query}`);
};

export const getUsersClients_CA = (
    total: number,
    page: number,
    search?: string,
): Promise<{ count: number; items: UserInterface[] } | any> => {
    const query = `?page=${page}&total=${total}${search ? '&search=' + search : ''}`;
    return http.get(`${apiEndPoint}/clients_ca${query}`);
};

export const getLivreursByFournisseur = (id: number): Promise<UserInterface[] | any> =>
    http.get(`${apiEndPoint}/livreurs/${id}`);

export const getLivreursByAgence = (id: number): Promise<UserInterface[] | any> =>
    http.get(`${apiEndPoint}/livreurs/agence/${id}`);

export const getUserClientAdmin = (id: number): Promise<UserInterface | any> =>
    http.get(`${apiEndPoint}/admin/client/${id}`);

export const getActiveLivreurs = (): Promise<UserInterface[] | any> => http.get(`${apiEndPoint}/active-livreurs`);

export const getCommissionEmployeTypeLivreurs = (): Promise<UserInterface[] | any> =>
    http.get(`${apiEndPoint}/commission-employetype-livreurs`);
