import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Button, Row, Form, Fade, Alert, Spinner } from 'react-bootstrap';
import { TableColumnType } from 'react-bs-datatable';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { AxiosResponse } from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck, faPlus, faEdit, faCheckDouble, faBan } from '@fortawesome/free-solid-svg-icons';

import { useQueryMeMo, buildUrlQueryParams, isEmptyObject } from 'utils/helpers';
import { ROLES as RolesMap } from 'constantes/permission-maps';

import FournisseurInterface from 'types/interfaces/fournisseur.interface';
import { getVilles } from 'services/ville.service';
import { getRoles } from 'services/role.service';
import { getBanques } from 'services/banque.service';
import VilleInterface from 'types/interfaces/ville.interface';
import BanqueInterface from 'types/interfaces/banque.interface';
import RoleInterface from 'types/interfaces/role.interface';
import {
    getFournisseursPaginated,
    saveFreshOrExistedFournisseur,
    toggleDisableFournisseur,
} from 'services/fournisseur.service';

import CustomModal from 'components/UI/customModal';
import { FormCheckField, FormField } from 'components/shared/formInputs';
import Stepper from 'components/UI/stepper';
import Datatable from 'components/shared/datatable';
import SearchInput from 'components/shared/searchInput';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import TitleElement from 'components/UI/titleElement';
import { WEB_URL } from 'constantes/envLoader';

const schema = [
    yup.object().shape({
        fournisseur: yup.object().shape({
            idFournisseur: yup.number(),
            raisonSocial: yup.string().required(),
            addresse: yup.string().required(),
            telephoneBureau1: yup.string().required(),
            telephoneBureau2: yup.string(),
            siteWeb: yup.string(),
            pageFacebook: yup.string(),
            pageInstagrame: yup.string(),
            etatCompte: yup.boolean(),
            urlAlias: yup
                .string()
                .matches(/^(https?:\/\/)([a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6})$/, 'Invalid URL format')
                .nullable(),
            rIB: yup.string(),
            idVille: yup.number().required(),
            idBanque: yup.number().required(),
        }),
    }),
    yup.object({
        agence: yup.object().shape({
            libelle: yup.string().required(),
            idVille: yup.number().required(),
        }),
    }),
    yup.object({
        user: yup.object().shape({
            nom: yup.string().required(),
            prenom: yup.string().required(),
            gSM: yup.string(),
            cIN: yup.string(),
            email: yup.string().email().required(),
            pwd: yup
                .string()
                .min(3)
                .max(30)
                .when('idUser', {
                    is: (idUser: number) => !!isNaN(idUser),
                    then: yup.string().min(3).max(30).required(),
                }),
        }),
    }),
];

const TOTAL_DATA_PER_PAGE = 100;

const FournisseurIndexPage: React.FC = () => {
    const [fournisseurs, setFournisseurs] = useState<{ items: FournisseurInterface[]; count: number }>({
        items: [],
        count: 0,
    });
    const [currentFournisseur, setCurrentFournisseur] = useState<FournisseurInterface | null>(null);
    const [villes, setVilles] = useState<VilleInterface[]>([]);
    const [banques, setBanques] = useState<BanqueInterface[]>([]);

    const [showFormModal, setShowFormModal] = useState<boolean>(false);
    const [formModalEditMode, setFormModalEditMode] = useState<boolean>(false);
    const [spinIt, setSpinIt] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [rolesMap, setRolesMap] = useState<object>({});

    const [inputValue, setInputValue] = useState('');
    const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null);

    const [activePage, setActivePage] = useState<number>(1);

    const history = useNavigate();
    const query = useQueryMeMo();

    const [step, setStep] = useState(0);
    const [confirmed, setConfirmed] = useState(false);
    const [formValues, setFormValues] = useState({});

    const maxStep = !!formModalEditMode ? 0 : 2;

    const handleFormModalClose = () => {
        setCurrentFournisseur(null);
        setShowFormModal(false);
        resetStepper();
    };

    const handleFormModalOpen = (editMode = false, fournisseurData?: FournisseurInterface) => {
        setConfirmed(false);
        setErrorMessage(null);
        if (fournisseurData) setCurrentFournisseur(fournisseurData);
        setFormModalEditMode(editMode);
        setShowFormModal(true);
    };

    const inputChanged = (e: any) => {
        setInputValue(e.target.value);

        clearTimeout(timer!);

        const newTimer = setTimeout(() => {
            handlePageNFilterChange(1, e.target.value);
        }, 500);

        setTimer(newTimer);
    };

    const handlePageNFilterChange = (activePage: number, search?: string) => {
        const urlObject: any = {};
        if (activePage > 1) urlObject['page'] = activePage;
        if (search && search.trim() != '') urlObject['search'] = search;
        history(isEmptyObject(urlObject) ? window.location.pathname : `?${buildUrlQueryParams(urlObject)}`);
    };

    const tableHeaders: TableColumnType<any>[] = [
        {
            prop: 'raisonSocial',
            title: 'Raison Social',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'urlAlias',
            title: 'URL',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: FournisseurInterface) => (row.urlAlias ? row.urlAlias : '-'),
        },
        {
            prop: 'etatCompte',
            title: 'Etat de Compte',
            cellProps: {
                className: 'foceVerticalMiddle text-center',
            },
            thProps: {
                className: 'text-center',
            },
            cell: (row: FournisseurInterface) => (
                <>
                    {!!row.etatCompte ? (
                        <FontAwesomeIcon icon={faCheck} size="xs" style={{ color: '#337ac8' }} />
                    ) : (
                        <FontAwesomeIcon icon={faXmark} size="xs" style={{ color: '#ff832c' }} />
                    )}
                </>
            ),
        },
        {
            prop: 'null',
            title: 'Actions',
            alignment: { horizontal: 'right' },
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: FournisseurInterface) => (
                <>
                    <Button
                        variant="outline-secondary"
                        className="button-input-group-effect gap-5marginpx"
                        id="basic-addon1"
                        onClick={() => handleFormModalOpen(true, row)}
                    >
                        <FontAwesomeIcon icon={faEdit} size="xs" style={{ color: '#337ab7' }} />
                    </Button>
                    {!!row.etatCompte ? (
                        <Button
                            variant="outline-secondary"
                            className="button-input-group-effect gap-5marginpx rounded-circle"
                            id="basic-addon2"
                            onClick={() => toggleStatus(row)}
                        >
                            <FontAwesomeIcon icon={faBan} size="xs" style={{ color: '#ff833b' }} />
                        </Button>
                    ) : (
                        <Button
                            variant="outline-secondary"
                            className="button-input-group-effect gap-5marginpx rounded-circle"
                            id="basic-addon2"
                            onClick={() => toggleStatus(row)}
                        >
                            <FontAwesomeIcon icon={faCheckDouble} size="xs" style={{ color: '#33b76e' }} />
                        </Button>
                    )}
                </>
            ),
        },
    ];

    const formData = [
        <div key={0}>
            {!formModalEditMode && <h4 className="text-center">Informations de fournisseur</h4>}
            <Row>
                <FormField
                    rowGroup
                    label="Raison Social"
                    name="fournisseur.raisonSocial"
                    type="text"
                    size="sm"
                    upperCase={true}
                />
                <FormField
                    rowGroup
                    as="textarea"
                    rows={2}
                    label="Addresse"
                    name="fournisseur.addresse"
                    type="text"
                    size="sm"
                />
            </Row>
            <Row>
                <FormField
                    rowGroup
                    label="Telephone Bureau1"
                    name="fournisseur.telephoneBureau1"
                    type="text"
                    size="sm"
                />
                <FormField
                    rowGroup
                    label="Telephone Bureau2"
                    name="fournisseur.telephoneBureau2"
                    type="text"
                    size="sm"
                />
            </Row>
            <Row>
                <FormField rowGroup label="SiteWeb" name="fournisseur.siteWeb" type="text" size="sm" />
                <FormField rowGroup label="Page Facebook" name="fournisseur.pageFacebook" type="text" size="sm" />
            </Row>
            <Row>
                <FormField rowGroup label="Page Instagrame" name="fournisseur.pageInstagrame" type="text" size="sm" />
                <FormField rowGroup label="RIB" name="fournisseur.rIB" type="text" size="sm" />
            </Row>
            <Row>
                <FormField
                    rowGroup
                    label="Ville"
                    name="fournisseur.idVille"
                    as="select"
                    size="sm"
                    className="form-select form-select-sm"
                >
                    <>
                        <option>Selectionner une ville</option>
                        {villes.map((d: VilleInterface) => (
                            <option key={d.idVille} value={d.idVille}>
                                {d.nomVille}
                            </option>
                        ))}
                    </>
                </FormField>
                <FormField
                    rowGroup
                    label="Banque"
                    name="fournisseur.idBanque"
                    as="select"
                    size="sm"
                    className="form-select form-select-sm"
                >
                    <>
                        <option>Selectionner une banque</option>
                        {banques.map((d: BanqueInterface) => (
                            <option key={d.idBanque} value={d.idBanque}>
                                {d.libelleBanque}
                            </option>
                        ))}
                    </>
                </FormField>
            </Row>
            <Row>
                <FormField rowGroup label="URL" name="fournisseur.urlAlias" type="text" size="sm" />
                <FormCheckField rowGroup label="ETAT de COMPTE" name="fournisseur.etatCompte" />
            </Row>
            <Alert variant="warning" className="mt-2">
                <p>
                    <strong>PS</strong>: Utilisez l&apos;<strong>URL</strong> avec soin, format valide :{' '}
                    <b>http(s)://exemple.com ou</b> <b>http(s)://sub.exemple.com</b>
                </p>
                <p className="text-secondary">
                    <b>
                        Merci de configurer un enregistrement d&apos;alias DNS vers :&nbsp;
                        {WEB_URL.replace(/^https?:\/\//, '')} dans votre gestion de domaine.
                    </b>
                </p>
            </Alert>
        </div>,
        <div key={1}>
            {!formModalEditMode && <h4 className="text-center">Agence Principale</h4>}
            <FormField label="Libelle" name="agence.libelle" type="text" size="sm" />
            <FormField label="Ville" name="agence.idVille" as="select" size="sm" className="form-select form-select-sm">
                <>
                    <option>Selectionner une ville</option>
                    {villes.map((d: VilleInterface) => (
                        <option key={d.idVille} value={d.idVille}>
                            {d.nomVille}
                        </option>
                    ))}
                </>
            </FormField>
        </div>,
        <div key={2}>
            {!formModalEditMode && <h4 className="text-center">Fournisseur utilisateur Admin</h4>}
            <Row>
                <FormField rowGroup label="Nom" name="user.nom" type="text" size="sm" upperCase={true} />
                <FormField rowGroup label="Prenom" name="user.prenom" type="text" size="sm" />
            </Row>
            <Row>
                <FormField rowGroup label="GSM" name="user.gSM" type="text" size="sm" />
                <FormField rowGroup label="CIN" name="user.cIN" type="text" size="sm" />
            </Row>
            <Row>
                <FormField rowGroup label="Email" name="user.email" type="email" size="sm" />
                <FormField rowGroup label="Password" name="user.pwd" type="password" size="sm" />
            </Row>
        </div>,
    ];

    const loadFournisseurs = async (totalPage: number, page: number, search?: string) => {
        try {
            const {
                status,
                data: { items: data },
                data: { count },
            } = await getFournisseursPaginated(TOTAL_DATA_PER_PAGE, page, search);
            if (status === 200) {
                setFournisseurs({ items: data, count });
            }
        } catch (Exception) {}
    };

    useEffect(() => {
        document.title = 'Fournisseurs - DS';

        getBanques()
            .then((response: AxiosResponse<BanqueInterface[] | any>) => {
                const { data } = response;
                setBanques(data);
            })
            .catch();

        getVilles()
            .then((response: AxiosResponse<VilleInterface[] | any>) => {
                const { data } = response;
                setVilles(data);
            })
            .catch();

        getRoles()
            .then((response: AxiosResponse<RoleInterface[] | any>) => {
                const { data } = response;
                const rolesObject = {};
                data.forEach((element: RoleInterface) => {
                    (rolesObject as any)[element.nomRole] = element.idRole;
                });
                setRolesMap(rolesObject);
            })
            .catch();
    }, []);

    useEffect(() => {
        const page = query.get('page');
        const search = query.get('search');
        let currentPage = 1;
        let instantSearch = '';
        if (page) currentPage = +page;
        if (search) instantSearch = decodeURIComponent(search);
        setActivePage(currentPage);
        setInputValue(instantSearch);
        const loadData = async () => {
            await loadFournisseurs(TOTAL_DATA_PER_PAGE, currentPage, instantSearch);
        };
        loadData();
    }, [query]);

    const toggleStatus = async (fournisseurData: FournisseurInterface) => {
        try {
            const { data: fournisseurInfo } = await toggleDisableFournisseur(fournisseurData.idFournisseur);
            const fournisseursList = [...fournisseurs.items];
            const index = fournisseursList.findIndex((d) => d.idFournisseur == fournisseurInfo.idFournisseur);
            fournisseursList[index] = fournisseurInfo;
            setFournisseurs({ items: fournisseursList, count: fournisseurs.count });
        } catch (Exception) {}
    };

    const resetStepper = () => {
        setStep(0);
        setConfirmed(false);
    };

    const submitForm = async (fournisseurValues: FournisseurInterface | any) => {
        let submitedData = {};
        if (!!formModalEditMode) {
            submitedData = fournisseurValues.fournisseur;
        } else {
            submitedData = { ...formValues, ...fournisseurValues };
        }
        setFormValues(submitedData);
        if (step < maxStep) {
            setStep(step + 1);
            return;
        }
        try {
            setErrorMessage(null);
            setSpinIt(true);
            const response = await saveFreshOrExistedFournisseur(submitedData, formModalEditMode);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Fournisseur a été enregistré avec succès');
                const fournisseurDATA = [...fournisseurs.items].filter(
                    (dd) => dd.idFournisseur !== resultData.idFournisseur,
                );
                fournisseurDATA.unshift(resultData);
                setFournisseurs({ items: fournisseurDATA, count: fournisseurs.count });
                handleFormModalClose();
                setSpinIt(false);
                setConfirmed(true);
                // console.log('submit', submitedData);
            }
        } catch (Exception) {
            setSpinIt(false);
            const { message = undefined }: any = Exception;
            setErrorMessage(message || 'Error occured!');
        }
    };

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { active: true, text: 'Fournisseurs' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Fournisseurs
                </TitleElement>
                <Button
                    variant="outline-secondary"
                    className="button-input-group-effect"
                    id="basic-addon1"
                    onClick={() => handleFormModalOpen()}
                >
                    <FontAwesomeIcon icon={faPlus} size="xs" style={{ color: '#337ab7' }} />
                </Button>
            </div>
            <Datatable data={fournisseurs.items} tableColumns={tableHeaders}>
                <SearchInput
                    inputValue={inputValue}
                    inputChanged={inputChanged}
                    activePage={activePage}
                    totalPages={Math.ceil(fournisseurs.count / TOTAL_DATA_PER_PAGE)}
                    handlePageNFilterChange={handlePageNFilterChange}
                />
            </Datatable>
            <CustomModal
                title={`${!!formModalEditMode ? 'Edit' : 'Nouveau'} Fournisseur`}
                size="lg"
                show={showFormModal}
                handleClose={handleFormModalClose}
            >
                <Formik
                    validationSchema={schema[step]}
                    onSubmit={(values) => submitForm(values)}
                    enableReinitialize={true}
                    initialValues={{
                        fournisseur: {
                            idFournisseur:
                                currentFournisseur && !!formModalEditMode
                                    ? currentFournisseur.idFournisseur
                                    : undefined,
                            raisonSocial:
                                currentFournisseur && !!formModalEditMode ? currentFournisseur.raisonSocial : '',
                            addresse: currentFournisseur && !!formModalEditMode ? currentFournisseur.addresse : '',
                            telephoneBureau1:
                                currentFournisseur && !!formModalEditMode ? currentFournisseur.telephoneBureau1 : '',
                            telephoneBureau2:
                                currentFournisseur && !!formModalEditMode ? currentFournisseur.telephoneBureau2 : '',
                            rIB: currentFournisseur && !!formModalEditMode ? currentFournisseur.rIB : '',
                            siteWeb: currentFournisseur && !!formModalEditMode ? currentFournisseur.siteWeb : '',
                            pageFacebook:
                                currentFournisseur && !!formModalEditMode ? currentFournisseur.pageFacebook : '',
                            pageInstagrame:
                                currentFournisseur && !!formModalEditMode ? currentFournisseur.pageInstagrame : '',
                            etatCompte:
                                currentFournisseur && !!formModalEditMode ? currentFournisseur.etatCompte : true,
                            idVille: currentFournisseur && !!formModalEditMode ? currentFournisseur.idVille : undefined,
                            idBanque:
                                currentFournisseur && !!formModalEditMode ? currentFournisseur.idBanque : undefined,
                            urlAlias: currentFournisseur && !!formModalEditMode ? currentFournisseur.urlAlias : '',
                        },
                        agence: { libelle: '', idVille: '' },
                        user: {
                            nom: '',
                            prenom: '',
                            gSM: '',
                            cIN: '',
                            email: '',
                            pwd: '',
                            type: 'Fournisseur',
                            etatCompte: true,
                            roles: [(rolesMap as any)[RolesMap.FOURNISSEUR_ADMIN]],
                        },
                    }}
                >
                    {(props: any) => (
                        <Form noValidate onSubmit={props.handleSubmit}>
                            <Stepper step={step} stepsCount={maxStep + 1} done={confirmed} />
                            <Fade timeout={500} in={!!errorMessage}>
                                {errorMessage ? <Alert variant="danger">{errorMessage}</Alert> : <></>}
                            </Fade>
                            {formData[step]}
                            <div className="d-flex justify-content-evenly">
                                {!confirmed && (
                                    <>
                                        {step > 0 && (
                                            <Button
                                                className="mt-3"
                                                variant="outline-dark"
                                                onClick={() => setStep(step - 1)}
                                                disabled={!!spinIt}
                                            >
                                                Prev
                                                {!!spinIt && (
                                                    <>
                                                        &nbsp; <Spinner animation="border" size="sm" />
                                                    </>
                                                )}
                                            </Button>
                                        )}
                                        <Button
                                            className="mt-3"
                                            variant={step < maxStep ? 'outline-primary' : 'outline-success'}
                                            type="submit"
                                            disabled={!!spinIt}
                                        >
                                            {step < maxStep ? 'Next' : 'Confirm'}
                                            {!!spinIt && (
                                                <>
                                                    &nbsp; <Spinner animation="border" size="sm" />
                                                </>
                                            )}
                                        </Button>
                                    </>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </CustomModal>
        </>
    );
};

export default FournisseurIndexPage;
