import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';

import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import { DisplayName, isOnlyLivreur } from 'utils/helpers';

import FactureLivreurInterface, {
    FactureLivreurCommandeInterface,
    FactureLivreurPaiementInterface,
} from 'types/interfaces/factureLivreur.interface';

import SiteLogo from 'components/UI/siteLogo';
import Printer from 'components/shared/printer';
import CommandeTarifsCommponent, {
    calculatePrix,
    getStatusTranslation,
} from 'pages/FactureLivreur/components/CommandeTarifs';

type Props = {
    current: FactureLivreurInterface;
    livreurVersion?: boolean;
};
const FacturePrint: React.FC<Props> = ({ current, livreurVersion = false }: Props) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    return (
        <Printer documentTitle="Facture">
            <>
                <div className="d-flex justify-content-between m-1">
                    <div>
                        <SiteLogo />
                        <br />
                        <strong>{current.ref}</strong> (<i>Total {current.status === 'Done' ? 'Payé' : 'Non payé'}</i>)
                    </div>
                    <div className="text-end">
                        <div>
                            {current.factureLivreurCommandes?.length! > 0 && (
                                <>
                                    <br />
                                    Livreur:{' '}
                                    <strong>
                                        {DisplayName((current.factureLivreurCommandes as any)[0].commande?.livreur!)}
                                    </strong>
                                </>
                            )}
                            <br />
                            Créée le <b>{dayjs(current.factureDate).format('DD/MM/YYYY HH:mm')}</b>
                        </div>
                        <div className="my-xsm-font">
                            Mis à jour le <b>{dayjs(current.lastActionDate).format('DD/MM/YYYY HH:mm')}</b>
                        </div>
                    </div>
                </div>
                <div className="m-2">
                    <h6> Facture détails</h6>
                </div>
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>Commande</th>
                            <th>Prix de commande</th>
                            <th>Ville</th>
                            <th>Commission</th>
                            {contextUser && !isOnlyLivreur(contextUser?.userData) && !livreurVersion && (
                                <th>Services</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {current.factureLivreurCommandes?.map((flc: FactureLivreurCommandeInterface, index: number) => (
                            <tr key={index}>
                                <td>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip className="refNcode_tooltip">
                                                Code: {flc.commande?.code}
                                                <br />
                                                Ref: {flc.commande?.ref ? flc.commande?.ref : '-'}
                                            </Tooltip>
                                        }
                                    >
                                        <Button type="button" variant="secondary-outline">
                                            <b style={{ fontSize: '0.7rem' }}>
                                                {flc.commande?.code.replace('CMD', '')}
                                            </b>
                                            {flc.commande?.ref && (
                                                <>
                                                    <br />
                                                    <b style={{ fontSize: '0.6rem' }}>{flc.commande?.ref}</b>
                                                </>
                                            )}
                                        </Button>
                                    </OverlayTrigger>
                                </td>
                                <td>{flc.commande?.prixTotal!} DH</td>
                                <td>{flc.commande?.ville?.nomVille}</td>
                                <td>{flc.commisionLivreur} DH</td>
                                {contextUser && !isOnlyLivreur(contextUser?.userData) && !livreurVersion && (
                                    <td>
                                        <CommandeTarifsCommponent commande={flc.commande!} /> DH
                                    </td>
                                )}
                            </tr>
                        ))}
                        <tr>
                            <td
                                colSpan={
                                    contextUser && !isOnlyLivreur(contextUser?.userData) && !livreurVersion ? 5 : 4
                                }
                                style={{ textAlign: 'right' }}
                            >
                                <strong>
                                    <div>Total Commande: {current.montantTotal} DH</div>
                                    <div>Total payé: {current.montantPaid} DH</div>
                                    <div>Total rest: {current.montantRest} DH</div>
                                    {contextUser && !isOnlyLivreur(contextUser?.userData) && !livreurVersion && (
                                        <div>
                                            Total Services:{' '}
                                            {current.factureLivreurCommandes?.reduce(
                                                (accumulator: number, item: any) => {
                                                    return (
                                                        accumulator +
                                                        calculatePrix(item.commande, contextUser?.userData)
                                                    );
                                                },
                                                0,
                                            )}{' '}
                                            DH
                                        </div>
                                    )}
                                    <div>
                                        {(current.factureLivreurCommandes as any)[0].commande?.livreur?.employe! ===
                                            'Commission' && (
                                            <i>
                                                Commission:{' '}
                                                {current.factureLivreurCommandes?.reduce(
                                                    (accumulator: number, item: any) => {
                                                        return accumulator + item.commisionLivreur;
                                                    },
                                                    0,
                                                )}{' '}
                                                DH
                                            </i>
                                        )}
                                    </div>
                                </strong>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <div className="m-2">
                    <h6> Paiement historique</h6>
                </div>
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Montant</th>
                            <th>Etat</th>
                        </tr>
                    </thead>
                    <tbody>
                        {current.factureLivreurPaiements?.length === 0 && (
                            <tr>
                                <td colSpan={4} className="text-center">
                                    Aucun paiement trouvé
                                </td>
                            </tr>
                        )}
                        {current.factureLivreurPaiements?.map((flp: FactureLivreurPaiementInterface, index: number) => (
                            <tr key={index}>
                                <td> {dayjs(flp.paimentDate).format('DD/MM/YYYY HH:mm:ss')}</td>
                                <td> {flp.montant} DH</td>
                                <td>{getStatusTranslation(flp.status)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </>
        </Printer>
    );
};

export default FacturePrint;
