import React, { useEffect, useState, useMemo, useContext, useCallback } from 'react';
import { OverlayTrigger, Tooltip, Button, Row, Form, Col } from 'react-bootstrap';
import moment from 'moment';
import { AxiosResponse } from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckDouble, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';

import TitleElement from 'components/UI/titleElement';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import TableTemplate from 'components/shared/datatable/tableTemplate';
import CustomModal from 'components/UI/customModal';
import FactureCreationForm from 'pages/Transfert/factures/components/FactureCreationForm';

import FournisseurInterface from 'types/interfaces/fournisseur.interface';
import TransfertCommandeInterface from 'types/interfaces/transfertCommande.interface';
import { getPartnersFournisseurs } from 'services/fournisseur.service';
import { getTransfertCommandeToFacture } from 'services/transfert.service';

import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';

const TransferttoFacture: React.FC = () => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    const [selectedFournisseurTransfert, setSelectedFournisseurTransfert] = useState<string>('');
    const [transferts, setTransferts] = useState<TransfertCommandeInterface[]>([]);
    const [fournisseurs, setFournisseurs] = useState<FournisseurInterface[]>([]);

    const [showMassModal, setShowMassModal] = useState<boolean>(false);
    const [checkedTransferts, setCheckedTransferts] = useState<number[]>([]);

    const handleMassModalClose = () => {
        setShowMassModal(false);
        setCheckedTransferts([]);
    };

    const handleMassModalOpen = () => {
        setShowMassModal(true);
    };

    const onCheckAllRows = useCallback(() => {
        setCheckedTransferts((oldState) => {
            if (oldState.length === transferts.length) {
                return [];
            }

            return transferts.map((el: TransfertCommandeInterface) => el.idTransfertCommande);
        });
    }, [transferts]);

    const onCheckRow = useCallback(
        (row: TransfertCommandeInterface) => {
            setCheckedTransferts((oldState) => {
                const idx = oldState.findIndex((el: number) => el === row.idTransfertCommande);

                if (idx === -1) {
                    return oldState.concat(row.idTransfertCommande);
                }

                const newState = [...oldState];
                newState.splice(idx, 1);

                return newState;
            });
        },
        [transferts],
    );

    useEffect(() => {
        if (contextUser) {
            getPartnersFournisseurs()
                .then((response: AxiosResponse<FournisseurInterface[] | any>) => {
                    const { data } = response;
                    setFournisseurs(
                        data.filter(
                            (item: FournisseurInterface) => item.idFournisseur !== contextUser.userData?.idFournisseur!,
                        ),
                    );
                })
                .catch();
        }
    }, [contextUser]);

    useEffect(() => {
        if (selectedFournisseurTransfert) {
            setCheckedTransferts([]);
            getTransfertCommandeToFacture(+selectedFournisseurTransfert)
                .then((response: AxiosResponse<TransfertCommandeInterface[] | any>) => {
                    const { data } = response;
                    setTransferts(data);
                })
                .catch();
        }
    }, [selectedFournisseurTransfert]);

    const tableHeaders = useMemo(
        () => [
            {
                Header: 'Ref/Code',
                accessor: 'commande.code',
                Cell: ({ row: { original } }: { row: { original: TransfertCommandeInterface } }) => (
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip className="refNcode_tooltip">
                                Code: {original.commande?.code}
                                <br />
                                Ref: {original.commande?.ref ? original.commande?.ref : '-'}
                            </Tooltip>
                        }
                    >
                        <Button type="button" variant="secondary-outline">
                            <b style={{ fontSize: '0.7rem' }}>{original.commande?.code.replace('CMD', '')}</b>
                            {original.commande?.ref && (
                                <>
                                    <br />
                                    <b style={{ fontSize: '0.6rem' }}>{original.commande?.ref}</b>
                                </>
                            )}
                        </Button>
                    </OverlayTrigger>
                ),
            },
            {
                Header: 'Source',
                accessor: 'fournisseurSource.raisonSocial',
            },
            {
                Header: 'Destinataire',
                accessor: 'fournisseurDestinataire.raisonSocial',
            },
            {
                Header: 'Tarif',
                accessor: 'prixTarif',
                Cell: ({ row }: any) => <>{row.original.prixTarif} DH</>,
            },
            {
                Header: 'Ville',
                accessor: 'commande.ville.nomVille',
            },
            {
                Header: 'Date',
                accessor: 'dateCreation',
                Cell: ({ row: { original } }: { row: { original: TransfertCommandeInterface } }) => (
                    <>{moment(original.dateCreation).format('DD/MM/YYYY HH:mm:ss')}</>
                ),
            },
            {
                Header: () => (
                    <Button variant="outline-dark" size="sm" onClick={() => onCheckAllRows()}>
                        <FontAwesomeIcon
                            icon={
                                checkedTransferts.length === transferts.length && checkedTransferts.length !== 0
                                    ? faCheck
                                    : faCheckDouble
                            }
                            size="xs"
                        />
                    </Button>
                ),
                accessor: 'checkbox2',
                Cell: ({ row: { original } }: { row: { original: TransfertCommandeInterface } }) => (
                    <input
                        type="checkbox"
                        checked={checkedTransferts.includes(original.idTransfertCommande)}
                        onChange={() => onCheckRow(original)}
                    />
                ),
                disableSortBy: true,
                disableFilters: true,
            },
        ],
        [transferts, onCheckAllRows, onCheckRow, checkedTransferts],
    );

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        {
            active: true,
            text: 'Créer facture partenaire',
        },
    ];

    useEffect(() => {
        document.title = 'Créer facture partenaire - DS';
    }, []);

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Créer facture partenaire
                </TitleElement>
            </div>
            <Row>
                <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_SFrs">
                    <Form.Label>Fournisseur:</Form.Label>
                    <Form.Select
                        size="sm"
                        name="selectedFournisseurTransfert"
                        value={selectedFournisseurTransfert}
                        onChange={(e) => {
                            const {
                                target: { value },
                            } = e;
                            setSelectedFournisseurTransfert(value);
                        }}
                        placeholder="Selectionner un fournisseur"
                    >
                        <option value="">Tous</option>
                        {fournisseurs.map((dr: FournisseurInterface) => (
                            <option key={dr.idFournisseur} value={dr.idFournisseur}>
                                {dr.raisonSocial}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
                {checkedTransferts.length > 0 && (
                    <Col className="align-self-center d-flex justify-content-end">
                        <Button
                            variant="outline-secondary"
                            className="button-input-group-effect gap-5marginpx"
                            id="basic-addon1"
                            onClick={() => handleMassModalOpen()}
                        >
                            <FontAwesomeIcon icon={faFileInvoiceDollar} size="xs" style={{ color: '#337ab7' }} />
                        </Button>
                    </Col>
                )}
            </Row>
            <TableTemplate
                columns={tableHeaders}
                data={transferts}
                options={{
                    pagination: false,
                    showGlobalFilter: true,
                    debugData: false,
                    showFiltersRowByColumn: true,
                    rowSelecting: false,
                    hiddenColumns: ['userId'],
                }}
            />
            <CustomModal title="Facturation" size="lg" show={showMassModal} handleClose={handleMassModalClose}>
                <>
                    <FactureCreationForm
                        transferts={transferts.filter((d) => checkedTransferts.includes(d.idTransfertCommande))}
                        checkedTransferts={checkedTransferts}
                        close={handleMassModalClose}
                        setTransferts={setTransferts}
                    />
                </>
            </CustomModal>
        </>
    );
};

export default TransferttoFacture;
