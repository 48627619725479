import React, { useCallback, useContext, useMemo, useState } from 'react';
import moment from 'moment';

import { TableColumnType } from 'react-bs-datatable';
import { Badge, Button } from 'react-bootstrap';

import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import { ROLES as RolesMap } from 'constantes/permission-maps';
import { utf8_to_b64 } from 'utils/helpers';

import CustomColorsBadge from 'components/UI/customColorsBadge';
import EtatCommandeBadge from 'components/UI/etatCommandeBadge';
import Datatable from 'components/shared/datatable';

import CommandeInterface from 'types/interfaces/commande.interface';
import { Link } from 'react-router-dom';
import CustomModal from 'components/UI/customModal';
import CommandeFormComponent from 'pages/Commande/components/CommandeForm';
import { AxiosResponse } from 'axios';
import { getCommandeById } from 'services/commande.service';
import UserInterface from 'types/interfaces/user.interface';
import DemandeRetourTab from 'pages/Users/components/DemandeRetourTab';
import DemandeRetourLivreurInterface from 'types/interfaces/demandeRetourLivreur.interface';

type Props = {
    commandes: { items: CommandeInterface[]; count: number };
    setCommandes: (value: { items: CommandeInterface[]; count: number }) => void;
    livreur: UserInterface;
};

const CommandesList: React.FC<Props> = ({ commandes, setCommandes, livreur }: Props) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentLocalCommande, setCurrentLocalCommande] = useState<CommandeInterface | null>(null);
    const [checkedCommandes, setCheckedCommandes] = useState<number[]>([]);

    const onCheckAllCommandes = useCallback(() => {
        setCheckedCommandes((oldState) => {
            if (
                oldState.length ===
                commandes.items.filter(
                    (item: CommandeInterface) =>
                        item.idEtatCommande === 3 &&
                        (item.demandeRetourLivreurs?.length === 0 ||
                            item.demandeRetourLivreurs?.filter((it: DemandeRetourLivreurInterface) => !!it.dead)
                                .length === item.demandeRetourLivreurs?.length),
                ).length
            ) {
                return [];
            }

            return commandes.items
                .filter(
                    (item: CommandeInterface) =>
                        item.idEtatCommande === 3 &&
                        (item.demandeRetourLivreurs?.length === 0 ||
                            item.demandeRetourLivreurs?.filter((it: DemandeRetourLivreurInterface) => !!it.dead)
                                .length === item.demandeRetourLivreurs?.length),
                )
                .map((el: CommandeInterface) => el.idCommande);
        });
    }, [commandes.items]);

    const onCheckCommandesrow = useCallback(
        (row: CommandeInterface) => {
            setCheckedCommandes((oldState) => {
                const idx = oldState.findIndex((el: number) => el === row.idCommande);

                if (
                    row.idEtatCommande !== 3 ||
                    (row.demandeRetourLivreurs?.length! > 0 &&
                        row.demandeRetourLivreurs?.filter((it: DemandeRetourLivreurInterface) => !it.dead).length! > 0)
                )
                    return oldState;

                if (idx === -1) {
                    return oldState.concat(row.idCommande);
                }

                const newState = [...oldState];
                newState.splice(idx, 1);

                return newState;
            });
        },
        [commandes.items],
    );

    const openModal = (id: number) => {
        getCommandeById(id)
            .then((response: AxiosResponse<CommandeInterface | any>) => {
                const { data } = response;
                setCurrentLocalCommande(data);
                setShowModal(true);
            })
            .catch();
    };

    const closeModal = () => {
        setCurrentLocalCommande(null);
        setShowModal(false);
    };

    const tableHeaders: TableColumnType<any>[] = useMemo(
        () => [
            ...(livreur &&
            !!livreur.requireDr &&
            contextUser?.userData?.userroles?.some(
                (d) => d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN || RolesMap.FOURNISSEUR_RECEPTIONISTE,
            )
                ? [
                      {
                          prop: 'checkbox',
                          cellProps: {
                              className: 'foceVerticalMiddle',
                          },
                          headerCell: () => (
                              <input
                                  type="checkbox"
                                  checked={
                                      checkedCommandes.length ===
                                      commandes.items.filter(
                                          (item: CommandeInterface) =>
                                              item.idEtatCommande === 3 &&
                                              (item.demandeRetourLivreurs?.length === 0 ||
                                                  item.demandeRetourLivreurs?.filter(
                                                      (it: DemandeRetourLivreurInterface) => !!it.dead,
                                                  ).length === item.demandeRetourLivreurs?.length),
                                      ).length
                                  }
                                  onChange={onCheckAllCommandes}
                              />
                          ),
                          cell: (row: CommandeInterface) => (
                              <>
                                  {row.idEtatCommande === 3 &&
                                      (row.demandeRetourLivreurs?.length === 0 ||
                                          row.demandeRetourLivreurs?.filter(
                                              (it: DemandeRetourLivreurInterface) => !!it.dead,
                                          ).length === row.demandeRetourLivreurs?.length) && (
                                          <input
                                              type="checkbox"
                                              checked={checkedCommandes.includes(row.idCommande)}
                                              onChange={() => onCheckCommandesrow(row)}
                                          />
                                      )}
                              </>
                          ),
                      },
                  ]
                : []),
            {
                prop: 'ref',
                title: 'CMD-Ref',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: CommandeInterface) => <>{row.ref ?? '-'}</>,
            },
            {
                prop: 'client.raisonSocial',
                title: 'Client',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
            },
            {
                prop: 'colis.refColis',
                title: 'Colis',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
            },
            {
                prop: 'acheteur.fullName',
                title: 'Acheteur',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
            },
            {
                prop: 'acheteur.gsm',
                title: 'GSM',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
            },
            {
                prop: 'prixTotal',
                title: 'Prix',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: CommandeInterface) => <>{row.prixTotal} DH</>,
            },
            {
                prop: 'ville.nomVille',
                title: 'Ville',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
            },
            {
                prop: 'dateCreation',
                title: 'Créé le',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: CommandeInterface) => <>{moment(row.dateCreation).format('DD/MM/YYYY HH:mm')}</>,
            },
            {
                prop: 'dateOperation',
                title: 'Dernière operation',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: CommandeInterface) => <>{moment(row.dateOperation).format('DD/MM/YYYY HH:mm')}</>,
            },
            {
                prop: 'etatCommande.etatCommande',
                title: 'Etat',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: CommandeInterface) => (
                    <>
                        <EtatCommandeBadge value={(row as any)['etatCommande.etatCommande']} />{' '}
                        {!!row.livreurPaiement && <Badge bg="info">P</Badge>}
                    </>
                ),
            },
            {
                prop: 'historique',
                title: 'Historique',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                cell: (row: CommandeInterface) => (
                    <>
                        <CustomColorsBadge
                            value={row.commandeActions[row.commandeActions.length - 1].etatTitre?.titre!}
                            textColor={row.commandeActions[row.commandeActions.length - 1].etatTitre?.textColor!}
                            backgroundColor={
                                row.commandeActions[row.commandeActions.length - 1].etatTitre?.backgroundColor!
                            }
                        />
                        {row.commandeActions[row.commandeActions.length - 1].idEtatTitre === 2 && (
                            <>
                                <CustomColorsBadge
                                    className="ms-1"
                                    value={
                                        row.commandeActions[row.commandeActions.length - 1].description?.split(
                                            ' - ',
                                        )[0]!
                                    }
                                    textColor="#fff"
                                    backgroundColor="#937388"
                                />
                            </>
                        )}
                    </>
                ),
            },
            {
                prop: 'null',
                title: '#',
                alignment: { horizontal: 'right' },
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                cell: (row: CommandeInterface) => (
                    <>
                        {false ? (
                            <Link to={`/commandes?open=${utf8_to_b64(row.idCommande?.toString()!)}`}>
                                <div className="d-grid gap-2">
                                    <FontAwesomeIcon icon={faInfo} size="xs" style={{ color: '#376ca9' }} />
                                </div>
                            </Link>
                        ) : (
                            <Button variant="outline-info" onClick={() => openModal(row.idCommande!)}>
                                <FontAwesomeIcon icon={faInfo} size="xs" style={{ color: '#376ca9' }} />
                            </Button>
                        )}
                    </>
                ),
            },
        ],
        [checkedCommandes, onCheckAllCommandes, onCheckCommandesrow],
    );

    return (
        <>
            {checkedCommandes.length > 0 &&
                contextUser?.userData?.userroles?.some(
                    (d) => d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN || RolesMap.FOURNISSEUR_RECEPTIONISTE,
                ) && (
                    <DemandeRetourTab
                        idLivreur={livreur.idUser}
                        checkedCommandes={checkedCommandes}
                        setCheckedCommandes={setCheckedCommandes}
                        setCommandes={(value: { items: CommandeInterface[]; count: number }) => setCommandes(value)}
                    />
                )}
            <Datatable
                data={commandes.items}
                tableColumns={tableHeaders}
                initialSort={{ initialState: { prop: 'dateOperation', order: 'desc' } }}
                paginationOtion={{ rowsPerPage: 100, options: [100] }}
            ></Datatable>

            <CustomModal
                title="Commande Détails"
                size="lg"
                show={showModal}
                handleClose={closeModal}
                fullscreen
                id="cmd-details-info"
            >
                <CommandeFormComponent
                    commandes={commandes}
                    setCommandes={(value: { items: CommandeInterface[]; count: number }) => setCommandes(value)}
                    currentCommande={currentLocalCommande!}
                    setCurrentCommande={(value: CommandeInterface | null) => setCurrentLocalCommande(value)}
                    setCheckedCommandes={() => void 0}
                />
            </CustomModal>
        </>
    );
};

export default CommandesList;
