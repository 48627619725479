import React, { Dispatch, SetStateAction } from 'react';
import { Button } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import FactureLivreurInterface from 'types/interfaces/factureLivreur.interface';
import { deleteFacture } from 'services/factureLivreur.service';

type Props = {
    current: FactureLivreurInterface;
    setCurrent: Dispatch<SetStateAction<FactureLivreurInterface | null>>;
    setList: Dispatch<SetStateAction<{ items: FactureLivreurInterface[]; count: number }>>;
    close: () => void;
};

const DeleteFactureAction: React.FC<Props> = ({ setCurrent, setList, current, close }: Props) => {
    const submit = async (_: any, { setSubmitting }: FormikHelpers<any>) => {
        try {
            setSubmitting(true);
            const response = await deleteFacture(current.idFactureLivreur);
            if (response.status === 204) {
                toast.success('Facture supprimée avec succès');
                setList((factures: { items: FactureLivreurInterface[]; count: number }) => {
                    const updatedFactures = factures.items.filter(
                        (el: FactureLivreurInterface) => el.idFactureLivreur !== current.idFactureLivreur,
                    );
                    return { items: updatedFactures, count: factures.count - 1 };
                });
                setCurrent(null);
                setSubmitting(false);
                close();
            }
        } catch (Exception) {
            toast.error('Erreur lors de la suppression de la facture');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <p>Êtes-vous sûr de vouloir supprimer cette facture Cette action est irréversible !</p>
            <Formik initialValues={{ confirmDeletion: false }} onSubmit={submit}>
                {({ isSubmitting }) => (
                    <Form>
                        <div className="d-flex justify-content-center gap-2">
                            <Button variant="secondary" onClick={close}>
                                Annuler
                            </Button>
                            <Button type="submit" variant="danger" disabled={isSubmitting}>
                                Confirmer la Suppression
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default DeleteFactureAction;
