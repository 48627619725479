import jwtDecode from 'jwt-decode';
import { localStorageToken } from 'constantes/envLoader';
import { UserAuthInterface } from 'types/index';
import { readImageTobase64 } from 'utils/helpers';

const getUser = (token?: string | null): UserAuthInterface | null => {
    try {
        const jwt: string | null = token ? token : localStorage.getItem(localStorageToken);
        if (jwt !== null) {
            const userDecoded: any = jwtDecode(jwt);
            userDecoded.userData = JSON.parse(userDecoded?.userData as string);
            return userDecoded as UserAuthInterface;
        }
        return null;
    } catch (ex) {
        return null;
    }
};

export default getUser;

export const savingAuth = (token: string, imageLogo: any) => {
    localStorage.setItem(localStorageToken, token);
    return { user: getUser(token), imageLogo: imageLogo && readImageTobase64(imageLogo, 'png') };
};
