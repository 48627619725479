import React, { useState, useEffect } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Formik } from 'formik';
import { AxiosResponse } from 'axios';

import CommandeInterface from 'types/interfaces/commande.interface';
import AcheteurInterface, { AcheteurEditInterface } from 'types/interfaces/acheteur.interface';
import { getCommandeById } from 'services/commande.service';
import { saveAcheteur } from 'services/acheteur.service';

const schema = yup.object().shape({
    idAcheteur: yup.number().required(),
    gsm: yup.string().required(),
    fullName: yup.string().required(),
});

type Props = {
    commandes: { items: CommandeInterface[]; count: number };
    setCommandes: (value: { items: CommandeInterface[]; count: number }) => void;
    currentCommande: CommandeInterface;
    setCurrentCommande: (value: CommandeInterface | null) => void;
    setCheckedCommandes: (value: number[]) => void;
    closeModal: () => void;
};

const CommandeAcheteurEditForm: React.FC<Props> = ({
    commandes,
    setCommandes,
    currentCommande,
    setCurrentCommande,
    setCheckedCommandes,
    closeModal,
}: Props) => {
    const [commande, setCommande] = useState<CommandeInterface | null>(null);
    const [spinIt, setSpinIt] = useState<boolean>(false);

    useEffect(() => {
        if (currentCommande) {
            getCommandeById(currentCommande?.idCommande!)
                .then((response: AxiosResponse<CommandeInterface | any>) => {
                    setCommande(response.data);
                })
                .catch();
        }
    }, [currentCommande]);

    const submitForm = async (editedData: AcheteurEditInterface | any) => {
        try {
            setSpinIt(true);
            const editedAcheteur: AcheteurInterface = { ...commande?.acheteur!, ...editedData };
            const response = await saveAcheteur(editedAcheteur);
            if (response.status === 200) {
                toast.success('Commande a été modifiée');
                const editedCmd = { ...commande!, acheteur: editedAcheteur };
                setCurrentCommande(editedCmd);
                setSpinIt(false);
                setCheckedCommandes([]);
                const cmdData = [...commandes.items];
                const index = cmdData.findIndex((item) => item.idCommande === editedCmd.idCommande);
                cmdData[index] = editedCmd;
                setCommandes({ items: cmdData, count: commandes.count });
                closeModal();
            }
        } catch (Exception) {
            setSpinIt(false);
            const { message = undefined }: any = Exception;
            toast.error(message || 'Error occured!');
        }
    };

    return (
        <Formik
            validationSchema={schema}
            onSubmit={(values) => submitForm(values)}
            enableReinitialize={true}
            initialValues={{
                idAcheteur: commande?.idAcheteur! ?? undefined,
                gsm: commande?.acheteur?.gsm! ?? '',
                fullName: commande?.acheteur?.fullName! ?? '',
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group controlId="form_grp_fullName">
                        <Form.Label>Nom</Form.Label>
                        <Form.Control
                            type="text"
                            name="fullName"
                            placeholder="Nom"
                            value={values.fullName}
                            onChange={handleChange}
                            isInvalid={!!errors.fullName}
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group controlId="form_grp_gsm">
                        <Form.Label>GSM</Form.Label>
                        <Form.Control
                            type="gsm"
                            name="gsm"
                            placeholder="Gsm"
                            value={values.gsm}
                            onChange={handleChange}
                            isInvalid={!!errors.gsm}
                            size="sm"
                        />
                    </Form.Group>
                    <div className="d-grid gap-2 mt-3">
                        <Button variant="primary" type="submit" disabled={!!spinIt}>
                            Enregistrer
                            {!!spinIt && (
                                <>
                                    &nbsp; <Spinner animation="border" size="sm" />
                                </>
                            )}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default CommandeAcheteurEditForm;
