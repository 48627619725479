import React, { Dispatch, SetStateAction, useState, useEffect, useContext } from 'react';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { Form, Col, Row, Spinner, Button, InputGroup } from 'react-bootstrap';
import Compressor from 'compressorjs';

import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';

import TransfertFactureInterface from 'types/interfaces/transfertFacture.interface';
import TransfertFactureDetails from 'pages/Transfert/factures/components/TransfertFactureDetails';
import { readFileAsync, readImageTobase64 } from 'utils/helpers';
import { postFacturePaiement, puttConfirmFacturePaiement } from 'services/transfert.service';
import ImageElement from 'components/UI/imageElement';
import Datetime from 'react-datetime';
import { MAX_SIZE_IMAGE_UPLOAD } from 'constantes/envLoader';
import { getFileById } from 'services/file.service';
import moment from 'moment';

const schema = yup.object({
    id: yup.number().required(),
    fileImage: yup.mixed().required(),
});

type Props = {
    transfertFacture: TransfertFactureInterface;
    setTransfertFacture: Dispatch<SetStateAction<TransfertFactureInterface | null>>;
    enablePaymentForm?: boolean;
    setList: Dispatch<SetStateAction<{ items: TransfertFactureInterface[]; count: number }>>;
    close: () => void;
};
const TransfertFacturePaiementForm: React.FC<Props> = ({
    transfertFacture,
    setTransfertFacture,
    enablePaymentForm = false,
    setList,
    close,
}: Props) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);
    const [spinIt, setSpinIt] = useState<boolean>(false);

    const submitPaiementForm = async (values: { id: string; fileImage: string } | any) => {
        try {
            setSpinIt(true);
            if (values.fileImage) {
                try {
                    if (values.fileImage) {
                        const fileResult = await readFileAsync(values.fileImage);
                        values.fileImage =
                            (fileResult as string)?.replace(/^data:image\/(png|jpg|jpeg);base64,/, '') || null;
                    }
                } catch (ex) {
                    values.fileImage = null;
                }
            }
            const response = await postFacturePaiement(values);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Facture paiement est traitée avec succées');
                setList((factures: { items: TransfertFactureInterface[]; count: number }) => {
                    const facturesData = [...factures.items];
                    const factureIndex = facturesData.findIndex(
                        (el: TransfertFactureInterface) => el.idTransfertFacture == resultData.idTransfertFacture,
                    );
                    facturesData[factureIndex] = resultData;
                    return { items: facturesData, count: factures.count };
                });
                setSpinIt(false);
                close();
            }
        } catch (Exception) {
        } finally {
            setSpinIt(false);
        }
    };
    const submitPaiementConfirmationForm = async (values: { idTransfertFacture: number } | any) => {
        try {
            setSpinIt(true);
            const response = await puttConfirmFacturePaiement(values);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Paiement est confirmé avec succées');
                setList((factures: { items: TransfertFactureInterface[]; count: number }) => {
                    const facturesData = [...factures.items];
                    const factureIndex = facturesData.findIndex(
                        (el: TransfertFactureInterface) => el.idTransfertFacture == resultData.idTransfertFacture,
                    );
                    facturesData[factureIndex] = resultData;
                    return { items: facturesData, count: factures.count };
                });
                setSpinIt(false);
                close();
            }
        } catch (Exception) {
        } finally {
            setSpinIt(false);
        }
    };

    useEffect(() => {
        if (transfertFacture.paid) {
            getFileById(transfertFacture.idFile!).then((response: any) => {
                const { data } = response;
                setTransfertFacture((prev: TransfertFactureInterface | null) => {
                    if (!prev) return null;
                    const next = { ...prev };
                    next.file = data;
                    return next;
                });
            });
        }
    }, [transfertFacture.idTransfertFacture]);

    return (
        <>
            <TransfertFactureDetails facture={transfertFacture} />
            {transfertFacture.paid === true &&
                !transfertFacture.confirmed &&
                (transfertFacture.transfertFactureCommandes as any)[0].transfertCommande?.idFournisseurSource ===
                    contextUser?.userData?.idFournisseur! && (
                    <div className="d-flex justify-content-end">
                        <Button
                            variant="outline-primary"
                            onClick={() =>
                                submitPaiementConfirmationForm({
                                    idTransfertFacture: transfertFacture.idTransfertFacture,
                                })
                            }
                            disabled={!!spinIt}
                        >
                            <b>Confirmer paiement</b>
                            {!!spinIt && (
                                <>
                                    &nbsp; <Spinner animation="border" size="sm" />
                                </>
                            )}
                        </Button>
                    </div>
                )}
            <br />
            <Formik
                validationSchema={schema}
                onSubmit={(values: any) => submitPaiementForm(values)}
                enableReinitialize={true}
                initialValues={{
                    id: transfertFacture.idTransfertFacture,
                    fileImage: null,
                }}
            >
                {({ handleSubmit, setFieldValue, values, errors }) => (
                    <Form noValidate onSubmit={handleSubmit} className="d-grid gap-2">
                        {transfertFacture.paid && (
                            <Row>
                                <Col auto>
                                    <Form.Label>Date:</Form.Label>
                                </Col>
                                <Col auto>
                                    <Datetime
                                        className="form-control form-control-sm no_form-control_input_style"
                                        inputProps={{
                                            name: 'dateFin',
                                            className: `text-end form-control form-control-sm`,
                                            readOnly: true,
                                        }}
                                        timeFormat={false}
                                        dateFormat="DD/MM/YYYY"
                                        value={moment(transfertFacture.dateFin)}
                                        closeOnSelect={true}
                                    />
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col md={{ span: 6, offset: 3 }}>
                                {enablePaymentForm && (
                                    <Form.Group controlId="form_grp_fileImage">
                                        <Form.Label>Recu</Form.Label>
                                        <InputGroup size="sm">
                                            <Form.Control
                                                size="sm"
                                                type="file"
                                                name="fileImage"
                                                placeholder="Recu"
                                                onChange={(event: any) => {
                                                    const image = event.currentTarget.files[0];
                                                    if (image.size > MAX_SIZE_IMAGE_UPLOAD) {
                                                        toast.warning(
                                                            'vous ne pouvez pas télécharger une image de plus de 1mo',
                                                        );
                                                        return;
                                                    } else if (!image.name.match(/.(png|jpg|jpeg)$/i)) {
                                                        toast.warning("Image Format n'est pas valide");
                                                        return;
                                                    }
                                                    // Compress the image
                                                    new Compressor(image, {
                                                        quality: 0.8, // Adjust compression quality as needed
                                                        success: (compressedImage) => {
                                                            console.log(image.size, compressedImage.size);
                                                            setFieldValue('fileImage', compressedImage);
                                                        },
                                                        error: () => {
                                                            toast.warning("Erreur lors de la compression de l'image");
                                                        },
                                                    });
                                                    // setFieldValue('fileImage', image);
                                                }}
                                                isInvalid={!!errors.fileImage}
                                                accept="image/png, image/jpg, image/jpeg"
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                )}
                                {values.fileImage ? (
                                    <div className="text-center my-3">
                                        <ImageElement
                                            file={readImageTobase64(values.fileImage)}
                                            alt="file recu"
                                            fluid
                                        />
                                    </div>
                                ) : transfertFacture?.file ? (
                                    <div className="text-center my-3">
                                        <ImageElement
                                            file={readImageTobase64(transfertFacture?.file?.fileImage)}
                                            alt="file recu"
                                            fluid
                                        />
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </Col>
                        </Row>
                        {enablePaymentForm && (
                            <div className="d-grid gap-2">
                                <Button variant="primary" type="submit" disabled={!!spinIt}>
                                    Enregistrer
                                    {!!spinIt && (
                                        <>
                                            &nbsp; <Spinner animation="border" size="sm" />
                                        </>
                                    )}
                                </Button>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default TransfertFacturePaiementForm;
