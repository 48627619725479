import React, { useContext, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import * as yup from 'yup';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Form, InputGroup, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { FieldArray, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { removeDuplicatedArrObj, sortArrByTwoCols, sortByNumber, useQueryMeMo, b64_to_utf8 } from 'utils/helpers';
import { Deserialize, Serialize } from 'pages/DraftCommande/utils';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';

import DraftCommandeInterface, {
    AcheteurCommandeDraftResponse,
    ProduitCommandeDraftResponse,
    ServiceCommandeDraftResponse,
} from 'types/interfaces/draftCommande.interface';
import TarifInterface from 'types/interfaces/tarif.interface';
import RegionInterface from 'types/interfaces/region.interface';
import VilleInterface from 'types/interfaces/ville.interface';
import { createDraftCommande, getDraftCommandeById, updateDraftCommande } from 'services/draftCommande.service';
import { getTarifsForClient } from 'services/tarif.service';
import { getRegionsWVilles } from 'services/region.service';

import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import TitleElement from 'components/UI/titleElement';
import SearchAcheteurByGSM from 'components/shared/searchAcheteurByGsm';

const schema = yup.object().shape({
    idDraftCommande: yup.number().nullable(),
    idVille: yup.number().required(),
    adresse: yup.string().required(),
    ref: yup.string().nullable(),
    commentaire: yup.string(),
    prixTotal: yup.number().moreThan(-1).required(),
    acheteur: yup.object().shape({
        idAcheteur: yup.number().nullable(),
        addresse: yup.string().nullable(),
        fullName: yup.string().required(),
        gsm: yup
            .string()
            .matches(
                /^(?:(?:(?:\+|00)212[\s]?(?:[\s]?\(0\)[\s]?)?)|0){1}(?:5[\s.-]?[2-3]|[5-8][\s.-]?[0-9]){1}[0-9]{1}(?:[\s.-]?\d{2}){3}$/,
                "Merci d'entrer un N°GSM valide",
            )
            .required(),
        email: yup.string().email().nullable(),
    }),
    commandeTarifs: yup
        .array()
        .of(
            yup.object({
                idTarif: yup.number().required(),
            }),
        )
        .min(1)
        .required(),
    produit: yup.object().shape({
        designation: yup.string().required(),
        quantity: yup.number().moreThan(0).required(),
        prixVente: yup.number().moreThan(-1).required(),
    }),
});

const CreateOrUpdateForm: React.FC = () => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);
    const [spinIt, setSpinIt] = useState<boolean>(false);
    const [tarifs, setTarifs] = useState<TarifInterface[]>([]);
    const [regions, setRegions] = useState<RegionInterface[]>([]);
    const [draft, setDraft] = useState<DraftCommandeInterface | null>(null);

    const history = useNavigate();
    const query = useQueryMeMo();
    const { id } = useParams();

    useEffect(() => {
        document.title = `${!!id ? 'Edit' : 'Ajout'} de Paquet - DS`;

        if (contextUser) {
            const client = query.get('client');
            const clientId = contextUser.userData.type === 'Client' ? '' : client ? b64_to_utf8(client) : '';

            if (contextUser.userData.type !== 'Client' && !clientId) {
                history('/commandes/draft', { replace: true });
                toast.warning("Une erreur s'est produite");
                return;
            }
            getTarifsForClient(+clientId!)
                .then((response: AxiosResponse<TarifInterface[] | any>) => {
                    const { data: responseData } = response;
                    const sortedArr = sortArrByTwoCols(
                        sortByNumber(responseData, 'idTarif', true),
                        'idOptionService',
                        'idClient',
                        true,
                    );
                    setTarifs(sortedArr);
                })
                .catch();
        }

        if (id && contextUser) {
            const client = query.get('client');
            const clientId = contextUser.userData.type === 'Client' ? '' : client ? b64_to_utf8(client) : '';

            if (contextUser.userData.type !== 'Client' && !clientId) {
                history('/commandes/draft', { replace: true });
                toast.warning("Une erreur s'est produite");
                return;
            }

            const idClientForData =
                contextUser.userData.type !== 'Client' ? +clientId! : contextUser?.userData.idClient!;
            getDraftCommandeById(+id!)
                .then(({ data }) => {
                    if (data.idClient.toString() !== idClientForData.toString()) {
                        history('/commandes/draft', { replace: true });
                        toast.warning("Une erreur s'est produite");
                        return;
                    }
                    setDraft(data);
                })
                .catch(() => {
                    toast.error("Une erreur s'est produite");
                });
        }

        getRegionsWVilles()
            .then((response: AxiosResponse<RegionInterface[] | any>) => {
                const { data } = response;
                setRegions(data);
            })
            .catch();
    }, [id, contextUser, query]);

    const submitForm = (values: any) => {
        setSpinIt(true);
        const data = {
            ...values,
            acheteurJson: Serialize(values.acheteur),
            produitJson: Serialize(values.produit),
            servicesJson: Serialize(values.commandeTarifs),
        };
        delete data.acheteur;
        delete data.produit;
        delete data.commandeTarifs;
        if (!id) {
            delete data.idDraftCommande;
            createDraftCommande(
                data,
                contextUser?.userData.type === 'Client' ? undefined : b64_to_utf8(query.get('client')!),
            )
                .then(() => {
                    toast.success('Paquet ajoute avec succes');
                    history(
                        `/commandes/draft${
                            contextUser?.userData.type !== 'Client' && query.get('client')
                                ? '?client=' + query.get('client')
                                : ''
                        }`,
                        {
                            replace: true,
                        },
                    );
                })
                .catch(() => {
                    toast.error("Une erreur s'est produite");
                    setSpinIt(false);
                });
        } else {
            updateDraftCommande(
                +id!,
                data,
                contextUser?.userData.type === 'Client' ? undefined : b64_to_utf8(query.get('client')!),
            )
                .then(() => {
                    toast.success('Paquet mis à jour avec succes');
                    history(
                        `/commandes/draft${
                            contextUser?.userData.type !== 'Client' && query.get('client')
                                ? '?client=' + query.get('client')
                                : ''
                        }`,
                        {
                            replace: true,
                        },
                    );
                })
                .catch(() => {
                    toast.error("Une erreur s'est produite");
                    setSpinIt(false);
                });
        }
    };

    const acheteurInitValue = {
        idAcheteur: undefined,
        addresse: '',
        fullName: '',
        gsm: '',
        email: '',
    };

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { path: '/commandes/draft ', text: 'Paquet Personnalisé' },
        { active: true, text: !!id ? 'Edit' : 'Nouveau' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    {!!id ? 'Edit' : 'Nouveau'} Paquet
                </TitleElement>
            </div>
            <Formik
                validationSchema={schema}
                onSubmit={(values) => submitForm(values)}
                enableReinitialize={!!id}
                initialValues={{
                    idDraftCommande: draft ? draft.idDraftCommande : undefined,
                    ref: draft ? draft.ref : '',
                    idVille: draft ? draft.idVille : '',
                    adresse: draft ? draft.adresse : '',
                    commentaire: draft ? draft.commentaire : '',
                    prixTotal: draft ? draft.prixTotal : 0,
                    commandeTarifs: draft ? Deserialize<ServiceCommandeDraftResponse>(draft.servicesJson) : [],
                    acheteur: draft
                        ? Deserialize<AcheteurCommandeDraftResponse>(draft.acheteurJson)
                        : { idAcheteur: undefined, gsm: '', email: '', adresse: '', fullName: '' },
                    produit: draft
                        ? Deserialize<ProduitCommandeDraftResponse>(draft.produitJson)
                        : { designation: '', quantity: 1, prixVente: 0 },
                }}
            >
                {({ setFieldValue, handleSubmit, handleChange, values, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <div className="d-grid gap-2 mt-3">
                            <Row>
                                <Form.Group as={Col} md="4" controlId="form_grp_ref">
                                    <Form.Label>Ref</Form.Label>
                                    <Form.Control
                                        type="ref"
                                        name="ref"
                                        placeholder="Ref"
                                        value={values.ref}
                                        onChange={handleChange}
                                        isInvalid={!!errors.ref}
                                        size="sm"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="form_grp_ville">
                                    <Form.Label>Villes</Form.Label>
                                    <Form.Select
                                        size="sm"
                                        name="idVille"
                                        value={values.idVille}
                                        onChange={(e) => {
                                            const defaultLivraisonTarif = (
                                                removeDuplicatedArrObj(
                                                    tarifs.filter(
                                                        (d: TarifInterface) =>
                                                            e.target.value.toString() === d.idVille.toString(),
                                                    ),
                                                    'idOptionService',
                                                ) as TarifInterface[]
                                            ).find(
                                                (d: TarifInterface) =>
                                                    d.optionService.nameOptionService === 'Livraison',
                                            )?.idTarif!;
                                            handleChange({
                                                target: {
                                                    value: defaultLivraisonTarif
                                                        ? [{ idTarif: defaultLivraisonTarif }]
                                                        : [],
                                                    name: 'commandeTarifs',
                                                },
                                            });
                                            handleChange({
                                                target: {
                                                    value:
                                                        (values.produit?.prixVente ?? 0) *
                                                        (values.produit?.quantity ?? 1),
                                                    name: 'prixTotal',
                                                },
                                            });
                                            handleChange(e);
                                        }}
                                        isInvalid={!!errors.idVille}
                                        placeholder="Selectionner une ville"
                                    >
                                        <option>Selectionner une ville</option>
                                        {regions.map((dr: RegionInterface) => (
                                            <optgroup key={dr.idRegion} label={dr.nomRegion}>
                                                {dr.villes?.map((d: VilleInterface) => (
                                                    <option key={d.idVille} value={d.idVille}>
                                                        {d.nomVille}
                                                    </option>
                                                ))}
                                            </optgroup>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="form_grp_commentaire">
                                    <Form.Label>Commentaire</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        as="textarea"
                                        rows={2}
                                        name="commentaire"
                                        placeholder="Commentaire"
                                        value={values.commentaire}
                                        onChange={handleChange}
                                        isInvalid={!!errors.commentaire}
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col md="8">
                                    <h5>Produit</h5>
                                    <InputGroup>
                                        <Form.Control
                                            title="Designation"
                                            size="sm"
                                            name="produit.designation"
                                            placeholder="Designation"
                                            value={values.produit?.designation}
                                            onChange={handleChange}
                                            isInvalid={!!errors.produit?.designation!}
                                        />
                                        <Form.Control
                                            title="Quantité"
                                            size="sm"
                                            type="text"
                                            className="text-end"
                                            name="produit.quantity"
                                            placeholder="Quantité"
                                            value={values.produit?.quantity!}
                                            onChange={(e: any) => {
                                                handleChange(e);
                                                setFieldValue(
                                                    'prixTotal',
                                                    e.target.value * (values.produit?.prixVente ?? 0),
                                                );
                                            }}
                                            isInvalid={!!errors.produit?.quantity!}
                                        />
                                        <Form.Control
                                            title="Prix tarif"
                                            size="sm"
                                            type="text"
                                            className="text-end"
                                            name="produit.prixVente"
                                            placeholder="Prix tarif"
                                            value={values.produit?.prixVente}
                                            onChange={(e: any) => {
                                                handleChange(e);
                                                setFieldValue(
                                                    'prixTotal',
                                                    e.target.value * (values.produit?.quantity ?? 1),
                                                );
                                            }}
                                            isInvalid={!!errors.produit?.prixVente!}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col md="4">
                                    <h5 className="text-center">Services</h5>
                                    <FieldArray
                                        name="commandeTarifs"
                                        render={(arrayHelpers) => {
                                            return (
                                                removeDuplicatedArrObj(
                                                    tarifs.filter(
                                                        (d: TarifInterface) =>
                                                            values.idVille.toString() === d.idVille.toString(),
                                                    ),
                                                    'idOptionService',
                                                ) as TarifInterface[]
                                            ).map((d: TarifInterface, index) => (
                                                <Row className=" g-3 align-items-center" key={d.idTarif}>
                                                    <Col auto>
                                                        <Form.Check
                                                            type="checkbox"
                                                            label={`${d.optionService.nameOptionService} :`}
                                                            name={`commandeTarifs[${index}].idTarif`}
                                                            id={`commandeTarifs[${index}].idTarif`}
                                                            checked={(
                                                                values.commandeTarifs as ServiceCommandeDraftResponse[]
                                                            )
                                                                .map((dt: any) => dt.idTarif.toString())
                                                                .includes(d.idTarif.toString())}
                                                            disabled={d.optionService.nameOptionService === 'Livraison'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    arrayHelpers.push({
                                                                        idTarif: d.idTarif,
                                                                    });
                                                                } else {
                                                                    const index = (
                                                                        values.commandeTarifs as ServiceCommandeDraftResponse[]
                                                                    ).findIndex((obj: any) => obj.idTarif == d.idTarif);
                                                                    arrayHelpers.remove(index);
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col auto className="text-end">
                                                        {d.prixTarif} DH
                                                    </Col>
                                                </Row>
                                            ));
                                        }}
                                    />
                                    <Row className="g-3 align-items-center">
                                        <Col auto>
                                            <strong>Prix des services:</strong>{' '}
                                            {!!errors.commandeTarifs && (
                                                <FontAwesomeIcon
                                                    icon={faExclamationTriangle}
                                                    size="sm"
                                                    style={{ color: '#dc3545', fontSize: '1rem' }}
                                                />
                                            )}
                                        </Col>
                                        <Col auto className="text-end">
                                            <strong>
                                                {tarifs
                                                    .filter((d: TarifInterface) =>
                                                        (values.commandeTarifs as ServiceCommandeDraftResponse[]).find(
                                                            (dd: any) => dd.idTarif === d.idTarif,
                                                        ),
                                                    )
                                                    .reduce((accumulator: number, object: any) => {
                                                        return accumulator + object.prixTarif;
                                                    }, 0)}{' '}
                                                DH
                                            </strong>
                                        </Col>
                                    </Row>
                                    <Form.Group controlId="form_grp_prixTotal">
                                        <Row className=" g-3 align-items-center">
                                            <Col auto>
                                                <Form.Label style={{ fontWeight: 700, fontSize: '1rem' }}>
                                                    Total
                                                </Form.Label>
                                            </Col>
                                            <Col auto>
                                                <InputGroup>
                                                    <Form.Control
                                                        className="text-end"
                                                        type="text"
                                                        name="prixTotal"
                                                        placeholder="Prix Tarif"
                                                        value={values.prixTotal}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.prixTotal}
                                                    />
                                                    <InputGroup.Text>DH</InputGroup.Text>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Group as={Col} md="6" controlId="form_grp_none">
                                    <Tabs
                                        defaultActiveKey="acheteur"
                                        transition={false}
                                        id="acheteur_tabs"
                                        className="mt-1"
                                    >
                                        <Tab eventKey="acheteur" title="Acheteur" className="tab-content-border">
                                            <Form.Group controlId="form_grp_gsmSearch">
                                                <Form.Label>Chercher par GSM</Form.Label>
                                                <SearchAcheteurByGSM
                                                    InitValue={acheteurInitValue}
                                                    setField={(f: string, v: any) => setFieldValue(f, v)}
                                                    fieldValuesData={values.acheteur}
                                                />
                                            </Form.Group>
                                            <Row>
                                                <Form.Group as={Col} md="6" controlId="form_grp_gsm">
                                                    <Form.Label>GSM</Form.Label>
                                                    <Form.Control
                                                        type="gsm"
                                                        name="acheteur.gsm"
                                                        placeholder="Gsm"
                                                        value={values.acheteur.gsm}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.acheteur?.gsm!}
                                                        size="sm"
                                                        readOnly={!!values.acheteur.idAcheteur}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="6" controlId="form_grp_email">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        name="acheteur.email"
                                                        placeholder="Email"
                                                        value={values.acheteur.email}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.acheteur?.email!}
                                                        size="sm"
                                                        readOnly={!!values.acheteur.idAcheteur}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group as={Col} md="12" controlId="form_grp_fullName">
                                                    <Form.Label>Nom</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="acheteur.fullName"
                                                        placeholder="Nom"
                                                        value={values.acheteur.fullName}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.acheteur?.fullName!}
                                                        size="sm"
                                                        readOnly={!!values.acheteur.idAcheteur}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Form.Group controlId="form_grp_acheteur_adresse">
                                                <Form.Label>Adresse</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={2}
                                                    name="acheteur.adresse"
                                                    placeholder="Adresse"
                                                    value={values.acheteur?.adresse!}
                                                    onChange={(e: any) => {
                                                        handleChange(e);
                                                        handleChange({
                                                            target: {
                                                                value: e.target.value,
                                                                name: 'adresse',
                                                            },
                                                        });
                                                    }}
                                                    isInvalid={!!errors.acheteur?.adresse!}
                                                    readOnly={!!values.acheteur.idAcheteur}
                                                />
                                            </Form.Group>
                                        </Tab>
                                    </Tabs>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="form_grp_adresse">
                                    <Form.Label>Adresse de livraison</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={2}
                                        name="adresse"
                                        placeholder="Adresse de livraison"
                                        value={values.adresse}
                                        onChange={handleChange}
                                        isInvalid={!!errors.adresse}
                                    />
                                </Form.Group>
                            </Row>
                            <div className="d-flex justify-content-end gap-2 mt-3">
                                <Button variant="primary" type="submit" disabled={!!spinIt}>
                                    Enregistrer
                                    {!!spinIt && (
                                        <>
                                            &nbsp; <Spinner animation="border" size="sm" />
                                        </>
                                    )}
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default CreateOrUpdateForm;
