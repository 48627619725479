import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import CommandeInterface, { CommandePatchInterface, CommandeEditInterface } from 'types/interfaces/commande.interface';
import { StatusType } from 'types';

const apiEndPoint = `${apiUrl}/commandes`;

export const getCommandeById = (id: number): Promise<CommandeInterface | any> => {
    return http.get(`${apiEndPoint}/${id}`);
};

export const getCommandeByCode = (code: string): Promise<CommandeInterface | any> => {
    return http.get(`${apiEndPoint}/byCodeOrRef/${code}`);
};

export const saveCommande = (commande: CommandeInterface) => {
    const body = {
        ...commande,
    };
    // if (commande.idCommande) {
    //     return http.put(`${apiEndPoint}/${commande.idCommande}`, body);
    // }
    return http.post(`${apiEndPoint}`, body);
};

export const getCommandesFiltered = (
    total: number,
    page: number,
    status: string,
    factureStatus: string,
    paiementStatus: string,
    phoneNumber: string,
    idVille: string,
    idEtatTitre?: number | string,
    idClient?: number | string,
    idLivreur?: number | string,
    dateDebut?: Date | string,
    dateFin?: Date | string,
    aFacturer?: boolean,
    transferts?: boolean,
    idPartner?: number | string,
): Promise<{ count: number; items: CommandeInterface[] } | any> => {
    const query = `?page=${page}&total=${total}${idClient ? '&idClient=' + idClient : ''}${
        idLivreur ? '&idLivreur=' + idLivreur : ''
    }${idEtatTitre ? '&idEtatTitre=' + idEtatTitre : ''}${dateDebut ? '&dateDebut=' + dateDebut : ''}${
        dateFin ? '&dateFin=' + dateFin : ''
    }${status ? '&status=' + status : ''}${factureStatus ? '&facture=' + factureStatus : ''}${
        paiementStatus ? '&paiement=' + paiementStatus : ''
    }${phoneNumber ? '&phoneNumber=' + phoneNumber : ''}${idVille ? '&idVille=' + idVille : ''}&aFacturer=${
        aFacturer ? 'true' : 'false'
    }&transferts=${transferts ? 'true' : 'false'}${idPartner ? '&idPartner=' + idPartner : ''}`;
    return http.get(`${apiEndPoint}/filter${query}`);
};

export const getCommandesneedAttentionFiltered = (
    total: number,
    page: number,
    status: string,
    factureStatus: string,
    paiementStatus: string,
    phoneNumber: string,
    idVille: string,
    idEtatTitre?: number | string,
    idClient?: number | string,
    idLivreur?: number | string,
    dateDebut?: Date | string,
    dateFin?: Date | string,
): Promise<{ count: number; items: CommandeInterface[] } | any> => {
    const query = `?page=${page}&total=${total}${idClient ? '&idClient=' + idClient : ''}${
        idLivreur ? '&idLivreur=' + idLivreur : ''
    }${idEtatTitre ? '&idEtatTitre=' + idEtatTitre : ''}${dateDebut ? '&dateDebut=' + dateDebut : ''}${
        dateFin ? '&dateFin=' + dateFin : ''
    }${status ? '&status=' + status : ''}${factureStatus ? '&facture=' + factureStatus : ''}${
        paiementStatus ? '&paiement=' + paiementStatus : ''
    }${phoneNumber ? '&phoneNumber=' + phoneNumber : ''}${idVille ? '&idVille=' + idVille : ''}`;
    return http.get(`${apiEndPoint}/need-attention/filter${query}`);
};

export const getCommandesStatesFiltered = (
    pageType: string | undefined,
    total: number,
    page: number,
    status: string,
    factureStatus: string,
    paiementStatus: string,
    phoneNumber: string,
    idVille: string,
    idEtatTitre?: number | string,
    idClient?: number | string,
    idLivreur?: number | string,
    dateDebut?: Date | string,
    dateFin?: Date | string,
    transferts?: boolean,
    idPartner?: number | string,
): Promise<any> => {
    const query = `?page=${page}&total=${total}${pageType && pageType !== 'Original' ? '&type=' + pageType : ''}${
        idClient ? '&idClient=' + idClient : ''
    }${idLivreur ? '&idLivreur=' + idLivreur : ''}${idEtatTitre ? '&idEtatTitre=' + idEtatTitre : ''}${
        dateDebut ? '&dateDebut=' + dateDebut : ''
    }${dateFin ? '&dateFin=' + dateFin : ''}${status ? '&status=' + status : ''}${
        factureStatus ? '&facture=' + factureStatus : ''
    }${paiementStatus ? '&paiement=' + paiementStatus : ''}${phoneNumber ? '&phoneNumber=' + phoneNumber : ''}${
        idVille ? '&idVille=' + idVille : ''
    }&transferts=${transferts ? 'true' : 'false'}${idPartner ? '&idPartner=' + idPartner : ''}`;
    return http.get(`${apiEndPoint}/states/filter${query}`);
};

export const getCommandesLivreurPaiementIsFalseFiltered = (
    total: number,
    page: number,
    idLivreur?: number | string,
): Promise<any> => {
    const query = `?page=${page}&total=${total}${idLivreur ? '&idLivreur=' + idLivreur : ''}`;
    return http.get(`${apiEndPoint}/falsy-livreur-paiement/filter${query}`);
};

export const createFactureForLivreurPaiement = (data: any, idLivreur: number): Promise<number[] | any> =>
    http.post(`${apiEndPoint}/range/livreur-paiement/${idLivreur}`, data);

export const affecterALivreurList = (data: any): Promise<number[] | any> =>
    http.post(`${apiEndPoint}/range/livraison`, data);

export const sendEtatCommande = (id: number, status: StatusType): Promise<CommandeInterface | any> => {
    return http.put(`${apiEndPoint}/${id}/${status}`);
};

export const patchCommande = (idCommande: number, commande: CommandePatchInterface) => {
    return http.patch(`${apiEndPoint}/${idCommande}`, commande);
};
export const editCommande = (idCommande: number, commande: CommandeEditInterface) => {
    return http.put(`${apiEndPoint}/edit/${idCommande}`, commande);
};

export const getLivreursActiveCommandes = (
    idUser: number,
): Promise<{ count: number; items: CommandeInterface[] } | any> => {
    return http.get(`${apiEndPoint}/of-livreur/${idUser}`);
};

export const getCommandeByCodeOrRefForAction = (
    codeOrRef: string,
    action: string,
): Promise<CommandeInterface | any> => {
    return http.get(`${apiEndPoint}/scan/byCodeRef/${codeOrRef}/${action}`);
};

export const sendRetourCommandeRange = (ids: number[]): Promise<number[] | any> => {
    return http.put(`${apiEndPoint}/retour-stock/range`, {
        ids,
    });
};

export const postTransfert = (bodyData: {
    idFournisseur: number;
    transfertDetails: { idCommande: number; idTarifTransfert: number; prix: number }[];
}): Promise<number[] | any> => {
    return http.post(`${apiEndPoint}/transfert`, bodyData);
};

export const deleteCommande = (id: number) => http.delete(`${apiEndPoint}/${id}`);
