import React /*, { useState }*/ from 'react';
import { Outlet } from 'react-router-dom';
// import { isMobile } from 'react-device-detect';
import { Container } from 'react-bootstrap';
import Header from 'components/core/default-layout/header';
import Footer from 'components/core/default-layout/footer';
// import SideBar from 'components/core/default-layout/side-bar/sidebar';
// import { _sidebarStorage } from 'constantes/envLoader';

type Props = {
    logout: (noRedirecting: boolean) => void;
};
const DefaultLayout: React.FC<Props> = ({ logout }: Props) => {
    // const [toggled, setToggled] = useState<boolean>(localStorage.getItem(_sidebarStorage) == 'true' || false);
    // const toggleSideBar = () => {
    //     const toggleValue = !toggled;
    //     setToggled(toggleValue);
    //     localStorage.setItem(_sidebarStorage, toggleValue.toString());
    // };
    // const hideSideBar = () => {
    //     if (isMobile && toggled === true) {
    //         setToggled(false);
    //         localStorage.setItem(_sidebarStorage, 'false');
    //     }
    // };

    return (
        <div className="root-wrapper">
            {/* <div className={toggled ? 'd-flex toggled' : 'd-flex'} id="wrapper">
                <SideBar />
                <div id="page-content-wrapper">
                    <Header handleSideBarToggle={toggleSideBar} logout={logout} />
                    <Container fluid="md" className="un-container" onClick={() => hideSideBar()}>
                        <Outlet />
                    </Container>
                </div>
            </div>
            <Footer /> */}
            <div id="wrapper">
                <div id="page-content-wrapper">
                    <Header logout={logout} />
                    <Container fluid="md" className="un-container c-container-md-none" onClick={() => void 0}>
                        <Outlet />
                    </Container>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default DefaultLayout;
