import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import dayjs from 'dayjs';

import { isTheOwnerOfCommande } from 'utils/helpers';

import CommandeInterface from 'types/interfaces/commande.interface';
import Printer from 'components/shared/printer';
import SiteLogo from 'components/UI/siteLogo';

import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';

type Props = {
    commandes: CommandeInterface[];
};

const MultiCommandesBE: React.FC<Props> = ({ commandes }: Props) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    return (
        <Printer documentTitle="Bordereau d'envoi">
            <>
                <div className="d-flex justify-content-between m-1">
                    <SiteLogo />
                    <div className="text-end">
                        <div> Le {dayjs().format('DD/MM/YYYY HH:mm')}</div>
                        <div>
                            <span className="my-xsm-font">
                                <b>{commandes.length}</b> commandes au total
                            </span>
                        </div>
                    </div>{' '}
                </div>
                <div className="m-2">
                    <h6> Bordereau d&apos;envoi</h6>
                </div>
                <hr style={{ margin: 0 }} />
                <Table striped responsive>
                    <thead>
                        <tr>
                            <th>Ref/Code</th>
                            {/* <th>Acheteur</th> */}
                            <th>Client</th>
                            <th>GSM</th>
                            <th>Prix</th>
                            <th>Ville</th>
                            <th>Addresse</th>
                            <th>Produits</th>
                        </tr>
                    </thead>
                    <tbody>
                        {commandes.map((commande: CommandeInterface) => (
                            <tr key={commande.idCommande}>
                                <td>
                                    <b style={{ fontSize: '0.7rem' }}>{commande.code.replace('CMD', '')}</b>
                                </td>
                                {/* <td>
                                    <span className="my-xsm-font">{commande.acheteur?.fullName!}</span>
                                </td> */}
                                <td>
                                    <span className="my-xsm-font">
                                        {contextUser?.userData.type === 'Client' ||
                                        isTheOwnerOfCommande(contextUser?.userData?.idFournisseur!, commande)
                                            ? commande.client?.raisonSocial!
                                            : '****'}
                                    </span>
                                </td>
                                <td>
                                    <span className="my-xsm-font">{commande.acheteur?.gsm!}</span>
                                </td>
                                <td>
                                    <span className="my-xsm-font">{commande.prixTotal}</span>
                                </td>
                                <td>
                                    <span className="my-xsm-font">{commande.ville?.nomVille!}</span>
                                </td>
                                <td>
                                    <span className="my-xsm-font">{commande.adresse}</span>
                                </td>
                                <td>
                                    <span className="my-xsm-font">
                                        {commande.coliscommandes?.reduce((accumulator, colisCommande) => {
                                            return accumulator + colisCommande.qteCommande;
                                        }, 0) ?? 0}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </>
        </Printer>
    );
};

export default MultiCommandesBE;
