import React, { Dispatch, SetStateAction } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { Button } from 'react-bootstrap';

import { deleteDraftCommandesRange } from 'services/draftCommande.service';
import { toast } from 'react-toastify';
import DraftCommandeInterface from 'types/interfaces/draftCommande.interface';

type Props = {
    ids: number[];
    close: () => void;
    clear: () => void;
    setList: Dispatch<SetStateAction<DraftCommandeInterface[]>>;
};

const DeleteDraftCommandesForm: React.FC<Props> = ({ ids, close, clear, setList }: Props) => {
    const submit = (_: any, { setSubmitting }: FormikHelpers<any>) => {
        setSubmitting(true);
        deleteDraftCommandesRange(ids)
            .then(() => {
                toast.success('Les éléments sélectionnés ont été supprimés avec succès.');
                setList((prev: DraftCommandeInterface[]) =>
                    prev.filter((item: DraftCommandeInterface) => !ids.includes(item.idDraftCommande)),
                );
                clear();
                close();
            })
            .catch(() => {
                toast.error("Une erreur s'est produite");
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <>
            <p>Êtes-vous sûr de vouloir supprimer les éléments sélectionnés ? Cette action est irréversible !</p>
            <Formik initialValues={{ confirmDeletion: false }} onSubmit={submit}>
                {({ isSubmitting }) => (
                    <Form>
                        <div className="d-flex justify-content-center gap-2">
                            <Button variant="secondary" onClick={close}>
                                Annuler
                            </Button>
                            <Button type="submit" variant="danger" disabled={isSubmitting}>
                                Confirmer la Suppression
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default DeleteDraftCommandesForm;
