import React, { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Form, InputGroup, OverlayTrigger, Popover, Row, Spinner } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Formik, Field, FieldArray } from 'formik';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import Datetime from 'react-datetime';
import Compressor from 'compressorjs';

import { MAX_SIZE_IMAGE_UPLOAD } from 'constantes/envLoader';
import { AppContextState } from 'types';
import AppContext from 'utils/appContext';
import { ROLES as RolesMap } from 'constantes/permission-maps';
import { b64_to_utf8, useQueryMeMo, utf8_to_b64, readFileAsync, readImageTobase64 } from 'utils/helpers';
import ColisInterface from 'types/interfaces/colis.interface';
import ClientInterface from 'types/interfaces/client.interface';
import ProduitInterface from 'types/interfaces/produit.interface';
import ColisProduitEnvoyeInterface from 'types/interfaces/colisProduitEnvoye.interface';
import { getProduitByClientId, saveProduit } from 'services/produit.service';
import { getClientById } from 'services/client.service';
import { getWProduitById, saveColis, envoiColis, deleteColis } from 'services/colis.service';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import CustomModal from 'components/UI/customModal';
import ImageModal from 'components/UI/imageModal';
import PermissionsGate from 'hoc/permissionsGate';
import TitleElement from 'components/UI/titleElement';

const schema = yup.object().shape({
    idColis: yup.number(),
    idRepresenatant: yup.number().nullable(),
    idAgence: yup.number().nullable(),
    refColis: yup.string().nullable(),
    dateEnvoi: yup.date().nullable(),
    commentaireEnvoi: yup.string(),
    moyenTransport: yup.string().required(),
    dateRec: yup.date().nullable(),
    commentaire: yup.string(),
    aOuvrir: yup.boolean(),
    fragile: yup.boolean(),
    aRecuperer: yup.boolean(),
    dateEnregistrement: yup.date().nullable(),
    dateRecuPrevu: yup.date().required(),
    idClient: yup.number(),
    productDetails: yup.boolean(),
    colisproduitenvoyes: yup
        .array()
        .of(
            yup.object({
                idEnvoi: yup.number(),
                idColis: yup.number(),
                idProduit: yup.number().required(),
                quantiteEnvoi: yup.number().positive(),
                quantiteRecu: yup.number(),
                commentaire: yup.string(),
                envoiStatus: yup.boolean(),
            }),
        )
        .min(1)
        .required(),
    paye: yup.boolean(),
    montantEnvoi: yup.number().when('paye', {
        is: true,
        then: yup.number().required(),
    }),
    fileImage: yup.mixed(),
});

export const schemaProduit = yup.object({
    idProduit: yup.number(),
    idClient: yup.number().required(),
    ref: yup.string().required(),
    designation: yup.string().required(),
    prixVente: yup.number().moreThan(-1),
    poids: yup.number().moreThan(-1),
    longueur: yup.number().moreThan(-1),
    hauteur: yup.number().moreThan(-1),
    photo: yup.string().nullable(),
    etat: yup.boolean(),
});

const FormColisPage: React.FC = () => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    const [currentColis, setCurrentColis] = useState<ColisInterface | null>(null);
    const [idClient, setIdClient] = useState<number | null>(null);
    const [currentClient, setCurrentClient] = useState<ClientInterface | null>(null);
    const [produits, setProduits] = useState<ProduitInterface[]>([]);

    const [formEditMode, setFormEditMode] = useState<boolean>(false);
    const [spinIt, setSpinIt] = useState<boolean>(false);
    const [showFormModal, setShowFormModal] = useState<boolean>(false);
    const [showImageModal, setShowImageModal] = useState<boolean>(false);
    const [imageFile, setImageFile] = useState<Blob | null>(null);

    const formikRef = useRef<any>(null);

    const { id } = useParams();
    const navigate = useNavigate();
    const query = useQueryMeMo();

    const handleFormModalClose = () => {
        setShowFormModal(false);
    };

    const handleFormModalOpen = () => {
        setShowFormModal(true);
    };

    const handleImageModalClose = () => {
        setShowImageModal(false);
    };

    const handleImageModalOpen = (file: Blob) => {
        const rFile = readImageTobase64(file);
        setImageFile(rFile as Blob);
        setShowImageModal(true);
    };

    const selectProduit = (selected: ProduitInterface) => {
        const cpe = {
            idProduit: selected.idProduit,
            quantiteEnvoi: 1,
            qteResteAcmd: 1,
            quantiteRecu: 0,
            qteNonExpide: 0,
            commentaire: '',
            envoiStatus: false,
            produit: selected,
        };
        const data = [
            ...(formikRef?.current?.values.colisproduitenvoyes ? formikRef?.current?.values.colisproduitenvoyes : []),
            cpe,
        ];
        formikRef?.current?.handleChange({
            target: {
                value: data,
                name: 'colisproduitenvoyes',
            },
        });
        handleFormModalClose();
    };

    const submitDeleteColis = async () => {
        if (currentColis?.dateEnvoi) return;
        try {
            setSpinIt(true);
            const response = await deleteColis(currentColis?.idColis!);
            if (response.status === 200) {
                toast.success('Colis a été supprimée avec succès');
                navigate(`/colis`);
                setSpinIt(false);
            }
        } catch (Exception) {
            setSpinIt(false);
            toast.success('Désolé, Cette action ne peut pas être réalisée!');
        }
    };

    useEffect(() => {
        document.title = `${!!formEditMode ? 'Edit' : 'Ajout'} de Colis - DS`;

        const clientId = query.get('client');

        if (id) {
            const fixedId = +b64_to_utf8(id);
            getWProduitById(fixedId)
                .then((response: AxiosResponse<ColisInterface | any>) => {
                    const { data } = response;
                    setCurrentColis(data);
                    setIdClient(data.idClient);
                    setFormEditMode(true);
                })
                .catch(() => {
                    navigate('/colis');
                });
        } else {
            if (contextUser?.userData.type === 'Fournisseur') {
                if (clientId) {
                    setIdClient(+b64_to_utf8(clientId));
                } else navigate('/colis');
            } else {
                setIdClient(contextUser?.userData.idClient!);
            }
            setFormEditMode(false);
        }
    }, [id, query, contextUser]);

    useEffect(() => {
        if (idClient) {
            getClientById(idClient)
                .then((response: AxiosResponse<ClientInterface | any>) => {
                    const { data } = response;
                    setCurrentClient(data);
                })
                .catch();
            getProduitByClientId(idClient)
                .then((response: AxiosResponse<ProduitInterface[]>) => {
                    const { data } = response;
                    setProduits(data);
                    // const cpe = data.find((p: ProduitInterface) => p.ref === 'DP-X0000X');

                    // if (cpe && !formEditMode)
                    //     formikRef?.current?.handleChange({
                    //         target: {
                    //             value: [
                    //                 {
                    //                     idProduit: cpe?.idProduit,
                    //                     quantiteEnvoi: 1,
                    //                     qteResteAcmd: 1,
                    //                     quantiteRecu: 0,
                    //                     qteNonExpide: 0,
                    //                     commentaire: '',
                    //                     envoiStatus: false,
                    //                     produit: cpe,
                    //                 },
                    //             ],
                    //             name: 'colisproduitenvoyes',
                    //         },
                    //     });
                })
                .catch();
        }
    }, [idClient]);

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { path: '/colis ', text: 'Colis' },
        { active: true, text: !!formEditMode ? 'Edit' : 'Nouveau' },
    ];

    const submitProduitForm = async (produitValues: ProduitInterface | any) => {
        try {
            setSpinIt(true);
            const response = await saveProduit(produitValues);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Produit a été enregistré avec succès');
                const produitsData = [...produits].filter((dd) => dd.idProduit !== resultData.idProduit);
                produitsData.unshift(resultData);
                setProduits(produitsData);
                selectProduit(resultData);
                setSpinIt(false);
            }
        } catch (Exception) {
            setSpinIt(false);
            const { message = undefined }: any = Exception;
            toast.error(message || 'Error occured!');
        }
    };

    const submitEnvoiColis = async () => {
        if (currentColis?.dateEnvoi) return;
        try {
            setSpinIt(true);
            const response = await envoiColis(currentColis?.idColis!);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Colis a été envoyé avec succès');
                setCurrentColis(resultData);
                setSpinIt(false);
            }
        } catch (Exception) {
            setSpinIt(false);
        }
    };
    const submitForm = async (colisValues: ColisInterface | any) => {
        if (currentColis?.ramassage) return;
        setSpinIt(true);
        if (colisValues.fileImage) {
            try {
                if (colisValues.fileImage) {
                    const fileResult = await readFileAsync(colisValues.fileImage);
                    colisValues.fileImage =
                        (fileResult as string)?.replace(/^data:image\/(png|jpg|jpeg);base64,/, '') || null;
                }
            } catch (ex) {
                colisValues.fileImage = null;
            }
        }
        if (!formEditMode) colisValues.idClient = idClient!;
        try {
            const response = await saveColis(colisValues);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Colis a été enregistré avec succès');
                setCurrentColis(resultData);
                setSpinIt(false);
                navigate(`/colis/edit/${utf8_to_b64(resultData.idColis.toString())}`);
            }
        } catch (Exception) {
            setSpinIt(false);
            const { message = undefined }: any = Exception;
            toast.error(message || 'Error occured!');
        }
    };

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    {!!formEditMode ? 'Edit' : 'Nouveau'} Colis
                </TitleElement>
                {contextUser && contextUser.userData.type === 'Fournisseur' && (
                    <Row className="p-2">
                        <Col className="align-self-center" xs={3} sm={3} md={6} lg={6}>
                            Client
                        </Col>
                        <Col xs={9} sm={9} md={6} lg={6}>
                            <Form.Control
                                type="text"
                                value={currentClient?.raisonSocial!}
                                onChange={() => void 0}
                                readOnly
                            />
                        </Col>
                    </Row>
                )}
            </div>
            <Formik
                innerRef={formikRef}
                validationSchema={schema}
                onSubmit={(values) => submitForm(values)}
                enableReinitialize={!!formEditMode}
                initialValues={{
                    idColis: currentColis && !!formEditMode ? currentColis.idColis : undefined,
                    idRepresenatant:
                        currentColis && !!formEditMode
                            ? currentColis.idRepresenatant
                            : contextUser?.userData.type === 'Fournisseur'
                            ? undefined
                            : contextUser?.IdUser,
                    idClient: currentColis && !!formEditMode ? currentColis.idClient : undefined,
                    refColis: currentColis && !!formEditMode ? currentColis.refColis : '',
                    commentaire: currentColis && !!formEditMode ? currentColis.commentaire : '',
                    moyenTransport: currentColis && !!formEditMode ? currentColis.moyenTransport : '',
                    productDetails: true,
                    // currentColis && !!formEditMode
                    //     ? !(
                    //           currentColis.colisproduitenvoyes.length === 1 &&
                    //           currentColis.colisproduitenvoyes[0].produit?.ref === 'DP-X0000X'
                    //       )
                    //     : false,
                    fragile: currentColis && !!formEditMode ? !!currentColis.fragile : false,
                    aRecuperer: currentColis && !!formEditMode ? !!currentColis.aRecuperer : false,
                    aOuvrir: currentColis && !!formEditMode ? !!currentColis.aOuvrir : false,
                    dateEnregistrement:
                        currentColis && !!formEditMode
                            ? currentColis.dateEnregistrement
                                ? moment(currentColis.dateEnregistrement)
                                : undefined
                            : undefined,
                    dateRecuPrevu:
                        currentColis && !!formEditMode ? moment(currentColis.dateRecuPrevu) : moment().add(2, 'days'),
                    dateRec:
                        currentColis && !!formEditMode
                            ? currentColis.dateRec
                                ? moment(currentColis.dateRec)
                                : undefined
                            : undefined,
                    dateEnvoi:
                        currentColis && !!formEditMode
                            ? currentColis.dateEnvoi
                                ? moment(currentColis.dateEnvoi)
                                : undefined
                            : undefined,
                    colisproduitenvoyes: currentColis && !!formEditMode ? currentColis.colisproduitenvoyes : [],
                    paye: currentColis && !!formEditMode ? !!currentColis.paye : false,
                    montantEnvoi: currentColis && !!formEditMode ? currentColis.montantEnvoi : 0,
                    fileImage: null,
                }}
            >
                {({ setFieldValue, handleSubmit, handleChange, values, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        {values.idColis && (
                            <Form.Control type="hidden" name="idColis" value={values.idColis} readOnly={true} />
                        )}
                        <Form.Control type="hidden" name="idClient" value={values.idClient} readOnly={true} />
                        <Row>
                            <Col md="2" lg="2">
                                <InputGroup>
                                    Réf: &nbsp;
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        name="refColis"
                                        placeholder="RefColis"
                                        value={values.refColis}
                                        onChange={handleChange}
                                        disabled={!!currentColis?.dateEnvoi}
                                    />
                                </InputGroup>
                            </Col>
                            <Form.Group as={Col} md="2" lg="2" sm="4" controlId="form_grp_fragile">
                                <Form.Check
                                    type="checkbox"
                                    label="Fragile"
                                    name="fragile"
                                    checked={values.fragile}
                                    onChange={handleChange}
                                    isInvalid={!!errors.fragile}
                                    disabled={!!currentColis?.dateEnvoi}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="2" lg="2" sm="4" controlId="form_grp_aOuvrir">
                                <Form.Check
                                    type="checkbox"
                                    label="À Ouvrir"
                                    name="aOuvrir"
                                    checked={values.aOuvrir}
                                    onChange={handleChange}
                                    isInvalid={!!errors.aOuvrir}
                                    disabled={!!currentColis?.dateEnvoi}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="2" lg="2" sm="4" controlId="form_grp_aRecuperer">
                                <Form.Check
                                    type="checkbox"
                                    label="À Récupérer"
                                    name="aRecuperer"
                                    checked={values.aRecuperer}
                                    onChange={handleChange}
                                    isInvalid={!!errors.aRecuperer}
                                    disabled={!!currentColis?.dateEnvoi}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" lg="4" controlId="form_grp_commentaire">
                                <Form.Control
                                    size="sm"
                                    as="textarea"
                                    rows={2}
                                    name="commentaire"
                                    placeholder="Commentaire"
                                    value={values.commentaire}
                                    onChange={handleChange}
                                    isInvalid={!!errors.commentaire}
                                    disabled={!!currentColis?.dateEnvoi}
                                />
                            </Form.Group>
                        </Row>
                        {!!formEditMode && (
                            <Row>
                                <Form.Group as={Col} md="3" lg="3" controlId="form_grp_dateEnregistrement">
                                    <Form.Label>Date d&apos;Enregistrement</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        name="dateEnregistrement"
                                        value={
                                            values.dateEnregistrement
                                                ? values.dateEnregistrement.format('DD/MM/YYYY HH:mm')
                                                : '-'
                                        }
                                        onChange={() => void 0}
                                        readOnly
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="3" lg="3" controlId="form_grp_dateEnvoi">
                                    <Form.Label>Date d&apos;Envoi</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        name="dateEnvoi"
                                        value={values.dateEnvoi ? values.dateEnvoi.format('DD/MM/YYYY HH:mm') : '-'}
                                        onChange={() => void 0}
                                        readOnly
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="3" lg="3" controlId="form_grp_dateRamassage">
                                    <Form.Label>Date de Ramassage</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        name="dateRamassage"
                                        value={
                                            currentColis?.ramassage?.dateRamassage
                                                ? moment(currentColis.ramassage.dateRamassage!).format(
                                                      'DD/MM/YYYY HH:mm',
                                                  )
                                                : '-'
                                        }
                                        onChange={() => void 0}
                                        readOnly
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="3" lg="3" controlId="form_grp_dateRec">
                                    <Form.Label>Date de réception</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        name="dateRec"
                                        value={values.dateRec ? values.dateRec.format('DD/MM/YYYY HH:mm') : '-'}
                                        onChange={() => void 0}
                                        readOnly
                                    />
                                </Form.Group>
                            </Row>
                        )}
                        <Row>
                            <Form.Group as={Col} md="2" controlId="form_grp_dateRecuPrevu">
                                <Form.Label>Date de réception prévu</Form.Label>
                                <Datetime
                                    className="form-control form-control-sm no_form-control_input_style"
                                    inputProps={{
                                        name: 'dateRecuPrevu',
                                        className: `form-control form-control-sm ${
                                            !!errors.dateRecuPrevu ? 'is-invalid' : ''
                                        }`,
                                        readOnly: true,
                                    }}
                                    timeFormat={false}
                                    dateFormat="DD/MM/YYYY"
                                    value={values.dateRecuPrevu}
                                    onChange={(dateValue: any) => {
                                        const e = {
                                            target: {
                                                value: dateValue,
                                                name: 'dateRecuPrevu',
                                            },
                                        };
                                        if (moment.isMoment(dateValue)) handleChange(e);
                                    }}
                                    closeOnSelect={true}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="2" controlId="form_grp_moyenTransport">
                                <Form.Label>Moyen de Transport</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    name="moyenTransport"
                                    placeholder="Moyen de Transport"
                                    value={values.moyenTransport}
                                    onChange={handleChange}
                                    isInvalid={!!errors.moyenTransport}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="2" controlId="form_grp_paye">
                                <Form.Label>Payé</Form.Label>
                                <Form.Check
                                    type="checkbox"
                                    name="paye"
                                    checked={values.paye}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleChange({
                                            target: {
                                                value: 0,
                                                name: 'montantEnvoi',
                                            },
                                        });
                                    }}
                                    isInvalid={!!errors.paye}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="2" controlId="form_grp_montentEnvoi">
                                <Form.Label>Montant d&apos;envoi</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="number"
                                    min={0}
                                    name="montantEnvoi"
                                    placeholder="Montant d'envoi"
                                    value={values.montantEnvoi}
                                    onChange={handleChange}
                                    isInvalid={!!errors.montantEnvoi}
                                    readOnly={!values.paye}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="form_grp_fileImage">
                                <Form.Label>Recu</Form.Label>
                                <InputGroup size="sm">
                                    <Form.Control
                                        size="sm"
                                        type="file"
                                        name="fileImage"
                                        placeholder="Recu"
                                        onChange={(event: any) => {
                                            const image = event.currentTarget.files[0];
                                            if (image.size > MAX_SIZE_IMAGE_UPLOAD) {
                                                toast.warning(
                                                    'vous ne pouvez pas télécharger une image de plus de 1mo',
                                                );
                                                return;
                                            } else if (!image.name.match(/.(png|jpg|jpeg)$/i)) {
                                                toast.warning("Image Format n'est pas valide");
                                                return;
                                            }
                                            // Compress the image
                                            new Compressor(image, {
                                                quality: 0.8, // Adjust compression quality as needed
                                                success: (compressedImage) => {
                                                    console.log(image.size, compressedImage.size);
                                                    setFieldValue('fileImage', compressedImage);
                                                },
                                                error: () => {
                                                    toast.warning("Erreur lors de la compression de l'image");
                                                },
                                            });
                                            // setFieldValue('fileImage', image);
                                        }}
                                        isInvalid={!!errors.fileImage}
                                        disabled={!!currentColis?.ramassage}
                                        accept="image/png, image/jpg, image/jpeg"
                                    />
                                    {(values.fileImage || currentColis?.colisRecuFile?.fileImage!) && (
                                        <Button
                                            variant="outline-secondary"
                                            size="sm"
                                            onClick={() =>
                                                handleImageModalOpen(
                                                    values.fileImage! ?? currentColis?.colisRecuFile?.fileImage!,
                                                )
                                            }
                                        >
                                            Afficher
                                        </Button>
                                    )}
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Col md="8" lg="8" className="align-self-center">
                                <h5>Détails des articles</h5>
                            </Col>
                            {/* <Form.Group as={Col} md="8" lg="8" controlId="form_grp_details">
                                <Form.Check
                                    type="checkbox"
                                    label="Détails des articles"
                                    name="productDetails"
                                    checked={values.productDetails}
                                    onChange={(e: any) => {
                                        handleChange(e);
                                        if (
                                            e.target.checked &&
                                            values.colisproduitenvoyes.length === 1 &&
                                            values.colisproduitenvoyes[0].produit?.ref === 'DP-X0000X'
                                        ) {
                                            handleChange({
                                                target: {
                                                    value: [],
                                                    name: 'colisproduitenvoyes',
                                                },
                                            });
                                        }
                                    }}
                                    style={
                                        values.colisproduitenvoyes && values.colisproduitenvoyes.length > 0
                                            ? {}
                                            : { color: 'red' }
                                    }
                                />
                            </Form.Group> */}

                            {!!values.productDetails && (
                                <PermissionsGate scopes={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.CLIENT_ADMIN]} strict>
                                    <Col md="4" lg="4" className="float-end mt-2 clearfix">
                                        <Button
                                            className="float-end"
                                            variant="outline-secondary"
                                            onClick={() => handleFormModalOpen()}
                                            disabled={!!currentColis?.dateEnvoi}
                                        >
                                            Add Article
                                        </Button>
                                    </Col>
                                </PermissionsGate>
                            )}
                        </Row>
                        <InputGroup
                            className={`mt-1 header-multi-produit ${!!values.productDetails ? '' : 'd-none'} ${
                                values.colisproduitenvoyes && values.colisproduitenvoyes.length > 0
                                    ? ''
                                    : 'inputGroup-fields-border-error'
                            }`}
                        >
                            <Field
                                name={`header.produit.ref`}
                                type="text"
                                value="Ref"
                                className="form-control"
                                readOnly
                            />
                            <Field
                                name={`header.produit.designation`}
                                type="text"
                                value="Designation"
                                className="form-control"
                                readOnly
                            />
                            <Field
                                name={`header.produit.prixVente`}
                                type="text"
                                value="PrixVente"
                                className="form-control"
                                readOnly
                            />
                            <Field
                                name={`header.quantity`}
                                type="text"
                                value="Quantity"
                                className="form-control"
                                readOnly
                            />
                            {values.dateRec && (
                                <Field
                                    name={`header.quantity`}
                                    type="text"
                                    value="Quantity Recu"
                                    className="form-control"
                                    readOnly
                                />
                            )}
                            <Button
                                variant="outline-warning"
                                className="inputGrpButton dynamicJsHoverColorIcon"
                                onClick={() => void 0}
                            >
                                <FontAwesomeIcon
                                    className="invisible"
                                    icon={faMinus}
                                    size="xs"
                                    style={{ color: '#ff0000' }}
                                />
                            </Button>
                        </InputGroup>
                        <FieldArray
                            name="colisproduitenvoyes"
                            render={() =>
                                values.colisproduitenvoyes &&
                                values.colisproduitenvoyes.map((rnData, index) => {
                                    const rnErrors =
                                        errors.colisproduitenvoyes?.length && errors.colisproduitenvoyes[index];
                                    return (
                                        <InputGroup
                                            key={index}
                                            className={`${index === 0 ? 'body-multi-produit-first' : ''} ${
                                                !!values.productDetails ? '' : 'd-none'
                                            }`}
                                        >
                                            <Field
                                                name={`colisproduitenvoyes[${index}].produit.ref`}
                                                type="text"
                                                placeholder="Ref"
                                                className={`form-control ${!!rnErrors ? ' is-invalid' : ''}`}
                                                readOnly
                                            />
                                            <Field
                                                name={`colisproduitenvoyes[${index}].produit.designation`}
                                                type="text"
                                                placeholder="Designation"
                                                className={`form-control ${!!rnErrors ? ' is-invalid' : ''}`}
                                                readOnly
                                            />
                                            <Field
                                                name={`colisproduitenvoyes[${index}].produit.prixVente`}
                                                type="text"
                                                placeholder="Prix"
                                                className={`form-control ${!!rnErrors ? ' is-invalid' : ''}`}
                                                readOnly
                                            />
                                            <Field
                                                name={`colisproduitenvoyes[${index}].quantiteEnvoi`}
                                                type="number"
                                                min={1}
                                                placeholder="Quantity"
                                                className={`form-control ${!!rnErrors ? ' is-invalid' : ''}`}
                                                disabled={!!currentColis?.dateEnvoi}
                                            />
                                            {values.dateRec && (
                                                <Field
                                                    name={`colisproduitenvoyes[${index}].quantiteRecu`}
                                                    type="number"
                                                    min={1}
                                                    placeholder="Quantity"
                                                    className={`form-control ${!!rnErrors ? ' is-invalid' : ''}`}
                                                    readOnly
                                                />
                                            )}
                                            <Button
                                                variant="outline-warning"
                                                className="inputGrpButton dynamicJsHoverColorIcon"
                                                onClick={() => {
                                                    const updatedArr = [...values.colisproduitenvoyes].filter(
                                                        (dvr, i) => i !== index,
                                                    );

                                                    handleChange({
                                                        target: {
                                                            value: updatedArr,
                                                            name: 'colisproduitenvoyes',
                                                        },
                                                    });
                                                }}
                                                disabled={!!currentColis?.dateEnvoi}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faMinus}
                                                    size="xs"
                                                    style={{ color: '#ff0000' }}
                                                />
                                            </Button>
                                        </InputGroup>
                                    );
                                })
                            }
                        />

                        <PermissionsGate scopes={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.CLIENT_ADMIN]} strict>
                            <div className="gap-2 mt-3 float-end">
                                {!currentColis?.idRamassage && (
                                    <Button variant="primary" type="submit" disabled={!!spinIt}>
                                        Enregistrer
                                        {!!spinIt && (
                                            <>
                                                &nbsp; <Spinner animation="border" size="sm" />
                                            </>
                                        )}
                                    </Button>
                                )}
                                {currentColis && !currentColis?.dateEnvoi && (
                                    <>
                                        <Button
                                            className="ms-2"
                                            variant="outline-primary"
                                            type="button"
                                            disabled={!!spinIt}
                                            onClick={() => submitEnvoiColis()}
                                        >
                                            Envoyer
                                            {!!spinIt && (
                                                <>
                                                    &nbsp; <Spinner animation="border" size="sm" />
                                                </>
                                            )}
                                        </Button>
                                        <OverlayTrigger
                                            trigger="focus"
                                            placement="top"
                                            overlay={
                                                <Popover id="popover-basic">
                                                    <Popover.Header>Êtes-vous sûr??</Popover.Header>
                                                    <Popover.Body className="text-center">
                                                        <Button
                                                            className="ms-2"
                                                            variant="danger-primary"
                                                            type="button"
                                                            disabled={!!spinIt}
                                                            onClick={() => submitDeleteColis()}
                                                        >
                                                            Confirmer
                                                            {!!spinIt && (
                                                                <>
                                                                    &nbsp; <Spinner animation="border" size="sm" />
                                                                </>
                                                            )}
                                                        </Button>
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <Button size="sm" variant="danger" disabled={!!spinIt}>
                                                Supprimer
                                            </Button>
                                        </OverlayTrigger>
                                    </>
                                )}
                                {currentColis &&
                                    currentColis?.colisproduitenvoyes?.some(
                                        (d: ColisProduitEnvoyeInterface) => d.qteResteAcmd! > 0,
                                    )! &&
                                    currentColis?.dateEnvoi! != null && (
                                        <Button
                                            className="ms-2"
                                            variant="outline-success"
                                            type="button"
                                            disabled={!!spinIt}
                                            onClick={() =>
                                                navigate(
                                                    `/commandes/ready-colis?target=${utf8_to_b64(
                                                        currentColis?.idColis?.toString()!,
                                                    )}`,
                                                )
                                            }
                                        >
                                            Passez commande
                                            {!!spinIt && (
                                                <>
                                                    &nbsp; <Spinner animation="border" size="sm" />
                                                </>
                                            )}
                                        </Button>
                                    )}
                            </div>
                        </PermissionsGate>
                    </Form>
                )}
            </Formik>
            <CustomModal title="Add Article" show={showFormModal} handleClose={handleFormModalClose} size="lg">
                <Formik
                    validationSchema={schemaProduit}
                    onSubmit={(values) => submitProduitForm(values)}
                    enableReinitialize={true}
                    initialValues={{
                        idProduit: undefined,
                        idClient: idClient!,
                        ref: `P${moment().valueOf()}-C${idClient!}`,
                        designation: '',
                        prixVente: 0,
                        poids: 0,
                        longueur: 0,
                        hauteur: 0,
                        photo: null,
                        etat: true,
                    }}
                >
                    {({ handleSubmit, handleChange, values, errors }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            {values.idProduit && (
                                <Form.Control type="hidden" name="idProduit" value={values.idProduit} readOnly={true} />
                            )}
                            <Row>
                                <Form.Group as={Col} md="6" controlId="form_grp_ref">
                                    <Form.Label>Ref</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        name="ref"
                                        placeholder="Réf produit"
                                        value={values.ref}
                                        onChange={() => void 0}
                                        disabled
                                    />
                                    {/* <Typeahead
                                        size="sm"
                                        id="basic-ref"
                                        labelKey="ref"
                                        options={produits.filter(
                                            (x: ProduitInterface) =>
                                                x.ref !== 'DP-X0000X' &&
                                                !formikRef?.current?.values.colisproduitenvoyes.find(
                                                    (y: ColisProduitEnvoyeInterface) => y.idProduit === x.idProduit,
                                                ),
                                        )}
                                        placeholder="Réf"
                                        highlightOnlyResult
                                        minLength={2}
                                        onChange={(selected: any[]) => {
                                            if (selected && selected.length > 0) {
                                                selectProduit(selected[0] as ProduitInterface);
                                            }
                                        }}
                                        onInputChange={(term: string) => {
                                            handleChange({
                                                target: { value: term, name: 'ref' },
                                            });
                                        }}
                                        disabled
                                    /> */}
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="form_grp_poids">
                                    <Form.Label>Poids (KG)</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="number"
                                        min={0}
                                        name="poids"
                                        placeholder="Poids"
                                        value={values.poids}
                                        onChange={handleChange}
                                        isInvalid={!!errors.poids}
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} md="6" controlId="form_grp_designation">
                                    <Form.Label>Designation</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        as="textarea"
                                        rows={2}
                                        name="designation"
                                        placeholder="Designation"
                                        value={values.designation}
                                        onChange={handleChange}
                                        isInvalid={!!errors.designation}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="form_grp_longeur">
                                    <Form.Label>Longeur (CM)</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="number"
                                        min={0}
                                        name="longueur"
                                        placeholder="Longeur"
                                        value={values.longueur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.longueur}
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} md="6" controlId="form_grp_prixVente">
                                    <Form.Label>PrixVente</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="number"
                                        min={1}
                                        name="prixVente"
                                        placeholder="PrixVente"
                                        value={values.prixVente}
                                        onChange={handleChange}
                                        isInvalid={!!errors.prixVente}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="form_grp_hauteur">
                                    <Form.Label>Largeur (CM)</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="number"
                                        min={0}
                                        name="hauteur"
                                        placeholder="Largeur"
                                        value={values.hauteur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.hauteur}
                                    />
                                </Form.Group>
                            </Row>
                            <div className="d-grid gap-2 mt-3">
                                <Button variant="primary" type="submit" disabled={!!spinIt}>
                                    Enregistrer
                                    {!!spinIt && (
                                        <>
                                            &nbsp; <Spinner animation="border" size="sm" />
                                        </>
                                    )}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </CustomModal>
            <ImageModal
                title="Reçu"
                file={imageFile}
                alt="file recu"
                show={showImageModal}
                handleClose={handleImageModalClose}
                fluid
            />
        </>
    );
};

export default FormColisPage;
