import React from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

type Props = {
    size?: 'sm' | 'lg';
    variant?: string;
    excelData: any[];
    fileName: string;
};

const ExportExcel: React.FC<Props> = ({ size, variant, excelData, fileName }: Props) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8';
    const fileExtention = '.xlsx';
    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${fileName}${fileExtention}`);
    };
    return (
        <>
            <OverlayTrigger overlay={<Tooltip id="export_to_excel">Export Liste to EXCEL</Tooltip>}>
                <Button size={size} variant={variant} onClick={() => exportToExcel()}>
                    Excel Export
                </Button>
            </OverlayTrigger>
        </>
    );
};

export default ExportExcel;
