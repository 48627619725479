import { createContext, useContext } from 'react';
import { AppContextState } from 'types/index';

const AppContext = createContext<AppContextState>({} as AppContextState);

const useAuth = () => {
    return useContext(AppContext);
};

export { AppContext, useAuth };

export default AppContext;
