import React, { useState } from 'react';
import * as yup from 'yup';
import moment from 'moment';
import { Col, Form, Row, Button, Spinner, InputGroup, Table } from 'react-bootstrap';
import Datetime from 'react-datetime';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import CommandeInterface from 'types/interfaces/commande.interface';
import FactureInterface from 'types/interfaces/facture.interface';
import { addFactureCommandes } from 'services/facture.service';

const schemaFacture = yup.object({
    refFacture: yup.string().required(),
    dateFacture: yup.date().required(),
    montantFacture: yup.number().positive().required(),
    remiseAccorde: yup
        .number()
        .moreThan(-1)
        .lessThan(yup.ref('montantFacture'), 'montantFacture devrait être > remiseAccorde')
        .required(),
});

type Props = {
    commandes: { items: CommandeInterface[]; count: number };
    setCommandes: (value: { items: CommandeInterface[]; count: number }) => void;
    checkedCommandes: number[];
    setCheckedCommandes: (value: number[]) => void;
    selectedClient: string | null;
    handleFactureFormModalClose: () => void;
};

const FactureCommandesFormComponent: React.FC<Props> = ({
    commandes,
    setCommandes,
    checkedCommandes,
    setCheckedCommandes,
    selectedClient,
    handleFactureFormModalClose,
}: Props) => {
    const [spinIt, setSpinIt] = useState<boolean>(false);

    const submitFactureForm = async (values: FactureInterface | any) => {
        try {
            setSpinIt(true);
            const response = await addFactureCommandes({
                ids: checkedCommandes,
                facture: values,
            });
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Facture a été créee.');
                const cmdData = [...commandes.items];
                commandes.items
                    .filter((d: CommandeInterface) => checkedCommandes.includes(d.idCommande))
                    .forEach((element: CommandeInterface) => {
                        const cmdIndex = cmdData.findIndex(
                            (di: CommandeInterface) => di.idCommande == element.idCommande,
                        );
                        cmdData[cmdIndex].idFacture = resultData.idFacture;
                        cmdData[cmdIndex].facture = resultData;
                    });
                setCommandes({ items: cmdData, count: cmdData.length });
                setCheckedCommandes([]);
                setSpinIt(false);
                handleFactureFormModalClose();
            }
        } catch (Exception) {
            setSpinIt(false);
        }

        setSpinIt(false);
    };
    return (
        <div>
            <Formik
                validationSchema={schemaFacture}
                onSubmit={(values) => submitFactureForm(values)}
                enableReinitialize={true}
                initialValues={{
                    refFacture: `FCMD${selectedClient!}-${moment().valueOf()}`,
                    dateFacture: moment(),
                    montantFacture: commandes.items
                        .filter((d: CommandeInterface) => checkedCommandes.includes(d.idCommande))
                        .reduce((accumulator: number, object: CommandeInterface) => {
                            return accumulator + object.prixTotal!;
                        }, 0),
                    remiseAccorde: 0,
                }}
            >
                {({ handleSubmit, handleChange, values, errors }) => (
                    <Form noValidate onSubmit={handleSubmit} className="d-grid gap-2">
                        <Row>
                            <Col md="6">
                                <Row className="align-items-center">
                                    <Col auto>
                                        <Form.Label>Réf:</Form.Label>
                                    </Col>
                                    <Col auto>
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            name="refFacture"
                                            value={values.refFacture}
                                            onChange={() => void 0}
                                            readOnly
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="6">
                                <Row className="align-items-center">
                                    <Col auto>
                                        <Form.Label>Date:</Form.Label>
                                    </Col>
                                    <Col auto>
                                        <Datetime
                                            className="form-control form-control-sm no_form-control_input_style"
                                            inputProps={{
                                                name: 'dateFacture',
                                                className: `text-end form-control form-control-sm ${
                                                    !!errors.dateFacture ? 'is-invalid' : ''
                                                }`,
                                                readOnly: true,
                                            }}
                                            timeFormat={false}
                                            dateFormat="DD/MM/YYYY"
                                            value={values.dateFacture}
                                            onChange={(dateValue: any) => {
                                                const e = {
                                                    target: {
                                                        value: dateValue,
                                                        name: 'dateFacture',
                                                    },
                                                };

                                                if (moment.isMoment(dateValue)) handleChange(e);
                                            }}
                                            closeOnSelect={true}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <Row className="align-items-center">
                                    <Col auto>
                                        <Form.Label>Montant:</Form.Label>
                                    </Col>
                                    <Col auto>
                                        <InputGroup>
                                            <Form.Control
                                                className="text-end"
                                                type="number"
                                                name="montantFacture"
                                                value={values.montantFacture}
                                                onChange={() => void 0}
                                                readOnly
                                            />
                                            <InputGroup.Text>DH</InputGroup.Text>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="6">
                                <Row className="align-items-center">
                                    <Col auto>
                                        <Form.Label>Remise:</Form.Label>
                                    </Col>
                                    <Col auto>
                                        <InputGroup>
                                            <Form.Control
                                                className="text-end"
                                                type="number"
                                                name="remiseAccorde"
                                                min={0}
                                                value={values.remiseAccorde}
                                                max={values.montantFacture}
                                                onChange={handleChange}
                                                isInvalid={!!errors.remiseAccorde}
                                            />
                                            <InputGroup.Text>DH</InputGroup.Text>
                                        </InputGroup>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.remiseAccorde}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <h6>Détails:</h6>
                        <Table striped responsive size="sm">
                            <thead>
                                <tr>
                                    <th>Code</th>
                                    <th>Ville</th>
                                    <th>Acheteur</th>
                                    <th>Date de livraison</th>
                                    <th>Frais</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {commandes.items
                                    .filter((d: CommandeInterface) => checkedCommandes.includes(d.idCommande))
                                    .map((d: CommandeInterface) => (
                                        <tr key={d.idCommande}>
                                            <td>
                                                <b>{d.code}</b>
                                            </td>
                                            <td>{d.ville?.nomVille}</td>
                                            <td>
                                                <b>{d.acheteur?.fullName!}</b>
                                                <br />
                                                {d.acheteur?.gsm}
                                            </td>
                                            <td>{moment(d.dateOperation).format('DD/MM/YYYY HH:mm')}</td>
                                            <td>
                                                {(d.commandetarifs ?? []).reduce((accumulator: number, object: any) => {
                                                    return accumulator + object.tarif?.prixTarif;
                                                }, 0)}
                                            </td>
                                            <td>{d.prixTotal!}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                        <div className="d-grid gap-2">
                            <Button variant="primary" type="submit" disabled={!!spinIt}>
                                Enregistrer
                                {!!spinIt && (
                                    <>
                                        &nbsp; <Spinner animation="border" size="sm" />
                                    </>
                                )}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default FactureCommandesFormComponent;
