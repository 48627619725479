import React, { useState } from 'react';
import styled from 'styled-components';

type Props = {
    children: any;
    text: string;
};

const Container = styled.div<{ isHovered: boolean }>`
    display: inline-block;
    cursor: pointer;
    padding: 5px;
    // border: 1px solid #ccc;
    border-radius: 4px;
    background-color: ${({ isHovered }) => (isHovered ? '#f0f0f0' : '#fff')};
    position: relative;
`;

const CopyIcon = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    margin-left: 8px;
    font-size: 14px;
    color: #999;
`;

const CopiedIndicator = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    background-color: #4caf50;
    color: #fff;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
`;

const CopyTextToClipboardComponent: React.FC<Props> = ({ children, text }: Props) => {
    const [isHovered, setIsHovered] = useState(false);
    const [copied, setCopied] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        setCopied(false);
    };

    const handleClick = () => {
        const textToCopy = text;
        navigator.clipboard.writeText(textToCopy).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset state after 2 seconds
        });
    };

    return (
        <Container
            isHovered={isHovered}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
        >
            {children}
            {isHovered && <CopyIcon>📋</CopyIcon>}
            {copied && <CopiedIndicator>Copié !</CopiedIndicator>}
        </Container>
    );
};

export default CopyTextToClipboardComponent;
