import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { AxiosResponse } from 'axios';
import { Alert, Badge, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckArrowRight, faTruckRampBox } from '@fortawesome/free-solid-svg-icons';

import { isTheLastTransfertIndex } from 'utils/helpers';
import TransfertCommandeInterface from 'types/interfaces/transfertCommande.interface';
import CommandeInterface from 'types/interfaces/commande.interface';
import { getCommandeById } from 'services/commande.service';
import { putCancel, putReturnConfirmed, putReturn, postReturn } from 'services/transfert.service';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';

type Props = {
    commande: CommandeInterface;
    commandes: { items: CommandeInterface[]; count: number };
    updateCommande: (data: CommandeInterface) => void;
    updateCommandesList: (data: { items: CommandeInterface[]; count: number }) => void;
};
const TransfertInfo: React.FC<Props> = ({ commande, commandes, updateCommande, updateCommandesList }: Props) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    const [sourceTransfert, setSourceTransfert] = useState<TransfertCommandeInterface | null>(null);
    const [destinataireTransfert, setDestinataireTransfert] = useState<TransfertCommandeInterface | null>(null);
    const [spinIt, setSpinIt] = useState<boolean>(false);

    const actionToDo = async (actionType: string, id: number) => {
        switch (actionType) {
            case 'cancel':
                return await putCancel(id);
            case 'request-return':
                return await postReturn(id);
            case 'return':
                return await putReturn(id);
            case 'confirm-return':
                return await putReturnConfirmed(id);
        }
    };

    const submitForm = async (actionType: string, id: number) => {
        try {
            setSpinIt(true);
            const response = await actionToDo(actionType, id);
            if (response.status === 200 || response.status === 204) {
                toast.success('Action est executée avec succès');
                getCommandeById(commande.idCommande)
                    .then((response: AxiosResponse<CommandeInterface | any>) => {
                        const { data: resultData } = response;
                        updateCommande(resultData);
                        setSpinIt(false);
                        const updatedCmd = [...commandes.items];
                        const index = updatedCmd.findIndex((p) => p.idCommande === resultData.idCommande);
                        updatedCmd[index] = resultData;
                        updateCommandesList({ items: updatedCmd, count: commandes.count });
                    })
                    .catch(() => {
                        toast.error("Erreur est survenue lors de l'actualisation des informations");
                    });
            }
        } catch (Exception) {
            setSpinIt(false);
            const { message = undefined }: any = Exception;
            toast.error(message || 'Error occured!');
        }
    };

    useEffect(() => {
        if (contextUser) {
            const source = commande.transfertCommandes?.find(
                (item: TransfertCommandeInterface) => item.idFournisseurSource === contextUser?.userData?.idFournisseur,
            );
            const destinataire = commande.transfertCommandes?.find(
                (item: TransfertCommandeInterface) =>
                    item.idFournisseurDestinataire === contextUser?.userData?.idFournisseur,
            );

            setSourceTransfert(source ?? null);
            setDestinataireTransfert(destinataire ?? null);
        }
    }, [contextUser, commande]);

    return (
        <>
            {sourceTransfert && (
                <Alert variant="info">
                    <div className="d-flex justify-content-start align-items-center gap-2 mb-1">
                        <FontAwesomeIcon icon={faTruckArrowRight} size="lg" />
                        <strong>Commande transférée à :</strong>
                    </div>
                    <div className="d-flex flex-wrap-reverse gap-1 justify-content-between pure-align-items-end">
                        <Badge bg="success" className="order-2">
                            {sourceTransfert?.fournisseurDestinataire?.raisonSocial!} - {` `}
                            {moment(sourceTransfert?.dateCreation).format('DD/MM/YYYY HH:mm')}
                        </Badge>
                        {commande.idEtatCommande! < 4 && (
                            <div className="order-1">
                                {sourceTransfert.received === false && (
                                    <OverlayTrigger
                                        trigger="focus"
                                        placement="top"
                                        overlay={
                                            <Popover id="popover-basic">
                                                <Popover.Header>Êtes-vous sûr??</Popover.Header>
                                                <Popover.Body className="text-center">
                                                    <Button
                                                        variant="warning"
                                                        size="sm"
                                                        onClick={() =>
                                                            submitForm('cancel', sourceTransfert.idTransfertCommande)
                                                        }
                                                        disabled={spinIt}
                                                    >
                                                        Confirmer
                                                    </Button>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <Button size="sm" variant="warning" disabled={!!spinIt}>
                                            Annuler
                                        </Button>
                                    </OverlayTrigger>
                                )}
                                {sourceTransfert.received === true && sourceTransfert.returned === -1 && (
                                    <OverlayTrigger
                                        trigger="focus"
                                        placement="top"
                                        overlay={
                                            <Popover id="popover-basic">
                                                <Popover.Header>Êtes-vous sûr??</Popover.Header>
                                                <Popover.Body className="text-center">
                                                    <Button
                                                        variant="warning"
                                                        size="sm"
                                                        onClick={() =>
                                                            submitForm('request-return', sourceTransfert.idCommande)
                                                        }
                                                        disabled={!!spinIt}
                                                    >
                                                        Confirmer
                                                    </Button>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <Button size="sm" variant="warning" disabled={!!spinIt}>
                                            Demander le retour
                                        </Button>
                                    </OverlayTrigger>
                                )}
                                {sourceTransfert.received === true &&
                                    sourceTransfert.returned === 1 &&
                                    isTheLastTransfertIndex(
                                        sourceTransfert.idTransfertCommande,
                                        commande.transfertCommandes!,
                                    ) && (
                                        <OverlayTrigger
                                            trigger="focus"
                                            placement="top"
                                            overlay={
                                                <Popover id="popover-basic">
                                                    <Popover.Header>Êtes-vous sûr??</Popover.Header>
                                                    <Popover.Body className="text-center">
                                                        <Button
                                                            variant="warning"
                                                            size="sm"
                                                            onClick={() =>
                                                                submitForm(
                                                                    'confirm-return',
                                                                    sourceTransfert.idTransfertCommande,
                                                                )
                                                            }
                                                            disabled={spinIt}
                                                        >
                                                            Confirmer
                                                        </Button>
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <Button size="sm" variant="warning" disabled={!!spinIt}>
                                                Confirmer le retour
                                            </Button>
                                        </OverlayTrigger>
                                    )}
                            </div>
                        )}
                    </div>
                </Alert>
            )}
            {destinataireTransfert && (
                <Alert variant="light" className="border border-info">
                    <div className="d-flex justify-content-start align-items-center gap-2 mb-1">
                        <FontAwesomeIcon icon={faTruckRampBox} size="lg" />
                        <strong>Commande provient de :</strong>
                    </div>
                    <div className="d-flex flex-wrap-reverse gap-1 justify-content-between pure-align-items-end">
                        <Badge bg="success" className="order-2">
                            {destinataireTransfert?.fournisseurSource?.raisonSocial!} - {` `}
                            {moment(destinataireTransfert?.dateCreation).format('DD/MM/YYYY HH:mm')}
                        </Badge>
                        {commande.idEtatCommande! < 4 && (
                            <div className="order-1">
                                {destinataireTransfert.received === true &&
                                    destinataireTransfert.returned === 0 &&
                                    isTheLastTransfertIndex(
                                        destinataireTransfert.idTransfertCommande,
                                        commande.transfertCommandes!,
                                    ) && (
                                        <OverlayTrigger
                                            trigger="focus"
                                            placement="top"
                                            overlay={
                                                <Popover id="popover-basic">
                                                    <Popover.Header>Êtes-vous sûr??</Popover.Header>
                                                    <Popover.Body className="text-center">
                                                        <Button
                                                            variant="warning"
                                                            size="sm"
                                                            onClick={() =>
                                                                submitForm(
                                                                    'return',
                                                                    destinataireTransfert.idTransfertCommande,
                                                                )
                                                            }
                                                            disabled={spinIt}
                                                        >
                                                            Confirmer
                                                        </Button>
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <Button size="sm" variant="warning" disabled={!!spinIt}>
                                                Retourner
                                            </Button>
                                        </OverlayTrigger>
                                    )}
                            </div>
                        )}
                    </div>
                </Alert>
            )}
        </>
    );
};

export default TransfertInfo;
