/* eslint-disable react/no-unknown-property */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Image, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

const Main = styled.div`
    min-height: 600px;
    margin: 0px auto;
    width: auto;
    max-width: 460px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const NotAuthorizedPage: React.FC = () => {
    const history = useNavigate();

    useEffect(() => {
        document.title = 'Not Authorized - DS';
    }, []);
    return (
        <Main>
            <Image src="/401_noaccess.jpg" alt="401" fluid />
            <Row style={{ width: '100%' }}>
                <Col className="d-flex flew-wrap justify-content-around align-items-center">
                    <Button variant="outline-info" onClick={() => history(-3)}>
                        Retour
                    </Button>
                    <Button variant="outline-primary" onClick={() => history('/', { replace: true })}>
                        Accueil
                    </Button>
                </Col>
            </Row>
        </Main>
    );
};

export default NotAuthorizedPage;
