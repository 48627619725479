import React from 'react';
import CustomModal from 'components/UI/customModal';
import ImageElement from 'components/UI/imageElement';

type Props = {
    file: Blob | string | null | any;
    alt: string;
    fluid?: boolean;
    rounded?: boolean;
    roundedCircle?: boolean;
    thumbnail?: boolean;
    title: string;
    show: boolean;
    handleClose: () => void;
    size?: 'sm' | 'lg' | 'xl';
};

const ImageModal: React.FC<Props> = ({
    file,
    alt,
    fluid,
    rounded,
    roundedCircle,
    thumbnail,
    title,
    show,
    handleClose,
    size,
}: Props) => {
    return !file ? (
        <></>
    ) : (
        <CustomModal title={title} show={show} handleClose={handleClose} size={size} bodyClassNames="text-center">
            <ImageElement
                file={file}
                alt={alt}
                fluid={fluid}
                rounded={rounded}
                roundedCircle={roundedCircle}
                thumbnail={thumbnail}
            />
        </CustomModal>
    );
};

ImageModal.defaultProps = {
    size: 'lg',
};

export default ImageModal;
