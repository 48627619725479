import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import RegionInterface from 'types/interfaces/region.interface';

const apiEndPoint = `${apiUrl}/regions`;

export const getRegions = (): Promise<RegionInterface[] | any> => {
    return http.get(apiEndPoint);
};

export const getRegionsWVilles = (): Promise<RegionInterface[] | any> => {
    return http.get(`${apiEndPoint}/include-villes`);
};

export const saveRegion = (region: RegionInterface) => {
    const body = {
        ...region,
    };
    if (region.idRegion) {
        return http.put(`${apiEndPoint}/${region.idRegion}`, body);
    }
    return http.post(`${apiEndPoint}`, body);
};
