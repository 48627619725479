import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { TableColumnType } from 'react-bs-datatable';
import { Col, Form, Row, Tab, Tabs, Button, Spinner, Dropdown, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { faInfo, faInfoCircle, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'classnames';

import { ROLES as RolesMap } from 'constantes/permission-maps';
import {
    isEmptyObject,
    buildUrlQueryParams,
    useQueryMeMo,
    Capitalize1stLetter,
    DisplayName,
    utf8_to_b64,
    b64_to_utf8,
    hideFullCell,
    isTheOwnerOfCommande,
    isSameFournisseurEmployee,
    canControlCommande,
    isOnlyLivreur,
    getCommissionPerCommandeResult,
    groupByCommandeAndVille,
    calculateCommissionsSum,
} from 'utils/helpers';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import PermissionsGate from 'hoc/permissionsGate';
import CommandeTarifsCommponent, { calculateTotalPrix } from 'pages/FactureLivreur/components/CommandeTarifs';
import CommandeFormComponent from 'pages/Commande/components/CommandeForm';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import CustomModal from 'components/UI/customModal';
import Datatable from 'components/shared/datatable';
import Paginator from 'components/UI/paginator';
import PrintCommande from 'pages/Commande/components/PrintCommande';
import SmallCommande from 'pages/Commande/components/SmallCommande';
import FactureComponent from 'pages/Commande/components/Facture';
import CommandeInterface from 'types/interfaces/commande.interface';
import CommissionLivreursInterface from 'types/interfaces/commissionsLivreur.interface';
import UserInterface from 'types/interfaces/user.interface';
import CommissionsLivreurInterface from 'types/interfaces/commissionsLivreur.interface';
import {
    getCommandesLivreurPaiementIsFalseFiltered,
    createFactureForLivreurPaiement,
    getCommandeById,
} from 'services/commande.service';
import { getLivreursByAgence, getLivreursByFournisseur } from 'services/user.service';
import { GetCommissionsByLivreur } from 'services/commissionsLivreur.service';
import TitleElement from 'components/UI/titleElement';

const TOTAL_DATA_PER_PAGE = 100;

const CommandeLivreurPaiementPage: React.FC = () => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    const [commandes, setCommandes] = useState<{ items: CommandeInterface[]; count: number }>({
        items: [],
        count: 0,
    });
    const [currentCommande, setCurrentCommande] = useState<CommandeInterface | null>(null);
    const [livreurs, setLivreurs] = useState<UserInterface[]>([]);
    const [commissionsLivreurs, setCommissionsLivreurs] = useState<CommissionsLivreurInterface[]>([]);
    const [checkedCommandes, setCheckedCommandes] = useState<number[]>([]);

    const [selectedLivreur, setSelectedLivreur] = useState<string | null>(null);
    const [activePage, setActivePage] = useState<number>(1);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showCommissionsModal, setShowCommissionsModal] = useState<boolean>(false);
    const [showPrintableOneCommandeFacture, setShowPrintableOneCommandeFacture] = useState<boolean>(false);
    const [showSmallCommande, setShowSmallCommande] = useState<boolean>(false);
    const [showFullFacture, setShowFullFacture] = useState<boolean>(false);
    const [idFacture, setIdFacture] = useState<null | number>(null);
    const [spinIt, setSpinIt] = useState<boolean>(false);

    const query = useQueryMeMo();
    const history = useNavigate();

    const handleShowFullFactureOpen = (idFacture: number) => {
        setIdFacture(idFacture);
        setShowFullFacture(true);
    };

    const handleShowFullFactureClose = () => {
        setShowFullFacture(false);
    };

    const handleModalClose = () => {
        setCurrentCommande(null);
        setShowModal(false);
    };
    const handleCommissionsModalOpen = () => {
        setShowCommissionsModal(true);
    };
    const handleCommissionsModalClose = () => {
        setShowCommissionsModal(false);
    };

    const handleModalOpen = (data: CommandeInterface, trueData = false) => {
        if (trueData) {
            setCurrentCommande(data);
            setShowModal(true);
        } else {
            const cmdData = commandes.items.find((d: CommandeInterface) => d.idCommande === data.idCommande);
            if (cmdData) {
                setCurrentCommande(cmdData);
                setShowModal(true);
            }
        }
    };
    const handlePrintableOneCommandeFactureModalClose = () => {
        setCurrentCommande(null);
        setShowPrintableOneCommandeFacture(false);
    };

    const handlePrintableOneCommandeFactureModalOpen = (data: CommandeInterface) => {
        const cmdData = commandes.items.find((d: CommandeInterface) => d.idCommande === data.idCommande);
        if (cmdData) {
            setCurrentCommande(cmdData);
            setShowPrintableOneCommandeFacture(true);
        }
    };
    const handleSmallCommandeModalClose = () => {
        setCurrentCommande(null);
        setShowSmallCommande(false);
    };

    const handleSmallCommandeModalOpen = (data: CommandeInterface) => {
        const cmdData = commandes.items.find((d: CommandeInterface) => d.idCommande === data.idCommande);
        if (cmdData) {
            setCurrentCommande(cmdData);
            setShowSmallCommande(true);
        }
    };

    const loadCommandes = (total: number, page: number, idLivreur?: string) => {
        getCommandesLivreurPaiementIsFalseFiltered(total, page, idLivreur)
            .then((response: AxiosResponse<CommandeInterface[] | any>) => {
                const { data } = response;
                setCommandes({
                    items: data.items,
                    count: data.count,
                });
            })
            .catch();

        if (contextUser) {
            const userLivreurId = !isOnlyLivreur(contextUser?.userData) ? idLivreur : contextUser.userData.idUser;
            GetCommissionsByLivreur(userLivreurId)
                .then((response: AxiosResponse<CommissionsLivreurInterface[] | any>) => {
                    const { data } = response;
                    setCommissionsLivreurs(data);
                })
                .catch();
        }
    };

    const handlePageNFilterChange = (activePage: number, searchedData?: string) => {
        setCheckedCommandes([]);
        const urlObject: any = {};
        if (activePage > 1) urlObject['page'] = activePage;
        if (searchedData) {
            const parsedObject = JSON.parse(searchedData);
            Object.keys(parsedObject).forEach((key) => {
                if (parsedObject[key as any]) {
                    if (key === 'livreur' && parsedObject[key as any])
                        urlObject[key] = utf8_to_b64(parsedObject[key as any]);
                    else urlObject[key] = parsedObject[key as any];
                }
            });
        }
        history(isEmptyObject(urlObject) ? window.location.pathname : `?${buildUrlQueryParams(urlObject)}`);
    };

    const onCheckAllCommandes = useCallback(() => {
        setCheckedCommandes((oldState) => {
            if (
                oldState.length ===
                commandes.items.filter((d: CommandeInterface) =>
                    canControlCommande(contextUser?.userData?.idFournisseur!, d),
                ).length
            ) {
                return [];
            }

            return commandes.items
                .filter((d: CommandeInterface) => canControlCommande(contextUser?.userData?.idFournisseur!, d))
                .map((el: CommandeInterface) => el.idCommande);
        });
    }, [commandes.items]);

    const onCheckCommandes = useCallback(
        (row: CommandeInterface) => {
            setCheckedCommandes((oldState) => {
                const idx = oldState.findIndex((el: number) => el === row.idCommande);

                if (!canControlCommande(contextUser?.userData?.idFournisseur!, row)) return oldState;

                if (idx === -1) {
                    return oldState.concat(row.idCommande);
                }

                const newState = [...oldState];
                newState.splice(idx, 1);

                return newState;
            });
        },
        [commandes.items],
    );

    useEffect(() => {
        document.title = 'Créer facture livreur - DS';
    }, []);

    useEffect(() => {
        if (
            contextUser?.userData?.userroles?.some(
                (d) =>
                    d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN ||
                    d.role.nomRole === RolesMap.FOURNISSEUR_RECEPTIONISTE,
            )
        ) {
            getLivreursByFournisseur(contextUser?.userData?.idFournisseur!)
                .then((response: AxiosResponse<UserInterface[] | any>) => {
                    const { data } = response;
                    setLivreurs(data);
                })
                .catch();
        } else {
            getLivreursByAgence(contextUser?.userData?.idAgence!)
                .then((response: AxiosResponse<UserInterface[] | any>) => {
                    const { data } = response;
                    setLivreurs(data);
                })
                .catch();
        }
    }, [contextUser]);

    useEffect(() => {
        const page = query.get('page');
        const currentPage = page ? +page : 1;
        const _livreur = query.get('livreur');
        const _currentLivreur = _livreur ? b64_to_utf8(_livreur) : undefined;
        setActivePage(currentPage);
        setSelectedLivreur(_currentLivreur!);
        const loadData = async () => {
            loadCommandes(TOTAL_DATA_PER_PAGE, currentPage, _currentLivreur!);
        };
        loadData();
    }, [query, selectedLivreur]);

    const submitFactureCreationForLivreurPaiement = async (commandeLivreurPaiement: any) => {
        if (!selectedLivreur) return;
        try {
            setSpinIt(true);
            const response = await createFactureForLivreurPaiement({ ids: commandeLivreurPaiement }, +selectedLivreur!);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Action enregistrée avec succès');
                const colisData = [...commandes.items].filter((dd) => !resultData.includes(dd.idCommande));
                setCommandes({ items: colisData, count: commandes.count - resultData.length });
                setCheckedCommandes([]);
            }
        } catch (exErr: any) {
            toast.error(exErr || 'Internal server error!');
        } finally {
            setSpinIt(false);
        }
    };

    const eagerLoadingCommande = (cmdId: number, openModal = false, updateInList = false) => {
        getCommandeById(cmdId)
            .then((response: AxiosResponse<CommandeInterface | any>) => {
                const { data } = response;
                if (updateInList && commandes.items.length > 0) {
                    const isStrictLivreur =
                        contextUser?.userData?.userroles?.some(
                            (d) => d.role.nomRole === RolesMap.FOURNISSEUR_LIVREUR,
                        ) &&
                        !contextUser?.userData?.userroles?.some((d) => d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN);
                    if (isStrictLivreur && data.idLivreur !== contextUser?.userData?.idUser!) {
                        const cmdData = commandes.items.filter(
                            (d: CommandeInterface) => d.idCommande != data.idCommande!,
                        );
                        setCommandes({ items: cmdData, count: commandes.count - 1 });
                    } else {
                        const cmdData = [...commandes.items];
                        const cmdIndex = cmdData.findIndex((d: CommandeInterface) => d.idCommande == data.idCommande!);
                        cmdData[cmdIndex] = data;
                        setCommandes({ items: cmdData, count: commandes.count });
                        if (openModal) handleModalOpen(data, true);
                    }
                } else {
                    if (openModal) handleModalOpen(data, true);
                }
            })
            .catch();
    };

    const tableHeaders: TableColumnType<any>[] = useMemo(
        () => [
            {
                prop: 'checkbox',
                thProps: {
                    className: 'element-va',
                },
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                headerCell: () => (
                    <input
                        type="checkbox"
                        checked={checkedCommandes.length === commandes.items.length}
                        onChange={onCheckAllCommandes}
                    />
                ),
                cell: (row: CommandeInterface) => (
                    <>
                        {canControlCommande(contextUser?.userData?.idFournisseur!, row) && (
                            <input
                                type="checkbox"
                                checked={checkedCommandes.includes(row.idCommande)}
                                //  readOnly
                                onChange={() => onCheckCommandes(row)}
                            />
                        )}
                    </>
                ),
            },
            {
                prop: 'ref',
                title: 'CMD-Ref',
                thProps: {
                    className: 'element-va',
                },
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: CommandeInterface) => <>{row.ref ?? '-'}</>,
            },
            ...(contextUser?.userData.type === 'Fournisseur'
                ? [
                      {
                          prop: 'client.raisonSocial',
                          title: 'Client',
                          cellProps: {
                              className: 'foceVerticalMiddle',
                          },
                          isFilterable: true,
                          isSortable: true,
                          cell: (row: CommandeInterface) => (
                              <span className="my-xsm-font">
                                  {isTheOwnerOfCommande(contextUser?.userData?.idFournisseur!, row)
                                      ? (row as any)['client.raisonSocial']
                                      : '****'}
                              </span>
                          ),
                      },
                  ]
                : []),
            {
                prop: 'colis.refColis',
                title: 'Colis',
                thProps: {
                    className: 'element-va',
                },
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                ...hideFullCell(
                    contextUser?.userData?.userroles?.some((d) => d.role.nomRole === RolesMap.FOURNISSEUR_LIVREUR) &&
                        !contextUser?.userData?.userroles?.some((d) => d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN),
                    true,
                ),
            },
            {
                prop: 'acheteur.fullName',
                title: 'Acheteur',
                thProps: {
                    className: 'element-va',
                },
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
            },
            {
                prop: 'acheteur.gsm',
                title: 'GSM',
                thProps: {
                    className: 'element-va',
                },
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
            },
            {
                prop: 'prixTotal',
                title: 'Prix',
                thProps: {
                    className: 'element-va',
                },
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: CommandeInterface) => <>{row.prixTotal!} DH</>,
            },
            ...(contextUser && !isOnlyLivreur(contextUser?.userData)
                ? []
                : [
                      {
                          prop: '##',
                          title: 'Prix Services',
                          thProps: {
                              className: 'element-va',
                          },
                          cellProps: {
                              className: 'foceVerticalMiddle',
                          },
                          isFilterable: true,
                          isSortable: true,
                          cell: (row: CommandeInterface) => (
                              <>
                                  <CommandeTarifsCommponent commande={row} /> DH
                              </>
                          ),
                      },
                  ]),
            {
                prop: 'ville.nomVille',
                title: 'Ville',
                thProps: {
                    className: 'element-va',
                },
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
            },
            {
                prop: 'adresse',
                title: 'Adresse',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
            },
            {
                prop: 'dateCreation',
                title: 'Créé le',
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: CommandeInterface) => <>{moment(row.dateCreation).format('DD/MM/YYYY HH:mm')}</>,
            },
            {
                prop: 'dateOperation',
                title: 'Dernière operation',
                thProps: {
                    className: 'element-va',
                },
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: CommandeInterface) => <>{moment(row.dateOperation).format('DD/MM/YYYY HH:mm')}</>,
            },
            {
                prop: 'livreur.nom',
                title: 'Livreur',
                thProps: {
                    className: 'element-va',
                },
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: CommandeInterface) => (
                    <span className="my-xsm-font">
                        {(row as any)['livreur.nom'] ? (
                            <>
                                {contextUser?.userData.type === 'Client' ||
                                isSameFournisseurEmployee(
                                    contextUser?.userData.idFournisseur!,
                                    (row as any)['livreur.idFournisseur'],
                                )
                                    ? `${(row as any)['livreur.nom'].toUpperCase()} ${Capitalize1stLetter(
                                          (row as any)['livreur.prenom'],
                                      )}`
                                    : '****'}
                            </>
                        ) : (
                            '-'
                        )}
                    </span>
                ),
                ...hideFullCell(
                    contextUser?.userData?.userroles?.some((d) => d.role.nomRole === RolesMap.FOURNISSEUR_LIVREUR) &&
                        !contextUser?.userData?.userroles?.some((d) => d.role.nomRole === RolesMap.FOURNISSEUR_ADMIN),
                    true,
                ),
            },
            {
                prop: '--',
                title: 'Facture',
                thProps: {
                    className: 'element-va',
                },
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                isFilterable: true,
                isSortable: true,
                cell: (row: CommandeInterface) => {
                    if (
                        contextUser?.userData.type !== 'Client' &&
                        !isTheOwnerOfCommande(contextUser?.userData?.idFournisseur!, row)
                    )
                        return <>Indisponible</>;
                    const fPaiement = row.idFacture && (row as any)['facture.idPaiement'];
                    return (
                        <span className="my-xsm-font">
                            {row.idFacture ? (
                                <>
                                    <strong>{(row as any)['facture.refFacture']}</strong>
                                    {fPaiement ? ' Payée' : ''}
                                </>
                            ) : (
                                '-'
                            )}
                        </span>
                    );
                },
            },
            {
                prop: 'null',
                title: '#',
                thProps: {
                    className: 'element-va',
                },
                alignment: { horizontal: 'right' },
                cellProps: {
                    className: 'foceVerticalMiddle',
                },
                cell: (row: CommandeInterface) => (
                    <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle id="dropdown-autoclose-true"></Dropdown.Toggle>

                        <Dropdown.Menu className="btnDropDownInList" style={{ width: 'auto', minWidth: 'auto' }}>
                            <Dropdown.Item
                                className="text-left"
                                onClick={() => eagerLoadingCommande(row.idCommande, true, true)}
                            >
                                <FontAwesomeIcon icon={faInfo} size="xs" style={{ color: '#376ca9' }} width="20" />
                                Détails commande
                            </Dropdown.Item>
                            <Dropdown.Item className="text-left" onClick={() => handleSmallCommandeModalOpen(row)}>
                                <FontAwesomeIcon icon={faPrint} size="xs" style={{ color: '#776ca9' }} width="20" />
                                Imprimer commande
                            </Dropdown.Item>

                            {row.idFacture &&
                                (contextUser?.userData.type === 'Client' ||
                                    isTheOwnerOfCommande(contextUser?.userData?.idFournisseur!, row)) && (
                                    <>
                                        <PermissionsGate
                                            scopes={[
                                                RolesMap.FOURNISSEUR_ADMIN,
                                                RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                                RolesMap.FOURNISSEUR_FACTURE,
                                            ]}
                                            strict
                                        >
                                            <Dropdown.Item
                                                className="text-left"
                                                onClick={() => handleShowFullFactureOpen(row.idFacture!)}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPrint}
                                                    size="xs"
                                                    style={{ color: '#776ca9' }}
                                                    width="20"
                                                />
                                                Imprimer facture
                                            </Dropdown.Item>
                                        </PermissionsGate>
                                        <PermissionsGate
                                            scopes={[
                                                RolesMap.FOURNISSEUR_ADMIN,
                                                RolesMap.FOURNISSEUR_RECEPTIONISTE,
                                                RolesMap.FOURNISSEUR_FACTURE,
                                            ]}
                                            strict
                                        >
                                            <Dropdown.Item
                                                className="text-left"
                                                onClick={() => handlePrintableOneCommandeFactureModalOpen(row)}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPrint}
                                                    size="xs"
                                                    style={{ color: '#776ca9' }}
                                                    width="20"
                                                />
                                                Imprimer commande facture
                                            </Dropdown.Item>
                                        </PermissionsGate>
                                    </>
                                )}
                        </Dropdown.Menu>
                    </Dropdown>
                ),
            },
        ],
        [checkedCommandes, onCheckAllCommandes, onCheckCommandes, commandes.items, contextUser, selectedLivreur],
    );

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { path: '/commandes', text: 'Commandes' },
        { active: true, text: 'Créer facture livreur' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Créer facture livreur
                </TitleElement>
            </div>
            {contextUser && !isOnlyLivreur(contextUser?.userData) && contextUser.userData.type === 'Fournisseur' && (
                <Row className="mb-2 holdit_notEffect">
                    <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_Livreur">
                        <Form.Label>Livreur:</Form.Label>
                        <Form.Select
                            size="sm"
                            name="selectedLivreur"
                            value={selectedLivreur!}
                            onChange={(e) => {
                                const {
                                    target: { value },
                                } = e;
                                setSelectedLivreur(value);
                                handlePageNFilterChange(
                                    1,
                                    JSON.stringify({
                                        livreur: value,
                                    }),
                                );
                            }}
                            placeholder="Selectionner un livreur"
                        >
                            <option value="">Selectionner un livreur</option>
                            {livreurs.map((d: UserInterface) => (
                                <option key={d.idUser} value={d.idUser}>
                                    {DisplayName(d)}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Row>
            )}
            <Datatable
                data={commandes.items}
                tableColumns={tableHeaders}
                initialSort={{ initialState: { prop: 'dateOperation', order: 'desc' } }}
            >
                <Row>
                    <Col className="d-flex flex-col justify-content-end align-items-end">
                        <Paginator
                            defaultPage={activePage}
                            totalPages={Math.ceil(commandes.count / TOTAL_DATA_PER_PAGE)}
                            callback={(e_page) =>
                                handlePageNFilterChange(
                                    e_page,
                                    JSON.stringify({
                                        livreur: selectedLivreur,
                                    }),
                                )
                            }
                        />
                    </Col>
                </Row>
            </Datatable>
            {((contextUser && isOnlyLivreur(contextUser?.userData)) || selectedLivreur) && checkedCommandes.length > 0 && (
                <Tabs defaultActiveKey="PaiementLivreur" transition={false} id="PaiementLivreur_tabs" className="mt-1">
                    <Tab eventKey="PaiementLivreur" title="Paiement Livreur" className="tab-content-border">
                        <div className="d-flex justify-content-between align-items-center gap-2 p-1">
                            <div>
                                <b>{checkedCommandes.length}</b> commande(s) selectionnée(s) (Prix Total:{' '}
                                {commandes.items
                                    .filter((d: CommandeInterface) => checkedCommandes.includes(d.idCommande))
                                    .reduce((accumulator: number, object: any) => {
                                        return accumulator + object.prixTotal;
                                    }, 0)}{' '}
                                DH
                                {contextUser && !isOnlyLivreur(contextUser?.userData) && (
                                    <>
                                        ,{' '}
                                        <b>
                                            Services:{' '}
                                            {calculateTotalPrix(
                                                commandes.items.filter((d: CommandeInterface) =>
                                                    checkedCommandes.includes(d.idCommande),
                                                ),
                                                contextUser?.userData!,
                                            )}
                                            DH
                                        </b>
                                    </>
                                )}
                                {livreurs.find((d: UserInterface) => d.idUser === +selectedLivreur!)?.employe! ===
                                    'Commission' && (
                                    <>
                                        ,{' '}
                                        <i>
                                            Commission:{' '}
                                            <strong>
                                                {calculateCommissionsSum(
                                                    getCommissionPerCommandeResult(
                                                        commandes.items.filter((d: CommandeInterface) =>
                                                            checkedCommandes.includes(d.idCommande),
                                                        ),
                                                        commissionsLivreurs,
                                                    ),
                                                )}
                                            </strong>
                                            DH{' '}
                                            <Button
                                                variant="outline-secondary"
                                                size="sm"
                                                onClick={() => handleCommissionsModalOpen()}
                                            >
                                                <FontAwesomeIcon icon={faInfoCircle} size="lg" color="#000" />
                                            </Button>
                                        </i>
                                    </>
                                )}
                                )
                            </div>
                            {contextUser && !isOnlyLivreur(contextUser?.userData) && (
                                <Button
                                    variant="outline-info"
                                    className="gap-5marginpx"
                                    onClick={() => submitFactureCreationForLivreurPaiement(checkedCommandes)}
                                    disabled={spinIt}
                                >
                                    Enregistrer
                                    {!!spinIt && (
                                        <>
                                            &nbsp; <Spinner animation="border" size="sm" />
                                        </>
                                    )}
                                </Button>
                            )}
                        </div>
                    </Tab>
                </Tabs>
            )}

            <CustomModal
                title="Commande Détails"
                size="lg"
                show={showModal}
                handleClose={handleModalClose}
                fullscreen
                id="cmd-details-info"
            >
                <CommandeFormComponent
                    commandes={commandes}
                    setCommandes={(value: { items: CommandeInterface[]; count: number }) => setCommandes(value)}
                    currentCommande={currentCommande!}
                    setCurrentCommande={(value: CommandeInterface | null) => setCurrentCommande(value)}
                    setCheckedCommandes={(value: number[]) => setCheckedCommandes(value)}
                />
            </CustomModal>
            <CustomModal
                title="Commissions Détails"
                size="lg"
                show={showCommissionsModal}
                handleClose={handleCommissionsModalClose}
                id="commissions-details-info"
            >
                <CommissionsTable
                    commissionsLivreurs={commissionsLivreurs}
                    commandes={commandes.items.filter((d: CommandeInterface) =>
                        checkedCommandes.includes(d.idCommande),
                    )}
                />
            </CustomModal>
            <CustomModal
                title="Imprimer facture d'une seule commande"
                size="lg"
                show={showPrintableOneCommandeFacture}
                handleClose={handlePrintableOneCommandeFactureModalClose}
            >
                {currentCommande ? <PrintCommande commande={currentCommande!} /> : <></>}
            </CustomModal>
            <CustomModal
                title="Imprimer la commande"
                size="lg"
                show={showSmallCommande}
                handleClose={handleSmallCommandeModalClose}
            >
                {currentCommande ? <SmallCommande commande={currentCommande!} /> : <></>}
            </CustomModal>
            <CustomModal
                title="Imprimer facture"
                size="lg"
                show={showFullFacture}
                handleClose={handleShowFullFactureClose}
            >
                <FactureComponent loadData={showFullFacture} idFacture={idFacture!} />
            </CustomModal>
        </>
    );
};

export default CommandeLivreurPaiementPage;

type CommissionsTableType = {
    commandes: CommandeInterface[];
    commissionsLivreurs: CommissionLivreursInterface[];
};
const CommissionsTable: React.FC<CommissionsTableType> = ({ commandes, commissionsLivreurs }) => {
    const result = getCommissionPerCommandeResult(commandes, commissionsLivreurs);
    const groupedData = groupByCommandeAndVille(result);
    return (
        <div>
            {Object.keys(groupedData).map((groupKey) => (
                <div key={groupKey}>
                    <h5>{groupKey}</h5>
                    <Table>
                        <thead>
                            <tr>
                                <th>Service</th>
                                <th>Commission</th>
                                <th>Tarif Commission</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(groupedData as any)[groupKey].map((item: any, index: number) => (
                                <tr key={index}>
                                    <td>{item.service}</td>
                                    <td
                                        className={clsx({
                                            'text-center fs-6 fw-bolder': true,
                                            'bg-danger text-light': item.commission === '-',
                                            'bg-warning text-dark': item.commission === 0,
                                        })}
                                    >
                                        {item.commission}
                                    </td>
                                    <td
                                        className={clsx({
                                            'text-center fs-6 fw-bolder': true,
                                            'bg-warning text-dark':
                                                item.commission === '-' && item.tarifCommission === 0,
                                            'bg-info text-dark': item.commission === '-' && item.tarifCommission > 0,
                                        })}
                                    >
                                        {item.tarifCommission}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            ))}
        </div>
    );
};
