import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ROLES as RolesMap } from 'constantes/permission-maps';
import CommandeActionInterface from 'types/interfaces/commandeAction.interface';
import UserInterface from 'types/interfaces/user.interface';
import { WEB_URL } from 'constantes/envLoader';
import CommandeInterface from 'types/interfaces/commande.interface';
import TransfertCommandeInterface from 'types/interfaces/transfertCommande.interface';
import RegionInterface from 'types/interfaces/region.interface';
import GroupVilleInterface from 'types/interfaces/groupVilleClone.interface';
import { GroupedOptionType as multiSelectGroupedComponentGroupedOptionType } from 'components/UI/multiSelectGroupedComponent';
import CommissionsLivreurInterface from 'types/interfaces/commissionsLivreur.interface';

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const getQueryString = (locationSearch: any, queryString: string) => {
    const params = new URLSearchParams(locationSearch);
    return params.get(queryString);
};

export const useQueryMeMo = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

export const copyThis = (data: any) => JSON.parse(JSON.stringify(data));

export const readCookie = (name: string) => {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
};

export const utf8_to_b64 = (str: string) => window.btoa(decodeURI(encodeURIComponent(str)));

export const b64_to_utf8 = (str: string) => decodeURIComponent(encodeURI(window.atob(str)));

export const deleteCookie = (name: string, domain?: string /*, path?: string*/) => {
    if (readCookie(name)) {
        document.cookie = `${name}=;domain=${domain ? domain : ''};path=/;max-age=0`;
    }
};

export const changeUrlPathName = (newPathName: string) => {
    window.history.pushState(null, '', newPathName);
};

export const flattenObj = (ob: any) => {
    // The object which contains the
    // final result
    const result = {};

    // loop through the object "ob"
    for (const i in ob) {
        // We check the type of the i using
        // typeof() function and recursively
        // call the function again
        if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
            const temp = flattenObj(ob[i]);
            for (const j in temp) {
                // Store temp in result
                (result as any)[i + '.' + j] = (temp as any)[j];
            }
        }

        // Else store ob[i] in result directly
        else {
            (result as any)[i] = ob[i];
        }
    }
    return result;
};

export const flattenObjInArray = (arr: any[]) => arr.map((d: any) => flattenObj(d));

export const buildUrlQueryParams = (myData: any) => {
    const out = [];

    for (const key in myData) {
        if (myData.hasOwnProperty(key)) {
            out.push(key + '=' + encodeURIComponent(myData[key]));
        }
    }

    const urlQueryString = out.join('&');
    return urlQueryString;
};

export const isEmptyObject = (obj: any) => Object.keys(obj).length === 0;

export const multipleNumbersExist = (arr: number[], values: number[]) =>
    values.every((value) => {
        return arr.includes(value);
    });

export const sortByDueDate = (array: any[], dateStringColumn: string, desc = false) => {
    return [...array].sort((a: any, b: any) => {
        return !!desc
            ? +new Date(b[dateStringColumn]) - +new Date(a[dateStringColumn])
            : +new Date(a[dateStringColumn]) - +new Date(b[dateStringColumn]);
    });
};

export const sortByNumber = (array: any[], stringColumn: string, desc = false) => {
    return [...array].sort((a: any, b: any) => {
        return !!desc ? b[stringColumn] - a[stringColumn] : a[stringColumn] - b[stringColumn];
    });
};

export const allowAccess = (
    ruinedROLES: string,
    requirements: string[] | undefined = [],
    strict = false,
    heighestRole = RolesMap.SUPER_ADMIN,
) => {
    let allow = false;
    const ROLES = ruinedROLES ? JSON.parse(b64_to_utf8(ruinedROLES)) : [];
    if (ROLES.includes(heighestRole) && !strict) allow = true;
    else if (requirements && requirements.length > 0) {
        requirements.forEach((data: string) => {
            if (ROLES.includes(data)) {
                allow = true;
            }
        });
    } else allow = true;
    return allow;
};

export const hasRole = (ruinedROLES: string, requirement: string) => {
    const ROLES = ruinedROLES ? JSON.parse(b64_to_utf8(ruinedROLES)) : [];
    return ROLES.includes(requirement);
};

export const Capitalize1stLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const readFileAsArrayBufferAsync = (file: Blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
};

export const readFileAsync = (file: Blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
};

export const sortArrByTwoCols = (arr: any[], stringAColumn: string, stringBColumn: string, desc = false) => {
    return arr.sort((a, b) => {
        if (a[stringAColumn] === b[stringAColumn]) {
            return !!desc ? b[stringBColumn] - a[stringBColumn] : a[stringBColumn] - b[stringBColumn];
        } else {
            return !!desc ? b[stringAColumn] - a[stringAColumn] : a[stringAColumn] - b[stringAColumn];
        }
    });
};

export const removeDuplicatedArrObj = (arr: object[], uniqueColumField: string) => {
    const uniqueIds: any[] = [];

    const unique = arr.filter((element) => {
        const isDuplicate = uniqueIds.includes((element as any)[uniqueColumField]);

        if (!isDuplicate) {
            uniqueIds.push((element as any)[uniqueColumField]);

            return true;
        }

        return false;
    });
    return unique;
};

export const buildQueryFromParamsObj = (obj: object) => {
    const paramsObject = { ...obj };
    const paramsString = Object.keys(paramsObject)
        .filter((k: any) => (paramsObject as any)[k])
        .map((k: any) => encodeURIComponent(k) + '=' + encodeURIComponent((paramsObject as any)[k]))
        .join('&');

    return paramsString ? `?${paramsString}` : '';
};

export const getRandomlyFromArray = (arr: any[]) => {
    const random = Math.floor(Math.random() * arr.length);
    return arr[random];
};
export const addLeadingZeros = (num: number, totalLength: number) => {
    return String(num).padStart(totalLength, '0');
};

export const hideFullCell = (hideIt?: boolean, headerVerticalAlign = false) =>
    !!hideIt
        ? {
              cellProps: {
                  className: 'd-none',
              },
              thProps: {
                  className: `${!!headerVerticalAlign ? 'element-va' : ''} d-none`,
              },
          }
        : {};

export const promiseMockResponseParam = (data: any) => {
    return new Promise(function (resolve) {
        resolve(data);
    });
};

export const findLastIndex = (arr: any[], fn: any) =>
    (arr
        .map((val, i) => [i, val])
        .filter(([i, val]) => fn(val, i, arr))
        .pop() || [-1])[0];

export const siEncoursLivraison = (data: CommandeActionInterface[], retourId = 10, enCoursDeLaivraisonId = 14) => {
    const lastRetourIndex = findLastIndex(data, (d: CommandeActionInterface) => d.idEtatTitre === retourId);
    const lastEnCoursDeLaivraisonIndex = findLastIndex(
        data,
        (d: CommandeActionInterface) => d.idEtatTitre === enCoursDeLaivraisonId,
    );
    console.log(lastRetourIndex, lastEnCoursDeLaivraisonIndex);
    return lastRetourIndex && lastEnCoursDeLaivraisonIndex && lastRetourIndex < lastEnCoursDeLaivraisonIndex;
};

export const readImageTobase64 = (file: any, type: 'jpeg' | 'png' = 'jpeg') =>
    typeof file === 'string' && !(file as string).startsWith('data:image') ? `data:image/${type};base64,${file}` : file;

export const scaleWidthToHeight = (newHeight: number, orgHeight: number, orgWidth: number) =>
    Math.ceil((newHeight * orgWidth) / orgHeight);

export const scaleHeightToWidth = (newWidth: number, orgWidth: number, orgHeight: number) =>
    Math.ceil((newWidth * orgHeight) / orgWidth);

export const getImageDimension = async (image: any) => {
    if (typeof createImageBitmap !== 'function') {
        return null;
    }
    try {
        const bmp = await createImageBitmap(image);
        return {
            width: bmp.width,
            height: bmp.height,
        };
    } catch (Exception) {
        return null;
    }
};

export const groupBy = <T, K extends keyof any>(array: T[], key: K): Record<string, T[]> =>
    array.reduce((accumulator, currentValue) => {
        const groupKey = (currentValue as any)[key];
        if (!accumulator[groupKey]) {
            accumulator[groupKey] = [];
        }
        accumulator[groupKey].push(currentValue);
        return accumulator;
    }, {} as Record<string, T[]>);

export const getFournisseurAliasUrl = (userData: UserInterface) => {
    let url = WEB_URL;
    if (
        userData &&
        userData.type === 'Fournisseur' &&
        userData.fournisseur?.urlAlias !== null &&
        userData.fournisseur?.urlAlias.trim() !== ''
    ) {
        url = userData.fournisseur?.urlAlias!;
    } else if (
        userData &&
        userData.type === 'Client' &&
        userData.client?.fournisseur?.urlAlias !== null &&
        userData.client?.fournisseur?.urlAlias.trim() !== ''
    ) {
        url = userData.client?.fournisseur?.urlAlias!;
    }
    return url;
};

export const isNumber = (numberValue: any) => {
    return !isNaN(numberValue) && isFinite(numberValue);
};

export const isTheOwnerOfCommande = (idFournisseur: number, commande: CommandeInterface) =>
    idFournisseur === commande.idFournisseur;

export const isSameFournisseurEmployee = (sourceFournisseur: number, idFournisseur: number) =>
    sourceFournisseur === idFournisseur;

export const canControlCommande = (connectedFournisseurId: number, commande: CommandeInterface) => {
    const amOwnerAndNoTransfert =
        connectedFournisseurId === commande.idFournisseur && commande.transfertCommandes?.length === 0;
    if (amOwnerAndNoTransfert) return true;
    else if (commande.transfertCommandes?.length! === 0) return false;
    const lastTransert = commande.transfertCommandes![commande.transfertCommandes?.length! - 1];
    return lastTransert.idFournisseurDestinataire === connectedFournisseurId && lastTransert.received === true;
};

export const DisplayName = (user: UserInterface) => `${user.nom.toUpperCase()} ${Capitalize1stLetter(user.prenom!)}`;

export const getDisplayNameForUserOrSecretName = (
    connectedUserData: UserInterface,
    targetUser: UserInterface,
    commande: CommandeInterface,
) => {
    const userFullName = `${targetUser.nom?.toUpperCase()!} ${Capitalize1stLetter(targetUser.prenom!)}`;

    if (connectedUserData.type === 'Client') {
        return targetUser.type === 'Client' ? userFullName : 'Agent';
    }

    const isProviderMatch = isSameFournisseurEmployee(connectedUserData.idFournisseur!, targetUser.idFournisseur!);

    if (targetUser.type === 'Client') {
        return isTheOwnerOfCommande(connectedUserData.idFournisseur!, commande) ? userFullName : 'Client';
    } else if (isProviderMatch) {
        return userFullName;
    } else {
        return '****';
    }
};

export const isTransfertFound = (transferts: TransfertCommandeInterface[], idFournisseurConnected: number) => {
    if (transferts.length === 0) return false;
    return transferts.some(
        (t: TransfertCommandeInterface) =>
            t.idFournisseurSource === idFournisseurConnected || t.idFournisseurDestinataire === idFournisseurConnected,
    );
};

export const isTheLastTransfertIndex = (idTransfert: number, listTransfert: TransfertCommandeInterface[]) => {
    const lastIndex = listTransfert[listTransfert.length - 1].idTransfertCommande;
    return idTransfert === lastIndex;
};
export const isOnlyLivreur = (userData: UserInterface) =>
    userData?.userroles?.length === 1 &&
    userData?.userroles?.some((d) => d.role.nomRole === RolesMap.FOURNISSEUR_LIVREUR);

export const isCommandeActionsBlocked = (cmd: CommandeInterface) => {
    return (
        cmd.idFacture ||
        cmd.livreurPaiement ||
        cmd.transfertCommandes?.filter((d) => d.factured)?.length! > 0 ||
        cmd.factureLivreurCommandes?.length! > 0
    );
};

export const groupingRegionVilles = (data: RegionInterface[]) => {
    const result = data.reduce((acc: any[], region) => {
        if (region.villes && region.villes.length > 0) {
            const cities = region.villes.map((ville) => ({
                label: ville.nomVille,
                group: region.nomRegion,
                id: ville.idVille,
            }));
            return [...acc, ...cities];
        }
        return acc;
    }, []);
    return result;
};

export const groupingOptionsRegionVilles = (data: RegionInterface[]): multiSelectGroupedComponentGroupedOptionType[] =>
    data.map((d) => ({
        label: d.nomRegion,
        options: d.villes?.map((v) => ({ label: v.nomVille, value: v.idVille })) ?? [],
    }));

export const getUsersLabelOptions = (
    label: string,
    data: UserInterface[],
): multiSelectGroupedComponentGroupedOptionType[] => [
    {
        label,
        options: data.map((d) => ({ label: DisplayName(d), value: d.idUser })),
    },
];

export const calculateTotalTarifCommision = (commandes: CommandeInterface[]) => {
    return commandes.reduce((accumulator, commande) => {
        return accumulator + calculateTarifCommision(commande);
    }, 0);
};

export const calculateTarifCommision = (commande: CommandeInterface) => {
    return commande.commandetarifs.reduce((accumulator, ct) => {
        return accumulator + ct.tarif.commissionLivreur;
    }, 0);
};

export const getCommissionPerCommandeResult = (
    commandes: CommandeInterface[],
    commissionsLivreurList: CommissionsLivreurInterface[],
) => {
    const result: any[] = [];
    commandes.forEach((commande) => {
        commande.commandetarifs.forEach((ct) => {
            const commission = commissionsLivreurList.find(
                (cl) => cl.idVille === ct.tarif.idVille && cl.idOptionService === ct.tarif.idOptionService,
            );
            result.push({
                commande: `${commande.code}`,
                ville: ct.tarif.ville.nomVille,
                service: ct.tarif.optionService.nameOptionService,
                commission: commission ? commission.prix : '-',
                tarifCommission: ct.tarif.commissionLivreur,
            });
        });
    });
    return result;
};

export const calculateCommissionsSum = (array: any[]) => {
    return array.reduce((sum: number, item: any) => {
        const commission = item.commission === '-' ? item.tarifCommission : item.commission;
        return sum + parseFloat(commission);
    }, 0);
};

export const groupByCommandeAndVille = (array: any[]) => {
    const groupedData = {};

    array.forEach((item) => {
        const key = `${item.commande}/${item.ville}`;
        if (!(groupedData as any)[key]) {
            (groupedData as any)[key] = [];
        }
        (groupedData as any)[key].push(item);
    });

    return groupedData;
};

export const findGroupVilleByVilleId = (
    groupVilles: GroupVilleInterface[],
    idVilleToFind: number,
): GroupVilleInterface | undefined => {
    for (const groupVille of groupVilles) {
        for (const groupVilleClone of groupVille.groupVilleClones || []) {
            if (groupVilleClone.idVille === idVilleToFind) {
                return groupVille;
            }
        }
    }
    return undefined;
};

export const formatPhoneNumber = (phoneNumber: string) => {
    let formated = phoneNumber;
    // If the phone number starts with '+', remove it
    formated = formated.replace(/^\+/, '');

    // If the phone number starts with 0, replace it with 212
    formated = formated.replace(/^0/, '212');

    return formated;
};
