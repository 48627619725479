import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { Formik } from 'formik';
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';

import CustomModal from 'components/UI/customModal';
import { getCommandeByCode } from 'services/commande.service';
import CommandeInterface from 'types/interfaces/commande.interface';
import { utf8_to_b64 } from 'utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

const schema = yup.object({
    code: yup.string().required(),
});

type Props = {
    show: boolean;
    setShow: (status: boolean) => void;
};

const SearchByCodeFormModal: React.FC<Props> = ({ show, setShow }: Props) => {
    const [spinIt, setSpinIt] = useState<boolean>(false);
    const navigate = useNavigate();

    const submitForm = async (values: any) => {
        try {
            setSpinIt(true);
            const response: AxiosResponse<CommandeInterface> = await getCommandeByCode(values.code);
            if (response.status === 200) {
                const { data: commande } = response;
                setShow(false);
                setSpinIt(false);
                navigate(`/commandes?open=${utf8_to_b64(commande?.idCommande?.toString()!)}`);
            }
        } catch (error: any) {
            if (error && error.status === 404) toast.warning("Commande n'existe pas");
            else toast.error('Erreur est survenue');

            setSpinIt(false);
        }
    };
    return (
        <CustomModal title="Scanner le code barre" show={show} handleClose={() => setShow(false)}>
            <div>
                <Formik
                    validationSchema={schema}
                    onSubmit={(values) => submitForm(values)}
                    enableReinitialize={true}
                    initialValues={{
                        code: '',
                    }}
                >
                    {({ handleSubmit, handleChange, values, errors }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group controlId="form_grp_nom">
                                <Form.Label>Code barre</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        name="code"
                                        placeholder="Code barre"
                                        value={values.code}
                                        onChange={handleChange}
                                        isInvalid={!!errors.code}
                                        autoFocus={true}
                                    />
                                    <Button
                                        variant="outline-secondary"
                                        onClick={() => handleChange({ target: { name: 'code', value: '' } })}
                                    >
                                        <FontAwesomeIcon icon={faX} size="lg" color="#3b3535" />
                                    </Button>
                                </InputGroup>
                                <Form.Control.Feedback type="invalid">{errors.code}</Form.Control.Feedback>
                            </Form.Group>
                            <div className="d-grid gap-2 mt-3">
                                <Button variant="primary" type="submit" disabled={!!spinIt}>
                                    Trouver
                                    {!!spinIt && (
                                        <>
                                            &nbsp; <Spinner animation="border" size="sm" />
                                        </>
                                    )}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </CustomModal>
    );
};

export default SearchByCodeFormModal;
