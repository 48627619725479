import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { Col, Form, InputGroup, Row, Table } from 'react-bootstrap';

import Printer, { SignatureSection } from 'components/shared/printer';
import CommandeInterface from 'types/interfaces/commande.interface';
import FactureInterface from 'types/interfaces/facture.interface';
import { loadFullFacture } from 'services/facture.service';
import SiteLogo from 'components/UI/siteLogo';

type Props = {
    idFacture: number;
    loadData: boolean;
};

const FactureComponent: React.FC<Props> = ({ idFacture, loadData }: Props) => {
    const [commandes, setCommandes] = useState<CommandeInterface[]>([]);
    const [facture, setFacture] = useState<FactureInterface | null>(null);

    useEffect(() => {
        if (loadData && idFacture) {
            loadFullFacture(idFacture)
                .then(
                    (response: AxiosResponse<{ facture: FactureInterface; commandes: CommandeInterface[] } | any>) => {
                        const { data } = response;
                        setFacture(data.facture);
                        setCommandes(data.commandes);
                    },
                )
                .catch();
        }
    }, [loadData, idFacture]);

    return (
        <Printer documentTitle="facture" whilePrintingContent={() => <SignatureSection />}>
            <div className="d-flex justify-content-between align-items-center p-2">
                <div className="d-flex flex-row align-items-center gap-2">
                    <SiteLogo />
                    <div className="d-flex flex-column">
                        <span className="font-weight-bold">Facture Ref</span> <b>{facture?.refFacture}</b>{' '}
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-between text-end">
                    <span>Le {moment(facture?.dateFacture).format('DD/MM/YYYY')}</span>
                    {facture?.paiement && (
                        <>
                            <div className="flexbasis100-break"></div>
                            <span>Facture payée le {moment(facture?.paiement.datePaiement).format('DD/MM/YYYY')}</span>
                        </>
                    )}
                </div>
            </div>
            <hr style={{ margin: 0 }} />
            <Row className="p-2">
                <Col md="6" lg="6" sm="6" xs="6">
                    <Row>
                        <Col auto>Client:</Col>
                        <Col auto className="text-end">
                            {commandes.length > 0 ? commandes[0].client.raisonSocial : '-'}
                        </Col>
                    </Row>
                    <Row>
                        <Col auto>Téléphone:</Col>
                        <Col auto className="text-end">
                            {commandes.length > 0 ? commandes[0].client?.telephoneBureau1 : '-'}
                        </Col>
                    </Row>
                    <Row>
                        <Col auto>Banque:</Col>
                        <Col auto className="text-end">
                            {commandes.length > 0 ? commandes[0].client?.banque.libelleBanque : '-'}
                        </Col>
                    </Row>
                    <Row>
                        <Col auto>RIB</Col>
                        <Col auto className="text-end">
                            {commandes.length > 0 ? commandes[0].client?.rIB ?? '-' : '-'}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr style={{ margin: 0 }} />
            <div className="p-2">
                <h6 className="text-start">Articles:</h6>
                <Table striped responsive size="sm">
                    <thead>
                        <tr>
                            <th>Code/Ref</th>
                            <th>Ville</th>
                            <th>Acheteur</th>
                            <th>Date de livraison</th>
                            <th>Frais</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {commandes.map((d: CommandeInterface) => (
                            <tr key={d.idCommande}>
                                <td>
                                    <b>{d.code}</b>
                                    <br />
                                    CMD-Ref: <b>{d?.ref ?? '-'}</b>
                                </td>
                                <td>
                                    {d.ville?.nomVille} / {d.ville?.region?.nomRegion!}
                                </td>
                                <td>
                                    <b>{d.acheteur?.fullName!}</b>
                                    <br />
                                    {d.acheteur?.gsm}
                                </td>
                                <td>{moment(d.dateOperation).format('DD/MM/YYYY HH:mm')}</td>
                                <td>
                                    {(d.commandetarifs ?? []).reduce((accumulator: number, object: any) => {
                                        return accumulator + object.tarif?.prixTarif;
                                    }, 0)}
                                </td>
                                <td>{d.prixTotal!}</td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan={4}></td>
                            <td>
                                {commandes.reduce((accumulator: number, object: any) => {
                                    return (
                                        accumulator +
                                        (object.commandetarifs ?? []).reduce((accumulator: number, deepObject: any) => {
                                            return accumulator + deepObject.tarif?.prixTarif;
                                        }, 0)
                                    );
                                }, 0)}
                            </td>
                            <td>
                                {commandes.reduce((accumulator: number, object: any) => {
                                    return accumulator + object.prixTotal;
                                }, 0)}
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <Row>
                    <Col>
                        {facture?.remiseAccorde && facture?.remiseAccorde > 0 ? (
                            <Row className="g-3 align-items-center">
                                <Col auto>
                                    <strong>Remise:</strong>
                                </Col>
                                <Col auto className="text-end">
                                    {facture?.remiseAccorde} DH
                                </Col>
                            </Row>
                        ) : (
                            <></>
                        )}
                        <Row className="g-3 mb-2 align-items-center">
                            <Col auto>
                                <strong>Montant facturé:</strong>
                            </Col>
                            <Col auto className="text-end">
                                <InputGroup>
                                    <Form.Control
                                        size="sm"
                                        className="text-end"
                                        type="text"
                                        name="montantFacture"
                                        value={facture?.montantFacture!}
                                        disabled
                                        style={{ background: '#fff' }}
                                    />
                                    <InputGroup.Text style={{ background: '#fff' }}>DH</InputGroup.Text>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Form.Group controlId="form_grp_prixTotal">
                            <Row className=" g-3 align-items-center">
                                <Col auto>
                                    <Form.Label style={{ fontWeight: 700, fontSize: '1rem' }}>
                                        Montant à payer
                                    </Form.Label>
                                </Col>
                                <Col auto>
                                    <InputGroup>
                                        <Form.Control
                                            size="sm"
                                            className="text-end"
                                            type="text"
                                            name="prixTotal"
                                            value={
                                                commandes.reduce((accumulator: number, object: any) => {
                                                    return accumulator + object.prixTotal;
                                                }, 0) -
                                                commandes.reduce((accumulator: number, object: any) => {
                                                    return (
                                                        accumulator +
                                                        (object.commandetarifs ?? []).reduce(
                                                            (accumulator: number, deepObject: any) => {
                                                                return accumulator + deepObject.tarif?.prixTarif;
                                                            },
                                                            0,
                                                        )
                                                    );
                                                }, 0)
                                            }
                                            disabled
                                        />
                                        <InputGroup.Text>DH</InputGroup.Text>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                    {facture?.idPaiement && (
                        <Col className="align-self-end">
                            <Row className="g-3 align-items-center">
                                <Col auto>Mode:</Col>
                                <Col auto className="text-end">
                                    {facture?.paiement?.modepaiement?.descriptionModePaiement}
                                </Col>
                            </Row>
                            <Row className="g-3 align-items-center">
                                <Col auto>
                                    <strong>Montant Payé:</strong>
                                </Col>
                                <Col auto className="text-end">
                                    {facture?.paiement?.montantPaye} DH
                                </Col>
                            </Row>
                        </Col>
                    )}
                </Row>
            </div>
        </Printer>
    );
};

FactureComponent.defaultProps = {
    loadData: false,
};

export default FactureComponent;
