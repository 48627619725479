import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import CommandeActionInterface from 'types/interfaces/commandeAction.interface';

const apiEndPoint = `${apiUrl}/commandeActions`;

export const saveCommandeAction = (commandeAction: CommandeActionInterface) => {
    const body = {
        ...commandeAction,
    };
    return http.post(`${apiEndPoint}`, body);
};
