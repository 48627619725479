import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import ModePaiementInterface from 'types/interfaces/modePaiement.interface';

const apiEndPoint = `${apiUrl}/modepaiements`;

export const getModePaiements = (): Promise<ModePaiementInterface[] | any> => {
    return http.get(apiEndPoint);
};

export const saveModePaiement = (modePaiement: ModePaiementInterface) => {
    const body = {
        ...modePaiement,
    };
    if (modePaiement.idModePaiement) {
        return http.put(`${apiEndPoint}/${modePaiement.idModePaiement}`, body);
    }
    return http.post(`${apiEndPoint}`, body);
};
