import configEnv from 'constantes/config.json';
import configDevEnv from 'constantes/configDev.json';

const isDev = window.location.href.indexOf('localhost') > -1;

const apiUrl = isDev ? configDevEnv.apiUrl : configEnv.apiUrl;
const localStorageToken = configEnv.localStorageToken;
const _sidebarStorage = configEnv._sidebarStorage;
const COOKIE_EXPIRATION_CHECKER = configEnv.COOKIE_EXPIRATION_CHECKER;
const DOMAIN = isDev ? configDevEnv.DOMAIN : configEnv.DOMAIN;
const LOGO_WIDTH = configEnv.LOGO_WIDTH;
const LOGO_MAX_HEIGHT = configEnv.LOGO_MAX_HEIGHT;
const MAX_SIZE_IMAGE_UPLOAD = configEnv.MAX_SIZE_IMAGE_UPLOAD;
const WEB_URL = isDev ? configDevEnv.WEB_URL : configEnv.WEB_URL;

export {
    apiUrl,
    localStorageToken,
    _sidebarStorage,
    COOKIE_EXPIRATION_CHECKER,
    DOMAIN,
    LOGO_WIDTH,
    LOGO_MAX_HEIGHT,
    MAX_SIZE_IMAGE_UPLOAD,
    WEB_URL,
};

export default isDev;
