import React, { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { readFileAsync } from 'utils/helpers';

type Props = {
    file: Blob | string | null | any;
    alt: string;
    fluid?: boolean;
    rounded?: boolean;
    roundedCircle?: boolean;
    thumbnail?: boolean;
    width?: string;
    height?: string;
};

const ImageElement: React.FC<Props> = ({
    file,
    alt,
    fluid,
    rounded,
    roundedCircle,
    thumbnail,
    width,
    height,
}: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [thumb, setThumb] = useState<any>(undefined);

    useEffect(() => {
        const loadFile = async () => {
            try {
                if (file) {
                    if (typeof file === 'string') {
                        setThumb(file);
                    } else {
                        setLoading(true);
                        const fileResult = await readFileAsync(file);
                        setThumb(fileResult);
                        setLoading(false);
                    }
                }
            } catch (Exception) {
                setLoading(false);
            }
        };
        loadFile();
    }, [file]);

    return !file ? (
        <></>
    ) : loading ? (
        <p>loading...</p>
    ) : (
        <Image
            src={thumb}
            alt={alt}
            fluid={fluid}
            rounded={rounded}
            roundedCircle={roundedCircle}
            thumbnail={thumbnail}
            width={width}
            height={height}
        />
    );
};

export default ImageElement;
