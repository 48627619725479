import React, { useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const PageBreaker = () => <div className="pagePrintBreaker"></div>;

export const SignatureSection = () => (
    <>
        <hr style={{ margin: 0 }} />
        <Row className="p-2" /*style={{ height: '100%' }}*/>
            <Col
                style={{ height: '50px' }}
                className="align-self-end border"
                md={{ span: 6, offset: 6 }}
                lg={{ span: 6, offset: 6 }}
                sm={{ span: 6, offset: 6 }}
                xs={{ span: 6, offset: 6 }}
            >
                <div>Signature:</div>
            </Col>
        </Row>
    </>
);

type Props = {
    children: React.ReactNode | React.ReactElement | React.FC | any;
    documentTitle: string;
    whilePrintingContent?: React.ReactNode | React.ReactElement | React.FC | any;
};

const Printer: React.FC<Props> = ({ children, documentTitle, whilePrintingContent: WhilePrintingContent }: Props) => {
    const componentRef = useRef<any>(null);
    const [whilePrinting, setWhilePrinting] = useState<boolean>(false);
    const handlePrint = useReactToPrint({ content: () => componentRef?.current!, documentTitle });
    return (
        <>
            <div className="clearfix">
                <Button
                    variant="outline-success"
                    className="float-end mb-2"
                    onClick={() => {
                        setWhilePrinting(true);
                        setTimeout(() => {
                            handlePrint();
                            setWhilePrinting(false);
                        }, 100);
                    }}
                >
                    Imprimer{' '}
                    <FontAwesomeIcon className="hoverWhite" icon={faPrint} size="xs" style={{ color: '#776ca9' }} />
                </Button>
            </div>
            <Row className="d-flex justify-content-center" ref={componentRef!}>
                <Col>
                    <div
                        style={
                            whilePrinting
                                ? {
                                      height: '100vh',
                                      border: 'none',
                                      padding: '20px',
                                  }
                                : {
                                      border: '1px solid rgba(0, 0, 0, 0.125)',
                                  }
                        }
                    >
                        {children}
                        {whilePrinting && <WhilePrintingContent />}
                    </div>
                </Col>
            </Row>
        </>
    );
};

Printer.defaultProps = {
    whilePrintingContent: () => <></>,
};

export default Printer;
