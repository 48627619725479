import React, { useMemo } from 'react';
import moment from 'moment';

import CommandeInterface from 'types/interfaces/commande.interface';

import TableTemplate from 'components/shared/datatable/tableTemplate';
import { Badge, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Capitalize1stLetter, hideFullCell } from 'utils/helpers';
import EtatCommandeBadge from 'components/UI/etatCommandeBadge';
import CustomColorsBadge from 'components/UI/customColorsBadge';

type Props = {
    commandes: CommandeInterface[];
    setCommandes: React.Dispatch<React.SetStateAction<CommandeInterface[]>>;
    pageType: string;
};

const List: React.FC<Props> = ({ commandes, setCommandes, pageType }: Props) => {
    const deleteRow = (id: number) => {
        setCommandes((prev: CommandeInterface[]) =>
            prev.filter((commande: CommandeInterface) => commande.idCommande !== id),
        );
    };

    const tableHeaders = useMemo(
        () => [
            {
                Header: 'Ref/Code',
                accessor: 'code',
                Cell: ({ row }: any) => (
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip className="refNcode_tooltip">
                                Code: {row.original.code}
                                <br />
                                Ref: {row.original.ref ? row.original.ref : '-'}
                            </Tooltip>
                        }
                    >
                        <Button type="button" variant="secondary-outline">
                            <b style={{ fontSize: '0.7rem' }}>{row.original.code.replace('CMD', '')}</b>
                            {row.original.ref && (
                                <>
                                    <br />
                                    <b style={{ fontSize: '0.6rem' }}>{row.original.ref}</b>
                                </>
                            )}
                        </Button>
                    </OverlayTrigger>
                ),
            },
            {
                Header: 'Client',
                accessor: 'client.raisonSocial',
                Cell: ({ row }: any) => <span className="my-xsm-font">{row.original.client.raisonSocial}</span>,
                ...hideFullCell(pageType === 'transfert_cmd'),
            },
            {
                Header: 'Acheteur',
                accessor: 'acheteur.fullName',
                Cell: ({ row }: any) => <span className="my-xsm-font">{row.original.acheteur.fullName}</span>,
            },
            {
                Header: 'GSM',
                accessor: 'acheteur.gsm',
                Cell: ({ row }: any) => <span className="my-xsm-font">{row.original.acheteur.gsm}</span>,
            },
            {
                Header: 'Prix',
                accessor: 'prixTotal',
                Cell: ({ row }: any) => <span className="my-xsm-font">{row.original.prixTotal}</span>,
            },
            {
                Header: 'Ville',
                accessor: 'ville.nomVille',
                Cell: ({ row }: any) => <span className="my-xsm-font">{row.original.ville.nomVille}</span>,
            },
            {
                Header: 'Adresse',
                accessor: 'adresse',
                Cell: ({ row }: any) => <span className="my-xsm-font">{row.original.adresse}</span>,
            },
            {
                Header: 'Créé le',
                accessor: 'dateCreation',
                Cell: ({ row }: any) => (
                    <span className="my-xsm-font">{moment(row.original.dateCreation).format('DD/MM/YY HH:mm')}</span>
                ),
            },
            {
                Header: 'Livrée le',
                accessor: 'dateLivraison',
                Cell: ({ row }: any) => (
                    <span className="my-xsm-font">
                        {row.original.idEtatCommande === 4
                            ? moment(row.original.dateOperation).format('DD/MM/YY HH:mm')
                            : '-'}
                    </span>
                ),
            },
            {
                Header: 'Livreur',
                accessor: 'livreur.nom',
                Cell: ({ row }: any) =>
                    row.original.livreur ? (
                        <span className="my-xsm-font">{Capitalize1stLetter(row.original.livreur.nom)}</span>
                    ) : (
                        '-'
                    ),
            },
            {
                Header: 'Etat',
                accessor: 'etatCommande.etatCommande',
                Cell: ({ row }: any) => <EtatCommandeBadge value={row.original.etatCommande.etatCommande} />,
            },
            {
                Header: 'Historique',
                accessor: 'historique',
                Cell: ({ row }: any) => {
                    const commandeActions = row.original.commandeActions;
                    const lastAction = commandeActions[commandeActions.length - 1];
                    const secondLastAction =
                        commandeActions.length > 1 ? commandeActions[commandeActions.length - 2] : null;
                    return (
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                secondLastAction && lastAction.idEtatTitre === 10 ? (
                                    <Tooltip className="etattitre_tooltip">{secondLastAction.etatTitre?.titre}</Tooltip>
                                ) : (
                                    <></>
                                )
                            }
                        >
                            <div>
                                {lastAction && (
                                    <CustomColorsBadge
                                        value={lastAction.etatTitre?.titre}
                                        textColor={lastAction.etatTitre?.textColor}
                                        backgroundColor={lastAction.etatTitre?.backgroundColor}
                                        className="me-1"
                                    />
                                )}
                                {lastAction?.etatTitre?.titre === 'Creation' && (
                                    <CustomColorsBadge
                                        value={moment(row.original.dateCreation).format('DD/MM/YY HH:mm')}
                                        textColor="#fff"
                                        backgroundColor="#5e837cbd"
                                    />
                                )}
                                {lastAction?.idEtatTitre === 2 && (
                                    <CustomColorsBadge
                                        value={lastAction.description?.split(' - ')[0]}
                                        textColor="#fff"
                                        backgroundColor="#937388"
                                    />
                                )}
                            </div>
                        </OverlayTrigger>
                    );
                },
                className: 'foceVerticalMiddle',
            },
            {
                Header: 'Facture',
                accessor: 'facture.refFacture',
                Cell: ({ row }: any) => {
                    return row.original.facture ? (
                        <>
                            <strong>{row.original.facture.refFacture}</strong>
                            {row.original.facture.idPaiement ? <Badge bg="info">Payée</Badge> : ''}
                        </>
                    ) : (
                        '-'
                    );
                },
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                Cell: ({ row }: any) => (
                    <Button variant="danger" onClick={() => deleteRow(row.original.idCommande)}>
                        Retirer
                    </Button>
                ),
                disableSortBy: true,
                disableFilters: true,
            },
        ],
        [commandes],
    );
    return (
        <TableTemplate
            columns={tableHeaders}
            data={commandes}
            options={{
                pagination: false,
                showGlobalFilter: true,
                debugData: false,
                showFiltersRowByColumn: true,
                rowSelecting: false,
                hiddenColumns: ['userId'],
            }}
        />
    );
};

export default List;
