import React, { Dispatch, SetStateAction, useState, useContext } from 'react';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { Form, Col, Row, Spinner, Button, InputGroup } from 'react-bootstrap';
import Compressor from 'compressorjs';

import FactureLivreurInterface from 'types/interfaces/factureLivreur.interface';

import ImageElement from 'components/UI/imageElement';

import { readFileAsync, readImageTobase64, hasRole } from 'utils/helpers';
import { MAX_SIZE_IMAGE_UPLOAD } from 'constantes/envLoader';
import { postAddPaiement } from 'services/factureLivreur.service';
import { ROLES as RolesMap } from 'constantes/permission-maps';

import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';

const schema = (imgIsRequired: boolean, rest: number) =>
    yup.object({
        montant: yup.number().min(0).max(rest).required(),
        fileImage: imgIsRequired ? yup.mixed().required() : yup.mixed().nullable(),
    });

type Props = {
    current: FactureLivreurInterface;
    setCurrent: Dispatch<SetStateAction<FactureLivreurInterface | null>>;
    setList: Dispatch<SetStateAction<{ items: FactureLivreurInterface[]; count: number }>>;
    close: () => void;
};

const FactureDetails: React.FC<Props> = ({ current, setCurrent, setList, close }: Props) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);
    const [spinIt, setSpinIt] = useState<boolean>(false);

    const submitPaiementForm = async (values: { montant: number; fileImage: string } | any) => {
        try {
            setSpinIt(true);
            if (values.fileImage) {
                try {
                    if (values.fileImage) {
                        const fileResult = await readFileAsync(values.fileImage);
                        values.fileImage =
                            (fileResult as string)?.replace(/^data:image\/(png|jpg|jpeg);base64,/, '') || null;
                    }
                } catch (ex) {
                    values.fileImage = null;
                }
            }
            const response = await postAddPaiement(values, current.idFactureLivreur);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Paiement est ajouté avec succées');
                setCurrent(resultData);
                setList((factures: { items: FactureLivreurInterface[]; count: number }) => {
                    const facturesData = [...factures.items];
                    const factureIndex = facturesData.findIndex(
                        (el: FactureLivreurInterface) => el.idFactureLivreur == resultData.idFactureLivreur,
                    );
                    facturesData[factureIndex] = resultData;
                    return { items: facturesData, count: factures.count };
                });
                setSpinIt(false);
                close();
            }
        } catch (Exception) {
        } finally {
            setSpinIt(false);
        }
    };

    return (
        <Formik
            validationSchema={schema(!hasRole(contextUser?.ROLES, RolesMap.FOURNISSEUR_ADMIN!), current.montantRest!)}
            onSubmit={(values: any) => submitPaiementForm(values)}
            enableReinitialize={true}
            initialValues={{
                montant: current.montantRest,
                fileImage: null,
            }}
        >
            {({ handleSubmit, setFieldValue, values, errors, handleChange }) => (
                <Form noValidate onSubmit={handleSubmit} className="d-grid gap-2">
                    <Row>
                        <Col md={{ span: 6, offset: 3 }}>
                            <Form.Group controlId="form_grp_montant">
                                <Form.Label>Montant</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="number"
                                    name="montant"
                                    value={values.montant}
                                    min={0}
                                    max={current.montantRest}
                                    placeholder="Montant"
                                    onChange={handleChange}
                                    isInvalid={!!errors.montant}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={{ span: 6, offset: 3 }}>
                            <Form.Group controlId="form_grp_fileImage">
                                <Form.Label>Recu</Form.Label>
                                <InputGroup size="sm">
                                    <Form.Control
                                        size="sm"
                                        type="file"
                                        name="fileImage"
                                        placeholder="Recu"
                                        onChange={(event: any) => {
                                            const image = event.currentTarget.files[0];
                                            if (image.size > MAX_SIZE_IMAGE_UPLOAD) {
                                                toast.warning(
                                                    'vous ne pouvez pas télécharger une image de plus de 1mo',
                                                );
                                                return;
                                            } else if (!image.name.match(/.(png|jpg|jpeg)$/i)) {
                                                toast.warning("Image Format n'est pas valide");
                                                return;
                                            }
                                            // Compress the image
                                            new Compressor(image, {
                                                quality: 0.8, // Adjust compression quality as needed
                                                success: (compressedImage) => {
                                                    console.log(image.size, compressedImage.size);
                                                    setFieldValue('fileImage', compressedImage);
                                                },
                                                error: () => {
                                                    toast.warning("Erreur lors de la compression de l'image");
                                                },
                                            });
                                            // setFieldValue('fileImage', image);
                                        }}
                                        isInvalid={!!errors.fileImage}
                                        accept="image/png, image/jpg, image/jpeg"
                                    />
                                </InputGroup>
                            </Form.Group>

                            {values.fileImage && (
                                <div className="text-center my-3">
                                    <ImageElement file={readImageTobase64(values.fileImage)} alt="file recu" fluid />
                                </div>
                            )}
                        </Col>
                    </Row>
                    <div className="d-grid gap-2">
                        <Button variant="primary" type="submit" disabled={!!spinIt}>
                            Enregistrer
                            {!!spinIt && (
                                <>
                                    &nbsp; <Spinner animation="border" size="sm" />
                                </>
                            )}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default FactureDetails;
