import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { TableColumnType } from 'react-bs-datatable';
import { Badge, Button, Col, Form, Row } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faX, faClock } from '@fortawesome/free-solid-svg-icons';

import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import useUserStore, { StoreStateInterface } from 'store/appStore';

import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import CustomModal from 'components/UI/customModal';
import Datatable from 'components/shared/datatable';
import {
    removeDuplicatedArrObj,
    utf8_to_b64,
    b64_to_utf8,
    useQueryMeMo,
    promiseMockResponseParam,
    sortArrByTwoCols,
    sortByNumber,
} from 'utils/helpers';
import ColisInterface from 'types/interfaces/colis.interface';
import RegionInterface from 'types/interfaces/region.interface';
import TarifInterface from 'types/interfaces/tarif.interface';
import ClientInterface from 'types/interfaces/client.interface';
import { getPreparedColis } from 'services/colis.service';
import { getRegionsWVilles } from 'services/region.service';
import { getTarifsForClient } from 'services/tarif.service';
import { getClientsByIdFournisseur } from 'services/client.service';
import { getUserClientAdmin } from 'services/user.service';
import TitleElement from 'components/UI/titleElement';
import FormNewCommande from 'pages/Commande/components/FormNewCommande';

const PreparedColisPage: React.FC = () => {
    const query = useQueryMeMo();
    const history = useNavigate();
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    const setLoadingFalse = useUserStore((state: StoreStateInterface) => state.setLoadingFalse);
    const setLoadingTrue = useUserStore((state: StoreStateInterface) => state.setLoadingTrue);

    const [currentColis, setCurrentColis] = useState<ColisInterface | null>(null);
    const [colis, setColis] = useState<ColisInterface[]>([]);
    const [regions, setRegions] = useState<RegionInterface[]>([]);
    const [tarifs, setTarifs] = useState<TarifInterface[]>([]);
    const [clients, setClients] = useState<ClientInterface[]>([]);
    const [selectedClient, setSelectedClient] = useState<string | null>(null);
    const [selectedClientAdmin, setSelectedClientAdmin] = useState<string | null>(null);

    const [showFormModal, setShowFormModal] = useState<boolean>(false);
    const [nextActionModal, setNextActionModal] = useState<{ idColis?: number; idCommande: number } | null>(null);

    const handleNextActionModalClose = () => {
        setNextActionModal(null);
    };

    const handleFormModalClose = () => {
        setCurrentColis(null);
        setShowFormModal(false);
    };

    const handleFormModalOpen = (data: ColisInterface | null, idColis?: number) => {
        if (!data && !idColis) return;
        let cData = { ...data };
        if (idColis) {
            const colisObj = colis.find((d: ColisInterface) => d.idColis === idColis);
            if (!colisObj) return;
            cData = colisObj;
        }

        setLoadingTrue();
        getTarifsForClient(cData.idClient)
            .then((response: AxiosResponse<TarifInterface[] | any>) => {
                const { data: responseData } = response;
                const sortedArr = sortArrByTwoCols(
                    sortByNumber(responseData, 'idTarif', true),
                    'idOptionService',
                    'idClient',
                    true,
                );
                setTarifs(sortedArr);
                return contextUser?.userData?.type === 'Client'
                    ? promiseMockResponseParam({ data: { idUser: contextUser?.IdUser } })
                    : getUserClientAdmin(cData?.idClient!);
            })
            .then((response: any) => {
                const { data: responseData } = response;
                setSelectedClientAdmin(responseData.idUser);

                setCurrentColis(cData as ColisInterface);
                setShowFormModal(true);
            })
            .catch()
            .finally(() => {
                setLoadingFalse();
            });
    };

    useEffect(() => {
        if (contextUser) {
            if (contextUser.userData?.type === 'Client') {
                getPreparedColis()
                    .then((response: AxiosResponse<ColisInterface[] | any>) => {
                        const { data } = response;
                        setColis(data);
                    })
                    .catch();

                getTarifsForClient()
                    .then((response: AxiosResponse<TarifInterface[] | any>) => {
                        const { data } = response;
                        const filtredData = removeDuplicatedArrObj(data, 'idOptionService') as TarifInterface[];
                        setTarifs(filtredData);
                    })
                    .catch();
            } else if (contextUser.userData?.type === 'Fournisseur') {
                getClientsByIdFournisseur(contextUser.userData?.idFournisseur!)
                    .then((response: AxiosResponse<ClientInterface[] | any>) => {
                        const { data } = response;
                        setClients(data);
                    })
                    .catch();
                getPreparedColis()
                    .then((response: AxiosResponse<ColisInterface[] | any>) => {
                        const { data } = response;
                        setColis(data);
                    })
                    .catch();
            }
        }
    }, [contextUser]);

    useEffect(() => {
        const targetedColis = query.get('target');
        if (targetedColis && colis) {
            const idColis = b64_to_utf8(targetedColis);
            const row = colis.find((d: any) => d.idColis == idColis);
            if (row) {
                history('/commandes/ready-colis', { replace: true });
                handleFormModalOpen(row);
            }
        }
    }, [colis, query]);

    useEffect(() => {
        if (contextUser && selectedClient && contextUser.userData?.type === 'Fournisseur') {
            getPreparedColis(+selectedClient!)
                .then((response: AxiosResponse<ColisInterface[] | any>) => {
                    const { data } = response;
                    setColis(data);
                })
                .catch();
        }
    }, [contextUser, selectedClient]);

    useEffect(() => {
        document.title = 'Nouvelle commande à partir du stock - DS';

        getRegionsWVilles()
            .then((response: AxiosResponse<RegionInterface[] | any>) => {
                const { data } = response;
                setRegions(data);
            })
            .catch();
    }, []);

    const tableHeaders: TableColumnType<any>[] = [
        {
            prop: 'refColis',
            title: 'Ref',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        ...(contextUser?.userData.type === 'Fournisseur'
            ? [
                  {
                      prop: 'client.raisonSocial',
                      title: 'Client',
                      cellProps: {
                          className: 'foceVerticalMiddle',
                      },
                      isFilterable: true,
                      isSortable: true,
                  },
              ]
            : []),
        {
            prop: 'dateEnregistrement',
            title: 'Enregistré',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: ColisInterface) => (
                <span className="my-xsm-font">
                    {row.dateEnregistrement ? moment(row.dateEnregistrement).format('DD/MM/YYYY HH:mm') : '-'}
                </span>
            ),
        },
        {
            prop: 'dateEnvoi',
            title: 'Envoyé',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: ColisInterface) => (
                <span className="my-xsm-font">
                    {row.dateEnvoi ? moment(row.dateEnvoi).format('DD/MM/YYYY HH:mm') : '-'}
                </span>
            ),
        },
        {
            prop: 'null2',
            title: 'Options',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: ColisInterface) => (
                <>
                    {row.fragile && !!row.fragile && (
                        <>
                            <Badge bg="info">Fragile</Badge>&nbsp;
                        </>
                    )}
                    {row.aRecuperer && !!row.aRecuperer && (
                        <>
                            <Badge bg="info">À récuperer</Badge>&nbsp;
                        </>
                    )}
                    {row.aOuvrir && !!row.aOuvrir && (
                        <>
                            <Badge bg="info">À ouvrir</Badge>&nbsp;
                        </>
                    )}
                </>
            ),
        },
        {
            prop: 'moyenTransport',
            title: 'Transport',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'ramassage.dateRamassage',
            title: 'Ramassage',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: ColisInterface) => (
                <span className="my-xsm-font">
                    {(row as any)['ramassage.dateRamassage']
                        ? moment((row as any)['ramassage.dateRamassage']).format('DD/MM/YYYY HH:mm')
                        : '-'}
                </span>
            ),
        },
        {
            prop: 'dateRec',
            title: 'Réçu',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: ColisInterface) => (
                <span className="my-xsm-font">
                    {row.dateRec ? moment(row.dateRec).format('DD/MM/YYYY HH:mm') : '-'}
                </span>
            ),
        },
        {
            prop: 'dateRecPrevu',
            title: 'Rec.Prévu',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: ColisInterface) => (
                <span className="my-xsm-font">{moment(row.dateRecuPrevu).format('DD/MM/YYYY')}</span>
            ),
        },
        {
            prop: 'null',
            title: 'Ramassage',
            alignment: { horizontal: 'center' },
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: ColisInterface) => (
                <>
                    {(row as any)['ramassage.idRamassage'] ? (
                        !!(row as any)['ramassage.etatRamassage'] ? (
                            <FontAwesomeIcon icon={faCheck} size="xs" style={{ color: '#33b76e' }} />
                        ) : (
                            <FontAwesomeIcon icon={faClock} size="xs" style={{ color: '#71a2ad' }} />
                        )
                    ) : (
                        <FontAwesomeIcon icon={faX} size="xs" style={{ color: '#ff833b' }} />
                    )}
                </>
            ),
        },
        {
            prop: 'null',
            title: 'Art.Reste',
            alignment: { horizontal: 'center' },
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: ColisInterface) => {
                const qteResteAcmd = row.colisproduitenvoyes?.reduce((accumulator: number, object: any) => {
                    return accumulator + object.qteResteAcmd;
                }, 0);
                return <>{qteResteAcmd}</>;
            },
        },
        {
            prop: 'null',
            title: '#',
            alignment: { horizontal: 'right' },
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: ColisInterface) => (
                <>
                    <Button className="ms-2" variant="outline-primary" onClick={() => handleFormModalOpen(row)}>
                        Commander
                    </Button>
                </>
            ),
        },
    ];

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { path: '/commandes', text: 'Commandes' },
        { active: true, text: 'Nouvelle commande à partir du stock' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Nouvelle commande à partir du stock
                </TitleElement>
            </div>
            <Row className="mb-2 holdit_notEffect">
                {contextUser && contextUser.userData.type === 'Fournisseur' && (
                    <Form.Group as={Col} lg={4} md={4} controlId="form_grp_Client">
                        <Form.Label>Client:</Form.Label>
                        <Form.Select
                            size="sm"
                            name="selectedClient"
                            value={selectedClient!}
                            onChange={(e) => {
                                const {
                                    target: { value },
                                } = e;
                                setSelectedClient(value);
                            }}
                            placeholder="Selectionner un client"
                        >
                            <option value="">Selectionner un client</option>
                            {clients.map((d: ClientInterface) => (
                                <option key={d.idClient} value={d.idClient}>
                                    {d.raisonSocial}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                )}
            </Row>
            <Datatable
                data={colis}
                tableColumns={tableHeaders}
                paginationOtion={{
                    rowsPerPage: 50,
                    options: [25, 50, 100, 200],
                }}
            />

            <CustomModal
                title="New commande"
                size="lg"
                show={showFormModal}
                handleClose={handleFormModalClose}
                fullscreen
            >
                <FormNewCommande
                    currentColis={currentColis}
                    regions={regions}
                    tarifs={tarifs}
                    selectedClientAdmin={selectedClientAdmin}
                    setColis={setColis}
                    setNextActionModal={setNextActionModal}
                    colis={colis}
                    handleFormModalClose={handleFormModalClose}
                />
            </CustomModal>

            <CustomModal title="Action suivante" show={!!nextActionModal} handleClose={handleNextActionModalClose}>
                {nextActionModal ? (
                    <>
                        <Row>
                            {nextActionModal.idColis && (
                                <Col lg={6} md={6} sm={6} xs={6}>
                                    <div className="d-grid gap-2">
                                        <Button
                                            onClick={() => handleFormModalOpen(null, nextActionModal?.idColis!)}
                                            variant="outline-info"
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </Col>
                            )}

                            <Col lg={6} md={6} sm={6} xs={6}>
                                <Link to={`/commandes?open=${utf8_to_b64(nextActionModal?.idCommande?.toString()!)}`}>
                                    <div className="d-grid gap-2">
                                        <Button variant="outline-primary">Commande détails</Button>
                                    </div>
                                </Link>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <></>
                )}
            </CustomModal>
        </>
    );
};

export default PreparedColisPage;
