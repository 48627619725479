import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import DemandeRetourLivreurInterface from 'types/interfaces/demandeRetourLivreur.interface';

const apiEndPoint = `${apiUrl}/demanderetourlivreur`;

export const getDemandeRetourLivreurs = (
    total: number,
    page: number,
    status: string,
    idLivreur?: number,
): Promise<{ count: number; items: DemandeRetourLivreurInterface[] }> => {
    const query = `?page=${page}&total=${total}${status ? `&status=${status}` : ''}${
        idLivreur ? `&idLivreur=${idLivreur}` : ''
    }`;
    return http.get(`${apiEndPoint}${query}`);
};

export const updateDemandeRetourLivreur = (
    id: number,
    drs: { idDemandeRetourLivreur: number; status: string },
): Promise<DemandeRetourLivreurInterface> => {
    return http.put(`${apiEndPoint}/${id}`, drs);
};

export const updateDemandeRetourLivreurRange = (drs: { ids: number[]; status: string }): Promise<number[]> => {
    return http.put(`${apiEndPoint}/range`, drs);
};
export const createDemandeRetourLivreurRange = (drs: { cmdIds: number[]; idLivreur: number }): Promise<number[]> => {
    return http.post(`${apiEndPoint}/range`, { ids: drs.cmdIds, idLivreur: drs.idLivreur });
};
