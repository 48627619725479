import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { deleteCommande } from 'services/commande.service';
import CommandeInterface from 'types/interfaces/commande.interface';

type Props = {
    commandes: { items: CommandeInterface[]; count: number };
    setCommandes: (value: { items: CommandeInterface[]; count: number }) => void;
    currentCommande: CommandeInterface;
    setCheckedCommandes: (value: number[]) => void;
};

const DeleteButton: React.FC<Props> = ({ commandes, setCommandes, currentCommande, setCheckedCommandes }: Props) => {
    const [spinIt, setSpinIt] = useState<boolean>(false);
    const navigate = useNavigate();
    const submitDeleteCommande = async () => {
        try {
            setSpinIt(true);
            const response = await deleteCommande(currentCommande?.idCommande!);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Colis a été supprimée avec succès');
                setCheckedCommandes([]);
                const cmdData: CommandeInterface[] = commandes.items.filter(
                    (item: CommandeInterface) => item.idCommande !== resultData.idCommande,
                );
                setCommandes({ items: cmdData, count: commandes.count - 1 });
                close();
                navigate(`/commandes`);
                setSpinIt(false);
            }
        } catch (Exception) {
            setSpinIt(false);
            toast.success('Désolé, Cette action ne peut pas être réalisée!');
        }
    };

    return (
        <>
            {currentCommande && (currentCommande.idEtatCommande === 5 || currentCommande.idEtatCommande === 6) && (
                <OverlayTrigger
                    trigger="focus"
                    placement="top"
                    overlay={
                        <Popover id="popover-basic">
                            <Popover.Header>Êtes-vous sûr??</Popover.Header>
                            <Popover.Body className="text-center">
                                <Button
                                    className="ms-2"
                                    variant="danger-primary"
                                    type="button"
                                    disabled={!!spinIt}
                                    onClick={() => submitDeleteCommande()}
                                >
                                    Confirmer
                                    {!!spinIt && (
                                        <>
                                            &nbsp; <Spinner animation="border" size="sm" />
                                        </>
                                    )}
                                </Button>
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <Button size="sm" variant="danger" disabled={!!spinIt}>
                        Supprimer
                    </Button>
                </OverlayTrigger>
            )}
        </>
    );
};

export default DeleteButton;
