import React, { useContext } from 'react';
import moment from 'moment';

import { getFournisseurAliasUrl, utf8_to_b64, isTheOwnerOfCommande, findGroupVilleByVilleId } from 'utils/helpers';
import BarCode from 'components/UI/barCode';
import QrCode from 'components/UI/qrCode';
import CommandeInterface from 'types/interfaces/commande.interface';
import Printer from 'components/shared/printer';
import SiteLogo from 'components/UI/siteLogo';
import { Col, Row } from 'react-bootstrap';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import GroupVilleInterface from 'types/interfaces/groupVilleClone.interface';

type Props = {
    groupVilles: GroupVilleInterface[];
    commandes: CommandeInterface[];
};

const MultiSmallCommandes: React.FC<Props> = ({ groupVilles, commandes }: Props) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);
    return (
        <Printer documentTitle="commande">
            <Row>
                {commandes.map((commande: CommandeInterface, index: number) => (
                    <Col
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        key={index}
                        className={`${index % 2 === 0 ? 'dashed-border-right' : 'ps-0'}`}
                    >
                        <div className={`d-flex justify-content-between align-items-center p-2 `}>
                            <div className="text-center">
                                <div className="d-flex flex-row align-items-center gap-2">
                                    <SiteLogo />
                                </div>
                                {commande.ref ? commande.ref : '-'}
                            </div>

                            <div className="text-end">
                                {commande.coliscommandes.length === 1 ? (
                                    <>
                                        <QrCode
                                            value={`${getFournisseurAliasUrl(
                                                contextUser?.userData!,
                                            )}/commandes?open=${utf8_to_b64(commande?.idCommande?.toString()!)}`}
                                            options={{ size: 50 }}
                                        />
                                        <span className="d-block mt-1 h6 text-dark">
                                            <b>Article :</b>
                                            {commande.coliscommandes[0].colisproduitenvoye.produit?.designation}
                                        </span>
                                    </>
                                ) : (
                                    <QrCode
                                        value={`${getFournisseurAliasUrl(
                                            contextUser?.userData!,
                                        )}/commandes?open=${utf8_to_b64(commande?.idCommande?.toString()!)}`}
                                        options={{ size: 50 }}
                                    />
                                )}
                            </div>
                        </div>
                        <hr style={{ margin: 0 }} />
                        <div className="d-flex justify-content-between p-2">
                            <div className="text-left">
                                <b>Expéditeur</b> (Vendeur): <br />
                                <strong>
                                    {contextUser?.userData.type === 'Client' ||
                                    isTheOwnerOfCommande(contextUser?.userData?.idFournisseur!, commande)
                                        ? commande.client?.raisonSocial
                                        : '****'}
                                </strong>
                                <br />
                                {contextUser?.userData.type === 'Client' ||
                                isTheOwnerOfCommande(contextUser?.userData?.idFournisseur!, commande)
                                    ? commande.client?.telephoneBureau1
                                    : '****'}
                                <br />
                                {moment(commande.dateCreation!).format('DD/MM/YYYYY HH:mm')}
                            </div>
                            <div className="text-left">
                                <b>Détstinataire</b>:<br />- <b>N° Commande</b>: {commande.idCommande}
                                <br />- {commande.acheteur?.fullName}
                                <br />- {commande.acheteur?.gsm}
                                <br />- {commande.ville?.nomVille}
                                <br />- {commande.adresse}
                            </div>
                        </div>
                        <hr style={{ margin: 0 }} />
                        <div className="d-flex justify-content-between align-items-center p-2">
                            <strong>
                                <i>{findGroupVilleByVilleId(groupVilles, commande?.idVille!)?.name! ?? '-'}</i>
                            </strong>
                            <b className="text-end">Prix: {commande.prixTotal} DH</b>
                        </div>
                        <div className="ms-auto order-last">
                            <div className="text-center">
                                <BarCode value={commande.code} options={{ width: 1.5, height: 25, fontSize: 14 }} />
                            </div>
                            <hr style={{ margin: 0, borderTop: ' 1px dashed #000', background: 'inherit' }} />
                        </div>
                    </Col>
                ))}
            </Row>
        </Printer>
    );
};

export default MultiSmallCommandes;
