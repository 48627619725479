import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import DemandeRetourStockInterface from 'types/interfaces/demandeRetourStock.interface';

const apiEndPoint = `${apiUrl}/demanderetourstock`;

export const getDemandeRetourStocks = (
    total: number,
    page: number,
    status: string,
    idClient?: number,
): Promise<{ count: number; items: DemandeRetourStockInterface[] } | any> => {
    const query = `?page=${page}&total=${total}${status ? `&status=${status}` : ''}&idClient=${idClient ?? -1}`;
    return http.get(`${apiEndPoint}/stock${query}`);
};

export const updateDemandeRetourStockRange = (drs: { ids: number[]; status: string }): Promise<number[]> => {
    return http.put(`${apiEndPoint}/range`, drs);
};

export const createDemandeRetourStock = (drs: any): Promise<DemandeRetourStockInterface | any> => {
    return http.post(apiEndPoint, drs);
};
export const createDemandeRetourStockRange = (cmdIds: number[]): Promise<number[] | any> => {
    return http.post(`${apiEndPoint}/range`, { ids: cmdIds });
};
