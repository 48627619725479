import React, { useState, useEffect } from 'react';
import Pagination from 'react-bootstrap/Pagination';

import { multipleNumbersExist } from 'utils/helpers';

type Props = {
    totalPages: number;
    defaultPage?: number;
    searchedData?: string;
    callback?: (page: number, search?: string) => void;
};

const STEP_SIZE = 2;

const Paginator: React.FC<Props> = ({ callback, defaultPage, totalPages, searchedData }: Props) => {
    const [search, setSearch] = useState<string>(searchedData!);
    const [active, setActive] = useState(Number(defaultPage));
    const [items, setItems] = useState<any[]>([]);

    const pageChange = (page: number, filter?: string) => {
        setActive(page);
        if (callback && typeof callback === 'function') callback(page, filter!);
    };

    useEffect(() => {
        if (defaultPage) setActive(Number(defaultPage));
        if (searchedData || searchedData === '') setSearch(searchedData);
    }, [defaultPage, searchedData]);

    useEffect(() => {
        const pages: number[] = [];
        const minLimit = active - STEP_SIZE;
        const maxLimit = active + STEP_SIZE;
        const start = minLimit < 1 ? 1 : minLimit;
        const end = maxLimit > totalPages ? totalPages : maxLimit;

        for (let page = start; page <= end; page++) {
            pages.push(page);
        }

        if (!pages.includes(1)) pages.unshift(1);
        if (!pages.includes(totalPages) && totalPages > 0) pages.push(totalPages);

        const pageNumbers = pages.map((page: number) => (
            <Pagination.Item key={page} active={page === active} onClick={() => pageChange(page, search!)}>
                {page}
            </Pagination.Item>
        ));

        if (!multipleNumbersExist(pages, [1, 2]) && pages.length > STEP_SIZE) {
            pageNumbers.unshift(<Pagination.Item key="..._start">&hellip;</Pagination.Item>);
            const cache_arr = pageNumbers[0];
            pageNumbers[0] = pageNumbers[1];
            pageNumbers[1] = cache_arr;
        }

        if (!multipleNumbersExist(pages, [totalPages - 1, totalPages]) && pages.length > STEP_SIZE) {
            pageNumbers.push(<Pagination.Item key="..._end">&hellip;</Pagination.Item>);
            const cache_arr = pageNumbers[pageNumbers.length - 1];
            pageNumbers[pageNumbers.length - 1] = pageNumbers[pageNumbers.length - 2];
            pageNumbers[pageNumbers.length - 2] = cache_arr;
        }

        setItems(pageNumbers);
    }, [active, totalPages]);
    return totalPages > 0 ? (
        <Pagination size="sm">
            <Pagination.Item disabled={1 === active} onClick={() => pageChange(active - 1, search!)}>
                Prev
            </Pagination.Item>
            {items}
            <Pagination.Item disabled={totalPages === active} onClick={() => pageChange(active + 1, search!)}>
                Next
            </Pagination.Item>
        </Pagination>
    ) : (
        <></>
    );
};

Paginator.defaultProps = {
    defaultPage: 1,
    searchedData: '',
};

export default Paginator;
