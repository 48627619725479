import React, { useContext } from 'react';
import moment from 'moment';

import { getFournisseurAliasUrl, utf8_to_b64, isTheOwnerOfCommande } from 'utils/helpers';
import BarCode from 'components/UI/barCode';
import QrCode from 'components/UI/qrCode';
import CommandeInterface from 'types/interfaces/commande.interface';
import Printer from 'components/shared/printer';
import SiteLogo from 'components/UI/siteLogo';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';

type Props = {
    commande: CommandeInterface;
};

const SmallCommande: React.FC<Props> = ({ commande }: Props) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);
    return (
        <Printer documentTitle="commande">
            <div className="d-flex justify-content-between align-items-center p-2">
                <div className="text-center">
                    <div className="d-flex flex-row align-items-center gap-2">
                        <SiteLogo />
                    </div>
                    {commande.ref ? commande.ref : '-'}
                </div>
                <div className="d-flex flex-column justify-content-between text-end">
                    <BarCode value={commande.code} options={{ height: 25, fontSize: 15 }} />
                </div>
            </div>
            <hr style={{ margin: 0 }} />
            <div className="d-flex justify-content-between align-items-center p-2">
                <div className="text-left">
                    {commande.coliscommandes.length === 1 ? (
                        <>
                            <QrCode
                                value={`${getFournisseurAliasUrl(contextUser?.userData!)}/commandes?open=${utf8_to_b64(
                                    commande?.idCommande?.toString()!,
                                )}`}
                                options={{ size: 50 }}
                            />
                            <span className="d-block mt-1 h6 text-dark">
                                <b>Article :</b>
                                {commande.coliscommandes[0].colisproduitenvoye.produit?.designation}
                            </span>
                        </>
                    ) : (
                        <QrCode
                            value={`${getFournisseurAliasUrl(contextUser?.userData!)}/commandes?open=${utf8_to_b64(
                                commande?.idCommande?.toString()!,
                            )}`}
                            options={{ size: 50 }}
                        />
                    )}
                </div>
                <div className="commande-border">
                    <b>Expéditeur</b> (Vendeur): <br />
                    <strong>
                        {' '}
                        {contextUser?.userData.type === 'Client' ||
                        isTheOwnerOfCommande(contextUser?.userData?.idFournisseur!, commande)
                            ? commande.client?.raisonSocial
                            : '****'}
                    </strong>
                    <br />
                    {contextUser?.userData.type === 'Client' ||
                    isTheOwnerOfCommande(contextUser?.userData?.idFournisseur!, commande)
                        ? commande.client?.telephoneBureau1
                        : '****'}
                    <br />
                    {moment(commande?.dateCreation!).format('DD/MM/YYYYY HH:mm')}
                </div>
                <div className="commande-border">
                    <b>Détstinataire</b>:<br />- <b>N° Commande</b>: {commande.idCommande}
                    <br />- {commande.acheteur?.fullName}
                    <br />- {commande.acheteur?.gsm}
                    <br />- {commande.ville?.nomVille}
                    <br />- {commande.adresse}
                </div>
            </div>
            <hr style={{ margin: 0 }} />
            <div className="d-flex justify-content-end align-items-center p-2 pe-5">
                <b>Prix: {commande.prixTotal} DH</b>
            </div>
        </Printer>
    );
};

export default SmallCommande;
