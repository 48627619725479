import React, { useState, useEffect } from 'react';

import CommandeInterface from 'types/interfaces/commande.interface';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import TitleElement from 'components/UI/titleElement';
import List from 'pages/ScanActions/components/List';
import Scan from 'pages/ScanActions/components/Scan';
import AffectationLivreur from 'pages/ScanActions/components/AffectationLivreur';
import RetourAgence from 'pages/ScanActions/components/RetourAgence';
import Transfert from 'pages/ScanActions/components/Transfert';

import { useQueryMeMo } from 'utils/helpers';

type Props = {
    title: string;
    pageType: string;
};
const LivreurAffectation: React.FC<Props> = ({ title, pageType }: Props) => {
    const [commandes, setCommandes] = useState<CommandeInterface[]>([]);

    const query = useQueryMeMo();

    useEffect(() => {
        setCommandes([]);
    }, [pageType]);

    const breadcrumbData: breadcrumbDataType[] =
        pageType === 'transfert_cmd'
            ? [
                  { path: '/', text: 'Accueil' },
                  { active: true, text: 'Partenariat' },
                  {
                      active: true,
                      text: title,
                  },
              ]
            : [
                  { path: '/', text: 'Accueil' },
                  { path: '/commandes', text: 'Commandes' },
                  {
                      active: true,
                      text: title,
                  },
              ];

    useEffect(() => {
        document.title = `${title} - DS`;
    }, []);

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    {title}
                </TitleElement>
                <Scan pageType={pageType} setCommandes={setCommandes} query={query} />
            </div>
            <List commandes={commandes} setCommandes={setCommandes} pageType={pageType} />

            {pageType === 'affectation_livreur' && (
                <AffectationLivreur commandes={commandes} setCommandes={setCommandes} />
            )}
            {pageType === 'return_cmd' && <RetourAgence commandes={commandes} setCommandes={setCommandes} />}
            {pageType === 'transfert_cmd' && <Transfert commandes={commandes} setCommandes={setCommandes} />}
        </>
    );
};

export default LivreurAffectation;
