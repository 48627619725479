import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import FournisseurInterface from 'types/interfaces/fournisseur.interface';

const apiEndPoint = `${apiUrl}/fournisseurs`;

export const getFournisseurs = (): Promise<FournisseurInterface[] | any> => {
    return http.get(apiEndPoint);
};

export const getPartnersFournisseurs = (): Promise<FournisseurInterface[] | any> => {
    return http.get(`${apiEndPoint}/partners`);
};

export const getFournisseursPaginated = (
    total: number,
    page: number,
    search?: string,
): Promise<{ count: number; items: FournisseurInterface[] } | any> => {
    const query = `?page=${page}&total=${total}${search ? '&search=' + search : ''}`;
    return http.get(`${apiEndPoint}/paginated${query}`);
};

export const toggleDisableFournisseur = (idFournisseur: number): Promise<FournisseurInterface | any> => {
    return http.put(`${apiEndPoint}/toggle-disable/${idFournisseur}`);
};

export const saveFreshOrExistedFournisseur = (data: FournisseurInterface | any, editMode = false) => {
    if (!!editMode) {
        return http.put(`${apiEndPoint}/${data.idFournisseur}`, data);
    }
    return http.post(`${apiEndPoint}/fresh`, data);
};

export const getFournisseursClients = (): Promise<FournisseurInterface[] | any> => {
    return http.get(`${apiEndPoint}/clients`);
};
