import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import GroupVilleInterface from 'types/interfaces/groupVilleClone.interface';

const apiEndPoint = `${apiUrl}/groupvilleclones`;

export const getAllGroupVilleClones = (): Promise<GroupVilleInterface[] | any> => {
    return http.get(apiEndPoint);
};

const addGroupVille = (formData: { name: string }): Promise<GroupVilleInterface | any> => {
    return http.post(apiEndPoint, formData);
};

const updateGroupVille = (
    id: number,
    formData: { idGroupVille: number; name: string },
): Promise<GroupVilleInterface | any> => {
    return http.put(`${apiEndPoint}/${id}`, formData);
};

export const saveGroupVille = (values: any, editMode: boolean) =>
    editMode ? updateGroupVille(values.idGroupVille, values) : addGroupVille(values);

export const addGroupVilleClone = (id: number, formData: { idVille: number }): Promise<GroupVilleInterface | any> => {
    return http.put(`${apiEndPoint}/${id}/add`, formData);
};

export const removeGroupVilleClone = (
    id: number,
    formData: { idVille: number },
): Promise<GroupVilleInterface | any> => {
    return http.put(`${apiEndPoint}/${id}/remove`, formData);
};
