import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Badge, Button, Form, Spinner, Row, Col, Table } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faEdit, faListUl, faMinus, faPlus, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import RegionInterface from 'types/interfaces/region.interface';
import GroupVilleInterface from 'types/interfaces/groupVilleClone.interface';
import { getRegionsWVilles } from 'services/region.service';
import {
    getAllGroupVilleClones,
    saveGroupVille,
    addGroupVilleClone,
    removeGroupVilleClone,
} from 'services/groupVilleClone.service';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import TitleElement from 'components/UI/titleElement';
import TableTemplate from 'components/shared/datatable/tableTemplate';
import CustomModal from 'components/UI/customModal';

const schema = yup.object({
    idGroupVille: yup.number(),
    name: yup.string().required(),
});

const GroupVilleClone: React.FC = () => {
    const [regions, setRegions] = useState<RegionInterface[]>([]);
    const [groupVilles, setGroupVilles] = useState<GroupVilleInterface[]>([]);

    const [currentGroupVille, setCurrentGroupVille] = useState<GroupVilleInterface | null>(null);
    const [showGVModal, setShowGVModal] = useState<boolean>(false);
    const [gVEditMode, setGVEditMode] = useState<boolean>(false);
    const [showGVClonesModal, setShowGVClonesModal] = useState<boolean>(false);

    const openGVModal = (data: GroupVilleInterface | null, editMode = false) => {
        setGVEditMode(editMode);
        setCurrentGroupVille(data);
        setShowGVModal(true);
    };

    const closeGVModal = () => {
        setGVEditMode(false);
        setCurrentGroupVille(null);
        setShowGVModal(false);
    };

    const openGVClonesModal = (data: GroupVilleInterface) => {
        setCurrentGroupVille(data);
        setShowGVClonesModal(true);
    };

    const closeGVClonesModal = () => {
        setCurrentGroupVille(null);
        setShowGVClonesModal(false);
    };

    useEffect(() => {
        document.title = `Villes groupement - DS`;

        getRegionsWVilles()
            .then((response: AxiosResponse<RegionInterface[] | any>) => {
                const { data } = response;
                setRegions(data);
            })
            .catch();

        getAllGroupVilleClones()
            .then((response: AxiosResponse<GroupVilleInterface[]>) => {
                const { data } = response;
                setGroupVilles(data);
            })
            .catch();
    }, []);

    const arrayColumns = useMemo(
        () => [
            {
                Header: 'Désignation',
                accessor: 'name',
            },
            {
                Header: 'Détails',
                accessor: 'groupVilleClones',
                Cell: ({ row: { original } }: { row: { original: GroupVilleInterface } }) => (
                    <div className="d-flex gap-1">
                        {original.groupVilleClones?.length === 0 ? (
                            <span>Aucune données</span>
                        ) : (
                            original.groupVilleClones?.map((item) => (
                                <Badge key={item.idGroupVilleClone} bg="info">
                                    {item.ville?.nomVille!}
                                </Badge>
                            ))
                        )}
                    </div>
                ),
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: 'Actions',
                accessor: '--action',
                Cell: ({ row: { original } }: { row: { original: GroupVilleInterface } }) => (
                    <div className="d-flex gap-1 justify-content-center">
                        <Button variant="outline-secondary" onClick={() => openGVModal(original, true)}>
                            <FontAwesomeIcon icon={faEdit} size="xs" style={{ color: '#36ccce' }} />
                        </Button>
                        <Button variant="outline-secondary" onClick={() => openGVClonesModal(original)}>
                            <FontAwesomeIcon icon={faListUl} size="xs" style={{ color: '#93e987' }} />
                        </Button>
                    </div>
                ),
                disableSortBy: true,
                disableFilters: true,
            },
        ],
        [groupVilles],
    );

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { text: 'Paramétrage' },
        { active: true, text: 'Villes groupement' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Villes groupement
                </TitleElement>
                <div className="d-flex gap-1">
                    <Button
                        variant="outline-secondary"
                        className="button-input-group-effect"
                        id="basic-addon1"
                        onClick={() => openGVModal(null, false)}
                    >
                        <FontAwesomeIcon icon={faAdd} size="xs" style={{ color: '#337ab7' }} />
                    </Button>
                </div>
            </div>

            <TableTemplate
                columns={arrayColumns}
                data={groupVilles}
                options={{
                    pagination: true,
                    showGlobalFilter: false,
                    debugData: false,
                    showFiltersRowByColumn: true,
                    rowSelecting: false,
                    hiddenColumns: ['userId'],
                }}
            />
            <CustomModal
                title={`${!!gVEditMode ? 'Edit' : 'Nouveau'} groupement`}
                size="lg"
                show={showGVModal}
                handleClose={closeGVModal}
            >
                <VilleGroupementForm
                    current={currentGroupVille!}
                    setList={setGroupVilles}
                    editMode={gVEditMode}
                    close={() => closeGVModal()}
                />
            </CustomModal>
            <CustomModal title="Groupement détails" size="lg" show={showGVClonesModal} handleClose={closeGVClonesModal}>
                {currentGroupVille ? (
                    <VilleGroupementDetails
                        current={currentGroupVille!}
                        setCurrent={setCurrentGroupVille}
                        list={groupVilles}
                        setList={setGroupVilles}
                        regions={regions}
                        close={() => closeGVClonesModal()}
                    />
                ) : (
                    <></>
                )}
            </CustomModal>
        </>
    );
};

export default GroupVilleClone;

type VilleGroupementFormType = {
    editMode: boolean;
    current: GroupVilleInterface;
    setList: Dispatch<SetStateAction<GroupVilleInterface[]>>;
    close: () => void;
};

const VilleGroupementForm: React.FC<VilleGroupementFormType> = ({
    current,
    editMode,
    setList,
    close,
}: VilleGroupementFormType) => {
    const [spinIt, setSpinIt] = useState<boolean>();

    const submitForm = async (values: any) => {
        try {
            setSpinIt(true);
            const response = await saveGroupVille(values, editMode);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Groupe a été enregistré avec succès');
                setList((prev) => {
                    const updatingData = [...prev].filter((dd) => dd.idGroupVille !== resultData.idGroupVille);
                    updatingData.unshift(resultData);
                    return updatingData;
                });
                close();
                setSpinIt(false);
            }
        } catch (Exception) {
        } finally {
            setSpinIt(false);
        }
    };

    return (
        <Formik
            validationSchema={schema}
            onSubmit={(values) => submitForm(values)}
            enableReinitialize={true}
            initialValues={{
                idGroupVille: current && !!editMode ? current.idGroupVille : undefined,
                name: current && !!editMode ? current.name : '',
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group controlId="form_grp_nom">
                        <Form.Label>Désignation</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder="Désignation"
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={!!errors.name}
                        />
                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                    <div className="d-grid gap-2 mt-3">
                        <Button variant="primary" type="submit" disabled={!!spinIt}>
                            Enregistrer
                            {!!spinIt && (
                                <>
                                    &nbsp; <Spinner animation="border" size="sm" />
                                </>
                            )}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

type VilleGroupementDetailsType = {
    current: GroupVilleInterface;
    setCurrent: Dispatch<SetStateAction<GroupVilleInterface | null>>;
    list: GroupVilleInterface[];
    setList: Dispatch<SetStateAction<GroupVilleInterface[]>>;
    regions: RegionInterface[];
    close: () => void;
};

const VilleGroupementDetails: React.FC<VilleGroupementDetailsType> = ({
    current,
    setCurrent,
    list,
    setList,
    regions,
    close,
}: VilleGroupementDetailsType) => {
    const [spinIt, setSpinIt] = useState<boolean>();

    const [selectedRegion, setSelectedRegion] = useState<number | null>(null);

    const onAddSubmit = async (idVille: number) => {
        try {
            setSpinIt(true);
            const response = await addGroupVilleClone(current?.idGroupVille, { idVille });
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Région est ajoutée au groupe avec succès');
                setCurrent(resultData);
                setList((prev) => {
                    const updatingData = [...prev].filter((dd) => dd.idGroupVille !== resultData.idGroupVille);
                    updatingData.unshift(resultData);
                    return updatingData;
                });
                false && close(); // disabled close for now
                setSpinIt(false);
            }
        } catch (Exception) {
        } finally {
            setSpinIt(false);
        }
    };
    const onRemoveSubmit = async (idVille: number) => {
        try {
            setSpinIt(true);
            const response = await removeGroupVilleClone(current?.idGroupVille, { idVille });
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Région est supprimée du groupe avec succès');
                setCurrent(resultData);
                setList((prev) => {
                    const updatingData = [...prev].filter((dd) => dd.idGroupVille !== resultData.idGroupVille);
                    updatingData.unshift(resultData);
                    return updatingData;
                });
                false && close(); // disabled close for now
                setSpinIt(false);
            }
        } catch (Exception) {
        } finally {
            setSpinIt(false);
        }
    };

    return (
        <Row>
            <Col md="6">
                <Row>
                    <Col md="2">Ville :</Col>
                    <Col>
                        <Form.Select
                            name="idRegion"
                            value={selectedRegion!}
                            onChange={(e) => setSelectedRegion(+e.target.value)}
                            placeholder="Selectionner une ville"
                            size="sm"
                        >
                            <option>Selectionner une ville</option>
                            {regions.map((d: RegionInterface) => (
                                <option key={d.idRegion} value={d.idRegion}>
                                    {d.nomRegion}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Table responsive bordered striped hover>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th className="text-end">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            {regions
                                .find((item) => item.idRegion.toString() === selectedRegion?.toString()!)
                                ?.villes?.filter(
                                    (ville) =>
                                        !list.some((item) =>
                                            item.groupVilleClones?.some((vv) => vv.idVille === ville.idVille),
                                        ),
                                )
                                ?.map((item) => (
                                    <tr key={item.idVille}>
                                        <td>{item.nomVille}</td>
                                        <td className="text-end">
                                            <Button
                                                size="sm"
                                                variant="outline-info"
                                                onClick={() => onAddSubmit(item.idVille)}
                                                disabled={spinIt}
                                            >
                                                {!!spinIt ? (
                                                    <Spinner animation="border" size="sm" />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faPlus}
                                                        size="xs"
                                                        style={{ color: '#000' }}
                                                    />
                                                )}
                                            </Button>
                                        </td>
                                    </tr>
                                )) ?? <div className="text-center">Aucune Région trouvée</div>}
                        </tbody>
                    </Table>
                </Row>
            </Col>
            <Col md="6">
                <Row>
                    <Col>
                        <div className="d-flex justify-content-between">
                            <div>
                                <strong>{current?.name}</strong>
                            </div>
                            <FontAwesomeIcon
                                icon={faQuestionCircle}
                                title="L'enregistrement se fait automatiquement"
                                size="lg"
                                style={{ color: '##337ab7' }}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Table responsive bordered striped hover>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th className="text-end">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            {current?.groupVilleClones?.length! > 0 ? (
                                current?.groupVilleClones?.map((item) => (
                                    <tr key={item.ville?.idVille}>
                                        <td>{item.ville?.nomVille}</td>
                                        <td className="text-end">
                                            <Button
                                                size="sm"
                                                variant="outline-info"
                                                onClick={() => onRemoveSubmit(item.ville?.idVille!)}
                                                disabled={spinIt}
                                            >
                                                {!!spinIt ? (
                                                    <Spinner animation="border" size="sm" />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faMinus}
                                                        size="xs"
                                                        style={{ color: '#e55757' }}
                                                    />
                                                )}
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <div className="text-center">Aucune Région trouvée</div>
                            )}
                        </tbody>
                    </Table>
                </Row>
            </Col>
        </Row>
    );
};
