import React, { useState } from 'react';
import Select, { MultiValue } from 'react-select';

interface OptionType {
    label: string;
    value: string | number;
}
export interface GroupedOptionType {
    options: OptionType[];
    label?: string;
}

type MultiSelectProps = {
    options: GroupedOptionType[];
    placeholder: string;
    onChange: (selectedItems: string[]) => void;
    classNames?: string;
};
const MultiSelectGroupedComponent: React.FC<MultiSelectProps> = ({
    options,
    placeholder,
    onChange,
    classNames,
}: MultiSelectProps) => {
    const [selectedItems, setSelectedItems] = useState<OptionType[]>([]);

    const handleMultiSelectChange = (option: MultiValue<OptionType>) => {
        const newSelects = Array.isArray(option) ? option : [];
        setSelectedItems(newSelects as OptionType[]);
        onChange((newSelects as OptionType[])?.map((selectedOption: any) => selectedOption.value));
    };

    return (
        <Select
            value={selectedItems}
            placeholder={placeholder}
            options={options}
            onChange={handleMultiSelectChange}
            isMulti={true}
            className={classNames}
            closeMenuOnSelect={false}
        />
    );
};

export default MultiSelectGroupedComponent;
