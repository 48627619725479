import React, { useState } from 'react';
import { Container, Row, Col, Alert, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { passwordUpdate } from 'services/user.service';
import { userChangePassword } from 'types/index';

import * as yup from 'yup';
import { Formik } from 'formik';

const schema = yup.object({
    OldPassword: yup.string().required(),
    Password: yup.string().min(3).required(),
    ConfirmPassword: yup
        .string()
        .oneOf([yup.ref('Password'), null], 'Passwords must match')
        .min(3)
        .required(),
});

const ProfilePasswordChangePage: React.FC = () => {
    const history = useNavigate();
    const [error, setError] = useState(null);

    const submitForm = async (formValues: userChangePassword | any) => {
        try {
            setError(null);
            const { status } = await passwordUpdate(formValues);
            if (status === 200) {
                toast.success('Votre mot de passe à été changé avec succès');
                history('/', { replace: true });
            } else throw new Error();
        } catch (err) {
            const { message = undefined }: any = err;
            setError(message || 'Error occured!');
        }
    };

    return (
        <Container>
            <Row>
                <Col xs={12} md={{ span: 4, offset: 4 }}>
                    <div className="text-center py-3 mt-3">
                        <h2 className="mb-0">Changement de mot de passe</h2>
                        {error && <Alert variant="danger">{error}</Alert>}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={{ span: 4, offset: 4 }}>
                    <Formik
                        validationSchema={schema}
                        onSubmit={(values) => submitForm(values)}
                        enableReinitialize={true}
                        initialValues={{
                            OldPassword: '',
                            Password: '',
                            ConfirmPassword: '',
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group controlId="exampleForm.ControlOldPassword">
                                    <Form.Label>Old Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="oldPassword"
                                        name="OldPassword"
                                        value={values.OldPassword}
                                        onChange={handleChange}
                                        isInvalid={!!errors.OldPassword}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.OldPassword}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="password"
                                        name="Password"
                                        value={values.Password}
                                        onChange={handleChange}
                                        isInvalid={!!errors.Password}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.Password}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlConfirmPassword">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="confirmPassword"
                                        name="ConfirmPassword"
                                        value={values.ConfirmPassword}
                                        onChange={handleChange}
                                        isInvalid={!!errors.ConfirmPassword}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.ConfirmPassword}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className="d-grid gap-2 mt-3">
                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};

export default ProfilePasswordChangePage;
