import React from 'react';

const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter } }) => {
    const count = preFilteredRows.length;

    return (
        <input
            className="form-control form-control-sm  colder-placeholder"
            value={filterValue || ''}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            // placeholder={`Rechercher ${count} enregistrements...`}
            placeholder=""
        />
    );
};

export default DefaultColumnFilter;
