import React, { useState, useContext } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { createDemandeRetourLivreurRange } from 'services/demandeRetourLivreur.service';
import CommandeInterface from 'types/interfaces/commande.interface';

import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import DemandeRetourLivreurInterface from 'types/interfaces/demandeRetourLivreur.interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

type Props = {
    checkedCommandes: number[];
    setCheckedCommandes: (data: number[]) => void;
    idLivreur: number;
    setCommandes: (value: { items: CommandeInterface[]; count: number }) => void;
};
const DemandeRetourTab: React.FC<Props> = ({
    checkedCommandes,
    setCheckedCommandes,
    idLivreur,
    setCommandes,
}: Props) => {
    const [spinIt, setSpinIt] = useState<boolean>(false);
    const { user: contextUser } = useContext<AppContextState>(AppContext);
    const submitDemande = async () => {
        try {
            setSpinIt(true);
            createDemandeRetourLivreurRange({ cmdIds: checkedCommandes, idLivreur })
                .then(() => {
                    setCheckedCommandes([]);
                    (
                        setCommandes as React.Dispatch<
                            React.SetStateAction<{ items: CommandeInterface[]; count: number }>
                        >
                    )((prev: { items: CommandeInterface[]; count: number }) => {
                        const updating = prev.items.map((item: CommandeInterface) => {
                            const newData: DemandeRetourLivreurInterface = {
                                idDemandeRetourLivreur: 9999,
                                idCommande: item.idCommande,
                                dateCreation: new Date(),
                                dateActionLivreur: null,
                                dateFin: null,
                                status: 'En attente',
                                idLivreur,
                                idUserFournisseur: contextUser?.userData?.idUser!,
                                idConfirmer: null,
                                idFournisseur: contextUser?.userData?.idFournisseur!,
                                dead: false,
                                codeDemande: 'not-received-yet',
                            };
                            if (checkedCommandes.includes(item.idCommande)) {
                                return {
                                    ...item,
                                    demandeRetourLivreurs: [...item.demandeRetourLivreurs!, newData],
                                };
                            }
                            return item;
                        });
                        return {
                            items: updating,
                            count: prev.count,
                        };
                    });
                    toast.success('Votre demande est envoyée.');
                    setSpinIt(false);
                    close();
                })
                .catch(() => {
                    setSpinIt(false);
                });
        } catch (Exception) {
            setSpinIt(false);
        }

        setSpinIt(false);
    };

    const close = () => {
        setCheckedCommandes([]);
    };
    return (
        <Tabs defaultActiveKey="demande_retour" transition={false} id="demande_retour_tabs" className="mt-1">
            <Tab eventKey="demande_retour" title={<strong>Demande retour</strong>} className="tab-content-border">
                <div className="d-flex gap-3 justify-content-between">
                    <div>
                        <b>{checkedCommandes.length}</b> commande(s) selectionnée(s)
                        <p>Voulez-vous vraiment envoyer cet demande de retour ?</p>
                    </div>
                    <div className="d-flex gap-3 justify-content-center mt-2">
                        <Button variant="outline-danger" onClick={() => submitDemande()} disabled={spinIt}>
                            Confirmer
                        </Button>
                        <Button variant="outline-secondary" onClick={close} disabled={spinIt}>
                            <FontAwesomeIcon icon={faX} size="xs" />
                        </Button>
                    </div>
                </div>
            </Tab>
        </Tabs>
    );
};

export default DemandeRetourTab;
