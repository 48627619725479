import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { Button, InputGroup } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

import AcheteurInterface from 'types/interfaces/acheteur.interface';
import { getFilterAcheteursByGSM } from 'services/acheteur.service';

type Props = {
    setField: (field: string, value: any) => void;
    fieldValuesData: any;
    InitValue: any;
};

const SearchAcheteurByGSM: React.FC<Props> = ({ setField, fieldValuesData, InitValue }: Props) => {
    const [acheteurs, setAcheteurs] = useState<AcheteurInterface[]>([]);
    const [spinIt, setSpinIt] = useState<boolean>(false);

    const handleAcheteurSearch = (query: string) => {
        setSpinIt(true);

        getFilterAcheteursByGSM(query)
            .then((response: AxiosResponse<AcheteurInterface[] | any>) => {
                const { status, data } = response;
                if (status === 200) {
                    setAcheteurs(data);
                }
                setSpinIt(false);
            })
            .catch(() => setSpinIt(false));
    };

    return (
        <InputGroup className="mb-3">
            <AsyncTypeahead
                id="async_acheteur_GSM"
                filterBy={() => true}
                isLoading={spinIt}
                labelKey={(option: any) => option.gsm}
                minLength={3}
                onSearch={(term: string) => {
                    handleAcheteurSearch(term);
                    setField('acheteur.gsm', term);
                }}
                selected={fieldValuesData.idAcheteur ? [fieldValuesData] : undefined}
                options={acheteurs}
                placeholder="Chercher un acheteur by GSM."
                size="sm"
                renderMenuItemChildren={(option: any) => (
                    <>
                        {option.gsm} | {option.fullName!}
                    </>
                )}
                onChange={(eData: any[]) => {
                    if (eData && eData.length > 0) {
                        const clientData = eData[0];
                        setField('acheteur', clientData);
                    }
                }}
                disabled={!!fieldValuesData.idAcheteur}
            />
            {fieldValuesData.idAcheteur && (
                <Button
                    variant="outline-info"
                    id="button-addon2"
                    onClick={() => {
                        setField('acheteur', InitValue);
                    }}
                >
                    <FontAwesomeIcon icon={faX} size="sm" />
                </Button>
            )}
        </InputGroup>
    );
};

export default SearchAcheteurByGSM;
