import React from 'react';
import PropTypes from 'prop-types';
import { Table as BTable } from 'react-bootstrap';
import {
    useTable,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    usePagination,
    useGroupBy,
    useExpanded,
} from 'react-table';
import DefaultColumnFilter from './inputs/defaultColumnFilter';
import GlobalFilter from './inputs/globalFilter';
import IndeterminateCheckbox from './inputs/indeterminateCheckbox';
import Paginator from './inputs/paginator';
import { fuzzyTextFilterFn } from './utils';

//  Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

const TableTemplate = ({ columns, data, options, children }) => {
    const {
        pagination,
        showGlobalFilter,
        debugData,
        showFiltersRowByColumn,
        rowSelecting,
        hiddenColumns,
        autoGrouping,
    } = options;
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        [],
    );

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        [],
    );
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows: rowsFromHooks,
        page, // Instead of using 'rows', we'll use page,

        prepareRow,
        state,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,

        selectedFlatRows,

        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGroupBy,
        toggleRowExpanded,
        isAllRowsExpanded,
        toggleAllRowsExpanded,
        state: { selectedRowIds, pageIndex, pageSize },
    } = useTable(
        {
            initialState: { hiddenColumns, pageSize: 100 },
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useGroupBy, // Add useGroupBy hook
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,

        (hooks) => {
            rowSelecting &&
                hooks.visibleColumns.push((columns) => [
                    // Let's make a column for selection
                    {
                        id: 'selection',
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <div>
                                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                            </div>
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({ row }) => (
                            <div>
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                            </div>
                        ),
                    },
                    ...columns,
                ]);
        },
    );

    // Handle auto-grouping
    React.useEffect(() => {
        if (autoGrouping && autoGrouping.columnId) {
            setGroupBy([autoGrouping.columnId]);
        }
    }, [autoGrouping, setGroupBy]);

    let rows = [];
    if (!!pagination) rows = page;
    else rows = rowsFromHooks;

    // Render the UI for your table
    return (
        <>
            {children && children}
            {!!showGlobalFilter && (
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
            )}
            <BTable striped bordered hover responsive size="sm" {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup, index) => (
                        <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, index) => (
                                <th
                                    key={index}
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    style={{ verticalAlign: 'middle' }}
                                    className="px-2"
                                >
                                    <div className="d-flex justify-content-between">
                                        <div>{column.render('Header')}</div>
                                        {/* Add a sort direction indicator */}
                                        <div style={{ cursor: 'pointer' }}>
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    ' 🔽'
                                                ) : (
                                                    ' 🔼'
                                                )
                                            ) : (
                                                <div style={{ width: '13.78px' }}></div>
                                            )}
                                        </div>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}

                    {!!showFiltersRowByColumn &&
                        headerGroups.map((headerGroup, index) => (
                            <tr key={index}>
                                {headerGroup.headers.map((column, index) => (
                                    <th key={index} style={{ verticalAlign: 'middle' }}>
                                        {/* Render the columns filter UI */}
                                        {column.canFilter ? column.render('Filter') : null}
                                    </th>
                                ))}
                            </tr>
                        ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        // Check if the row is a group header
                        if (row.isGrouped) {
                            return (
                                <tr {...row.getRowProps()} onClick={() => toggleRowExpanded(row.id)}>
                                    <td colSpan={visibleColumns.length}>
                                        {row.isExpanded ? '👇' : '👉'} {row.values[autoGrouping.columnId]}
                                    </td>
                                </tr>
                            );
                        } else if (row.isAggregated) {
                            // Render aggregated rows here if needed
                        } else {
                            // Render a standard or sub-row
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                    })}
                                </tr>
                            );
                        }
                    })}
                    {rows.length === 0 && (
                        <tr>
                            <td colSpan="100%">no data</td>
                        </tr>
                    )}
                </tbody>
            </BTable>
            {!!pagination && (
                <Paginator
                    pageCount={pageCount}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pageOptions={pageOptions}
                />
            )}
            {!!debugData && (
                <>
                    <div>
                        {!!pagination && <>Showing the first 20 </>}results of {rows.length} rows
                    </div>
                    <div>
                        <pre>
                            <code>{JSON.stringify(state.filters, null, 2)}</code>
                        </pre>
                    </div>
                    <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
                    <pre>
                        <code>
                            {JSON.stringify(
                                {
                                    selectedRowIds: selectedRowIds,
                                    'selectedFlatRows[].original': selectedFlatRows.map((d) => d.original),
                                },
                                null,
                                2,
                            )}
                        </code>
                    </pre>
                </>
            )}
        </>
    );
};

TableTemplate.defaultProps = {
    options: {
        pagination: true,
        showGlobalFilter: true,
        debugData: false,
        showFiltersRowByColumn: true,
        rowSelecting: false,
        hiddenColumns: [],
        autoGrouping: null,
    },
    children: undefined,
};

export default TableTemplate;

const CellWrapper = ({ children, position }) => <div className={`text-${position}`}>{children}</div>;

CellWrapper.prototype = {
    position: PropTypes.oneOf(['center', 'right', 'left']),
};
CellWrapper.defaultProps = {
    position: 'left',
};

export { CellWrapper };
