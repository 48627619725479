import React, { useEffect, useMemo, useState } from 'react';
import { Accordion, OverlayTrigger, Popover, Tooltip, Button, Badge } from 'react-bootstrap';
import moment from 'moment';
import { toast } from 'react-toastify';

import TableTemplate from 'components/shared/datatable/tableTemplate';
import { getTransfertToReturn, putReturn, putMassAction } from 'services/transfert.service';
import TransfertCommandeInterface from 'types/interfaces/transfertCommande.interface';
import SearchLocalByCodeRefFormModal, {
    ScanButtonTrigger,
} from 'pages/Transfert/components/searchLocalByCodeRefFormModal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonWalkingArrowLoopLeft, faSync } from '@fortawesome/free-solid-svg-icons';

type Props = {
    eventKey: string;
    scanMode: boolean;
};

const ACTION_TYPE = 'to-return';

const RequestsToReturn: React.FC<Props> = ({ eventKey, scanMode }: Props) => {
    const [show, setShow] = useState<boolean>(false);
    const [transferts, setTransferts] = useState<TransfertCommandeInterface[]>([]);
    const [spinIt, setSpinIt] = useState(false);

    useEffect(() => {
        !scanMode ? LoadData() : clearData();
    }, [scanMode]);

    const clearData = () => {
        setTransferts([]);
    };

    const deleteRow = (id: number) => {
        setTransferts((prev: TransfertCommandeInterface[]) =>
            prev.filter((t: TransfertCommandeInterface) => t.idTransfertCommande !== id),
        );
    };

    const submitAction = (id: number) => {
        setSpinIt(true);
        putReturn(id)
            .then(() => {
                setTransferts(transferts.filter((d) => d.idTransfertCommande !== id));
                toast.success('Demande de retour est traitée avec success');
            })
            .catch(() => {
                toast.error('Une erreur est survenue');
            })
            .finally(() => {
                setSpinIt(false);
            });
    };

    const submitMassAction = async (values: { ids: number[]; action: string }) => {
        setSpinIt(true);
        putMassAction(values)
            .then(({ data: resultData }: any) => {
                setTransferts(transferts.filter((d) => !resultData.includes(d.idTransfertCommande)));
                toast.success('Demande(s) de retour traitée(s) avec success');
            })
            .catch(() => {
                toast.error('Une erreur est survenue');
            })
            .finally(() => {
                setSpinIt(false);
            });
    };

    const LoadData = () => {
        getTransfertToReturn()
            .then(({ data }) => {
                setTransferts(data);
            })
            .catch();
    };

    const tableHeaders = useMemo(
        () => [
            {
                Header: 'Ref/Code',
                accessor: 'commande.code',
                Cell: ({ row }: any) => (
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip className="refNcode_tooltip">
                                Code: {row.original.commande.code}
                                <br />
                                Ref: {row.original.commande.ref ? row.original.commande.ref : '-'}
                            </Tooltip>
                        }
                    >
                        <Button type="button" variant="secondary-outline">
                            <b style={{ fontSize: '0.7rem' }}>{row.original.commande.code.replace('CMD', '')}</b>
                            {row.original.commande.ref && (
                                <>
                                    <br />
                                    <b style={{ fontSize: '0.6rem' }}>{row.original.commande.ref}</b>
                                </>
                            )}
                        </Button>
                    </OverlayTrigger>
                ),
            },
            {
                Header: 'Source',
                accessor: 'fournisseurSource.raisonSocial',
            },
            {
                Header: 'Ville',
                accessor: 'commande.ville.nomVille',
            },
            {
                Header: 'Date',
                accessor: 'dateCreation',
                Cell: ({ row: { original } }: { row: { original: TransfertCommandeInterface } }) => (
                    <>{moment(original.dateCreation).format('DD/MM/YYYY HH:mm:ss')}</>
                ),
            },
            {
                Header: 'Tarif',
                accessor: 'prixTarif',
                Cell: ({ row }: any) => <>{row.original.prixTarif} DH</>,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                Cell: ({ row: { original } }: { row: { original: TransfertCommandeInterface } }) =>
                    scanMode ? (
                        <Button variant="danger" onClick={() => deleteRow(original.idTransfertCommande)}>
                            Retirer
                        </Button>
                    ) : (
                        <OverlayTrigger
                            trigger="focus"
                            placement="top"
                            overlay={
                                <Popover id="popover-basic">
                                    <Popover.Header>Êtes-vous sûr??</Popover.Header>
                                    <Popover.Body className="text-center">
                                        <Button
                                            size="sm"
                                            type="button"
                                            variant="success"
                                            onClick={() => submitAction(original.idTransfertCommande)}
                                            disabled={!!spinIt}
                                        >
                                            Confirmer
                                        </Button>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <Button size="sm" variant="warning" disabled={!!spinIt}>
                                retourner
                            </Button>
                        </OverlayTrigger>
                    ),
                disableSortBy: true,
                disableFilters: true,
            },
        ],
        [transferts, scanMode],
    );

    return (
        <>
            {scanMode && (
                <SearchLocalByCodeRefFormModal
                    setShow={setShow}
                    show={show}
                    pageType={ACTION_TYPE}
                    setTransferts={setTransferts}
                />
            )}
            <div className="d-flex justify-content-between gap-2 pe-1 pt-1 border-top-0">
                <Accordion.Item eventKey={eventKey} className="w-100">
                    <Accordion.Header>
                        <div className="d-flex justify-content-between align-items-start w-100 me-2">
                            <span>
                                Demandes de Retour{' '}
                                <Badge bg={transferts.length > 0 ? 'info' : 'warning'}>{transferts.length}</Badge>
                            </span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <TableTemplate
                            columns={tableHeaders}
                            data={transferts}
                            options={{
                                pagination: false,
                                showGlobalFilter: true,
                                debugData: false,
                                showFiltersRowByColumn: true,
                                rowSelecting: false,
                                hiddenColumns: ['userId'],
                            }}
                        />
                    </Accordion.Body>
                </Accordion.Item>
                <div className="d-flex flex-column gap-2 pt-1">
                    {!scanMode && (
                        <Button onClick={() => LoadData()}>
                            <FontAwesomeIcon icon={faSync} />
                        </Button>
                    )}
                    {scanMode && (
                        <>
                            <ScanButtonTrigger setShow={setShow} />
                            {transferts.length > 0 && (
                                <OverlayTrigger
                                    trigger="focus"
                                    placement="top"
                                    overlay={
                                        <Popover id="popover-basic">
                                            <Popover.Header>Êtes-vous sûr??</Popover.Header>
                                            <Popover.Body className="text-center">
                                                <Button
                                                    size="sm"
                                                    type="button"
                                                    variant="success"
                                                    onClick={() =>
                                                        submitMassAction({
                                                            ids: transferts.map((d) => d.idTransfertCommande),
                                                            action: ACTION_TYPE,
                                                        })
                                                    }
                                                    disabled={!!spinIt}
                                                >
                                                    Retourer
                                                </Button>
                                            </Popover.Body>
                                        </Popover>
                                    }
                                >
                                    <Button size="sm" title="Retourer" variant="outline-warning" disabled={!!spinIt}>
                                        <FontAwesomeIcon icon={faPersonWalkingArrowLoopLeft} />
                                    </Button>
                                </OverlayTrigger>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default RequestsToReturn;
