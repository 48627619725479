import React from 'react';
import dayjs from 'dayjs';
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import TransfertFactureCommandeInterface from 'types/interfaces/transfertFactureCommande.interface';
import TransfertFactureInterface from 'types/interfaces/transfertFacture.interface';

type Props = {
    facture: TransfertFactureInterface;
};
const TransfertFactureDetails: React.FC<Props> = ({ facture }: Props) => {
    const { transfertFactureCommandes: factureDetails } = facture;
    return (
        <>
            <div className="d-flex justify-content-between m-1">
                <div>
                    <div>
                        <strong>{facture.ref}</strong>
                    </div>
                    <div>
                        Créée le <b>{dayjs(facture.dateCreation).format('DD/MM/YYYY HH:mm')}</b>
                    </div>
                </div>
                <div className="text-end">
                    <span className="my-xsm-font">
                        {facture.paid === true && (
                            <>
                                <br />
                                {facture.confirmed! && (
                                    <>
                                        <FontAwesomeIcon
                                            icon={faCheckCircle}
                                            size="lg"
                                            style={{ color: '#1d9c0c' }}
                                            width="20"
                                        />{' '}
                                    </>
                                )}
                                Payée le <b>{dayjs(facture.dateFin).format('DD/MM/YYYY HH:mm')}</b>
                            </>
                        )}
                    </span>
                </div>
            </div>
            <div className="m-2">
                <h6> Facture détails</h6>
            </div>
            <Table responsive striped>
                <thead>
                    <tr>
                        <th>Commande</th>
                        <th>Prix de commande</th>
                        <th>Date</th>
                        <th>Ville</th>
                        <th>Prix</th>
                    </tr>
                </thead>
                <tbody>
                    {factureDetails?.map((facture: TransfertFactureCommandeInterface, index: number) => (
                        <tr key={index}>
                            <td>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip className="refNcode_tooltip">
                                            Code: {facture.transfertCommande?.commande?.code}
                                            <br />
                                            Ref:{' '}
                                            {facture.transfertCommande?.commande?.ref
                                                ? facture.transfertCommande?.commande?.ref
                                                : '-'}
                                        </Tooltip>
                                    }
                                >
                                    <Button type="button" variant="secondary-outline">
                                        <b style={{ fontSize: '0.7rem' }}>
                                            {facture.transfertCommande?.commande?.code.replace('CMD', '')}
                                        </b>
                                        {facture.transfertCommande?.commande?.ref && (
                                            <>
                                                <br />
                                                <b style={{ fontSize: '0.6rem' }}>
                                                    {facture.transfertCommande?.commande?.ref}
                                                </b>
                                            </>
                                        )}
                                    </Button>
                                </OverlayTrigger>
                            </td>
                            <td>{facture.transfertCommande?.commande?.prixTotal!} DH</td>
                            <td>{dayjs(facture.transfertCommande?.dateCreation).format('DD/MM/YYYY')}</td>
                            <td>{facture.transfertCommande?.commande?.ville?.nomVille}</td>
                            <td>{facture.transfertCommande?.prixTarif} DH</td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={2} style={{ textAlign: 'right' }}>
                            Total Commande :
                        </td>
                        <td>
                            <b>
                                {factureDetails?.reduce(
                                    (total, facture) => total + facture.transfertCommande?.commande?.prixTotal!,
                                    0,
                                )}{' '}
                                DH
                            </b>
                        </td>
                        <td style={{ textAlign: 'right' }}>Total Facturation :</td>
                        <td>
                            <b>
                                {factureDetails?.reduce(
                                    (total, facture) => total + facture.transfertCommande?.prixTarif!,
                                    0,
                                )}{' '}
                                DH
                            </b>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4} style={{ textAlign: 'right' }}>
                            <b>Total :</b>
                        </td>
                        <td>
                            <b>
                                {(factureDetails?.reduce(
                                    (total, facture) => total + facture.transfertCommande?.commande?.prixTotal!,
                                    0,
                                ) ?? 0) -
                                    (factureDetails?.reduce(
                                        (total, facture) => total + facture.transfertCommande?.prixTarif!,
                                        0,
                                    ) ?? 0)}{' '}
                                DH
                            </b>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </>
    );
};

export default TransfertFactureDetails;
