import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import TarifInterface, { TarifsMultipleCreationInterface } from 'types/interfaces/tarif.interface';

const apiEndPoint = `${apiUrl}/tarifs`;

export const getTarifsForClient = (idClient?: number): Promise<TarifInterface[] | any> => {
    return http.get(`${apiEndPoint}/client${idClient ? '?idClient=' + idClient : ''}`);
};

export const getTarifsFiltered = (
    total: number,
    page: number,
    idClient?: number | string,
    idVille?: number | string,
): Promise<{ count: number; items: TarifInterface[] } | any> => {
    const query = `?page=${page}&total=${total}${idClient ? '&idClient=' + idClient : ''}${
        idVille ? '&idVille=' + idVille : ''
    }`;
    return http.get(`${apiEndPoint}/filter${query}`);
};

export const createMultipleTarif = (tarif: TarifsMultipleCreationInterface, idClient?: number | string) => {
    return http.post(`${apiEndPoint}/multiple${idClient ? '?clientId=' + idClient : ''}`, tarif);
};
