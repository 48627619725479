import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { Button, Form, Spinner } from 'react-bootstrap';
import { TableColumnType } from 'react-bs-datatable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';

import ServiceInterface from 'types/interfaces/service.interface';
import { getServices, saveService } from 'services/service.service';

import CustomModal from 'components/UI/customModal';
import Datatable from 'components/shared/datatable';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import TitleElement from 'components/UI/titleElement';

const schema = yup.object({
    idService: yup.number(),
    nomService: yup.string().required(),
    descriptionSservice: yup.string().required(),
});

const ServicePage: React.FC = () => {
    const [services, setServices] = useState<ServiceInterface[]>([]);
    const [currentService, setCurrentService] = useState<ServiceInterface | null>(null);

    const [showFormModal, setShowFormModal] = useState<boolean>(false);
    const [formModalEditMode, setFormModalEditMode] = useState<boolean>(false);
    const [spinIt, setSpinIt] = useState<boolean>(false);

    const handleFormModalClose = () => {
        setCurrentService(null);
        setShowFormModal(false);
    };

    const handleFormModalOpen = (editMode = false, servicesData?: ServiceInterface) => {
        if (servicesData) setCurrentService(servicesData);
        setFormModalEditMode(editMode);
        setShowFormModal(true);
    };

    const tableHeaders: TableColumnType<any>[] = [
        {
            prop: 'nomService',
            title: 'Nom',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'descriptionSservice',
            title: 'Description',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'null',
            title: 'Action',
            alignment: { horizontal: 'right' },
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: ServiceInterface) => (
                <Button
                    variant="outline-secondary"
                    className="button-input-group-effect"
                    id="basic-addon1"
                    onClick={() => handleFormModalOpen(true, row)}
                >
                    <FontAwesomeIcon icon={faEdit} size="xs" style={{ color: '#337ab7' }} />
                </Button>
            ),
            isFilterable: false,
            isSortable: false,
        },
    ];

    useEffect(() => {
        document.title = 'Paramétrage Service - DS';

        getServices()
            .then((response: AxiosResponse<ServiceInterface | any>) => {
                const { data } = response;
                setServices(data);
            })
            .catch();
    }, []);

    const submitForm = async (serviceValues: ServiceInterface | any) => {
        try {
            setSpinIt(true);
            const response = await saveService(serviceValues);
            if (response.status === 200) {
                const { data: resultData } = response;
                toast.success('Service a été enregistré avec succès');
                const servicesData = [...services].filter((dd) => dd.idService !== resultData.idService);
                servicesData.unshift(resultData);
                setServices(servicesData);
                handleFormModalClose();
                setSpinIt(false);
            }
        } catch (Exception) {
            setSpinIt(false);
        }
    };

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        { text: 'Paramétrage' },
        { active: true, text: 'Service' },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Services
                </TitleElement>
                <Button
                    variant="outline-secondary"
                    className="button-input-group-effect"
                    id="basic-addon1"
                    onClick={() => handleFormModalOpen()}
                >
                    <FontAwesomeIcon icon={faPlus} size="xs" style={{ color: '#337ab7' }} />
                </Button>
            </div>
            <Datatable data={services} tableColumns={tableHeaders} />
            <CustomModal
                title={`${!!formModalEditMode ? 'Edit' : 'Nouveau'} Service`}
                size="lg"
                show={showFormModal}
                handleClose={handleFormModalClose}
            >
                <div>
                    <Formik
                        validationSchema={schema}
                        onSubmit={(values) => submitForm(values)}
                        enableReinitialize={true}
                        initialValues={{
                            idService: currentService && !!formModalEditMode ? currentService.idService : undefined,
                            nomService: currentService && !!formModalEditMode ? currentService.nomService : '',
                            descriptionSservice:
                                currentService && !!formModalEditMode ? currentService.descriptionSservice : '',
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                {values.idService && (
                                    <Form.Control
                                        type="hidden"
                                        name="idService"
                                        value={values.idService}
                                        readOnly={true}
                                    />
                                )}
                                <Form.Group controlId="form_grp_nom">
                                    <Form.Label>Nom Service</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="nomService"
                                        placeholder="Nom Service"
                                        value={values.nomService}
                                        onChange={handleChange}
                                        isInvalid={!!errors.nomService}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.nomService}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="form_grp_nom">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="descriptionSservice"
                                        placeholder="Description"
                                        value={values.descriptionSservice}
                                        onChange={handleChange}
                                        isInvalid={!!errors.descriptionSservice}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.descriptionSservice}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className="d-grid gap-2 mt-3">
                                    <Button variant="primary" type="submit" disabled={!!spinIt}>
                                        Enregistrer
                                        {!!spinIt && (
                                            <>
                                                &nbsp; <Spinner animation="border" size="sm" />
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </CustomModal>
        </>
    );
};

export default ServicePage;
