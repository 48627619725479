import React, { Dispatch, SetStateAction } from 'react';
import { Accordion } from 'react-bootstrap';
import DraftCommandeInterface from 'types/interfaces/draftCommande.interface';
import PrintDrafts from 'pages/DraftCommande/components/PrintDrafts';
import DeleteDraftCommandesForm from 'pages/DraftCommande/components/DeleteDraftsForm';
import TransferDraftCommandesForm from 'pages/DraftCommande/components/TransferDraftCommandesForm';

type Props = {
    close: () => void;
    list: DraftCommandeInterface[];
    setList: Dispatch<SetStateAction<DraftCommandeInterface[]>>;
    checks: number[];
    setChecks: (data: number[]) => void;
    idClient?: number;
};
const ActionDraft: React.FC<Props> = ({ close, list, setList, setChecks, checks, idClient }: Props) => {
    return (
        <>
            <Accordion className="mb-2">
                <Accordion.Item eventKey="0">
                    <Accordion.Header className="c-warning-header">Suppression ?</Accordion.Header>
                    <Accordion.Body>
                        <DeleteDraftCommandesForm
                            setList={setList}
                            ids={checks}
                            clear={() => setChecks([])}
                            close={close}
                        />
                    </Accordion.Body>
                </Accordion.Item>
                <div className="my-1"></div>
                <Accordion.Item eventKey="1">
                    <Accordion.Header className="c-info-header">Transfert ?</Accordion.Header>
                    <Accordion.Body>
                        <TransferDraftCommandesForm
                            setList={setList}
                            ids={checks}
                            clear={() => setChecks([])}
                            close={close}
                            idClient={idClient}
                        />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <PrintDrafts drafts={list} />
        </>
    );
};

export default ActionDraft;
