import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Alert, Form, Button } from 'react-bootstrap';
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { vrifyResetToken, changeResetPassword } from 'services/authService';
import { userActivePassword } from 'types/index';

import * as yup from 'yup';
import { Formik } from 'formik';

const schema = yup.object({
    Password: yup.string().min(3).required(),
    ConfirmPassword: yup
        .string()
        .oneOf([yup.ref('Password'), null], 'Passwords must match')
        .min(3)
        .required(),
});

const ResetPasswordPage: React.FC = () => {
    const history = useNavigate();
    const location = useLocation();
    const { token } = useParams();
    const [isValide, setIsValide] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (token) {
            const verifyToken = async () => {
                try {
                    const { status } = await vrifyResetToken(token);
                    if (status === 200) setIsValide(true);
                    else throw new Error();
                } catch (err) {
                    const { message = undefined }: any = err;
                    setError(message || 'Error occured!');
                }
            };
            verifyToken();
        }
    }, [token, location]);

    const submitForm = async (formValues: userActivePassword | any) => {
        try {
            setError(null);
            const {
                status,
                data: { message },
            } = await changeResetPassword(token!, formValues);
            if (status === 200) {
                toast.success(message);
                history('/login', { replace: true });
            } else throw new Error();
        } catch (err) {
            const { message = undefined }: any = err;
            setError(message || 'Error occured!');
        }
    };

    return (
        <Container>
            <Row>
                <Col xs={12} md={{ span: 4, offset: 4 }}>
                    <div className="text-center py-3 mt-3">
                        <h2 className="mb-0">Réinitialisation</h2>
                        {!error && <p>Changement de mot de passe</p>}
                        {error && (
                            <>
                                <Alert variant="danger">{error}</Alert>
                                <Link to="/login">Go To Login Page</Link>
                            </>
                        )}
                    </div>
                </Col>
            </Row>
            {isValide === true && (
                <Row>
                    <Col xs={12} md={{ span: 4, offset: 4 }}>
                        <Formik
                            validationSchema={schema}
                            onSubmit={(values) => submitForm(values)}
                            enableReinitialize={true}
                            initialValues={{
                                Password: '',
                                ConfirmPassword: '',
                            }}
                        >
                            {({ handleSubmit, handleChange, values, errors }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Group controlId="exampleForm.ControlPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="password"
                                            name="Password"
                                            value={values.Password}
                                            onChange={handleChange}
                                            isInvalid={!!errors.Password}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.Password}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="exampleForm.ControlConfirmPassword">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="confirmPassword"
                                            name="ConfirmPassword"
                                            value={values.ConfirmPassword}
                                            onChange={handleChange}
                                            isInvalid={!!errors.ConfirmPassword}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.ConfirmPassword}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <div className="d-grid gap-2 mt-3">
                                        <Button variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default ResetPasswordPage;
