import http from 'services/httpService';
import { apiUrl } from 'constantes/envLoader';
import VilleInterface from 'types/interfaces/ville.interface';

const apiEndPoint = `${apiUrl}/villes`;

export const getVilles = (): Promise<VilleInterface[] | any> => {
    return http.get(apiEndPoint);
};

export const saveVille = (ville: VilleInterface) => {
    const body = {
        ...ville,
    };
    if (ville.idVille) {
        return http.put(`${apiEndPoint}/${ville.idVille}`, body);
    }
    return http.post(`${apiEndPoint}`, body);
};
