import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { TableColumnType } from 'react-bs-datatable';
import { Col, Form, Row, Dropdown, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Datetime from 'react-datetime';
import {
    faPrint,
    faInfo,
    faPen,
    faEdit,
    faCircleCheck,
    faTimesCircle,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { isEmptyObject, buildUrlQueryParams, useQueryMeMo, utf8_to_b64, b64_to_utf8 } from 'utils/helpers';
import { ROLES as RolesMap } from 'constantes/permission-maps';
import AppContext from 'utils/appContext';
import { AppContextState } from 'types/index';
import TitleElement from 'components/UI/titleElement';
import Breadcrumbs, { breadcrumbDataType } from 'components/shared/breadCrumbs';
import PermissionsGate from 'hoc/permissionsGate';
import CustomModal from 'components/UI/customModal';
import Datatable from 'components/shared/datatable';
import Collapser from 'components/UI/collapser';
import Paginator from 'components/UI/paginator';
import EditFormFacture from 'pages/Facture/components/EditFormFacture';
import DeleteFormFacture from 'pages/Facture/components/DeleteFormFacture';
import FormPaiement from 'pages/Facture/components/FormPaiement';
import FactureComponent from 'pages/Commande/components/Facture';
import ClientInterface from 'types/interfaces/client.interface';
import FactureInterface from 'types/interfaces/facture.interface';
import { getClientsByIdFournisseur } from 'services/client.service';
import { getFacturesList } from 'services/facture.service';
import CommandeInterface from 'types/interfaces/commande.interface';

type Props = any;

const TOTAL_DATA_PER_PAGE = 100;

const FactureIndexPage: React.FC<Props> = ({}: Props) => {
    const { user: contextUser } = useContext<AppContextState>(AppContext);

    const [factures, setFactures] = useState<{ items: FactureInterface[]; count: number }>({
        items: [],
        count: 0,
    });
    const [clients, setClients] = useState<ClientInterface[]>([]);

    const [selectedPaiementEtat, setSelectedPaiementEtat] = useState<string>('');
    const [selectedClient, setSelectedClient] = useState<string | null>(null);
    const [date_debut, setDate_debut] = useState<any>(null);
    const [date_fin, setDate_fin] = useState<any>(null);
    const [activePage, setActivePage] = useState<number>(1);
    const [showFullFacture, setShowFullFacture] = useState<boolean>(false);
    const [showDetailsFacture, setShowDetailsFacture] = useState<boolean>(false);
    const [showDetailsPaiement, setShowDetailsPaiement] = useState<boolean>(false);
    const [showDeleteFacture, setShowDeleteFacture] = useState<boolean>(false);
    const [idFacture, setIdFacture] = useState<null | number>(null);

    const query = useQueryMeMo();
    const history = useNavigate();

    const handleShowFullFactureOpen = (idFacture: number) => {
        setIdFacture(idFacture);
        setShowFullFacture(true);
    };

    const handleShowFullFactureClose = () => {
        setShowFullFacture(false);
    };

    const handleShowDetailsFactureOpen = (idFacture: number) => {
        setIdFacture(idFacture);
        setShowDetailsFacture(true);
    };

    const handleShowDetailsFactureClose = () => {
        setShowDetailsFacture(false);
    };

    const handleShowDetailsPaiementOpen = (idFacture: number) => {
        setIdFacture(idFacture);
        setShowDetailsPaiement(true);
    };

    const handleShowDetailsPaiementClose = () => {
        setShowDetailsPaiement(false);
    };

    const handleShowDeleteFactureOpen = (idFacture: number) => {
        setIdFacture(idFacture);
        setShowDeleteFacture(true);
    };

    const handleShowDeleteFactureClose = () => {
        setShowDeleteFacture(false);
    };

    const onChangeDateDebut = (e: any) => {
        setDate_debut(e);
        handlePageNFilterChange(
            1,
            JSON.stringify({
                client: selectedClient,
                paiement: selectedPaiementEtat,
                dDebut: e.format('YYYY-MM-DD HH:mm:ss'),
                dFin: date_fin,
            }),
        );
    };

    const onChangeDateFin = (e: any) => {
        setDate_fin(e);
        handlePageNFilterChange(
            1,
            JSON.stringify({
                client: selectedClient,
                paiement: selectedPaiementEtat,
                dDebut: date_debut,
                dFin: e.format('YYYY-MM-DD HH:mm:ss'),
            }),
        );
    };

    const loadFactures = (
        total: number,
        page: number,
        idClient?: string,
        paiementStatus?: string,
        dateDebut?: Date | string,
        dateFin?: Date | string,
    ) => {
        getFacturesList(total, page, idClient, paiementStatus, dateDebut, dateFin)
            .then((response: AxiosResponse<FactureInterface[] | any>) => {
                const { data } = response;
                setFactures({
                    items: data.items,
                    count: data.count,
                });
                console.log(data);
            })
            .catch();
    };

    const handlePageNFilterChange = (activePage: number, searchedData?: string) => {
        const urlObject: any = {};
        if (activePage > 1) urlObject['page'] = activePage;
        if (searchedData) {
            const parsedObject = JSON.parse(searchedData);
            Object.keys(parsedObject).forEach((key) => {
                if (parsedObject[key as any]) {
                    if (key === 'client' && parsedObject[key as any])
                        urlObject[key] = utf8_to_b64(parsedObject[key as any]);
                    else urlObject[key] = parsedObject[key as any];
                }
            });
        }
        history(isEmptyObject(urlObject) ? window.location.pathname : `?${buildUrlQueryParams(urlObject)}`);
    };

    useEffect(() => {
        document.title = `Liste factures client - DS`;
    }, []);

    useEffect(() => {
        if (contextUser && contextUser.userData.type === 'Fournisseur') {
            getClientsByIdFournisseur(contextUser.userData.idFournisseur!)
                .then((response: AxiosResponse<ClientInterface[] | any>) => {
                    const { data } = response;
                    setClients(data);
                })
                .catch();
        }
    }, [contextUser]);

    useEffect(() => {
        const page = query.get('page');
        const client = query.get('client');
        const paiementStatus = query.get('paiement');
        const dDebut = query.get('dDebut');
        const dFin = query.get('dFin');
        const currentPage = page ? +page : 1;
        const currentClient = client ? b64_to_utf8(client) : '';
        const currentStatusPaiement = paiementStatus ?? '';
        const _dateDebut = (dDebut ? moment(dDebut) : moment().subtract(3, 'days')).format('YYYY-MM-DD HH:mm:ss');
        const _dateFin = (dFin ? moment(dFin) : moment().endOf('day')).format('YYYY-MM-DD HH:mm:ss');
        setActivePage(currentPage);
        setSelectedClient(currentClient!);
        setSelectedPaiementEtat(currentStatusPaiement);
        setDate_debut(_dateDebut!);
        setDate_fin(_dateFin!);
        const loadData = async () => {
            loadFactures(TOTAL_DATA_PER_PAGE, currentPage, currentClient, currentStatusPaiement, _dateDebut, _dateFin);
        };
        loadData();
    }, [contextUser, query, date_debut, date_fin, selectedClient, selectedPaiementEtat]);

    const tableHeaders: TableColumnType<any>[] = [
        {
            prop: 'refFacture',
            title: 'Ref',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
        },
        {
            prop: 'dateFacture',
            title: 'Date',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: FactureInterface) => (
                <>
                    {(row as any)['dateFacture'] ? moment((row as any)['dateFacture']).format('DD/MM/YYYY HH:mm') : '-'}
                </>
            ),
        },
        {
            prop: 'montantFacture',
            title: 'Montant',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: FactureInterface) => <>{row.montantFacture} DH</>,
        },
        {
            prop: '-nbrcmds',
            title: 'Nombre des cmds',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: FactureInterface) => <b>{row.commandes?.length ?? 0}</b>,
        },
        {
            prop: '--cmds',
            title: 'Commandes',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: FactureInterface) => <CommandesDataColumn cmds={row.commandes!} />,
        },
        {
            prop: 'client',
            title: 'Client',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: FactureInterface) => (
                <>{row.commandes?.length! > 0 ? (row.commandes as any)[0].client?.raisonSocial ?? '-' : '-'}</>
            ),
        },
        {
            prop: 'idPaiement',
            title: 'Payé',
            alignment: { horizontal: 'center' },
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: FactureInterface) => (
                <>
                    {row.idPaiement ? (
                        <FontAwesomeIcon icon={faCircleCheck} style={{ color: '#3da977' }} />
                    ) : (
                        <FontAwesomeIcon icon={faTimesCircle} style={{ color: '#b74141' }} />
                    )}
                </>
            ),
        },
        {
            prop: 'paiement.datePaiement',
            title: 'Payé Le',
            alignment: { horizontal: 'center' },
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: FactureInterface) => (
                <>{row.idPaiement ? moment((row as any)['paiement.datePaiement']).format('DD/MM/YYYY HH:mm') : '-'}</>
            ),
        },
        {
            prop: 'paiement.montantPaye',
            title: 'Montant Payé',
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            isFilterable: true,
            isSortable: true,
            cell: (row: FactureInterface) => <>{row.idPaiement ? `${(row as any)['paiement.montantPaye']} DH` : '-'}</>,
        },
        {
            prop: '--',
            title: 'Actions',
            alignment: { horizontal: 'right' },
            cellProps: {
                className: 'foceVerticalMiddle',
            },
            cell: (row: FactureInterface) => (
                <Dropdown className="d-inline mx-2">
                    <Dropdown.Toggle id="dropdown-autoclose-true"></Dropdown.Toggle>

                    <Dropdown.Menu className="btnDropDownInList" style={{ width: 'auto', minWidth: 'auto' }}>
                        <Dropdown.Item className="text-left" onClick={() => handleShowFullFactureOpen(row.idFacture!)}>
                            Imprimer <FontAwesomeIcon icon={faPrint} size="xs" style={{ color: '#776ca9' }} />
                        </Dropdown.Item>
                        <Dropdown.Item
                            className="text-left"
                            onClick={() => handleShowDetailsFactureOpen(row.idFacture!)}
                        >
                            Facture détails{' '}
                            <FontAwesomeIcon
                                icon={row.idPaiement ? faInfo : faEdit}
                                size="xs"
                                style={{ color: '#3a91db' }}
                            />
                        </Dropdown.Item>
                        <Dropdown.Item
                            className="text-left"
                            onClick={() => handleShowDetailsPaiementOpen(row.idFacture!)}
                        >
                            Paiement détails{' '}
                            <FontAwesomeIcon
                                icon={row.idPaiement ? faEdit : faPen}
                                size="xs"
                                style={{ color: '#3a91db' }}
                            />
                        </Dropdown.Item>
                        {!row.idPaiement && (
                            <PermissionsGate scopes={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_FACTURE]} strict>
                                <Dropdown.Item
                                    className="text-left"
                                    onClick={() => handleShowDeleteFactureOpen(row.idFacture!)}
                                >
                                    Supprimer cette facture{' '}
                                    <FontAwesomeIcon icon={faTrash} size="xs" style={{ color: '#ec1d0c' }} />
                                </Dropdown.Item>
                            </PermissionsGate>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            ),
        },
    ];

    const breadcrumbData: breadcrumbDataType[] = [
        { path: '/', text: 'Accueil' },
        {
            active: true,
            text: 'Facturation',
        },
        {
            active: true,
            text: 'Liste factures client',
        },
    ];

    return (
        <>
            <Breadcrumbs data={breadcrumbData} />
            <div className="d-flex justify-content-between align-items-center py-2">
                <TitleElement level={1} lineWidth="50%">
                    Liste factures client
                </TitleElement>
            </div>
            <Collapser moreClassNames={['flex-grow-07']}>
                <Row className="mb-2 holdit_notEffect">
                    <PermissionsGate scopes={[RolesMap.FOURNISSEUR_ADMIN, RolesMap.FOURNISSEUR_FACTURE]} strict>
                        <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_Client">
                            <Form.Label>Client:</Form.Label>
                            <Form.Select
                                size="sm"
                                name="selectedClient"
                                value={selectedClient!}
                                onChange={(e) => {
                                    const {
                                        target: { value },
                                    } = e;
                                    setSelectedClient(value);
                                    handlePageNFilterChange(
                                        1,
                                        JSON.stringify({
                                            client: value,
                                            paiement: selectedPaiementEtat,
                                            dDebut: date_debut,
                                            dFin: date_fin,
                                        }),
                                    );
                                }}
                                placeholder="Selectionner un client"
                            >
                                <option value="">Selectionner un client</option>
                                {clients.map((d: ClientInterface) => (
                                    <option key={d.idClient} value={d.idClient}>
                                        {d.raisonSocial}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </PermissionsGate>
                    <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_Paiement">
                        <Form.Label>Etat de paiement:</Form.Label>
                        <Form.Select
                            size="sm"
                            name="selectedPaiementEtat"
                            value={selectedPaiementEtat}
                            onChange={(e) => {
                                const {
                                    target: { value },
                                } = e;
                                setSelectedPaiementEtat(value);
                                handlePageNFilterChange(
                                    1,
                                    JSON.stringify({
                                        client: selectedClient!,
                                        paiement: value,
                                        dDebut: date_debut,
                                        dFin: date_fin,
                                    }),
                                );
                            }}
                            placeholder="Selectionner une etat de paiement"
                        >
                            <option value="">Tous</option>
                            <option value="1">Payée</option>
                            <option value="0">Non Payée</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_date_debut">
                        <Form.Label>Date début:</Form.Label>
                        <Datetime
                            inputProps={{
                                name: 'date_debut',
                                className: 'form-control form-control-sm',
                                readOnly: true,
                            }}
                            onChange={(e: any) => onChangeDateDebut(e)}
                            value={moment(date_debut, 'YYYY-MM-DD  HH:mm:ss').format('DD/MM/YYYY')}
                            dateFormat="DD/MM/YYYY"
                            timeFormat={false}
                            closeOnSelect
                        />
                    </Form.Group>
                    <Form.Group as={Col} xs={6} lg={3} md={3} controlId="form_grp_date_fin">
                        <Form.Label>Date fin:</Form.Label>
                        <Datetime
                            inputProps={{
                                name: 'date_fin',
                                className: 'form-control form-control-sm',
                                readOnly: true,
                            }}
                            onChange={(e: any) => onChangeDateFin(e)}
                            value={moment(date_fin, 'YYYY-MM-DD  HH:mm:ss').format('DD/MM/YYYY')}
                            dateFormat="DD/MM/YYYY"
                            timeFormat={false}
                            closeOnSelect
                        />
                    </Form.Group>
                </Row>
            </Collapser>
            <Datatable
                data={factures.items}
                tableColumns={tableHeaders}
                initialSort={{ initialState: { prop: 'dateFacture', order: 'desc' } }}
            >
                <Row>
                    <Col className="d-flex flex-col justify-content-end align-items-end">
                        <Paginator
                            defaultPage={activePage}
                            totalPages={Math.ceil(factures.count / TOTAL_DATA_PER_PAGE)}
                            callback={(e_page) =>
                                handlePageNFilterChange(
                                    e_page,
                                    JSON.stringify({
                                        client: selectedClient,
                                        paiement: selectedPaiementEtat,
                                        dDebut: date_debut,
                                        dFin: date_fin,
                                    }),
                                )
                            }
                        />
                    </Col>
                </Row>
            </Datatable>
            <CustomModal
                title="Imprimer facture"
                size="lg"
                show={showFullFacture}
                handleClose={handleShowFullFactureClose}
            >
                <FactureComponent loadData={showFullFacture} idFacture={idFacture!} />
            </CustomModal>
            <CustomModal
                title="Détails de facture"
                size="lg"
                show={showDetailsFacture}
                handleClose={handleShowDetailsFactureClose}
            >
                <EditFormFacture
                    factures={factures}
                    setFactures={(value: { items: FactureInterface[]; count: number }) => setFactures(value)}
                    idFacture={idFacture!}
                />
            </CustomModal>
            <CustomModal
                title="Détails de paiement"
                size="lg"
                show={showDetailsPaiement}
                handleClose={handleShowDetailsPaiementClose}
            >
                <FormPaiement
                    factures={factures}
                    setFactures={(value: { items: FactureInterface[]; count: number }) => setFactures(value)}
                    idFacture={idFacture!}
                />
            </CustomModal>
            <CustomModal
                title="Suppression de facture"
                size="lg"
                show={showDeleteFacture}
                handleClose={handleShowDeleteFactureClose}
            >
                <DeleteFormFacture
                    factures={factures}
                    setFactures={(value: { items: FactureInterface[]; count: number }) => setFactures(value)}
                    idFacture={idFacture!}
                    close={handleShowDeleteFactureClose}
                />
            </CustomModal>
        </>
    );
};

FactureIndexPage.defaultProps = {};

export default FactureIndexPage;

type CommandesDataColumnProps = {
    cmds: CommandeInterface[];
};
const CommandesDataColumn: React.FC<CommandesDataColumnProps> = ({ cmds }: CommandesDataColumnProps) => {
    const commandes = (variant = 'success') =>
        cmds.map((cmd) => (
            <Badge
                key={cmd.idCommande!}
                className="user-select-none d-flex justify-content-center align-content-center align-self-center"
                bg={variant}
            >
                {cmd.code}
            </Badge>
        ));

    const defaultList = commandes();
    const DetailsList = commandes('info');
    const firstTwoCommandes = defaultList.slice(0, 2);

    return (
        <div className="d-flex gap-1 my-xsm-font align-content-center ">
            {firstTwoCommandes}{' '}
            {defaultList.length > 2 && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-show-commandes">{DetailsList}</Tooltip>}>
                    <div className="cursor-pointer">...</div>
                </OverlayTrigger>
            )}
        </div>
    );
};
